import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { ExploreDataObj, ExploreDimData, MappedName } from '@pubstack/common/src/analytics/query'
import { NonNullable } from '@pubstack/common/src/assertion'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { ReactGoogleChartProps } from 'react-google-charts'
import { Controller, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ChipListInput } from '~/components/ChipListInput'
import { Icon } from '~/components/Icon'
import { RadioGroup } from '~/components/RadioGroup'
import { Select } from '~/components/Select'
import { TabProp, Tabs } from '~/components/Tabs'
import { TimelineMetricProps } from '~/components/TimelineMetric'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { Widget, WidgetProps } from '~/components/Widget'
import { AnalyticsBreakdownObj, useAnalyticsBreakdownObj } from '~/modules/analytics/AnalyticsBreakdownObj'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { AnalyticsDataTableConfigs } from '~/modules/analytics/analyticsDataTableObj.hooks'
import { WithClassName } from '~/types/utils'
import { getFilterSidebarContent } from '~/utils/analytics'
import { downloadCSVdata } from '~/utils/csv'
import { DataDistributionType, ExploreFormulasConfig } from './explore'
import { getExploreLeaderboard } from './exploreLeaderboardChart'
import { getExploreTimeline } from './exploreTimelineChart'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 16px;
  }
`

const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
  padding-top: 5px;
  ${Fonts.P2};
`

const FlexGrow = styled.div`
  flex: 1;
`

const Label = styled.span`
  width: 37px;
`

const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
  flex: 1;
  ${Tooltip} {
    align-self: center;
  }
`

const InfoIcon = styled(Icon)`
  color: ${Colors.Hurricane};
`

const getFns = {
  time: getExploreTimeline,
  dim: getExploreLeaderboard,
}

const downloadCSV = (formData: ExploreFormData, rawData: ExploreDataObj<any>, exploreFormulasConfig: ExploreFormulasConfig) => {
  let csv = ''
  const metrics = formData.metrics.map((d) => Object.entries(exploreFormulasConfig).find(([, formula]) => formula.name === d)?.[0]).filter(NonNullable)
  if (formData.type === 'dim') {
    const header = [formData.dimension, ...formData.metrics].join(',')
    const rows = Object.values(rawData.mappings).map((mapping) => {
      return [mapping.label, ...metrics.map((k) => exploreFormulasConfig[k].compute(rawData, 0)[mapping.value])].join(',')
    })

    csv = [header, ...rows].join('\n')
  } else {
    const header = ['epoch', formData.dimension, ...formData.metrics].join(',')
    const rows = rawData.epoch?.flatMap((epoch, index) => {
      return Object.values(rawData.mappings).map((mapping) => {
        return [epoch, mapping.label, ...metrics.map((k) => exploreFormulasConfig[k].compute(rawData, index)[mapping.value])].join(',')
      })
    })

    csv = [header, ...rows].join('\n')
  }

  downloadCSVdata(csv, 'explore-view')
}

export const useMetrics = <T extends ExploreDataObj<any>>({
  metricNames,
  data,
  comparisonData,
  currencySymbol,
  onMetricChange,
  exploreFormulasConfig,
}: {
  metricNames: string[]
  data: T
  comparisonData?: T
  currencySymbol: CurrencySymbol
  onMetricChange: (metric: string) => void
  exploreFormulasConfig: ExploreFormulasConfig
}) => {
  const [current, setCurrent] = useState({ index: 0, name: metricNames?.[0] ?? '' })
  useEffect(() => {
    const index = metricNames.findIndex((name) => name === current.name)
    if (index !== -1) {
      setCurrent({ index, name: current.name })
    } else {
      setCurrent({ index: 0, name: metricNames?.[0] ?? '' })
    }
  }, [metricNames])

  return useMemo(() => {
    const metrics = metricNames.map<TimelineMetricProps>((metricName, index) => {
      const f = exploreFormulasConfig[metricName]
      const rawValue = f && f.isComputable(data) ? f.sum(data) : 0
      return {
        active: current.index === index,
        label: f.name,
        value: f ? f.displayable(rawValue, currencySymbol).toString() : undefined,
        notApplicable: !f,
        onClick: () => {
          onMetricChange(metricName)
          f && setCurrent({ index, name: metricName })
        },
        tooltipText: f.tooltip,
      }
    })

    return {
      metrics,
      currentMetric: metrics[current.index],
      currentMetricIndex: current.index,
    }
  }, [current.index, current.name, data, metricNames, comparisonData, currencySymbol, onMetricChange])
}

export type ExploreFormData = {
  metrics: string[]
  dimension: string
  type: 'time' | 'dim'
  limit: number
  order: 'DESC' | 'ASC'
  others?: boolean
}

const dataTypes: { type: DataDistributionType; label: string }[] = [
  { type: 'number', label: '123' },
  { type: 'percentage', label: '%' },
]

type PureExploreWidgetProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    onSubmit: (data: ExploreFormData) => Promise<void>
    onBreakdownRowClick: (name: MappedName) => void
    onBreakdownSortChange: (metric: string) => void
    rawData?: ExploreDataObj<any>
    rawDataBreakdown?: ExploreDimData<any>
    formData: ExploreFormData
    currencySymbol: CurrencySymbol
    dimensions: Dimension[]
    exploreFormulasConfig: ExploreFormulasConfig
  }

const _PureExploreWidget: FunctionComponent<PureExploreWidgetProps> = ({
  className,
  onSubmit,
  onBreakdownRowClick,
  onBreakdownSortChange,
  rawData,
  rawDataBreakdown,
  formData,
  dimensions,
  currencySymbol,
  exploreFormulasConfig,
  ...props
}) => {
  const { control, handleSubmit } = useForm<ExploreFormData>({ defaultValues: { ...formData } })
  const [useDataDistributionType, setDataDistributionType] = useState<'number' | 'percentage'>('number')
  const metricNames = useMemo(() => formData.metrics.map((metric) => Object.entries(exploreFormulasConfig).find(([, formula]) => formula.name === metric)?.[0] ?? ''), [formData.metrics])
  const dimensionsOptions = getFilterSidebarContent()
    .filter((f) => dimensions.includes(f.dimension))
    .map(({ dimension, title }) => ({
      label: title === 'Bidders' ? 'Prebid bidders' : title,
      value: dimension,
    }))
  const { metrics, currentMetricIndex } = useMetrics({
    metricNames,
    data: rawData ?? ({ mappings: {} as Record<string, MappedName> } as ExploreDataObj<any>),
    currencySymbol,
    onMetricChange: () => {},
    exploreFormulasConfig,
  })
  const metric = metricNames[currentMetricIndex]
  const { chart, legends } = metric
    ? getFns[formData.type]({ rawData: rawData as any, formula: exploreFormulasConfig[metric], currencySymbol, type: useDataDistributionType })
    : {
        chart: {
          data: undefined,
          chartType: 'BarChart',
          options: {
            ...AnalyticsDefaultChartOptions,
          },
        } as ReactGoogleChartProps,
        legends: [],
      }

  const breakdownProps = useAnalyticsBreakdownObj<any>({
    rawData: rawDataBreakdown,
    currencySymbol,
    onTabChange: () => {},
    getColumnsConfigs: () => metricNames,
    getConfigs: () =>
      [
        [
          {
            displayedAs: 'text',
            sortLabel: 'Site name',
          },
          ...metricNames.map((metricName) => ({
            propertyName: metricName,
            formula: exploreFormulasConfig[metricName],
            tooltip: exploreFormulasConfig[metricName].tooltip,
          })),
        ],
      ] as AnalyticsDataTableConfigs<any>,
    tabsConfig: [{ dimension: formData.dimension as Dimension, label: '', iconName: 'empty' }],
  })

  return (
    <PageContent>
      <Widget
        className={className}
        hideHeader
        isLoading={false}
        error={false}
        empty={false}
        onRefreshClick={function (): void {
          throw new Error('Function not implemented.')
        }}
      >
        <FormWrapper>
          <Group>
            <Label>Query</Label>
            <FlexGrow>
              <ChipListInput
                control={control}
                name={'metrics'}
                label={'Metrics'}
                selectableOptions={Object.values(exploreFormulasConfig).map((formula) => ({ value: formula.name, label: formula.name }))}
                onChange={() => handleSubmit(onSubmit)()}
                isSpaceAllowed
                maxChipCount={5}
                variant={'small'}
              />
            </FlexGrow>
          </Group>
          <Group>
            <FlexGrow>
              <Select control={control} onChange={() => handleSubmit(onSubmit)()} name={'dimension'} label={'Grouped by dimension'} options={dimensionsOptions} searchable variant={'small'} />
            </FlexGrow>
          </Group>
          <Group>
            <Label>View</Label>
            <FlexGrow>
              <RadioGroup
                control={control}
                onChange={() => handleSubmit(onSubmit)()}
                name={'type'}
                size={'small'}
                options={[
                  { label: 'Timeline', value: 'time', iconName: 'timeline' },
                  { label: 'Bars', value: 'dim', iconName: 'bar' },
                ]}
              />
            </FlexGrow>
          </Group>
          <Group>
            <Controller
              control={control}
              name={'others'}
              render={({ field: { onChange, value } }) => (
                <>
                  <Toggle
                    value={value}
                    onClick={() => {
                      onChange(!value)
                      handleSubmit(onSubmit)()
                    }}
                  />
                  <span>Show &quot;Others&quot;</span>
                  <Tooltip title={'When disabled, only the top 10 in terms of Auctions is shown.'}>
                    <InfoIcon name={'info'} width={'16px'} />
                  </Tooltip>
                </>
              )}
            />
          </Group>
        </FormWrapper>
      </Widget>
      <AnalyticsChartWidget
        {...props}
        title={'View'}
        icon={'chart_bar'}
        chart={chart}
        legends={legends}
        metrics={metrics}
        info={
          <>
            <Tabs
              tabs={dataTypes.map((d, index) => ({
                label: d.label,
                active: useDataDistributionType === d.type,
              }))}
              onClick={({ label }: TabProp) => {
                setDataDistributionType(dataTypes.find((d) => d.label === label)?.type ?? 'number')
              }}
              fluid={false}
            />
            <Button variant={'tertiary'} iconName={'download'} onClick={() => (rawData ? downloadCSV(formData, rawData, exploreFormulasConfig) : undefined)} />
          </>
        }
      />
      <AnalyticsBreakdownObj {...props} {...breakdownProps} onRowClick={onBreakdownRowClick} onSortChange={onBreakdownSortChange} />
    </PageContent>
  )
}

export const PureExploreWidget = styled(_PureExploreWidget)``
