import styled from '@emotion/styled'
import { ScopeUser } from '@pubstack/common/src/user'
import { FunctionComponent } from 'react'
import { useRecoilValue } from 'recoil'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { currentScopeState } from '~/state'
import { WithClassName } from '~/types/utils'

const ModalActions = styled.div`
  display: flex;
  gap: 24px;
`

const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > * {
    display: inline-flex;
    flex-direction: column;
  }
`
type PureSettingsMembersPageDeleteModalProps = WithClassName & { member: ScopeUser; onDelete: () => unknown }
const _PureSettingsMembersPageDeleteModal: FunctionComponent<PureSettingsMembersPageDeleteModalProps> = ({ member, onDelete }) => {
  const { close } = useGlobalModal()
  const scope = useRecoilValue(currentScopeState)
  return (
    <Modal.Content>
      <Modal.Title>Remove member</Modal.Title>

      <Modal.Body>
        <Paragraphs>
          <p>
            <span>
              You are about to revoke{' '}
              <strong>
                {member.nickname}&apos;s ({member.email})
              </strong>{' '}
              access.
            </span>
            <span>This person will not be able to connect to {scope.name} anymore.</span>
          </p>
          <p>Do you confirm your choice ?</p>
        </Paragraphs>
      </Modal.Body>

      <Modal.Actions>
        <Button onClick={close} variant={'tertiary'}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onDelete()
            close()
          }}
          variant={'negative'}
        >
          Remove member
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

export const PureSettingsMembersPageDeleteModal = styled(_PureSettingsMembersPageDeleteModal)``
