import _ from 'lodash'
import { BidderAdapter } from './bidder-adapter'

export interface ExcludedCampaignsData {
  url: string
  lastModified?: number
  canLambdaBeLaunched: boolean
}

export interface RefreshGlobalSetting {
  bidders: string[]
  sizes: string[]
  timer?: number
}
export interface AdmRefreshGlobalSettings {
  id: string
  scopeId: string
  settings: RefreshGlobalSetting[]
}

export type AdStackRefreshGlobalSettingsData = {
  refreshGlobalSettings: AdmRefreshGlobalSettings | null
  excludedCampaignsData: ExcludedCampaignsData | null
  bidders: BidderAdapter[]
}

export function refreshSettingIsEmpty(refreshSetting: RefreshGlobalSetting): boolean {
  return refreshSetting.bidders.length === 0 && refreshSetting.sizes.length === 0
}

/**
 * This method identifies the [bidders x sizes] duplicates (> Rules/Refresh/Global settings/Settings)
 * A setting is a duplicate if it has bidder AND size intersections with another setting
 * Note: empty arrays = all elements (ex: empty bidder array = all bidders)
 * @param settingToCheck
 * @param actualSettings the whole settings \ {settingToValidate}
 */
export function getDuplicatedSettings(settingToCheck: RefreshGlobalSetting, actualSettings: RefreshGlobalSetting[]): DuplicatedSettings[] {
  return actualSettings.reduce((acc, setting) => {
    const biddersIntersection =
      setting.bidders.length === 0 && settingToCheck.bidders.length === 0
        ? ['all bidders']
        : setting.bidders.length > 0 && settingToCheck.bidders.length > 0
          ? _.intersection(setting.bidders, settingToCheck.bidders)
          : []
    const sizesIntersection =
      setting.sizes.length === 0 && settingToCheck.sizes.length === 0
        ? ['all sizes']
        : setting.sizes.length > 0 && settingToCheck.sizes.length > 0
          ? _.intersection(setting.sizes, settingToCheck.sizes)
          : []
    return biddersIntersection.length > 0 && sizesIntersection.length > 0
      ? [...acc, { bidders: biddersIntersection.includes('all bidders') ? [] : biddersIntersection, sizes: sizesIntersection.includes('all sizes') ? [] : sizesIntersection }]
      : acc
  }, [] as DuplicatedSettings[])
}

export function displayDuplicateSettings(duplicatedSettings: DuplicatedSettings[]): string {
  return duplicatedSettings.map(displayDuplicateSetting).join(', ')
}

export function displayDuplicateSetting(duplicatedSettings: DuplicatedSettings): string {
  const array: string[] = []
  if (duplicatedSettings.bidders.length > 0) array.push(duplicatedSettings.bidders.join(', '))
  if (duplicatedSettings.sizes.length > 0) array.push(duplicatedSettings.sizes.join(', '))
  return array.join(' + ')
}

// to store the identified bidders x sizes duplicates
export type DuplicatedSettings = {
  bidders: string[]
  sizes: string[]
}
