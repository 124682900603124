import styled from '@emotion/styled'
import { PrebidModuleNames, Provider } from '@pubstack/common/src/catalogItem'
import { Icon } from '~/components/Icon'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import { AdditionalComponentProps, DataBySite, PureAdStackPrebidModulePage, PureAdStackPrebidModulePageProps } from '~/modules/adstack/inventory/modules/PureAdStackPrebidModulePage'

const _PureAdStackRealTimeData = <ModuleName extends PrebidModuleNames>({ code, updateSiteData, ...props }: PureAdStackPrebidModulePageProps<ModuleName>) => {
  const WaitForItToggle = ({ siteModule }: AdditionalComponentProps) => {
    return (
      <TableCell>
        <Tooltip title={siteModule.moduleData ? 'Manage the waitForIt param (required if an AuctionDelay has been set)' : 'Please set params before enabling waitForIt option.'}>
          <Toggle
            id={siteModule.siteId}
            disabled={!siteModule.moduleData}
            value={!!(siteModule.moduleData as Omit<Provider<ModuleName>, 'name'>)?.waitForIt}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              const data = siteModule.moduleData as Omit<Provider<ModuleName>, 'name'>
              data.waitForIt = !data?.waitForIt
              if (code) {
                updateSiteData(code, {
                  siteName: siteModule.siteName,
                  siteId: siteModule.siteId,
                  moduleData: siteModule.moduleData as DataBySite<ModuleName>['moduleData'],
                })
              }
            }}
          />
        </Tooltip>
      </TableCell>
    )
  }
  const WaitName = () => {
    const Container = styled.div`
      display: flex;
      justify-items: center;
      align-items: center;
      gap: 10px;
    `
    const TooltipStyled = styled(Tooltip)`
      width: 18px;
      height: 18px;
    `
    return (
      <Container>
        <div>Wait for it</div>
        <TooltipStyled title={'Setting this value to true designates the RTD module as "important," causing the auction to wait the full delay even if other RTD modules haven\'t responded.'}>
          <Icon height={'18px'} width={'18px'} name={'info'} />
        </TooltipStyled>
      </Container>
    )
  }
  const TableWaitForItColumn: TableColumns[number] = {
    name: <WaitName />,
    isSortable: false,
  }
  const addCol: PureAdStackPrebidModulePageProps<ModuleName>['additionalColumns'] = [[TableWaitForItColumn, WaitForItToggle]]

  return <PureAdStackPrebidModulePage code={code} updateSiteData={updateSiteData} {...props} additionalColumns={addCol} />
}

export const PureAdStackRealTimeData = _PureAdStackRealTimeData
