export const getEnvFromUrl = (url: string): string => {
  if (url.includes('preview')) {
    url = url.slice(9)
  }
  const envs: { [key: string]: string } = {
    'preview.pubstack.io': 'preview',
    'app.dev.pubstack.io': 'dev',
    'app.beta.pubstack.io': 'beta',
    'app.pubstack.io': 'prod',
  }
  return envs[url]
}
