import styled from '@emotion/styled'
import { ReportNameType } from '@pubstack/common/src/report'
import { FunctionComponent, ReactNode, useState } from 'react'
import { Sizes } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import Button from '~/components/Button'
import { Popover } from '~/components/Popover'
import { WithClassName } from '~/types/utils'
import { PureReportsOption } from './PureReportsOption'

const Options = styled.div`
  box-shadow: 0 3px 10px rgba(54, 54, 54, 0.3);
  border-radius: 4px;
  padding: ${Sizes[12]};
  max-height: 272px;
  overflow-y: auto;
  box-sizing: border-box;
  ${ScrollbarStyle};

  @media (min-height: 550px) {
    max-height: 340px;
  }

  @media (min-height: 650px) {
    max-height: 440px;
  }

  @media (min-height: 750px) {
    max-height: 540px;
  }
`

export type ReportSelectorOption = {
  title: string | ReactNode
  description: string | ReactNode
  value: ReportNameType
}

type PureReportsSelectorProps = WithClassName & {
  options: ReportSelectorOption[]
  value?: ReportSelectorOption
  onChange: (option: ReportSelectorOption, index: number) => void
}
const _PureReportsSelector: FunctionComponent<PureReportsSelectorProps> = ({ className, options, value, onChange }) => {
  const [open, setOpen] = useState(false)
  const trigger = (
    <Button className={className} variant={'secondary'}>
      {value?.title ?? 'Report type'}
    </Button>
  )
  return (
    <Popover trigger={trigger} open={open} setOpen={setOpen}>
      <Options>
        {(options || []).map((option, index) => (
          <PureReportsOption
            key={index}
            {...option}
            checked={option === value}
            onChange={() => {
              onChange(option, index)
              setTimeout(() => setOpen(false), 200)
            }}
            name={'reports'}
          />
        ))}
      </Options>
    </Popover>
  )
}
export const PureReportsSelector = styled(_PureReportsSelector)``
