import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon, IconName } from './Icon'

export type TabProp = {
  label: string
  iconName?: IconName
  active?: boolean
  disabled?: boolean
}

const TabIcon = styled(Icon)``

const TabWrapper = styled.div<{ active?: boolean; disabled?: boolean }>`
  ${Fonts.P1}
  font-weight: bold;
  padding: 0 10px 3px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => (props.disabled ? '#c4c4c4' : props.active ? '#363636' : '#4c5774')};
  position: relative;

  :before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    border-radius: 4px 4px 0 0;
    background-color: ${Colors.King};
    opacity: ${({ active }) => (active ? 1 : 0)};
  }

  ${({ active, disabled }) =>
    !disabled &&
    css`
      :hover {
        color: #363636;
        :before {
          opacity: ${active ? 1 : 0.1};
        }
      }
    `}

  ${TabIcon} {
    margin-right: 4px;
  }
`
const TabsWrapper = styled.div<{ fluid: boolean }>`
  border-bottom: 1px solid #e5e5e5;
  display: inline-flex;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};

  ${TabWrapper} + ${TabWrapper} {
    margin-left: 8px;
  }
`

type TabOnClick = (_: TabProp) => void
const Tab = ({ tab, onClick }: { tab: TabProp; onClick?: TabOnClick }) => (
  <TabWrapper active={tab.active} disabled={tab.disabled} onClick={() => !tab.disabled && onClick && onClick(tab)}>
    {tab.iconName && <TabIcon name={tab.iconName} width={'20px'} />}
    {tab.label}
  </TabWrapper>
)

const Tabs = ({ tabs, onClick, fluid = true }: { tabs: TabProp[]; onClick?: TabOnClick; fluid?: boolean }) => (
  <TabsWrapper fluid={fluid}>
    {tabs.map((tab, index) => (
      <Tab tab={tab} key={index} onClick={onClick} />
    ))}
  </TabsWrapper>
)

export { Tabs }
