import { DateTime } from 'luxon'

export const downloadCSVdata = (data: string, fileName?: string) => {
  const time = DateTime.now().toFormat('yyyy-MM-dd--HH-mm-ss')
  const anchor = document.createElement('a')
  anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
  anchor.target = '_blank'
  anchor.download = fileName ? `pbstck-${fileName}-${time}.csv` : `pbstck-data-${time}.csv`
  anchor.click()
  anchor.remove()
}
