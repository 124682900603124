import styled from '@emotion/styled'
import { FunctionComponent, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TabProp } from '~/components/Tabs'
import { WithClassName } from '~/types/utils'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackContextTabs } from './PureAdStackContextTabs'

type TabLabel = 'Contexts' | 'Context keys'

const contextTabs: TabProp[] = [{ label: 'Contexts' }, { label: 'Context keys' }]

const tabLabelToPath: { [key in TabLabel]: string } = {
  Contexts: 'contexts',
  'Context keys': 'context-keys',
}

type AdStackContextTabsProps = WithClassName & {
  //
}

const _AdStackContextTabs: FunctionComponent<AdStackContextTabsProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const tabLabelFromLocation = (Object.keys(tabLabelToPath) as Array<keyof typeof tabLabelToPath>).find(
    (key) => tabLabelToPath[key] === (location.pathname.split('/').find((dir) => Object.values(tabLabelToPath).includes(dir)) ?? 'contexts')
  )

  const [activeTab, setActiveTab] = useState<TabLabel>(tabLabelFromLocation ?? 'Contexts')

  useEffect(() => {
    setActiveTab(tabLabelFromLocation ?? 'Contexts')
  }, [tabLabelFromLocation])

  const breadcrumbs = useBreadcrumbs()

  return (
    <PureAdStackContextTabs
      breadcrumbs={breadcrumbs}
      onTabClick={(tab) => {
        setActiveTab(tab.label as TabLabel)
        navigate(tabLabelToPath[tab.label as TabLabel])
      }}
      tabs={contextTabs.map((t) => ({ ...t, active: t.label === activeTab }))}
    >
      <Outlet />
    </PureAdStackContextTabs>
  )
}

export const AdStackContextTabs = styled(_AdStackContextTabs)``
