import { DateTime, Interval } from 'luxon'

export const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export interface Preset {
  label: string
  action: () => void
  displayable: () => boolean
  selector: { even: DateTime; odd: DateTime; next: 'even' | 'odd' }
}

export type IntervalWithLabel = {
  label: 'custom' | string
  interval: Interval
}
