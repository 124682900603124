import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Flyout } from '~/components/Flyout'
import { WithClassName } from '~/types/utils'

type PureAdStackGamIntegrationHelpFlyoutProps = WithClassName & {
  isOpen: boolean
  onClose: () => unknown
}

const HelpText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  & div {
    margin-bottom: 10px;
  }
  h2 {
    margin-bottom: 12px;
  }

  ul,
  ol {
    margin: 0;
    margin-left: 20px;
    padding: 0;
  }

  & div > p {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
`

const _PureAdStackTamIntegrationHelpFlyout: FunctionComponent<PureAdStackGamIntegrationHelpFlyoutProps> = ({ className, isOpen, onClose }) => {
  return (
    <Flyout className={className} isOpen={isOpen} onClose={onClose} title={'Help'} color={Colors.Violet}>
      <HelpText>
        <h2>TAM integration</h2>
        <div>To configure Amazon&apos;s TAM (Transparent Ad Marketplace) integration on a website, Pubstack just needs you to provide information given to you by Amazon.</div>
        <div>
          Pubstack takes care of the necessary steps to manage technical integration:
          <ol>
            <li>load Amazon library</li>
            <li>initialize tags</li>
            <li>request ads </li>
          </ol>
        </div>
        <div>You only need to fill in your publisher ID and save.</div>
      </HelpText>
    </Flyout>
  )
}

export const PureAdStackTamIntegrationHelpFlyout = styled(_PureAdStackTamIntegrationHelpFlyout)``
