import styled from '@emotion/styled'
import { DisplayedRule, DisplayedStack, SyncCategory } from '@pubstack/common/src/stack'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { OutOfSync } from '~/components/AdStack/OutOfSync'
import { Icon } from '~/components/Icon'
import { Skeleton } from '~/components/Skeleton'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

export const DetailsWrapper = styled.div`
  padding: 8px 0 20px 0;
  max-width: 816px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  ${Fonts.P1}
  h2 {
    ${Fonts.P1}
    font-weight: 500;
  }
  h3 {
    ${Fonts.P2}
    color: ${Colors.SlateGrey};
    font-weight: normal;
  }
  ${Tooltip} {
    display: inline-flex;
  }
  ${Skeleton} {
    width: 100%;
  }
`
export const TextIcon = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`
export const Title = styled.h2`
  ${Fonts.H2}
  font-weight: 500;
`
export const pipeSeparator = `\xa0\xa0|\xa0\xa0`

type DeviceRuleDetailsProps<T extends DisplayedRule> = WithClassName & {
  device: 'Desktop' | 'Mobile'
  detailedStack: DisplayedStack
  syncCategory: SyncCategory
  showName: (rule: T) => string
  rules: T[]
}

const DeviceRuleDetails = <T extends DisplayedRule>({ device, detailedStack, syncCategory, showName, rules }: DeviceRuleDetailsProps<T>) => {
  return (
    <>
      <h2>{device} rules</h2>
      {rules &&
        Object.entries(rules || []).map(([_, rule], index) => {
          return (
            <div key={index}>
              <div>
                <h3>
                  {device} rule {index + 1}
                </h3>
                <OutOfSync category={syncCategory} detailedStack={detailedStack} name={showName(rule)} />
              </div>
              <div>
                <h3>Rule applied to</h3>
                <span>{rule.adUnitNames.join(pipeSeparator)}</span>
              </div>
            </div>
          )
        })}
    </>
  )
}

type StackRuleDetailsProps<T extends DisplayedRule> = WithClassName &
  Pick<DeviceRuleDetailsProps<T>, 'detailedStack' | 'showName' | 'syncCategory'> & {
    isLoading: boolean
    title: string
    featureEnabled: boolean
    desktopRules: T[]
    mobileRules: T[]
  }

export const StackRuleDetails = <T extends DisplayedRule>({ isLoading, detailedStack, title, featureEnabled, syncCategory, showName, desktopRules, mobileRules }: StackRuleDetailsProps<T>) => {
  return (
    <>
      <TextIcon>
        <Title>{title}</Title>
        <Icon width={'26px'} name={featureEnabled ? 'check' : 'close'} fill={featureEnabled ? Colors.Success : Colors.Hurricane} />
      </TextIcon>
      {isLoading ? (
        <Skeleton bigger width={'750px'} />
      ) : featureEnabled ? (
        <DetailsWrapper>
          {desktopRules.length > 0 && <DeviceRuleDetails device={'Desktop'} detailedStack={detailedStack} rules={desktopRules} showName={showName} syncCategory={syncCategory} />}
          {mobileRules.length > 0 && <DeviceRuleDetails device={'Mobile'} detailedStack={detailedStack} rules={mobileRules} showName={showName} syncCategory={syncCategory} />}
        </DetailsWrapper>
      ) : (
        <DetailsWrapper />
      )}
    </>
  )
}
