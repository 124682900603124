import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { AccessCard } from '~/components/AccessCard'
import { Link } from '~/components/Link'
import { SettingStatusCard } from '~/components/SettingStatusCard'
import { WithClassName } from '~/types/utils'
import { SUPPORT_MAIL } from '~/utils/constants'
import { SETTINGS_NAV_CONFIG, SettingsAccessLabel } from '~/utils/settings'

const SettingsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 42px;
  & h2 {
    ${Fonts.H2}
    font-weight: 500;
    margin-bottom: 16px;
  }
`

const SettingsNav = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: stretch;

  & > * {
    flex-basis: 388px;
  }
`

type PureSettingsHomePageProps = WithClassName & {
  onAccessCardClick: (label: SettingsAccessLabel) => unknown
  displayRefreshAccess: boolean
  displayAbTest: boolean
  isOwner: boolean
  integrations: {
    isAdxActive: boolean
    isPrebidActive: boolean
    prebidVariable?: string
  }
}
const _PureSettingsHomePage: FunctionComponent<PureSettingsHomePageProps> = ({ className, onAccessCardClick, displayRefreshAccess, displayAbTest, integrations, isOwner }) => {
  const teamSettings: SettingsAccessLabel[] = ['profile', 'members', ...(isOwner ? (['security'] as SettingsAccessLabel[]) : [])]
  const publishingSettings: SettingsAccessLabel[] = [
    'sites',
    ...(displayRefreshAccess ? (['refreshGroups', 'refreshExceptions'] as SettingsAccessLabel[]) : []),
    ...(displayAbTest ? (['abTest'] as SettingsAccessLabel[]) : []),
  ]

  return (
    <SettingsWrapper className={className}>
      <div>
        <h2>Team</h2>
        <SettingsNav>
          {teamSettings.map((setting, index) => (
            <AccessCard {...SETTINGS_NAV_CONFIG[setting]} onClick={() => onAccessCardClick(setting)} key={index} />
          ))}
        </SettingsNav>
      </div>
      <div>
        <h2>Publishing settings</h2>
        <SettingsNav>
          {publishingSettings.map((setting, index) => (
            <AccessCard {...SETTINGS_NAV_CONFIG[setting]} onClick={() => onAccessCardClick(setting)} key={index} />
          ))}
        </SettingsNav>
      </div>
      <div>
        <h2>Integrations</h2>
        <SettingsNav>
          <SettingStatusCard
            title={'AdX'}
            content={
              integrations.isAdxActive ? (
                <div>AdX is integrated.</div>
              ) : (
                <>
                  <div>AdX is not integrated with your account.</div>
                  <div>
                    Contact <Link label={SUPPORT_MAIL} href={`mailto:${SUPPORT_MAIL}`} /> to integrate it.
                  </div>
                </>
              )
            }
            icon={'gam'}
            active={integrations.isAdxActive}
          />
          <SettingStatusCard
            title={'Prebid'}
            content={
              <>
                <div>{integrations.isPrebidActive ? `Global variable: ${integrations.prebidVariable}` : 'Prebid is not integrated with your account.'}</div>
                <div>
                  Contact <Link label={SUPPORT_MAIL} href={`mailto:${SUPPORT_MAIL}`} /> {integrations.isPrebidActive ? 'to edit it.' : 'to integrate it.'}
                </div>
              </>
            }
            icon={'prebid'}
            active={integrations.isPrebidActive}
          />
        </SettingsNav>
      </div>
    </SettingsWrapper>
  )
}

export const PureSettingsHomePage = styled(_PureSettingsHomePage)``
