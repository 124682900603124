import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { CONTEXT_ELEMENT_OP_AS_STR, ConditionElement, ConditionGroup } from '@pubstack/common/src/stackContext'
import { FunctionComponent } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'

const ConditionGroupChild = styled.span``
const ConditionGroupWrapper = styled.p<{ level: number; inline?: boolean }>`
  ${({ level }) =>
    level > 0 &&
    css`
      margin-left: 12px;
    `}

  ${({ inline }) =>
    !!inline &&
    css`
      & * {
        display: inline !important;
      }
    `}
`
const ConditionElementKey = styled.span`
  font-weight: 500;
`
const ConditionElementOperation = styled.span`
  ${Fonts.colors.SlateGrey};
  font-weight: 400;
`
const ConditionElementValues = styled.span`
  font-weight: 500;
`
const ConditionGroupOperation = styled.span`
  font-weight: 700;
`

type ContextConditionsProps = WithClassName & {
  group: ConditionGroup
  level?: number
  inline?: boolean
}
const _ContextConditions: FunctionComponent<ContextConditionsProps> = ({ className, group, level = 0, inline }) => {
  return (
    <ConditionGroupWrapper level={level} inline={inline} className={className}>
      {group.elements.map((e, index) => (
        <p key={index}>
          <ConditionGroupOperation>{!!index && group.op}</ConditionGroupOperation>{' '}
          <ConditionGroupChild>
            {'elements' in e ? (
              <>
                {'('}
                <br />
                <ContextConditions group={e} level={level + 1} />
                {')'}
              </>
            ) : (
              <DetailedContextConditionsElement element={e} />
            )}
          </ConditionGroupChild>{' '}
        </p>
      ))}
    </ConditionGroupWrapper>
  )
}
export const ContextConditions = styled(_ContextConditions)``

const DetailedContextConditionsElement: FunctionComponent<{ element: ConditionElement }> = ({ element }) => {
  return (
    <span>
      <ConditionElementKey>{element.key}</ConditionElementKey> <ConditionElementOperation>{CONTEXT_ELEMENT_OP_AS_STR[element.op]}</ConditionElementOperation> [
      <ConditionElementValues>{element.values.join(', ')}</ConditionElementValues>]
    </span>
  )
}
