import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { MappedName, SiteMetricsByDim } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useCallback } from 'react'
import { DataTableSorting } from '~/components/DataTable/DataTableTypes'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsBreakdown, useAnalyticsBreakdown } from '~/modules/analytics/AnalyticsBreakdown'
import { allAuctionFillRate, allECPM, allImpressionRevenue, auctionCount, hbBidDensity, hbImpressionRevenue } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { TabConfig } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

type PureSiteBreakdownProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    rawData?: SiteMetricsByDim
    onTabChange: (dimension: Dimension) => void
    currencySymbol: CurrencySymbol
    onRowClick: (name: MappedName) => void
    sorting?: DataTableSorting
    onSortChange: (metric: string) => void
    tabsConfig: TabConfig[]
    defaultTabIndex?: number
  }
const _PureSiteBreakdown: FunctionComponent<PureSiteBreakdownProps> = ({ currencySymbol, rawData, onTabChange, tabsConfig, defaultTabIndex, ...props }) => {
  const breakdownProps = useAnalyticsBreakdown({
    currencySymbol,
    rawData,
    onTabChange,
    tabsConfig,
    defaultTabIndex,
    getConfigs: useCallback(
      ({ dimension }) => [
        [
          {
            displayedAs: 'text',
            propertyName: 'name',
            sortLabel: 'Site name',
          },
          {
            formula: auctionCount,
            propertyName: 'auctionCount',
            tooltip: ANALYTICS_TOOLTIPS.AUCTION_COUNT,
          },
          {
            formula: dimension === 'stackIdVersion' ? hbImpressionRevenue : allImpressionRevenue,
            propertyName: 'impressionRevenue',
            tooltip: dimension === 'stackIdVersion' ? ANALYTICS_TOOLTIPS.PREBID_REVENUE : ANALYTICS_TOOLTIPS.REVENUE,
          },
          {
            formula: allAuctionFillRate,
            propertyName: 'auctionFillRate',
            tooltip: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
            status: {
              alert: 5,
              warning: 15,
              mode: 'below',
            },
          },
          {
            formula: allECPM,
            propertyName: 'eCPM',
            tooltip: ANALYTICS_TOOLTIPS.ECPM,
          },
          ...(dimension === 'abTestPopulation'
            ? []
            : [
                {
                  formula: hbBidDensity,
                  propertyName: 'hbBidDensity',
                  tooltip: ANALYTICS_TOOLTIPS.PREBID_DENSITY,
                },
              ]),
        ],
      ],
      []
    ),
    getColumnsConfigs: useCallback(({ dimension }) => ['auctionCount', 'impressionRevenue', 'auctionFillRate', 'eCPM', ...(dimension === 'abTestPopulation' ? [] : ['hbBidDensity'])], []),
  })
  return <AnalyticsBreakdown {...props} {...breakdownProps} />
}
export const PureSiteBreakdown = styled(_PureSiteBreakdown)``
