import { GroupOverlap } from '@pubstack/common/src/refresh'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useState } from 'react'
import { toggleValueInArray } from '~/utils/array'
import { RefreshGroupComboSearch } from './RefreshGroupComboSearch'

export const RefreshGroupSitesForm: FunctionComponent<{
  siteList: Site[]
  refreshGroupSites: Site[]
  overlap: GroupOverlap
  updateRefreshGroup: (sites: Site[]) => unknown
}> = ({ siteList, refreshGroupSites, updateRefreshGroup, overlap }) => {
  const [siteFilter, setSiteFilter] = useState('')
  // building checkboxes: we check sites that are in refresh group's ones, then filter them according to the search value
  const options = siteList.map((s) => ({ label: s.name, value: s.id, checked: refreshGroupSites.some((rs) => rs.id === s.id) })).filter((s) => s.label.toLowerCase().includes(siteFilter.toLowerCase()))

  const onToggle = (site: Site) => {
    // toggling the site
    const newSites = toggleValueInArray(refreshGroupSites, site, 'id')

    // dispatch the new value
    updateRefreshGroup(newSites)
  }

  const onSelectAll = () => {
    if (options.every((o) => o.checked)) {
      updateRefreshGroup(refreshGroupSites.filter((s) => !options.find((o) => o.value === s.id)))
    } else {
      updateRefreshGroup([...refreshGroupSites, ...siteList.filter((s) => s.name.toLowerCase().includes(siteFilter.toLowerCase()) && !refreshGroupSites.find((rs) => rs.id === s.id))])
    }
  }

  return (
    <RefreshGroupComboSearch
      overlapIds={overlap.tagIds}
      onAllCheckboxToggle={onSelectAll}
      onCheckboxToggle={(index) => onToggle({ name: options[index].label, id: options[index].value })}
      options={options}
      onSearch={setSiteFilter}
    />
  )
}
