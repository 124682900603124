import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Color, ColorUtil, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius, Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background-color: ${ColorUtil.hexOpacity(Colors.Jet, 0.2)};
  transition: background ${Transitions.quick};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`

const Title = styled.span`
  ${Fonts.P1}
  font-weight: bold;
`
const Detail = styled.span`
  ${Fonts.P3}
`

const Wrapper = styled.div<{ disabled: boolean; selected: boolean; selectedIconColor: Color }>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 12px;
  min-width: min-content;
  ${BorderRadius.style}
  border: 1px solid ${Colors.Platinum};
  cursor: pointer;
  ${Fonts.colors.Jet}
  user-select: none;

  transition: border ${Transitions.quick};

  ${({ disabled }) =>
    disabled &&
    css`
      ${Fonts.colors.Silver}
      border-color: transparent;
      background-color: ${ColorUtil.hexOpacity(Colors.Platinum, 0.2)};
      & ${IconCircle} {
        background-color: ${ColorUtil.hexOpacity(Colors.Jet, 0.07)};
      }
    `}

  :hover {
    ${({ selected, disabled, selectedIconColor }) =>
      !disabled
        ? css`
            border-color: ${selected ? Colors.Navy : Colors.Silver};
            & ${IconCircle} {
              background-color: ${selectedIconColor};
            }
          `
        : css`
            cursor: not-allowed;
          `}
  }

  ${({ selected, selectedIconColor }) =>
    selected &&
    css`
      border-color: ${Colors.King};
      & ${IconCircle} {
        background-color: ${selectedIconColor};
      }
    `}
`

type SelectorProps = WithClassName & { onClick: () => unknown; disabled?: boolean; selected?: boolean; icon: IconName; title: string; detail?: string; selectedColor?: Color }
const _Selector: FunctionComponent<SelectorProps> = ({ className, onClick, disabled, selected, title, detail, icon, selectedColor }) => {
  return (
    <Wrapper onClick={() => !disabled && onClick()} className={className} disabled={!!disabled} selected={!!selected} selectedIconColor={selectedColor ?? Colors.Success}>
      <IconCircle>
        <Icon name={icon} width={'22px'} fill={Colors.White} />
      </IconCircle>
      <Content>
        <Title>{title}</Title>
        <Detail>{detail}</Detail>
      </Content>
    </Wrapper>
  )
}

export const Selector = styled(_Selector)``
