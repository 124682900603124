type ToggleValueInArrayOverload = {
  <T extends object>(array: T[], value: T, comparisonProperty: keyof T): T[]
  <T extends string | number | boolean>(array: T[], value: T): T[]
}
/**
 * toggle value in/out of an array based on scalar value or a unique object's property to compare
 * @param array the array of values
 * @param value the value to toggle
 * @param [comparisonProperty=undefined] use in case of array of objects, unique comparison property to identify the value
 */
export const toggleValueInArray: ToggleValueInArrayOverload = <T>(array: T[], value: T, comparisonProperty?: keyof T) => {
  if (comparisonProperty) {
    return array.find((o) => value[comparisonProperty] === o[comparisonProperty]) ? array.filter((o) => value[comparisonProperty] !== o[comparisonProperty]) : [...array, value]
  }
  return array.find((v) => value === v) ? array.filter((v) => value !== v) : [...array, value]
}

/**
 * Check that two arrays contains the same elements
 */
export const areArraysEqual = <T extends string | number | boolean>(a: T[], b: T[]): boolean => a.length === b.length && a.every((element, index) => element === b[index])
