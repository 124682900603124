import styled from '@emotion/styled'
import { ModuleRTDNames, PrebidModuleNames, Provider, REAL_TIME_DATA_MODULES_CONFIG } from '@pubstack/common/src/catalogItem'
import { IdModuleNames, UserSyncUserId } from '@pubstack/common/src/userSync'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { Skeleton } from '~/components/Skeleton'
import { Status } from '~/components/Status'
import { Toggle } from '~/components/Toggle'
import { JSONEditorStatus, PureAdStackPrebidModuleJSONEditor } from '~/modules/adstack/inventory/modules/PureAdStackPrebidModuleJSONEditor'
import { JSON_TEMPLATES } from '~/modules/adstack/inventory/modules/PureAdStackPrebidModulePage'
import { WithClassName } from '~/types/utils'

const ModulesWrapper = styled.div``

const ModulePanel = styled(CollapsiblePanel)`
  margin-bottom: 20px;
`
const Title = styled.div`
  width: 30%;
`
const ModuleStatus = styled(Status)`
  width: 30%;
`
const SubTitle = styled.span`
  color: ${Colors.Jet};
  ${Fonts.H2}
  font-weight: 500;
`
const JSONEditor = styled(PureAdStackPrebidModuleJSONEditor)`
  width: fill-available;
`
const SearchBar = styled.form`
  margin-bottom: 12px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  gap: 12px;
  ${Button} {
    margin-top: 4px;
  }
`
const NoModules = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  h1 {
    ${Fonts.H1}
    font-weight: 400;
    margin-bottom: 12px;
  }
  p {
    ${Fonts.P1}
  }
`

export type DataBySite<ModuleName extends PrebidModuleNames> = {
  code: PrebidModuleNames
  moduleData: (ModuleName extends IdModuleNames ? Omit<UserSyncUserId<ModuleName>, 'name'> : Omit<Provider<ModuleName>, 'name'>) | undefined
}

type PureModuleBySiteProps = WithClassName & {
  isLoading: boolean
  modules: DataBySite<PrebidModuleNames>[]
  updateSiteData: (data: DataBySite<PrebidModuleNames>) => void
}

function isRTDModule(module: DataBySite<PrebidModuleNames>): module is DataBySite<ModuleRTDNames> {
  return REAL_TIME_DATA_MODULES_CONFIG.some((rtd) => rtd.name === module.code)
}

const _PureModulesBySite: FunctionComponent<PureModuleBySiteProps> = ({ isLoading, modules, updateSiteData }) => {
  // track errors or pending changes on each site settings
  const [siteSettingsStatus, setSiteSettingsStatus] = useState<{ [name in DataBySite<PrebidModuleNames>['code']]?: JSONEditorStatus }>(Object.fromEntries(modules.map((id) => [id.code, {}])))
  const sortedModules = [...modules].sort((a, b) => a.code.localeCompare(b.code))
  const {
    resetField,
    control,
    formState: { isDirty },
  } = useForm<{ search: string; status: string }>({
    defaultValues: {
      search: '',
      status: 'allModules',
    },
  })
  const { search, status } = useWatch({ control })
  const searchedModules = sortedModules.filter((m) => !search || m.code.toLowerCase().includes(search.toLowerCase()))
  const filteredModules = status === 'usedModules' ? searchedModules.filter((m) => m.moduleData) : status === 'notUsedModules' ? searchedModules.filter((m) => !m.moduleData) : searchedModules
  return (
    <ModulesWrapper>
      <SearchBar>
        <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
        <Select
          control={control}
          name={'status'}
          options={[
            { label: 'All modules', value: 'allModules' },
            { label: 'Used', value: 'usedModules' },
            { label: 'Not used', value: 'notUsedModules' },
          ]}
          label={'Status'}
        />

        {isDirty && (
          <Button
            iconName={'close'}
            variant={'tertiary'}
            onClick={() => {
              resetField('search')
              resetField('status')
            }}
          />
        )}
      </SearchBar>
      {!isLoading ? (
        filteredModules.length > 0 ? (
          filteredModules.map((module, index) => (
            <ModulePanel
              title={
                <>
                  <Title>{module.code}</Title>
                  <ModuleStatus state={module.moduleData ? 'active' : 'inactive'}>{module.moduleData ? 'Used' : 'Not used'}</ModuleStatus>
                  {isRTDModule(module) && (
                    // TODO type guard doesn't work here, type is not narrowed to DataBySite<ModuleRTDNames>
                    <>
                      <Toggle
                        value={(module as DataBySite<ModuleRTDNames>).moduleData?.waitForIt}
                        disabled={!module.moduleData?.params}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          const data = module.moduleData as Omit<Provider<ModuleRTDNames>, 'name'>
                          data.waitForIt = !data?.waitForIt
                          updateSiteData({
                            code: module.code,
                            moduleData: data as DataBySite<ModuleRTDNames>['moduleData'],
                          })
                        }}
                      />
                      <span>Wait for it</span>
                    </>
                  )}
                </>
              }
              key={index}
              isCollapsible={true}
              hasBorderWhenOpened={true}
            >
              <SubTitle>Settings</SubTitle>
              <JSONEditor
                statusUpdate={(updatedStatus) =>
                  setSiteSettingsStatus({
                    ...siteSettingsStatus,
                    [module.code]: updatedStatus,
                  })
                }
                params={module.moduleData ?? JSON_TEMPLATES[module.code]}
                onValidate={(data) => {
                  updateSiteData({
                    code: module.code,
                    moduleData: data as DataBySite<PrebidModuleNames>['moduleData'],
                  })
                }}
              />
            </ModulePanel>
          ))
        ) : (
          <NoModules>
            <Icon name={'night_sky'} width={'190px'} />
            <div>
              <h1>No modules.</h1>
            </div>
          </NoModules>
        )
      ) : (
        [0, 1, 2].map((e) => <ModulePanel key={e} forceState={'closed'} hasBorderWhenOpened isCollapsible={true} title={<Skeleton width={`15ch`} />} />)
      )}
    </ModulesWrapper>
  )
}

export const PureModulesBySite = styled(_PureModulesBySite)``
