import { CUSTOM_AD_FORMAT } from '@pubstack/common/src/adFormat'
import { AdUnit, getAdUnitMediatypes } from '@pubstack/common/src/adunit'
import { SiteStacksByProperty } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAdUnits, useSitesStacks } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { downloadCSVdata } from '~/utils/csv'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackAdUnitsPage } from './PureAdStackAdUnitsPage'

export const AdStackAdUnitsPage: React.FunctionComponent = () => {
  const logger = useLogger()
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const [adUnits, setAdUnits] = useState<AdUnit[]>([])
  const { byId, all } = useAdUnits(null)
  const [sitesStacks, setSitesStacks] = useState<SiteStacksByProperty>({})
  const { all: allStacks } = useSitesStacks(null, '')
  const toast = useToast()

  const loadAdUnitPageData = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setAdUnits(result)
  }

  const loadSitesStacks = async () => {
    if (allStacks.loading) {
      allStacks.abort()
    }
    const result = await allStacks.fetchSiteStacks()
    setSitesStacks(result)
  }

  const sendLogs = async () => {
    await logger.info({ action: 'click', type: 'detailed-view-flyout', actionName: 'adUnit', from: 'adunits-page' })
  }

  const archiveAdUnit = async (adUnit: AdUnit) => {
    try {
      await byId.put({ ...adUnit, enabled: !adUnit.enabled })
      toast.success(`The ad unit ${adUnit.name} has been ${!adUnit.enabled ? 'un' : ''}archived successfully.`)
      loadAdUnitPageData()
    } catch (e) {
      toast.alert('An error occurred while trying to archive the ad unit.')
    }
  }

  const exportAdUnits = async () => {
    const convertBoolean = (bool?: boolean): string => (bool ? 'enabled' : 'disabled')
    const convertArray = (array: any): string => array.join(' | ')
    const keyValuesHeader: string[] = [...Array(5).keys()].map((i) => `Key-value ${i + 1}`)
    const header = [
      'Name',
      'Archived',
      'Ad unit name in the ad server',
      'Technical identification (div ID)',
      'Devices',
      'Format',
      'Dynamic',
      'Parallax',
      'Max size width',
      'Max size height',
      'Header bidding',
      'Banner',
      'Fluid size',
      'Standard sizes',
      'Custom sizes',
      'Outstream',
      keyValuesHeader,
    ]

    const displayAdUnit = (adUnit: AdUnit): string => {
      const mediatypes = getAdUnitMediatypes(adUnit)
      return [
        adUnit.name,
        adUnit.enabled ? 'no' : 'yes',
        adUnit.adServerAdUnitName,
        adUnit.divId,
        convertArray(adUnit.devices.sort()),
        adUnit.adFormat?.name ?? CUSTOM_AD_FORMAT.name,
        convertBoolean(adUnit.dynamicEnabled),
        convertBoolean(adUnit.parallaxEnabled),
        adUnit.containerMaxSize?.width && `${adUnit.containerMaxSize?.width}px`,
        adUnit.containerMaxSize?.height && `${adUnit.containerMaxSize?.height}px`,
        convertBoolean(adUnit.headerBiddingEnabled),
        convertBoolean(mediatypes.includes('Banner')),
        convertBoolean(adUnit.fluid),
        convertArray(adUnit.sizes),
        convertArray(adUnit.customSizes),
        convertBoolean(mediatypes.includes('Outstream')),
        adUnit.slotKeyValues?.map(({ key, values }) => `${key} = ${convertArray(values)}`).join(','),
      ].join(',')
    }

    const enableAdUnits = adUnits.filter((adUnit) => adUnit.enabled).sort((a, b) => a.name.localeCompare(b.name))
    const archivedAdUnits = adUnits.filter((adUnit) => !adUnit.enabled).sort((a, b) => a.name.localeCompare(b.name))

    const csvContent = [header.join(','), [...enableAdUnits, ...archivedAdUnits].map((adUnit: AdUnit) => displayAdUnit(adUnit))].flat().join('\n')
    downloadCSVdata(csvContent, 'adUnits-export')
  }

  useEffect(() => {
    loadAdUnitPageData()
    loadSitesStacks()
  }, [])

  return (
    <PureAdStackAdUnitsPage
      adUnits={adUnits}
      sitesStacks={sitesStacks}
      isLoading={all.loading}
      onCreateAdUnit={() => navigate(`/adstack/adunits/new`)}
      onUpdateAdUnit={(adUnitId) => navigate(`/adstack/adunits/edit/${adUnitId}`)}
      onArchiveAdUnit={(adUnit) => archiveAdUnit(adUnit)}
      onExportAdUnits={exportAdUnits}
      onOpenFlyout={sendLogs}
      breadcrumbs={breadcrumbs}
    />
  )
}
