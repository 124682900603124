import styled from '@emotion/styled'
import { animated, useSpring } from '@react-spring/web'
import BezierEasing from 'bezier-easing'
import { FunctionComponent } from 'react'
import { Color, ColorUtil, Colors } from '~/assets/style/colors'
import { WithClassName } from '~/types/utils'

const easeInOut = BezierEasing(0.42, 0, 0.58, 1)
const ease = BezierEasing(0.25, 0.1, 0.25, 1)
const linear = BezierEasing(0, 0, 1, 1)
const easeIn = BezierEasing(0.42, 0, 1, 1)
const easeOut = BezierEasing(0, 0, 0.58, 1)

const getStep = (t: number) => {
  if (t === 0) return [0, 0.2, 0, 0]
  if (t <= 0.2) return [0, 0.2, 1, 0]
  if (t <= 0.5) return [0.2, 0.5, 2, 1]
  return [0.5, 0.8, 3, 2]
}

const apply = (initial: number, next: number, t: number) => (initial + (next - initial) * t).toFixed(3)

const Line = styled(animated.line)`
  transform-origin: 50% 50%;
  animation-timing-function: ease-in-out;
  stroke-dasharray: 3.225 100;
  stroke-linecap: round;
  animation-fill-mode: both;
`

const petals: Pick<PetalProps, 'x1' | 'x2' | 'color' | 'strokeWidth' | 'transform' | 'dasharray' | 'colors'>[] = [
  {
    dasharray: [3.225, 2, 7.87, 7.87],
    transform: [
      { x: 3.88, y: 1.9, deg: 45 },
      { x: 7.25, y: 5, deg: 135 },
      { x: 9.71, y: 2.625, deg: 180 },
      { x: 9.71, y: 2.625, deg: 180 },
    ],
    strokeWidth: 2.735,
    x1: 1.225,
    x2: -10,
    color: '#50a9df',
    colors: ['#50a9df', '#50a9df', '#afcc46', Colors.Jet],
  },
  {
    dasharray: [3.27, 2, 3.05, 5.05],
    transform: [
      { x: 10.884, y: -1.863, deg: -45 },
      { x: 5.88, y: -6, deg: 60 },
      { x: 4.84, y: 0, deg: 180 },
      { x: 6.84, y: 0, deg: 180 },
    ],
    strokeWidth: 2.732,
    x1: 1.275,
    x2: -4,
    color: '#afcc46',
    colors: ['#afcc46', '#afcc46', '#8b6aac', Colors.Jet],
  },
  {
    dasharray: [3.225, 2, 7.87, 7.87],
    transform: [
      { x: 10.879, y: -1.886, deg: 45 },
      { x: 7.25, y: -5.9, deg: 135 },
      { x: 9.68, y: -2.62, deg: 180 },
      { x: 9.68, y: -2.62, deg: 180 },
    ],
    strokeWidth: 2.731,
    x1: 1.258,
    x2: -9.97,
    color: '#8b6aac',
    colors: ['#8b6aac', '#8b6aac', '#1db19d', Colors.Jet],
  },
  {
    dasharray: [3.213, 2, 3.06, 5.06],
    transform: [
      { x: 3.841, y: 1.925, deg: -44.85 },
      { x: 7.5, y: 5.9, deg: 60 },
      { x: 9.675, y: 0, deg: 180 },
      { x: 9.675, y: 0, deg: 180 },
    ],
    strokeWidth: 2.737,
    x1: 1.265,
    x2: -3.95,
    color: '#1db19d',
    colors: ['#1db19d', '#1db19d', '#50a9df', Colors.Jet],
  },
]

type PetalProps = {
  isHover?: boolean
  x1: number
  x2: number
  color: string
  strokeWidth: number
  transform: { x: number; y: number; deg: number }[]
  dasharray: number[]
  colors: Color[]
}
const Petal: FunctionComponent<PetalProps> = ({ isHover, dasharray, strokeWidth, transform, x1, x2, color, colors }) => {
  const spring = useSpring({
    config: { duration: 800 },
    from: { x: 0, stroke: color },
    x: isHover ? 0.8 : 0,
    stroke: isHover ? 'currentColor' : color,
  })

  const stroke = [strokeWidth, 1.68, 0.87, 0.87]

  const prepare = (t: number) => {
    const [i1, i2, step, previousStep] = getStep(t)
    const n = 1 - Math.max(0, (i2 - t) / (i2 - i1))
    const value = easeInOut(n)
    return { step, previousStep, value }
  }

  return (
    <Line
      x1={x1}
      x2={x2}
      y1={7}
      y2={7}
      stroke={color}
      stroke-linecap={'round'}
      stroke-width={strokeWidth}
      style={{
        transform: spring.x.to([0, 0.2, 0.5, 0.8], [0, 0.2, 0.5, 0.8]).to((t) => {
          const { step, previousStep, value } = prepare(t)
          const deg = apply(transform[previousStep].deg, transform[step].deg, value)
          const x = apply(transform[previousStep].x, transform[step].x, value)
          const y = apply(transform[previousStep].y, transform[step].y, value)
          return `rotate(${deg}deg) translate(${x}px, ${y}px)`
        }),
        strokeWidth: spring.x.to([0, 0.2, 0.5, 0.8], [0, 0.2, 0.5, 0.8]).to((t) => {
          const { step, previousStep, value } = prepare(t)
          return apply(stroke[previousStep], stroke[step], value)
        }),
        strokeDasharray: spring.x.to([0, 0.2, 0.5, 0.8], [0, 0.2, 0.5, 0.8]).to((t) => {
          const { step, previousStep, value } = prepare(t)
          return `${apply(dasharray[previousStep], dasharray[step], value)} 100`
        }),
        stroke: spring.x.to([0, 0.2, 0.5, 0.8], [0, 0.2, 0.5, 0.8]).to((t) => {
          const { step, previousStep, value } = prepare(t)
          const initialColor = ColorUtil.hexToRgb(colors[previousStep])
          const currentColor = ColorUtil.hexToRgb(colors[step])
          const red = Number(apply(initialColor.red, currentColor.red, value))
          const green = Number(apply(initialColor.green, currentColor.green, value))
          const blue = Number(apply(initialColor.blue, currentColor.blue, value))
          return ColorUtil.rgbToHex({ red, green, blue })
        }),
      }}
    />
  )
}

const _LogoMenuIcon: FunctionComponent<WithClassName & { isHover?: boolean }> = ({ className, isHover }) => (
  <svg viewBox={'0 0 14 14'} className={className}>
    {petals.map((petal, i) => (
      <Petal key={i} isHover={isHover} {...petal} />
    ))}
  </svg>
)

export const LogoMenuIcon = styled(_LogoMenuIcon)``
