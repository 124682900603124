import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Color } from '~/assets/style/colors'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div<{ color: Color }>`
  --wizard-indicator-color: ${({ color }) => color};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
const Step = styled.div<{ isFull: boolean }>`
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  box-sizing: border-box;
  position: relative;

  * + & {
    ::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 2px;
      top: calc(50% - 1px);
      left: ${({ isFull }) => (isFull ? '-12px' : '-13px')};
      background-color: var(--wizard-indicator-color);
    }
  }

  ${({ isFull }) =>
    isFull
      ? css`
          background-color: var(--wizard-indicator-color);
        `
      : css`
          border: 2px solid var(--wizard-indicator-color);
        `}
`

type StepperIndicatorProps = WithClassName & {
  steps: number
  currentStep: number
  color: Color
}
const _StepperIndicator: FunctionComponent<StepperIndicatorProps> = ({ className, currentStep, steps, color }) => (
  <Wrapper className={className} color={color}>
    {new Array(steps).fill(undefined).map((_, index) => (
      <Step key={index} isFull={index < currentStep} />
    ))}
  </Wrapper>
)
export const StepperIndicator = styled(_StepperIndicator)``
