import styled from '@emotion/styled'
import { Controller, FieldPath, FieldValues, PathValue, UseControllerProps } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'

const JsonTextArea = styled.textarea`
  ${BorderRadius.style};
  outline: none;
  resize: none;
  border: 1px solid ${Colors.Platinum};
  white-space: pre;
  min-height: 200px;
  width: 100%;
  background-color: ${Colors.White};
  ${Fonts.Mono};
  ${Fonts.colors.Jet};
  resize: vertical;

  padding: 8px;

  &:active,
  &:focus {
    border-color: ${Colors.Silver};
  }
`

const DEFAULT_JSON_INDENTATION_SIZE = 2

export type JSONEditorStatus = {
  error?: boolean
  dirty?: boolean
}

export const formatJSON = (value: string, indentationSize: number = 2): string => {
  try {
    return JSON.stringify(JSON.parse(value), null, indentationSize)
  } catch (e) {
    return value
  }
}

const DEFAULT_JSON_SYNTAX_VALIDATION = (formValue: string) => {
  try {
    const parseResult = JSON.parse(formValue)
    if (parseResult) {
      return true
    }
  } catch (e) {
    if (e instanceof SyntaxError) {
      return e.message
    }
    return 'JSON validation error'
  }
  return 'JSON validation error'
}
type JSONEditorInputProps<T extends FieldValues, U extends FieldPath<T>> = WithClassName & {
  indentationSize?: number
  jsonSyntaxValidation?: (v: PathValue<T, U>) => string | boolean
} & UseControllerProps<T, U>
const _JSONEditor = <T extends { jsonValue: string }, U extends FieldPath<T>>({
  name,
  control,
  rules,
  shouldUnregister,
  jsonSyntaxValidation = DEFAULT_JSON_SYNTAX_VALIDATION,
  indentationSize = DEFAULT_JSON_INDENTATION_SIZE,
  ...props
}: JSONEditorInputProps<T, U>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: {
          jsonSyntax: jsonSyntaxValidation,
          ...rules?.validate,
        },
        ...rules,
      }}
      shouldUnregister={shouldUnregister}
      render={({ field: { ref, value, onChange, onBlur, ...field } }) => {
        return (
          <JsonTextArea
            {...props}
            {...field}
            ref={ref}
            spellCheck={false}
            value={value}
            onBlur={(v) => {
              !control?.getFieldState(name).invalid && onChange(formatJSON(v.target.value, indentationSize))
              onBlur()
            }}
            onChange={(v) => {
              !control?.getFieldState(name).invalid ? onChange(v.target.value) : onChange(formatJSON(v.target.value, indentationSize))
            }}
            rows={(value?.match(/}|:/g) || []).length}
          />
        )
      }}
    />
  )
}

export const JSONEditorInput = styled(_JSONEditor)``
