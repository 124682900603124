import { MappedName } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import * as ReactDOMServer from 'react-dom/server'
import { Color } from '~/assets/style/colors'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'
import { WithClassName } from '~/types/utils'
import { displayWithCurrency } from '~/utils/string'

export type ChartTooltipProps = WithClassName & {
  name: MappedName
  currencySymbol: CurrencySymbol
  allImpressionRevenue: {
    name: string
    displayable: string
    percentage: string
  }
  details: {
    name: string
    color: Color
    displayable: string
    percentage: string
  }[]
}

export const getChartTooltip = ({ allImpressionRevenue, name, currencySymbol, details }: Omit<ChartTooltipProps, 'className'>) => {
  const metrics: ChartTooltipMetricProps[][] = [
    details.map((dc) => ({
      label: dc.name,
      value: displayWithCurrency(dc.displayable, currencySymbol),
      iconColor: dc.color,
    })),
    [
      {
        label: allImpressionRevenue.name,
        value: displayWithCurrency(allImpressionRevenue.displayable, currencySymbol),
        iconName: 'sigma',
      },
      {
        label: 'Overall revenue share',
        value: allImpressionRevenue.percentage,
        iconName: 'percent',
        secondary: true,
      },
    ],
  ]
  return ReactDOMServer.renderToString(<ChartTooltip date={name.label} metrics={metrics} />)
}
