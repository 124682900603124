import styled from '@emotion/styled'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Input } from '~/components/Input'
import { Toggle } from '~/components/Toggle'
import { WidgetMessage } from '~/components/WidgetMessage'

const CustomSettingsToggle = styled.div`
  display: inline-flex;
  gap: 8px;
  align-items: center;

  & > label {
    user-select: none;
    cursor: pointer;
  }
`

const PopulationSplitWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
`

type RefreshGroupCustomSettings = { preventCLS: boolean; blockerEnabled: boolean; populationSplit?: number }
export const RefreshGroupCustomSettingsForm: FunctionComponent<{
  customSettings: RefreshGroupCustomSettings
  updateRefreshGroup: (settings: RefreshGroupCustomSettings) => unknown
}> = ({ customSettings, updateRefreshGroup }) => {
  const {
    control,
    formState: { errors },
    trigger,
  } = useForm({ defaultValues: { populationSplit: customSettings.populationSplit ?? 50 } })

  const [isPreventLayoutShift, setIsPreventLayoutShift] = useState<boolean>(customSettings.preventCLS)
  const [isABTestEnabled, setIsABTestEnabled] = useState<boolean>(!!customSettings.populationSplit)
  const [isBlockerEnabled, setIsBlockerEnabled] = useState<boolean>(customSettings.blockerEnabled)

  const populationSplit = useWatch({ control, name: 'populationSplit' })
  useEffect(() => {
    trigger('populationSplit')
    updateRefreshGroup({ preventCLS: isPreventLayoutShift, blockerEnabled: isBlockerEnabled, populationSplit: isABTestEnabled ? populationSplit : undefined })
  }, [populationSplit, isPreventLayoutShift, isBlockerEnabled, isABTestEnabled])

  const toggleCLS = () => setIsPreventLayoutShift(!isPreventLayoutShift)
  const toggleABTest = () => setIsABTestEnabled(!isABTestEnabled)
  const toggleBlocker = () => setIsBlockerEnabled(!isBlockerEnabled)
  return (
    <>
      <CustomSettingsToggle>
        <Toggle id={'toggle-cls'} onClick={toggleCLS} value={isPreventLayoutShift} />
        <label htmlFor={'toggle-cls'}>Prevent layout shift</label>
      </CustomSettingsToggle>
      <CustomSettingsToggle>
        <Toggle id={'toggle-abtest'} onClick={toggleABTest} value={isABTestEnabled} />
        <label htmlFor={'toggle-abtest'}>Launch A/B test</label>
      </CustomSettingsToggle>
      {isABTestEnabled && (
        <>
          <WidgetMessage
            message={"Before launching an AB test, make sure the key-value targetings are defined at page level (and not at slot level). We won't be able to do refresh otherwise."}
            icon={'tips'}
          />
          <PopulationSplitWrapper>
            Population split
            <Input
              error={errors.populationSplit?.message}
              type={'number'}
              min={1}
              max={100}
              rules={{
                required: { value: isABTestEnabled, message: 'Please select a value between 1 and 100%' },
                min: { value: 1, message: 'Please select a value between 1 and 100%' },
                max: { value: 100, message: 'Please select a value between 1 and 100%' },
              }}
              control={control}
              name={'populationSplit'}
            />
            %
          </PopulationSplitWrapper>
          <CustomSettingsToggle>
            <Toggle id={'toggle-blocker'} value={isBlockerEnabled} onClick={toggleBlocker} />
            <label htmlFor={'toggle-blocker'}>Block all refreshes not triggered by Pubstack on the test population.</label>
          </CustomSettingsToggle>
        </>
      )}
    </>
  )
}
