import styled from '@emotion/styled'
import { DisplayedAdUnit, getAdUnitMediatypes } from '@pubstack/common/src/adunit'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Qualifier } from '~/components/Qualifier'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

const Card = styled.div<{ isError: boolean }>`
  background-color: ${Colors.White};
  border: 1px solid ${({ isError }) => (isError ? Colors.Alert : Colors.Platinum)};
  padding: 10px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Name = styled.div`
  ${Fonts.P1};
  ${Fonts.colors.Jet};
  font-weight: bold;
  flex: 1;
  word-break: break-all;
  span {
    ${Fonts.colors.Ash};
  }
`

const CloseButton = styled(Button)`
  margin: -2px 0;
`

const AdServerName = styled.div`
  ${Fonts.P3};
  ${Fonts.colors.Jet};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DivId = styled.div`
  ${Fonts.P3};
  ${Fonts.colors.Jet};
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Qualifiers = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 3px;
  flex: 1;
  align-items: flex-end;
`

const Footer = styled.div`
  border-top: 1px solid ${Colors.Platinum};
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding-top: 12px;
  gap: 4px;
  height: 38px;
`

export type AdUnitCardProps = WithClassName & {
  adUnit: DisplayedAdUnit
  isError?: boolean
  isArchived?: boolean
  onClose: () => void
  roadblock: { isRoadblock: boolean; onToggleClick: () => void; isMaster: boolean; onMasterIconClick: () => void }
}

const getTooltip = (state: boolean) => {
  return state ? 'allowed' : 'disabled'
}

const _AdUnitCard: FunctionComponent<AdUnitCardProps> = ({ className, adUnit, onClose, isError, isArchived, roadblock }) => {
  const adUnitMediatypes = getAdUnitMediatypes(adUnit)
  return (
    <Card className={className} isError={isError ?? false}>
      <Header>
        <Name title={adUnit.name}>
          {adUnit.name} <span>{isArchived && '(archived)'}</span>
        </Name>
        <Tooltip title={'Remove ad unit'}>
          <CloseButton variant={'tertiary'} iconName={'close'} iconSize={Sizes['18']} onClick={onClose} />
        </Tooltip>
      </Header>
      <AdServerName>
        name in ad server: <span title={adUnit.adServerAdUnitName}>{adUnit.adServerAdUnitName}</span>
      </AdServerName>
      <DivId>
        div ID: <span title={adUnit.divId}>{adUnit.divId}</span>
      </DivId>
      <Qualifiers>
        <Qualifier iconName={'header_bidding'} tooltipText={`Header bidding ${getTooltip(adUnit.headerBiddingEnabled)}`} active={adUnit.headerBiddingEnabled} enabled={true} />
        <Qualifier iconName={'dynamic'} tooltipText={`Dynamic ${getTooltip(adUnit.dynamicEnabled)}`} active={adUnit.dynamicEnabled} enabled={true} />
        <Qualifier iconName={'banner'} tooltipText={`Banner ${getTooltip(adUnitMediatypes.includes('Banner'))}`} active={adUnitMediatypes.includes('Banner')} enabled={true} />
        <Qualifier iconName={'outstream2'} tooltipText={`Outstream ${getTooltip(adUnitMediatypes.includes('Outstream'))}`} active={adUnitMediatypes.includes('Outstream')} enabled={true} />
      </Qualifiers>
      <Footer>
        <Toggle value={roadblock.isRoadblock} onClick={roadblock.onToggleClick} />
        <span>Roadblock</span>
        {roadblock.isRoadblock && (
          <Tooltip title={'Define as Master the ad unit'}>
            <Button variant={'tertiary'} iconName={roadblock.isMaster ? 'star' : 'star_empty'} onClick={roadblock.onMasterIconClick} />
          </Tooltip>
        )}
      </Footer>
    </Card>
  )
}

export const AdUnitCard = styled(_AdUnitCard)``
