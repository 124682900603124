import { Res, useFetch } from 'use-http'

export type ApiResponse<T> = { data: T | undefined; error: Error | undefined; loading: boolean }

export class FetchError<TDATA> extends Error {
  public response: Res<TDATA>
  public constructor(response: Res<TDATA>) {
    super(JSON.stringify(response))
    this.response = response
  }
}

// TODO 2022-10-22 tmu nra should we use this everywhere to replace useFecth (+ ADR to use hooks with try/catch) ?
export const useFetchWithException = <T>(...args: Parameters<typeof useFetch>) => {
  const fetch = useFetch<T>(...args)

  const wrap =
    <W extends 'get' | 'post' | 'put' | 'delete'>(fn: (...args: Parameters<(typeof fetch)[W]>) => Promise<T>) =>
    async (...args: Parameters<typeof fn>) => {
      const data = await fn(...args)
      if (!fetch.response.ok) {
        throw new FetchError(fetch.response)
      }
      return data
    }

  const post = wrap<'post'>(fetch.post)
  const get = wrap<'get'>(fetch.get)
  const put = wrap<'put'>(fetch.put)
  const del = wrap<'delete'>(fetch.delete)
  return { ...fetch, post, get, put, del, delete: del }
}
