import { FunctionComponent, useEffect } from 'react'
import { useHubspotChatToken } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'

const hubspotChatSrc = '//js-na1.hs-scripts.com/4565186.js'
export const HubspotChat: FunctionComponent = () => {
  const { byId: createHubspotToken } = useHubspotChatToken(null)
  const user = useUser()
  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    const script = document.createElement('script')
    script.src = hubspotChatSrc
    script.async = true
    script.id = 'hs-script-loader'
    document.body.appendChild(script)
    window._hsq = window._hsq || []
    window._hsq.push([
      'identify',
      {
        email: user?.profile.email,
      },
    ])
    const getToken = async () => {
      const hubspotToken = await createHubspotToken.post({ email: user?.profile.email })
      window.hsConversationsSettings = {
        identificationEmail: user?.profile.email,
        identificationToken: hubspotToken.token,
      }
      window.HubSpotConversations.widget.load()
    }

    getToken()

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}
