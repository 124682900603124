import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { PrebidConfig } from '@pubstack/common/src/prebidConfig'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius, Sizes } from '~/assets/style/tokens'
import { AdStackIntegrationLogo } from '~/components/AdStackIntegrationLogo'
import { Breadcrumbs } from '~/components/Breadcrumbs'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Skeleton } from '~/components/Skeleton'
import { Status } from '~/components/Status'
import Table, { handleTableSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import { TableCollapsibleRow, TableCollapsibleRowChevronColumn } from '~/components/table/TableCollapsibleRow'
import { WithClassName } from '~/types/utils'
import { PureAdStackPrebidSiteConfigForm } from './PureAdStackPrebidSiteConfigForm'

const ModuleInformation = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  & > div {
    ${BorderRadius.style}
  }
`

const ModuleLogo = styled.div`
  background-color: ${Colors.White};
  padding: 45px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    align-self: center;
    max-height: 35px;
    max-width: 190px;
  }
`

const ModuleDetail = styled.div`
  background-color: ${Colors.White};
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

const ModuleDetailTitle = styled.span`
  ${Fonts.H2};
  display: inline-flex;
  align-items: center;
  gap: 4px;
`
const ModuleDetailContent = styled.div`
  display: inline-flex;
  gap: 150px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
`

const ModuleLabel = styled.span`
  color: ${Colors.SlateGrey};
  ${Fonts.P2}
`
const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > ${HeaderActions} {
    margin-bottom: 20px;
  }
`
const Wrapper = styled.div`
  padding: 20px;

  & ${Breadcrumbs} {
    margin-bottom: 20px;
  }
`

const SiteGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`
const SiteTitle = styled(TableCell)`
  font-weight: bold;
`
const NoSites = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 28px;
  text-align: center;
  ${Fonts.P1}
  h1 {
    ${Fonts.H1}
    font-weight: 400;
  }
`
type PureAdStackPrebidIntegrationPageProps = WithClassName & {
  breadcrumbs: React.ReactNode
  isLoading: boolean
  prebidConfig: PrebidConfig
  onCreateSiteConfig: (siteConfig: SiteConfig) => Promise<void>
  baseCDNUrl: string
  bidderAdapters: BidderAdapter[]
  onBackClick: () => void
}

const _PureAdStackPrebidIntegrationPage: FunctionComponent<PureAdStackPrebidIntegrationPageProps> = ({
  className,
  breadcrumbs,
  isLoading,
  prebidConfig,
  onCreateSiteConfig,
  baseCDNUrl,
  bidderAdapters,
  onBackClick,
}) => {
  const [columns, setColumns] = useState<TableColumns<Site>>([
    {
      name: 'Site',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    TableCollapsibleRowChevronColumn,
  ])

  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({})

  const { reset } = useForm<PrebidConfig>({
    defaultValues: prebidConfig,
  })

  const sortedSites = handleTableSort(columns, prebidConfig.sites)

  useEffect(() => {
    reset({
      ...prebidConfig,
    })
  }, [prebidConfig])

  return (
    <Wrapper className={className}>
      <Header>
        {breadcrumbs}
        <Button variant={'tertiary'} onClick={onBackClick}>
          Back
        </Button>
      </Header>
      <ModuleInformation>
        <ModuleLogo>{isLoading ? <Skeleton bigger width={'195px'} /> : <AdStackIntegrationLogo baseCDNUrl={baseCDNUrl} name={'prebid'} />}</ModuleLogo>
        <ModuleDetail>
          <ModuleDetailTitle>
            <Icon name={'rocket'} width={Sizes['22']} /> Module detail
          </ModuleDetailTitle>
          <ModuleDetailContent>
            <div>
              <ModuleLabel>Integration type</ModuleLabel>
              <span>Prebid Wrapper</span>
            </div>
            <div>
              <ModuleLabel>Status</ModuleLabel>
              <div>{isLoading ? <Skeleton bigger width={'70px'} /> : <Status state={'active'}>Active</Status>}</div>
            </div>
          </ModuleDetailContent>
        </ModuleDetail>
      </ModuleInformation>
      <SiteGroup>
        {!isLoading ? (
          prebidConfig.sites.length > 0 ? (
            <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
              {sortedSites.map((site) => {
                const siteConfig = prebidConfig.siteConfigs.find((s) => site.id === s.siteId)
                return (
                  <TableCollapsibleRow
                    key={site.id}
                    isOpen={isOpen[site.id] ?? false}
                    onToggleCollapse={() => {
                      setIsOpen((previousState) => ({ ...previousState, [site.id]: !previousState[site.id] }))
                    }}
                    content={() => (
                      <PureAdStackPrebidSiteConfigForm
                        siteId={site.id}
                        siteConfig={siteConfig}
                        scopeId={prebidConfig.scopeId}
                        onCreateSiteConfig={(data) => {
                          onCreateSiteConfig(data)
                        }}
                        bidderAdapters={bidderAdapters}
                      />
                    )}
                  >
                    <SiteTitle>{site.name}</SiteTitle>
                  </TableCollapsibleRow>
                )
              })}
            </Table>
          ) : (
            <NoSites>
              <Icon name={'night_sky'} width={'190px'} />
              <h1>You don’t have any sites yet.</h1>
            </NoSites>
          )
        ) : (
          <Skeleton bigger width={'100%'} />
        )}
      </SiteGroup>
    </Wrapper>
  )
}

export const PureAdStackPrebidIntegrationPage = styled(_PureAdStackPrebidIntegrationPage)``
