import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { WithClassName } from '~/types/utils'
import { TimelineMetric, TimelineMetricProps } from './TimelineMetric'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > ${TimelineMetric} {
    margin-right: 16px;
    margin-bottom: 16px;
    :last-of-type {
      margin-right: 0;
    }
  }
`

type TimelineMetricsProps = WithClassName & {
  metrics: TimelineMetricProps[]
}
const _TimelineMetrics: FunctionComponent<TimelineMetricsProps> = ({ className, metrics }) => (
  <Wrapper className={className}>
    {metrics.map((metric, index) => (
      <TimelineMetric {...metric} key={index} />
    ))}
  </Wrapper>
)
export const TimelineMetrics = styled(_TimelineMetrics)``
