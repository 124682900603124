import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { WidgetMessage } from '~/components/WidgetMessage'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  margin-top: 20px;
`

type AnalyticsBreakdownLimitMessageProps = WithClassName & {
  count?: number
  limit?: number
  name: string
}
const _AnalyticsBreakdownLimitMessage: FunctionComponent<AnalyticsBreakdownLimitMessageProps> = ({ className, limit, name, count }) =>
  (count ?? 0) >= (limit ?? 50) && (
    <Wrapper>
      <WidgetMessage className={className} message={`Only the top ${limit ?? 50} ${name.toLowerCase()} are displayed.`} icon={'view'} />
    </Wrapper>
  )
export const AnalyticsBreakdownLimitMessage = styled(_AnalyticsBreakdownLimitMessage)``
