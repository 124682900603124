import styled from '@emotion/styled'
import { OverviewMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo } from 'react'
import Button from '~/components/Button'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { MetricConfiguration, TimelineConfiguration, useMetrics, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import {
  adxAuctionFillRate,
  adxAuctionRpm,
  adxECPM,
  adxImpressionCount,
  adxImpressionRevenue,
  allAuctionFillRate,
  allAuctionRpm,
  allECPM,
  allImpressionCount,
  allImpressionRevenue,
  auctionCount,
  hbAuctionFillRate,
  hbAuctionRpm,
  hbECPM,
  hbImpressionCount,
  hbImpressionRevenue,
  openBiddingAuctionFillRate,
  openBiddingAuctionRpm,
  openBiddingECPM,
  openBiddingImpressionCount,
  openBiddingImpressionRevenue,
} from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_CHART_COLOR_CONFIG, getComparisonDataLegendLabel } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { downloadCSVdata } from '~/utils/csv'
import { displayWithCurrency } from '~/utils/string'

const InfoContainer = styled.div`
  display: flex;
`

// TODO: tmu 2022-07-18 - refactor this ugly legacy code
const downloadCSV = (data: Partial<OverviewMetricsByTime>, timezone: Timezone) => {
  let csv = ''
  const columns = Object.keys(data) as (keyof OverviewMetricsByTime)[]
  csv += columns.join(',') + '\n'
  data?.epoch?.forEach((epoch, index) => {
    const row = columns.map((column) => (column === 'epoch' ? DateTime.fromISO(epoch, { zone: timezone }).toISO() : data?.[column]?.[index]))
    csv += row.join(',')
    csv += '\n'
  })

  downloadCSVdata(csv, 'overview')
}

const isHbComputable = (data: OverviewMetricsByTime) => hbImpressionRevenue.isComputable(data) && hbImpressionCount.isComputable(data)
const isAdxComputable = (data: OverviewMetricsByTime) => adxImpressionRevenue.isComputable(data) && adxImpressionCount.isComputable(data)
const isOpenBiddingComputable = (data: OverviewMetricsByTime) => openBiddingImpressionRevenue.isComputable(data) && openBiddingImpressionCount.isComputable(data)

const COMPARISON_CHART_OPTIONS = {
  type: 'line',
  lineWidth: 2,
  lineDashStyle: [7, 5],
  enableInteractivity: false,
  tooltip: 'none',
  color: ANALYTICS_CHART_COLOR_CONFIG['comparisonLine'].color,
}

const useConfig = (comparisonData: OverviewMetricsByTime) =>
  useMemo((): (MetricConfiguration<OverviewMetricsByTime> & TimelineConfiguration<OverviewMetricsByTime>)[] => {
    const baseComparisonConfig: Omit<MetricConfiguration<OverviewMetricsByTime>['dataConfig'][number], 'getFormula'> = {
      name: 'comparisonLine',
      label: getComparisonDataLegendLabel(comparisonData.epoch),
      getTooltipLabel: ({ comparisonData, index, dateFormat }) => (comparisonData ? DateTime.fromISO(comparisonData.epoch[index]).toFormat(dateFormat) : ''),
      iconName: 'data_line_dashed',
    }
    return [
      {
        getChartOptions: ({ currencySymbol, legendsConfig }) => {
          const comparisonLineIndex = legendsConfig.indexOf('comparisonLine')
          return {
            seriesType: 'bars',
            series: { ...(comparisonLineIndex >= 0 ? { [comparisonLineIndex]: COMPARISON_CHART_OPTIONS } : {}) },
            vAxis: {
              ...AnalyticsDefaultChartOptions.vAxis,
              format: displayWithCurrency('#.##', currencySymbol),
            },
          }
        },
        metric: {
          name: 'impressionRevenue',
          tooltipText: ANALYTICS_TOOLTIPS.REVENUE,
        },
        legendConfig: ['hb', 'adx', 'openBidding', 'comparisonLine'],
        tooltipConfig: [
          ['hb', 'adx', 'openBidding'],
          ['impressionRevenue', 'comparisonLine'],
        ],
        dataConfig: [
          {
            name: 'impressionRevenue',
            label: allImpressionRevenue.name,
            iconName: 'sigma',
            getFormula: ({ data }) => (allImpressionRevenue.isComputable(data) ? allImpressionRevenue : undefined),
          },
          {
            name: 'hb',
            isComputable: ({ data }) => isHbComputable(data),
            getFormula: () => hbImpressionRevenue,
          },
          {
            name: 'adx',
            isComputable: ({ data }) => isAdxComputable(data),
            getFormula: () => adxImpressionRevenue,
          },
          {
            name: 'openBidding',
            isComputable: ({ data }) => isOpenBiddingComputable(data),
            getFormula: () => openBiddingImpressionRevenue,
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ comparisonData }) => !!comparisonData?.epoch.length,
            getFormula: () => allImpressionRevenue,
          },
        ],
      },
      {
        getChartOptions: ({ legendsConfig }) => {
          const comparisonLineIndex = legendsConfig.indexOf('comparisonLine')
          return {
            seriesType: 'bars',
            series: { ...(comparisonLineIndex >= 0 ? { [comparisonLineIndex]: COMPARISON_CHART_OPTIONS } : {}) },
          }
        },
        metric: {
          name: 'auction',
          tooltipText: ANALYTICS_TOOLTIPS.AUCTION_COUNT,
        },
        legendConfig: ['auction', 'comparisonLine'],
        tooltipConfig: ['auction', 'comparisonLine'],
        dataConfig: [
          {
            name: 'auction',
            label: auctionCount.name,
            getFormula: ({ data }) => (auctionCount.isComputable(data) ? auctionCount : undefined),
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ comparisonData }) => !!comparisonData?.epoch.length,
            getFormula: () => auctionCount,
          },
        ],
      },
      {
        getChartOptions: ({ legendsConfig }) => {
          const comparisonLineIndex = legendsConfig.indexOf('comparisonLine')
          return {
            seriesType: 'bars',
            series: { ...(comparisonLineIndex >= 0 ? { [comparisonLineIndex]: COMPARISON_CHART_OPTIONS } : {}) },
            vAxis: {
              ...AnalyticsDefaultChartOptions.vAxis,
            },
          }
        },
        metric: {
          name: 'impressionCount',
        },
        legendConfig: ['hb', 'adx', 'openBidding', 'comparisonLine'],
        tooltipConfig: [
          ['hb', 'adx', 'openBidding'],
          ['impressionCount', 'comparisonLine'],
        ],
        dataConfig: [
          {
            name: 'impressionCount',
            label: allImpressionCount.name,
            iconName: 'sigma',
            getFormula: ({ data }) => (allImpressionCount.isComputable(data) ? allImpressionCount : undefined),
          },
          {
            name: 'hb',
            isComputable: ({ data }) => isHbComputable(data),
            getFormula: () => hbImpressionCount,
          },
          {
            name: 'adx',
            isComputable: ({ data }) => isAdxComputable(data),
            getFormula: () => adxImpressionCount,
          },
          {
            name: 'openBidding',
            isComputable: ({ data }) => isOpenBiddingComputable(data),
            getFormula: () => openBiddingImpressionCount,
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ comparisonData }) => !!comparisonData?.epoch.length,
            getFormula: () => allImpressionCount,
          },
        ],
      },
      {
        getChartOptions: ({ currencySymbol, legendsConfig }) => {
          const comparisonLineIndex = legendsConfig.indexOf('comparisonLine')
          return {
            seriesType: 'bars',
            series: { ...(comparisonLineIndex >= 0 ? { [comparisonLineIndex]: COMPARISON_CHART_OPTIONS } : {}) },
            vAxis: {
              ...AnalyticsDefaultChartOptions.vAxis,
              format: displayWithCurrency('#.##', currencySymbol),
            },
          }
        },
        metric: {
          name: 'auctionRPM',
          tooltipText: ANALYTICS_TOOLTIPS.AUCTION_RPM,
        },
        legendConfig: ['hb', 'adx', 'openBidding', 'comparisonLine'],
        tooltipConfig: [
          ['hb', 'adx', 'openBidding'],
          ['auctionRPM', 'comparisonLine'],
        ],
        dataConfig: [
          {
            name: 'auctionRPM',
            label: allAuctionRpm.name,
            iconName: 'sigma',
            getFormula: ({ data }) => (allAuctionRpm.isComputable(data) ? allAuctionRpm : undefined),
          },
          {
            name: 'hb',
            isComputable: ({ data }) => isHbComputable(data),
            getFormula: () => hbAuctionRpm,
          },
          {
            name: 'adx',
            isComputable: ({ data }) => isAdxComputable(data),
            getFormula: () => adxAuctionRpm,
          },
          {
            name: 'openBidding',
            isComputable: ({ data }) => isOpenBiddingComputable(data),
            getFormula: () => openBiddingAuctionRpm,
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ comparisonData }) => !!comparisonData?.epoch.length,
            getFormula: () => allAuctionRpm,
          },
        ],
      },
      {
        getChartOptions: ({ legendsConfig }) => {
          const comparisonLineIndex = legendsConfig.indexOf('comparisonLine')
          return {
            seriesType: 'bars',
            series: { ...(comparisonLineIndex >= 0 ? { [comparisonLineIndex]: COMPARISON_CHART_OPTIONS } : {}) },
            vAxis: {
              ...AnalyticsDefaultChartOptions.vAxis,
              format: "#.##'%'",
            },
          }
        },
        metric: {
          name: 'auctionFillRate',
          tooltipText: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
        },
        legendConfig: ['hb', 'adx', 'openBidding', 'comparisonLine'],
        tooltipConfig: [
          ['hb', 'adx', 'openBidding'],
          ['auctionFillRate', 'comparisonLine'],
        ],
        dataConfig: [
          {
            name: 'auctionFillRate',
            label: allAuctionFillRate.name,
            iconName: 'sigma',
            getFormula: ({ data }) => (allAuctionFillRate.isComputable(data) ? allAuctionFillRate : undefined),
          },
          {
            name: 'hb',
            isComputable: ({ data }) => isHbComputable(data),
            getFormula: () => hbAuctionFillRate,
          },
          {
            name: 'adx',
            isComputable: ({ data }) => isAdxComputable(data),
            getFormula: () => adxAuctionFillRate,
          },
          {
            name: 'openBidding',
            isComputable: ({ data }) => isOpenBiddingComputable(data),
            getFormula: () => openBiddingAuctionFillRate,
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ comparisonData }) => !!comparisonData?.epoch.length,
            getFormula: () => allAuctionFillRate,
          },
        ],
      },
      {
        getChartOptions: ({ legendsConfig, currencySymbol }) => {
          const comparisonLineIndex = legendsConfig.indexOf('comparisonLine')
          return {
            seriesType: 'lines',
            series: { ...(comparisonLineIndex >= 0 ? { [comparisonLineIndex]: COMPARISON_CHART_OPTIONS } : {}) },
            vAxis: {
              ...AnalyticsDefaultChartOptions.vAxis,
              format: displayWithCurrency('#.##', currencySymbol),
            },
          }
        },
        metric: {
          name: 'eCPM',
          tooltipText: ANALYTICS_TOOLTIPS.ECPM,
        },
        legendConfig: ['hb', 'adx', 'openBidding'],
        tooltipConfig: [['hb', 'adx', 'openBidding'], ['eCPM']],
        dataConfig: [
          {
            name: 'eCPM',
            label: allECPM.name,
            iconName: 'empty',
            getFormula: ({ data }) => (allECPM.isComputable(data) ? allECPM : undefined),
          },
          {
            name: 'hb',
            isComputable: ({ data }) => isHbComputable(data),
            getFormula: () => hbECPM,
          },
          {
            name: 'adx',
            isComputable: ({ data }) => isAdxComputable(data),
            getFormula: () => adxECPM,
          },
          {
            name: 'openBidding',
            isComputable: ({ data }) => isOpenBiddingComputable(data),
            getFormula: () => openBiddingECPM,
          },
        ],
      },
    ]
  }, [comparisonData])

type PureOverviewTimelineProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    data: OverviewMetricsByTime
    comparisonData: OverviewMetricsByTime
    currencySymbol: CurrencySymbol
    currentEpoch: DateTime
    onMetricChange: (metric: string) => void
    timezone: Timezone
  }
const _PureOverviewTimeline: FunctionComponent<PureOverviewTimelineProps> = ({ data, comparisonData, currencySymbol, currentEpoch, onMetricChange, timezone, ...props }) => {
  const config = useConfig(comparisonData)
  const { metrics, currentMetric, currentConfig } = useMetrics({ config, data, comparisonData, onMetricChange, currencySymbol })
  const { chart, legends } = useTimelineChart({
    currentConfig,
    data: currentMetric.notApplicable ? { epoch: [] as string[] } : data,
    comparisonData,
    currencySymbol,
    timezone,
    currentEpoch,
  })
  return (
    <AnalyticsChartWidget
      {...props}
      icon={'chart_bar'}
      title={'Overall Performance'}
      info={
        <InfoContainer>
          <Button variant={'tertiary'} iconName={'download'} onClick={() => downloadCSV(data, timezone)} />
        </InfoContainer>
      }
      metrics={metrics}
      legends={legends}
      chart={chart}
    />
  )
}
export const PureOverviewTimeline = styled(_PureOverviewTimeline)``
