import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

type RadioOptionValueType = string | number | boolean

type ComponentSize = 'normal' | 'small'

export type RadioOption = {
  value: RadioOptionValueType
  label: string
  disabled?: boolean
  iconName?: IconName
}

const RadioGroupWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  padding-right: 1px;
  background-color: ${Colors.Platinum};
  border-radius: 4px;
`

const RadioItemWrapper = styled.div<{ selected: boolean; disabled: boolean; small: boolean }>`
  padding: 9px;
  cursor: pointer;
  margin-right: -1px;
  border: 1px solid transparent;
  ${Fonts.P1};
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  color: ${Colors.King};
  background-color: ${Colors.White};
  background-clip: padding-box;

  :first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  :last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  ${({ disabled, selected }) =>
    disabled
      ? css`
          // CSS applied on disabled radio item (no hover)
          color: ${Colors.Ash};
          background-color: ${Colors.Cloud};
          cursor: not-allowed;
        `
      : selected
        ? css`
            // CSS applied on selected items (specif colors and hover)
            color: ${Colors.White};
            background-color: ${Colors.King};
            border-color: ${Colors.King};
            &:hover {
              background-color: ${Colors.Cobalt};
              border-color: ${Colors.Cobalt};
            }
          `
        : css`
            // CSS applied on non-selected items
            &:hover {
              border-color: ${Colors.King};
            }
          `}
  ${({ small }) =>
    small &&
    css`
      padding-top: 3px;
      padding-bottom: 3px;
      ${Fonts.P2};
    `}
`

type BaseRadioGroupProps = WithClassName & {
  options: RadioOption[]
  value?: RadioOptionValueType
  onChange: (value: RadioOptionValueType) => void
  size?: ComponentSize
}
const _RadioGroup: FunctionComponent<BaseRadioGroupProps> = ({ className, options, value, onChange, size }) => {
  return (
    <RadioGroupWrapper className={className}>
      {options.map((option) => (
        <RadioItemWrapper
          key={option.label}
          selected={option.value === value}
          onClick={!option.disabled ? () => onChange(option.value) : undefined}
          disabled={!!option.disabled}
          small={size === 'small'}
        >
          {option.iconName && <Icon width={size === 'small' ? '16px' : '20px'} name={option.iconName} />}
          {option.label}
        </RadioItemWrapper>
      ))}
    </RadioGroupWrapper>
  )
}

export const BaseRadioGroup = styled(_RadioGroup)``

type RadioGroupProps<T extends FieldValues> = Omit<UseControllerProps<T>, 'defaultValue'> & Omit<BaseRadioGroupProps, 'value' | 'onChange'> & Partial<Pick<BaseRadioGroupProps, 'onChange'>>
const RadioGroup = <T extends FieldValues>({ control, onChange, name, shouldUnregister, rules, ...props }: RadioGroupProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={shouldUnregister}
      rules={rules}
      render={({ field }) => (
        <BaseRadioGroup
          {...props}
          value={field.value as string}
          onChange={(option) => {
            field.onChange(option)
            onChange && onChange(option)
          }}
        />
      )}
    />
  )
}

export { RadioGroup }
