import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureSettingsPage } from './PureSettingsPage'

export const SettingsPage = () => {
  const breadCrumbs = useBreadcrumbs()
  const navigate = useNavigate()
  const location = useLocation()
  const isOnHomePage = location.pathname.endsWith('settings')
  return (
    <PureSettingsPage hideNavigateBack={isOnHomePage} onNavigateBack={() => navigate(-1)} breadcrumbs={breadCrumbs}>
      <Outlet />
    </PureSettingsPage>
  )
}
