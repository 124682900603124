import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { MappedName, TopRevenuesByDim } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import Button from '~/components/Button'
import { IconName } from '~/components/Icon'
import { useRevenueQuery } from '~/modules/analytics/overview/OverviewPage.hooks'
import { Context } from '~/state'
import { WithClassName } from '~/types/utils'
import { PureOverviewRevenue } from './PureOverviewRevenue'

const RevenueContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex-basis: calc(50% - 36px); /* -12px to account for widget borders and margins */
  }

  & > :nth-of-type(even) {
    margin-left: 20px;
  }

  & > :not(:nth-of-type(-n + 2)) {
    margin-top: 20px;
  }
`

const getIconFromDimension = (dimension: Dimension) => {
  const icons: { [key in Dimension]?: IconName } = {
    bidder: 'bidder',
    tagId: 'site',
    country: 'position',
    device: 'device',
    adUnit: 'picture_in_picture',
    size: 'size',
  }

  return icons[dimension] ?? 'warning'
}

const getTitleFromDimension = (dimension: Dimension) => {
  const titles: { [key in Dimension]?: string } = {
    bidder: 'bidders',
    tagId: 'sites',
    country: 'countries',
    device: 'devices',
    adUnit: 'ad units',
    size: 'sizes',
  }

  return titles[dimension] ?? ''
}

const getUriForMoreFromDimension = (dimension: Dimension) => {
  const titles: { [key in Dimension]?: string } = {
    bidder: '/analytics/bidders/',
    tagId: '/analytics/sites/',
  }

  return titles[dimension]
}

type OverviewRevenueChartProps = WithClassName & {
  context: Context
  title: string
  icon: IconName
  currencySymbol: CurrencySymbol
  dimension: Dimension
  dimensions: Dimension[]
  revenueDisplayThreshold: number
  moreUri?: string
  onRevenueChartSelect: (name: MappedName) => void
}

const OverviewRevenueChart: FunctionComponent<OverviewRevenueChartProps> = ({
  className,
  icon,
  title,
  context,
  dimensions,
  currencySymbol,
  dimension,
  revenueDisplayThreshold,
  moreUri,
  onRevenueChartSelect,
}) => {
  const revenue = useRevenueQuery(dimension, context, dimensions)
  const revenueEmptyData: TopRevenuesByDim = { impressionCpmSum: [], adxImpressionCpmSum: [], openBiddingImpressionCpmSum: [], name: [], total: {} }
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      revenue.load()
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context])

  return (
    <PureOverviewRevenue
      className={className}
      revenueDisplayThreshold={revenueDisplayThreshold}
      onRefreshClick={useCallback(revenue.load, [])}
      icon={icon}
      rawData={revenue.data ?? revenueEmptyData}
      isLoading={revenue.loading}
      error={!!revenue.error}
      empty={!revenue.loading && !revenue.data?.name.length}
      title={title}
      currencySymbol={currencySymbol}
      info={
        moreUri &&
        useMemo(
          () => (
            <Button variant={'tertiary'} onClick={() => navigate(moreUri)}>
              More
            </Button>
          ),
          [navigate]
        )
      }
      onValueSelect={onRevenueChartSelect}
    />
  )
}

type OverviewRevenueProps = WithClassName & {
  context: Context
  dimensions: Dimension[]
  currencySymbol: CurrencySymbol
  onRevenueChartSelect: (name: MappedName, dimension: Dimension) => void
}
const _OverviewRevenue: FunctionComponent<OverviewRevenueProps> = ({ className, context, dimensions, currencySymbol, onRevenueChartSelect }) => {
  const dimensionsToDisplay: Dimension[] = ['bidder', 'tagId', 'country', 'device', 'adUnit', 'size']
  const [showMinorData] = useLocalStorage<boolean>('showMinorData', false)
  const revenueDisplayThreshold = showMinorData ? Number.MIN_SAFE_INTEGER : 0.01

  return (
    <RevenueContainer className={className}>
      {dimensionsToDisplay.map((dimension, index) => (
        <OverviewRevenueChart
          key={index}
          title={getTitleFromDimension(dimension)}
          icon={getIconFromDimension(dimension)}
          revenueDisplayThreshold={revenueDisplayThreshold}
          dimension={dimension}
          context={context}
          dimensions={dimensions}
          currencySymbol={currencySymbol}
          moreUri={getUriForMoreFromDimension(dimension)}
          onRevenueChartSelect={(name) => onRevenueChartSelect && onRevenueChartSelect(name, dimension)}
        />
      ))}
    </RevenueContainer>
  )
}

export const OverviewRevenue = styled(_OverviewRevenue)``
