import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-bottom: 2rem;
  }
`

const MailLink: FunctionComponent<{ to: string }> = ({ to }) => <Link href={`mailto:${to}`} label={to} />

const MessageWrapper = styled.span`
  & ${Link} {
    display: inline;
  }
`

type PureForbiddenProps = WithClassName & { onClick: () => void }
const _PureForbidden: FunctionComponent<PureForbiddenProps> = ({ className, onClick }) => {
  return (
    <ErrorWrapper className={className}>
      <Icon width={'115px'} height={'135px'} name={'mascot'} />
      <h1>You are not allowed to access this page</h1>
      <MessageWrapper>
        Please ask your administrator to grant you the rights, or contact <MailLink to={'support@pubstack.io'} />
      </MessageWrapper>
      <Button onClick={onClick} variant={'primary'}>
        Go to home page
      </Button>
    </ErrorWrapper>
  )
}

export const PureForbidden = styled(_PureForbidden)``
