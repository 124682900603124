import { LazyLoadingRule } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyLoadingRules } from '~/api/adm-api.hook'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackLazyLoadingPage } from './PureAdStackLazyLoadingPage'

export const AdStackLazyLoadingPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const [lazyLoadingRules, setLazyLoadingRules] = useState<LazyLoadingRule[]>([])

  const { all } = useLazyLoadingRules(null)

  const loadLazyLoadingRules = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setLazyLoadingRules(result)
  }

  useEffect(() => {
    loadLazyLoadingRules()
  }, [])

  return (
    <PureAdStackLazyLoadingPage
      lazyLoadingRules={lazyLoadingRules}
      isLoading={all.loading}
      breadcrumbs={breadcrumbs}
      onCreateRule={() => navigate(`/adstack/lazy-loading/new`)}
      onEditRule={(id: string) => navigate(`/adstack/lazy-loading/edit/${id}`)}
    />
  )
}
