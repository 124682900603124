import { GamIntegration } from '@pubstack/common/src/gam/adx'
import { Feature } from '@pubstack/common/src/rolesAndFeatures'
import { AdminScope, AdminScopeDataIngestionFlags, EMPTY_ADMIN_SCOPE } from '@pubstack/common/src/scope'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAdminScope, useGamIntegration } from '~/api/admin-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { AdminScopeFeaturesGamIntegration } from './AdminScopeFeaturesGamIntegration'
import { PureAdminScopeFeatures } from './PureAdminScopeFeatures'

const EMPTY_GAM_INTEGRATION: GamIntegration = {
  serviceAccount: undefined,
  secretName: undefined,
  gamNetworks: [],
}

export const AdminScopeFeatures: FunctionComponent = () => {
  const { scopeId } = useParams()
  const { byId: adminScopeById } = useAdminScope(null)
  const [currentScope, setCurrentScope] = useState<AdminScope>()
  const toast = useToast()

  const { byId: gamIntegrationById } = useGamIntegration(null, scopeId ?? '')
  const [currentIntegration, setCurrentIntegration] = useState<GamIntegration>(EMPTY_GAM_INTEGRATION)

  const loadGamIntegration = async () => {
    if (gamIntegrationById.loading) {
      gamIntegrationById.abort()
    }
    const gamint = await gamIntegrationById.get()
    setCurrentIntegration(gamint ?? EMPTY_GAM_INTEGRATION)
  }

  useEffect(() => {
    loadGamIntegration()
  }, [])

  const loadScope = async () => {
    if (scopeId) {
      if (adminScopeById.loading) {
        adminScopeById.abort()
      }
      const scope = await adminScopeById.get(scopeId)
      setCurrentScope(scope)
    }
  }

  const updateScope = async (scope: AdminScope) => {
    if (scopeId) {
      if (adminScopeById.loading) {
        adminScopeById.abort()
      }
      setCurrentScope(scope)
      await adminScopeById.put(scopeId, scope)
      loadScope()
    }
  }

  //TODO tmu nra 2022-09-21 -- debounce this so we don't flood the api
  const toggleFeature = (featureName: Feature) => {
    if (currentScope) {
      let enableUserSessionIngestion = false
      if (featureName === 'adstack' && !currentScope.features?.includes(featureName)) {
        toast.alert(`Please make sure a configuration has been created for the scope ${currentScope.name} before enabling the Ad stack management feature.`, { icon: 'rocket', duration: Infinity })
        // if adstack is enabled, we want to enable user session ingestion as well
        if (!currentScope.standaloneUserSessionEnabled) {
          toast.success(`User sessions data ingestion will also be enabled.`, { icon: 'info', duration: Infinity })
          enableUserSessionIngestion = true
        }
      }
      const updatedScope = {
        ...currentScope,
        features: currentScope.features?.includes(featureName) ? currentScope.features?.filter((f) => f !== featureName) : [...(currentScope?.features ?? []), featureName],
        standaloneUserSessionEnabled: currentScope.standaloneUserSessionEnabled || enableUserSessionIngestion,
      }
      updateScope(updatedScope)
    }
  }

  //TODO tmu nra 2022-09-21 -- debounce this so we don't flood the api
  const toggleDataIngestion = (flagName: keyof AdminScopeDataIngestionFlags) => {
    if (currentScope) {
      // user sessions must remain enabled if adstack is enabled
      if (flagName === 'standaloneUserSessionEnabled' && currentScope.standaloneUserSessionEnabled && currentScope.features?.includes('adstack')) {
        toast.alert(`You cannot disable the user sessions data ingestion while the Ad stack management feature is enabled.`, { icon: 'warning', duration: Infinity })
        return
      }
      const updatedScope = {
        ...currentScope,
        [flagName]: !currentScope[flagName],
      }
      updateScope(updatedScope)
    }
  }

  useEffect(() => {
    loadScope()
    loadGamIntegration()
  }, [scopeId])

  const gamIngestionStatus: { adxEnabled: boolean; openBiddingEnabled: boolean; gamIntegrationEnabled: boolean } = {
    adxEnabled: (currentIntegration.gamNetworks ?? []).some((n) => n.dataIngestionStatus === 'ACTIVE') ?? false,
    openBiddingEnabled: (currentIntegration.gamNetworks ?? []).some((n) => n.openBiddingEnabled) ?? false,
    gamIntegrationEnabled: (currentIntegration.gamNetworks ?? []).some((n) => n.gamIntegrationEnabled) ?? false,
  }

  //TODO tmu nra 2022-09-21 -- handle error
  return (
    <PureAdminScopeFeatures
      isLoading={(adminScopeById.loading || gamIntegrationById.loading) && !adminScopeById.error}
      gamCollapsibleContent={
        currentScope && (
          <AdminScopeFeaturesGamIntegration
            scope={currentScope}
            currentIntegration={currentIntegration}
            loadGamIntegration={loadGamIntegration}
            setCurrentIntegration={setCurrentIntegration}
            gamIntegrationLoading={gamIntegrationById.loading}
          />
        )
      }
      gamIngestion={gamIngestionStatus}
      scope={currentScope ?? EMPTY_ADMIN_SCOPE}
      toggleFeature={toggleFeature}
      toggleDataIngestion={toggleDataIngestion}
    />
  )
}
