import styled from '@emotion/styled'
import { Feature } from '@pubstack/common/src/rolesAndFeatures'
import { AdminScope, AdminScopeDataIngestionFlags } from '@pubstack/common/src/scope'
import { FunctionComponent, ReactNode } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { IconName } from '~/components/Icon'
import { Spinner } from '~/components/Spinner'
import { Toggle } from '~/components/Toggle'
import { WidgetMessage } from '~/components/WidgetMessage'
import { WithClassName } from '~/types/utils'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  & > ${WidgetMessage} {
    align-self: flex-start;
  }
`

const FeatureGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const FeatureGroupTitle = styled.h2`
  ${Fonts.H2}
  margin-bottom: 8px;
  font-weight: 500;
`
const FeatureActionWrapper = styled.div`
  display: flex;
  gap: 30px;
`
const FeatureToggle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;
`

const FeatureSpinner = styled(Spinner)`
  width: 44px;
`

type FeatureContent = {
  title: string
  icon?: IconName
  feature?: Feature
  ingestion?: keyof AdminScopeDataIngestionFlags | ('adxEnabled' | 'openBiddingEnabled' | 'gamIntegrationEnabled')
  additionalFeature?: 'sessionTrackingDisabled'
  additionalFeatureTitle?: string
  isCollapsible?: boolean
  collapsibleContent?: ReactNode
}

type PureAdminScopeFeaturesProps = WithClassName & {
  scope: AdminScope
  isLoading: boolean
  toggleFeature: (feature: Feature) => unknown
  toggleDataIngestion: (flag: keyof AdminScopeDataIngestionFlags) => unknown
  gamCollapsibleContent: ReactNode
  gamIngestion: { adxEnabled: boolean; openBiddingEnabled: boolean; gamIntegrationEnabled: boolean }
}
const _PureAdminScopeFeatures: FunctionComponent<PureAdminScopeFeaturesProps> = ({ className, isLoading, scope, gamCollapsibleContent, toggleFeature, toggleDataIngestion, gamIngestion }) => {
  const dataIngestions: AdminScopeDataIngestionFlags & { adxEnabled: boolean; openBiddingEnabled: boolean; gamIntegrationEnabled: boolean } = {
    ...scope,
    ...gamIngestion,
  }

  const FEATURES_CONFIG: {
    title: string
    content: FeatureContent[]
  }[] = [
    {
      title: 'GAM',
      content: [
        {
          title: 'GAM integration & data ingestion',
          isCollapsible: true,
          collapsibleContent: gamCollapsibleContent,
        },
        { title: 'AdX', icon: 'gam_color', feature: 'adx', ingestion: 'adxEnabled' },
        { title: 'Open Bidding', icon: 'gam_color', feature: 'openBidding', ingestion: 'openBiddingEnabled' },
      ],
    },
    {
      title: 'Analytics',
      content: [
        { title: 'Analytics basics (Overview, Bidders, Sites, Reports)', icon: 'chart_bar', feature: 'analytics' },
        { title: 'Viewability', icon: 'view', feature: 'viewability', ingestion: 'viewabilityEnabled' },
        { title: 'Ad refresh (legacy)', icon: 'refresh', feature: 'refresh', ingestion: 'refreshEnabled' },

        {
          title: 'User sessions',
          icon: 'group',
          feature: 'standaloneUserSession',
          ingestion: 'standaloneUserSessionEnabled',
          additionalFeature: 'sessionTrackingDisabled',
          additionalFeatureTitle: 'Disable Session Tracking',
        },
        { title: 'A/B test', icon: 'population_split', feature: 'abtest' },
        { title: 'SmartAd Server', icon: 'smart', feature: 'smartAdServer', ingestion: 'smartEnabled' },
      ],
    },
    {
      title: 'Google ad manager',
      content: [
        { title: 'GAM overview Web', icon: 'shiny_web', feature: 'gamOverviewWeb' },
        { title: 'GAM overview APP', icon: 'shiny_app', feature: 'gamOverviewApp' },
        { title: 'GAM overview AMP', icon: 'bolt', feature: 'gamOverviewAmp' },
      ],
    },
    {
      title: 'Ad stack management (Klean)',
      content: [
        { title: 'Ad stack management', icon: 'rocket', feature: 'adstack' },
        { title: 'Refresh', icon: 'refresh', feature: 'admRefresh' },
        { title: 'Marketplace', icon: 'store_front', feature: 'marketplace' },
      ],
    },
    {
      title: 'Add-on',
      content: [{ title: 'Live chat', icon: 'chat', feature: 'liveChat' }],
    },
  ]

  const isScopeIngestionFlag = (flag: string): flag is keyof AdminScopeDataIngestionFlags =>
    ['viewabilityEnabled', 'refreshEnabled', 'standaloneUserSessionEnabled', 'smartEnabled', 'sessionTrackingDisabled'].includes(flag)

  return (
    <PageWrapper className={className}>
      <WidgetMessage icon={'warning'} message={'Make sure that data are correctly ingested before granting access to users.'} />
      {FEATURES_CONFIG.map((featureConfig, index) => (
        <FeatureGroup key={index}>
          <FeatureGroupTitle>{featureConfig.title}</FeatureGroupTitle>
          {featureConfig.content.map((content, contentIndex) => {
            const contentIngestion = content.ingestion //needed for the "isScopeIngestionFlag" type assertion to work
            const additionalFeature = content.additionalFeature
            return (
              <CollapsiblePanel
                key={`feature-${contentIndex}`}
                icon={content.icon}
                title={content.title}
                isCollapsible={content.isCollapsible}
                actions={
                  <FeatureActionWrapper>
                    {contentIngestion && isScopeIngestionFlag(contentIngestion) && (
                      <FeatureToggle>
                        <span>Data ingestion</span>
                        {isLoading ? <FeatureSpinner /> : <Toggle value={scope[contentIngestion]} onClick={() => toggleDataIngestion(contentIngestion)} />}
                      </FeatureToggle>
                    )}
                    {additionalFeature && (
                      <FeatureToggle>
                        <span>{content.additionalFeatureTitle}</span>
                        {isLoading ? (
                          <FeatureSpinner />
                        ) : (
                          <Toggle disabled={contentIngestion && !dataIngestions[contentIngestion]} value={scope[additionalFeature]} onClick={() => toggleDataIngestion(additionalFeature)} />
                        )}
                      </FeatureToggle>
                    )}
                    {content.feature && (
                      <FeatureToggle>
                        <span>User access</span>
                        {isLoading ? (
                          <FeatureSpinner />
                        ) : (
                          <Toggle
                            disabled={contentIngestion && !dataIngestions[contentIngestion]}
                            value={scope.features?.includes(content.feature)}
                            onClick={() => content.feature && toggleFeature(content.feature)}
                          />
                        )}
                      </FeatureToggle>
                    )}
                  </FeatureActionWrapper>
                }
              >
                {content.collapsibleContent}
              </CollapsiblePanel>
            )
          })}
        </FeatureGroup>
      ))}
    </PageWrapper>
  )
}

export const PureAdminScopeFeatures = styled(_PureAdminScopeFeatures)``
