import { LazyLoadingRule } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLazyLoadingRules } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackLazyLoadingEditPage } from './PureAdStackLazyLoadingEditPage'

export const AdStackLazyLoadingEditPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const breadcrumbs = useBreadcrumbs()
  const [lazyLoadingRules, setLazyLoadingRules] = useState<LazyLoadingRule[]>([])

  const [lazyLoadingRule, setLazyLoadingRule] = useState<LazyLoadingRule>()
  const { lazyLoadingRuleId } = useParams()

  const { all, byId } = useLazyLoadingRules(null)

  async function loadLazyLoadingRule(lazyLoadingRuleId?: string) {
    if (lazyLoadingRuleId) {
      try {
        const lazyLoadingRuleToUpdate = await byId.get(lazyLoadingRuleId)
        setLazyLoadingRule(lazyLoadingRuleToUpdate)
      } catch (exception) {
        toast.alert('An error occurred while fetching this lazy loading rule.')
        throw exception
      }
    }
  }

  const loadLazyLoadingRules = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setLazyLoadingRules(result)
  }

  const createLazyLoadingRule = async (lazyLoadingRule: LazyLoadingRule) => {
    try {
      const newRule = await byId.post(lazyLoadingRule)
      toast.success(`${newRule.name} added to your inventory.`)
      navigate('/adstack/lazy-loading')
    } catch (exception) {
      toast.alert('An error occurred while saving this rule.')
      throw exception
    }
  }

  const updateLazyLoadingRule = async (lazyLoadingRule: LazyLoadingRule) => {
    try {
      const updatedLazyLoadingRule = await byId.put(lazyLoadingRule)
      toast.admSuccess(lazyLoadingRule.name, lazyLoadingRule.stacksUse && lazyLoadingRule.id !== updatedLazyLoadingRule.id, navigate)
      navigate('/adstack/lazy-loading')
    } catch (exception) {
      toast.alert('An error occurred while saving this rule.')
      throw exception
    }
  }

  useEffect(() => {
    loadLazyLoadingRules()
    loadLazyLoadingRule(lazyLoadingRuleId)
  }, [lazyLoadingRuleId])

  return (
    <PureAdStackLazyLoadingEditPage
      isEditing={!!lazyLoadingRuleId}
      isLoading={byId.loading}
      lazyLoadingRule={lazyLoadingRule}
      existingLazyLoadingRules={lazyLoadingRules}
      breadcrumbs={breadcrumbs}
      onCreateLazyLoadingRule={createLazyLoadingRule}
      onUpdateLazyLoadingRule={updateLazyLoadingRule}
      onBack={() => navigate(-1)}
    />
  )
}
