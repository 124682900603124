import { DataProviderRTD, ModuleRTDNames, PrebidModuleNames, REAL_TIME_DATA_MODULES_CONFIG } from '@pubstack/common/src/catalogItem'
import { ID_MODULES, IdModuleNames, UserSyncUserId } from '@pubstack/common/src/userSync'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useCatalogItems, useIdentityModule, usePrebidModulesConfig, useRealTimeDataModule } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { DataBySite, PureModulesBySite } from './PureModulesBySite'
import { SitePageContext } from './SitePage'

const _ModulesBySite: React.FunctionComponent = () => {
  const [modules, setModules] = useState<DataBySite<PrebidModuleNames>[]>([])
  const { currentSite } = useOutletContext<SitePageContext>()
  const { prebidModulesConfig } = usePrebidModulesConfig()
  const { byId: rtdModuleUpdate } = useRealTimeDataModule(null)
  const { byId: idModuleUpdate } = useIdentityModule(null)
  const { all } = useCatalogItems(null)
  const toast = useToast()

  const getModulesData = async () => {
    if (all.loading) {
      all.abort()
    }
    const allEmptyModules = await all.get()
    const siteModulesConfig = await prebidModulesConfig.get(currentSite.id || '')
    const defaultData: DataBySite<PrebidModuleNames>[] = allEmptyModules
      .filter((m) => m.type === 'prebidModule' && !siteModulesConfig.some((s) => s.name === m.code))
      .map((m) => ({
        code: m.code as PrebidModuleNames,
        moduleData: undefined,
      }))
    const dataBySite: DataBySite<PrebidModuleNames>[] = siteModulesConfig.map(({ name, ...moduleData }) => ({
      code: name,
      moduleData,
    }))
    const allModules: DataBySite<PrebidModuleNames>[] = [...defaultData, ...dataBySite]

    setModules(allModules)
  }

  const updateSiteData = async <ModuleName extends PrebidModuleNames>(siteData: DataBySite<ModuleName>) => {
    try {
      const rtdModuleConfig = REAL_TIME_DATA_MODULES_CONFIG.find((e) => e.name === siteData.code)
      if (rtdModuleConfig) {
        const update = {
          name: siteData.code,
          ...siteData.moduleData,
        } as DataProviderRTD<ModuleRTDNames>
        await rtdModuleUpdate.put(rtdModuleConfig.id, currentSite.id, update)
      }

      const idModuleConfig = ID_MODULES.find((e) => e.code === siteData.code)
      if (idModuleConfig) {
        const update = {
          name: siteData.code,
          ...siteData.moduleData,
        } as UserSyncUserId<IdModuleNames>
        await idModuleUpdate.put(idModuleConfig?.id, currentSite.id, update)
      }

      toast.success(`${siteData.code} settings updated.`)
      getModulesData()
    } catch (e) {
      toast.alert('An error has occurred, please retry later.')
    }
  }

  useEffect(() => {
    getModulesData()
  }, [currentSite])

  return <PureModulesBySite isLoading={prebidModulesConfig.loading || all.loading} modules={modules} updateSiteData={updateSiteData} />
}

export const ModulesBySite = _ModulesBySite
