import styled from '@emotion/styled'
import { RefreshMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo } from 'react'
import { Colors } from '~/assets/style/colors'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { MetricConfiguration, TimelineConfiguration, useMetrics, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import {
  firstCallAndRefreshAuctionFillRate,
  firstCallAndRefreshAverageViewedTime,
  firstCallAndRefreshECPM,
  firstCallAndRefreshImpressionCount,
  firstCallAndRefreshImpressionRevenue,
  firstCallAndRefreshViewabilityMRC,
  firstCallAuctionFillRate,
  firstCallAverageViewedTime,
  firstCallECPM,
  firstCallImpressionCount,
  firstCallImpressionRevenue,
  firstCallViewabilityMRC,
  refreshAuctionFillRate,
  refreshAverageViewedTime,
  refreshECPM,
  refreshImpressionCount,
  refreshImpressionRevenue,
  refreshRevenueUplift,
  refreshViewabilityMRC,
} from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { displayWithCurrency } from '~/utils/string'
import { RefreshUpliftMessage } from './RefreshUpliftMessage'

const useConfig = () => {
  return useMemo((): (MetricConfiguration<RefreshMetricsByTime> & TimelineConfiguration<RefreshMetricsByTime>)[] => {
    const firstCall = { name: 'firstCall', label: 'First call' }
    const refresh = { name: 'refresh', label: 'Refresh' }
    const legendConfig = ['firstCall', 'refresh']
    return [
      {
        getChartOptions: ({ currencySymbol }) => ({
          seriesType: 'bars',
          isStacked: true,
          vAxis: {
            ...AnalyticsDefaultChartOptions.vAxis,
            format: displayWithCurrency('#.##', currencySymbol),
          },
        }),
        metric: {
          name: 'impressionRevenue',
          tooltipText: ANALYTICS_TOOLTIPS.REVENUE,
          message: ({ data, currencySymbol }) => {
            if (firstCallImpressionRevenue.isComputable(data) && firstCallImpressionRevenue.sum(data) > 0) {
              const value = refreshImpressionRevenue.isComputable(data) ? refreshImpressionRevenue.sum(data) : 0
              return {
                icon: 'rocket',
                message: <RefreshUpliftMessage value={value} percentage={Math.round((value / firstCallImpressionRevenue.sum(data)) * 100)} currencySymbol={currencySymbol} />,
              }
            }
            return undefined
          },
        },
        legendConfig,
        tooltipConfig: [legendConfig, ['uplift']],
        dataConfig: [
          {
            name: 'impressionRevenue',
            label: firstCallAndRefreshImpressionRevenue.name,
            isComputable: ({ data }) => firstCallAndRefreshImpressionRevenue.isComputable(data),
            getFormula: () => firstCallAndRefreshImpressionRevenue,
          },
          { ...firstCall, isComputable: ({ data }) => firstCallImpressionRevenue.isComputable(data), getFormula: () => firstCallImpressionRevenue },
          { ...refresh, isComputable: ({ data }) => refreshImpressionRevenue.isComputable(data), getFormula: () => refreshImpressionRevenue },
          {
            name: 'uplift',
            label: 'Refresh uplift',
            iconName: 'rocket',
            iconColor: Colors.Jet,
            isComputable: ({ data }) => refreshRevenueUplift.isComputable(data),
            getFormula: () => refreshRevenueUplift,
          },
        ],
      },
      {
        getChartOptions: () => ({
          seriesType: 'bars',
          isStacked: true,
        }),
        metric: {
          name: 'impressionCount',
          tooltipText: '',
        },
        legendConfig,
        tooltipConfig: legendConfig,
        dataConfig: [
          {
            name: 'impressionCount',
            label: firstCallAndRefreshImpressionCount.name,
            isComputable: ({ data }) => firstCallAndRefreshImpressionCount.isComputable(data),
            getFormula: () => firstCallAndRefreshImpressionCount,
          },
          { ...firstCall, isComputable: ({ data }) => firstCallImpressionCount.isComputable(data), getFormula: () => firstCallImpressionCount },
          { ...refresh, isComputable: ({ data }) => refreshImpressionCount.isComputable(data), getFormula: () => refreshImpressionCount },
        ],
      },
      {
        getChartOptions: () => ({
          seriesType: 'line',
          vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: "#'%'", minValue: 0, maxValue: 100 },
        }),
        metric: {
          name: 'auctionFillRate',
          tooltipText: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
        },
        legendConfig,
        tooltipConfig: legendConfig,
        dataConfig: [
          {
            name: 'auctionFillRate',
            label: firstCallAndRefreshAuctionFillRate.name,
            isComputable: ({ data }) => firstCallAndRefreshAuctionFillRate.isComputable(data),
            getFormula: ({ data }) => (firstCallAndRefreshAuctionFillRate.isComputable(data) ? firstCallAndRefreshAuctionFillRate : undefined),
          },
          { ...firstCall, isComputable: ({ data }) => firstCallAuctionFillRate.isComputable(data), getFormula: () => firstCallAuctionFillRate },
          { ...refresh, isComputable: ({ data }) => refreshAuctionFillRate.isComputable(data), getFormula: () => refreshAuctionFillRate },
        ],
      },
      {
        getChartOptions: ({ currencySymbol }) => ({
          seriesType: 'line',
          vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: displayWithCurrency('#,###', currencySymbol), minValue: 0 },
        }),
        metric: {
          name: 'eCPM',
          tooltipText: ANALYTICS_TOOLTIPS.ECPM,
        },
        legendConfig,
        tooltipConfig: legendConfig,
        dataConfig: [
          {
            name: 'eCPM',
            label: firstCallAndRefreshECPM.name,
            isComputable: ({ data }) => firstCallAndRefreshECPM.isComputable(data),
            getFormula: () => firstCallAndRefreshECPM,
          },
          { ...firstCall, isComputable: ({ data }) => firstCallECPM.isComputable(data), getFormula: () => firstCallECPM },
          { ...refresh, isComputable: ({ data }) => refreshECPM.isComputable(data), getFormula: () => refreshECPM },
        ],
      },
      {
        getChartOptions: () => ({
          seriesType: 'line',
          vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: "#'%'", minValue: 0, maxValue: 100 },
        }),
        metric: {
          name: 'viewabilityMRC',
          tooltipText: ANALYTICS_TOOLTIPS.VIEWABILITY,
        },
        legendConfig,
        tooltipConfig: legendConfig,
        dataConfig: [
          {
            name: 'viewabilityMRC',
            label: firstCallAndRefreshViewabilityMRC.name,
            isComputable: ({ data }) => firstCallAndRefreshViewabilityMRC.isComputable(data),
            getFormula: () => firstCallAndRefreshViewabilityMRC,
          },
          { ...firstCall, isComputable: ({ data }) => firstCallViewabilityMRC.isComputable(data), getFormula: () => firstCallViewabilityMRC },
          { ...refresh, isComputable: ({ data }) => refreshViewabilityMRC.isComputable(data), getFormula: () => refreshViewabilityMRC },
        ],
      },

      {
        getChartOptions: () => ({
          seriesType: 'line',
          vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: "#'s'", minValue: 0 },
        }),
        metric: {
          name: 'averageViewedTime',
          tooltipText: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
        },
        legendConfig,
        tooltipConfig: legendConfig,
        dataConfig: [
          {
            name: 'averageViewedTime',
            label: firstCallAndRefreshAverageViewedTime.name,
            isComputable: ({ data }) => firstCallAndRefreshAverageViewedTime.isComputable(data),
            getFormula: () => firstCallAndRefreshAverageViewedTime,
          },
          { ...firstCall, isComputable: ({ data }) => firstCallAverageViewedTime.isComputable(data), getFormula: () => firstCallAverageViewedTime },
          { ...refresh, isComputable: ({ data }) => refreshAverageViewedTime.isComputable(data), getFormula: () => refreshAverageViewedTime },
        ],
      },
    ]
  }, [])
}

type PureRefreshTimelineProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    data: RefreshMetricsByTime
    currencySymbol: CurrencySymbol
    currentEpoch: DateTime
    timezone: Timezone
    onMetricChange: (metric: string) => void
  }
const _PureRefreshTimeline: FunctionComponent<PureRefreshTimelineProps> = ({ data, currencySymbol, currentEpoch, timezone, onMetricChange, ...props }) => {
  const config = useConfig()
  const { metrics, currentConfig, currentMetric, metricMessage } = useMetrics({ config, data, currencySymbol, onMetricChange })
  const { chart, legends } = useTimelineChart({ currentConfig, data: currentMetric.notApplicable ? { epoch: [] as string[] } : data, currencySymbol, currentEpoch, timezone })

  return <AnalyticsChartWidget {...props} icon={'chart_bar'} title={'Ad Refresh performance'} metrics={metrics} chart={chart} legends={legends} metricMessage={metricMessage} />
}
export const PureRefreshTimeline = styled(_PureRefreshTimeline)``
