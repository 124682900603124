import { AllValuesWithSize } from '@pubstack/common/src/analytics/query'
import { EMPTY_REFRESH_GROUP, GroupOverlap, RefreshGroup } from '@pubstack/common/src/refresh'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useAnalyticsQuery, useRefreshGroups } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { contextState } from '~/state'
import { PureSettingsRefreshGroupAccordionPage } from './PureSettingsRefreshGroupAccordionPage'

export const SettingsRefreshGroupAccordionPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const context = useRecoilValue(contextState)
  const user = useUser()
  const isPubstack = user ? user.isAdmin : false

  const { byId: analyticsQuery } = useAnalyticsQuery<AllValuesWithSize>(null, [], context)
  const { byId: refreshGroupsById, check } = useRefreshGroups(null)

  const devices = [
    { name: 'Desktop', id: 'desktop' },
    { name: 'Mobile', id: 'mobile' },
    { name: 'Tablet', id: 'tablet' },
    { name: 'Others', id: 'other' },
  ]
  const [bidders, setBidders] = useState<AllValuesWithSize['bidders']>([])
  const [adunits, setAdunits] = useState<AllValuesWithSize['adunits']>([])
  const [sites, setSites] = useState<AllValuesWithSize['sites']>([])
  const [refreshGroup, setRefreshGroup] = useState<RefreshGroup>()

  const loadDimensions = async () => {
    if (analyticsQuery.loading) {
      analyticsQuery.abort()
    }
    const res = await analyticsQuery.post('all.values.with.size')
    if (res.values) {
      const {
        values: { sites, adunits, bidders },
      } = res
      setBidders(bidders)
      setSites(sites)
      setAdunits(adunits)
    }
  }

  const loadRefreshGroup = async () => {
    if (id) {
      const refreshGroup = await refreshGroupsById.get(id)
      setRefreshGroup(refreshGroup)
    }
  }

  const save = async (refreshGroup: RefreshGroup) => {
    if (refreshGroup.id) {
      await refreshGroupsById.put(refreshGroup)
    } else {
      await refreshGroupsById.post(refreshGroup)
    }
    navigate('/settings/refresh-groups')
  }

  const onSave = async (refreshGroup: RefreshGroup, options?: { activateGroup?: boolean; bypassCheck?: boolean }): Promise<void | GroupOverlap> => {
    if (options?.bypassCheck) {
      return save(refreshGroup)
    }
    const overlaps = await check(refreshGroup)
    if (overlaps) {
      return overlaps.conflicts
    } else {
      save({ ...refreshGroup, enabled: options?.activateGroup === undefined ? refreshGroup.enabled : options.activateGroup })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      loadDimensions()
      loadRefreshGroup()
    })
  }, [context, id])

  return (
    <PureSettingsRefreshGroupAccordionPage
      isLoading={refreshGroupsById.loading || analyticsQuery.loading}
      isEditing={!!id}
      refreshGroup={refreshGroup ?? EMPTY_REFRESH_GROUP}
      setRefreshGroup={setRefreshGroup}
      adUnits={adunits}
      bidders={bidders}
      devices={devices}
      sites={sites}
      isPubstackRole={isPubstack}
      onSave={onSave}
    />
  )
}
