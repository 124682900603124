import styled from '@emotion/styled'
import { AllMetrics } from '@pubstack/common/src/analytics/query'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon, IconName } from '~/components/Icon'
import { DetailInfo } from './DataTableTypes'

export const DataTableDetailedPropertyProvider: {
  [key in keyof Pick<AllMetrics, 'bidTagIdCount' | 'bidAdUnitCount' | 'bidBidderCount'>]: (value: number | string) => { label: string; icon: IconName }
} = {
  bidTagIdCount: (count: number | string) => ({ label: `${count} sites`, icon: 'site' }),
  bidAdUnitCount: (count: number | string) => ({ label: `${count} ad units`, icon: 'picture_in_picture' }),
  bidBidderCount: (count: number | string) => ({ label: `${count} bidders`, icon: 'bidder' }),
}

const DataTableDetailedPropertyIcon = styled(Icon)`
  margin-right: 4px;
`

const DataTableDetailedPropertyWrapper = styled.div`
  ${Fonts.P2};
  color: ${Colors.SlateGrey};
  font-weight: normal;
  display: inline-flex;
  flex: 0 1 120px;
  align-items: center;
  white-space: nowrap;
`

const DataTableDetailedPropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

const DataTableDetailedProperty = ({ icon, label }: { icon?: IconName; label: string }) => (
  <DataTableDetailedPropertyWrapper>
    {icon && <DataTableDetailedPropertyIcon name={icon} width={'14px'} />} {label}
  </DataTableDetailedPropertyWrapper>
)

export const DataTableDetailedProperties = ({ details }: { details: DetailInfo[] }) => (
  <DataTableDetailedPropertyContainer>
    {details.map((detail, index) => (
      <DataTableDetailedProperty key={index} icon={detail.icon} label={detail.label} />
    ))}
  </DataTableDetailedPropertyContainer>
)
