import { ADM_QA_REPORTS_TIME_RANGES, AdmQaReport } from '@pubstack/common/src/adm-qa-reports'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import * as ReactDomServer from 'react-dom/server'
import { Color, DataColors } from '~/assets/style/colors'
import { ChartTooltip } from '~/components/ChartTooltip'
import { LegendProps } from '~/components/Legend'
import { ADM_QA_REPORTS_DIMS, ADM_QA_REPORTS_TIME_RANGE_LABELS, AdmQaReportsDimName, AuctionsImpressionsData, BidsData, UserSessionData, ViewabilityData } from '~/modules/admin/adm-qa/admQaReports'

type AdmQAReportsMetrics<T extends AuctionsImpressionsData | BidsData | ViewabilityData | UserSessionData> = Omit<T, AdmQaReportsDimName>

type DimensionMetricsBySite<T extends AuctionsImpressionsData | BidsData | ViewabilityData | UserSessionData> = {
  [metric in keyof AdmQAReportsMetrics<T>]: {
    [site: string]: number
  }[]
} & { [dim in AdmQaReportsDimName]: string[] }

export type ChartMetric<T extends AuctionsImpressionsData | BidsData | ViewabilityData | UserSessionData> = {
  label: string
  compute: (data: DimensionMetricsBySite<T>, index: number, site: string) => number
  display: (data: DimensionMetricsBySite<T>, index: number, site: string, currencySymbol?: CurrencySymbol) => string
}

export type ChartMetrics<T extends AuctionsImpressionsData | BidsData | ViewabilityData | UserSessionData> = {
  [key in string]: ChartMetric<T>
}

const getAdmQAReportsMetrics = <T extends AuctionsImpressionsData | BidsData | ViewabilityData | UserSessionData>(dataType: T): (keyof AdmQAReportsMetrics<T>)[] => {
  const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>

  return getKeys(dataType).filter((key) => !ADM_QA_REPORTS_DIMS.includes(key as AdmQaReportsDimName)) as (keyof AdmQAReportsMetrics<T>)[]
}

const buildDataBySite = <T extends AuctionsImpressionsData | BidsData | ViewabilityData | UserSessionData>(
  data: T,
  dimension: AdmQaReportsDimName,
  metrics: (keyof AdmQAReportsMetrics<T>)[],
  sites: string[]
) => {
  const emptyDataBySite = {
    [dimension]: [] as string[],
    ...(Object.fromEntries(metrics.map((metric) => [metric, []])) as any),
  } as DimensionMetricsBySite<T>

  const dimensionIsMissing = !data[dimension]
  if (dimensionIsMissing) {
    return emptyDataBySite
  } else {
    return data[dimension].reduce((acc, dim, rowIndex) => {
      const currentDimension = dim as AdmQaReportsDimName
      const site = data.website[rowIndex]
      if (site === null || dim === null) return acc
      if (!acc[dimension]?.includes(currentDimension)) {
        acc[dimension]?.push(currentDimension)
        metrics.forEach((metric) => {
          acc[metric].push(Object.fromEntries(sites.map((site) => [site, 0])))
        })
      }
      const dimensionIndex = acc[dimension]?.indexOf(currentDimension)
      metrics.forEach((metric) => {
        if (!acc[metric][dimensionIndex][site]) {
          acc[metric][dimensionIndex][site] = 0
        }

        acc[metric][dimensionIndex][site] += (data[metric as keyof T] as number[])[rowIndex] ?? 0
      })
      return acc
    }, emptyDataBySite)
  }
}

export const useAdmQAReportChartData = <T extends AuctionsImpressionsData | BidsData | ViewabilityData | UserSessionData>(
  data: T,
  dimension: AdmQaReportsDimName,
  sites: string[],
  chartMetric: ChartMetric<T>,
  report: AdmQaReport,
  currencySymbol: CurrencySymbol
) => {
  const dataBySite = useMemo(() => buildDataBySite(data, dimension, getAdmQAReportsMetrics(data), sites), [data, dimension, sites])
  const siteColors = [DataColors.Kaiminus, DataColors.Pumpkin]
  const legends: LegendProps[] = useMemo(
    () =>
      sites.map((site, index) => {
        return {
          label: site,
          iconColor: siteColors[index],
          iconName: 'square',
        }
      }),
    [sites]
  )
  const reportTimeRange = ADM_QA_REPORTS_TIME_RANGES[report.range]
  const dateBefore = DateTime.fromISO(report.admLiveDate).minus(reportTimeRange.minus)
  const dateAfter = DateTime.fromISO(report.admLiveDate).plus(reportTimeRange.plus)

  const chartData = useMemo(() => {
    const headers = ['Bidder', { type: 'string', role: 'tooltip', p: { html: true } }, ...sites]
    return [
      headers,
      ...dataBySite[dimension].map((bidder, index) => {
        const tooltip = (
          <ChartTooltip
            key={`tooltip_${bidder}`}
            metrics={sites.map((site, siteIndex) => {
              return [
                {
                  iconName: 'calendar',
                  iconColor: siteColors[siteIndex] as Color,
                  label: (siteIndex === 0 ? dateBefore : dateAfter).toFormat(`dd LLL. ${'day' in reportTimeRange.span ? '' : 'h:00 a'}`),
                  value: '',
                },
                {
                  label: site,
                  value: chartMetric.display(dataBySite, index, site, currencySymbol),
                  iconColor: siteColors[siteIndex] as Color,
                },
              ]
            })}
            date={ADM_QA_REPORTS_TIME_RANGE_LABELS[report.range]}
          />
        )
        return [
          bidder,
          ReactDomServer.renderToString(tooltip),
          ...sites.map((site) => {
            return chartMetric.compute(dataBySite, index, site)
          }),
        ]
      }),
    ]
  }, [dataBySite, dimension, sites, chartMetric, report.admLiveDate, currencySymbol])

  return { chartData, legends }
}
