import styled from '@emotion/styled'
import { ControlCenterSite } from '@pubstack/common/src/controlCenter'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DisplayedStack, MultiStackSynchronizePayload, MultiStacksDeployPayload, Stack, StackGroup } from '@pubstack/common/src/stack'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, ReactNode, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { PureAdstackStackDetailsFlyout } from '~/modules/adstack/inventory/sites/PureAdStackStackDetailsFlyout'
import { WithClassName } from '~/types/utils'
import { ControlCenterSection } from './components/ControlCenterSection'

const Wrapper = styled.div`
  padding: 20px;

  ${CollapsiblePanel} + ${CollapsiblePanel} {
    margin-top: 20px;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & span {
    ${Fonts.P2}
  }
`

const FilterAction = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  margin: 16px 0;
  gap: 10px;
  ${_Input} {
    width: 388px;
  }
`

export type ControlCenterViewModel = {
  site: Site
  stackGroups: StackGroup[]
}

type PureAdStackControlCenterPageProps = WithClassName & {
  breadcrumbs: ReactNode
  isLoading: boolean
  outOfSyncList: ControlCenterSite[]
  toDeployList: ControlCenterSite[]
  upToDateList: ControlCenterSite[]
  onCreateUpdatedVersion: (body: MultiStackSynchronizePayload) => Promise<void>
  onDeploy: (body: MultiStacksDeployPayload) => Promise<void>
  onDetailedView: (stack: Stack) => void
  onEditStack: (stackId: string, siteId?: string) => void
  stackDetails: DisplayedStack
  isStackDetailsLoading: boolean
  currencySymbol: CurrencySymbol
  hasAdmRefresh: boolean
}

const _PureAdStackControlCenterPage: FunctionComponent<PureAdStackControlCenterPageProps> = ({
  className,
  breadcrumbs,
  isLoading,
  outOfSyncList,
  toDeployList,
  upToDateList,
  onCreateUpdatedVersion,
  onDeploy,
  onDetailedView,
  onEditStack,
  stackDetails,
  isStackDetailsLoading,
  currencySymbol,
  hasAdmRefresh,
}) => {
  const { control, reset: resetSearch } = useForm({ defaultValues: { search: '' } })
  const [isOpenStackDetailsFlyout, setIsOpenStackDetailsFlyout] = useState<boolean>(false)
  const [canEditDetailedStack, setCanEditDetailedStack] = useState<boolean>(false)
  const search = useWatch({ control, name: 'search' })

  const outOfSyncStacksNumber = new Set(outOfSyncList.flatMap((site) => site.stacks.map((stack) => stack.stackId))).size
  const deploymentStacksNumber = new Set(toDeployList.flatMap((site) => site.stacks.map((stack) => stack.stackId))).size
  const upToDateStacksNumber = new Set(upToDateList.flatMap((site) => site.stacks.map((stack) => stack.stackId))).size

  const canEdit = (stack: Stack, stacks: Stack[]) => stack.status !== 'live' || !stacks.some((s) => s.id !== stack.id && s.stackId === stack.stackId)

  return (
    <Wrapper className={className}>
      <Header>
        {breadcrumbs}
        <span>Update and deploy your stacks from one place</span>
      </Header>
      <FilterAction>
        <Input control={control} name={'search'} iconLeft={'search'} labelIsPlaceholder label={'Search'} />
        {search && <Button iconName={'close'} variant={'tertiary'} onClick={() => resetSearch()} />}
      </FilterAction>

      <ControlCenterSection
        title={`Out of sync (${outOfSyncStacksNumber} stack${outOfSyncStacksNumber > 1 ? 's' : ''})`}
        icon={<Icon name={'sync'} fill={Colors.Warning} />}
        actionIconName={'sync'}
        description={
          <>
            A stack is out of sync if a component’s configuration (such as an ad unit or an integration) has been edited.
            <br />
            To apply the changes, you need to update the stack, which will create a new version of it.
          </>
        }
        isLoading={isLoading}
        data={outOfSyncList}
        emptyIcon={<Icon name={'check_circled'} width={'44px'} height={'44px'} fill={Colors.Success} />}
        emptyDescription={'No update required.'}
        actionLabel={'Create updated version'}
        onActionClick={async (sites: ControlCenterViewModel[]) => {
          const body: MultiStackSynchronizePayload = sites.flatMap(({ site, stackGroups }) =>
            stackGroups.map(({ stacks }) => ({
              siteId: site.id,
              stackId: stacks[0].id,
            }))
          )
          return await onCreateUpdatedVersion(body)
        }}
        titleModal={'Create updated version'}
        confirmLabelModal={'Update stacks'}
        descriptionModal={
          <>
            <p>A new updated version will be created for each following stacks.</p>
            <p>You will need to deploy them to replace old versions on your sites.</p>
          </>
        }
        search={search}
        onDetailedView={(stack, stackGroup) => {
          onDetailedView(stack)
          setCanEditDetailedStack(canEdit(stack, stackGroup.stacks))
          setIsOpenStackDetailsFlyout(true)
        }}
      />
      <ControlCenterSection
        title={`Deployments (${deploymentStacksNumber} stack${deploymentStacksNumber > 1 ? 's' : ''})`}
        icon={<Icon name={'send'} fill={Colors.Success} />}
        actionIconName={'send'}
        description={
          <>
            Fully deploy stacks to production. You can partially deploy a stack version by choosing the percentage of traffic to be applied.
            <br />
            When running such a setup, synchronization is not possible: you need to deploy a version at 100% first.
          </>
        }
        isLoading={isLoading}
        data={toDeployList}
        emptyIcon={<Icon name={'check_circled'} width={'44px'} height={'44px'} fill={Colors.Success} />}
        emptyDescription={'No deployment waiting.'}
        actionLabel={'Deploy'}
        onActionClick={async (sites: ControlCenterViewModel[]) => {
          const body: MultiStacksDeployPayload = sites.flatMap(({ site, stackGroups }) =>
            stackGroups.map((group) => {
              const stacks = group.stacks.filter((s) => (s.ratio ?? 0) > 0)
              return {
                siteId: site.id,
                stackId: stacks[0].stackId,
                id: stacks[0].id,
                ratio: stacks[0].ratio,
              }
            })
          )
          return await onDeploy(body)
        }}
        titleModal={'Deploy'}
        confirmLabelModal={'Deploy stacks'}
        descriptionModal={<p>The following stacks will be deployed on your sites.</p>}
        search={search}
        canAbTest={true}
        onDetailedView={(stack) => {
          onDetailedView(stack)
          setIsOpenStackDetailsFlyout(true)
        }}
      />
      <ControlCenterSection
        title={`Live and up to date (${upToDateStacksNumber} stack${upToDateStacksNumber > 1 ? 's' : ''})`}
        icon={<Icon name={'check_circled'} fill={Colors.Success} />}
        description={'Find all your live and up to date stacks below.'}
        isLoading={isLoading}
        data={upToDateList}
        emptyIcon={<Icon name={'search'} width={'44px'} height={'44px'} fill={Colors.Hurricane} />}
        emptyDescription={'No up to date live stacks found.'}
        extraEmptyDescription={'Create, update and/or deploy the stacks to have the latest versions live on your sites !'}
        isReadOnly={true}
        search={search}
        onDetailedView={(stack) => {
          onDetailedView(stack)
          setIsOpenStackDetailsFlyout(true)
        }}
      />
      <PureAdstackStackDetailsFlyout
        isOpen={isOpenStackDetailsFlyout}
        isLoading={isStackDetailsLoading}
        onClose={() => setIsOpenStackDetailsFlyout(false)}
        detailedStack={stackDetails}
        canEdit={canEditDetailedStack}
        onEditStack={onEditStack}
        currencySymbol={currencySymbol}
        hasAdmRefresh={hasAdmRefresh}
      />
    </Wrapper>
  )
}

export const PureAdStackControlCenterPage = styled(_PureAdStackControlCenterPage)``
