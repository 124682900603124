import { Alert, AlertKeys } from './analytics/check'
import { Dimension } from './analytics/dimension'

export function getQueryLink(check: Alert, toBase64: (str: string) => string): { ctx?: string } {
  const dimensions: Dimension[] = ['tagId', 'bidder', 'adUnit']
  if (!(Object.keys(check.keys) as Dimension[]).some((key: Dimension) => dimensions.includes(key))) return {}
  const filters = (Object.keys(check.keys) as (keyof AlertKeys)[]).reduce((arr, current: keyof AlertKeys) => {
    if (!dimensions.includes(current)) return { ...arr }

    return {
      ...arr,
      [current]: {
        name: current,
        mode: 'include',
        values: [
          {
            label: check.keys[current]?.label,
            value: check.keys[current]?.value,
          },
        ],
      },
    }
  }, {})
  return {
    ctx: toBase64(JSON.stringify(filters)),
  }
}
