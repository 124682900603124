import styled from '@emotion/styled'
import { AdminApiKey } from '@pubstack/common/src/tag'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { ScrollbarStyle } from '~/assets/style/utils'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { Column, EmptyTable, handleTableSearchAndSort, onColumnSort, SortableColumn, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'

const ScopeApiKeysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  padding-bottom: 10px;
  overflow: hidden;
`

const ApiKeyTableActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const ApiKeyTable = styled(Table)`
  max-height: 100%;
  overflow: auto;
  ${ScrollbarStyle}
`

type NewKeyModalProps = { onClickPrimary: (keyName: AdminApiKey['name']) => unknown }
const NewKeyModal: FunctionComponent<NewKeyModalProps> = ({ onClickPrimary }) => {
  const modal = useGlobalModal()
  const { control, reset, handleSubmit } = useForm<Pick<AdminApiKey, 'name'>>({
    defaultValues: {
      name: '',
    },
  })

  const close = () => {
    reset()
    modal.close()
  }

  const onSubmit = (key: Pick<AdminApiKey, 'name'>) => {
    onClickPrimary(key.name)
    close()
  }

  return (
    <Modal.Content>
      <Modal.Title>Add API key</Modal.Title>

      <Modal.Body>
        <Input name={'name'} rules={{ required: true }} labelIsPlaceholder control={control} label={'API key name'} />
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Create key</Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const ADMIN_KEYS_COLUMNS: (Column | SortableColumn<AdminApiKey>)[] = [
  {
    name: 'Name',
    isSortable: true,
    order: 'ascending',
    attributeSort: 'name',
  },
  {
    name: 'ID',
    isSortable: true,
    order: 'none',
    attributeSort: 'id',
  },
]

type PureAdminScopeApiKeysProps = WithClassName & {
  keys: AdminApiKey[]
  isLoading: boolean
  onNewKey: (keyName: AdminApiKey['name']) => unknown
}
const _PureAdminScopeApiKeys: FunctionComponent<PureAdminScopeApiKeysProps> = ({ className, keys, isLoading, onNewKey }) => {
  const { control } = useForm<Pick<AdminApiKey, 'name'>>({
    defaultValues: {
      name: '',
    },
  })
  const modal = useGlobalModal()
  const keySearch = useWatch({ control, name: 'name' })

  const [columns, setColumns] = useState<TableColumns<AdminApiKey>>(ADMIN_KEYS_COLUMNS)

  const displayedKeys = handleTableSearchAndSort(columns, keys, keySearch, ['name'])

  const CreateNewApiKeyButton = () => <Button onClick={() => modal.open(NewKeyModal, { onClickPrimary: onNewKey })}>New API key</Button>

  return (
    <ScopeApiKeysWrapper className={className}>
      <ApiKeyTableActions>
        <Input iconLeft={'search'} name={'name'} control={control} label={'Search'} labelIsPlaceholder />
        <CreateNewApiKeyButton />
      </ApiKeyTableActions>
      <ApiKeyTable
        columns={columns}
        isLoading={isLoading}
        onClickHeading={(column) => {
          onColumnSort(columns, column, setColumns)
        }}
      >
        {displayedKeys.length ? (
          displayedKeys.map((apiKey, index) => (
            <TableRow key={index}>
              <TableCell>{apiKey.name}</TableCell>
              <TableCell>{apiKey.id}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colspan={columns.length}>
              <EmptyTable>
                <div>
                  <h2>No API key</h2>
                  <p>There is no API key existing for this scope.</p>
                </div>
                <CreateNewApiKeyButton />
              </EmptyTable>
            </TableCell>
          </TableRow>
        )}
      </ApiKeyTable>
    </ScopeApiKeysWrapper>
  )
}

export const PureAdminScopeApiKeys = styled(_PureAdminScopeApiKeys)``
