import { GamNetworkConfig } from '@pubstack/common/src/gam/adx'
import { Feature } from '@pubstack/common/src/rolesAndFeatures'
import { AdminScope, AdminScopeWithGamNetwork } from '@pubstack/common/src/scope'
import { AdminCSVUser } from '@pubstack/common/src/user'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAdminMarketplace, useAdminScope, useAdminScopes, useAdminUser } from '~/api/admin-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { downloadCSVdata } from '~/utils/csv'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import PureAdminScopePage from './PureAdminScopePage'

export const AdminScopePage = () => {
  const navigate = useNavigate()
  const [scopes, setScopes] = useState<AdminScope[]>([])
  const { all: allAdminScopes, byId: adminScopesById } = useAdminScopes(null)
  const { byId: adminScopeById, scopeCSV } = useAdminScope(null)
  const { byId: adminUserById, usersCSV } = useAdminUser(null)
  const { upload: uploadMarketplaceJson } = useAdminMarketplace(null)
  const toast = useToast()
  const breadcrumbs = useBreadcrumbs()

  useEffect(() => {
    loadScopes()
  }, [])

  async function loadScopes() {
    const s = await allAdminScopes.get()
    setScopes(s)
  }

  async function getScope(scopeId: AdminScope['id']) {
    return adminScopeById.get(scopeId)
  }

  async function createScope(scope: Omit<AdminScope, 'id' | 'enabled'>) {
    await adminScopesById.post(scope)
    await loadScopes()
  }

  async function updateScope(scope: AdminScope) {
    await adminScopesById.put(`/${scope.id}`, scope)
    await loadScopes()
  }

  async function createPubstackUser(email: string) {
    await adminUserById.post({ email: email })
  }

  async function downloadScopeCSV() {
    const scopes = await scopeCSV.get()
    const displayUserAccess = (scope: AdminScopeWithGamNetwork, feature: Feature) => {
      return `User Access : ${scope.features?.includes(feature) ? 'Enabled' : 'Disabled'}`
    }
    const displayDataIngestion = (scope: AdminScopeWithGamNetwork, dataIngestion: keyof typeof scope) => {
      return `Data Ingestion : ${scope[dataIngestion] ? 'Enabled' : 'Disabled'}`
    }

    const displayRow = (scope: AdminScopeWithGamNetwork, feature: Feature, dataIngestion?: keyof typeof scope) => {
      if (dataIngestion) {
        return `${displayDataIngestion(scope, dataIngestion)} - ${displayUserAccess(scope, feature)}`
      } else {
        return displayUserAccess(scope, feature)
      }
    }

    const hasAdx = (scope: AdminScopeWithGamNetwork) => {
      return `Data Ingestion : ${scope.gamNetworkConfigs.find((g) => g.dataIngestionStatus === 'ACTIVE') ? 'Enabled' : 'Disabled'}`
    }

    const hasGam = (scope: AdminScopeWithGamNetwork, property: keyof GamNetworkConfig) => {
      return `Data Ingestion : ${scope.gamNetworkConfigs.find((g) => g[property]) ? 'Enabled' : 'Disabled'}`
    }

    const csvContent =
      'Id,Name,Adx, OpenBidding, Analytics, Viewability, Ad refresh(legacy), User sessions, A/B Test, SmartAd Server, GAM Web, GAM App, GAM Amp, Ad stack Management, Refresh ADM, Live Chat\n' +
      scopes
        .map((scope: AdminScopeWithGamNetwork) =>
          [
            scope.id,
            scope.name,
            `${hasAdx(scope)} - ${displayUserAccess(scope, 'adx')}`,
            `${hasGam(scope, 'openBiddingEnabled')} - ${displayUserAccess(scope, 'openBidding')}`,
            displayRow(scope, 'analytics'),
            displayRow(scope, 'viewability', 'viewabilityEnabled'),
            displayRow(scope, 'refresh', 'refreshEnabled'),
            displayRow(scope, 'standaloneUserSession', 'standaloneUserSessionEnabled'),
            displayRow(scope, 'abtest'),
            displayRow(scope, 'smartAdServer', 'smartEnabled'),
            `${hasGam(scope, 'gamIntegrationEnabled')} - ${displayUserAccess(scope, 'gamOverviewWeb')}`,
            `${hasGam(scope, 'gamIntegrationEnabled')} - ${displayUserAccess(scope, 'gamOverviewAmp')}`,
            `${hasGam(scope, 'gamIntegrationEnabled')} - ${displayUserAccess(scope, 'gamOverviewApp')}`,
            displayRow(scope, 'adstack', 'kleanadsEnabled'),
            displayRow(scope, 'admRefresh'),
            displayRow(scope, 'liveChat'),
          ].join(',')
        )
        .join('\n')
    downloadCSVdata(csvContent, 'admin-scopes')
  }

  async function downloadUserCSV() {
    const users = await usersCSV.get()
    const csvContent =
      'Email,UserName,LastLogin,ScopeName\n' +
      users
        .map((user: AdminCSVUser) =>
          [
            user.email,
            user.name,
            user.lastLogin,
            user.scopesWithRoles === 'all'
              ? ['all']
              : user.scopesWithRoles
                  .map(
                    (scopeWithRole) =>
                      `${scopes.find((s) => s.id === scopeWithRole.scopeId)?.name ?? scopeWithRole.scopeId}(${scopeWithRole.role} ${scopeWithRole.alertEmailEnabled ? 'alertEmailEnabled' : ''})`
                  )
                  .join(' / '),
          ].join(',')
        )
        .join('\n')

    downloadCSVdata(csvContent, 'admin-users')
  }

  async function archiveScope(scope: AdminScope) {
    await adminScopesById.put(`/${scope.id}`, { ...scope, enabled: false })
    await loadScopes()
  }

  async function unarchiveScope(scope: AdminScope) {
    await adminScopesById.put(`/${scope.id}`, { ...scope, enabled: true })
    await loadScopes()
  }

  async function uploadMarketplaceJSON(json: File) {
    try {
      await uploadMarketplaceJson(json)
      toast.success('Sellers.json has been successfully uploaded')
    } catch (e) {
      toast.alert('An error occurred while trying to upload the file. Please contact the tech team')
    }
  }

  return (
    <PureAdminScopePage
      breadcrumbs={breadcrumbs}
      scopes={{ data: scopes, loading: adminScopesById.loading, error: adminScopesById.error }}
      createScope={createScope}
      updateScope={updateScope}
      getScope={getScope}
      onDeliveryClick={() => navigate('/admin/delivery')}
      onSnoozeAlarmClick={() => navigate('/admin/snooze-alarm')}
      downloadScopeCSV={{ isDownloading: scopeCSV.loading, onClick: downloadScopeCSV }}
      downloadUserCSV={{ isDownloading: usersCSV.loading, onClick: downloadUserCSV }}
      onUploadMarketplace={uploadMarketplaceJSON}
      onScopeRowClick={(scope) => navigate(`/admin/scopes/${scope.id}`)}
      onCreatePubstackUser={createPubstackUser}
      archiveScope={archiveScope}
      unarchiveScope={unarchiveScope}
    />
  )
}
