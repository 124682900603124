import styled from '@emotion/styled'
import { pluralize } from '@pubstack/common/src/localization'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Input, _Input } from '~/components/Input'
import { Toggle } from '~/components/Toggle'

const RefreshCappingToggle = styled.div`
  display: inline-flex;
  gap: 12px;
  align-items: center;

  & > label {
    user-select: none;
    cursor: pointer;
  }
`

const CappingRule = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  & ${_Input} {
    width: 70px;
  }
`

export const RefreshGroupCappingForm: FunctionComponent<{
  refreshCapping?: number
  updateRefreshGroup: (capping: number | undefined) => unknown
}> = ({ refreshCapping, updateRefreshGroup }) => {
  const {
    control,
    formState: { errors },
  } = useForm<{ capping: number }>({ defaultValues: { capping: refreshCapping ?? 10 }, mode: 'onChange' })
  const [isCappingEnabled, setIsCappingEnabled] = useState<boolean>(!!refreshCapping)

  const cappingValue = useWatch({ control, name: 'capping' })

  useEffect(() => {
    // this manual trigger of validation allows react-hook form to reevaluate the "required" conditions on our fields
    updateRefreshGroup(isCappingEnabled ? cappingValue : undefined)
  }, [cappingValue, isCappingEnabled])

  const toggleCapping = () => setIsCappingEnabled(!isCappingEnabled)

  return (
    <>
      <RefreshCappingToggle>
        <label htmlFor={'capping'}>Refresh capping</label>
        <Toggle id={'capping'} onClick={toggleCapping} value={isCappingEnabled} />
      </RefreshCappingToggle>
      {isCappingEnabled && (
        <CappingRule>
          Stop refreshing after
          <Input
            error={errors.capping?.message}
            min={1}
            control={control}
            name={'capping'}
            type={'number'}
            rules={{ required: true, min: { value: 1, message: 'Your number of impressions should be greater than 0' } }}
          />
          {pluralize('impression', cappingValue)}.
        </CappingRule>
      )}
    </>
  )
}
