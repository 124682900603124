import styled from '@emotion/styled'
import { ScopeUser } from '@pubstack/common/src/user'
import { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { EmptyTable, handleTableSearchAndSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'
import { SUPPORT_MAIL } from '~/utils/constants'
import { PureSettingsMembersPageAddFlyout } from './PureSettingsMembersPageAddFlyout'
import { PureSettingsMembersPageDeleteModal } from './PureSettingsMembersPageDeleteModal'
import { PureSettingsMembersPageEditFlyout } from './PureSettingsMembersPageEditFlyout'

const SettingsMembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const SettingsMembersHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const ActionsTableCell = styled(TableCell)`
  width: 0; /** necessary to get the last cell to shrink down to hug action buttons */
`

const SettingsMembersActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

type PureSettingsMembersPageProps = WithClassName & {
  members: ScopeUser[]
  isLoading: boolean
  isError: boolean
  canEditOrDeleteMember: boolean
  onAddMember: (member: ScopeUser) => unknown
  onDeleteMember: (member: ScopeUser) => unknown
  onEditMember: (member: ScopeUser) => unknown
}
const _PureSettingsMembersPage: FunctionComponent<PureSettingsMembersPageProps> = ({ className, members, isLoading, isError, canEditOrDeleteMember, onEditMember, onDeleteMember, onAddMember }) => {
  const modal = useGlobalModal()
  const membersCols: TableColumns<ScopeUser> = [
    { name: 'Member', attributeSort: 'nickname', isSortable: true, order: 'ascending' },
    { name: 'E-mail', attributeSort: 'email', isSortable: true, order: 'none' },
    { name: 'Role', attributeSort: 'role', isSortable: true, order: 'none' },
    ...(canEditOrDeleteMember ? ([{ name: 'Action', isSortable: false }] as TableColumns) : []),
  ]

  const [columns, setColumns] = useState<TableColumns<ScopeUser>>(membersCols)

  const { control, watch } = useForm({
    defaultValues: {
      search: '',
    },
  })
  const memberSearch = watch('search')

  const sortedMembers = handleTableSearchAndSort<ScopeUser>(columns, members, memberSearch, ['nickname', 'email'])

  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [toEditMember, setToEditMember] = useState<ScopeUser>()

  const validateMemberEmail = (email: string) => {
    return !members.some((member) => member.email.toLocaleLowerCase().trim() === email?.toLocaleLowerCase().trim())
  }

  return (
    <SettingsMembersWrapper className={className}>
      <SettingsMembersHeader>
        <Input control={control} name={'search'} labelIsPlaceholder label={'Search'} iconLeft={'search'} />
        {canEditOrDeleteMember && (
          <Button iconName={'add_person'} onClick={() => setIsOpenAddModal(true)}>
            Add member
          </Button>
        )}
      </SettingsMembersHeader>
      <Table isLoading={isLoading} columns={columns} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {sortedMembers.length ? (
          sortedMembers.map((member, index) => (
            <TableRow
              onClick={() => {
                canEditOrDeleteMember && setToEditMember(member)
              }}
              key={index}
            >
              <TableCell>{member.nickname}</TableCell>
              <TableCell>{member.email}</TableCell>
              <TableCell>{member.role[0].toUpperCase() + member.role.slice(1)}</TableCell>
              {canEditOrDeleteMember && (
                <ActionsTableCell>
                  <SettingsMembersActions>
                    <Tooltip title={'Edit'}>
                      <Button
                        onClick={(event) => {
                          event.stopPropagation()
                          setToEditMember(member)
                        }}
                        variant={'tertiary'}
                        iconName={'edit'}
                      />
                    </Tooltip>
                    <Tooltip title={'Remove'}>
                      <Button
                        onClick={(event) => {
                          event.stopPropagation()
                          modal.open(PureSettingsMembersPageDeleteModal, {
                            member,
                            onDelete: () => onDeleteMember(member),
                          })
                        }}
                        variant={'tertiary'}
                        iconName={'delete'}
                      />
                    </Tooltip>
                  </SettingsMembersActions>
                </ActionsTableCell>
              )}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colspan={columns.length}>
              <EmptyTable>
                {isError ? (
                  <div>
                    <h2>An error occurred when trying to get members of your team</h2>
                    <p>
                      Please refresh the page or contact <Link label={SUPPORT_MAIL} href={`mailto:${SUPPORT_MAIL}`} /> if the issue persists.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h2>No team members</h2>
                    <p>There are no members in your team.</p>
                    {canEditOrDeleteMember && (
                      <Button iconName={'add_person'} onClick={() => setIsOpenAddModal(true)}>
                        Add member
                      </Button>
                    )}
                  </div>
                )}
              </EmptyTable>
            </TableCell>
          </TableRow>
        )}
      </Table>
      <PureSettingsMembersPageAddFlyout isOpen={isOpenAddModal} onRequestClose={() => setIsOpenAddModal(false)} onAdd={onAddMember} validateMember={validateMemberEmail} />
      <PureSettingsMembersPageEditFlyout member={toEditMember} setMember={setToEditMember} onSaveChanges={onEditMember} />
    </SettingsMembersWrapper>
  )
}

export const PureSettingsMembersPage = styled(_PureSettingsMembersPage)``
