import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { WithClassName } from '~/types/utils'
import { copyToClipboard } from '~/utils/string'
import Button from './Button'

type CopyButtonProps = WithClassName & {
  contentText: string
  disabled?: boolean
  onCopy?: () => unknown
}

const _CopyButton: FunctionComponent<CopyButtonProps> = ({ contentText, disabled, onCopy }) => {
  const onClickCopy = (scopeId: string) => {
    copyToClipboard(window.document, scopeId)
  }

  return (
    <Button
      variant={'secondary'}
      iconName={'copy'}
      disabled={disabled}
      onClick={() => {
        onClickCopy(contentText)
        onCopy && onCopy()
      }}
    />
  )
}

export const CopyButton = styled(_CopyButton)``
