import styled from '@emotion/styled'
import { FC, PropsWithChildren, ReactElement, cloneElement, useEffect, useState } from 'react'
import { PopoverAlign, PopoverPosition, Popover as ReactTinyPopover } from 'react-tiny-popover'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevelValues } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'

const PopoverContent = styled.div`
  overflow: hidden;
  background: ${Colors.White};
  ${Fonts.colors.Jet}
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(54, 54, 54, 0.3);
  ${Fonts.P1}
`

type PopoverProps = WithClassName & {
  trigger: ReactElement<{ isOpened?: boolean }>
  align?: PopoverAlign
  open?: boolean
  setOpen?: (_: boolean) => void
  positions?: PopoverPosition[]
  isToggleable?: boolean
}
const _Popover: FC<PropsWithChildren<PopoverProps>> = ({ className, children, trigger, align, open, setOpen, positions, isToggleable }) => {
  const [internalOpen, setInternalOpen] = useState(open || false)
  useEffect(() => setInternalOpen(open || false), [open])

  return (
    <ReactTinyPopover
      containerStyle={{ zIndex: `${ElevationLevelValues.high}` }}
      isOpen={internalOpen}
      content={<PopoverContent className={className}>{children}</PopoverContent>}
      positions={positions?.length ? positions : ['bottom', 'top', 'right', 'left']}
      onClickOutside={() => (setOpen || setInternalOpen)(false)}
      align={align ?? 'start'}
    >
      <span onClick={() => (setOpen || setInternalOpen)(isToggleable ? !open : true)}>{cloneElement(trigger, { isOpened: open || internalOpen })}</span>
    </ReactTinyPopover>
  )
}
const Popover = styled(_Popover)``

export { Popover }
