import styled from '@emotion/styled'
import { SiteSpaConfig, SpaMode } from '@pubstack/common/src/kleanadsScopeConfig'
import { FunctionComponent, useEffect } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { RadioGroup, RadioOption } from '~/components/RadioGroup'
import { Skeleton } from '~/components/Skeleton'
import { Toggle } from '~/components/Toggle'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'
import { SiteOnboardingStepCard } from './components/SiteOnboardingStepCard'

type SiteOverviewForm = {
  adManagementEnabled: boolean
  spaConfig: SiteSpaConfig
}

const SubTitle = styled.span`
  ${Fonts.H2};
  ${Fonts.colors.Jet};
  font-weight: 500;
  margin-bottom: 4px;
`

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const ToggleContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ToggleText = styled.span`
  ${Fonts.P2};
  ${Fonts.colors.Hurricane};
`

const Text = styled.div`
  ${Fonts.P1};
  ${Fonts.colors.Jet};
`

const PageDetectionWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`

const AdManagement = styled.div`
  margin-bottom: 16px;
`

const SPA = styled.div`
  margin-bottom: 24px;
`

const GetStarted = styled.div`
  margin-bottom: 20px;
`

const Steps = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`

type SiteStepKey = 'adUnits' | 'contextKeys' | 'gamAndAUnitPath' | 'biddersAndModules' | 'defaultStack'
const STEPS_ORDER: SiteStepKey[] = ['adUnits', 'contextKeys', 'gamAndAUnitPath', 'biddersAndModules', 'defaultStack']
const STEPS_CONFIG: { [key in SiteStepKey]: { name: string; description: string; optional?: boolean; requirements?: SiteStepKey[] } } = {
  adUnits: {
    name: 'Ad units',
    description: 'Start building your inventory by creating at least one ad unit per device.',
  },
  contextKeys: {
    name: 'Context keys',
    description: 'Create context keys to build the ad unit path dynamically.',
    optional: true,
  },
  gamAndAUnitPath: {
    name: 'GAM and ad unit path',
    description: 'Set GAM and define the ad unit path architecture.',
  },
  biddersAndModules: {
    name: 'Bidders and modules',
    description: 'Set the header bidding bidders and modules to use on this site.',
    optional: true,
  },
  defaultStack: {
    name: 'Default stack',
    description: 'Set the default stack: select the ad units and the monetization rules to apply.',
    requirements: ['adUnits', 'gamAndAUnitPath'],
  },
}

type SiteActivationModalProps = {
  onEnable: () => void
  siteName: string
}
const SiteActivationModal: FunctionComponent<SiteActivationModalProps> = ({ onEnable, siteName }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Activate ad management</Modal.Title>

      <Modal.Body>
        <div>This action is not reversible without the help of Pubstack&apos;s support.</div>
        <div>Are you sure you want to enable ad management on {siteName} ?</div>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onEnable}>
          Enable
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

type PureSiteOverviewSiteConfig = {
  [key in SiteStepKey]: {
    done?: boolean
    onClick: () => void
  }
}
type PureSiteOverviewProps = WithClassName & {
  name: string
  adManagementEnabled: boolean
  canEnableAdManagement: boolean
  onAdManagementEnable: () => Promise<void> | void
  steps: PureSiteOverviewSiteConfig
  isLoading: boolean
  spaConfig: SiteSpaConfig
  onSpaUpdate: (spaConfig: SiteSpaConfig) => Promise<void> | void
  canEnableSpa: boolean
}
const _PureSiteOverview: FunctionComponent<PureSiteOverviewProps> = ({
  className,
  name,
  adManagementEnabled,
  onAdManagementEnable,
  steps,
  canEnableAdManagement,
  isLoading,
  spaConfig,
  onSpaUpdate,
  canEnableSpa,
}) => {
  const modal = useGlobalModal()
  const { control, setValue } = useForm<SiteOverviewForm>({ defaultValues: { adManagementEnabled: adManagementEnabled ?? false, spaConfig: spaConfig } })
  const { enabled: spaConfigEnabled, mode: spaConfigMode } = useWatch({ control, name: 'spaConfig' })
  const pageDetectionOptions: RadioOption[] = [
    {
      label: 'Manual',
      value: 'manual',
    },
    {
      label: 'Auto',
      value: 'auto',
    },
  ]

  useEffect(() => {
    setValue('adManagementEnabled', adManagementEnabled)
  }, [adManagementEnabled])

  useEffect(() => {
    setValue('spaConfig', spaConfig)
  }, [spaConfig])

  return (
    <div className={className}>
      {isLoading ? (
        <Skeleton bigger />
      ) : (
        <>
          <AdManagement>
            <ToggleWrapper>
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Toggle
                    value={value}
                    onClick={() => {
                      modal.open(SiteActivationModal, {
                        siteName: name,
                        onEnable: async () => {
                          onChange(!value)
                          await onAdManagementEnable()
                          modal.close()
                        },
                      })
                    }}
                    disabled={adManagementEnabled || !canEnableAdManagement}
                  />
                )}
                name={'adManagementEnabled'}
              />
              <ToggleContentWrapper>
                <span>Ad management</span>
                <ToggleText>Manage and monetize your site via Pubstack.</ToggleText>
              </ToggleContentWrapper>
            </ToggleWrapper>
          </AdManagement>
          {adManagementEnabled && (
            <>
              {canEnableSpa && (
                <SPA>
                  <ToggleWrapper>
                    <Controller
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Toggle
                          value={value}
                          onClick={async () => {
                            onChange(!value)
                            await onSpaUpdate({ enabled: !value, mode: spaConfigMode })
                          }}
                        />
                      )}
                      name={'spaConfig.enabled'}
                    />
                    <ToggleContentWrapper>
                      <span>SPA</span>
                      <ToggleText>Enable if the site is a Single Page Application.</ToggleText>
                      {spaConfigEnabled && (
                        <PageDetectionWrapper>
                          <span>Page detection</span>
                          <RadioGroup
                            control={control}
                            name={'spaConfig.mode'}
                            options={pageDetectionOptions}
                            onChange={async (value) => {
                              await onSpaUpdate({ enabled: spaConfigEnabled, mode: value as SpaMode })
                            }}
                          />
                        </PageDetectionWrapper>
                      )}
                    </ToggleContentWrapper>
                  </ToggleWrapper>
                </SPA>
              )}

              <GetStarted>
                <SubTitle>Get started</SubTitle>
                <Text>Unlock the full potential of your site in a few steps.</Text>
              </GetStarted>
              <Steps>
                {STEPS_ORDER.map((key) => {
                  const { requirements, ...config } = STEPS_CONFIG[key]
                  const requirementNames = (requirements || []).map((r) => STEPS_CONFIG[r].name)
                  const step = steps[key] ?? {}
                  const locked = (requirements || []).some((r) => !steps[r].done)
                  return <SiteOnboardingStepCard key={key} requirements={requirementNames} locked={locked} {...config} {...step} />
                })}
              </Steps>
            </>
          )}
        </>
      )}
    </div>
  )
}
export const PureSiteOverview = styled(_PureSiteOverview)``
