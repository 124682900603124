import styled from '@emotion/styled'
import { RefreshRule } from '@pubstack/common/src/stack'
import { FunctionComponent, useEffect } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Input, _Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Toggle } from '~/components/Toggle'
import { WithClassName } from '~/types/utils'

const TitleBar = styled.div`
  display: flex;
  padding-bottom: 40px;
  width: 100%;
  justify-content: space-between;
  h1 {
    ${Fonts.H1}
  }
  ${Link} {
    margin-bottom: 12px;
  }
`

const AdStackEditContentCard = styled(ContentCard)`
  width: 796px;
`

const AdStackEditPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const BottomBar = styled.div`
  border-top: 1px solid ${Colors.Platinum};
`

const Title = styled.div`
  ${Fonts.H1}
  padding-bottom: 20px;
  font-weight: regular;
`

const PageWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-width: 1000px;
  ${Fonts.P1}
  h2 {
    ${Fonts.H2}
    font-weight: 500;
  }

  ${_Input} {
    width: 394px;
    padding: 20px 0;
  }
`

const Subtitle = styled.div`
  ${Fonts.H2};
  font-weight: 500;
  span {
    display: inline-flex;
    align-items: center;
  }
`

const FormButtonsWrapper = styled.div`
  text-align: right;
  padding: 4px 20px;
  ${Button} {
    margin-left: 15px;
  }
`

const FormToggle = styled.div`
  display: inline-flex;
  gap: 8px;
  flex-wrap: wrap;
  p {
    ${Fonts.P2};
    ${Fonts.colors.SlateGrey};
  }
  padding-top: 16px;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 12px;
`

const RuleDefinitionWrapper = styled.div`
  ${Fonts.P1};
  padding-top: 6px;
  padding-bottom: 6px;
`

type PureAdStackRefreshEditPageProps = WithClassName & {
  refreshRule?: RefreshRule
  onBack: () => void
  breadcrumbs: React.ReactNode
  onCreateRefreshRule: (refreshRule: RefreshRule) => Promise<void>
  onUpdateRefreshRule: (refreshRule: RefreshRule) => Promise<void>
  existingRefreshRules: RefreshRule[]
  isEditing: boolean
  isLoading: boolean
}

const _PureAdStackRefreshEditPage: FunctionComponent<PureAdStackRefreshEditPageProps> = ({
  className,
  refreshRule,
  onBack,
  onCreateRefreshRule,
  breadcrumbs,
  existingRefreshRules,
  isEditing,
  onUpdateRefreshRule,
  isLoading,
}) => {
  const defaultValues: RefreshRule = refreshRule ?? {
    id: '',
    name: '',
    creationTime: 123,
    technicalId: '',
    timer: 1,
  }

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm<RefreshRule>({ mode: 'onChange', defaultValues })

  const onSubmit: SubmitHandler<RefreshRule> = (newRule) => {
    const sanitize = (value?: string | number) => Math.trunc(Number(value ?? 0))
    const toEdit: RefreshRule = {
      ...newRule,
      timer: sanitize(newRule.timer),
      capping: newRule.cappingEnabled ? sanitize(newRule.capping) : undefined,
    }
    isEditing ? onUpdateRefreshRule(toEdit) : onCreateRefreshRule(toEdit)
  }

  useEffect(() => {
    reset(refreshRule)
  }, [refreshRule])

  const { cappingEnabled } = useWatch({ control })

  return (
    <AdStackEditPageWrapper>
      <TitleBar>
        {breadcrumbs}
        <Button variant={'tertiary'} onClick={onBack}>
          Back
        </Button>
      </TitleBar>
      <PageWrapper>
        <AdStackEditContentCard color={Colors.Petrol}>
          <Title>{isEditing ? `Edit: ${refreshRule?.name ?? ''}` : 'New refresh rule'}</Title>
          <BottomBar />
          {!isLoading && (
            <form className={className} id={'newRuleForm'} onSubmit={handleSubmit(onSubmit)}>
              <FormWrapper>
                <Subtitle>
                  <h2>Identification</h2>
                </Subtitle>
                <Input
                  type={'text'}
                  label={'Name'}
                  control={control}
                  name={'name'}
                  error={errors.name?.message}
                  rules={{
                    required: { value: true, message: 'Name required.' },
                    validate: {
                      value: (formValue) => {
                        return (formValue as string) !== refreshRule?.name && existingRefreshRules.map((f) => f.name).includes(formValue as string) ? 'A rule with this name already exists' : true
                      },
                    },
                  }}
                  maxLength={100}
                />
                <Subtitle>
                  <h2>Viewability timer</h2>
                </Subtitle>
                <RuleDefinitionWrapper>
                  <p>Trigger a refresh when an impression is in view during:</p>
                </RuleDefinitionWrapper>
                <InputWrapper>
                  <Input
                    type={'number'}
                    label={'Viewability timer'}
                    control={control}
                    name={'timer'}
                    key={'timer'}
                    error={errors.timer?.message}
                    rules={{
                      required: { value: true, message: 'Viewability timer is required.' },
                      min: { value: 1, message: 'Please enter a value, 1 second at least.' },
                      pattern: { value: RegExp('^[0-9]*$'), message: 'Please enter a value, 1 second at least.' },
                    }}
                    min={1}
                    step={1}
                    iconRight={'second'}
                  />
                </InputWrapper>
                <Subtitle>
                  <h2>Capping</h2>
                </Subtitle>
                <RuleDefinitionWrapper>
                  <p>Activate capping to define a number of refresh allowed.</p>
                </RuleDefinitionWrapper>
                <Controller
                  control={control}
                  name={'cappingEnabled'}
                  render={({ field: { onChange, value } }) => (
                    <FormToggle>
                      <Toggle
                        id={'cappingEnabled'}
                        onClick={() => {
                          onChange(!value)
                        }}
                        value={value}
                      />
                      Capping
                    </FormToggle>
                  )}
                />
                {cappingEnabled && (
                  <InputWrapper>
                    <Input
                      type={'number'}
                      label={'Stop refresh after'}
                      control={control}
                      name={'capping'}
                      key={'timer'}
                      error={errors.capping?.message}
                      rules={{
                        required: { value: !!cappingEnabled, message: 'Capping is required.' },
                        min: { value: 1, message: 'Please enter a value, 1 impression at least.' },
                        pattern: { value: RegExp('^[0-9]*$'), message: 'Please enter a value, 1 impression at least.' },
                      }}
                      min={1}
                      step={1}
                    />{' '}
                    impressions
                  </InputWrapper>
                )}
              </FormWrapper>
              <FormButtonsWrapper>
                <Button variant={'tertiary'} onClick={onBack}>
                  Cancel
                </Button>
                <Button variant={'primary'} type={'submit'} disabled={!isDirty}>
                  Save rule
                </Button>
              </FormButtonsWrapper>
            </form>
          )}
        </AdStackEditContentCard>
      </PageWrapper>
    </AdStackEditPageWrapper>
  )
}

export const PureAdStackRefreshEditPage = styled(_PureAdStackRefreshEditPage)``
