import styled from '@emotion/styled'
import { SiteMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { TimelineConfiguration, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import { adxImpressionRevenue, allImpressionRevenue, hbImpressionRevenue, hbWinningCpmSum, openBiddingImpressionRevenue } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { displayWithCurrency } from '~/utils/string'
import { PureSiteChartTitle } from './PureSiteChartTitle'

const useConfig = (data: SiteMetricsByTime) => {
  return useMemo((): TimelineConfiguration<SiteMetricsByTime> => {
    const hasBidWin = hbImpressionRevenue.isComputable(data) && hbWinningCpmSum.isComputable(data)
    const hasHb = hbImpressionRevenue.isComputable(data) && hbWinningCpmSum.isComputable(data)
    const hasAdx = adxImpressionRevenue.isComputable(data)
    const hasOb = openBiddingImpressionRevenue.isComputable(data)
    return {
      getChartOptions: ({ currencySymbol }) => ({
        seriesType: 'area',
        series: hasBidWin ? { [0]: { type: 'line', lineDashStyle: [7, 5] } } : {},
        vAxis: {
          ...AnalyticsDefaultChartOptions.vAxis,
          format: displayWithCurrency('#,###', currencySymbol),
          minValue: 0,
        },
      }),
      legendConfig: [...(hasBidWin ? ['bidWin'] : []), ...(hasHb ? ['hb'] : []), ...(hasAdx ? ['adx'] : []), ...(hasOb ? ['openBidding'] : [])],
      tooltipConfig: [...(hasBidWin ? [['bidWin']] : []), [...(hasHb ? ['hb'] : []), ...(hasAdx ? ['adx'] : []), ...(hasOb ? ['openBidding'] : [])], ['allImpressionRevenue']],
      dataConfig: [
        {
          name: 'allImpressionRevenue',
          label: allImpressionRevenue.name,
          getFormula: () => allImpressionRevenue,
          isComputable: ({ data }) => allImpressionRevenue.isComputable(data),
          iconName: 'sigma',
        },
        {
          name: 'bidWin',
          label: hbWinningCpmSum.name,
          isComputable: ({ data }) => hbImpressionRevenue.isComputable(data) && hbWinningCpmSum.isComputable(data),
          getFormula: () => hbWinningCpmSum,
          iconName: 'data_line_dashed',
          withValue: true,
        },
        {
          name: 'hb',
          label: hbImpressionRevenue.name,
          isComputable: ({ data }) => hbImpressionRevenue.isComputable(data) && hbWinningCpmSum.isComputable(data),
          getFormula: () => hbImpressionRevenue,
          withValue: true,
        },
        {
          name: 'adx',
          label: adxImpressionRevenue.name,
          getFormula: () => adxImpressionRevenue,
          isComputable: ({ data }) => adxImpressionRevenue.isComputable(data),
          withValue: true,
        },
        {
          name: 'openBidding',
          label: openBiddingImpressionRevenue.name,
          getFormula: () => openBiddingImpressionRevenue,
          isComputable: ({ data }) => openBiddingImpressionRevenue.isComputable(data),
          withValue: true,
        },
      ],
    }
  }, [data])
}

type PureSiteRevenueChartProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    data: SiteMetricsByTime
    currencySymbol: CurrencySymbol
    timezone: Timezone
    currentEpoch: DateTime
  }
const _PureSiteRevenueChart: FunctionComponent<PureSiteRevenueChartProps> = ({ data, currencySymbol, currentEpoch, timezone, ...props }) => {
  const totalRevenue = allImpressionRevenue.displayable(allImpressionRevenue.isComputable(data) ? allImpressionRevenue.sum(data) : 0, currencySymbol)
  const title = <PureSiteChartTitle title={'Revenue'} infoLabel={'total'} infoValue={totalRevenue} />

  const currentConfig = useConfig(data)

  const { chart, legends } = useTimelineChart({ currentConfig, data, currencySymbol, currentEpoch, timezone })

  return <AnalyticsChartWidget {...props} icon={'site'} title={title} chart={chart} legends={legends} />
}
export const PureSiteRevenueChart = styled(_PureSiteRevenueChart)``
