import { GamNetworkConfig } from '@pubstack/common/src/gam/adx'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGamIntegration, useGamManagerAdStackConfig } from '~/api/admin-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { PureGamManager } from './PureGamManager'

export const GamManager: FunctionComponent = () => {
  const { scopeId } = useParams()
  const toast = useToast()

  const [running, setRunning] = useState(false)
  const [loading, setLoading] = useState(true)

  const { byId: gamIntegrationById } = useGamIntegration(null, scopeId ?? '')
  const { byId: gamManagerAdStackConfig } = useGamManagerAdStackConfig(null, scopeId ?? '')
  const [networks, setNetworks] = useState<GamNetworkConfig[]>([])

  const loadGamIntegration = async () => {
    setLoading(true)
    if (gamIntegrationById.loading) {
      gamIntegrationById.abort()
    }
    const gamint = await gamIntegrationById.get()
    setNetworks(gamint.gamNetworks ?? [])
    setLoading(false)
  }

  useEffect(() => {
    loadGamIntegration()
  }, [])

  const runCreationProcess = async (network: GamNetworkConfig) => {
    setLoading(true)
    await gamManagerAdStackConfig
      .put(network.id)
      .then(() => {
        toast.success(`Success for network ${network.id}`)
      })
      .catch(() => {
        toast.alert(`Failed for network ${network.id}`)
      })
    setLoading(false)
  }

  const onNetworkChange = (option: GamNetworkConfig) => {
    setLoading(true)
    gamManagerAdStackConfig.get(option.id).then((res) => {
      setLoading(false)
      if (res.status === 'RUNNING') {
        setRunning(true)
      } else {
        setRunning(false)
      }
    })
  }
  return <PureGamManager networks={networks} isRunning={running} isLoading={loading} runCreationProcess={runCreationProcess} onNetworkChange={onNetworkChange} />
}
