import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { BidMetricsByDim, MappedName } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useCallback } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsBreakdown, useAnalyticsBreakdown } from '~/modules/analytics/AnalyticsBreakdown'
import { bidCpm, bidRate, bidRequests, bidTimeoutRate, hbImpressionRevenue, hbWinRate } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { TabConfig } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

type PureBidderBreakdownProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon' | 'info'> & {
    rawData?: BidMetricsByDim
    currencySymbol: CurrencySymbol
    onTabChange: (dimension: Dimension) => void
    onRowClick: (name: MappedName) => void
    defaultTabIndex?: number
    onSortChange: (metric: string) => void
    tabsConfig: TabConfig[]
  }
const _PureBidderBreakdown: FunctionComponent<PureBidderBreakdownProps> = ({ currencySymbol, rawData, onTabChange, tabsConfig, defaultTabIndex, ...props }) => {
  const breakdownProps = useAnalyticsBreakdown<string>({
    currencySymbol,
    rawData,
    onTabChange,
    tabsConfig,
    defaultTabIndex,
    maxResults: 50,
    getConfigs: useCallback(
      () => [
        [
          {
            displayedAs: 'gauge',
            propertyName: 'name',
            sortLabel: 'Bidder name',
            getRawValue: ({ data }) => (data.bidRequests.percentage !== undefined ? data.bidRequests.percentage : data.impressionRevenue.percentage ?? 0),
            max: 1,
          },
          {
            formula: hbImpressionRevenue,
            propertyName: 'impressionRevenue',
            tooltip: ANALYTICS_TOOLTIPS.PREBID_REVENUE,
          },
          {
            formula: bidRequests,
            propertyName: 'bidRequests',
            tooltip: ANALYTICS_TOOLTIPS.BID_REQUESTS,
          },
          {
            formula: bidRate,
            propertyName: 'bidRate',
            tooltip: ANALYTICS_TOOLTIPS.BID_RATE,
            status: {
              alert: 5,
              warning: 15,
            },
          },
          {
            formula: bidCpm,
            propertyName: 'bidCpm',
            tooltip: ANALYTICS_TOOLTIPS.BID_CPM,
          },
          {
            formula: hbWinRate,
            propertyName: 'hbWinRate',
            tooltip: ANALYTICS_TOOLTIPS.PREBID_WIN_RATE,
          },
          {
            formula: bidTimeoutRate,
            propertyName: 'bidTimeoutRate',
            tooltip: ANALYTICS_TOOLTIPS.BID_TIMEOUT_RATE,
            status: {
              alert: 10,
              warning: 5,
            },
          },
        ],
      ],
      []
    ),
    getColumnsConfigs: useCallback(() => ['impressionRevenue', 'bidRequests', 'bidRate', 'bidCpm', 'hbWinRate', 'bidTimeoutRate'], []),
    defaultSort: 'bidRequests',
  })
  return <AnalyticsBreakdown {...props} {...breakdownProps} />
}
export const PureBidderBreakdown = styled(_PureBidderBreakdown)``
