import styled from '@emotion/styled'
import { AdUnitDevice } from '@pubstack/common/src/adunitDevice'
import { LazyLoadingRuleConfig } from '@pubstack/common/src/stack'
import { capitalizeFirstLetter } from '@pubstack/common/src/stringUtils'
import { FunctionComponent, useState } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Toggle } from '~/components/Toggle'
import { StackFormData } from '~/modules/adstack/inventory/sites/stack/PureStackEdit'
import { WithClassName } from '~/types/utils'

type LazyLoadingRuleSelectorProps = WithClassName & {
  control: Control<StackFormData>
  availableRules: SelectOptionProp<string>[]
  availableDevices: AdUnitDevice[]
}

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 4px;
`

const Title = styled.h2`
  ${Fonts.H2};
  ${Colors.Jet};
  font-weight: 500;
`

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 760px;
`

const _LazyLoadingRuleSelector: FunctionComponent<LazyLoadingRuleSelectorProps> = ({ control, availableRules, availableDevices }) => {
  const formData = useWatch({ control, name: 'lazyLoadingRules' })

  const [visible, setVisible] = useState(!!formData)
  const [formDataMemo, setFormDataMemo] = useState<LazyLoadingRuleConfig[] | undefined>(formData ?? availableDevices.map((device) => ({ device, ruleId: '' })))

  return (
    <Controller
      control={control}
      name={'lazyLoadingRules'}
      render={({ field: { onChange }, formState: { errors } }) => {
        return (
          <>
            <ToggleWrapper>
              <Toggle
                value={visible}
                onClick={() => {
                  setVisible(!visible)
                  if (visible) {
                    onChange(undefined)
                    setFormDataMemo(formData)
                  } else {
                    onChange(formDataMemo)
                  }
                }}
              />
              <Title>Lazy loading</Title>
            </ToggleWrapper>
            {visible && (
              <SelectWrapper>
                {availableDevices.map((device, index) => (
                  <Select
                    searchable
                    isMessageBlock
                    rules={{ required: `You must select a rule for ${device}.` }}
                    error={errors?.lazyLoadingRules?.[index]?.ruleId?.message}
                    key={index}
                    control={control}
                    name={`lazyLoadingRules.${index}.ruleId`}
                    label={`${capitalizeFirstLetter(device)} rule`}
                    options={availableRules.sort((optionA, optionB) => optionA.label.localeCompare(optionB.label))}
                  />
                ))}
              </SelectWrapper>
            )}
          </>
        )
      }}
    />
  )
}

export const LazyLoadingRuleSelector = styled(_LazyLoadingRuleSelector)``
