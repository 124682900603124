import styled from '@emotion/styled'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius, Sizes } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'
import { Link } from './Link'
import { Spinner } from './Spinner'

export type WidgetProps = WithClassName & {
  isLoading: boolean
  error: boolean
  empty: boolean
  isTodayDisabled?: boolean
  info?: ReactNode
  onRefreshClick: () => void
  onSwitchTo7DaysClick?: () => void
  hideHeader?: boolean
} & (
    | {
        hideHeader: true
      }
    | {
        hideHeader?: false
        icon: IconName
        title: ReactNode
        subTitle?: ReactNode
      }
  )

const WidgetCard = styled.div<{ hideHeader?: boolean }>`
  ${BorderRadius.style}
  border: 1px solid ${Colors.Platinum};
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  padding: ${({ hideHeader }) => (hideHeader ? '12px' : '16px 12px')};
  min-height: 0;
`
const WidgetHeader = styled.div`
  margin-bottom: 24px;
`
const WidgetHeaderSecondLine = styled.div``

const WidgetHeaderFirstLine = styled.div<{ error: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  opacity: ${({ error }) => (error ? 0 : 1)};
  ${Fonts.P1};
  ${Fonts.colors.Jet};
`
const WidgetSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
  margin-left: 6px;
`
const WidgetTitle = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  ${Icon} {
    margin-right: 4px;
  }
`

const WidgetContent = styled.div<{ isLoading: boolean }>`
  opacity: ${({ isLoading }) => (isLoading ? 0.4 : 1)};
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const ContentErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
  ${Icon} {
    margin-bottom: 16px;
  }
  a {
    cursor: pointer;
  }
  ${Fonts.P1}
  ${Fonts.colors.Jet}
`

const Subtitle = styled.div`
  ${Fonts.P2};
  ${Fonts.colors.Hurricane};
  font-style: italic;

  margin-left: 26px;
`

const _Widget: FC<PropsWithChildren<WidgetProps>> = ({ className, children, info, isLoading, onRefreshClick, onSwitchTo7DaysClick, error, empty, isTodayDisabled, ...props }) => {
  return (
    <WidgetCard className={className} hideHeader={props.hideHeader}>
      {(props.hideHeader === false || props.hideHeader === undefined) && (
        <WidgetHeader>
          <WidgetHeaderFirstLine error={error}>
            <WidgetTitle>
              <Icon name={props.icon} width={Sizes['22']} fill={Colors.Jet} />
              {props.title}
              {isLoading && <WidgetSpinner isColored />}
            </WidgetTitle>
            {!!info && info}
          </WidgetHeaderFirstLine>
          <WidgetHeaderSecondLine>
            <Subtitle>{props.subTitle}</Subtitle>
          </WidgetHeaderSecondLine>
        </WidgetHeader>
      )}
      <WidgetContent isLoading={isLoading}>
        {error ? (
          <ContentErrorMessageWrapper>
            <Icon name={'sync_problem'} width={'48px'} />
            <div>Oops, something wrong happened.</div>
            <div>
              Please, try to <a onClick={onRefreshClick}>refresh</a> the widget.
            </div>
          </ContentErrorMessageWrapper>
        ) : isTodayDisabled ? (
          <ContentErrorMessageWrapper>
            <Icon name={'night_sky'} width={'190px'} />
            <div>We are doing our best to provide you data with the speed of light.</div>
            <div>Unfortunately real-time data is not available here.</div>
            <div>
              For an optimal experience, we suggest to select at least <Link onClick={onSwitchTo7DaysClick} label={'7 days of data'} />
            </div>
          </ContentErrorMessageWrapper>
        ) : empty ? (
          <ContentErrorMessageWrapper>
            <Icon name={'sentiment_dissatisfied'} width={'24px'} />
            <div>Oops, no data</div>
            <div>
              Please, try to change your filters and <Link onClick={onRefreshClick} label={'refresh'} /> the widget.
            </div>
          </ContentErrorMessageWrapper>
        ) : (
          children
        )}
      </WidgetContent>
    </WidgetCard>
  )
}

export const Widget = styled(_Widget)``
