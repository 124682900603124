import { useMemo } from 'react'
import { useLocation, useMatches, useNavigate, useSearchParams } from 'react-router-dom'
import { PubstackRouteMeta } from '~/auth/ProtectedRoute'
import { BreadcrumbProps, Breadcrumbs } from '~/components/Breadcrumbs'

export const useBreadcrumbs = <T,>(additionalParams?: T) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [search] = useSearchParams()
  const matches = useMatches()

  const breadcrumbsMatches = matches.filter((match) => {
    return !!(match.handle as PubstackRouteMeta).breadcrumbLabel ?? true
  })

  const breadcrumbs: BreadcrumbProps[] = breadcrumbsMatches.map((match, index, full) => {
    let label = ''
    const metaData = match.handle as PubstackRouteMeta<T>
    if (metaData) {
      const breadcrumbLabel = metaData.breadcrumbLabel
      if (breadcrumbLabel) {
        if (typeof breadcrumbLabel === 'string') {
          label = breadcrumbLabel
        } else {
          label = breadcrumbLabel({ search, additionalParams })
        }
      }
    }
    return {
      label,
      route: full.length - 1 !== index ? match.pathname : undefined,
    }
  })

  const breadcrumbSubtitle = (breadcrumbsMatches.pop()?.handle as PubstackRouteMeta).breadcrumbSubtitle
  return useMemo(() => <Breadcrumbs data={breadcrumbs} subtitle={breadcrumbSubtitle} onClick={({ route }) => route && navigate(route)} />, [location, search, additionalParams])
}
