import styled from '@emotion/styled'
import { RefreshGroup } from '@pubstack/common/src/refresh'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ScrollbarStyle } from '~/assets/style/utils'
import Button from '~/components/Button'
import Chip from '~/components/Chip'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { Icon } from '~/components/Icon'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'

const RecapContent = styled.div`
  ${ScrollbarStyle};
  max-height: 380px;
`

const RecapPanel = styled(CollapsiblePanel)`
  ${ScrollbarStyle}

  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }

  border: none;
  border-radius: 0;
  ${Fonts.P1}
  ${Fonts.colors.Jet}
  &:not(:last-of-type) {
    border-bottom: 1px solid ${Colors.Platinum};
  }
`

const ChipWrapper = styled.div`
  ${ScrollbarStyle};
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;

  & + * {
    margin-top: 10px;
  }
`

type PureRefreshGroupRecapModalProps = { refreshGroup: RefreshGroup }
export const PureRefreshGroupRecapModal: FunctionComponent<PureRefreshGroupRecapModalProps> = ({ refreshGroup }) => {
  const { close } = useGlobalModal()
  const adUnits = refreshGroup.adUnits.map((a, i) => <Chip key={i} color={Colors.Petrol} text={a.name} />)
  const sites = refreshGroup.tagIds.map((s, i) => <Chip key={i} color={Colors.Mint} text={s.name} />)
  const devices = refreshGroup.devices.map((d, i) => <Chip key={i} color={Colors.Lilac} text={d.name} />)
  const targetings = refreshGroup.keyValueTargetings?.map(
    (d, i) =>
      d.key &&
      d.value.length && (
        <ChipWrapper key={i}>
          <Chip color={Colors.Ghost} text={d.key} /> <Icon name={'arrow_right_alt'} />
          <Chip color={Colors.Ghost} text={d.value.join(', ')} />
        </ChipWrapper>
      )
  )
  const rules = refreshGroup.specificRules?.map((d, i) => (
    <ChipWrapper key={i}>
      <Chip color={Colors.Ghost} text={d.bidderName} />
      <Icon name={'arrow_right_alt'} />
      refresh every
      <Chip color={Colors.Ghost} text={`${d.timer}s`} />
    </ChipWrapper>
  ))
  const capping = refreshGroup.capping
  return (
    <Modal.Content>
      <Modal.Title>{refreshGroup.name} recap</Modal.Title>

      <Modal.Body>
        <RecapContent>
          <RecapPanel title={'Ad units'}>
            <ChipWrapper>{adUnits}</ChipWrapper>
          </RecapPanel>

          <RecapPanel title={'Sites'}>
            <ChipWrapper>{sites}</ChipWrapper>
          </RecapPanel>

          <RecapPanel title={'Devices'}>
            <ChipWrapper>{devices}</ChipWrapper>
          </RecapPanel>

          {!!targetings?.length && <RecapPanel title={'Key-values (GAM)'}>{targetings}</RecapPanel>}

          <RecapPanel title={'Rules'}>
            <ChipWrapper>
              <Chip color={Colors.Ghost} text={'Default'} /> <Icon name={'arrow_right_alt'} />
              refresh every <Chip color={Colors.Ghost} text={`${refreshGroup.timer}s`} />
            </ChipWrapper>
            {rules}
          </RecapPanel>

          <RecapPanel title={'Refresh capping'}>
            <ChipWrapper>
              Stop refreshing after <Chip color={Colors.Ghost} text={`${capping} impressions`} />
            </ChipWrapper>
          </RecapPanel>

          <RecapPanel title={'Custom settings'}>
            <ChipWrapper>
              Content Layout Shift prevention is <Chip color={Colors.Ghost} text={refreshGroup.preventCLS ? 'on' : 'off'} />
            </ChipWrapper>
            {refreshGroup.populationSplit && (
              <>
                <ChipWrapper>
                  A/B testing population split is <Chip color={Colors.Ghost} text={`${refreshGroup.populationSplit}%`} />
                </ChipWrapper>
                <ChipWrapper>
                  A/B testing Pubstack blocker is <Chip color={Colors.Ghost} text={refreshGroup.blockerEnabled ? 'enabled' : 'disabled'} />
                </ChipWrapper>
              </>
            )}
          </RecapPanel>
        </RecapContent>
      </Modal.Body>

      <Modal.Actions>
        <Button onClick={close}>Close</Button>
      </Modal.Actions>
    </Modal.Content>
  )
}
