import styled from '@emotion/styled'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import type { ChartWrapperOptions } from 'react-google-charts/dist/types'
import { Legend } from '~/components/Legend'
import { displayWithCurrency } from '~/utils/string'
import { DisplayableTypes } from './formulas/operation'

export const ChartWrapper = styled.div`
  height: 300px;
  margin-bottom: 30px;

  display: flex;
  flex-flow: column;
  svg {
    width: 100% !important;
  }
  div[dir='ltr'] {
    width: 100% !important;
  }
`

export const AnalyticsDefaultChartAreaPadding = {
  left: 50,
  right: 20,
  bottom: 30,
  top: 20,
} as const

export const ChartContainer = styled.div`
  flex: 1 1 auto;
`

export const ChartWrapperSmall = styled(ChartWrapper)`
  max-height: 180px;
  height: 180px;
`
export const Legends = styled.div`
  margin-left: ${AnalyticsDefaultChartAreaPadding.left}px;

  ${Legend}:not(:last-child) {
    margin-right: 28px;
  }
`

export const AnalyticsDefaultChartOptions: ChartWrapperOptions['options'] = {
  chartArea: AnalyticsDefaultChartAreaPadding,
  hAxis: {
    gridlines: { color: 'none' },
    textStyle: { color: '#4a4a4a', fontName: 'Roboto', fontSize: '12' },
    slantedText: false,
    minorGridlines: {
      count: 0,
    },
    type: 'datetime',
  },
  legend: { position: 'none' },
  vAxis: {
    format: 'short',
    textStyle: { color: '#4a4a4a', fontName: 'Roboto', fontSize: '11' },
    viewWindow: { min: 0 },
  },
  animation: { duration: 200, startup: true },
  crosshair: { orientation: 'vertical' },
  annotations: { textStyle: { color: '#555' } },
  focusTarget: 'category',
  tooltip: { isHtml: true },
  isStacked: true,
}

export const getAnalyticsChartFormatConfig = (type: DisplayableTypes, currencySymbol?: CurrencySymbol) => {
  switch (type) {
    case 'percentage':
      return { format: `#'%'`, minValue: 0, maxValue: 100 }
    case 'monetary':
      return { format: displayWithCurrency('#.##', currencySymbol) }
    case 'preciseMonetary':
      return { format: displayWithCurrency('#,###', currencySymbol), minValue: 0 }
    case 'seconds':
      return { format: `#'s'`, minValue: 0 }
    case 'uplift':
      return { format: `'+'#'%'`, minValue: 0 }
    default:
      return { format: 'short' }
  }
}

export const convertChartDateTime = (epoch: string, timezone: Timezone) => {
  const d = DateTime.fromISO(epoch, { zone: timezone })
  return `Date(${d.year}, ${d.month - 1}, ${d.day}, ${d.hour}, ${d.minute}, ${d.second})`
}
