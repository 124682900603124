import styled from '@emotion/styled'
import { ANCHOR, CUSTOM_AD_FORMAT, IN_CONTENT_PARALLAX, LEADERBOARD, SKIN_SINGLE_AD, SKIN_SIZE } from '@pubstack/common/src/adFormat'
import { AdUnit, AnchorFeature, VALIDATE_ADUNIT_SIZE_REGEX } from '@pubstack/common/src/adunit'
import { AdUnitDevice } from '@pubstack/common/src/adunitDevice'
import { FunctionComponent, useMemo, useState } from 'react'
import { Control, FieldErrors, UseFormSetValue, Validate, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import Button from '~/components/Button'
import Chip from '~/components/Chip'
import { ChipListChipRenderer, ChipListInput } from '~/components/ChipListInput'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { RadioButtonGroup } from '~/components/RadioButtonGroup'
import { Toggle, ToggleInput } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { WidgetMessage } from '~/components/WidgetMessage'
import { WithClassName } from '~/types/utils'
import { areArraysEqual } from '~/utils/array'
import { AdFormatDisplay, useAdFormat } from './AdFormatDisplay'
import { AdUnitKeyValuesForm } from './AdUnitKeyValuesForm'
import { AdUnitForm, ErrorMessage, getPredefinedSizes, isSizeFitsContainer } from './PureAdStackAdUnitEditPage'
import { SizeRecommendationPopover } from './components/SizeRecommendationPopover'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 0;
`

const FormWrapper = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${Fonts.P1};
  h2 {
    ${Fonts.H2};
    font-weight: 500;
    gap: 4px;
    display: flex;
    align-items: center;
  }
`

const MediatypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const SizesChoice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-bottom: 35px;
  margin-top: 12px;
`

const MediatypeHeader = styled.div`
  ${Fonts.P1};
  font-weight: 500;
`

const SizesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

const SizeInput = styled.div`
  flex-grow: 1;
`

const MaxSizeFilter = styled.div`
  display: flex;
  gap: 8px;
`

const StandardSizes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`

const SizeChip = styled(Chip)<{ isExcluded: boolean }>`
  ${({ isExcluded }) =>
    isExcluded &&
    `
    text-decoration: line-through;
  `}
`

export const Help = styled.div`
  ${Fonts.P2};
  ${Fonts.colors.SlateGrey};
`

export const FormToggle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div:nth-of-type(1) {
    display: inline-flex;
    gap: 8px;
    align-items: center;
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 52px;
    flex-basis: 100%;
  }
`

const AdditionalBehaviour = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const AdditionalBehaviourInfo = styled.div`
  code {
    background-color: ${Colors.Platinum};
    padding: 0 4px;
    ${BorderRadius.style};
  }
`

enum RestoreState {
  RESTORE = 'Restore',
  RESET = 'Reset',
}

type PureAdStackAdUnitSecondStepProps = WithClassName & {
  control: Control<AdUnitForm>
  errors: FieldErrors<AdUnitForm>
  setValue: UseFormSetValue<AdUnitForm>
  adUnit: AdUnit
  isLoading: boolean
  isEditing: boolean
}

const RecommendedSizesTooltip = styled(Tooltip)`
  display: inline-flex;
  align-items: center;
`

const getSizeChipRenderer: (isSizeExcluded: (size: string) => boolean, isSizeRecommended: (size: string) => boolean) => ChipListChipRenderer = (isSizeExcluded, isSizeRecommended) => {
  const SizeChipRenderer: ChipListChipRenderer = (chip, { value }) => {
    const isExcluded = isSizeExcluded(value)
    return <SizeChip {...chip} isExcluded={isExcluded} color={isSizeExcluded(value) ? Colors.Platinum : Colors.Sora} text={value} iconLeft={isSizeRecommended(value) ? 'stars' : undefined} />
  }
  return SizeChipRenderer
}

const _PureAdStackAdUnitSecondStep: FunctionComponent<PureAdStackAdUnitSecondStepProps> = ({ control, errors, setValue, adUnit, isLoading, isEditing, className }) => {
  const { adFormat, sizes, customSizes, containerMaxSize, devices, bannerEnabled, anchor, parallaxEnabled, dynamicEnabled } = useWatch({ control })

  const selectedAdFormat = useAdFormat(adFormat?.id)

  const skinFitsInContainer = useMemo(() => isSizeFitsContainer(SKIN_SIZE, containerMaxSize).both, [SKIN_SIZE, containerMaxSize?.height, containerMaxSize?.width])

  const predefinedSizes = useMemo(() => getPredefinedSizes(selectedAdFormat as AdFormatDisplay, devices as AdUnitDevice[]), [selectedAdFormat, devices, containerMaxSize])
  const missingRecommendedSizes = predefinedSizes.filter((item) => (sizes || []).indexOf(item) < 0)

  const [isRecommendedSizesPopoverOpen, setIsRecommendedSizesPopoverOpen] = useState(isEditing && missingRecommendedSizes.length > 0)
  const validateSizes: Validate<string[], AdUnit> = (sizesToValidate) => {
    if (!sizesToValidate.every((size) => VALIDATE_ADUNIT_SIZE_REGEX.test(size))) {
      return 'Sizes should follow this format : [Num]x[Num] (ex: 300x250)'
    }

    if (new Set(sizesToValidate).size !== sizesToValidate.length) {
      const duplicates = [...new Set(sizesToValidate.filter((size) => sizesToValidate.filter((s) => s === size).length > 1)).values()]
      return `Sizes can only be used once, please remove duplicates on: ${duplicates.join(', ')}`
    }

    if (containerMaxSize) {
      const isSizeValid = (size: string) => isSizeFitsContainer(size, containerMaxSize).both
      const unfittingSizes = sizesToValidate.map((size) => (isSizeValid(size) ? null : size)).filter((size) => size)
      if (unfittingSizes.length > 0) {
        if (unfittingSizes.length === sizesToValidate.length) {
          return `No sizes fit your criteria. Update your filters or click on "Apply size filter" to remove them.`
        }
        return `Some sizes do not fit your criteria. Click on "Apply size filter" to remove them.`
      }
    }

    if (selectedAdFormat?.id === SKIN_SINGLE_AD.id && sizesToValidate.length) {
      if (!skinFitsInContainer) {
        return `Skin size should not be filtered out for this ad format.`
      }
    }

    if (anchor && sizesToValidate.length && adFormat?.id === ANCHOR.id) {
      if (sizesToValidate.some((size) => !isSizeFitsContainer(size, { height: 100 }).height)) {
        return `Anchor ad format is not available for heights over 100px in size.`
      }
    }

    return true
  }

  const validateSkinSize: Validate<string[], AdUnit> = (sizes) => {
    if (selectedAdFormat?.id === SKIN_SINGLE_AD.id && !sizes.includes(SKIN_SIZE)) {
      return `Standard size '1800x1000' is mandatory for the Skin format`
    }

    return true
  }

  const getRestoreState = (sizesList: string[], currentSizes: string[]): RestoreState => {
    if (isEditing) {
      if (areArraysEqual(sizesList, currentSizes)) {
        return RestoreState.RESET
      } else {
        return currentSizes.length > 0 ? RestoreState.RESTORE : RestoreState.RESET
      }
    } else {
      return RestoreState.RESET
    }
  }

  const restoreSizes = (sizesList: string[], currentSizes: string[]): string[] => {
    const restoreState = getRestoreState(sizesList, currentSizes)
    switch (restoreState) {
      case RestoreState.RESTORE: {
        return currentSizes
      }
      case RestoreState.RESET: {
        return []
      }
    }
  }

  const dynamicToggleState = useMemo(() => {
    if (!selectedAdFormat?.isDynamicToggleEnabled) {
      return { disabled: true, message: 'Incompatible with the current ad format.' }
    }
    if (anchor?.mode) {
      return { disabled: true, message: 'Incompatible with anchor.' }
    }
    return { disabled: false }
  }, [selectedAdFormat, anchor])

  const anchorToggleState = useMemo(() => {
    if (selectedAdFormat?.id === ANCHOR.id) {
      return { disabled: true, message: 'Mandatory on this ad format.' }
    }
    if (!selectedAdFormat?.isAnchorToggleEnabled) {
      return { disabled: true, message: 'Incompatible with the current ad format.' }
    }
    if (parallaxEnabled) {
      return { disabled: true, message: 'Incompatible with parallax.' }
    }
    if (devices?.includes('desktop') && adFormat?.id !== CUSTOM_AD_FORMAT.id) {
      return { disabled: true, message: 'Incompatible with desktop.' }
    }
    if (dynamicEnabled) {
      return { disabled: true, message: 'Incompatible with dynamic.' }
    }
    return { disabled: false }
  }, [selectedAdFormat, parallaxEnabled, devices, dynamicEnabled])

  const parallaxToggleState = useMemo(() => {
    if (selectedAdFormat?.id === IN_CONTENT_PARALLAX.id) {
      return { disabled: true, message: 'Mandatory on this ad format.' }
    }
    if (!selectedAdFormat?.isParallaxToggleEnabled) {
      return { disabled: true, message: 'Incompatible with the current ad format.' }
    }
    if (anchor?.mode) {
      return { disabled: true, message: 'Incompatible with anchor.' }
    }
    return { disabled: false }
  }, [selectedAdFormat, anchor])

  return (
    <Wrapper className={className}>
      <div>
        <FormHeader>
          <h2>Demand source</h2>
          Enable or disable header bidding for this ad unit.
        </FormHeader>
        <div>
          <FormToggle>
            <div>
              <ToggleInput disabled={isEditing && adUnit.headerBiddingEnabled} id={'headerBiddingEnabled'} control={control} name={'headerBiddingEnabled'} />
              <span>Header bidding</span>
            </div>
          </FormToggle>
        </div>
      </div>
      <div>
        <FormHeader>
          <h2>Additional behaviour</h2>
        </FormHeader>
        <AdditionalBehaviour>
          <FormToggle>
            <div>
              <ToggleInput id={'dynamic'} disabled={dynamicToggleState.disabled} control={control} name={'dynamicEnabled'} />
              <span>Dynamic</span>
            </div>
            <div>
              <Help>Allows the ad unit to be used multiple times on a single page. {dynamicToggleState.message}</Help>
            </div>
          </FormToggle>

          <FormToggle>
            <div>
              <Toggle
                disabled={anchorToggleState.disabled}
                id={'anchor'}
                value={!!anchor?.mode}
                onClick={() =>
                  !anchor?.mode
                    ? setValue('anchor.mode', adUnit.anchor?.mode ?? 'createNewDiv', { shouldDirty: true })
                    : setValue('anchor.mode', undefined as unknown as AnchorFeature['mode'], { shouldDirty: true })
                }
              />
              <span>Anchor</span>
            </div>
            <div>
              <Help>Display the creative in a closable container at the bottom of the page. {anchorToggleState.message}</Help>
              {errors.anchor && (
                <ErrorMessage>
                  <Icon name={'alert'} width={'0.875rem'} />
                  {errors.anchor.message}
                </ErrorMessage>
              )}
              {anchor?.mode && !errors.anchor && (
                <>
                  <RadioButtonGroup
                    name="anchor.mode"
                    control={control}
                    options={[
                      {
                        label: 'No container exists for this ad unit, create it automatically',
                        value: 'createNewDiv',
                      },
                      {
                        label: 'A container exists for this ad unit, use it',
                        value: 'useExistingDiv',
                      },
                    ]}
                  />
                  <WidgetMessage
                    icon={'info'}
                    message={
                      <AdditionalBehaviourInfo>
                        <p>
                          Anchor works best with creatives which height is of <code>100px</code> or less.{' '}
                          <Button size={'s'} onClick={() => setValue('containerMaxSize.height', 100)} variant="secondary">
                            Set height filter
                          </Button>
                        </p>
                      </AdditionalBehaviourInfo>
                    }
                  />
                </>
              )}
            </div>
          </FormToggle>

          <FormToggle>
            <div>
              <ToggleInput disabled={parallaxToggleState.disabled} id={'parallax'} control={control} name={'parallaxEnabled'} />
              <span>Parallax</span>
            </div>

            <div>
              <Help>Display bigger creatives in a smaller container by keeping most of the creative viewable when scrolling. {parallaxToggleState.message}</Help>
              {parallaxEnabled && !errors.parallaxEnabled && (
                <WidgetMessage
                  icon={'info'}
                  message={
                    <AdditionalBehaviourInfo>
                      <p>
                        To ensure viewability, containers with a smaller height than <code>310px</code> will be resized to match that height.
                      </p>
                      <p>
                        If you want to avoid any CLS increase, only use parallax on ad units which container has a height of <code>310px</code> or greater.
                      </p>
                    </AdditionalBehaviourInfo>
                  }
                />
              )}
            </div>
          </FormToggle>
        </AdditionalBehaviour>
      </div>
      <div>
        <FormHeader>
          <h2>Mediatypes</h2>
        </FormHeader>
        {selectedAdFormat?.mediatypes?.length && (
          <MediatypesWrapper>
            {selectedAdFormat.mediatypes.includes('Banner') && (
              <FormWrapper>
                <MediatypeHeader>Banner</MediatypeHeader>
                <FormToggle>
                  <div>
                    <ToggleInput disabled={selectedAdFormat.mediatypes?.length === 1} id={'bannerEnabled'} control={control} name={'bannerEnabled'} />
                    <span>Banner</span>
                  </div>
                </FormToggle>
                {bannerEnabled && (
                  <>
                    {(selectedAdFormat.id === LEADERBOARD.id || selectedAdFormat.id === SKIN_SINGLE_AD.id) && (
                      <FormToggle>
                        <div>
                          <ToggleInput id={'skinEnabled'} control={control} name={'skinEnabled'} disabled={!skinFitsInContainer || selectedAdFormat.id === SKIN_SINGLE_AD.id} />
                          <span>Skin</span>
                        </div>
                      </FormToggle>
                    )}
                    <FormToggle>
                      <div>
                        <ToggleInput
                          id={'fluid'}
                          control={control}
                          rules={{
                            required: { value: (sizes ?? []).length === 0, message: 'Minimum one size or fluid required.' },
                          }}
                          name={'fluid'}
                        />
                        <span>Fluid (GAM)</span>
                        {errors.fluid && (
                          <ErrorMessage>
                            <Icon name={'alert'} width={'0.875rem'} />
                            {errors.fluid.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </FormToggle>
                    <SizesChoice>
                      <MaxSizeFilter>
                        <Input
                          type={'number'}
                          label={'Max width filter'}
                          control={control}
                          name={'containerMaxSize.width'}
                          helper={'Optional'}
                          iconRight={'pixel'}
                          min={1}
                          rules={{
                            validate: {
                              value: (formValue) => {
                                return !formValue || Number(formValue) > 0 ? true : 'Should be greater than 0'
                              },
                            },
                          }}
                          error={errors.containerMaxSize?.width?.message}
                        />
                        <Input
                          type={'number'}
                          label={'Max height filter'}
                          control={control}
                          name={'containerMaxSize.height'}
                          helper={'Optional'}
                          iconRight={'pixel'}
                          min={1}
                          rules={{
                            validate: {
                              value: (formValue) => {
                                return !formValue || Number(formValue) > 0 ? true : 'Should be greater than 0'
                              },
                            },
                          }}
                          error={errors.containerMaxSize?.height?.message}
                        />
                        <Button
                          disabled={
                            (!containerMaxSize?.width && !containerMaxSize?.height) ||
                            [...(sizes ?? []), ...(customSizes ?? [])].filter((size) => !isSizeFitsContainer(size, containerMaxSize).both).length === 0
                          }
                          variant={'tertiary'}
                          onClick={() => {
                            setValue('sizes', sizes?.filter((size) => isSizeFitsContainer(size, containerMaxSize).both) ?? [])
                            setValue('customSizes', customSizes?.filter((size) => isSizeFitsContainer(size, containerMaxSize).both) ?? [])
                          }}
                        >
                          Apply size filter
                        </Button>
                      </MaxSizeFilter>
                      <StandardSizes>
                        <SizesWrapper>
                          <SizeInput>
                            <ChipListInput
                              chipColor={Colors.Sora}
                              type={'text'}
                              label={'Standard sizes (px) - ad server, header bidding'}
                              control={control}
                              name={'sizes'}
                              helper={'Type a size (ex: 300x250) then press Enter or Space'}
                              error={errors.sizes?.message}
                              rules={{
                                validate: (sizes, adUnit) => {
                                  const validateResult = validateSizes(sizes, adUnit)
                                  return validateResult === true ? validateSkinSize(sizes, adUnit) : validateResult
                                },
                              }}
                              chipRenderer={getSizeChipRenderer(
                                (size) => !isSizeFitsContainer(size, containerMaxSize).both,
                                (size) => predefinedSizes.includes(size)
                              )}
                              iconRight={'pixel'}
                              additionalAction={
                                predefinedSizes.length
                                  ? {
                                      icon: 'stars',
                                      onClick: () => setIsRecommendedSizesPopoverOpen(!isRecommendedSizesPopoverOpen),
                                      disabled: missingRecommendedSizes.length === 0,
                                      renderer: (action) => (
                                        <RecommendedSizesTooltip disabled={isRecommendedSizesPopoverOpen} title={missingRecommendedSizes.length ? 'Recommended sizes' : 'Recommended sizes applied'}>
                                          <SizeRecommendationPopover
                                            open={isRecommendedSizesPopoverOpen && !!missingRecommendedSizes.length}
                                            onClose={() => {
                                              setIsRecommendedSizesPopoverOpen(false)
                                            }}
                                            onAddAllSizes={(sizesToAdd) => setValue('sizes', [...(sizes ?? []), ...sizesToAdd])}
                                            onSizeClick={(size) => setValue('sizes', [...(sizes ?? []), size])}
                                            sizes={missingRecommendedSizes}
                                          >
                                            {action}
                                          </SizeRecommendationPopover>
                                        </RecommendedSizesTooltip>
                                      ),
                                    }
                                  : undefined
                              }
                            />
                          </SizeInput>

                          <Tooltip title={getRestoreState(sizes as string[], adUnit.sizes)} disabled={areArraysEqual(sizes as string[], adUnit.sizes)}>
                            <Button
                              variant={'tertiary'}
                              iconName={'restore'}
                              iconSize={'26px'}
                              disabled={areArraysEqual(sizes as string[], adUnit.sizes)}
                              onClick={() => setValue('sizes', restoreSizes(sizes as string[], adUnit.sizes))}
                            />
                          </Tooltip>
                        </SizesWrapper>
                      </StandardSizes>
                      <SizesWrapper>
                        <SizeInput>
                          <ChipListInput
                            chipColor={Colors.Turquoise}
                            isColorVariable
                            type={'text'}
                            label={'Custom sizes (px) - ad server only'}
                            control={control}
                            name={'customSizes'}
                            helper={'Optional. Type a size (ex: 300x250) then press Enter or Space'}
                            error={errors.customSizes?.message}
                            rules={{
                              validate: validateSizes,
                            }}
                            chipRenderer={getSizeChipRenderer(
                              (size) => !isSizeFitsContainer(size, containerMaxSize).both,
                              (size) => predefinedSizes.includes(size)
                            )}
                            iconRight={'pixel'}
                          />
                        </SizeInput>
                        <Tooltip title={getRestoreState(customSizes as string[], adUnit.customSizes)} disabled={customSizes?.length === 0}>
                          <Button
                            variant={'tertiary'}
                            iconName={'restore'}
                            iconSize={'26px'}
                            disabled={customSizes?.length === 0}
                            onClick={() => setValue('customSizes', restoreSizes(customSizes as string[], adUnit.customSizes))}
                          />
                        </Tooltip>
                      </SizesWrapper>
                    </SizesChoice>
                  </>
                )}
              </FormWrapper>
            )}
            {selectedAdFormat?.mediatypes.includes('Outstream') && (
              <FormWrapper>
                <MediatypeHeader>Outstream</MediatypeHeader>
                <FormToggle>
                  <div>
                    <ToggleInput id={'canOutstream'} control={control} name={'canOutstream'} disabled={selectedAdFormat.mediatypes?.length === 1} />
                    <span>Outstream</span>
                  </div>
                </FormToggle>
              </FormWrapper>
            )}
          </MediatypesWrapper>
        )}
      </div>
      {!isLoading && (
        <FormWrapper>
          <FormHeader>
            <h2>Key-values (optional)</h2>
          </FormHeader>
          <AdUnitKeyValuesForm
            updateAdUnit={(keyValues) => {
              setValue('slotKeyValues', keyValues, { shouldDirty: true })
            }}
            keyValues={(adUnit.slotKeyValues as AdUnit['slotKeyValues']) ?? []}
          />
        </FormWrapper>
      )}
    </Wrapper>
  )
}

export const PureAdStackAdUnitSecondStep = styled(_PureAdStackAdUnitSecondStep)``
