import styled from '@emotion/styled'
import { BidMetricsByBidder, MappedName } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent } from 'react'
import { MultipleDetailedDataTable } from '~/components/DataTable/DataTable'
import { DataTableDetailedPropertyProvider } from '~/components/DataTable/DataTableDetailedProperties'
import { Widget, WidgetProps } from '~/components/Widget'
import { useAnalyticsDataTable } from '~/modules/analytics/analyticsDataTable.hooks'
import { bidAdUnitCount, bidCpm, bidRate, bidRequests, bidTagIdCount, bidTimeoutRate, hbImpressionRevenue, hbWinRate } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

type PureBiddersOverviewProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon' | 'info'> & {
    rawData?: BidMetricsByBidder
    displayableImpressionRevenuePercentageThreshold?: number
    displayableBidRequestsPercentageThreshold?: number
    currencySymbol: CurrencySymbol
    onRowClick: (name: MappedName) => void
    onSortChange: (metric: string) => void
  }
const _PureBiddersOverview: FunctionComponent<PureBiddersOverviewProps> = ({
  rawData,
  displayableImpressionRevenuePercentageThreshold,
  displayableBidRequestsPercentageThreshold,
  currencySymbol,
  onRowClick,
  onSortChange,
  ...props
}) => {
  const { data, columns, sorting } = useAnalyticsDataTable({
    rawData,
    currencySymbol,
    filterData: ([bidder]) => {
      if (!rawData) {
        return true
      }
      const totalBidRequests = bidRequests.isComputable(rawData) ? bidRequests.sum(rawData) : 0
      const totalImpressionRevenue = hbImpressionRevenue.sum(rawData)
      const n1 = (bidder.impressionRevenue.rawValue ?? 0) / totalImpressionRevenue
      const n2 = (bidder.bidRequests.rawValue ?? 0) / totalBidRequests
      return n1 >= (displayableImpressionRevenuePercentageThreshold ?? 0.01) || n2 >= (displayableBidRequestsPercentageThreshold ?? 0.01)
    },
    dataConfigs: [
      [
        {
          displayedAs: 'detail',
          getDetails: ({ data }) => [DataTableDetailedPropertyProvider.bidTagIdCount(data.bidTagIdCount.value), DataTableDetailedPropertyProvider.bidAdUnitCount(data.bidAdUnitCount.value)],
          status: {
            alert: 30,
            warning: 60,
            defaultValue: 'success',
          },
          sortLabel: 'Name',
        },
        {
          formula: hbImpressionRevenue,
          propertyName: 'impressionRevenue',
          tooltip: ANALYTICS_TOOLTIPS.PREBID_REVENUE,
        },
        {
          formula: bidRequests,
          propertyName: 'bidRequests',
          tooltip: ANALYTICS_TOOLTIPS.BID_REQUESTS,
        },
        {
          formula: bidRate,
          propertyName: 'bidRate',
          status: {
            warning: 15,
            alert: 5,
          },
          tooltip: ANALYTICS_TOOLTIPS.BID_RATE,
        },
        {
          formula: bidCpm,
          propertyName: 'bidCpm',
          tooltip: ANALYTICS_TOOLTIPS.BID_CPM,
        },
        {
          formula: hbWinRate,
          propertyName: 'headerBiddingWinRate',
          tooltip: ANALYTICS_TOOLTIPS.PREBID_WIN_RATE,
        },
        {
          formula: bidTimeoutRate,
          propertyName: 'bidTimeoutRate',
          status: {
            warning: 5,
            alert: 10,
          },
          tooltip: ANALYTICS_TOOLTIPS.BID_TIMEOUT_RATE,
        },
        {
          formula: bidTagIdCount,
          propertyName: 'bidTagIdCount',
        },
        {
          formula: bidAdUnitCount,
          propertyName: 'bidAdUnitCount',
        },
      ],
    ],
    columnsConfigs: ['impressionRevenue', 'bidRequests', 'bidRate', 'bidCpm', 'headerBiddingWinRate', 'bidTimeoutRate'],
  })

  return (
    <Widget {...props} icon={'bidder'} title={'Overview per bidders'} subTitle={'Click on a bidder to see details'}>
      <MultipleDetailedDataTable
        columns={columns}
        rows={data}
        sorting={sorting}
        onRowClick={({ index }) => onRowClick(data[index][0].name)}
        onSortChange={(option) => onSortChange(option.value as string)}
      />
    </Widget>
  )
}
export const PureBiddersOverview = styled(_PureBiddersOverview)``
