import styled from '@emotion/styled'
import type { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'

type PureArchiveAdUnitModalProps = {
  adUnitName: string
  onArchive: () => Promise<void>
}
const _PureArchiveAdUnitModal: FunctionComponent<PureArchiveAdUnitModalProps> = ({ adUnitName, onArchive }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Archive {adUnitName}</Modal.Title>
      <Modal.Body>
        <p>You are about to archive an ad unit which is used in one or several stacks.</p>
      </Modal.Body>
      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onArchive}>
          Archive ad unit
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

export const PureArchiveAdUnitModal = styled(_PureArchiveAdUnitModal)``
