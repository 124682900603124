import { CATALOG_ITEM_TYPE, CatalogItem } from '@pubstack/common/src/catalogItem'
import { useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useCatalogItems } from '~/api/adm-api.hook'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { TabProp } from '~/components/Tabs'
import { configurationState } from '~/state'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { QueryParams, useUrlSync } from '~/utils/useUrlSync'
import { PureAdStackCatalogTabs } from './PureAdStackCatalogTabs'

type TabLabel = 'My integrations' | 'All integrations'
const TabUrls: { [key in TabLabel]: string } = {
  'My integrations': 'my-integrations',
  'All integrations': 'all-integrations',
}

const allTypesOption: SelectOptionProp = {
  label: 'All types',
  value: 'all',
}

export type CatalogPageContext = {
  baseCDNUrl: string
  modules: CatalogItem[]
  isLoading: boolean
  catalogItemOptions: SelectOptionProp[]
  onCheckIntegrations: () => void
  search: string
  type: string
  setQueryParams: (params: QueryParams) => void
}

const _AdStackCatalogTabs: React.FunctionComponent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<TabLabel>('My integrations')
  const catalogTabs: TabProp[] = useMemo(() => {
    const tabs = [
      {
        label: 'My integrations',
        active: /my-integrations\/?$/.test(location?.pathname ?? ''),
      },
      {
        label: 'All integrations',
        active: /all-integrations\/?$/.test(location?.pathname ?? ''),
      },
    ]
    setActiveTab(tabs.find((t) => t.active)?.label as TabLabel)
    return tabs
  }, [location])
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)
  const [catalogData, setCatalogData] = useState<CatalogItem[]>()
  const breadcrumbs = useBreadcrumbs()
  const { all } = useCatalogItems(null)
  const [catalogItemOptions, setCatalogItems] = useState<SelectOptionProp[]>([allTypesOption])

  const [{ search, type }, setQueryParams] = useUrlSync()

  const getCatalogData = async () => {
    if (all.loading) {
      all.abort()
    }
    const allModules = await all.get()

    const catalogItemTypes = [...new Set(allModules.map(({ type }) => type))]
    setCatalogItems([allTypesOption, ...catalogItemTypes.map((s) => ({ value: s, label: CATALOG_ITEM_TYPE[s] }))])
    setCatalogData(allModules)
  }

  useEffect(() => {
    getCatalogData()
  }, [])

  const modules = useMemo(
    () => catalogData && catalogData.sort((a, b) => a.displayName.localeCompare(b.displayName)).filter((b) => (activeTab === 'My integrations' ? b.isUsed : b)),
    [catalogData, activeTab]
  )

  const onTabChange = (label: TabLabel) => {
    setActiveTab(label)
    navigate(TabUrls[label])
  }

  return (
    <PureAdStackCatalogTabs breadcrumbs={breadcrumbs} onTabClick={(tab) => onTabChange(tab.label as TabLabel)} tabs={catalogTabs}>
      <Outlet
        context={
          {
            baseCDNUrl: STATIC_ASSETS_CDN,
            modules: modules ?? [],
            isLoading: all.loading,
            catalogItemOptions,
            onCheckIntegrations: () => onTabChange('All integrations'),
            search: search ?? '',
            type: type ?? 'all',
            setQueryParams,
          } satisfies CatalogPageContext
        }
      />
    </PureAdStackCatalogTabs>
  )
}

export const AdStackCatalogTabs = _AdStackCatalogTabs
