import { cx } from '@emotion/css'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { BidderCatalogAlias } from '@pubstack/common/src/bidderCatalog'
import { FunctionComponent, useState } from 'react'
import { Breadcrumbs } from '~/components/Breadcrumbs'
import Button from '~/components/Button'
import { PureCatalogItemHeader } from '~/modules/adstack/inventory/catalog/PureCatalogItemHeader'
import { WithClassName } from '~/types/utils'
import { PureAdStackIntegrationAliasForm } from './PureAdStackIntegrationAliasForm'
import { PureAdStackIntegrationHelpFlyout } from './PureAdStackIntegrationHelpFlyout'

const ANIMATION_IN_DURATION_MS = 200
const ANIMATION_OUT_DURATION_MS = 200

const formIn = keyframes`
  0% {
    opacity: 0;
    height: 0;
  }
  75% {
    height: 105px;
    opacity: 1;
  }
`

const formOut = keyframes`
  0% {
    opacity: 1;
    height: 105px;
  }
  
  25% {
    opacity: 0;
    padding: 20px;
    margin-top: 20px;
  }
  
  100% {
    padding: 0;
    margin-top: 0;
    opacity: 0;
    height: 0px;
  }
`

const IntegrationAliasForm = styled(PureAdStackIntegrationAliasForm)<{ isShown: boolean }>`
  display: ${({ isShown }) => (isShown ? 'block' : 'none')};
  margin-top: 20px;
`

const Wrapper = styled.div`
  padding: 20px;

  & ${Breadcrumbs} {
    margin-bottom: 20px;
  }

  &.form-open > ${IntegrationAliasForm} {
    animation: ${ANIMATION_IN_DURATION_MS}ms ease-out ${formIn} normal forwards;
  }

  &.form-close > ${IntegrationAliasForm} {
    animation: ${ANIMATION_OUT_DURATION_MS}ms ease-out ${formOut} normal forwards;
  }
`

type PureAdStackIntegrationPageProps = WithClassName & {
  breadcrumbs: React.ReactNode
  integrationName: string
  integrationAliases?: BidderCatalogAlias[]
  isLoading: boolean
  isIntegrationUsed?: boolean
  onCreateAlias: (value: string) => void
  baseCDNUrl: string
  disableAliasing: boolean
  onBackClick: () => void
}

const _PureAdStackIntegrationPage: FunctionComponent<PureAdStackIntegrationPageProps> = ({
  className,
  breadcrumbs,
  integrationName,
  isIntegrationUsed,
  isLoading,
  onCreateAlias,
  baseCDNUrl,
  disableAliasing,
  onBackClick,
}) => {
  const [isOpenHelpFlyout, setIsOpenHelpFlyout] = useState<boolean>(false)
  const [isShownIntegrationAliasForm, setIsShownIntegrationAliasForm] = useState<boolean>(false)
  const [isAliasFormClassToggled, setIsAliasFormClassToggled] = useState<boolean>(false)

  const showForm = () => {
    setIsShownIntegrationAliasForm(true)
    setIsAliasFormClassToggled(true)
  }

  const hideForm = () => {
    setIsAliasFormClassToggled(false)
    setTimeout(() => {
      setIsShownIntegrationAliasForm(false)
    }, ANIMATION_OUT_DURATION_MS)
  }
  return (
    <Wrapper className={cx([className, isAliasFormClassToggled ? 'form-open' : 'form-close'])}>
      <PureCatalogItemHeader
        breadcrumbs={breadcrumbs}
        catalogItemCode={integrationName}
        catalogItemType={'prebidModule'}
        isLoading={isLoading}
        isActive={!!isIntegrationUsed}
        baseCDNUrl={baseCDNUrl}
        actions={
          <>
            <Button variant={'tertiary'} onClick={onBackClick}>
              Back
            </Button>
            <Button variant={'tertiary'} onClick={() => setIsOpenHelpFlyout(true)} iconName={'help'}>
              Help
            </Button>
            {!disableAliasing && (
              <Button variant={'secondary'} onClick={showForm} iconName={'add_circle'}>
                New alias
              </Button>
            )}
          </>
        }
      />
      <IntegrationAliasForm
        isShown={isShownIntegrationAliasForm}
        onClose={hideForm}
        onSubmitAlias={(value) => {
          onCreateAlias(value)
          hideForm()
        }}
        isLoading={isLoading}
      />

      <PureAdStackIntegrationHelpFlyout isOpen={isOpenHelpFlyout} onClose={() => setIsOpenHelpFlyout(false)} />
    </Wrapper>
  )
}

export const PureAdStackIntegrationPage = styled(_PureAdStackIntegrationPage)``
