import { parseFromDateWithoutTime, sameDay } from '@pubstack/common/src/date'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

type Cache = {
  [key: string]: {
    ttl: DateTime | null
    value: unknown
  }
}

const cache: Cache = {}
interface Body {
  [key: string | number | symbol]: unknown
  args?: unknown
  path: string
  context: { from: string; to: string }
}

const buildCacheKey = (body: Body) => JSON.stringify(body)

const toCache = <T>(body: Body, value: T) => {
  const EXPIRE_IN_MINUTES_WHEN_CURRENT_DAY_IN_REQUEST = 1
  let ttl: DateTime | null = null
  const now = DateTime.now()
  if (sameDay(parseFromDateWithoutTime(body.context.to), now)) {
    ttl = now.plus({ minutes: EXPIRE_IN_MINUTES_WHEN_CURRENT_DAY_IN_REQUEST })
  }
  cache[buildCacheKey(body)] = {
    value,
    ttl,
  }
}
const fromCache = <T>(body: Body): T | null => {
  const cacheElement = cache[buildCacheKey(body)]

  if (cacheElement && (cacheElement.ttl === null || cacheElement.ttl > DateTime.now())) {
    return cacheElement.value as T
  }

  return null
}

export const useCachedRequest = <T>() => {
  return useCallback(async (body: Body, action: () => Promise<T>): Promise<T> => {
    let result = fromCache<T>(body)
    if (result) {
      return result
    }

    result = await action()
    toCache(body, result)
    return result
  }, [])
}
