import styled from '@emotion/styled'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DisplayedStack, Stack, isStackNameReserved } from '@pubstack/common/src/stack'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { useUser } from '~/auth/user.hooks'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { RadioButtonGroup } from '~/components/RadioButtonGroup'
import { RadioOption } from '~/components/RadioGroup'
import { Select } from '~/components/Select'
import { Skeleton } from '~/components/Skeleton'
import { WithClassName } from '~/types/utils'
import { PureStackDetails } from './stack/PureStackDetails'

const SubTitle = styled.div`
  padding-bottom: 20px;
`

const DetailsHeader = styled.div`
  display:flex;
  justify-content: space-between;
  align-items:center;
  ${Fonts.H2};
  font-weight: 600;
  padding-bottom: 20px;
`
const OptionsList = styled.div`
  padding: 20px 0;
`
type PureStackTemplateFlyoutProps = WithClassName & {
  isOpen: boolean
  isLoading: boolean
  sites: Pick<Site, 'id' | 'name'>[]
  currentSiteId: Site['id']
  templates: Stack[]
  detailedStack: DisplayedStack
  onClose: () => void
  onCreateStack: (selectedTemplate: string) => void
  onDetailedView: (id: string, siteId: string) => void
  currencySymbol: CurrencySymbol
}
const _PureStackTemplateFlyout: FunctionComponent<PureStackTemplateFlyoutProps> = ({
  isOpen,
  isLoading,
  onClose,
  templates,
  sites,
  currentSiteId,
  detailedStack,
  currencySymbol,
  onCreateStack,
  onDetailedView,
}) => {
  const { control, handleSubmit } = useForm({ defaultValues: { selectedSite: currentSiteId, selectedTemplate: '' } })
  const selectedSiteId = useWatch({ control, name: 'selectedSite' })
  const [isDetailedViewVisible, setIsDetailedViewVisible] = useState<boolean>(false)
  const sitesOptions = sites.map((site) => ({ label: site.name, value: site.id }))
  const hasAdmRefresh = !!useUser()?.hasFeature('admRefresh')
  const optionsFromSelectedSite: RadioOption[] = templates.filter((t) => t.siteId === selectedSiteId).map((a) => ({ label: a.name, value: a.id, iconName: 'view' }))
  optionsFromSelectedSite
    .sort((a, b) => a.label.localeCompare(b.label)) // alphabetical order
    .sort((a, b) => (isStackNameReserved(a.label) ? -1 : isStackNameReserved(b.label) ? 1 : 0)) // always stack with the name reserved at first
  const templatesOptions: RadioOption[] = [{ label: 'No template', value: '' }, ...optionsFromSelectedSite]

  return (
    <Flyout
      color={Colors.Lilac}
      width={`816px`}
      title={'Use existing stack as template'}
      isOpen={isOpen}
      onClose={() => {
        setIsDetailedViewVisible(false)
        onClose()
      }}
      actions={
        !isDetailedViewVisible && (
          <Button variant={'primary'} onClick={handleSubmit((formValues) => onCreateStack(formValues.selectedTemplate))}>
            Create stack
          </Button>
        )
      }
    >
      <>
        {isDetailedViewVisible ? (
          <>
            <DetailsHeader>
              {isLoading ? <Skeleton bigger /> : <span>{`${detailedStack.name} - ${detailedStack.version ? 'v' + detailedStack.version : 'draft'} details`}</span>}
              <Button variant={'tertiary'} onClick={() => setIsDetailedViewVisible(false)}>
                Back
              </Button>
            </DetailsHeader>
            <PureStackDetails currencySymbol={currencySymbol} detailedStack={detailedStack} hasAdmRefresh={hasAdmRefresh} isLoading={isLoading} onBack={() => true} />
          </>
        ) : (
          <>
            <SubTitle>{`Select a site then an existing stack to duplicate its settings.`}</SubTitle>
            {!templates.find((t) => t.siteId === currentSiteId) && (
              <SubTitle>
                This first stack will be used as <b>Default</b>.
              </SubTitle>
            )}
            {isLoading ? (
              <Skeleton width={'300px'} />
            ) : (
              <>
                <Select control={control} name={'selectedSite'} options={sitesOptions} label={'Site'} />
                <OptionsList>
                  <RadioButtonGroup
                    control={control}
                    name={'selectedTemplate'}
                    options={templatesOptions}
                    onIconClick={(id) => {
                      onDetailedView(id, selectedSiteId)
                      setIsDetailedViewVisible(true)
                    }}
                  />
                </OptionsList>
              </>
            )}
          </>
        )}
      </>
    </Flyout>
  )
}

export const PureStackTemplateFlyout = styled(_PureStackTemplateFlyout)``
