import styled from '@emotion/styled'
import { LazyLoadingRule } from '@pubstack/common/src/stack'
import { FunctionComponent, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Input, _Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Toggle } from '~/components/Toggle'
import { WithClassName } from '~/types/utils'
import { ViewportType } from './components/LazyLoadingViewport'
import { LazyLoadingViewportPreview } from './components/LazyLoadingViewportPreview'

const TitleBar = styled.div`
  display: flex;
  padding-bottom: 40px;
  width: 100%;
  justify-content: space-between;
  h1 {
    ${Fonts.H1}
  }
  ${Link} {
    margin-bottom: 12px;
  }
`

const AdStackEditContentCard = styled(ContentCard)`
  width: 796px;
`

const AdStackEditPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const BottomBar = styled.div`
  border-top: 1px solid ${Colors.Platinum};
`

const Title = styled.div`
  ${Fonts.H1}
  padding-bottom: 20px;
  font-weight: regular;
`

const PageWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-width: 1000px;
  ${Fonts.P1}
  h2 {
    ${Fonts.H2}
    font-weight: 500;
  }

  ${_Input} {
    width: 394px;
    padding: 20px 0;
  }

  & > ${_Input} {
    margin-bottom: 12px;
  }
`

const Subtitle = styled.div`
  ${Fonts.P1}
  span {
    display: inline-flex;
    align-items: center;
  }
`

const FormButtonsWrapper = styled.div`
  text-align: right;
  padding: 4px 20px;
  ${Button} {
    margin-left: 15px;
  }
`

const FormToggle = styled.div`
  display: inline-flex;
  gap: 8px;
  flex-wrap: wrap;
  p {
    ${Fonts.P2};
    ${Fonts.colors.SlateGrey};
  }
  padding-bottom: 16px;
`

const InputsWrapper = styled.div`
  ${_Input} {
    padding: 16px 0px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const RuleDefinitionWrapper = styled.div`
  padding-top: 6px;
  padding-bottom: 24px;
`

type PureAdStackLazyLoadingEditPageProps = WithClassName & {
  lazyLoadingRule?: LazyLoadingRule
  onBack: () => void
  breadcrumbs: React.ReactNode
  onCreateLazyLoadingRule: (lazyLoadingRule: LazyLoadingRule) => Promise<void>
  onUpdateLazyLoadingRule: (lazyLoadingRule: LazyLoadingRule) => Promise<void>
  existingLazyLoadingRules: LazyLoadingRule[]
  isEditing: boolean
  isLoading: boolean
}

const _PureAdStackLazyLoadingEditPage: FunctionComponent<PureAdStackLazyLoadingEditPageProps> = ({
  className,
  lazyLoadingRule,
  onBack,
  onCreateLazyLoadingRule,
  breadcrumbs,
  existingLazyLoadingRules,
  isEditing,
  onUpdateLazyLoadingRule,
  isLoading,
}) => {
  const defaultValues: LazyLoadingRule = lazyLoadingRule ?? {
    gamEnabled: true,
    headerBiddingEnabled: true,
    id: '',
    name: '',
    creationTime: 123,
    technicalId: '',
  }

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    reset,
    trigger,
  } = useForm<LazyLoadingRule>({ mode: 'onChange', defaultValues })

  const { googletagFetchViewportsPercent, googletagRenderViewportsPercent, prebidAuctionViewportsPercent, headerBiddingEnabled, gamEnabled } = useWatch({ control })
  const [focusElement, setFocusElement] = useState<ViewportType | undefined>()

  const onSubmit: SubmitHandler<LazyLoadingRule> = (newRule) => {
    const sanitize = (value?: string | number) => Math.trunc(Number(value ?? 0) * 100)
    const toEdit = {
      ...newRule,
      googletagFetchViewportsPercent: gamEnabled ? sanitize(googletagFetchViewportsPercent) : undefined,
      googletagRenderViewportsPercent: gamEnabled ? sanitize(googletagRenderViewportsPercent) : undefined,
      prebidAuctionViewportsPercent: headerBiddingEnabled ? sanitize(prebidAuctionViewportsPercent) : undefined,
    }
    isEditing ? onUpdateLazyLoadingRule(toEdit) : onCreateLazyLoadingRule(toEdit)
  }

  useEffect(() => {
    reset(lazyLoadingRule)
  }, [lazyLoadingRule])

  useEffect(() => {
    trigger('googletagFetchViewportsPercent')
  }, [prebidAuctionViewportsPercent])

  useEffect(() => {
    trigger('googletagRenderViewportsPercent')
  }, [googletagFetchViewportsPercent])

  return (
    <AdStackEditPageWrapper>
      <TitleBar>
        {breadcrumbs}
        <Button variant={'tertiary'} onClick={onBack}>
          Back
        </Button>
      </TitleBar>
      <PageWrapper>
        <AdStackEditContentCard color={Colors.Lilac}>
          <Title>{isEditing ? `Edit: ${lazyLoadingRule?.name ?? ''}` : 'New lazy loading rule'}</Title>
          <BottomBar />
          {!isLoading && (
            <form className={className} id={'newRuleForm'} onSubmit={handleSubmit(onSubmit)}>
              <FormWrapper>
                <Subtitle>
                  <h2>Identification</h2>
                </Subtitle>
                <Input
                  type={'text'}
                  label={'Name'}
                  control={control}
                  name={'name'}
                  error={errors.name?.message}
                  rules={{
                    required: { value: true, message: 'Name required.' },
                    validate: {
                      value: (formValue) => {
                        return (formValue as string) !== lazyLoadingRule?.name && existingLazyLoadingRules.map((f) => f.name).includes(formValue as string)
                          ? 'A rule with this name already exists'
                          : true
                      },
                    },
                  }}
                  maxLength={100}
                />
                <Subtitle>
                  <h2>Rule definition</h2>
                </Subtitle>
                <RuleDefinitionWrapper>
                  <p>Define how far from the viewable viewport the following actions must be done.</p>
                  <p>The smaller the number of viewports, the closer from viewable viewport the action will be done. </p>
                </RuleDefinitionWrapper>
                <Controller
                  control={control}
                  name={'headerBiddingEnabled'}
                  render={({ field: { onChange, value } }) => (
                    <FormToggle>
                      <Toggle
                        id={'headerBidding'}
                        onClick={() => {
                          onChange(!value)
                        }}
                        value={value}
                      />
                      <div>
                        <span>Lazy loading on header bidding</span>
                        <p>When header bidding is not lazy loaded, the auction starts at the moment the user opens a page.</p>
                        <p>If the stack doesn&apos;t use header bidding, this parameter won&apos;t have any impact.</p>
                      </div>
                    </FormToggle>
                  )}
                />
                <Controller
                  control={control}
                  name={'gamEnabled'}
                  render={({ field: { onChange, value } }) => (
                    <FormToggle>
                      <Toggle
                        id={'gamEnabled'}
                        onClick={() => {
                          onChange(!value)
                        }}
                        value={value}
                      />
                      <div>
                        <span>Lazy loading on GAM</span>
                        <p>When GAM is not lazy loaded, the request and impression rendering start as soon as possible.</p>
                      </div>
                    </FormToggle>
                  )}
                />
                <InputsWrapper>
                  <InputWrapper>
                    <Input
                      type={'number'}
                      label={'Auction start'}
                      disabled={!headerBiddingEnabled}
                      control={control}
                      name={'prebidAuctionViewportsPercent'}
                      key={'prebidAuctionViewportsPercent'}
                      error={headerBiddingEnabled && errors.prebidAuctionViewportsPercent?.message}
                      rules={{
                        required: { value: !!headerBiddingEnabled, message: 'Auction start required.' },
                        min: { value: 0, message: 'Please enter a value between 0 and 1000 viewports.' },
                        max: { value: 1000, message: 'Please enter a value between 0 and 1000 viewports.' },
                        pattern: { value: RegExp('^[0-9]*[,.]?[0-9]?$'), message: 'Please enter a value between 0 and 1000 viewports.' },
                      }}
                      min={0}
                      max={1000}
                      step={0.1}
                      onFocus={() => setFocusElement('Auction start')}
                      onBlur={() => setFocusElement(undefined)}
                    />{' '}
                    viewports
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      type={'number'}
                      label={'GAM request'}
                      disabled={!gamEnabled}
                      control={control}
                      name={'googletagFetchViewportsPercent'}
                      key={'googletagFetchViewportsPercent'}
                      error={gamEnabled && errors.googletagFetchViewportsPercent?.message}
                      rules={{
                        required: { value: !!gamEnabled, message: 'GAM request required.' },
                        min: { value: 0, message: 'Please enter a value between 0 and 1000 viewports.' },
                        max: { value: 1000, message: 'Please enter a value between 0 and 1000 viewports.' },
                        pattern: { value: RegExp('^[0-9]*[,.]?[0-9]?$'), message: 'Please enter a value between 0 and 1000 viewports.' },
                        validate: {
                          value: (formValue) => {
                            if (gamEnabled) {
                              const auctionStart = Number.parseFloat((prebidAuctionViewportsPercent ?? 'NaN').toString())
                              const gamRequest = Number.parseFloat((formValue ?? 'NaN').toString())
                              if (headerBiddingEnabled && !isNaN(auctionStart) && !isNaN(gamRequest) && gamRequest > auctionStart) {
                                return 'The GAM request cannot happen before the auction start.'
                              } else {
                                return true
                              }
                            } else {
                              return true
                            }
                          },
                        },
                      }}
                      min={0}
                      max={1000}
                      step={0.1}
                      onFocus={() => setFocusElement('GAM request')}
                      onBlur={() => setFocusElement(undefined)}
                    />{' '}
                    viewports
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      type={'number'}
                      label={'Render impression'}
                      disabled={!gamEnabled}
                      control={control}
                      name={'googletagRenderViewportsPercent'}
                      key={'googletagRenderViewportsPercent'}
                      error={gamEnabled && errors.googletagRenderViewportsPercent?.message}
                      rules={{
                        required: { value: !!gamEnabled, message: 'Render impression required.' },
                        min: { value: 0, message: 'Please enter a value between 0 and 1000 viewports.' },
                        max: { value: 1000, message: 'Please enter a value between 0 and 1000 viewports.' },
                        pattern: { value: RegExp('^[0-9]*[,.]?[0-9]?$'), message: 'Please enter a value between 0 and 1000 viewports.' },
                        validate: {
                          value: (formValue) => {
                            if (gamEnabled) {
                              const gamRequest = Number.parseFloat((googletagFetchViewportsPercent ?? 'NaN').toString())
                              const renderImpression = Number.parseFloat((formValue ?? 'NaN').toString())
                              if (!isNaN(renderImpression) && !isNaN(gamRequest) && renderImpression > gamRequest) {
                                return 'The render impression cannot happen before the GAM request.'
                              } else {
                                return true
                              }
                            } else {
                              return true
                            }
                          },
                        },
                      }}
                      min={0}
                      max={1000}
                      step={0.1}
                      onFocus={() => setFocusElement('Render impression')}
                      onBlur={() => setFocusElement(undefined)}
                    />{' '}
                    viewports
                  </InputWrapper>
                </InputsWrapper>
              </FormWrapper>
              <FormButtonsWrapper>
                <Button variant={'tertiary'} onClick={onBack}>
                  Cancel
                </Button>
                <Button variant={'primary'} type={'submit'} disabled={!isDirty || (!headerBiddingEnabled && !gamEnabled)}>
                  Save rule
                </Button>
              </FormButtonsWrapper>
            </form>
          )}
        </AdStackEditContentCard>
        <LazyLoadingViewportPreview
          auctionStart={headerBiddingEnabled ? prebidAuctionViewportsPercent ?? -1 : -1}
          gamRequest={gamEnabled ? googletagFetchViewportsPercent ?? -1 : -1}
          renderImpression={gamEnabled ? googletagRenderViewportsPercent ?? -1 : -1}
          focusElement={focusElement}
        />
      </PageWrapper>
    </AdStackEditPageWrapper>
  )
}

export const PureAdStackLazyLoadingEditPage = styled(_PureAdStackLazyLoadingEditPage)``
