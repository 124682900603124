import styled from '@emotion/styled'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { Site } from '@pubstack/common/src/tag'
import { TamConfig } from '@pubstack/common/src/tam/tamConfig'
import { FunctionComponent, useEffect, useState } from 'react'
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { Skeleton } from '~/components/Skeleton'
import { Toggle } from '~/components/Toggle'
import Table, { handleTableSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { PureCatalogItemHeader } from '~/modules/adstack/inventory/catalog/PureCatalogItemHeader'
import { WithClassName } from '~/types/utils'
import { PureAdStackTamIntegrationHelpFlyout } from './PureAdStackTamIntegrationHelpFlyout'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

const PubIdForm = styled.form`
  display: inline-flex;
  align-items: center;
  gap: 16px;
`
const SiteGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`
const SiteTitle = styled(TableCell)`
  font-weight: bold;
`
const NoSites = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 28px;
  text-align: center;
  ${Fonts.P1}
  h1 {
    ${Fonts.H1}
    font-weight: 400;
  }
`

type PureAdStackTamIntegrationPageProps = WithClassName & {
  breadcrumbs: React.ReactNode
  tamConfig: TamConfig
  onCreatePublisherId: (publisherId: string) => Promise<void>
  onCreateSiteConfig: (siteConfig: SiteConfig) => Promise<void>
  isLoading: boolean
  baseCDNUrl: string
  onBackClick: () => void
}

export const _PureAdStackTamIntegrationPage: FunctionComponent<PureAdStackTamIntegrationPageProps> = ({
  className,
  isLoading,
  breadcrumbs,
  tamConfig,
  onCreatePublisherId,
  onCreateSiteConfig,
  baseCDNUrl,
  onBackClick,
}) => {
  const [isOpenHelpFlyout, setIsOpenHelpFlyout] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(!tamConfig.apsPubId)
  const [columns, setColumns] = useState<TableColumns<Site>>([
    {
      name: 'Site',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'TAM enabled',
      isSortable: false,
    },
  ])
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<TamConfig>({
    defaultValues: tamConfig,
  })

  const onPublisherIdEdit: SubmitHandler<TamConfig> = () => {
    setIsEditing(!isEditing)
  }

  const onPublisherIdSubmit: SubmitHandler<TamConfig> = (formValue) => {
    onCreatePublisherId(formValue.apsPubId)
    setIsEditing(!isEditing)
  }

  const getErrorMessage = (errors: FieldErrors<TamConfig>) => {
    if (errors.apsPubId?.type === 'required') {
      return 'Publisher ID is required'
    }
  }
  const onSiteConfigSubmit: SubmitHandler<SiteConfig> = (formValue) => {
    onCreateSiteConfig(formValue)
  }

  const sortedSites = handleTableSort(columns, tamConfig.sites)
  useEffect(() => {
    reset(tamConfig)
    setIsEditing(!tamConfig.apsPubId)
  }, [tamConfig])

  return (
    <Wrapper className={className}>
      <PureCatalogItemHeader
        breadcrumbs={breadcrumbs}
        catalogItemCode={'tam'}
        catalogItemType={'wrapper'}
        isLoading={isLoading}
        isActive={!!tamConfig.apsPubId}
        baseCDNUrl={baseCDNUrl}
        actions={
          <>
            <Button variant={'tertiary'} onClick={onBackClick}>
              Back
            </Button>
            <Button variant={'tertiary'} onClick={() => setIsOpenHelpFlyout(true)} iconName={'help'}>
              Help
            </Button>
          </>
        }
      />
      <ContentCard color={Colors.Pumpkin}>
        <PubIdForm onSubmit={handleSubmit(onPublisherIdSubmit)}>
          <Input disabled={!isEditing} type={'text'} name={'apsPubId'} rules={{ required: true }} label={'Publisher ID'} control={control} error={getErrorMessage(errors)} />
          {!isEditing ? (
            <Button variant={'primary'} onClick={handleSubmit(onPublisherIdEdit)}>
              Edit
            </Button>
          ) : (
            <Button disabled={!isDirty} variant={'primary'} onClick={handleSubmit(onPublisherIdSubmit)}>
              Save
            </Button>
          )}
        </PubIdForm>
      </ContentCard>
      <SiteGroup>
        {!isLoading ? (
          tamConfig.apsPubId !== '' && tamConfig.sites.length > 0 ? (
            <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
              {sortedSites.map((site) => {
                const siteConfig = tamConfig.siteConfigs.find((s) => site.id === s.siteId) || { siteId: site.id, scopeId: '', isTamEnabled: !!tamConfig.apsPubId }
                return (
                  <TableRow key={site?.id}>
                    <SiteTitle>{site.name}</SiteTitle>
                    <TableCell>
                      <Toggle
                        id={site.id}
                        value={siteConfig?.isTamEnabled === undefined ? !!tamConfig.apsPubId : siteConfig?.isTamEnabled}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          const data = siteConfig as SiteConfig
                          data.isTamEnabled = !data?.isTamEnabled
                          onSiteConfigSubmit(data)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
          ) : (
            <NoSites>
              <Icon name={'night_sky'} width={'190px'} />
              <h1>You don’t have any sites yet or your Publisher Id is not set.</h1>
            </NoSites>
          )
        ) : (
          <Skeleton bigger width={'100%'} />
        )}
      </SiteGroup>
      <PureAdStackTamIntegrationHelpFlyout isOpen={isOpenHelpFlyout} onClose={() => setIsOpenHelpFlyout(false)} />
    </Wrapper>
  )
}

export const PureAdStackTamIntegrationPage = styled(_PureAdStackTamIntegrationPage)``
