import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Color, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'
import { Tooltip } from './Tooltip'

export type LegendProps = WithClassName & {
  label: string | ReactNode
  fontColor?: Color
  iconName?: IconName
  iconColor?: Color
  value?: ReactNode
  tooltip?: ReactNode
}

export const LegendValue = styled.span`
  font-weight: bold;
  margin-left: 2px;
`

export const LegendTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
`

export const LegendTooltipIcon = styled(Icon)`
  margin-left: 2px;
`

const LegendWrapper = styled.div<{ fontColor?: Color }>`
  ${Fonts.P2}

  display: inline-flex;
  align-items: center;

  color: ${({ fontColor }) => (fontColor ? fontColor : Colors.Jet)};

  & > ${Icon} {
    margin-right: 6px;
  }
`

const _Legend: FunctionComponent<LegendProps> = ({ className, label, fontColor, iconName = 'square', iconColor, value, tooltip }) => {
  return (
    <LegendWrapper className={className} fontColor={fontColor}>
      <Icon name={iconName} fill={iconColor} width={Sizes[14]} height={Sizes[14]} />
      {label}
      {!!value && (
        <>
          : <LegendValue>{value}</LegendValue>
        </>
      )}
      {!!tooltip && (
        <LegendTooltip title={tooltip}>
          <LegendTooltipIcon name={'info'} width={'14px'} />
        </LegendTooltip>
      )}
    </LegendWrapper>
  )
}

export const Legend = styled(_Legend)``
