import styled from '@emotion/styled'
import { FunctionComponent, isValidElement } from 'react'
import { PaletteColor } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon, IconName } from '~/components/Icon'
import { WithClassName } from '~/types/utils'
import { TOAST_TYPE_COLOR, ToastHandlerMessage, ToastType } from './ToastConfig'

export type ToastMessageProps = WithClassName & {
  type?: ToastType
  icon?: IconName
  message: ToastHandlerMessage
  iconFillColor?: PaletteColor[keyof PaletteColor]
}

const ToastMessageContent = styled.div`
  ${Fonts.P1}
  padding: 16px 20px;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`

const ToastMessageTitle = styled.span`
  display: inline-flex;
  gap: 8px;

  // because we don't have a hand on what the message will be we need to align the icon ourselves
  // this value is equal to ((line-height)-(font-size))/2
  align-items: flex-start;
  & ${Icon} {
    margin-top: 0.156rem;
  }
`

/**
 * Should only be used when calling toast functions in useToast
 */
const _ToastMessage: FunctionComponent<ToastMessageProps> = ({ className, message, icon, type, iconFillColor }) => {
  const isSingleElement = isValidElement<any>(message) || typeof message === 'string'

  return (
    <ToastMessageContent className={className}>
      <ToastMessageTitle>
        {icon && <Icon height={'20px'} width={'20px'} name={icon} fill={iconFillColor ? iconFillColor : TOAST_TYPE_COLOR[type ?? 'neutral'].color} />}
        {isSingleElement ? <span>{message}</span> : <strong>{message?.title}</strong>}
      </ToastMessageTitle>
      {!isSingleElement && message?.body}
    </ToastMessageContent>
  )
}

export const ToastMessage = styled(_ToastMessage)``
