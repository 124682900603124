import { BidParam, BidParamType } from './bidderCatalog'

export enum BidderParamValidationType {
  MISSING_PARAMS = 'missing-params',
  WRONG_TYPE = 'wrong-type',
  DUPLICATE_ENTRY = 'duplicate-entry',
  MISSING_LINES = 'missing-lines',
}

type BidderParamValidationPayload = {
  [BidderParamValidationType.MISSING_PARAMS]: {
    paramName: string
  }
  [BidderParamValidationType.WRONG_TYPE]: {
    paramName: string
    expectedType: BidParamType
  }
  [BidderParamValidationType.DUPLICATE_ENTRY]: {
    siteName: string
    device: string
    adUnitName: string
  }
  [BidderParamValidationType.MISSING_LINES]: {
    expectedLines: number
    actualLines: number
  }
}

export type BidderParamValidation<Type extends BidderParamValidationType = BidderParamValidationType> = { line: number | undefined } & {
  type: Type
  detail: BidderParamValidationPayload[Type]
}

export class BidderValidationError extends Error {}

export const validateExpectedBidderParamType = (
  value: string | undefined,
  param: BidParam
): BidderParamValidation<BidderParamValidationType.WRONG_TYPE | BidderParamValidationType.MISSING_PARAMS> | undefined => {
  if (!value) {
    return {
      line: undefined,
      type: BidderParamValidationType.MISSING_PARAMS,
      detail: {
        paramName: param.name,
      },
    }
  }

  const validation: BidderParamValidation<BidderParamValidationType.WRONG_TYPE> = {
    line: undefined,
    type: BidderParamValidationType.WRONG_TYPE,
    detail: {
      paramName: value,
      expectedType: param.type,
    },
  }

  switch (param.type) {
    case 'string': {
      break
    }
    case 'number': {
      const numValue = parseInt(value)
      if (isNaN(numValue)) {
        return validation
      }
      break
    }
    case 'array': {
      if (typeof value !== 'string') {
        return validation
      }
      if (!(value as string).match(/\[.*?\]/)) {
        return validation
      }
      break
    }
    case 'boolean': {
      if (!['true', 'false'].includes(value)) {
        return validation
      }
      break
    }
    default: {
      throw new BidderValidationError(`Bid param from catalog has an unknown type : ${param.type}`)
    }
  }
}

/**
 * Denormalize a bidder param string value to its expected type
 */
export const denormalizeBidderParamString = (param: BidParam, value: string): string | number | string[] | boolean => {
  const CSV_ARRAY_DELIMITER = ',' as const
  switch (param.type) {
    case 'string': {
      return value
    }
    case 'number': {
      return parseInt(value)
    }
    case 'array': {
      // we receive a string formated as "[value1, value2, value3]", we need to remove the brackets and split on the delimiter
      // quotes are required around arrays to be CSV compliant, but the parser removes them automatically, we don't need to do anything for them
      return (
        value
          .slice(1, -1) // remove brackets
          .split(CSV_ARRAY_DELIMITER)
          // trim each value and remove surrounding single quotes if needed
          .map((v: string) => v.trim().replace(/^['|’|‘](.*)['|‘|’]$/, '$1'))
      )
    }
    case 'boolean': {
      return value === 'true'
    }
    default: {
      throw new Error(`Bid param from catalog has an unexpected type : ${param.type}. Might be a database error.`)
    }
  }
}
