import styled from '@emotion/styled'
import AbTestVoid from '~/assets/icons/ab_test_void.svg?react'
import AccountCircleIcon from '~/assets/icons/account_circle.svg?react'
import AdStackIcon from '~/assets/icons/ad_stack.svg?react'
import PathIcon from '~/assets/icons/ad_unit_path.svg?react'
import AddIcon from '~/assets/icons/add.svg?react'
import AddAdUnitIcon from '~/assets/icons/add_adunit.svg?react'
import AddBidderIcon from '~/assets/icons/add_bidder.svg?react'
import AddCircleIcon from '~/assets/icons/add_circle.svg?react'
import AddPersonIcon from '~/assets/icons/add_person.svg?react'
import AddSiteIcon from '~/assets/icons/add_site.svg?react'
import AdminIcon from '~/assets/icons/admin.svg?react'
import AnchorIcon from '~/assets/icons/anchor.svg?react'
import Anchor from '~/assets/icons/anchor_ad_format.svg?react'
import ArchiveIcon from '~/assets/icons/archive.svg?react'
import ArrowRightAltIcon from '~/assets/icons/arrow_right_alt.svg?react'
import BannerIcon from '~/assets/icons/banner.svg?react'
import BarIcon from '~/assets/icons/bar.svg?react'
import ChartBarIcon from '~/assets/icons/bar_chart.svg?react'
import BlockIcon from '~/assets/icons/block.svg?react'
import BuildIcon from '~/assets/icons/build.svg?react'
import CalendarIcon from '~/assets/icons/calendar.svg?react'
import CancelCircle from '~/assets/icons/cancel_circle.svg?react'
import CategoryIcon from '~/assets/icons/category.svg?react'
import Chat from '~/assets/icons/chat.svg?react'
import CheckIcon from '~/assets/icons/check.svg?react'
import ChevronRightCircleIcon from '~/assets/icons/chevron_right_circle.svg?react'
import Circle from '~/assets/icons/circle.svg?react'
import CloseIcon from '~/assets/icons/close.svg?react'
import CopyIcon from '~/assets/icons/copy.svg?react'
import Custom from '~/assets/icons/custom_ad_format.svg?react'
import DashboardIcon from '~/assets/icons/dashboard.svg?react'
import DataLineDashedIcon from '~/assets/icons/dashed.svg?react'
import DataLineIcon from '~/assets/icons/data_line.svg?react'
import DeleteIcon from '~/assets/icons/delete.svg?react'
import DesktopIcon from '~/assets/icons/desktop.svg?react'
import DeviceIcon from '~/assets/icons/devices.svg?react'
import ArrowBottomRightIcon from '~/assets/icons/diagonal_arrow_down_right.svg?react'
import DonutIcon from '~/assets/icons/donut.svg?react'
import DownloadIcon from '~/assets/icons/download.svg?react'
import DraftIcon from '~/assets/icons/draft.svg?react'
import DynamicIcon from '~/assets/icons/dynamic.svg?react'
import EditIcon from '~/assets/icons/edit.svg?react'
import EmptyIcon from '~/assets/icons/empty.svg?react'
import ViewIcon from '~/assets/icons/eye.svg?react'
import InputsIcon from '~/assets/icons/feature.svg?react'
import FloorIcon from '~/assets/icons/floor.svg?react'
import Folder from '~/assets/icons/folder.svg?react'
import GamIcon from '~/assets/icons/gam.svg?react'
import GamColor from '~/assets/icons/gamColor.svg?react'
import BoltIcon from '~/assets/icons/gam_overview_amp.svg?react'
import ShinyAppIcon from '~/assets/icons/gam_overview_app.svg?react'
import ShinyWebIcon from '~/assets/icons/gam_overview_web.svg?react'
import GroupIcon from '~/assets/icons/groups.svg?react'
import HeaderBiddingIcon from '~/assets/icons/header_bidding.svg?react'
import HelpIcon from '~/assets/icons/help.svg?react'
import HelpOutlineIcon from '~/assets/icons/help_outline.svg?react'
import InContent from '~/assets/icons/in_content.svg?react'
import InfoIcon from '~/assets/icons/info.svg?react'
import Key from '~/assets/icons/key.svg?react'
import OrganizeDownIcon from '~/assets/icons/keyboard_arrow_big_down.svg?react'
import OrganizeUpIcon from '~/assets/icons/keyboard_arrow_big_up.svg?react'
import ChevronDownIcon from '~/assets/icons/keyboard_arrow_down.svg?react'
import ChevronLeftIcon from '~/assets/icons/keyboard_arrow_left.svg?react'
import ChevronRightIcon from '~/assets/icons/keyboard_arrow_right.svg?react'
import ChevronUpIcon from '~/assets/icons/keyboard_arrow_up.svg?react'
import SiteIcon from '~/assets/icons/language.svg?react'
import Leaderboard from '~/assets/icons/leaderboard.svg?react'
import LinkedIcon from '~/assets/icons/linked.svg?react'
import LoadingIcon from '~/assets/icons/loading.svg?react'
import BidderIcon from '~/assets/icons/local_atm.svg?react'
import Shipping from '~/assets/icons/local_shipping.svg?react'
import Lock from '~/assets/icons/lock.svg?react'
import LockRound from '~/assets/icons/lock_round.svg?react'
import LogoutIcon from '~/assets/icons/logout.svg?react'
import MailIcon from '~/assets/icons/mail.svg?react'
import MascotIcon from '~/assets/icons/mascot.svg?react'
import MenuIcon from '~/assets/icons/menu.svg?react'
import MenuExtendIcon from '~/assets/icons/menu_open.svg?react'
import MillisecondIcon from '~/assets/icons/millisecond.svg?react'
import MobileIcon from '~/assets/icons/mobile.svg?react'
import MobileInterstitial from '~/assets/icons/mobile_interstitial.svg?react'
import MobileLeaderboard from '~/assets/icons/mobile_leaderboard.svg?react'
import NightSky from '~/assets/icons/night_sky.svg?react'
import NotificationIcon from '~/assets/icons/notifications.svg?react'
import OpenInNew from '~/assets/icons/open_in_new.svg?react'
import OutOfPage from '~/assets/icons/out_of_page.svg?react'
import OutstreamIcon from '~/assets/icons/outstream.svg?react'
import Outstream2Icon from '~/assets/icons/outstream2.svg?react'
import OutstreamSingleAd from '~/assets/icons/outstream_single_ad.svg?react'
import PaperplaneRound from '~/assets/icons/paperplane_round.svg?react'
import ParallaxIcon from '~/assets/icons/parallax.svg?react'
import PrebidIcon from '~/assets/icons/pbjs.svg?react'
import PercentIcon from '~/assets/icons/percent.svg?react'
import PersonIcon from '~/assets/icons/person.svg?react'
import PictureInPictureIcon from '~/assets/icons/picture_in_picture.svg?react'
import PixelIcon from '~/assets/icons/pixel.svg?react'
import PositionIcon from '~/assets/icons/place.svg?react'
import PlusIcon from '~/assets/icons/plus.svg?react'
import PolicyIcon from '~/assets/icons/policy.svg?react'
import PopulationSplit from '~/assets/icons/population_split.svg?react'
import PresentIcon from '~/assets/icons/present.svg?react'
import PubstackIcon from '~/assets/icons/pubstack.svg?react'
import RadioIcon from '~/assets/icons/radio.svg?react'
import ReceiptIcon from '~/assets/icons/receipt.svg?react'
import RectangleLarge from '~/assets/icons/rectangle_large.svg?react'
import RectangleMedium from '~/assets/icons/rectangle_medium.svg?react'
import RefreshIcon from '~/assets/icons/refresh.svg?react'
import RestoreIcon from '~/assets/icons/refresh_left.svg?react'
import DocumentIcon from '~/assets/icons/report.svg?react'
import AlertIcon from '~/assets/icons/reportalert.svg?react'
import Rocket from '~/assets/icons/rocket.svg?react'
import SearchIcon from '~/assets/icons/search.svg?react'
import SecondIcon from '~/assets/icons/second.svg?react'
import SendIcon from '~/assets/icons/send.svg?react'
import SentimentDissatisfiedIcon from '~/assets/icons/sentiment_dissatisfied.svg?react'
import SentimentSatisfiedIcon from '~/assets/icons/sentiment_satisfied.svg?react'
import SettingsIcon from '~/assets/icons/settings.svg?react'
import Sidebar from '~/assets/icons/sidebar.svg?react'
import SigmaIcon from '~/assets/icons/sigma.svg?react'
import SizeIcon from '~/assets/icons/size.svg?react'
import SkinSingleAd from '~/assets/icons/skin.svg?react'
import Smart from '~/assets/icons/smart.svg?react'
import SquareIcon from '~/assets/icons/square.svg?react'
import StarIcon from '~/assets/icons/star.svg?react'
import StarEmptyIcon from '~/assets/icons/star_empty.svg?react'
import Stars from '~/assets/icons/stars.svg?react'
import StoreFront from '~/assets/icons/store_front.svg?react'
import CheckCircledIcon from '~/assets/icons/success.svg?react'
import SyncIcon from '~/assets/icons/sync.svg?react'
import SyncProblemIcon from '~/assets/icons/sync_problem.svg?react'
import TabletIcon from '~/assets/icons/tablet.svg?react'
import TimelineIcon from '~/assets/icons/timeline.svg?react'
import Tips from '~/assets/icons/tips.svg?react'
import UnarchiveIcon from '~/assets/icons/unarchive.svg?react'
import OrganizeIcon from '~/assets/icons/unfold_more.svg?react'
import UploadIcon from '~/assets/icons/upload.svg?react'
import VideoIcon from '~/assets/icons/video.svg?react'
import ViewOffIcon from '~/assets/icons/visibility-off.svg?react'
import WarningIcon from '~/assets/icons/warning.svg?react'
import FireIcon from '~/assets/icons/whatshot.svg?react'
import SnoozeIcon from '~/assets/icons/zzz.svg?react'
import { LogoMenuIcon } from './LogoMenuIcon'

export const illustrations = {
  paperplane_round: () => <PaperplaneRound />,
  lock: () => <Lock />,
  lock_round: () => <LockRound />,
  mascot: () => <MascotIcon />,
  night_sky: () => <NightSky />,
  ab_test_void: () => <AbTestVoid />,
} as const

export const adFormats = {
  anchor_ad_format: () => <Anchor />,
  leaderboard: () => <Leaderboard />,
  sidebar: () => <Sidebar />,
  rectangle_medium: () => <RectangleMedium />,
  rectangle_large: () => <RectangleLarge />,
  outstream_single_ad: () => <OutstreamSingleAd />,
  mobile_leaderboard: () => <MobileLeaderboard />,
  in_content: () => <InContent />,
  skin_single_ad: () => <SkinSingleAd />,
  out_of_page: () => <OutOfPage />,
  mobile_interstitial: () => <MobileInterstitial />,
  custom: () => <Custom />,
} as const

export const icons = {
  add: () => <AddIcon />,
  plus: () => <PlusIcon />,
  add_circle: () => <AddCircleIcon />,
  account_circle: () => <AccountCircleIcon />,
  add_person: () => <AddPersonIcon />,
  admin: () => <AdminIcon />,
  ad_stack: () => <AdStackIcon />,
  anchor: () => <AnchorIcon />,
  path: () => <PathIcon />,
  add_adUnit: () => <AddAdUnitIcon />,
  add_bidder: () => <AddBidderIcon />,
  archive: () => <ArchiveIcon />,
  chart_bar: () => <ChartBarIcon />,
  calendar: () => <CalendarIcon />,
  close: () => <CloseIcon />,
  dashboard: () => <DashboardIcon />,
  delete: () => <DeleteIcon />,
  device: () => <DeviceIcon />,
  desktop: () => <DesktopIcon />,
  edit: () => <EditIcon />,
  help_outline: () => <HelpOutlineIcon />,
  chevron_down: () => <ChevronDownIcon />,
  chevron_up: () => <ChevronUpIcon />,
  chevron_left: () => <ChevronLeftIcon />,
  chevron_right: () => <ChevronRightIcon />,
  bidder: () => <BidderIcon />,
  notification: () => <NotificationIcon />,
  mobile: () => <MobileIcon />,
  picture_in_picture: () => <PictureInPictureIcon />,
  refresh: () => <RefreshIcon />,
  restore: () => <RestoreIcon />,
  search: () => <SearchIcon />,
  settings: () => <SettingsIcon />,
  arrow_bottom_right: () => <ArrowBottomRightIcon />,
  unarchive: () => <UnarchiveIcon />,
  organize: () => <OrganizeIcon />,
  organize_up: () => <OrganizeUpIcon />,
  organize_down: () => <OrganizeDownIcon />,
  arrow_right_alt: () => <ArrowRightAltIcon />,
  alert: () => <AlertIcon />,
  warning: () => <WarningIcon />,
  menu_extend: () => <MenuExtendIcon />,
  menu: () => <MenuIcon />,
  check_circled: () => <CheckCircledIcon />,
  info: () => <InfoIcon />,
  policy: () => <PolicyIcon />,
  help: () => <HelpIcon />,
  copy: () => <CopyIcon />,
  check: () => <CheckIcon />,
  download: () => <DownloadIcon />,
  document: () => <DocumentIcon />,
  prebid: () => <PrebidIcon />,
  person: () => <PersonIcon />,
  logout: () => <LogoutIcon />,
  view: () => <ViewIcon />,
  view_off: () => <ViewOffIcon />,
  position: () => <PositionIcon />,
  sentiment_dissatisfied: () => <SentimentDissatisfiedIcon />,
  sentiment_satisfied: () => <SentimentSatisfiedIcon />,
  site: () => <SiteIcon />,
  sync_problem: () => <SyncProblemIcon />,
  tips: () => <Tips />,
  fire: () => <FireIcon />,
  upload: () => <UploadIcon />,
  group: () => <GroupIcon />,
  folder: () => <Folder />,
  gam: () => <GamIcon />,
  shiny_web: () => <ShinyWebIcon />,
  bolt: () => <BoltIcon />,
  shiny_app: () => <ShinyAppIcon />,
  shipping: () => <Shipping />,
  donut: () => <DonutIcon />,
  rocket: () => <Rocket />,
  square: () => <SquareIcon />,
  data_line_dashed: () => <DataLineDashedIcon />,
  mail: () => <MailIcon />,
  sigma: () => <SigmaIcon />,
  percent: () => <PercentIcon />,
  data_line: () => <DataLineIcon />,
  size: () => <SizeIcon />,
  empty: () => <EmptyIcon />,
  radio: () => <RadioIcon />,
  receipt: () => <ReceiptIcon />,
  category: () => <CategoryIcon />,
  add_site: () => <AddSiteIcon />,
  inputs: () => <InputsIcon />,
  key: () => <Key />,
  circle: () => <Circle />,
  chat: () => <Chat />,
  gam_color: () => <GamColor />,
  block: () => <BlockIcon />,
  tablet: () => <TabletIcon />,
  star: () => <StarIcon />,
  star_empty: () => <StarEmptyIcon />,
  smart: () => <Smart />,
  population_split: () => <PopulationSplit />,
  present: () => <PresentIcon />,
  pubstack: () => <PubstackIcon />,
  linked: () => <LinkedIcon />,
  draft: () => <DraftIcon />,
  chevronRightCircle: () => <ChevronRightCircleIcon />,
  loading: () => <LoadingIcon />,
  dynamic: () => <DynamicIcon />,
  header_bidding: () => <HeaderBiddingIcon />,
  send: () => <SendIcon />,
  open_in_new: () => <OpenInNew />,
  store_front: () => <StoreFront />,
  floor: () => <FloorIcon />,
  millisecond: () => <MillisecondIcon />,
  cancel_circle: () => <CancelCircle />,
  sync: () => <SyncIcon />,
  video: () => <VideoIcon />,
  snooze: () => <SnoozeIcon />,
  outstream: () => <OutstreamIcon />,
  logoMenu: ({ isHover }: { isHover?: boolean }) => <LogoMenuIcon isHover={isHover} />,
  build: () => <BuildIcon />,
  pixel: () => <PixelIcon />,
  banner: () => <BannerIcon />,
  outstream2: () => <Outstream2Icon />,
  parallax: () => <ParallaxIcon />,
  second: () => <SecondIcon />,
  timeline: () => <TimelineIcon />,
  bar: () => <BarIcon />,
  stars: () => <Stars />,
  ...illustrations,
  ...adFormats,
} as const

export type IconName = keyof typeof icons

export type IconProps = {
  name: IconName
  width?: string
  height?: string
  fill?: string
  stroke?: string
  className?: string
  isHover?: boolean
}

const SvgContainer = styled.span<IconProps>`
  width: ${(props) => props.width ?? '1.5rem'};
  min-width: ${(props) => props.width ?? '1.5rem'};
  height: ${(props) => props.height ?? props.width ?? '1.5rem'};
  min-height: ${(props) => props.height ?? props.width ?? '1.5rem'};
  --fill-color: ${(props) => props.fill};
  color: ${(props) => props.fill};
  --stroke-color: ${(props) => props.stroke};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  & > svg {
    inset: 0;
    width: 100%;
    height: 100%;
  }
`

const _Icon = (props: IconProps) => {
  const Svg = icons[props.name]
  return (
    <SvgContainer {...props}>
      <Svg isHover={props.isHover} />
    </SvgContainer>
  )
}
export const Icon = styled(_Icon)``
