import styled from '@emotion/styled'
import { AdUnit } from '@pubstack/common/src/adunit'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { DisplayedStack, EMPTY_DISPLAYED_STACK, Stack, buildStackTemplates } from '@pubstack/common/src/stack'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useDetailedStacks, useStacks } from '~/api/adm-api.hook'
import { useSites } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { useToast } from '~/components/Toast/useToasts'
import { PureStackTemplateFlyout } from '~/modules/adstack/inventory/sites/PureStackTemplateFlyout'
import { WithClassName } from '~/types/utils'
import { useLogger } from '~/utils/logger'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureSiteOverview } from './PureSiteOverview'
import { SitePageContext } from './SitePage'

const useSteps = (stacks: Stack[] | undefined, siteConfig: SiteConfig | undefined, adUnits: AdUnit[], site: Site, onDefaultStackClick: () => void) => {
  const navigate = useNavigate()
  return useMemo(() => {
    return {
      adUnits: {
        onClick: () => navigate('/adstack/adunits'),
        done: adUnits.some((adUnit) => adUnit.devices.includes('desktop')) && adUnits.some((adUnit) => adUnit.devices.includes('mobile')),
      },
      contextKeys: {
        onClick: () => navigate('/adstack/context/context-keys'),
      },
      gamAndAUnitPath: {
        onClick: () => navigate(`/adstack/sites/${site.id}/adserver/gam`),
        done: (siteConfig?.adUnitPathConfig?.length ?? 0) > 0,
      },
      biddersAndModules: {
        onClick: () => navigate('/adstack/integrations/my-integrations'),
      },
      defaultStack: {
        onClick: () => onDefaultStackClick(),
        done: stacks?.some((s) => s.name === 'Default' && s.status === 'live') ?? false,
      },
    }
  }, [stacks, siteConfig, adUnits])
}

type SiteOverviewProps = WithClassName & {
  //
}

const _SiteOverview: FunctionComponent<SiteOverviewProps> = ({ className }) => {
  const logger = useLogger()
  const toast = useToast()
  const navigate = useNavigate()
  const { details } = useDetailedStacks(null)
  const currencySymbol = useScopeCurrency()
  const [stackDetails, setStackDetails] = useState<DisplayedStack>(EMPTY_DISPLAYED_STACK)
  const onCreateStack = async (templateId: string) => {
    setIsOpenStackTemplateFlyout(false)
    navigate(`../stacks/new?template=${templateId}`)
  }

  const onDetailedView = async (id: string, siteId: string) => {
    if (details.loading) {
      details.abort()
    }
    try {
      const detailsStack = await details.get(id, siteId)
      setStackDetails(detailsStack)
      await logger.info({ action: 'click', type: 'detailed-view-flyout', actionName: 'stack', from: 'stacks-page' })
    } catch (exception) {
      toast.alert(`An error occurred while getting the detailed stack.`)

      throw exception
    }
  }

  const user = useUser()
  const { currentSite, onAdManagementEnable, stacks, adUnits, siteConfig, spaConfig, isLoading, onSpaUpdate } = useOutletContext<SitePageContext>()
  const [templates, setTemplates] = useState<Stack[]>([])
  const [isOpenStackTemplateFlyout, setIsOpenStackTemplateFlyout] = useState<boolean>(false)
  const onDefaultStackClick = () => {
    if (stacks === undefined || (siteConfig?.adUnitPathConfig?.length ?? 0) === 0 || adUnits.length === 0) return
    if (stacks.length > 0) {
      navigate('../stacks')
    } else {
      if (templates.length > 0) {
        setIsOpenStackTemplateFlyout(true)
      } else {
        navigate(`../stacks/new`)
      }
    }
  }
  const steps = useSteps(stacks, siteConfig, adUnits, currentSite, onDefaultStackClick)
  const props = {
    canEnableAdManagement: !!(user?.getScopeRole() === 'owner' || user?.isAdmin),
    name: currentSite.name,
    adManagementEnabled: currentSite.adManagementEnabled ?? false,
    onAdManagementEnable,
    steps,
    isLoading,
    onSpaUpdate,
    spaConfig,
    canEnableSpa: !!user?.isAdmin,
  }
  const [sites, setSites] = useState<Pick<Site, 'id' | 'name'>[]>([])
  const { all: allStacks } = useStacks(null)
  const { all: allSites } = useSites(null)

  async function loadSites() {
    const allSitesByScope = await allSites.get({ getDisabledSites: false })
    setSites(allSitesByScope.filter((s) => s.adManagementEnabled).sort((a, b) => a.name.localeCompare(b.name)))
  }

  async function loadStackTemplates() {
    const stacks = await allStacks.get()
    setTemplates(stacks)
  }

  useEffect(() => {
    loadSites()
  }, [])

  useEffect(() => {
    loadStackTemplates()
  }, [sites])

  return (
    <>
      <PureSiteOverview className={className} {...props} />
      {currentSite.id && (
        <PureStackTemplateFlyout
          isOpen={isOpenStackTemplateFlyout}
          isLoading={allSites.loading || allStacks.loading}
          sites={sites}
          currentSiteId={currentSite.id}
          templates={buildStackTemplates(templates)}
          detailedStack={stackDetails}
          currencySymbol={currencySymbol}
          onClose={() => setIsOpenStackTemplateFlyout(false)}
          onCreateStack={(templateId) => onCreateStack(templateId)}
          onDetailedView={(id, siteId) => onDetailedView(id, siteId)}
        />
      )}
    </>
  )
}
export const SiteOverview = styled(_SiteOverview)``
