import { ContextKey } from '@pubstack/common/src/contextKey'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContextKeys } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackContextKeysPage } from './PureAdStackContextKeysPage'

export const AdStackContextKeysPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const [contextKeys, setContextKeys] = useState<ContextKey[]>([])
  const toast = useToast()
  const { all } = useContextKeys(null)

  const loadContextKeyPageData = async () => {
    if (all.loading) {
      all.abort()
    }

    try {
      const result = await all.get()
      result.forEach((c) => c.values.sort((a, b) => b.localeCompare(a)).sort((a, b) => (a === c.defaultValue || b !== c.defaultValue ? -1 : 1)))
      setContextKeys(result)
    } catch (e) {
      toast.alert(`It seems that no configuration has been created for this scope. Context keys can't be retrieved. Please contact support team.`, { icon: 'rocket', duration: Infinity })
    }
  }

  useEffect(() => {
    loadContextKeyPageData()
  }, [])

  return (
    <PureAdStackContextKeysPage
      contextKeys={contextKeys}
      isLoading={all.loading}
      onCreateContextKey={() => navigate(`/adstack/context/context-keys/new`)}
      onUpdateContextKey={(contextKey) => navigate(`/adstack/context/context-keys/edit/${contextKey.name}`)}
      breadcrumbs={breadcrumbs}
    />
  )
}
