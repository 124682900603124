import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { WithClassName } from '~/types/utils'

const ChartTitle = styled.span`
  & > span {
    margin-left: 4px;
    border-left: 1px solid black;
    padding-left: 4px;

    & > strong {
      font-weight: bold;
    }
  }
`

type PureSiteChartTitleProps = WithClassName & { title: string; infoLabel: string; infoValue: string }
const _PureSiteChartTitle: FunctionComponent<PureSiteChartTitleProps> = ({ className, title, infoLabel, infoValue }) => (
  <ChartTitle className={className}>
    {title}
    <span>
      {infoLabel}: <strong>{infoValue}</strong>
    </span>
  </ChartTitle>
)

export const PureSiteChartTitle = styled(_PureSiteChartTitle)``
