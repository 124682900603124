import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AllMetrics, MappedName } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { useMemo, useState } from 'react'
import { MultipleDetailedDataTable } from '~/components/DataTable/DataTable'
import { TabProp, Tabs } from '~/components/Tabs'
import { Widget as BaseWidget, WidgetProps } from '~/components/Widget'
import { WithClassName } from '~/types/utils'
import { TabConfig } from '~/utils/analytics'
import { AnalyticsBreakdownLimitMessage } from './AnalyticsBreakdownLimitMessage'
import { AnalyticsDataTableConfigs, AnalyticsDataTableFilterData, useAnalyticsDataTable } from './analyticsDataTable.hooks'

const Widget = styled(BaseWidget)`
  max-height: 100%;
`

const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: hidden;
`

const TabContentWrapper = styled.div`
  margin-top: 20px;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

type RawData = Partial<AllMetrics> & {
  name: MappedName[]
}

type UseAnalyticsBreakdownProps<P extends string> = {
  rawData?: RawData
  currencySymbol: CurrencySymbol
  onTabChange: (dimension: Dimension) => void
  defaultTabIndex?: number
  tabsConfig: TabConfig[]
  getConfigs: (_: { dimension: Dimension }) => AnalyticsDataTableConfigs<P>
  getColumnsConfigs: (_: { dimension: Dimension }) => P[]
  defaultSort?: P
  filterData?: AnalyticsDataTableFilterData<P>
  maxResults?: number
}
export const useAnalyticsBreakdown = <P extends string>({
  rawData,
  currencySymbol,
  onTabChange,
  defaultTabIndex,
  tabsConfig,
  getConfigs,
  getColumnsConfigs,
  defaultSort,
  filterData,
  maxResults,
}: UseAnalyticsBreakdownProps<P>) => {
  const [currentTab, setCurrentTab] = useState(defaultTabIndex ?? 0)
  const tabs: TabProp[] = tabsConfig.map((tab, index) => ({
    active: index === currentTab,
    iconName: tab.iconName,
    label: tab.label,
  }))

  const dataConfigs = useMemo(() => getConfigs({ dimension: tabsConfig[currentTab].dimension }), [tabsConfig[currentTab].dimension])
  const columnsConfigs = useMemo(() => getColumnsConfigs({ dimension: tabsConfig[currentTab].dimension }), [tabsConfig[currentTab].dimension])

  const onTabClick = (tab: TabProp) => {
    const newTab = tabs.findIndex((t) => t === tab)
    setCurrentTab(newTab)
    const dimension = tabsConfig[newTab].dimension
    onTabChange(dimension)
  }

  const { data, columns, sorting } = useAnalyticsDataTable({ rawData, currencySymbol, filterData, dataConfigs, columnsConfigs, maxResults, defaultSort })

  return {
    data,
    columns,
    sorting,
    onTabClick,
    tabs,
    currentTab,
  }
}

export type AnalyticsBreakdownProps<P extends string> = WithClassName &
  Omit<WidgetProps, 'title' | 'icon' | 'info' | 'subTitle'> &
  ReturnType<typeof useAnalyticsBreakdown<P>> & {
    onRowClick: (name: MappedName) => void
    onSortChange: (metric: string) => void
  }

const _AnalyticsBreakdown = <P extends string>({ onRowClick, onSortChange, data, tabs, columns, sorting, onTabClick, currentTab, ...props }: AnalyticsBreakdownProps<P>) => {
  return (
    <Widget {...props} icon={'fire'} title={'Breakdown'} subTitle={'Click on a line to filter'}>
      <WidgetContent>
        <Tabs tabs={tabs} onClick={onTabClick} />
        {!props.isLoading && <AnalyticsBreakdownLimitMessage count={data.length} name={tabs[currentTab].label} />}
        <TabContentWrapper>
          <MultipleDetailedDataTable
            columns={columns}
            rows={data}
            sorting={sorting}
            onRowClick={({ row }) => onRowClick(row[0].name)}
            onSortChange={(option) => onSortChange(option.value as string)}
          />
        </TabContentWrapper>
      </WidgetContent>
    </Widget>
  )
}
export const AnalyticsBreakdown = styled(_AnalyticsBreakdown)``
