import { css } from '@emotion/react'

const ScrollbarBaseStyle = css`
  & {
    /* Webkit based browser */
    ::-webkit-scrollbar {
      background: transparent;
      &:hover {
        background: #f5f5f5;
      }
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: transparent;
    }

    :hover::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #aaa;
    }

    @supports not selector(::-webkit-scrollbar) {
      /* Other browser implementing css scrollbar module*/
      scrollbar-color: transparent transparent;
      scrollbar-width: thin;
      :hover {
        scrollbar-color: #aaa #f5f5f5;
      }
    }
  }
`
export const ScrollbarWidth = '5px'
export const ScrollbarStyle = css`
  ${ScrollbarBaseStyle};
  & {
    /* Webkit based browser */
    ::-webkit-scrollbar {
      width: ${ScrollbarWidth};
    }
  }
`
export const ScrollbarHorizontalStyle = css`
  ${ScrollbarBaseStyle};
  & {
    /* Webkit based browser */
    ::-webkit-scrollbar {
      height: 5px;
    }
  }
`
