import styled from '@emotion/styled'
import { NO_SPACE_OR_SPECIAL_CHAR_EXCEPT_HYPHEN_UNDERSCORE } from '@pubstack/common/src/input'
import { FunctionComponent } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { _Input as BaseInput, Input } from '~/components/Input'
import { WithClassName } from '~/types/utils'

const Form = styled.form`
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;

  & ${BaseInput} {
    min-width: 326px;
    margin-bottom: 20px;
  }
`

const MappingTitle = styled.span`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`

const MappingLabel = styled.span`
  ${Fonts.H2};
  display: inline-flex;
  align-items: center;
  gap: 4px;
`

const MappingContentCard = styled(ContentCard)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

type PureAdStackIntegrationAliasFormProps = WithClassName & { isLoading: boolean; onSubmitAlias: (value: string) => void; onClose: () => void }
const _PureAdStackIntegrationAliasForm: FunctionComponent<PureAdStackIntegrationAliasFormProps> = ({ className, isLoading, onSubmitAlias, onClose }) => {
  const defaultValues = {
    adapterName: '',
  }
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<{ adapterName: string }>({
    defaultValues,
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<{ adapterName: string }> = (formValue) => {
    onSubmitAlias(formValue.adapterName)
    reset()
  }

  return (
    <MappingContentCard className={className} color={Colors.Ming}>
      <MappingTitle>
        <MappingLabel>Create new alias</MappingLabel>

        <Button variant={'tertiary'} iconName={'close'} onClick={onClose} />
      </MappingTitle>

      <Form>
        <Input
          error={errors.adapterName?.message}
          label={'Adapter name'}
          control={control}
          name={'adapterName'}
          helper={"This name can't be edited later."}
          rules={{ required: true, pattern: { value: NO_SPACE_OR_SPECIAL_CHAR_EXCEPT_HYPHEN_UNDERSCORE, message: 'No space or special character except - and _' } }}
        />
        <Button disabled={!isValid || isLoading} onClick={handleSubmit(onSubmit)}>
          Validate
        </Button>
      </Form>
    </MappingContentCard>
  )
}

export const PureAdStackIntegrationAliasForm = styled(_PureAdStackIntegrationAliasForm)``
