import { AllMetrics } from '@pubstack/common/src/analytics/query'
import { NonNullable } from '@pubstack/common/src/assertion'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { ChartWrapperOptions, GoogleChartWrapperChartType, ReactGoogleChartProps } from 'react-google-charts'
import { Colors } from '~/assets/style/colors'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'
import { LegendProps } from '~/components/Legend'
import { TimelineMetricProps } from '~/components/TimelineMetric'
import { ANALYTICS_CHART_COLOR_CONFIG, getChartTooltipDateFormat } from '~/utils/analytics'
import { AnalyticsChartWidgetMessage } from './AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions, convertChartDateTime } from './AnalyticsCharts'
import { Formula } from './formulas/operation'

type DataPointConfig<T extends Partial<AllMetrics>> = Omit<LegendProps, 'className' | 'label' | 'value' | 'tooltip'> & {
  name: string | 'comparisonLine'
  getFormula: GetFormula<T>
  label?: string
  getTooltipLabel?: (args: TooltipArgs<T>) => string
  isComputable?: (args: BuildArgs<T>) => boolean
  legendTooltip?: ReactNode
  withValue?: boolean
}
type BuildArgs<T extends Partial<AllMetrics>> = {
  data: T
  comparisonData?: T
  currencySymbol: CurrencySymbol
}
type GetFormula<T extends Partial<AllMetrics>> = (args: BuildArgs<T>) => Formula<any> | undefined
export type TimelineConfiguration<T extends Partial<AllMetrics>> = {
  getChartOptions?: (args: BuildArgs<T> & { legendsConfig: string[] }) => Partial<ChartWrapperOptions['options']>
  legendConfig: string[]
  tooltipConfig: string[] | string[][]
  dataConfig: DataPointConfig<T>[]
}
export type MetricConfiguration<T extends Partial<AllMetrics>> = {
  metric: {
    name: string
    tooltipText?: string | ReactElement
    message?: (args: BuildArgs<T>) => AnalyticsChartWidgetMessage | undefined
  }
  dataConfig: DataPointConfig<T>[]
}

const isMultiLevel = <T,>(array: T[] | T[][]): array is T[][] => array && array.length > 0 && array[0] instanceof Array
const mapConfig = <T extends Partial<AllMetrics>>(config: TimelineConfiguration<T>, names: string[]): DataPointConfig<T>[] =>
  names.map((l) => config.dataConfig.find((d) => d.name === l)).filter(NonNullable)

function getConfig<T extends Partial<AllMetrics>, N extends string[] | string[][]>(config: TimelineConfiguration<T>, names: N): N extends string[][] ? DataPointConfig<T>[][] : DataPointConfig<T>[]
function getConfig<T extends Partial<AllMetrics>>(config: TimelineConfiguration<T>, names: string[] | string[][]): DataPointConfig<T>[] | DataPointConfig<T>[][] {
  return isMultiLevel(names) ? names.map((l) => mapConfig(config, l)) : mapConfig(config, names)
}

const isComputable =
  <T extends Partial<AllMetrics>>(args: BuildArgs<T>) =>
  (config: DataPointConfig<T>): boolean =>
    config.isComputable ? config.isComputable(args) : true

export const useMetrics = <T extends Partial<AllMetrics>, C extends MetricConfiguration<T>>({
  config,
  data,
  comparisonData,
  currencySymbol,
  onMetricChange,
}: {
  config: C[]
  data: T
  comparisonData?: T
  currencySymbol: CurrencySymbol
  onMetricChange: (metric: string) => void
}) => {
  const [current, setCurrent] = useState<number>(0)
  return useMemo(() => {
    const metrics = config.map<TimelineMetricProps>(({ metric, dataConfig }, index) => {
      const metricDataPoint = dataConfig.flat().find((d) => d.name === metric.name)
      if (!metricDataPoint) {
        throw Error(`missing metric data point configuration for ${metric.name}`)
      }
      const f = metricDataPoint.getFormula({ data, currencySymbol })
      const rawValue = f && f.isComputable(data) ? f.sum(data) : 0
      return {
        active: current === index,
        label: metricDataPoint.label ?? ANALYTICS_CHART_COLOR_CONFIG[metric.name]?.title ?? '',
        value: f ? f.displayable(rawValue, currencySymbol).toString() : undefined,
        notApplicable: !f,
        onClick: () => {
          onMetricChange(metric.name)
          f && setCurrent(index)
        },
        tooltipText: metric.tooltipText,
      }
    })

    const currentConfig = config[current]
    const metricMessage = currentConfig.metric.message ? currentConfig.metric.message({ data, comparisonData, currencySymbol }) : undefined

    return {
      metrics,
      currentMetric: metrics[current],
      currentConfig,
      metricMessage,
    }
  }, [current, config, data, comparisonData, currencySymbol, onMetricChange])
}

export const useTimelineChart = <T extends Partial<AllMetrics> & { epoch: string[] }>({
  currentConfig,
  data,
  comparisonData,
  currencySymbol,
  currentEpoch,
  timezone,
  chartType,
}: {
  currentConfig: TimelineConfiguration<T>
  data: T
  comparisonData?: T
  currencySymbol: CurrencySymbol
  currentEpoch: DateTime
  timezone: Timezone
  chartType?: GoogleChartWrapperChartType
}) => {
  const legends = useLegends(currentConfig, { data, comparisonData, currencySymbol })
  const colors = useMemo(() => legends.map((l) => l.iconColor), [legends])
  const convertData = useGetChartTimelineData(currentConfig, timezone)
  const chartOptions = useChartOptions({
    currentConfig,
    colors,
    data,
    currencySymbol,
    comparisonData,
  })

  let chartData: unknown[] | undefined
  if (data.epoch.length === 0) {
    chartData = undefined
  } else {
    chartData = convertData(data, comparisonData, currentEpoch, currencySymbol)
  }

  return {
    chart: useMemo((): ReactGoogleChartProps => ({ chartType: chartType ?? 'ComboChart', options: chartOptions, data: chartData }), [chartOptions, chartData]),
    legends,
  }
}

const useGetChartTimelineData = <T extends Partial<AllMetrics> & { epoch: string[] }>(currentConfig: TimelineConfiguration<T>, timezone: Timezone) => {
  return useCallback(
    (data: T, comparisonData: T | undefined, currentEpoch: DateTime, currencySymbol: CurrencySymbol) => {
      const dataConfig = getConfig(currentConfig, currentConfig.legendConfig).filter(isComputable<T>({ data, comparisonData, currencySymbol }))
      const tooltipConfig = getConfig(currentConfig, currentConfig.tooltipConfig)
      const dateFormat = getChartTooltipDateFormat(data.epoch)
      const chartDataValues: (string | Date | number)[][] = data.epoch.map((epoch, index) => [
        convertChartDateTime(epoch, timezone),
        getChartTooltip({
          epoch: DateTime.fromISO(epoch, { zone: timezone }),
          tooltipConfig,
          data,
          comparisonData,
          index,
          currentEpoch,
          dateFormat,
          currencySymbol,
        }),
        ...dataConfig.map((d) => {
          const usedData = d.name === 'comparisonLine' ? comparisonData ?? ({} as T) : data
          const formula = d.getFormula({ data, comparisonData, currencySymbol })
          const isComputable =
            !!formula &&
            (d.isComputable ?? (() => true))({
              data,
              comparisonData,
              currencySymbol,
            }) &&
            formula.isComputable(usedData ?? {})
          return isComputable ? formula.compute(usedData, index) : 0
        }),
      ])

      return [[{ type: 'datetime' }, { type: 'string', role: 'tooltip', p: { html: true } }, ...dataConfig.map((l) => l.name)], ...chartDataValues]
    },
    [currentConfig]
  )
}

const useLegends = <T extends Partial<AllMetrics>>(currentConfig: TimelineConfiguration<T>, args: BuildArgs<T>) => {
  return useMemo(
    () =>
      getConfig(currentConfig, currentConfig.legendConfig)
        .filter(isComputable<T>(args))
        .map((l) => {
          const formula = l.getFormula(args)
          return {
            ...l,
            iconColor: l.iconColor ?? ANALYTICS_CHART_COLOR_CONFIG[l.name]?.color ?? Colors.Jet,
            label: l.label ?? ANALYTICS_CHART_COLOR_CONFIG[l.name]?.title ?? '',
            value: l.withValue && formula ? formula.displayable(formula.sum(args.data), args.currencySymbol) : undefined,
            tooltip: l.legendTooltip,
          }
        }),
    [currentConfig, args]
  )
}

const useChartOptions = <T extends Partial<AllMetrics>>({
  currentConfig,
  colors,
  data,
  comparisonData,
  currencySymbol,
}: {
  currentConfig: TimelineConfiguration<T>
  colors: string[]
  data: T
  comparisonData?: T
  currencySymbol: CurrencySymbol
}) => {
  return useMemo(() => {
    const { getChartOptions, legendConfig } = currentConfig
    return {
      ...AnalyticsDefaultChartOptions,
      colors,
      ...(getChartOptions
        ? getChartOptions({
            data,
            comparisonData,
            currencySymbol,
            legendsConfig: getConfig(currentConfig, legendConfig)
              .filter(isComputable<T>({ data, comparisonData, currencySymbol }))
              .map((a) => a.name),
          })
        : {}),
    }
  }, [currentConfig, colors, data, comparisonData, currencySymbol])
}

type TooltipArgs<T extends Partial<AllMetrics>> = {
  epoch: DateTime
  tooltipConfig: DataPointConfig<T>[] | DataPointConfig<T>[][]
  data: T
  comparisonData: T | undefined
  index: number
  currentEpoch: DateTime
  dateFormat: string
  currencySymbol: CurrencySymbol
}
const getChartTooltip = <T extends Partial<AllMetrics> & { epoch: string[] }>(args: TooltipArgs<T>) => {
  const { epoch, tooltipConfig, data, comparisonData, index, currentEpoch, currencySymbol, dateFormat } = args
  if (epoch > currentEpoch) {
    return '<div style="display: none"></div>'
  }
  const dataConfig = (isMultiLevel(tooltipConfig) ? tooltipConfig : [tooltipConfig]).map((arr) => arr.filter(isComputable(args))).filter((arr) => arr.length)

  const metrics: ChartTooltipMetricProps[][] = dataConfig.map((group) => {
    return group
      .map((c) => {
        const usedData = c.name === 'comparisonLine' ? comparisonData ?? ({} as T) : data
        const formula = c.getFormula({ data, comparisonData, currencySymbol })
        if (!formula) {
          return undefined
        }
        const computed = formula.compute(usedData, index)
        return {
          label: c.getTooltipLabel ? c.getTooltipLabel(args) : c.label ?? ANALYTICS_CHART_COLOR_CONFIG[c.name]?.title,
          iconName: c.iconName ?? 'square',
          iconColor: c.iconColor ?? ANALYTICS_CHART_COLOR_CONFIG[c.name]?.color ?? Colors.Jet,
          value: formula.displayable(computed, currencySymbol),
          secondary: c.name === 'comparisonLine',
        }
      })
      .filter(NonNullable)
  })
  const tooltipContent = <ChartTooltip date={epoch.toFormat(dateFormat)} metrics={metrics} />
  return ReactDOMServer.renderToString(tooltipContent)
}
