import { RefreshGroup } from '@pubstack/common/src/refresh'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRefreshGroups } from '~/api/api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { PureSettingsRefreshGroupsPage } from './PureSettingsRefreshGroupsPage'

export const SettingsRefreshGroupsPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const [refreshGroups, setRefreshGroups] = useState<RefreshGroup[]>([])

  const { all, byId, check } = useRefreshGroups(null)

  const toast = useToast()

  useEffect(() => {
    loadRefreshGroups()
  }, [])

  const loadRefreshGroups = async () => {
    setRefreshGroups(await all.get())
  }

  const toDelete = async (refreshGroup: RefreshGroup) => {
    try {
      await byId.del(refreshGroup.id)
      setRefreshGroups(refreshGroups.filter((r) => r.id !== refreshGroup.id))
      toast.success(
        <>
          <strong>{refreshGroup.name}</strong> has been deleted.
        </>
      )
    } catch (e) {
      toast(`Something went wrong when trying to delete ${refreshGroup.name}`)
    }
  }

  const toggleStatus = async (refreshGroup: RefreshGroup) => {
    const replacer = (replacement: RefreshGroup) => refreshGroups.map((r) => (r.id === refreshGroup.id ? replacement : r))

    const updated = { ...refreshGroup, enabled: !refreshGroup.enabled }
    setRefreshGroups(replacer(updated))
    try {
      const overlaps = await check(updated)
      if (overlaps) {
        toast.alert(
          <>
            Cannot activate <strong>{updated.name}</strong> because there are conflicts with other groups : {overlaps.conflicts.groups.map((a) => a.name).join(', ')}
          </>
        )
        setRefreshGroups(replacer(refreshGroup))
      } else {
        await byId.put(updated)
        toast.success(
          <>
            <strong>{refreshGroup.name}</strong> has been {updated.enabled ? 'enabled' : 'disabled'}.
          </>
        )
      }
    } catch (e) {
      toast.alert(`Something went wrong when trying to change ${refreshGroup.name}'s status`)
      setRefreshGroups(replacer(refreshGroup))
    }
  }

  const onEdit = async (refreshGroup?: RefreshGroup) => {
    if (refreshGroup) {
      if (refreshGroup.id) {
        navigate(`edit/${refreshGroup?.id}`)
      } else {
        const clone = await byId.post({ ...refreshGroup, name: `Clone of ${refreshGroup.name}`, enabled: false })
        navigate(`edit/${clone.id}`)
      }
    }
  }

  return (
    <PureSettingsRefreshGroupsPage
      refreshGroups={refreshGroups}
      isLoading={byId.loading || all.loading}
      isError={!!all.error}
      onDeleteRefreshGroup={toDelete}
      onCreateRefreshGroup={() => navigate('new')}
      onEditRefreshGroup={onEdit}
      onCloneRefreshGroup={(refreshGroup) => onEdit({ ...refreshGroup, id: '' })}
      onToggleRefreshGroupStatus={toggleStatus}
    />
  )
}
