import { GroupOverlap, RefreshGroup } from '@pubstack/common/src/refresh'
import { FunctionComponent, useState } from 'react'
import { toggleValueInArray } from '~/utils/array'
import { RefreshGroupComboSearch } from './RefreshGroupComboSearch'

export type RefreshGroupAdUnit = RefreshGroup['adUnits'][number]

export const RefreshGroupAdUnitsForm: FunctionComponent<{
  adUnitList: RefreshGroupAdUnit[]
  refreshGroupAdUnits: RefreshGroupAdUnit[]
  updateRefreshGroup: (AdUnits: RefreshGroupAdUnit[]) => unknown
  overlap: GroupOverlap
}> = ({ adUnitList, refreshGroupAdUnits, updateRefreshGroup, overlap }) => {
  const [adUnitFilter, setAdUnitFilter] = useState('')

  // building checkboxes: we check ad units that are in refresh group's ones, then filter them according to the search value
  const options = adUnitList
    .map((s) => ({ label: s.name, value: s.id, checked: refreshGroupAdUnits.some((rs) => rs.id === s.id) }))
    .filter((s) => s.label.toLowerCase().includes(adUnitFilter.toLowerCase()))

  const onToggle = (adUnit: RefreshGroupAdUnit) => {
    // toggling the AdUnit
    const newAdUnits = toggleValueInArray(refreshGroupAdUnits, adUnit, 'id')

    // dispatch the new value
    updateRefreshGroup(newAdUnits)
  }

  const onSelectAll = () => {
    if (options.every((o) => o.checked)) {
      updateRefreshGroup(refreshGroupAdUnits.filter((a) => !options.find((o) => o.value === a.id)))
    } else {
      updateRefreshGroup([...refreshGroupAdUnits, ...adUnitList.filter((s) => s.name.toLowerCase().includes(adUnitFilter.toLowerCase()) && !refreshGroupAdUnits.find((ra) => ra.id === s.id))])
    }
  }

  return (
    <RefreshGroupComboSearch
      overlapIds={overlap.adUnits}
      onAllCheckboxToggle={onSelectAll}
      onCheckboxToggle={(index) => onToggle({ name: options[index].label, id: options[index].value })}
      options={options}
      onSearch={(value) => setAdUnitFilter(value)}
    />
  )
}
