import styled from '@emotion/styled'
import { PropsWithChildren, cloneElement } from 'react'
import { ScrollbarStyle } from '~/assets/style/utils'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { WithClassName } from '~/types/utils'
import { DataTableCell } from './DataTableCell'
import { DataTableHeader } from './DataTableHeader'
import { DataTableBody, DataTableRow } from './DataTableRow'
import { DataTableColumnData, DataTableSorting } from './DataTableTypes'

type DataTableProps<T> = WithClassName & {
  columns: DataTableColumnData<T>[]
  sorting?: DataTableSorting
  onSortChange?: (option: SelectOptionProp) => void
}

const Wrapper = styled.div`
  display: flex;
  max-height: 100%;
  overflow: auto;
  ${ScrollbarStyle};
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const _DataTable = <T,>({ className, columns, sorting, children, onSortChange }: PropsWithChildren<DataTableProps<T>>) => (
  <Wrapper>
    <Table className={className}>
      <DataTableHeader columns={columns} sorting={sorting} onSortChange={onSortChange} />
      {children}
    </Table>
  </Wrapper>
)
export const DataTable = styled(_DataTable)`` as typeof _DataTable

type BaseDataTableProps<T> = DataTableProps<T> & {
  rows: T[]
  onRowClick?: (config: { row: T; index: number }) => void
}

/**
 * Basic DataTable abstraction allowing for rendering data on single line rows (with gauges, details or text only)
 */
const _BaseDataTable = <T,>({ rows, columns, onRowClick, ...props }: BaseDataTableProps<T>) => {
  return (
    <DataTable {...props} columns={columns}>
      {rows.map((row, rowIndex) => (
        <DataTableBody key={rowIndex} onClick={() => onRowClick && onRowClick({ row, index: rowIndex })}>
          <DataTableRow>
            {columns.map((col, colIndex) =>
              col.render ? cloneElement(col.render({ index: rowIndex, rows, col, key: colIndex }), { key: colIndex }) : <DataTableCell key={colIndex}>{(row as any)[col.key]}</DataTableCell>
            )}
          </DataTableRow>
        </DataTableBody>
      ))}
    </DataTable>
  )
}

export const BaseDataTable = styled(_BaseDataTable)`` as typeof _BaseDataTable

type MultipleDetailedDataTableProps<T> = DataTableProps<T> & {
  rows: T[][]
  onRowClick?: (config: { row: T[]; index: number }) => void
}
/**
 * Double Detailed DataTable abstraction allowing for rendering data on multiple lines for each row
 */
const _MultipleDetailedDataTable = <T,>({ rows, columns, onRowClick, ...props }: MultipleDetailedDataTableProps<T>) => (
  <DataTable {...props} columns={columns}>
    {rows.map((row, rowIndex) => (
      <DataTableBody key={rowIndex} onClick={() => onRowClick && onRowClick({ row, index: rowIndex })}>
        {row.map((detail, detailIndex) => (
          <DataTableRow key={`${rowIndex}-${detailIndex}`}>
            {columns.map((col, colIndex) =>
              col.render ? (
                col.render({ index: detailIndex, rows: row, col, key: colIndex, secondary: detailIndex > 0 })
              ) : (
                <DataTableCell secondary={detailIndex > 0} key={colIndex}>
                  {(detail as any)[col.key]}
                </DataTableCell>
              )
            )}
          </DataTableRow>
        ))}
      </DataTableBody>
    ))}
  </DataTable>
)
export const MultipleDetailedDataTable = styled(_MultipleDetailedDataTable)`` as typeof _MultipleDetailedDataTable
