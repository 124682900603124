import styled from '@emotion/styled'
import { type AdUnit, getAdUnitMediatypes } from '@pubstack/common/src/adunit'
import type { DisplayedBidder, LazyLoadingRule } from '@pubstack/common/src/stack'
import type { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon } from '~/components/Icon'
import { Qualifier } from '~/components/Qualifier'
import type { WithClassName } from '~/types/utils'

const RichDetailsWrapper = styled.div`
  overflow: hidden;
  background: ${Colors.White};
  ${Fonts.colors.Jet}
  margin: -10px;
  border-radius: 4px;
  padding: 10px;
  max-width: 480px;
  box-shadow: 0 3px 10px rgba(54, 54, 54, 0.3);
  ${Fonts.P3}
  h3 {
    ${Fonts.P3}
    color: ${Colors.Jet};
    font-weight: 700;
  }
  h2 {
    ${Fonts.P3}
    color: ${Colors.Warning};
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  strong {
    font-weight: 500;
  }
`
const Qualifiers = styled.div`
  padding-top: 6px;
`

const Divider = styled.div`
  margin: 8px 0;
  height: 1px;
  max-height: 1px;
  min-height: 1px;
  flex: 1;
  background-color: ${Colors.Platinum};
`

const Archived = styled.div`
display:flex;
`

type RichDetailsProps = WithClassName & {
  name?: string
  adUnit?: AdUnit
  bidder?: DisplayedBidder
  conditions?: ReactNode
  differences?: string[]
  lazyLoadingRule?: LazyLoadingRule
  isDisabled?: boolean
}

const _RichDetails: FunctionComponent<RichDetailsProps> = ({ className, name, adUnit, bidder, conditions, differences, lazyLoadingRule, isDisabled }) => {
  return (
    <RichDetailsWrapper>
      {name && !adUnit && !bidder && <span>{name}</span>}
      {adUnit && (
        <>
          <div>
            <h3>name in ad server:</h3> {adUnit.adServerAdUnitName}
          </div>
          <div>
            <h3>div ID:</h3> {adUnit.divId}
          </div>
          <Qualifiers>
            <Qualifier
              iconName={'header_bidding'}
              tooltipText={adUnit.headerBiddingEnabled ? 'Header bidding enabled' : 'Header bidding disabled'}
              color={Colors.Success}
              active={adUnit.headerBiddingEnabled && !isDisabled}
              enabled={adUnit.enabled}
            />
            <Qualifier
              iconName={'dynamic'}
              tooltipText={adUnit.dynamicEnabled ? 'Dynamic enabled' : 'Dynamic disabled'}
              color={Colors.Success}
              active={adUnit.dynamicEnabled && !isDisabled}
              enabled={adUnit.enabled}
            />
            <Qualifier
              iconName={'banner'}
              tooltipText={getAdUnitMediatypes(adUnit).includes('Banner') ? 'Banner enabled' : 'Banner disabled'}
              color={Colors.Success}
              active={getAdUnitMediatypes(adUnit).includes('Banner') && !isDisabled}
              enabled={adUnit.enabled}
            />
            <Qualifier
              iconName={'outstream2'}
              tooltipText={getAdUnitMediatypes(adUnit).includes('Outstream') ? 'Outstream enabled' : 'Outstream disabled'}
              color={Colors.Success}
              active={getAdUnitMediatypes(adUnit).includes('Outstream') && !isDisabled}
              enabled={adUnit.enabled}
            />
          </Qualifiers>
        </>
      )}
      {bidder && (
        <>
          <h3>Mapped with :</h3>
          {bidder.desktop && bidder.desktop.length > 0 && (
            <div>
              <strong>Desktop:</strong>
              {bidder.desktop.join(`\xa0\xa0|\xa0\xa0`)}
            </div>
          )}
          {bidder.mobile && bidder.mobile.length > 0 && (
            <div>
              <strong>Mobile:</strong>
              {bidder.mobile.join(`\xa0\xa0|\xa0\xa0`)}
            </div>
          )}
        </>
      )}
      {conditions && (
        <>
          <h3>Conditions:</h3> {conditions}
        </>
      )}
      {lazyLoadingRule && (
        <>
          <div>
            <h3>Lazy loading on HB:</h3> {lazyLoadingRule.headerBiddingEnabled ? 'Yes' : 'No'}
          </div>
          <div>
            <h3>Lazy loading on GAM:</h3> {lazyLoadingRule.gamEnabled ? 'Yes' : 'No'}
          </div>
          <div>
            <h3>Auction start:</h3> {lazyLoadingRule.headerBiddingEnabled ? `${lazyLoadingRule.prebidAuctionViewportsPercent} viewports` : 'not lazy loaded'}
          </div>
          <div>
            <h3>GAM request:</h3> {lazyLoadingRule.gamEnabled ? `${lazyLoadingRule.googletagFetchViewportsPercent} viewports` : 'not lazy loaded'}
          </div>
          <div>
            <h3>Render impression:</h3> {lazyLoadingRule.gamEnabled ? `${lazyLoadingRule.googletagRenderViewportsPercent} viewports` : 'not lazy loaded'}
          </div>
        </>
      )}

      {differences && (
        <>
          <Divider />
          <h2>
            <Icon width={'18px'} name={'sync_problem'} fill={Colors.Warning} />
            Out of sync
          </h2>
          {isDisabled ? (
            <Archived>
              <Icon width={'18px'} name={'warning'} fill={Colors.Alert} />
              <span>Archived</span>
            </Archived>
          ) : (
            differences.map((e) => <div key={e}>{e}</div>)
          )}
        </>
      )}
    </RichDetailsWrapper>
  )
}

export const RichDetails = styled(_RichDetails)``
