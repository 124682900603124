import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useIntegration } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { currentScopeState } from '~/state'
import { SettingsAccessLabel } from '~/utils/settings'
import { PureSettingsHomePage } from './PureSettingsHomePage'

export const SettingsHomePage = () => {
  const currentScope = useRecoilValue(currentScopeState)
  const navigate = useNavigate()
  const { byId } = useIntegration(null) // TODO 2022-11-28 TMU NRA manage loading, abort & error
  const [isAdxActive, setIsAdxActive] = useState(false)
  const user = useUser()

  const getIsAdxActive = async () => {
    const res = await byId.get()
    setIsAdxActive(res.gamNetworks?.some((network) => network.dataIngestionStatus === 'ACTIVE') ?? false)
  }

  const navigateOnClick = (label: SettingsAccessLabel) => {
    const path = label
      .split(/\B(?=[A-Z])/)
      .map((w: string) => w.toLowerCase())
      .join('-')
    navigate(path)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      getIsAdxActive()
    }) // TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
    return () => clearTimeout(timeout)
  }, [])

  return (
    <PureSettingsHomePage
      isOwner={!!(user?.getScopeRole() === 'owner' || user?.isAdmin)}
      onAccessCardClick={navigateOnClick}
      displayRefreshAccess={!!user?.hasFeature('refresh')}
      displayAbTest={user ? user.hasFeature('abtest') : false}
      integrations={{ isAdxActive: isAdxActive, isPrebidActive: !!currentScope.pbjs, prebidVariable: currentScope.pbjs }}
    />
  )
}
