import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Color, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'

const TitleBar = styled.div`
  display: flex;
  padding-bottom: 40px;
  width: 100%;
  justify-content: space-between;
  h1 {
    ${Fonts.H1}
  }
  ${Link} {
    margin-bottom: 12px;
  }
`

const AdStackEditContentCard = styled(ContentCard)`
  width: 1000px;
`

const AdStackEditPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const BottomBar = styled.div`
  border-top: 1px solid ${Colors.Platinum};
`

const Title = styled.div`
  ${Fonts.H1}
  padding-bottom: 20px;
  font-weight: regular;
`

export type PureAdStackEditPageProps = WithClassName & {
  children: ReactNode | ReactNode[]
  onBackClick: () => void
  breadcrumbs: ReactNode
  color: Color
  title: ReactNode
}

const _PureAdStackEditPage: FunctionComponent<PureAdStackEditPageProps> = ({ children, onBackClick, breadcrumbs, color, title }) => {
  return (
    <AdStackEditPageWrapper>
      <TitleBar>
        {breadcrumbs}
        <Button variant={'tertiary'} onClick={onBackClick}>
          Back
        </Button>
      </TitleBar>
      <AdStackEditContentCard color={color}>
        <Title>{title}</Title>
        <BottomBar />
        {children}
      </AdStackEditContentCard>
    </AdStackEditPageWrapper>
  )
}

export const PureAdStackEditPage = styled(_PureAdStackEditPage)``
