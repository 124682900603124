import styled from '@emotion/styled'
import { AbTestConfiguration } from '@pubstack/common/src/abTest'
import { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Modal } from '~/components/Modal'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { EmptyTable, handleTableSearchAndSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'
import { SUPPORT_MAIL } from '~/utils/constants'
import { PureActivateAbTestModal } from './PureActivateAbTestModal'

const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > * {
    display: inline-flex;
    flex-direction: column;
  }
`

const DeleteAbTestModal: FunctionComponent<{
  abTest: AbTestConfiguration
  onDelete: () => void
}> = ({ abTest, onDelete }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Remove A/B test configuration</Modal.Title>

      <Modal.Body>
        <Paragraphs>
          <p>
            <span>
              You are about to delete A/B test <strong>{abTest.name}</strong>
            </span>
            {abTest.active && <span>This test is currently running.</span>}
          </p>
          <p>Do you confirm your choice ?</p>
        </Paragraphs>
      </Modal.Body>

      <Modal.Actions>
        <Button onClick={close} variant={'tertiary'}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onDelete()
            close()
          }}
          variant={'negative'}
        >
          Remove A/B test
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const SettingsAbTestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const SettingsAbTestHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const ActionsTableCell = styled(TableCell)`
  width: 0; /** necessary to get the last cell to shrink down to hug action buttons */

  > div {
    display: flex;
    gap: 16px;
  }
`

const EmptyAbTestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;

  ${Icon} {
    margin-bottom: 28px;
  }

  div {
    margin-bottom: 38px;
  }

  ${Fonts.P1}
  ${Fonts.colors.Jet}
`

type PureSettingsAbTestPageProps = WithClassName & {
  abTests: AbTestConfiguration[]
  isLoading: boolean
  isError: boolean
  onDeleteAbTest: (abTest: AbTestConfiguration) => void
  onViewAbTest: (abTest?: AbTestConfiguration) => void
  onCreateAbTest: () => void
  onToggleAbTestStatus: (abTest: AbTestConfiguration) => void
  canEnableAbTest: boolean
}
const _PureSettingsAbTestPage: FunctionComponent<PureSettingsAbTestPageProps> = ({
  className,
  abTests,
  isLoading,
  isError,
  onViewAbTest,
  onCreateAbTest,
  onDeleteAbTest,
  onToggleAbTestStatus,
  canEnableAbTest,
}) => {
  const modal = useGlobalModal()
  const AbTestCols: TableColumns<AbTestConfiguration> = [
    { name: 'A/B test name', attributeSort: 'name', isSortable: true, order: 'ascending' },
    { name: 'Variants', isSortable: false },
    { name: 'Status', isSortable: false },
    { name: 'Actions', isSortable: false },
  ]

  const activeCount = abTests.filter((v) => v.active).length
  const [columns, setColumns] = useState<TableColumns<AbTestConfiguration>>(AbTestCols)

  const { control, watch } = useForm({
    defaultValues: {
      search: '',
    },
  })

  const AbTestSearch = watch('search')

  const sortedAbTest = handleTableSearchAndSort(columns, abTests, AbTestSearch, ['name'])

  const toggle = (abTest: AbTestConfiguration) => (
    <Toggle
      onClick={(event) => {
        event.stopPropagation()
        if (!abTest.active && activeCount > 0) {
          modal.open(PureActivateAbTestModal, { abTest, onToggle: () => onToggleAbTestStatus(abTest) })
        } else {
          onToggleAbTestStatus(abTest)
        }
      }}
      value={abTest.active}
      disabled={!canEnableAbTest}
    />
  )

  return abTests.length ? (
    <SettingsAbTestWrapper className={className}>
      <SettingsAbTestHeader>
        <Input control={control} name={'search'} label={'Search'} labelIsPlaceholder iconLeft={'search'} />
        <Button onClick={onCreateAbTest}>Set A/B test</Button>
      </SettingsAbTestHeader>
      <Table isLoading={isLoading} columns={columns} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {sortedAbTest.map((abTest, index) => (
          <TableRow
            key={index}
            onClick={() => {
              onViewAbTest(abTest)
            }}
          >
            <TableCell>{abTest.name}</TableCell>
            <TableCell>{`${abTest.variants.map((value) => value.variant).join(', ')}`}</TableCell>
            <TableCell>{canEnableAbTest ? <>{toggle(abTest)}</> : <Tooltip title={'Only owners can enable a test'}>{toggle(abTest)}</Tooltip>}</TableCell>
            <ActionsTableCell>
              <div>
                <Tooltip title={'View'}>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation()
                      onViewAbTest(abTest)
                    }}
                    variant={'tertiary'}
                    iconName={'view'}
                  />
                </Tooltip>
                {(!abTest.active || canEnableAbTest) && (
                  <Tooltip title={'Remove'}>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation()
                        modal.open(DeleteAbTestModal, { abTest, onDelete: () => onDeleteAbTest(abTest) })
                      }}
                      variant={'tertiary'}
                      iconName={'delete'}
                    />
                  </Tooltip>
                )}
              </div>
            </ActionsTableCell>
          </TableRow>
        ))}
      </Table>
    </SettingsAbTestWrapper>
  ) : isError ? (
    <Table isLoading={isLoading} columns={columns} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
      <TableRow>
        <TableCell colspan={columns.length}>
          <EmptyTable>
            <div>
              <h2>An error occurred when trying to get A/B test configurations</h2>
              <p>
                Please refresh the page or contact <Link label={SUPPORT_MAIL} href={`mailto:${SUPPORT_MAIL}`} /> if the issue persists.
              </p>
            </div>
          </EmptyTable>
        </TableCell>
      </TableRow>
    </Table>
  ) : (
    <EmptyAbTestWrapper>
      <Icon name={'ab_test_void'} width={'190px'} />
      <div>No A/B test tracked here.</div>
      <Button onClick={onCreateAbTest}>Set A/B test</Button>
    </EmptyAbTestWrapper>
  )
}

export const PureSettingsAbTestPage = styled(_PureSettingsAbTestPage)``
