import { Site } from '@pubstack/common/src/tag'
import { useEffect, useState } from 'react'
import { useSites } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { useToast } from '~/components/Toast/useToasts'
import { useLogger } from '~/utils/logger'
import { PureSettingsSitesPage } from './PureSettingsSitesPage'

export const SettingsSitesPage = () => {
  const logger = useLogger()
  const user = useUser()
  const currentRole = (user?.userRoles && user.getScopeRole()) ?? ''
  const canEditSites = !['demo'].includes(currentRole)
  const canArchiveSites = !['user', 'demo'].includes(currentRole)

  const { all, byId } = useSites(null)
  const [sites, setSites] = useState<Site[]>([])
  const toast = useToast()

  const getSites = async () => {
    if (all.loading) {
      all.abort()
    }
    try {
      const sites = await all.get({ getDisabledSites: true })
      setSites(sites)
    } catch (e) {
      toast.alert('An error occurred while trying to fetch your inventory.')
    }
  }

  const onAddSites = async (newSites: Site[]) => {
    if (newSites.length) {
      try {
        const res = await all.post(newSites)
        logger.info({ action: 'click', type: 'site-settings', detail: 'add-sites', siteName: null })
        setSites([...sites, ...res])
        if (newSites.length === 1) {
          toast.success(<strong>{res[0].name} sucessfully added to your inventory.</strong>)
        } else {
          toast.success(
            <strong>
              {`${res
                .map((s) => s.name)
                .join(', ')
                .substring(0, 20)}…`}{' '}
              sucessfully added to your inventory.
            </strong>
          )
        }
      } catch (e) {
        toast.alert('An error occurred while trying to update your inventory.')
      }
    }
  }

  const onArchiveSite = async (toArchive: Site) => {
    try {
      await byId.del(toArchive)
      setSites(sites.map((s) => (s.id === toArchive.id ? { ...toArchive, enabled: false } : s)))
      toast.success(
        <>
          <strong>{toArchive.name}</strong> was successfuly archived.
        </>
      )
    } catch (e) {
      toast.alert(
        <>
          An error occurred while trying to archive <strong>{toArchive.name}</strong>.
        </>
      )
    }
  }

  const onEditSite = async (toEdit: Site) => {
    try {
      const res = await byId.put(toEdit)
      setSites(sites.map((s) => (s.id === toEdit.id ? res : s)))
      toast.success(
        <>
          <strong>{res.name}</strong> was successfully updated.
        </>
      )
    } catch (e) {
      toast.alert(
        <>
          An error occurred while trying to edit <strong>{toEdit.name}</strong>.
        </>
      )
    }
  }

  const onUnarchiveSite = async (toUnarchive: Site) => {
    try {
      const res = await byId.put(toUnarchive)
      setSites(sites.map((s) => (s.id === toUnarchive.id ? res : s)))
      toast.success(
        <>
          <strong>{res.name}</strong> was successfully restored.
        </>
      )
    } catch (e) {
      toast.alert(
        <>
          An error occurred while trying to restore <strong>{toUnarchive.name}</strong>.
        </>
      )
    }
  }

  const onCopyToClipboard = (label: string) => {
    toast.success(
      <>
        <strong>{label}</strong> was successfully copied to your clipboard.
      </>
    )
  }

  const onSearch = async (search: string) => {
    if (all.loading) {
      all.abort()
    }
    try {
      const sites = await all.get({ getDisabledSites: true, search })
      setSites(sites)
    } catch (e) {
      toast.alert('An error occurred while trying to fetch your inventory.')
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getSites()
    })
  }, [])

  return (
    <PureSettingsSitesPage
      sites={sites}
      isLoading={all.loading || byId.loading}
      isError={false}
      canEditSites={canEditSites}
      canArchiveSites={canArchiveSites}
      onAddSite={onAddSites}
      onArchiveSite={onArchiveSite}
      onUnarchiveSite={onUnarchiveSite}
      onEditSite={onEditSite}
      onCopyToClipBoard={onCopyToClipboard}
      onSearch={onSearch}
    />
  )
}
