import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { Scope } from '@pubstack/common/src/scope'
import { ChangeEvent, FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { useUserMetadata } from '~/api/api.hook'
import { Colors } from '~/assets/style/colors'
import { ScrollbarStyle } from '~/assets/style/utils'
import { useUser } from '~/auth/user.hooks'
import { _Input as BaseInput } from '~/components/Input'
import { contextState, currentScopeState, loadingState } from '~/state'
import { useLogger } from '~/utils/logger'

const ScopesPopoverContentSearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`
const ScopesPopoverContentList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: auto;
  ${ScrollbarStyle}
`
const ScopesPopoverContentListItem = styled.div`
  display: block;
  padding: 8px 12px;
  user-select: none;
  cursor: pointer;
  &:hover {
    background: ${Colors.Topaze};
  }
`
const ScopesPopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ScopesPopoverContentNoResultWrapper = styled.div`
  padding: 16px;
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
`

const ScopesPopoverContentNoResult: FC<PropsWithChildren<{ currentFilterValue: string }>> = ({ currentFilterValue }) => {
  return (
    <ScopesPopoverContentNoResultWrapper>
      <h5>No results</h5>
      <p>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        We found no results for "{currentFilterValue}". {/*TODO 2022-04-01 NRA CFO : delete the rule ? &quot; not pretty...*/}
        <br />
        Check the spelling or use another word.
      </p>
    </ScopesPopoverContentNoResultWrapper>
  )
}

const ScopesPopoverContent: FC<PropsWithChildren<{ scopes: Scope[] }>> = ({ scopes }) => {
  const logger = useLogger()
  const user = useUser()
  const currentScope = useRecoilValue(currentScopeState)
  const [, setLoading] = useRecoilState(loadingState)
  const { byId: userMetaDataById } = useUserMetadata(null)
  const { getAccessTokenSilently } = useAuth0()
  const [displayedScopes, setDisplayedScopes] = useState([...scopes])
  const [inputScope, setInputScope] = useState('')
  const resetContext = useResetRecoilState(contextState)
  const filterScopes = (event: ChangeEvent<HTMLInputElement>) => {
    setInputScope(event.target.value)
    setDisplayedScopes(scopes.filter((item) => item.name.toLowerCase().includes(event.target.value.toLowerCase())))
  }
  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputElement.current?.focus()
  })

  const swap = async (scope: Scope) => {
    logger.info({ action: 'click', type: 'switch-scope', toScope: scope.name })
    await userMetaDataById.put({
      scopeId: scope.id,
      alertEmail: user?.alertEmail ?? { [currentScope.id]: false },
    })

    const loading = getAccessTokenSilently({ cacheMode: 'off' })
    setLoading(true)
    await loading
    resetContext()
    window.location.reload()
  }

  const ScopesItems = displayedScopes.map((scope, index) => (
    <ScopesPopoverContentListItem onClick={() => swap(scope)} key={index}>
      {scope.name}
    </ScopesPopoverContentListItem>
  ))

  return (
    <ScopesPopoverContentWrapper>
      <ScopesPopoverContentSearchBar>
        <BaseInput iconLeft={'search'} type={'text'} labelIsPlaceholder label={'Search'} onChange={filterScopes} ref={inputElement} />
      </ScopesPopoverContentSearchBar>
      {displayedScopes.length ? <ScopesPopoverContentList>{ScopesItems}</ScopesPopoverContentList> : <ScopesPopoverContentNoResult currentFilterValue={inputScope} />}
    </ScopesPopoverContentWrapper>
  )
}

export default ScopesPopoverContent
