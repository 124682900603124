import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Scope } from '@pubstack/common/src/scope'
import { FC } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { Icon } from '~/components/Icon'
import { WithClassName } from '~/types/utils'

const ScopesButtonContent = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-areas: 'scope icon';
  align-items: center;
`
const ScopeName = styled.span`
  grid-area: scope;
  ${Fonts.colors.Hurricane};
  ${Fonts.P1};
`
const ScopeIcon = styled(Icon)`
  grid-area: icon;
  justify-self: start;
  margin-left: 2px;
`

const Wrapper = styled.div<{ hasMultiScope: boolean }>`
  padding: ${Sizes[4]} ${Sizes[8]};
  border-radius: 4px;
  ${Fonts.colors.Hurricane}

  ${({ hasMultiScope }) =>
    hasMultiScope &&
    css`
      cursor: pointer;

      :hover {
        background-color: ${Colors.Platinum};
      }
    `}
`

type ScopesButtonProps = WithClassName & {
  hasMultiScope: boolean
  currentScope: Scope
}
const _ScopesButton: FC<ScopesButtonProps> = ({ hasMultiScope, currentScope, className }) => {
  return (
    <Wrapper className={className} hasMultiScope={hasMultiScope}>
      <ScopesButtonContent>
        <ScopeName>{currentScope.name}</ScopeName>
        {hasMultiScope && <ScopeIcon name={'chevron_down'} width={'24px'} />}
      </ScopesButtonContent>
    </Wrapper>
  )
}
export const ScopesButton = styled(_ScopesButton)``
