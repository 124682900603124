import styled from '@emotion/styled'
import { ChangeEventHandler, FunctionComponent, useRef } from 'react'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & input {
    display: none;
  }

  align-items: center;

  background-color: ${Colors.Ghost};
  padding: 20px;
`

const ActionsInfo = styled.div`
  display: inline-flex;
  flex-direction: column;
`
const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const MappingActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
`

type MappingTransferProps = WithClassName & {
  isUploading: boolean
  isTemplateDownloading: boolean
  isMappingDownloading: boolean
  onDownload: (options?: { fullMapping?: boolean }) => void
  onUpload: (file: File) => void
  isMapped?: boolean
}
const _MappingTransfer: FunctionComponent<MappingTransferProps> = ({ className, isMapped, isUploading, isTemplateDownloading, isMappingDownloading, onDownload, onUpload }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      onUpload(event.target.files[0])
      event.target.value = ''
    }
  }

  return (
    <Wrapper className={className}>
      <input onChange={handleFileChange} ref={inputRef} type={'file'} accept={'text/csv'} />
      <MappingActions>
        <ActionsInfo>
          <span>Download your mapping to edit it for every site.</span>
          <span>Please do not remove any line or column.</span>
        </ActionsInfo>
        <ActionButtons>
          {isMapped && (
            <Button disabled={isMappingDownloading} iconSpin={isMappingDownloading} iconName={isMappingDownloading ? 'pubstack' : 'download'} onClick={() => onDownload({ fullMapping: true })}>
              {isMappingDownloading ? 'Downloading' : 'Download current mapping'}
            </Button>
          )}
          <Button
            disabled={isTemplateDownloading}
            iconSpin={isTemplateDownloading}
            iconName={isTemplateDownloading ? 'pubstack' : 'download'}
            variant={isMapped ? 'secondary' : 'primary'}
            onClick={() => onDownload()}
          >
            {isTemplateDownloading ? 'Downloading' : 'Download template'}
          </Button>
          <Button disabled={isUploading} iconSpin={isUploading} iconName={isUploading ? 'pubstack' : 'upload'} variant={'secondary'} onClick={handleUploadClick}>
            {isUploading ? 'Uploading' : 'Upload new mapping'}
          </Button>
        </ActionButtons>
      </MappingActions>
    </Wrapper>
  )
}

export const MappingTransfer = styled(_MappingTransfer)``
