import { css } from '@emotion/react'
import { Colors } from './colors'
import { Sizes } from './tokens'

export const Fonts = {
  H0: css`
    font-family: 'Rubik', sans-serif;
    font-size: ${Sizes[26]};
    line-height: 36px;
  `,
  H1: css`
    font-family: 'Rubik', sans-serif;
    font-size: ${Sizes[20]};
    line-height: 30px;
  `,
  H2: css`
    font-family: 'Rubik', sans-serif;
    font-size: ${Sizes[16]};
    line-height: 26px;
  `,
  H3: css`
    font-family: 'Rubik', sans-serif;
    font-size: ${Sizes[14]};
    line-height: 22px;
  `,
  P0: css`
    font-family: 'Roboto', sans-serif;
    font-size: ${Sizes[18]};
    line-height: 30px;
  `,
  P1: css`
    font-family: 'Roboto', sans-serif;
    font-size: ${Sizes[16]};
    line-height: 26px;
  `,
  P2: css`
    font-family: 'Roboto', sans-serif;
    font-size: ${Sizes[14]};
    line-height: 22px;
  `,
  P3: css`
    font-family: 'Roboto', sans-serif;
    font-size: ${Sizes[12]};
    line-height: 20px;
  `,
  Mono: css`
    font-family: 'Inconsolata', monospace;
    font-size: ${Sizes[16]};
    line-height: 26px;
  `,
  MonoSmall: css`
    font-family: 'Inconsolata', monospace;
    font-size: ${Sizes[14]};
    line-height: 22px;
  `,
  colors: {
    White: css`
      color: ${Colors.White};
    `,
    Jet: css`
      color: ${Colors.Jet};
    `,
    Hurricane: css`
      color: ${Colors.Hurricane};
    `,
    SlateGrey: css`
      color: ${Colors.SlateGrey};
    `,
    King: css`
      color: ${Colors.King};
    `,
    Alert: css`
      color: ${Colors.Alert};
    `,
    Warning: css`
      color: ${Colors.Warning};
    `,
    Cobalt: css`
      color: ${Colors.Cobalt};
    `,
    Navy: css`
      color: ${Colors.Navy};
    `,
    Topaze: css`
      color: ${Colors.Topaze};
    `,
    Silver: css`
      color: ${Colors.Silver};
    `,
    Ash: css`
      color: ${Colors.Ash};
    `,
  },
} as const
