import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Tag } from '~/components/Tag'
import { WithClassName } from '~/types/utils'

const Card = styled.div<{ onClick?: () => void; locked?: boolean }>`
  background-color: ${Colors.White};
  padding: 12px;
  border-radius: 4px;
  display: grid;
  grid-row-gap: 4px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  ${({ locked }) =>
    locked
      ? css`
          grid-template-columns: 1fr min-content;
          grid-template-rows: min-content min-content;
          grid-template-areas:
            'title locked'
            'requirements locked';
        `
      : css`
          grid-template-columns: 1fr min-content;
          grid-template-rows: min-content min-content;
          grid-template-areas:
            'title status'
            'description description';
        `};
`

const Title = styled.div`
  grid-area: title;
  display: flex;
  gap: 2px;
  align-items: center;
  margin-top: 2px;
`

const Name = styled.div<{ locked?: boolean }>`
  ${Fonts.colors.Jet};
  ${Fonts.P1};
  font-weight: bold;

  ${({ locked }) =>
    locked &&
    css`
      color: ${Colors.Ash};
      &:hover {
        color: ${Colors.Ash};
      }
    `}
`

const Description = styled.div`
  grid-area: description;
  ${Fonts.colors.Jet};
  ${Fonts.P2};
  margin-bottom: 4px;
`
const Requirements = styled.div`
  grid-area: requirements;
  ${Fonts.colors.Navy};
  ${Fonts.P2};
  font-weight: bold;
  margin-bottom: 4px;
`
const Status = styled.div`
  grid-area: status;
  display: flex;
  align-items: center;
`

const Locked = styled.div`
  display: flex;
  align-items: center;
  grid-area: locked;
`

export type SiteOnboardingStepCardProps = WithClassName & {
  name: string
  description: string
  onClick?: () => void
  optional?: boolean
  done?: boolean
  locked?: boolean
  requirements?: string[]
}
const _SiteOnboardingStepCard: FunctionComponent<SiteOnboardingStepCardProps> = ({ className, name, description, done, optional, onClick, locked, requirements }) => (
  <Card className={className} onClick={onClick && !locked ? () => onClick() : undefined} locked={locked}>
    <Title>
      <Name locked={locked}>{name}</Name>
      <Button variant={'tertiary'} iconName={'chevronRightCircle'} iconSize={'18px'} disabled={locked} />
    </Title>
    {locked ? <Requirements>Required: {(requirements || []).join(', ')}</Requirements> : <Description>{description}</Description>}
    {(done || optional) && (
      <Status>
        {done && <Icon name={'check'} fill={Colors.Success} />}
        {optional && <Tag color={Colors.Ash}>Optional</Tag>}
      </Status>
    )}
    {locked && (
      <Locked>
        <Icon name={'lock'} width={'46px'} />
      </Locked>
    )}
  </Card>
)
export const SiteOnboardingStepCard = styled(_SiteOnboardingStepCard)``
