import { BIDDER_PARAM_CSV_HEADERS, BIDDER_PARAM_PROPERTIES, BidParam, BidderCatalogAlias, BidderParam, isBidderParamProperty } from '@pubstack/common/src/bidderCatalog'
import { validateExpectedBidderParamType } from '@pubstack/common/src/bidderParamValidation'
import { SpreadsheetSiteValidation, SpreadsheetValidationEntry } from '~/modules/adstack/inventory/bidderParams/PureAdStackIntegrationSpreadsheet'

export const onBidderParamsUpdate = (alias: BidderCatalogAlias, bidderParams: BidderParam[]): [BidderCatalogAlias, File] => {
  //TODO tmu sle 2023-03-05 CHANGE THIS - we should not be using the same pipeline as files here, it was just a shortcut
  const res: string[] = []
  const headers = [...BIDDER_PARAM_CSV_HEADERS, ...alias.bidParams.map((param) => param.name)]
  res.push(headers.join(','))
  res.push(
    ...bidderParams.flatMap((bp) =>
      headers
        .flatMap((headerName) => {
          if (isBidderParamProperty(headerName)) {
            return bp[BIDDER_PARAM_PROPERTIES[headerName]]
          } else {
            return bp.params[headerName]?.toString()
          }
        })
        .join(',')
    )
  )
  const csv = res.join('\n')
  const updatedAlias: BidderCatalogAlias = { ...alias, bidderParams, isUsed: bidderParams.some((bp) => Object.keys(bp.params).length > 0) }
  //TODO tmu sle 2023-03-05 CHANGE THIS - we should not be using the same pipeline as files here, it was just a shortcut
  return [updatedAlias, new File([csv], `${alias.bidderLabel}_mapping.csv`, { type: 'text/csv' })]
}

/**
 * Generate validation errors from bidder params values
 * @returns a validation array aggregated by site name containing validation error entries for each bidder param
 */
export const validateBidderParams = (values: BidderParam[], currentBidder: { bidParams: BidParam[] } | undefined): SpreadsheetSiteValidation[] => {
  const requiredParams = currentBidder?.bidParams.filter((bidParam) => bidParam.required) ?? []
  const optionalParams = currentBidder?.bidParams.filter((bidParam) => !bidParam.required) ?? []

  const notEmptyBidderParams = values.map((bidderParam, i) => ({ bidderParam, i })).filter(({ bidderParam }) => Object.values(bidderParam.params).filter((value) => !!value).length > 0)

  return (
    notEmptyBidderParams
      .flatMap(({ bidderParam, i }) => {
        const nonEmptyOptionalParams = optionalParams.filter((p) => !!bidderParam.params[p.name])
        return [...requiredParams, ...nonEmptyOptionalParams].map((param) => {
          const error = {
            device: bidderParam.device,
            adUnitName: bidderParam.adUnitName,
            siteName: bidderParam.siteName,
            bidderName: bidderParam.bidderName,
            bidderParamIndex: i,
            param,
          }

          const validation = validateExpectedBidderParamType(bidderParam.params[param.name] as string, param)
          if (validation) {
            return { ...error, errorType: validation.type }
          }
          return null
        })
      })
      .filter((v): v is SpreadsheetValidationEntry & { siteName: string } => !!v)
      // aggregate by siteName
      .reduce((siteValidation, validationEntry) => {
        if (!siteValidation.find((siteValidation) => siteValidation.siteName === validationEntry.siteName)) {
          return [...siteValidation, { siteName: validationEntry.siteName, entries: [validationEntry] }]
        } else {
          return siteValidation.map((siteValidation) =>
            siteValidation.siteName === validationEntry.siteName ? { ...siteValidation, entries: [...siteValidation.entries, validationEntry] } : siteValidation
          )
        }
      }, [] as SpreadsheetSiteValidation[])
  )
}
