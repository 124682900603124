import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { MappedName, ViewabilityMetricsByDim } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useCallback } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsBreakdown, useAnalyticsBreakdown } from '~/modules/analytics/AnalyticsBreakdown'
import { allAuctionFillRate, allAverageViewedTime, allECPM, allImpressionRevenue, allViewabilityMRC, bidCpm, bidRate, hbBidDensity, hbImpressionRevenue, hbWinRate } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { TabConfig } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

type PureViewabilityBreakdownProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    rawData: ViewabilityMetricsByDim
    onTabChange: (dimension: Dimension) => void
    currencySymbol: CurrencySymbol
    onRowClick: (name: MappedName) => void
    defaultTabIndex?: number
    onSortChange: (metric: string) => void
    showMinorData?: boolean
    tabsConfig: TabConfig[]
  }
const _PureViewabilityBreakdown: FunctionComponent<PureViewabilityBreakdownProps> = ({ currencySymbol, rawData, onTabChange, tabsConfig, defaultTabIndex, showMinorData, ...props }) => {
  const breakdownProps = useAnalyticsBreakdown<string>({
    currencySymbol,
    rawData,
    onTabChange,
    tabsConfig,
    defaultTabIndex,
    getConfigs: useCallback(
      ({ dimension }) => [
        [
          {
            displayedAs: 'gauge',
            propertyName: 'name',
            getRawValue: ({ data }) => data.viewabilityMRC.rawValue ?? 0,
            max: 100,
            status: {
              alert: 30,
              warning: 60,
              defaultValue: 'success',
            },
            sortLabel: 'Name',
          },
          {
            formula: allViewabilityMRC,
            propertyName: 'viewabilityMRC',
            status: {
              alert: 30,
              warning: 60,
            },
            tooltip: ANALYTICS_TOOLTIPS.VIEWABILITY,
          },
          {
            formula: allAverageViewedTime,
            propertyName: 'averageViewedTime',
            tooltip: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
          },
          {
            formula: dimension === 'stackIdVersion' ? hbImpressionRevenue : allImpressionRevenue,
            propertyName: 'revenue',
            tooltip: dimension === 'stackIdVersion' ? ANALYTICS_TOOLTIPS.PREBID_REVENUE : ANALYTICS_TOOLTIPS.REVENUE,
          },
          {
            formula: bidRate,
            propertyName: 'bidRate',
            tooltip: ANALYTICS_TOOLTIPS.BID_RATE,
            isComputable: ({ mappedName }) => mappedName.value !== 'adx',
          },
          {
            formula: bidCpm,
            propertyName: 'bidCpm',
            tooltip: ANALYTICS_TOOLTIPS.BID_CPM,
            isComputable: ({ mappedName }) => mappedName.value !== 'adx',
          },
          {
            formula: hbWinRate,
            propertyName: 'hbWinRate',
            tooltip: ANALYTICS_TOOLTIPS.PREBID_WIN_RATE,
            isComputable: ({ mappedName }) => mappedName.value !== 'adx',
          },
          {
            formula: allAuctionFillRate,
            propertyName: 'auctionFillRate',
            tooltip: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
            status: {
              alert: 5,
              warning: 15,
            },
          },
          {
            formula: allECPM,
            propertyName: 'eCPM',
            tooltip: ANALYTICS_TOOLTIPS.ECPM,
          },
          {
            formula: hbBidDensity,
            propertyName: 'bidDensity',
            tooltip: ANALYTICS_TOOLTIPS.PREBID_DENSITY,
          },
        ],
      ],
      []
    ),
    getColumnsConfigs: useCallback(
      ({ dimension }) => ['viewabilityMRC', 'averageViewedTime', 'revenue', ...(dimension === 'bidder' ? ['bidRate', 'bidCpm', 'hbWinRate'] : ['auctionFillRate', 'eCPM', 'bidDensity'])],
      []
    ),
    filterData: useCallback(
      (_: unknown, index: number) => {
        if (showMinorData) {
          return true
        }

        const sum = (a: number | null, b: number | null) => (a ?? 0) + (b ?? 0)
        const measurableImpressionCount = rawData?.measurableImpressionCount
        const adxMeasurableImpressionCount = rawData?.adxMeasurableImpressionCount
        const sumMeasurableImpressionCount = (measurableImpressionCount?.reduce(sum, 0) ?? 0) + (adxMeasurableImpressionCount?.reduce(sum, 0) ?? 0)
        return ((measurableImpressionCount?.[index] ?? 0) + (adxMeasurableImpressionCount?.[index] ?? 0)) / (sumMeasurableImpressionCount ?? 1) > 0.01
      },
      [rawData, showMinorData]
    ),
  })

  return <AnalyticsBreakdown {...props} {...breakdownProps} />
}
export const PureViewabilityBreakdown = styled(_PureViewabilityBreakdown)``
