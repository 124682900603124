import { cx } from '@emotion/css'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { Color, ColorUtil, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevel } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import { WithClassName } from '~/types/utils'
import Button from './Button'
import { usePortal } from './layout/usePortal'

type WidthPx = `${number}px`
const flyoutEase = 'cubic-bezier(0.695, 0.0485, 0.34, 1)'

const flyoutIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  75% {
    opacity: 1;
    transform: translateX(0);
  }
`

const flyoutOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`

const flyoutFadeIn = keyframes`
  from {
    background-color: transparent;
  }
  to {
    background-color: ${ColorUtil.hexOpacity(Colors.Jet, 0.1)};
  }
`

const flyoutFadeOut = keyframes`
  from {
    background-color: ${ColorUtil.hexOpacity(Colors.Jet, 0.1)};
  }
  to {
    background-color: transparent;
  }
`

const FlyoutRoot = styled.div<{ isOpen: boolean; rootX: number; rootY: number }>`
  display: flex;
  justify-content: flex-end;
  ${({ rootX, rootY }) => css`
    height: calc(100% - ${rootY}px);
    width: calc(100% - ${rootX}px);
    left: ${rootX}px;
    top: ${rootY}px;
  `}

  position: fixed;
  overflow-x: hidden;
  overflow-y: visible;

  ${ElevationLevel.middle}

  &.flyout-open {
    animation: 0.15s ${flyoutEase} ${flyoutFadeIn} normal forwards;
    > * {
      animation: 0.25s ${flyoutEase} ${flyoutIn} normal forwards;
    }
  }
  &.flyout-close {
    animation: 0.1s ${flyoutEase} ${flyoutFadeOut} normal forwards;
    > * {
      animation: 0.2s ${flyoutEase} ${flyoutOut} normal forwards;
    }
  }
`

const FlyoutWrapper = styled.div<{ color?: Color; width?: WidthPx }>`
  background-color: ${Colors.White};
  height: 100%;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  position: relative;
  gap: 20px;
  box-shadow: 0 0 20px rgba(54, 54, 54, 0.5);
  padding-left: 6px;

  ::before {
    display: block;
    position: absolute;
    left: 0;

    content: '';
    width: 6px;
    height: 100%;
    background: ${({ color }) => (color ? color : `linear-gradient(180deg, ${Colors.King} 0%, ${Colors.Pool} 100%)`)};
  }

  & > div {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const FlyoutContent = styled.div`
  ${ScrollbarStyle};
  flex: 1;
  overflow-y: auto;
  padding-top: 4px;
`

const FlyoutFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const FlyoutActions = styled.div``
const FlyoutHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 4px;
`

const FlyoutTitle = styled.div`
  ${Fonts.H1}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const FLYOUT_PORTAL_ID = 'flyout-portal'

type FlyoutProps = WithClassName & { title: ReactNode; color?: Color; actions?: ReactNode; isOpen: boolean; onClose: () => unknown; width?: WidthPx }
const _Flyout: FunctionComponent<PropsWithChildren<FlyoutProps>> = ({ className, color, children, isOpen, onClose, width = '600px', title, actions }) => {
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen)
  useEffect(() => {
    if (isOpen !== isOpenInternal) {
      if (isOpenInternal) {
        const timeout = setTimeout(() => setIsOpenInternal(isOpen), 200)
        return () => clearTimeout(timeout)
      } else {
        setIsOpenInternal(isOpen)
      }
    }
  }, [isOpen])

  const portal = usePortal(FLYOUT_PORTAL_ID)

  return portal(
    (portalTarget) =>
      isOpenInternal && (
        <FlyoutRoot
          rootX={portalTarget.getBoundingClientRect().x ?? 0}
          rootY={portalTarget.getBoundingClientRect().y ?? 0}
          onClick={onClose}
          isOpen={isOpenInternal}
          className={cx([className, isOpen ? 'flyout-open' : 'flyout-close'])}
        >
          <FlyoutWrapper onClick={(e) => e.stopPropagation()} color={color} width={width}>
            <FlyoutHeader>
              <FlyoutTitle>{title}</FlyoutTitle>
              <Button variant={'tertiary'} onClick={onClose} iconName={'close'} />
            </FlyoutHeader>
            <FlyoutContent>{children}</FlyoutContent>
            <FlyoutFooter>
              <Button variant={'tertiary'} onClick={onClose}>
                Close
              </Button>
              <FlyoutActions>{actions}</FlyoutActions>
            </FlyoutFooter>
          </FlyoutWrapper>
        </FlyoutRoot>
      )
  )
}

export const Flyout = styled(_Flyout)``
