import { ADM_QA_REPORTS_TIME_RANGES, AdmQAReportCategory, AdmQaReport, AdmQaReportsTimeRange } from '@pubstack/common/src/adm-qa-reports'
import { AdminTag } from '@pubstack/common/src/tag'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAdmQAReports, useAdminTags } from '~/api/admin-api.hook'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { useToast } from '~/components/Toast/useToasts'
import { downloadCSVdata } from '~/utils/csv'
import { PureAdmQaReportsPage } from './PureAdmQaReportsPage'
import { ADM_QA_REPORTS_TIME_RANGE_LABELS } from './admQaReports'

const ADM_QA_REPORTS_TIME_RANGES_OPTIONS: SelectOptionProp[] = (Object.keys(ADM_QA_REPORTS_TIME_RANGES) as AdmQaReportsTimeRange[]).map((timeRange) => ({
  value: timeRange,
  label: ADM_QA_REPORTS_TIME_RANGE_LABELS[timeRange],
}))

export type AdmQaReportsLoading = Pick<ReturnType<typeof useAdmQAReports>, 'loading'>['loading']

const AdmQAReportsPage = () => {
  const [sites, setSites] = useState<AdminTag[]>([])
  const [reportList, setReportList] = useState<AdmQaReport[]>([])

  const { scopeId } = useParams()
  const { all: allAdminTags } = useAdminTags(null, scopeId ?? '')
  const { abort, loading, reports } = useAdmQAReports(null, scopeId ?? '')

  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    loadSites()
    loadReports()
  }, [scopeId])

  const loadSites = async () => {
    const s = await allAdminTags.get()
    setSites(s)
  }

  const loadReports = async () => {
    const r = await reports.all()
    setReportList(r)
  }

  const createReport = async (siteIdA: string, siteIdB: string, deployDate: string, range: AdmQaReportsTimeRange) => {
    try {
      if (loading.reportLoading) {
        abort.reportAbort()
      }
      reports.new(siteIdA, siteIdB, deployDate, range)
      toast.success('Report creation in progress, you should refresh in a minute.')
    } catch (e) {
      toast.alert('Report creation failed')
    }
  }

  const deleteReport = async (report: AdmQaReport) => {
    try {
      if (loading.reportLoading) {
        abort.reportAbort()
      }
      await reports.delete(report.id)
      setReportList((r) => r.filter((id) => id.id !== report.id))
    } catch (e) {
      toast.alert('Report deletion failed')
    }
  }

  const downloadReport = async (report: AdmQaReport, category: AdmQAReportCategory) => {
    try {
      if (loading.reportDataLoading) {
        abort.reportDataAbort()
      }
      const data = await reports.dataById(report.id, category)
      const siteA = sites.find((s) => s.id === report.siteIdA)?.name ?? report.siteIdA
      const siteB = sites.find((s) => s.id === report.siteIdA)?.name ?? report.siteIdB
      const liveDate = new Date(report.admLiveDate).toLocaleDateString()
      const range = report.range
      downloadCSVdata(data, `${category}_${siteA}_vs_${siteB}_${liveDate}_${range}`)
    } catch (e) {
      toast.alert('Report download failed')
    }
  }

  const navigateToAnalysis = (report: AdmQaReport) => {
    navigate(`analysis/${report.id}`)
  }

  return (
    <PureAdmQaReportsPage
      createReport={createReport}
      deleteReport={deleteReport}
      downloadReport={downloadReport}
      timeRanges={ADM_QA_REPORTS_TIME_RANGES_OPTIONS}
      loading={loading}
      reports={reportList}
      sites={sites}
      onReportClick={navigateToAnalysis}
    />
  )
}

export default AdmQAReportsPage
