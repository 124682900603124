import { CurrencySymbol } from '@pubstack/common/src/currency'

export const removeTraillingSlash = (str: string) => (str.endsWith('/') ? str.slice(0, -1) : str)

/** allowing narrow no break space so the currencysymbol doesn't break */
// eslint-disable-next-line no-irregular-whitespace
export const displayWithCurrency = (value?: string | number, currencySymbol?: CurrencySymbol) => `${value ?? ''}${currencySymbol ? ` ${currencySymbol}` : ''}`.trim()

export function copyToClipboard(document: Document, value: string): Promise<void> {
  return new Promise((resolve, reject) => {
    // Create an temporary input in absolute to store copy the value
    const tempInput = document.createElement('textarea')
    tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px'
    tempInput.value = value
    document.body.appendChild(tempInput)
    tempInput.select()
    try {
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      resolve()
      throw { message: 'boom' }
    } catch (e) {
      document.body.removeChild(tempInput)
      reject(e)
    }
  })
}

/**
 * fast hashing function
 * Warning : high collision rate, do not use in sensitive operations
 * @param s any string
 * @returns a 32 bit long number
 *
 * ref: Taken from Google Analytics' old domain name hashing function
 */
export const fastHash = (s: string) => {
  const largePrime = 0x9e3779b1
  let hashBase = 0xcafebabe
  for (let i = 0; i < s.length; i++) hashBase = Math.imul(hashBase ^ s.charCodeAt(i), largePrime)
  return (hashBase ^ (hashBase >>> 16)) >>> 0
}

export const isNumber = (value: string | null): boolean => !!value && !isNaN(Number(value))

export const formatJSON = (value: string, indentationSpaceCount: number = 2): string => {
  try {
    return JSON.stringify(JSON.parse(value), null, indentationSpaceCount)
  } catch (e) {
    return value
  }
}
