import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ForwardedRef, forwardRef } from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'

const statesConfig = {
  true: {
    left: {
      width: 2.17571,
      height: 6.11829,
      transform: 'rotate(135 5.46143 10.56143) translate(5.46143 10.56143)',
    },
    right: {
      width: 2.17571,
      height: 11.9664,
      transform: 'rotate(45 10.56143 2.5) translate(10.56143 2)',
    },
  },
  false: {
    left: {
      width: 2.17571,
      height: 11.9664,
      transform: 'rotate(135 11 9.46143) translate(11 9.46143)',
    },
    right: {
      width: 2.17571,
      height: 11.9664,
      transform: 'rotate(45 9.46143 1) translate(9.46143 1)',
    },
  },
}

type ToggleProps = WithClassName & Omit<ComponentProps<'input'>, 'value'> & { value?: boolean }

const ToggleIcon = ({ value }: Pick<ToggleProps, 'value'>) => {
  const config = statesConfig[`${!!value || false}`]
  return (
    <svg width={'10'} height={'10'} viewBox={'0 0 12 12'} fill={'currentColor'} xmlns={'http://www.w3.org/2000/svg'}>
      <rect x={'0'} y={'0'} width={config.left.width} height={config.left.height} rx={'1.08786'} transform={config.left.transform} />
      <rect x={'0'} y={'0'} width={config.right.width} height={config.right.height} rx={'1.08786'} transform={config.right.transform} />
    </svg>
  )
}

const Circle = styled.span`
  width: 16px;
  height: 16px;
  background-color: ${Colors.White};
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: ${Transitions.quick};
`
const ToggleCircle = ({ value }: Pick<ToggleProps, 'value'>) => (
  <Circle>
    <ToggleIcon value={value} />
  </Circle>
)

const ToggleWrapper = styled.span<ToggleProps>`
  width: 44px;
  height: 24px;
  display: inline-flex;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 4px;
  color: ${Colors.SlateGrey};
  cursor: pointer;
  transition: ${Transitions.quick};
  user-select: none;

  ${(props) =>
    props.value
      ? css`
          padding-left: 24px;
          background-color: ${Colors.Success};
          color: ${Colors.Success};
        `
      : css`
          padding-left: 4px;
          background-color: ${Colors.Hurricane};
          color: ${Colors.Hurricane};
        `};

  &:hover {
    ${(props) =>
      props.value
        ? css`
            background-color: ${Colors.Forest};
            color: ${Colors.Forest};
          `
        : css`
            background-color: ${Colors.SlateGrey};
            color: ${Colors.SlateGrey};
          `};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${Colors.Silver};
      color: ${Colors.Silver};
      cursor: not-allowed;

      :hover {
        background-color: ${Colors.Silver};
        color: ${Colors.Silver};
      }
    `}

  & > input {
    display: none;
    position: absolute;
  }
`

const _Toggle = ({ value, disabled, onClick, className, ...props }: ToggleProps, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <ToggleWrapper disabled={disabled} className={className} onClick={disabled ? () => null : onClick} value={value}>
      <ToggleCircle value={value} />
      <input {...props} disabled={disabled} type={'checkbox'} defaultChecked={!!value} ref={ref} />
    </ToggleWrapper>
  )
}
export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(_Toggle)

type ToggleInputProps<T extends FieldValues> = Omit<ToggleProps, 'value' | 'ref' | 'onChange'> & Omit<UseControllerProps<T>, 'defaultValue'> & { onChange?: (value: boolean) => void }
export const ToggleInput = <T extends FieldValues>({ name, control, rules, shouldUnregister, onChange, onClick, className, ...props }: ToggleInputProps<T>, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Toggle
          {...props}
          value={field.value}
          onClick={() => {
            field.onChange(!field.value)
            onChange && onChange(!field.value)
          }}
        />
      )}
    />
  )
}
