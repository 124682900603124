import styled from '@emotion/styled'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { CONTEXT_ELEMENT_OP_AS_STR, ConditionElement, StackContext } from '@pubstack/common/src/stackContext'
import { Fragment, FunctionComponent, useMemo } from 'react'
import { Control, FieldErrors, useFieldArray, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { ChipListInput } from '~/components/ChipListInput'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Tooltip } from '~/components/Tooltip'

const KeyValueForm = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`
const KeyValueWrapper = styled.section`
  display: inline-flex;
  gap: 15px;
  align-items: baseline;
  padding: 20px 0;
  & ${Button} {
    align-self: center;
  }
  width: 100%;

  ${Tooltip} {
    display: inline-flex;
    &::before {
      content: '';
      display: inline-flex;
      height: 25px;
      flex: 1;
    }
  }
`
const AddButton = styled(Button)`
  margin-top: 20px;
`
const SelectWrapper = styled.div`
  min-width: 292px;
`
const SelectWrapperOperation = styled.div`
  min-width: 160px;
  max-width: 160px;
`
const SelectWrapperWide = styled(SelectWrapper)`
  flex: 1;
`

const operationsOptions: SelectOptionProp[] = Object.entries(CONTEXT_ELEMENT_OP_AS_STR).map(([value, label]) => ({ value, label }))

export const ContextConditionsForm: FunctionComponent<{
  control: Control<StackContext>
  errors: FieldErrors<StackContext>
  contextKeys: ContextKey[]
  onKeyChange: (index: number) => void
}> = ({ control, errors, contextKeys, onKeyChange }) => {
  const selectKeyOptions: SelectOptionProp[] = contextKeys ? contextKeys.map((s) => ({ value: s.name, label: s.name })) : [{ value: '', label: '' }]
  const contextKeysByName = useMemo(() => contextKeys.reduce((acc, contextKey) => ({ ...acc, [contextKey.name]: contextKey }), {} as { [key: string]: ContextKey }), [contextKeys])

  const { fields, append, remove } = useFieldArray({ control, name: 'conditions.elements' })

  const formKeyValues = useWatch({
    control,
    name: 'conditions.elements',
  }) as ConditionElement[]

  const getSelectValueOptions = (index: number) => {
    return (contextKeysByName[(formKeyValues[index] as ConditionElement)?.key] || { values: [] }).values.map((c) => ({ label: c, value: c }))
  }

  const getSelectContextOptions = (index: number) => {
    return selectKeyOptions.map((option) => {
      const indexKv = formKeyValues.findIndex(({ key }) => key === option.value)
      return { ...option, disabled: indexKv !== index && indexKv !== -1 }
    })
  }

  return (
    <KeyValueForm>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          {index > 0 && <span>AND</span>}
          <KeyValueWrapper>
            <SelectWrapper>
              <Select
                options={getSelectContextOptions(index)}
                error={(errors?.conditions?.elements as FieldErrors<ConditionElement[]>)?.[index]?.key?.message}
                label={'Context key'}
                control={control}
                name={`conditions.elements.${index}.key`}
                rules={{
                  required: 'Key required.',
                }}
                searchable={true}
                onChange={() => onKeyChange(index)}
              />
            </SelectWrapper>
            <SelectWrapperOperation>
              <Select control={control} name={`conditions.elements.${index}.op`} label={''} options={operationsOptions} />
            </SelectWrapperOperation>
            <SelectWrapperWide>
              <ChipListInput
                chipColor={Colors.Mauve}
                isSpaceAllowed={true}
                selectableOptions={getSelectValueOptions(index)}
                label={'Values'}
                control={control}
                name={`conditions.elements.${index}.values`}
                canRemoveValues={true}
                error={(errors?.conditions?.elements as FieldErrors<ConditionElement[]>)?.[index]?.values?.message}
                rules={{
                  required: { value: !!formKeyValues[index]?.key, message: 'Value is required.' },
                }}
                disabled={getSelectValueOptions(index).length === 0}
              />
            </SelectWrapperWide>
            <Tooltip title={'Must keep at least one condition'} disabled={formKeyValues.length > 1}>
              <Button onClick={() => remove(index)} variant={'tertiary'} iconName={'delete'} disabled={formKeyValues.length === 1} />
            </Tooltip>
          </KeyValueWrapper>
        </Fragment>
      ))}
      {fields.length < 5 && (
        <AddButton variant={'secondary'} iconName={'add'} disabled={!!errors.conditions} onClick={() => append({ op: 'IN', key: '', values: [] }, { shouldFocus: false })}>
          Add condition
        </AddButton>
      )}
    </KeyValueForm>
  )
}
