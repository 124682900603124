import { AdStackSite } from '@pubstack/common/src/site'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAdStackSites } from '~/api/adm-api.hook'
import { useUser } from '~/auth/user.hooks'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureSitesPage } from './PureSitesPage'

export const SitesPage = () => {
  const navigate = useNavigate()
  const [sites, setSites] = useState<AdStackSite[]>([])
  const { all } = useAdStackSites(null)
  const user = useUser()

  useEffect(() => {
    loadSites()
  }, [])

  async function loadSites() {
    const sites = await all.get()
    setSites(sites)
  }

  const breadcrumbs = useBreadcrumbs()

  return (
    <PureSitesPage
      sites={sites}
      isLoading={all.loading}
      canEditSite={!!(user && user.userRoles && (user.getScopeRole() === 'owner' || user.getScopeRole() === 'user' || user.isAdmin))}
      onEditSite={(siteSelected) => {
        const path = !siteSelected.liveStacks && !siteSelected.readyToDeployStacks && !siteSelected.draftStacks ? 'activation' : 'stacks'
        navigate(`/adstack/sites/${siteSelected.id}/${path}`)
      }}
      onAddSite={() => navigate(`/settings/sites/`)}
      breadcrumbs={breadcrumbs}
    />
  )
}
