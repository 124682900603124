import styled from '@emotion/styled'
import { BidMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent } from 'react'
import { WidgetProps } from '~/components/Widget'
import { WithClassName } from '~/types/utils'
import { BidderCPMDataType, PureBidderWidgetCPM } from './PureBidderWidgetCPM'
import { PureBidderWidgetFunnel } from './PureBidderWidgetFunnel'
import { BidderResponsesDataType, PureBidderWidgetResponses } from './PureBidderWidgetResponses'
import { PureBidderWidgetRevenue } from './PureBidderWidgetRevenue'

const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 20px;
  }
`

const InlineLineChartContainer = styled.div`
  display: flex;

  > div {
    flex: 1;
  }

  & > * + * {
    margin-left: 20px;
  }
`

type PureBidderChartsProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon'> & {
    data: BidMetricsByTime
    currencySymbol: CurrencySymbol
    onCPMTabChange: (type: BidderCPMDataType) => void
    onResponsesTabChange: (type: BidderResponsesDataType) => void
    timezone: Timezone
    currentEpoch: DateTime
  }
const _PureBidderCharts: FunctionComponent<PureBidderChartsProps> = ({ className, onCPMTabChange, onResponsesTabChange, ...props }) => (
  <ChartsContainer className={className}>
    <PureBidderWidgetRevenue {...props} />
    <PureBidderWidgetResponses {...props} onTabChange={onResponsesTabChange} />
    <InlineLineChartContainer>
      <PureBidderWidgetCPM {...props} onTabChange={onCPMTabChange} />
      <PureBidderWidgetFunnel {...props} />
    </InlineLineChartContainer>
  </ChartsContainer>
)
export const PureBidderCharts = styled(_PureBidderCharts)``
