import styled from '@emotion/styled'
import { AdminScope } from '@pubstack/common/src/scope'
import { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { ElevationLevel } from '~/assets/style/tokens'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { TabProp, Tabs } from '~/components/Tabs'
import { WithClassName } from '~/types/utils'

const ScopeDetailWrapper = styled.div`
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`
const ScopeDetailHeader = styled.div`
  background-color: ${Colors.Ghost};
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  position: sticky;
  top: 0px;
  padding-top: 20px;
  ${ElevationLevel.middle}
`

type PureAdminScopeDetailProps = WithClassName & {
  currentScope?: AdminScope
  onTabClick: (tab: TabProp) => unknown
  onScopeChange: (option: SelectOptionProp) => unknown
  scopes?: AdminScope[]
  tabs: TabProp[]
  breadcrumbs?: React.ReactNode
}
const _PureAdminScopeDetail: FunctionComponent<PropsWithChildren<PureAdminScopeDetailProps>> = ({ className, breadcrumbs, onTabClick, scopes, currentScope, onScopeChange, tabs, children }) => {
  const selectOptions: SelectOptionProp[] = scopes ? scopes.map((s) => ({ value: s.id, label: s.name })) : [{ value: '', label: '' }]
  const { control, setValue } = useForm<FieldValues>({ defaultValues: { scope: currentScope?.id ?? '' } })

  useEffect(() => {
    setValue('scope', currentScope?.id)
  }, [currentScope])

  return (
    <ScopeDetailWrapper className={className}>
      <ScopeDetailHeader>
        {breadcrumbs}
        <Select control={control} options={selectOptions} label={'Selected scope'} name={'scope'} onChange={onScopeChange} searchable={true} />
        <Tabs tabs={tabs} onClick={onTabClick} />
      </ScopeDetailHeader>
      {children}
    </ScopeDetailWrapper>
  )
}

export const PureAdminScopeDetail = styled(_PureAdminScopeDetail)``
