import styled from '@emotion/styled'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DisplayedStack } from '@pubstack/common/src/stack'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { Skeleton } from '~/components/Skeleton'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'
import { PureStackDetails } from './stack/PureStackDetails'

type PureAdstackStackDetailsFlyoutProps = WithClassName & {
  isOpen: boolean
  isLoading: boolean
  detailedStack: DisplayedStack
  onClose: () => unknown
  canEdit: boolean
  onEditStack: (stackId: string, siteId?: string) => void
  currencySymbol: CurrencySymbol
  hasAdmRefresh: boolean
}

const _PureAdstackStackDetailsFlyout: FunctionComponent<PureAdstackStackDetailsFlyoutProps> = ({
  className,
  isOpen,
  isLoading,
  detailedStack,
  canEdit,
  onEditStack,
  onClose,
  currencySymbol,
  hasAdmRefresh,
}) => {
  return (
    <Flyout
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      title={isLoading ? <Skeleton bigger /> : `${detailedStack.name} - ${detailedStack.version ? 'v' + detailedStack.version : 'draft'} details`}
      color={Colors.King}
      width={`816px`}
      actions={
        <Tooltip title={canEdit ? 'Edit stack' : 'This stack already has 2 versions.'} positions={['left']}>
          <Button
            variant={'secondary'}
            onClick={() => {
              onEditStack(detailedStack.stackId, detailedStack.siteId)
            }}
            iconName={'edit'}
            disabled={!canEdit}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <PureStackDetails currencySymbol={currencySymbol} detailedStack={detailedStack} hasAdmRefresh={hasAdmRefresh} isLoading={isLoading} onBack={() => true} />
    </Flyout>
  )
}

export const PureAdstackStackDetailsFlyout = styled(_PureAdstackStackDetailsFlyout)``
