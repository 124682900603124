import { MarketplaceBidder } from '@pubstack/common/src/marketplaceBidder'
import { FunctionComponent, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useIntegrationMarketplace } from '~/api/api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { configurationState } from '~/state'
import { PureIntegrationPage } from './PureIntegrationPage'
export const IntegrationPage: FunctionComponent = () => {
  const toast = useToast()
  const [bidders, setBidders] = useState<MarketplaceBidder[]>([])
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)
  const { all, byId } = useIntegrationMarketplace(null)

  const getAllBidders = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setBidders(result)
  }

  useEffect(() => {
    getAllBidders()
  }, [])

  const toggleSsp = async (bidder: MarketplaceBidder) => {
    try {
      await byId.post({ ...bidder, activated: !bidder.activated })
      await getAllBidders()
      toast.success(`${bidder.name} has been ${bidder.activated ? 'desactivated' : 'activated'}.`)
    } catch (exception) {
      toast.alert('An error occurred.')
      throw exception
    }
  }

  return <PureIntegrationPage static_CDN_url={STATIC_ASSETS_CDN} bidders={bidders} loading={all.loading} onToggle={toggleSsp} />
}
