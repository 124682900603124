export function shortNumber(number: number): string {
  if (number > 1e19) {
    throw new RangeError('Input expected to be < 1e19')
  }

  if (number < -1e19) {
    throw new RangeError('Input expected to be > -1e19')
  }

  if (Math.abs(number) < 1000) {
    return '' + number
  }

  let shortNumber: string
  const sign = number < 0 ? '-' : ''
  const suffixes: { [key: string]: number } = {
    k: 6,
    M: 9,
    B: 12,
    T: 16,
  }

  number = Math.abs(number)
  const size = Math.floor(number).toString().length

  const exponent = size % 3 === 0 ? size - 3 : size - (size % 3)
  shortNumber = '' + Math.round(10 * (number / Math.pow(10, exponent))) / 10

  for (const suffix in suffixes) {
    if (exponent < suffixes[suffix]) {
      shortNumber += suffix
      break
    }
  }

  return sign + shortNumber
}
