import styled from '@emotion/styled'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent } from 'react'
import { FieldErrors, useFieldArray, useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { Input } from '~/components/Input'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'

const FlyoutActions = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 24px;
`

const AddSiteForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 26px;

  & > section {
    padding-right: 64px;
    display: inline-flex;
    gap: 20px;
    & > * {
      flex-grow: 1;
    }

    & > button {
      /** TODO nra tmu 2022-10-18 this is used instead of a aling-items: center because of alert messages moving everything */
      margin-top: 4px;
    }
  }
`

type PureSettingsSitesAddSiteFlyoutProps = WithClassName & {
  isOpen: boolean
  validateSite: (siteName: string | boolean | Omit<Site, 'id'> | Omit<Site, 'id'>[] | undefined) => boolean
  onRequestClose: () => unknown
  onAdd: (sites: Site[]) => unknown
}
export const PureSettingsSitesAddSiteFlyout: FunctionComponent<PureSettingsSitesAddSiteFlyoutProps> = ({ isOpen, validateSite, onRequestClose, onAdd }) => {
  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<{ sites: Omit<Site, 'id'>[] }>({
    defaultValues: { sites: [{ name: '' }] },
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sites',
  })

  watch((value) => {
    if (value?.sites?.[value?.sites?.length - 1]?.name) {
      append({ name: '' })
    }
  })

  const close = () => {
    reset()
    onRequestClose()
  }

  const onSubmit = (values: { sites: Omit<Site, 'id'>[] }) => {
    onAdd(values.sites.filter((s) => s.name.trim().length).map((s) => ({ name: s.name.trim(), id: '' })))
    close()
  }

  const getErrorMessage = (errors: FieldErrors<{ sites: Omit<Site, 'id'>[] }>, index: number) => {
    if (errors?.sites?.[index]?.name?.type === 'required') {
      return 'At least one site name is required'
    }
    if (errors?.sites?.[index]?.name?.type === 'pattern') {
      return 'A name cannot only be whitespaces'
    }
    if (errors?.sites?.[index]?.name?.type === 'validate') {
      return 'This name is already in use. Please choose another.'
    }
  }

  return (
    <Flyout
      color={SETTINGS_NAV_CONFIG.sites.color}
      title={'Add site'}
      isOpen={isOpen}
      onClose={close}
      actions={
        <FlyoutActions>
          {isDirty && (
            <Button iconName={'restore'} onClick={() => reset()} variant={'tertiary'}>
              Reset
            </Button>
          )}
          <Button disabled={!isValid || fields.length === 1} onClick={handleSubmit(onSubmit)}>
            Add site
          </Button>
        </FlyoutActions>
      }
    >
      <AddSiteForm>
        {fields.map((field, index) => (
          <section key={field.id}>
            <Input
              error={getErrorMessage(errors, index)}
              rules={{ required: fields.length - 1 !== index, pattern: /.*[^ ].*/, validate: validateSite }}
              control={control}
              name={`sites.${index}.name`}
              label={'Site name'}
            />
            {fields.length > 1 && (
              <Button
                disabled={fields.length - 1 === index}
                onClick={() => {
                  remove(index)
                }}
                variant={'tertiary'}
                iconName={'delete'}
              />
            )}
          </section>
        ))}
      </AddSiteForm>
    </Flyout>
  )
}
