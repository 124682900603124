import styled from '@emotion/styled'
import { BidMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { TimelineConfiguration, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import { hbImpressionRevenue, hbWinningCpmSum } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { displayWithCurrency } from '~/utils/string'

const bidderRevenueMetricConfiguration: TimelineConfiguration<BidMetricsByTime> = {
  getChartOptions: ({ currencySymbol }) => ({
    annotations: { ...AnalyticsDefaultChartOptions.annotations, style: 'point' },
    crosshair: { ...AnalyticsDefaultChartOptions.crosshair, trigger: 'both' },
    vAxis: { format: displayWithCurrency('#,###', currencySymbol), minValue: 0 },
  }),
  legendConfig: ['hbImpressionRevenue', 'hbWinningCpmSum'],
  tooltipConfig: ['hbImpressionRevenue', 'hbWinningCpmSum'],
  dataConfig: [
    {
      name: 'hbImpressionRevenue',
      isComputable: ({ data }) => hbImpressionRevenue.isComputable(data),
      getFormula: () => hbImpressionRevenue,
      iconName: 'data_line',
      legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_PREBID_REVENUE,
      withValue: true,
    },
    {
      name: 'hbWinningCpmSum',
      isComputable: ({ data }) => hbWinningCpmSum.isComputable(data),
      getFormula: () => hbWinningCpmSum,
      iconName: 'data_line',
      legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_PREBID_WIN_BIDS,
      withValue: true,
    },
  ],
}

type PureBidderWidgetRevenueProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon' | 'info'> & {
    data: BidMetricsByTime
    currencySymbol: CurrencySymbol
    currentEpoch: DateTime
    timezone: Timezone
  }
const _PureBidderWidgetRevenue: FunctionComponent<PureBidderWidgetRevenueProps> = ({ data, currencySymbol, currentEpoch, timezone, ...props }) => {
  const { chart, legends } = useTimelineChart({ currentConfig: bidderRevenueMetricConfiguration, data, currencySymbol, currentEpoch, timezone })
  return <AnalyticsChartWidget {...props} icon={'bidder'} title={'Revenue'} chart={chart} legends={legends} smallChart={true} />
}
export const PureBidderWidgetRevenue = styled(_PureBidderWidgetRevenue)``
