import { IdModuleNames, ScopeModuleIdentification, UserSyncUserId } from '@pubstack/common/src/userSync'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useIdentityModule } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { DataBySite, PureAdStackPrebidModulePage } from '~/modules/adstack/inventory/modules/PureAdStackPrebidModulePage'
import { configurationState } from '~/state'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'

export const AdStackIdentityModulePage = () => {
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)
  const [identityData, setIdentityData] = useState<ScopeModuleIdentification<IdModuleNames>>()

  const breadcrumbs = useBreadcrumbs({ integrationName: identityData?.code ?? '' })
  const toast = useToast()
  const navigate = useNavigate()

  const { integrationId } = useParams()
  const { all, byId } = useIdentityModule(null)

  const updateSiteIdentity = async <ModuleName extends IdModuleNames>(code: ModuleName, data: DataBySite<ModuleName>) => {
    try {
      if (integrationId && identityData && data.moduleData) {
        const update = { name: code, params: data.moduleData.params, storage: data.moduleData.storage } as UserSyncUserId<ModuleName>
        await byId.put(integrationId, data.siteId, update)
        toast.admSuccess(data.siteName)
        setIdentityData({
          ...identityData,
          code: code,
          userIdData: identityData.userIdData.map((d) => (d.siteId === data.siteId ? { ...data, userId: update } : d)),
        })
      }
    } catch (e) {
      toast.alert('An error has occurred, please retry later.')
    }
  }

  const loadData = async () => {
    if (integrationId) {
      const res = await all.get(integrationId)
      setIdentityData(res)
    }
  }

  useEffect(() => {
    loadData()
  }, [integrationId])

  const data = identityData
    ? identityData.userIdData.map((idData) => ({ ...idData, moduleData: idData.userId ? { params: idData.userId.params, storage: idData.userId.storage } : undefined }) as DataBySite<IdModuleNames>)
    : []
  return (
    <PureAdStackPrebidModulePage
      breadcrumbs={breadcrumbs}
      code={identityData?.code}
      data={data}
      helpText={'UserSync : UserId'}
      isLoading={all.loading}
      updateSiteData={updateSiteIdentity}
      baseCDNUrl={STATIC_ASSETS_CDN}
      onBackClick={() => navigate(-1)}
    />
  )
}
