import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Tooltip } from '~/components/Tooltip'
import Table, { handleTableSearch, handleTableSort, onColumnSort, SortableColumn, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { PureUsedStackTooltip } from '~/modules/adstack/rules/PureUsedStackTooltip'
import { countStacksUse } from '~/modules/adstack/rules/countStacksUsed'
import { WithClassName } from '~/types/utils'
import { HeaderBiddingRuleWithBidders } from './AdStackHeaderBiddingPage'
import { PureAdStackHeaderBiddingDetailsFlyout } from './PureAdStackHeaderBiddingDetailsFlyout'

type PureAdStackHeaderBiddingPageProps = WithClassName & {
  headerBiddingRules: HeaderBiddingRuleWithBidders[]
  isLoading: boolean
  breadcrumbs: React.ReactNode
  onCreateRule: () => void
  onEditRule: (id: string) => void
}

const HeaderBiddingRulesWrapper = styled.div`
  padding: 20px;
`

const TitleBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const TitleButtons = styled.div`
  display: flex;
  gap: 20px;
`

const ActionsTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down to hug action buttons */
`

const ButtonActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

const FilterAction = styled.form`
  margin: 12px 0px;
  display: flex;
`

const StacksTooltip = styled.div`
  div:not(:last-child) {
    border-bottom: 1px solid ${Colors.SlateGrey};
    padding-bottom: 6px;
  }
  div:not(:first-child) {
    padding-top: 6px;
  }
`

const _PureAdStackHeaderBiddingPage: FunctionComponent<PureAdStackHeaderBiddingPageProps> = ({ className, headerBiddingRules, isLoading, breadcrumbs, onCreateRule, onEditRule }) => {
  const [isOpenDetailsFlyout, setIsOpenDetailsFlyout] = useState<boolean>(false)
  const [ruleDetail, setRuleDetail] = useState<Omit<HeaderBiddingRuleWithBidders, 'numberOfBidderAdapters'>>()
  const [columns, setColumns] = useState<TableColumns<HeaderBiddingRuleWithBidders>>([
    {
      name: 'Rule',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'Active bidders',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'numberOfBidderAdapters',
    },
    {
      name: 'Used on',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'usedInStacks',
    },
    {
      name: 'Actions',
      isSortable: false,
    },
  ])

  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })

  const headerBiddingRulesWithNoRule: HeaderBiddingRuleWithBidders[] = [
    ...headerBiddingRules,
    { id: 'NO_RULE', name: 'All active bidders', bidderAdapters: [], usedInStacks: {}, bidderNames: [], numberOfBidderAdapters: 0, isBlockList: false },
  ]
  const search = useWatch({ control, name: 'search' })
  const searchedHeaderBiddingRules = handleTableSearch(headerBiddingRulesWithNoRule, search, ['name'])
  const sortedColumn = (columns.find((c) => c.isSortable && c.order !== 'none') || columns[0]) as SortableColumn<HeaderBiddingRuleWithBidders>
  let sortedHeaderBiddingRules: HeaderBiddingRuleWithBidders[] = []
  if (sortedColumn.attributeSort === 'usedInStacks') {
    sortedHeaderBiddingRules = searchedHeaderBiddingRules.sort((a, b) => (sortedColumn.order === 'ascending' ? 1 : -1) * (countStacksUse(b.usedInStacks) - countStacksUse(a.usedInStacks)))
  } else {
    sortedHeaderBiddingRules = handleTableSort(columns, searchedHeaderBiddingRules)
  }

  const generateBidderTooltip = (bidderAdapters: BidderAdapter[]) => (
    <StacksTooltip>
      <div>{bidderAdapters.map((bidderAdapter) => `${bidderAdapter.displayName}`).join(' | ')}</div>
    </StacksTooltip>
  )

  return (
    <HeaderBiddingRulesWrapper className={className}>
      <TitleBar>
        {breadcrumbs}
        <TitleButtons>
          <Button variant={'primary'} onClick={onCreateRule}>
            New rule
          </Button>
        </TitleButtons>
      </TitleBar>
      <>
        <FilterAction>
          <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
        </FilterAction>
        <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
          {sortedHeaderBiddingRules.map((headerBiddingRule) => (
            <TableRow
              key={headerBiddingRule.id}
              onClick={
                headerBiddingRule.name !== 'All active bidders'
                  ? (event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      setRuleDetail(headerBiddingRule)
                      setIsOpenDetailsFlyout(true)
                    }
                  : undefined
              }
            >
              <TableCell>{headerBiddingRule.name}</TableCell>
              <TableCell>
                {headerBiddingRule.numberOfBidderAdapters > 0 ? (
                  <Tooltip title={generateBidderTooltip(headerBiddingRule.bidderAdapters)} positions={['left', 'bottom', 'top', 'right']}>
                    {headerBiddingRule.numberOfBidderAdapters} {headerBiddingRule.numberOfBidderAdapters > 1 ? 'bidders' : 'bidder'}
                  </Tooltip>
                ) : (
                  <span>{headerBiddingRule.name !== 'All active bidders' ? '0 bidder' : '-'}</span>
                )}
              </TableCell>
              <TableCell>
                {headerBiddingRule.usedInStacks && countStacksUse(headerBiddingRule.usedInStacks) > 0 ? (
                  <PureUsedStackTooltip stacksBySite={headerBiddingRule.usedInStacks} />
                ) : (
                  <span>{headerBiddingRule.name !== 'All active bidders' ? '0 stack' : '-'}</span>
                )}
              </TableCell>
              <ActionsTableCell>
                <ButtonActions>
                  <Tooltip title={headerBiddingRule.name !== 'All active bidders' ? 'View' : `Can't view a Pubstack rule`}>
                    <Button
                      iconName={'view'}
                      variant={'tertiary'}
                      title={'View'}
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        setRuleDetail(headerBiddingRule)
                        setIsOpenDetailsFlyout(true)
                      }}
                      disabled={headerBiddingRule.name === 'All active bidders'}
                    />
                  </Tooltip>
                  <Tooltip title={headerBiddingRule.name !== 'All active bidders' ? 'Edit' : `Can't edit a Pubstack rule`} positions={['left', 'bottom', 'top', 'right']}>
                    <Button iconName={'edit'} variant={'tertiary'} title={'Edit'} onClick={() => onEditRule(headerBiddingRule.id)} disabled={headerBiddingRule.name === 'All active bidders'} />
                  </Tooltip>
                </ButtonActions>
              </ActionsTableCell>
            </TableRow>
          ))}
        </Table>
      </>
      {ruleDetail && (
        <PureAdStackHeaderBiddingDetailsFlyout isOpen={isOpenDetailsFlyout} onClose={() => setIsOpenDetailsFlyout(false)} headerBiddingRule={ruleDetail} onEdit={() => onEditRule(ruleDetail.id)} />
      )}
    </HeaderBiddingRulesWrapper>
  )
}

export const PureAdStackHeaderBiddingPage = styled(_PureAdStackHeaderBiddingPage)``
