import styled from '@emotion/styled'
import { GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST, GAM_DASHBOARD_NOT_APPLICABLE_AD_UNIT_LEVEL, GAM_DASHBOARD_OTHERS, GamOverviewMetricsByTime, MappedName } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useState } from 'react'
import { Color } from '~/assets/style/colors'
import { BaseDataTable } from '~/components/DataTable/DataTable'
import { DataTableCell, GaugeDataTableCell } from '~/components/DataTable/DataTableCell'
import { DataTableColumnData, DataTableSorting } from '~/components/DataTable/DataTableTypes'
import { Icon } from '~/components/Icon'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Tooltip } from '~/components/Tooltip'
import { Widget, WidgetProps } from '~/components/Widget'
import { eCPMObj, impressionCountObj, impressionRevenueObj, videoCompletionRate, videoViewershipTotalErrorCount, viewabilityMRCObj } from '~/modules/analytics/formulas'
import { arraySumObj } from '~/modules/analytics/formulas/operation-obj'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import {
  GAM_OVERVIEW_BREAKDOWN_ECPM_TOOLTIPS,
  GAM_OVERVIEW_BREAKDOWN_GAUGE_TOOLTIPS,
  GAM_OVERVIEW_BREAKDOWN_REVENUE_TOOLTIPS,
  GAM_OVERVIEW_DIMENSION_COLORS,
  GAM_OVERVIEW_GENERAL_COLOR,
  GamOverviewDimensions,
} from '~/utils/gamOverview'
import { displayWithCurrency } from '~/utils/string'

type GamOverviewMetricsByTimeMetric = 'impressionRevenue' | 'impressionCount' | 'eCPM' | 'viewabilityMRC' | 'videoCompletionRate' | 'videoTotalErrorRate'

const GamOverviewBreakdownWidget = styled(Widget)`
  max-height: 100%;
`
const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: hidden;
`

type GamOverviewMetricsByDim = {
  name: MappedName
  color?: Color
} & {
  [key in GamOverviewMetricsByTimeMetric]: { raw: number; display: number | string }
}

type TableContentProps = WithClassName & {
  rawData: GamOverviewMetricsByDim[]
  onRowClick: (name: MappedName) => void
  sorting: DataTableSorting<GamOverviewMetricsByTimeMetric>
  onSortChange: (metric: string) => void
}
const TableContent: FunctionComponent<TableContentProps> = ({ rawData, onRowClick, sorting, onSortChange }) => {
  return (
    <BaseDataTable
      sorting={sorting}
      columns={getBreakdownCols(rawData)}
      onRowClick={({ index }) => onRowClick(rawData[index].name)}
      rows={rawData}
      onSortChange={(option) => onSortChange(option.value as string)}
    />
  )
}

const DataTableTextWithTooltip = styled.div`
  display: inline-flex;

  & ${Tooltip} {
    margin-left: 3px;
    display: flex;
    align-items: center;
  }
`

const getBreakdownCols = (rawData: GamOverviewMetricsByDim[]): DataTableColumnData<GamOverviewMetricsByDim>[] => {
  return [
    {
      name: '',
      displaySort: true,
      render: ({ index }) => {
        const revenueSum = rawData.reduce((acc, next) => acc + next.impressionRevenue.raw, 0)
        const hasTooltip = (key: string): key is keyof typeof GAM_OVERVIEW_BREAKDOWN_GAUGE_TOOLTIPS => {
          return Object.keys(GAM_OVERVIEW_BREAKDOWN_GAUGE_TOOLTIPS).includes(key)
        }

        const tooltipKey = rawData[index].name.value
        const tooltipText = hasTooltip(tooltipKey) ? GAM_OVERVIEW_BREAKDOWN_GAUGE_TOOLTIPS[tooltipKey] : undefined

        return (
          <GaugeDataTableCell gaugeColor={rawData[index].color} gaugeValues={{ value: rawData[index].impressionRevenue.raw, max: revenueSum }}>
            <DataTableTextWithTooltip>
              {rawData[index].name.label}

              {tooltipText && (
                <Tooltip title={tooltipText} align={'center'} reposition={true}>
                  <Icon name={'info'} width={'16px'} />
                </Tooltip>
              )}
            </DataTableTextWithTooltip>
          </GaugeDataTableCell>
        )
      },
    },
    {
      name: impressionRevenueObj.name,
      displaySort: false,
      render: ({ index }) => {
        const hasTooltip = (key: string): key is keyof typeof GAM_OVERVIEW_BREAKDOWN_REVENUE_TOOLTIPS => {
          return Object.keys(GAM_OVERVIEW_BREAKDOWN_REVENUE_TOOLTIPS).includes(key)
        }

        const tooltipKey = rawData[index].name.value
        const tooltipText = hasTooltip(tooltipKey) ? GAM_OVERVIEW_BREAKDOWN_REVENUE_TOOLTIPS[tooltipKey] : undefined

        return (
          <DataTableCell>
            <DataTableTextWithTooltip>
              {rawData[index].impressionRevenue.display}
              {tooltipText && (
                <Tooltip title={tooltipText} align={'center'} reposition={true}>
                  <Icon name={'info'} width={'16px'} />
                </Tooltip>
              )}
            </DataTableTextWithTooltip>
          </DataTableCell>
        )
      },
      tooltip: ANALYTICS_TOOLTIPS.REVENUE,
    },
    {
      name: impressionCountObj.name,
      displaySort: false,
      render: ({ index }) => <DataTableCell>{rawData[index].impressionCount.display}</DataTableCell>,
    },
    {
      name: eCPMObj.name,
      displaySort: false,
      render: ({ index }) => {
        const hasTooltip = (key: string): key is keyof typeof GAM_OVERVIEW_BREAKDOWN_ECPM_TOOLTIPS => {
          return Object.keys(GAM_OVERVIEW_BREAKDOWN_ECPM_TOOLTIPS).includes(key)
        }

        const tooltipKey = rawData[index].name.value
        const tooltipText = hasTooltip(tooltipKey) ? GAM_OVERVIEW_BREAKDOWN_ECPM_TOOLTIPS[tooltipKey] : undefined

        return (
          <DataTableCell>
            <DataTableTextWithTooltip>
              {rawData[index].eCPM.display}
              {tooltipText && (
                <Tooltip title={tooltipText} align={'center'} reposition={true}>
                  <Icon name={'info'} width={'16px'} />
                </Tooltip>
              )}
            </DataTableTextWithTooltip>
          </DataTableCell>
        )
      },
      tooltip: ANALYTICS_TOOLTIPS.ECPM,
    },
    {
      name: viewabilityMRCObj.name,
      displaySort: false,
      render: ({ index }) => <DataTableCell>{rawData[index].viewabilityMRC.display}</DataTableCell>,
      tooltip: ANALYTICS_TOOLTIPS.VIEWABILITY,
    },
    {
      name: 'Vid. completion rate',
      displaySort: false,
      render: ({ index }) => <DataTableCell>{rawData[index].videoCompletionRate.display}</DataTableCell>,
      tooltip: ANALYTICS_TOOLTIPS.VIDEO_COMPLETION_RATE,
    },
    {
      name: 'Vid. error rate',
      displaySort: false,
      render: ({ index }) => <DataTableCell>{rawData[index].videoTotalErrorRate.display}</DataTableCell>,
      tooltip: ANALYTICS_TOOLTIPS.VIDEO_ERROR_RATE,
    },
  ]
}

const getDataBreakdown = ({
  rawData,
  sortValue,
  currencySymbol,
  dimension,
  displayVideoData,
}: {
  rawData: GamOverviewMetricsByTime
  currencySymbol: CurrencySymbol
  sortValue: SelectOptionProp<GamOverviewMetricsByTimeMetric>
  dimension: GamOverviewDimensions
  displayVideoData: boolean
}): GamOverviewMetricsByDim[] => {
  const { epoch: _, mappings, ...data } = rawData

  const dataSum = (Object.keys(data) as (keyof Omit<GamOverviewMetricsByTime, 'epoch' | 'mappings'>)[]).reduce(
    (objectAgg, value) => {
      objectAgg[value] = [arraySumObj(data[value] ?? [])]
      return objectAgg
    },
    {} as { [key in keyof GamOverviewMetricsByTime]: { [key: string]: number }[] }
  ) as any // TODO typing is hard here, formula on obj doesn't take optional keys in account

  const revenueSortedQueryBreakDown = Object.keys(mappings)
    .map((dimValue) => ({
      name: { value: dimValue, label: mappings[dimValue] },
      impressionRevenue: {
        raw: impressionRevenueObj.compute(dataSum, 0)[dimValue],
        display: displayWithCurrency(impressionRevenueObj.displayable(impressionRevenueObj.compute(dataSum, 0)[dimValue]), currencySymbol),
      },
      viewabilityMRC: {
        raw: viewabilityMRCObj.compute(dataSum, 0)[dimValue],
        display: viewabilityMRCObj.displayable(viewabilityMRCObj.compute(dataSum, 0)[dimValue]),
      },
      eCPM: {
        raw: eCPMObj.compute(dataSum, 0)[dimValue],
        display: displayWithCurrency(eCPMObj.displayable(eCPMObj.compute(dataSum, 0)[dimValue]), currencySymbol),
      },
      impressionCount: {
        raw: impressionCountObj.compute(dataSum, 0)[dimValue],
        display: impressionCountObj.displayable(impressionCountObj.compute(dataSum, 0)[dimValue]),
      },
      videoCompletionRate: {
        raw: videoCompletionRate.compute(dataSum, 0)[dimValue],
        display: displayVideoData ? videoCompletionRate.displayable(videoCompletionRate.compute(dataSum, 0)[dimValue]) : '-',
      },
      videoTotalErrorRate: {
        raw: videoViewershipTotalErrorCount.compute(dataSum, 0)[dimValue],
        display: displayVideoData ? videoViewershipTotalErrorCount.displayable(videoViewershipTotalErrorCount.compute(dataSum, 0)[dimValue]) : '-',
      },
    }))
    .sort((a, b) => b['impressionRevenue'].raw - a['impressionRevenue'].raw) //sorting based on revenue allows to assign colors to dimensions that have order-based colors

  const breakdownWithColors = revenueSortedQueryBreakDown
    .filter((o) => !o.name.value.startsWith('Unmapped') && o.name.value !== GAM_DASHBOARD_OTHERS && o.name.value !== '' && o.name.value !== GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST) //we have to forget some values, thus they don't get an indexed color
    .map((o, index) => {
      if (['pubstackDemandChannel', 'device'].includes(dimension)) {
        return { ...o, color: GAM_OVERVIEW_DIMENSION_COLORS[dimension][o.name.value] }
      }
      return { ...o, color: GAM_OVERVIEW_DIMENSION_COLORS[dimension][index] ?? GAM_OVERVIEW_GENERAL_COLOR.Others } // we only colors first adunits/sites, then we use the Others' color
    })

  const notApplicable = revenueSortedQueryBreakDown.find(
    (o) => o.name.value.startsWith('Unmapped') || o.name.value === GAM_DASHBOARD_NOT_APPLICABLE_AD_UNIT_LEVEL || o.name.value === GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST
  ) //after giving out colors based on index we can add the unranked info back in
  if (notApplicable) {
    breakdownWithColors.push({ ...notApplicable, color: GAM_OVERVIEW_GENERAL_COLOR.NotApplicable }) //which, if it exists, is always in the NotApplicable color category
  }

  const sortedData = breakdownWithColors.sort((a, b) => b[sortValue.value].raw - a[sortValue.value].raw)
  //Others are always at the end
  const others = revenueSortedQueryBreakDown.find((o) => o.name.label === GAM_DASHBOARD_OTHERS)
  const isOthersInData = sortedData.some((o) => o.name.label.startsWith(GAM_DASHBOARD_OTHERS))
  // If there is already an Others value in the data, avoid duplicate Others line (e.g. Devices)
  if (others && !isOthersInData) {
    sortedData.push({ ...others, color: GAM_OVERVIEW_GENERAL_COLOR.Others })
  }
  return sortedData
}

const sortOptionsWithoutFillRate: SelectOptionProp<GamOverviewMetricsByTimeMetric>[] = [
  { label: impressionRevenueObj.name, value: 'impressionRevenue' },
  { label: impressionCountObj.name, value: 'impressionCount' },
  { label: eCPMObj.name, value: 'eCPM' },
  { label: viewabilityMRCObj.name, value: 'viewabilityMRC' },
]

type PureGamOverviewBreakdownProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    rawData: GamOverviewMetricsByTime
    currencySymbol: CurrencySymbol
    onRowClick: (name: MappedName) => void
    onSortChange: (metric: string) => void
    dimension: GamOverviewDimensions
    displayVideoData: boolean
  }
const _PureGamOverviewBreakdown: FunctionComponent<PureGamOverviewBreakdownProps> = ({ rawData, currencySymbol, onRowClick, onSortChange, dimension, displayVideoData, ...props }) => {
  const sortOptions = sortOptionsWithoutFillRate
  const [sortValue, setSortValue] = useState<SelectOptionProp<GamOverviewMetricsByTimeMetric>>(sortOptions[0])

  const sorting: DataTableSorting<GamOverviewMetricsByTimeMetric> = {
    setSortValue,
    sortValue,
    sortOptions,
  }

  const breakdownData = getDataBreakdown({ rawData, currencySymbol, sortValue, dimension, displayVideoData })
  return (
    <GamOverviewBreakdownWidget {...props} icon={'fire'} title={'Breakdown'} subTitle={'Click on a line to filter'}>
      <WidgetContent>
        <TableContent rawData={breakdownData} onRowClick={onRowClick} sorting={sorting} onSortChange={onSortChange} />
      </WidgetContent>
    </GamOverviewBreakdownWidget>
  )
}
export const PureGamOverviewBreakdown = styled(_PureGamOverviewBreakdown)``
