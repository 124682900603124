import { Scope } from '@pubstack/common/src/scope'
import { FunctionComponent, useEffect } from 'react'
import User from '~/auth/User'
import { useUser } from '~/auth/user.hooks'

const getFilter = (scopes: Scope[], user: User | null) => {
  if (user && user.isAdmin) {
    const currentScope = scopes.find((s) => s.id === user.currentScopeId)
    scopes = currentScope ? [currentScope] : scopes
  }
  const scopeIdFilter = scopes.map((s) => `scopeId:${s.id}`)
  const scopeFilter = scopes.map((s) => `scope:${s.name}`)
  const featureFilter = (user?.features || []).map((f) => `feature:${f.split(':')[2]}`)
  return [...scopeIdFilter, ...scopeFilter, ...featureFilter].join(';')
}

export const BeamerIntegration: FunctionComponent<{ scopes: Scope[] }> = ({ scopes }) => {
  const user = useUser()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
    script.async = true
    script.id = 'hs-script-loader'
    document.body.appendChild(script)
    window.beamer_config = {
      product_id: 'mNGnZtjh47048', //DO NOT CHANGE: This is your product code on Beamer
      selector: 'beamer',
      user_id: user && user?.currentScopeId + ':' + user?.profile?.email,
      user_lastname: user?.profile.email?.split('@')?.[0],
      user_firstname: ' ',
      user_email: user?.profile.email,
      filter: getFilter(scopes, user),
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}
