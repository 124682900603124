import styled from '@emotion/styled'
import { BidParam, BidderCatalogAlias, BidderParam, MappedBidder } from '@pubstack/common/src/bidderCatalog'
import { SiteStacksByProperty } from '@pubstack/common/src/stack'
import { FunctionComponent } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Qualifier } from '~/components/Qualifier'
import { Select } from '~/components/Select'
import { Skeleton } from '~/components/Skeleton'
import { Tooltip } from '~/components/Tooltip'
import { PureAdStackSiteMappingSpreadsheet } from '~/modules/adstack/inventory/bidderParams/PureAdStackSiteMappingSpreadsheet'
import { validateBidderParams } from '~/modules/adstack/inventory/bidderParams/mappingTransfer/mappingUpdate'
import { WithClassName } from '~/types/utils'
import { generateStackNameList } from '../../rules/PureUsedStackTooltip'

const TitleBloc = styled.span`
  ${Fonts.P1};
  width: 25%;
  display: inline-flex;
  align-items: center;
  gap: 8px;
`
const WrapperPanel = styled(CollapsiblePanel)`
  padding: 12px;
`

const AdunitList = styled.div`
  display: grid;
  gap: 10px;
`
const RowTitle = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`
const AdUnitName = styled.div`
  ${Fonts.P1}
  width: 25%;
  display: flex;
  flex-direction: column;
`

const PanelContentHeader = styled.div`
  & ${_Input} {
    width: 200px;
  }
`
const NoAdunits = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  h1 {
    ${Fonts.H1}
    font-weight: 400;
    margin-bottom: 12px;
  }
  p {
    ${Fonts.P1}
  }
`
const SearchBar = styled.form`
  margin-bottom: 12px;
  display: flex;
  gap: 14px;
`
const getNbOfParams = (bidderParams: BidderParam[], device: string, hasParams?: boolean) => {
  return bidderParams.filter((bp) => bp.device === device && hasParams && Object.keys(bp.params).length > 0).length
}

function enrichAndSort(filteredBidders: MappedBidder[], stacksByAdunit: SiteStacksByProperty, siteName: string): MappedAdunit[] {
  return filteredBidders.reduce((acc, e) => {
    const adUnitNames = new Set(
      e.alias.bidderParams.map((bp) => {
        return bp.adUnitName
      })
    )
    adUnitNames.forEach((adUnitName) => {
      const found = acc.find((ma) => ma.adUnitName === adUnitName)
      if (found) {
        const newMapping = {
          ...e.alias,
          bidderParams: e.alias.bidderParams.filter((bp) => bp.adUnitName === adUnitName),
        }
        found.mappings[e.alias.bidderLabel] = newMapping
        found.usedNbOfDesktopBidders += getNbOfParams(newMapping.bidderParams, 'desktop', true)
        found.usedNbOfMobileBidders += getNbOfParams(newMapping.bidderParams, 'mobile', true)
        found.totalNbOfDesktopBidders += getNbOfParams(newMapping.bidderParams, 'desktop')
        found.totalNbOfMobileBidders += getNbOfParams(newMapping.bidderParams, 'mobile')
      } else {
        const mappings: { [key: string]: BidderCatalogAlias } = {}
        mappings[e.alias.bidderLabel] = {
          ...e.alias,
          bidderParams: e.alias.bidderParams.filter((bp) => bp.adUnitName === adUnitName),
        }
        const params = mappings[e.alias.bidderLabel].bidderParams
        acc.push({
          adUnitName: adUnitName,
          adServerAdUnitName: 'n/a',
          adUnitId: 'n/a',
          usedNbOfDesktopBidders: getNbOfParams(params, 'desktop', true),
          usedNbOfMobileBidders: getNbOfParams(params, 'mobile', true),
          totalNbOfDesktopBidders: getNbOfParams(params, 'desktop'),
          totalNbOfMobileBidders: getNbOfParams(params, 'mobile'),
          mappedStacks: stacksByAdunit[adUnitName]?.[siteName] ?? [],
          totalNbOfStacks: e.totalStacks,
          mappings,
        })
      }
    })
    return acc
  }, [] as MappedAdunit[])
}

type MappedAdunit = {
  mappings: { [key: string]: BidderCatalogAlias }
  adUnitName: string
  adServerAdUnitName: string
  adUnitId: string
  usedNbOfDesktopBidders: number
  usedNbOfMobileBidders: number
  mappedStacks: {
    stackName: string
    version: string
    enabled: boolean
  }[]
  totalNbOfDesktopBidders: number
  totalNbOfMobileBidders: number
  totalNbOfStacks: number
}

type PureSiteMappingAdunitViewProps = WithClassName & {
  bidderList: MappedBidder[]
  isLoading: boolean
  onCreateNewBidder: () => void
  onMappingUploadByAdUnit: (adUnitName: string, bidderParams: BidderParam[]) => void
  stacksByAdunit: SiteStacksByProperty
  siteName: string
}

const _PureSiteMappingAdunitView: FunctionComponent<PureSiteMappingAdunitViewProps> = ({ className, bidderList, isLoading, onCreateNewBidder, onMappingUploadByAdUnit, stacksByAdunit, siteName }) => {
  const adunitList: MappedAdunit[] = enrichAndSort(bidderList, stacksByAdunit, siteName)
  const {
    resetField,
    control,
    formState: { isDirty },
  } = useForm<{ search: string; usedOnThisSite: boolean }>({
    defaultValues: {
      search: '',
      usedOnThisSite: false,
    },
  })
  const { search, usedOnThisSite } = useWatch({ control })
  const searchedAdunits = adunitList.filter((a) => !search || a.adUnitName.toLowerCase().includes(search.toLowerCase()))
  const filteredAdunits = usedOnThisSite ? searchedAdunits.filter((a) => a.mappedStacks.length > 0) : searchedAdunits
  const pipeSeparator = `\xa0 | \xa0`
  return (
    <AdunitList>
      <SearchBar>
        <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search ad unit'} control={control} />
        <Select
          control={control}
          name={'usedOnThisSite'}
          disabled={true}
          options={[
            { label: 'Used on this site', value: true },
            { label: 'All ad units', value: false },
          ]}
          label={'Ad units'}
        />
        {isDirty && (
          <Button
            iconName={'close'}
            variant={'tertiary'}
            onClick={() => {
              resetField('search')
              resetField('usedOnThisSite')
            }}
          />
        )}
      </SearchBar>
      {isLoading ? (
        <>
          {[0, 1, 2].map((e) => (
            <WrapperPanel
              key={e}
              hasBorderWhenOpened
              isCollapsible={true}
              className={className}
              title={
                <RowTitle>
                  <TitleBloc>
                    <Skeleton width={`15ch`} />
                  </TitleBloc>
                  <TitleBloc>
                    <Skeleton width={`15ch`} />
                  </TitleBloc>
                  <TitleBloc>
                    <Skeleton width={`15ch`} />
                  </TitleBloc>
                </RowTitle>
              }
            />
          ))}
        </>
      ) : filteredAdunits.length > 0 ? (
        filteredAdunits.map((adunit) => {
          return (
            <WrapperPanel
              key={adunit.adUnitName}
              hasBorderWhenOpened
              isCollapsible={true}
              className={className}
              title={
                <RowTitle>
                  <AdUnitName>
                    <span>{adunit.adUnitName}</span>
                  </AdUnitName>
                  <TitleBloc>
                    <Qualifier iconName={'desktop'} tooltipText={'Desktop'} color={Colors.Petrol} active={true} enabled={true} />
                    {adunit.usedNbOfDesktopBidders === 0 || adunit.usedNbOfDesktopBidders !== adunit.totalNbOfDesktopBidders ? (
                      <span>
                        {adunit.usedNbOfDesktopBidders} bidder{adunit.usedNbOfDesktopBidders > 1 && 's'}/alias{adunit.usedNbOfDesktopBidders > 1 && 'es'}
                      </span>
                    ) : (
                      <span>All active bidders/aliases ({adunit.totalNbOfDesktopBidders})</span>
                    )}
                  </TitleBloc>
                  <TitleBloc>
                    <Qualifier iconName={'mobile'} tooltipText={'Mobile'} color={Colors.Pool} active={true} enabled={true} />
                    {adunit.usedNbOfMobileBidders === 0 || adunit.usedNbOfMobileBidders !== adunit.totalNbOfMobileBidders ? (
                      <span>
                        {adunit.usedNbOfMobileBidders} bidder{adunit.usedNbOfMobileBidders > 1 && 's'}/alias{adunit.usedNbOfMobileBidders > 1 && 'es'}
                      </span>
                    ) : (
                      <span>All active bidders/aliases ({adunit.totalNbOfMobileBidders})</span>
                    )}
                  </TitleBloc>
                  {adunit.mappedStacks.length > 0 ? (
                    <Tooltip title={generateStackNameList(adunit.mappedStacks).reduce((stack, acc) => [acc, pipeSeparator, stack])} positions={['left', 'bottom', 'top', 'right']}>
                      {adunit.mappedStacks.length !== adunit.totalNbOfStacks ? (
                        <span>
                          {adunit.mappedStacks.length} stack{adunit.mappedStacks.length > 1 && 's'}
                        </span>
                      ) : (
                        <span>All stacks ({adunit.totalNbOfStacks})</span>
                      )}
                    </Tooltip>
                  ) : (
                    <span>0 stack</span>
                  )}
                </RowTitle>
              }
            >
              <>
                <PanelContentHeader>
                  <h2>Mapping</h2>
                  <span>Fill wanted lines then Save mapping. Do not remove any line or column.</span>
                </PanelContentHeader>
                <PureAdStackSiteMappingSpreadsheet
                  bidParamsMap={Object.entries(adunit.mappings).reduce(
                    (acc, [key, value]) => {
                      acc[key] = value.bidParams
                      return acc
                    },
                    {} as { [key: string]: BidParam[] }
                  )}
                  bidderParams={Object.values(adunit.mappings)
                    .sort((a, b) => a.bidderLabel.localeCompare(b.bidderLabel))
                    .flatMap((m) => m.bidderParams)}
                  validateBidderParams={(bidderParams) => {
                    return Array.from(new Set(bidderParams.map((m) => m.bidderName)))
                      .flatMap((name) => {
                        return validateBidderParams(
                          bidderParams.filter((m) => m.bidderName === name),
                          { bidParams: adunit.mappings[name].bidParams || [] }
                        )
                      })
                      .flatMap((sv) => sv.entries)
                  }}
                  onBidderParamsUpdate={(bidderParamsValue) => {
                    onMappingUploadByAdUnit(adunit.adUnitName, bidderParamsValue)
                  }}
                />
              </>
            </WrapperPanel>
          )
        })
      ) : (
        <NoAdunits>
          <Icon name={'night_sky'} width={'190px'} />
          <div>
            <h1>It seems that no bidder mapping exist on your site.</h1>
            <p>Add bidder mapping to your inventory.</p>
          </div>
          <Button iconName={'add_bidder'} variant={'primary'} onClick={onCreateNewBidder}>
            Add new bidder
          </Button>
        </NoAdunits>
      )}
    </AdunitList>
  )
}

export const PureSiteMappingAdunitView = styled(_PureSiteMappingAdunitView)``
