import styled from '@emotion/styled'
import { FunctionComponent, ReactNode, useState } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { ScrollbarStyle } from '~/assets/style/utils'
import Button from '~/components/Button'
import { BaseDataTable } from '~/components/DataTable/DataTable'
import { DataTableCell } from '~/components/DataTable/DataTableCell'
import { DataTableColumnData } from '~/components/DataTable/DataTableTypes'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { ControlCenterViewModel } from '~/modules/adstack/inventory/controlCenter/PureAdStackControlCenterPage'

const Description = styled.div`
  margin-bottom: 24px;
`

const SitesListWrapper = styled.div`
  display: flex;
  overflow: hidden;
  ${ScrollbarStyle};
  max-height: 380px;
  position: relative;

  > * {
    flex: 1 0;
  }
`

const SiteDataTable = styled(BaseDataTable)`
  flex: 1 0;
  th,
  td > div {
    min-width: auto;
  }

  th:nth-of-type(3),
  th:nth-of-type(3) div,
  tr td:nth-of-type(3) div {
    --size: 50px;
    width: var(--size);
    max-width: var(--size);
    text-align: right;
    justify-content: right;
  }

  th:nth-of-type(4),
  th:nth-of-type(4) div,
  tr td:nth-of-type(4) div {
    --size: 70px;
    width: var(--size);
    max-width: var(--size);
    text-align: right;
    justify-content: right;
  }
` as typeof BaseDataTable

const Cell = styled(DataTableCell)`
  ${Fonts.P2};
  font-weight: 400;
  cursor: default;
`
const SiteCell = styled(Cell)`
  ${Fonts.P2};
  &:first-of-type {
    ${Fonts.P2};
  }
  font-weight: 500;
`

type SitesListProps = {
  sites: ControlCenterViewModel[]
  canAbTest: boolean
}
const SitesList: FunctionComponent<SitesListProps> = ({ sites, canAbTest }) => {
  type Data = {
    siteName: string
    stackName: string
    version: string
    percentage: string
  }
  const data: Data[] = sites.flatMap(({ stackGroups, site }) =>
    stackGroups.flatMap((stackGroup, stackGroupIndex) =>
      stackGroup.stacks.flatMap((stack, stackIndex) => ({
        siteName: stackGroupIndex === 0 && stackIndex === 0 ? site.name : '',
        stackName: stack.name,
        percentage: `${stack.ratio ?? 0}%`,
        version: `v${stack.version ?? 0}`,
      }))
    )
  )
  const columns: DataTableColumnData<Data>[] = [
    { name: 'Site', render: ({ index, rows, ...props }) => <SiteCell {...props}>{rows[index].siteName}</SiteCell> },
    { name: 'Stack', render: ({ index, rows, ...props }) => <Cell {...props}>{rows[index].stackName}</Cell> },
    { name: 'Version', render: ({ index, rows, ...props }) => <Cell {...props}>{rows[index].version}</Cell> },
    ...(canAbTest ? ([{ name: '% of traffic', render: ({ index, rows, ...props }) => <Cell {...props}>{rows[index].percentage}</Cell> }] as DataTableColumnData<Data>[]) : []),
  ]

  return (
    <SitesListWrapper>
      <SiteDataTable columns={columns} rows={data} />
    </SitesListWrapper>
  )
}

type ControlCenterActionConfirmModalProps = {
  sites: ControlCenterViewModel[]
  onConfirm: (sites: ControlCenterViewModel[]) => Promise<void>
  confirmLabel: string
  description: ReactNode
  title: string
  canAbTest: boolean
}
const _ControlCenterActionConfirmModal: FunctionComponent<ControlCenterActionConfirmModalProps> = ({ sites, onConfirm, confirmLabel, description, title, canAbTest }) => {
  const { close } = useGlobalModal()
  const [isLoading, setLoading] = useState(false)
  const onSubmit = async () => {
    if (isLoading) return
    setLoading(true)
    await onConfirm(sites)
  }
  return (
    <Modal.Content>
      <Modal.Title>{title}</Modal.Title>

      <Modal.Body>
        <Description>{description}</Description>
        <SitesList sites={sites} canAbTest={canAbTest} />
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onSubmit} disabled={isLoading}>
          {confirmLabel}
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

export const ControlCenterActionConfirmModal = styled(_ControlCenterActionConfirmModal)``
