import styled from '@emotion/styled'
import { capitalizeFirstLetter } from '@pubstack/common/src/stringUtils'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Flyout } from '~/components/Flyout'
import { AdFormatDisplay } from '~/modules/adstack/inventory/adunits/AdFormatDisplay'
import { WithClassName } from '~/types/utils'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${Fonts.P2};
  font-weight: 400;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const BlockTitle = styled.div`
  ${Fonts.H2};
  font-weight: 500;
`

const Compatibility = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const BlockSubTitle = styled.span`
  font-weight: 500;
`

export type AdFormatCardHelpFlyoutProps = WithClassName & {
  adFormat: AdFormatDisplay
  isOpen: boolean
  onRequestClose: () => unknown
}

const _AdFormatCardHelpFlyout: FunctionComponent<AdFormatCardHelpFlyoutProps> = ({ className, adFormat, isOpen, onRequestClose }) => {
  const pipeSeparator = `\xa0 | \xa0`
  return (
    <Flyout className={className} isOpen={isOpen} onClose={onRequestClose} title={adFormat.name} color={Colors.Turquoise}>
      <Content>
        <Block>
          <BlockTitle>Format</BlockTitle>
          <div>{adFormat.helpText}</div>
        </Block>
        <Block>
          <BlockTitle>Compatibility</BlockTitle>
          <Compatibility>
            <div>
              <BlockSubTitle>Devices: </BlockSubTitle>
              {adFormat.devices.map(capitalizeFirstLetter).join(pipeSeparator)}
            </div>
            <div>
              <BlockSubTitle>Mediatypes: </BlockSubTitle>
              {adFormat.mediatypes.join(pipeSeparator)}
            </div>
            {adFormat.sizes.length > 0 && (
              <div>
                <BlockSubTitle>Recommended sizes: </BlockSubTitle>
                {adFormat.sizes.map((sizeByDevice) => sizeByDevice.size).join(pipeSeparator)}
              </div>
            )}
          </Compatibility>
        </Block>
      </Content>
    </Flyout>
  )
}

export const AdFormatCardHelpFlyout = styled(_AdFormatCardHelpFlyout)``
