import styled from '@emotion/styled'
import { ExclusionType, RefreshException } from '@pubstack/common/src/refresh'
import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Banner } from '~/components/Banner'
import Button from '~/components/Button'
import { ChipListInput } from '~/components/ChipListInput'
import { ContentCard } from '~/components/ContentCard'
import { Icon } from '~/components/Icon'
import { Selector } from '~/components/Selector'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'
import { PureSettingsRefreshExceptionsDeleteFileModal } from './PureSettingsRefreshExceptionsDeleteFileModal'
import { ListTransfer } from './listTransfer/ListTransfer'

const FormWrapper = styled.form`
  max-width: min-content;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CardTitle = styled.h2`
  ${Fonts.colors.Jet}
  ${Fonts.H2}
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin: 0;
`

const Card = styled(ContentCard)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ModeChoices = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 8px;

  & ${Selector} {
    width: 400px;
  }
`

const IdentifierChoice = styled.div`
  > * {
    padding: 16px 0;
    border-top: 1px solid ${Colors.Platinum};
  }
  :last-of-type > * {
    border-bottom: 1px solid ${Colors.Platinum};
  }
`
export type RefreshExceptionFormData = Omit<RefreshException, 'exclusionFiles'> & { exclusionFiles?: { mode: string; lineItemIdFile: File | null; orderIdFile: File | null } }

type PureSettingsRefreshExceptionsPage = WithClassName & {
  breadcrumbs: ReactNode
  refreshException: RefreshException
  onDownload: (type: ExclusionType) => void
  onDelete: (type: ExclusionType) => void
  onSave: (data: RefreshExceptionFormData) => void
}
const _PureSettingsRefreshExceptionsPage: FunctionComponent<PureSettingsRefreshExceptionsPage> = ({ className, refreshException, onDownload, onDelete, onSave }) => {
  const modal = useGlobalModal()
  const defaultValues: RefreshException = {
    exclusionSizes: refreshException.exclusionSizes ?? [],
    exclusionBidders: refreshException.exclusionBidders ?? [],
    exclusionFiles: {
      mode: refreshException.exclusionFiles?.mode ?? '',
      lineItemIdFile: '',
      orderIdFile: '',
    },
  }
  const {
    control,
    setValue,
    formState: { isDirty, dirtyFields },
    handleSubmit,
    reset,
    resetField,
    getValues,
  } = useForm<RefreshException>({ defaultValues })
  const exclusionFiles = useWatch({ control, name: 'exclusionFiles' })

  const [lineItemIdFile, setLineItemIdFile] = useState<File | null>(null)
  const [orderIdFile, setOrderIdFile] = useState<File | null>(null)

  const [hasSaved, setHasSaved] = useState(false)

  const handleReset = () => {
    reset(defaultValues)
    setLineItemIdFile(null)
    setOrderIdFile(null)
  }

  const getCurrentFileName = (type: ExclusionType): string => {
    return refreshException.exclusionFiles?.[`${type}File`] ?? (type === 'orderId' ? orderIdFile?.name : lineItemIdFile?.name) ?? ''
  }

  /**
   * to update the form accurately we need to watch refreshException changes
   * these changes happen on three occasions : on init, on save, and on file deletion
   */
  useEffect(() => {
    // in case of init or save
    if (hasSaved || !isDirty) {
      handleReset()
      setHasSaved(false)
    } else {
      // on a file deletion we set the dirty values in the form manually to not mess with the form's state, but still remove the file
      const dirtyFieldsNames = Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>
      const changedValues = Object.fromEntries(dirtyFieldsNames.map((key, i) => [key, getValues(dirtyFieldsNames)[i]]))
      dirtyFieldsNames.forEach((value) => {
        setValue(value, changedValues[value])
      })
    }
  }, [refreshException])

  const onSubmit: SubmitHandler<RefreshException> = (data) => {
    const formData: RefreshExceptionFormData = {
      ...data,
      exclusionFiles: {
        mode: data.exclusionFiles?.mode ?? '',
        lineItemIdFile,
        orderIdFile,
      },
    }
    onSave(formData)
    setHasSaved(true)
  }

  const openDeleteFileModal = (type: ExclusionType) => {
    const fileName = getCurrentFileName(type)
    modal.open(PureSettingsRefreshExceptionsDeleteFileModal, {
      fileName,
      onValidate: () => {
        if (orderIdFile && type === 'orderId') {
          setOrderIdFile(null)
          resetField('exclusionFiles.orderIdFile')
        } else if (lineItemIdFile && type === 'lineItemId') {
          setLineItemIdFile(null)
          resetField('exclusionFiles.lineItemIdFile')
        } else {
          onDelete(type)
        }
      },
    })
  }

  return (
    <div className={className}>
      <FormWrapper>
        <Card color={SETTINGS_NAV_CONFIG['refreshExceptions'].color}>
          <CardTitle>
            <Icon width={'22px'} name={'size'} /> Creative formats & bidders
          </CardTitle>
          <CardContent>
            Disable ad refresh on:
            <ChipListInput chipColor={Colors.Mint} isColorVariable={true} control={control} name={'exclusionSizes'} label={'Sizes'} />
            <ChipListInput chipColor={Colors.Topaze} isColorVariable={true} control={control} name={'exclusionBidders'} label={'Bidders'} />
          </CardContent>
        </Card>
        <Card color={SETTINGS_NAV_CONFIG['refreshExceptions'].color}>
          <CardTitle>
            <Icon width={'22px'} name={'receipt'} /> Order & line item
          </CardTitle>
          <CardContent>
            Select the type of list you want to use, then upload the corresponding list.
            <ModeChoices>
              <Selector
                selected={exclusionFiles?.mode === 'whitelist'}
                title={'Allow list'}
                detail={'Enable ad refresh on the following list'}
                icon={'check'}
                onClick={() => setValue('exclusionFiles.mode', 'whitelist', { shouldDirty: true })}
              />
              <Selector
                selected={exclusionFiles?.mode === 'blacklist'}
                title={'Block list'}
                detail={'Disable ad refresh on the following list'}
                icon={'block'}
                onClick={() => setValue('exclusionFiles.mode', 'blacklist', { shouldDirty: true })}
              />
            </ModeChoices>
            <div>
              <IdentifierChoice>
                <ListTransfer
                  disabled={exclusionFiles?.mode !== 'blacklist' && exclusionFiles?.mode !== 'whitelist'}
                  filename={orderIdFile?.name ?? refreshException.exclusionFiles?.orderIdFile ?? undefined}
                  control={control}
                  name={'exclusionFiles.orderIdFile'}
                  label={'Order ID'}
                  onDownload={() => onDownload('orderId')}
                  onDelete={() => openDeleteFileModal('orderId')}
                  onChange={(event) => setOrderIdFile(event.target.files?.[0] ?? null)}
                />
              </IdentifierChoice>
              <IdentifierChoice>
                <ListTransfer
                  disabled={exclusionFiles?.mode !== 'blacklist' && exclusionFiles?.mode !== 'whitelist'}
                  filename={lineItemIdFile?.name ?? refreshException.exclusionFiles?.lineItemIdFile ?? undefined}
                  control={control}
                  name={'exclusionFiles.lineItemIdFile'}
                  label={'Line item ID'}
                  onDownload={() => onDownload('lineItemId')}
                  onDelete={() => openDeleteFileModal('lineItemId')}
                  onChange={(event) => setLineItemIdFile(event.target.files?.[0] ?? null)}
                />
              </IdentifierChoice>
            </div>
          </CardContent>
        </Card>
        <Banner
          isOpen={isDirty}
          message={'You are about to edit refresh exceptions. Are you sure ?'}
          actions={
            <>
              <Button onClick={handleReset} variant={'secondary'} iconName={'restore'}>
                Restore
              </Button>
              <Button onClick={handleSubmit(onSubmit)}>Apply</Button>
            </>
          }
        />
      </FormWrapper>
    </div>
  )
}

export const PureSettingsRefreshExceptionsPage = styled(_PureSettingsRefreshExceptionsPage)``
