import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, RefreshMetricsByDim, RefreshMetricsByRank, RefreshMetricsByTime } from '@pubstack/common/src/analytics/query'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useAnalyticsQuery } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { logSortBreakdownAction, onBreakdownRowClick } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useBreakdownTabs } from '~/utils/useBreakdownTabs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureRefreshBreakdown } from './PureRefreshBreakdown'
import { PureRefreshRank } from './PureRefreshRank'
import { PureRefreshTimeline } from './PureRefreshTimeline'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 16px;
  }
`

type RefreshPageProps = WithClassName
const _RefreshPage: FunctionComponent<RefreshPageProps> = ({ className }) => {
  const logger = useLogger()
  const dimensions: Dimension[] = ['tagId', 'bidder', 'adUnit', 'device', 'country', 'size', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion']
  const dashboard: AnalyticsQueryDashboard = { name: 'refresh', filterType: 'auctions' }
  const [context, setContext] = useRecoilState(contextState)
  const [breakdownDimension, setBreakdownDimension] = useState<Dimension>('tagId')
  const [timeData, setTimeData] = useState<RefreshMetricsByTime | undefined>(undefined)
  const [rankData, setRankData] = useState<RefreshMetricsByRank | undefined>(undefined)
  const [dimData, setDimData] = useState<RefreshMetricsByDim | undefined>(undefined)
  const { byId: analyticsQueryTime } = useAnalyticsQuery<RefreshMetricsByTime>(null, dimensions, context)
  const { byId: analyticsQueryRank } = useAnalyticsQuery<RefreshMetricsByRank>(null, dimensions, context)
  const { byId: analyticsQueryDim } = useAnalyticsQuery<RefreshMetricsByDim>(null, dimensions, context)
  const currencySymbol = useScopeCurrency()
  const user = useUser()
  const tabsConfig = useBreakdownTabs(user, ['tagId', 'bidder', 'adUnit', 'device', 'country', 'size'], { abTestPopulation: 'abtest', stackIdVersion: 'adstack' })

  const loadTimeLineData = async () => {
    if (analyticsQueryTime.loading) {
      analyticsQueryTime.abort()
    }
    const d = await analyticsQueryTime.post('refresh.metrics.by.time', {})
    setTimeData(d.values)
  }

  const loadRankData = async () => {
    if (analyticsQueryRank.loading) {
      analyticsQueryRank.abort()
    }
    const d = await analyticsQueryRank.post('refresh.metrics.by.rank', {})
    setRankData(d.values)
  }

  const loadBreakdownData = async (dimension: Dimension) => {
    if (analyticsQueryDim.loading) {
      analyticsQueryDim.abort()
    }
    const d = await analyticsQueryDim.post('refresh.metrics.by.dim', { dimension })
    setDimData(d.values)
  }

  const loadAllData = async () => {
    loadBreakdownData(breakdownDimension)
    loadRankData()
    loadTimeLineData()
  }

  const onBreakdownTabChange = (dimension: Dimension) => {
    logger.info({ action: 'click', type: 'refresh-breakdown', detail: dimension })
    setBreakdownDimension(dimension)
    loadBreakdownData(dimension)
  }

  const onMetricChange = (metric: string) => {
    logger.info({ type: 'graph-action', action: 'click', graphName: 'refresh-timeline', detail: metric })
  }

  useEffect(() => {
    setTimeout(() => {
      loadAllData()
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context])

  const breadcrumbs = useBreadcrumbs()
  return (
    <AnalyticsPage className={className} onRefreshClick={loadAllData} filterDimensions={dimensions} dashboard={dashboard} title={breadcrumbs}>
      <PageContent>
        <PureRefreshTimeline
          isLoading={analyticsQueryTime.loading}
          error={!!analyticsQueryTime.error}
          empty={!analyticsQueryTime.loading && !timeData?.epoch.length}
          onRefreshClick={loadTimeLineData}
          data={timeData ?? { epoch: [] }}
          currencySymbol={currencySymbol}
          currentEpoch={DateTime.now()}
          timezone={context.timeRange.tz}
          onMetricChange={onMetricChange}
        />
        <PureRefreshRank
          isLoading={analyticsQueryRank.loading}
          error={!!analyticsQueryRank.error}
          empty={!analyticsQueryRank.loading && !rankData?.name.length}
          onRefreshClick={loadRankData}
          rawData={rankData}
          currencySymbol={currencySymbol}
        />
        <PureRefreshBreakdown
          isLoading={analyticsQueryDim.loading}
          error={!!analyticsQueryDim.error}
          empty={!analyticsQueryDim.loading && !dimData?.name.length}
          onRefreshClick={() => loadBreakdownData(breakdownDimension)}
          rawData={dimData ?? { name: [] }}
          onTabChange={onBreakdownTabChange}
          currencySymbol={currencySymbol}
          onRowClick={onBreakdownRowClick(logger, breakdownDimension, setContext, !dimData)}
          onSortChange={logSortBreakdownAction(logger)}
          tabsConfig={tabsConfig}
        />
      </PageContent>
    </AnalyticsPage>
  )
}
export const RefreshPage = styled(_RefreshPage)``
