import { NonNullable } from '@pubstack/common/src/assertion'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { useUser } from '~/auth/user.hooks'
import { useAlerts } from '~/modules/alerts/hooks/useAlerts'
import { useCanAccessRoute } from '~/utils/router'
import { PureMenu, PureMenuItem, PureMenuSection } from './PureMenu'
import { SimpleItem, buildSections } from './menu.config'

const appendTraillingSlash = (str: string) => `${str}${str.endsWith('/') ? '' : '/'}`
const addActive =
  (currentLocation: string) =>
  <T extends SimpleItem>(item: T) => ({
    ...item,
    active: appendTraillingSlash(currentLocation).startsWith(appendTraillingSlash(item.to)),
  })
const addOnClick =
  (onClick: (item: SimpleItem) => void) =>
  <T extends SimpleItem>(item: T & { onClick?: () => void }) => ({ ...item, onClick: item.onClick ?? (() => onClick(item)) })

const useMenuOpenState = () => {
  const [isGroupOpen, setIsGroupOpen] = useLocalStorage<{ [key: string]: boolean }>('menuOpenGroups', {})
  const key = (section: string, item: string) => `${section}-${item}`
  const isOpen = useCallback((section: string, item: string) => isGroupOpen[key(section, item)], [isGroupOpen])
  const toggle = useCallback((section: string, item: string) => setIsGroupOpen({ ...isGroupOpen, [key(section, item)]: !isGroupOpen[key(section, item)] }), [isGroupOpen, setIsGroupOpen])
  const open = useCallback((section: string, item: string) => setIsGroupOpen({ ...isGroupOpen, [key(section, item)]: true }), [isGroupOpen, setIsGroupOpen])
  const close = useCallback((section: string, item: string) => setIsGroupOpen({ ...isGroupOpen, [key(section, item)]: false }), [isGroupOpen, setIsGroupOpen])

  return {
    isOpen,
    open,
    close,
    toggle,
  }
}

const Menu = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const canAccessRoute = useCanAccessRoute()
  const user = useUser()
  const alerts = useAlerts()
  const alertsNotification = (alerts[user?.currentScopeId || ''] || []).filter((alert) => !alert.acknowledge).length
  const { open, toggle, isOpen } = useMenuOpenState()
  const handleItems = (items: SimpleItem[]): PureMenuItem[] => {
    return items
      .filter((item) => canAccessRoute(item.to))
      .map(addActive(location.pathname))
      .map(addOnClick((item) => navigate(item.to)))
  }

  const rawSections = useMemo(() => buildSections({ alertsNotification }), [alertsNotification])

  const sections: PureMenuSection[] = rawSections
    .map((section) => ({
      ...section,
      items: section.items
        .map((item) => {
          if (item.type === 'group') {
            return {
              ...item,
              items: handleItems(item.items),
              open: isOpen(section.title, item.name),
              onClick: () => toggle(section.title, item.name),
            }
          } else {
            return handleItems([item]).pop()
          }
        })
        .filter(NonNullable)
        .filter((item) => (item.type === 'group' ? item.items.length > 0 : true)),
    }))
    .filter((section) => section.items.length > 0)

  const sendEmail = () => (window.location.href = 'mailto:support@pubstack.io')

  const bottomItems: PureMenuItem[] = (
    [
      ...(canAccessRoute('/settings') ? [{ name: 'Settings', to: '/settings' }] : []),
      user?.isAdmin ? { name: 'Admin', to: '/admin/scopes' } : { name: 'Contact us', to: 'unused', onClick: sendEmail },
    ] as SimpleItem[]
  )
    .map(addActive(location.pathname))
    .map(addOnClick((item) => navigate(item.to)))

  useEffect(() => {
    sections.forEach((section) => {
      section.items.forEach((item) => {
        if (item.type === 'group' && item.items.some((i) => i.active)) {
          open(section.title, item.name)
        }
      })
    })
  }, [location])

  return <PureMenu sections={sections} bottomItems={bottomItems} />
}

export default Menu
