import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { DisplayedAdUnit, isDeviceEnabled } from '@pubstack/common/src/adunit'
import { SiteStacksByProperty } from '@pubstack/common/src/stack'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { Icon } from '~/components/Icon'
import { Qualifier } from '~/components/Qualifier'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'
import { StackUsage } from '../../StackUsage'
import { useAdFormat } from './AdFormatDisplay'
import { AdUnitMediatypesDetails } from './components/AdUnitMediatypesDetails'

type PureAdstackAdUnitDetailsFlyoutProps = WithClassName & {
  isOpen: boolean
  adUnit: DisplayedAdUnit
  sitesStacks?: SiteStacksByProperty
  onClose: () => unknown
  onEditAdUnit: (adunitId: string) => void
}

const Title = styled.h2`
  ${Fonts.H2}
  font-weight: 500;
`

const DetailsWrapper = styled.div`
  padding: 10px 0;
  max-width: 816px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  ${Fonts.P1}
  h2 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  ${Tooltip} {
    display: inline-flex;
  }
`
const AdUnitDetailWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const MediaTypesWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 16px;
`
const AdUnitDetailBloc = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
  width: 243px;
  h3 {
    ${Fonts.P2}
    color: ${Colors.SlateGrey};
    font-weight: normal;
  }
  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const IconWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 6px 0 10px 0;
`

const TextIcon = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`
const NoValueText = styled.div`
  color: ${Colors.Ash};
  font-style: italic;
  padding: 8px 0;
`
const FormatIcon = styled(Icon)`
  --format-icon-color: ${Colors.Turquoise};
`

const pipeSeparator = `\xa0 | \xa0`

const _PureAdstackAdUnitDetailsFlyout: FunctionComponent<PureAdstackAdUnitDetailsFlyoutProps> = ({ className, isOpen, adUnit, sitesStacks, onEditAdUnit, onClose }) => {
  const adFormat = useAdFormat(adUnit.adFormat?.id)
  return (
    <Flyout
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      title={adUnit.name}
      color={Colors.Turquoise}
      width={`816px`}
      actions={
        <Button
          variant={'secondary'}
          onClick={() => {
            onEditAdUnit(adUnit.id)
          }}
          iconName={'edit'}
          disabled={!adUnit.enabled}
        >
          Edit
        </Button>
      }
    >
      <DetailsWrapper>
        <Title>Identification</Title>
        <AdUnitDetailWrapper>
          <div>
            <AdUnitDetailBloc>
              <h3>Name</h3>
              <span>{adUnit.name}</span>
            </AdUnitDetailBloc>
          </div>
          <div>
            <AdUnitDetailBloc>
              <h3>Ad unit name in the ad server</h3>
              <span>{adUnit.adServerAdUnitName}</span>
            </AdUnitDetailBloc>
          </div>
          <div>
            <AdUnitDetailBloc>
              <h3>Technical identification (div ID)</h3>
              <span>{adUnit.divId}</span>
            </AdUnitDetailBloc>
          </div>
        </AdUnitDetailWrapper>
      </DetailsWrapper>
      <DetailsWrapper>
        <AdUnitDetailWrapper>
          <AdUnitDetailBloc>
            <Title>Devices</Title>
            <IconWrapper>
              <Qualifier
                iconName={'desktop'}
                tooltipText={isDeviceEnabled('desktop', adUnit.devices) ? 'Desktop enabled' : 'Desktop disabled'}
                color={Colors.Petrol}
                active={isDeviceEnabled('desktop', adUnit.devices)}
                enabled={adUnit.enabled}
              />
              <Qualifier
                iconName={'mobile'}
                tooltipText={isDeviceEnabled('mobile', adUnit.devices) ? 'Mobile enabled' : 'Mobile disabled'}
                color={Colors.Pool}
                active={isDeviceEnabled('mobile', adUnit.devices)}
                enabled={adUnit.enabled}
              />
            </IconWrapper>
          </AdUnitDetailBloc>
          <AdUnitDetailBloc>
            <Title>Format</Title>
            <IconWrapper>
              <TextIcon>
                <FormatIcon name={adFormat?.iconName ?? 'custom'} width={'34px'} height={'34px'} />
                <span>{adFormat?.name}</span>
              </TextIcon>
            </IconWrapper>
          </AdUnitDetailBloc>
          <AdUnitDetailBloc>
            <Title>Behaviour</Title>
            <IconWrapper>
              <Qualifier
                iconName={'dynamic'}
                tooltipText={adUnit.dynamicEnabled ? 'Dynamic enabled' : 'Dynamic disabled'}
                color={Colors.Success}
                active={adUnit.dynamicEnabled}
                enabled={adUnit.enabled}
              />
              <Qualifier
                iconName={'parallax'}
                tooltipText={adUnit.parallaxEnabled ? 'Parallax enabled' : 'Parallax disabled'}
                color={Colors.Success}
                active={adUnit.parallaxEnabled}
                enabled={adUnit.enabled}
              />
              <Qualifier iconName={'anchor'} tooltipText={adUnit.anchor ? 'Anchor enabled' : 'Anchor disabled'} color={Colors.Success} active={!!adUnit.anchor} enabled={adUnit.enabled} />
            </IconWrapper>
          </AdUnitDetailBloc>
        </AdUnitDetailWrapper>
      </DetailsWrapper>
      <DetailsWrapper>
        <Title>Max size filter</Title>
        <AdUnitDetailWrapper>
          <div>
            <AdUnitDetailBloc>
              <h3>Max width</h3>
              <span>
                {adUnit.containerMaxSize?.width ? (
                  `${adUnit.containerMaxSize.width} px`
                ) : (
                  <NoValueText
                    css={css`
                      padding: 0;
                    `}
                  >
                    Not defined
                  </NoValueText>
                )}
              </span>
            </AdUnitDetailBloc>
          </div>
          <div>
            <AdUnitDetailBloc>
              <h3>Max height</h3>
              <span>
                {adUnit.containerMaxSize?.height ? (
                  `${adUnit.containerMaxSize.height} px`
                ) : (
                  <NoValueText
                    css={css`
                      padding: 0;
                    `}
                  >
                    Not defined
                  </NoValueText>
                )}
              </span>
            </AdUnitDetailBloc>
          </div>
        </AdUnitDetailWrapper>
      </DetailsWrapper>
      <DetailsWrapper>
        <AdUnitDetailWrapper>
          <AdUnitDetailBloc>
            <Title>Demand source</Title>
            <IconWrapper>
              <Qualifier
                iconName={'header_bidding'}
                tooltipText={adUnit.headerBiddingEnabled ? 'Header bidding enabled' : 'Header bidding disabled'}
                color={Colors.Success}
                active={adUnit.headerBiddingEnabled}
                enabled={adUnit.enabled}
              />
            </IconWrapper>
          </AdUnitDetailBloc>
        </AdUnitDetailWrapper>
      </DetailsWrapper>
      <MediaTypesWrapper>
        <Title>Mediatypes</Title>
        <DetailsWrapper>
          <AdUnitDetailWrapper>
            <AdUnitMediatypesDetails adUnit={adUnit} />
          </AdUnitDetailWrapper>
        </DetailsWrapper>
      </MediaTypesWrapper>

      <Title>Key-values</Title>

      {adUnit.slotKeyValues && adUnit.slotKeyValues?.length > 0 ? (
        adUnit.slotKeyValues?.map((element) => (
          <DetailsWrapper key={element.key}>
            <AdUnitDetailBloc>
              <h3>Key</h3>
              <span>{element.key}</span>
            </AdUnitDetailBloc>
            <AdUnitDetailBloc
              css={css`
                width: 100%;
              `}
            >
              <h3>Values</h3>
              <span>{element.values.join(pipeSeparator)}</span>
            </AdUnitDetailBloc>
          </DetailsWrapper>
        ))
      ) : (
        <NoValueText>No key-values used.</NoValueText>
      )}

      <StackUsage sitesStacks={sitesStacks?.[adUnit.name]} usedInElement={'Ad unit'} />
    </Flyout>
  )
}

export const PureAdstackAdUnitDetailsFlyout = styled(_PureAdstackAdUnitDetailsFlyout)``
