import { User, useAuth0 } from '@auth0/auth0-react'
import { UserRole, UserViewMode, isPubstackUserRole, scopeAllFeatures } from '@pubstack/common/src/rolesAndFeatures'
import { AlertEmail } from '@pubstack/common/src/user'
/* user.hooks */
import { useMemo } from 'react'
import PbstckUser from './User'

type AugmentedUser = User & {
  'https://pubstack.io/user_metadata': {
    currentScopeId: string
    alertEmail: AlertEmail
  }
  'https://pubstack.io/app_metadata': {
    roles: UserRole
  }
}

export const useUser = (): PbstckUser | null => {
  const { user } = useAuth0<AugmentedUser>()
  const buildProfile = (user: AugmentedUser): PbstckUser => {
    const namespace = 'https://pubstack.io'
    let { roles: userRoles } = user[`${namespace}/app_metadata`] || { roles: [] }
    const { alertEmail } = user[`${namespace}/user_metadata`] || {}
    const currentScopeId = user[`${namespace}/user_metadata`].currentScopeId

    let view: UserViewMode | undefined
    if (isPubstackUserRole(userRoles) && userRoles.view) {
      view = userRoles.view
      userRoles = { [currentScopeId]: userRoles.view }
    }

    // pubstack features are auth0 permissions
    // they look like this: 'currentScopeId:feature:adx'
    const features: string[] = isPubstackUserRole(userRoles) ? scopeAllFeatures(currentScopeId) : user[`${namespace}/user_authorization`].permissions || []
    return new PbstckUser({
      profile: user,
      accessToken: user.accessToken,
      idToken: user.idToken,
      refreshToken: user.refreshToken,
      userRoles,
      currentScopeId,
      features,
      alertEmail,
      view,
    })
  }

  return useMemo(() => (user ? buildProfile(user) : null), [user])
}
