import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { OverviewMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect } from 'react'
import { useTimelineQueries } from '~/modules/analytics/overview/OverviewPage.hooks'
import { Context } from '~/state'
import { WithClassName } from '~/types/utils'
import { useLogger } from '~/utils/logger'
import { PureOverviewTimeline } from './PureOverviewTimeline'

type OverviewTimelineProps = WithClassName & {
  context: Context
  dimensions: Dimension[]
  currencySymbol: CurrencySymbol
}
const _OverviewTimeline: FunctionComponent<OverviewTimelineProps> = ({ className, context, dimensions, currencySymbol }) => {
  const logger = useLogger()
  const { timelineMain, timelineComparison, timelineLoadAll } = useTimelineQueries(context, dimensions)
  const timelineEmptyData: OverviewMetricsByTime = { epoch: [] }

  const onMetricChange = (metric: string) => {
    logger.info({ type: 'graph-action', action: 'click', graphName: 'overview-timeline', detail: metric })
  }

  useEffect(() => {
    timelineLoadAll()
  }, [context])

  return (
    <PureOverviewTimeline
      className={className}
      isLoading={timelineMain.loading || timelineComparison.loading}
      error={!!timelineMain.error && !!timelineComparison.error}
      empty={!timelineMain.loading && !timelineComparison.loading && !timelineMain.data?.epoch?.length}
      onRefreshClick={timelineLoadAll}
      data={timelineMain.data ?? timelineEmptyData}
      comparisonData={timelineComparison.data ?? timelineEmptyData}
      currencySymbol={currencySymbol}
      currentEpoch={DateTime.now()}
      onMetricChange={onMetricChange}
      timezone={context.timeRange.tz}
    />
  )
}

export const OverviewTimeline = styled(_OverviewTimeline)``
