import { cx } from '@emotion/css'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'
import { Color, ColorUtil, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevel } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import { WithClassName } from '~/types/utils'
import Button from './Button'

type WidthPx = `${number}px`
const flyoutEase = 'cubic-bezier(0.695, 0.0485, 0.34, 1)'

const flyoutIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  75% {
    opacity: 1;
    transform: translateX(0);
  }
`

const flyoutOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`

const flyoutFadeIn = keyframes`
  from {
    background-color: transparent;
  }
  to {
    background-color: ${ColorUtil.hexOpacity(Colors.Jet, 0.1)};
  }
`

const flyoutFadeOut = keyframes`
  from {
    background-color: ${ColorUtil.hexOpacity(Colors.Jet, 0.1)};
  }
  to {
    background-color: transparent;
  }
`

const FlyoutRoot = styled.div`
  display: flex;
  justify-content: flex-end;
  right: 0;
    
  width: 100%;
  height: 100%;
    
  position: absolute;
  overflow-x: hidden;
  overflow-y: visible;

  ${ElevationLevel.middle}

  &.flyout-open {
    animation: 0.15s ${flyoutEase} ${flyoutFadeIn} normal forwards;
    > * {
      animation: 0.25s ${flyoutEase} ${flyoutIn} normal forwards;
    }
  }
  &.flyout-close {
    animation: 0.1s ${flyoutEase} ${flyoutFadeOut} normal forwards;
    > * {
      animation: 0.2s ${flyoutEase} ${flyoutOut} normal forwards;
    }
  }
`

const FlyoutWrapper = styled.div<{ color?: Color; width?: WidthPx }>`
  display: grid;
  grid-template-areas: 
          'title title headerClose'
          'body body body'
          'actionClose actions actions';

  gap: 12px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
    
  background-color: ${Colors.White};
  width: ${({ width }) => width};
  position: relative;
  box-shadow: 0 0 20px rgba(54, 54, 54, 0.5);

  border-left: 6px solid;
  border-image-slice: 1;
  border-image-source: ${({ color }) => (color ? color : `linear-gradient(180deg, ${Colors.King} 0%, ${Colors.Pool} 100%)`)};

  padding: 20px 20px 12px;
`

const FlyoutBody = styled.div`
  grid-area: body;
  ${ScrollbarStyle};
  flex: 1;
  overflow-y: auto;
  padding-top: 8px;
`

const FlyoutActions = styled.div`
  grid-area: actions;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`
const FlyoutTitle = styled.div`
  grid-area: title;
  ${Fonts.H1}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-content: center;
`

const HeaderClose = styled(Button)`
    grid-area: headerClose;
`
const ActionClose = styled(Button)`
    grid-area: actionClose;
    margin: 4px 0;
    padding: 0 12px;
    ${Fonts.P1}}
`

type FlyoutProps = WithClassName & {
  color?: Color
  isOpen: boolean
  onRequestClose: () => unknown
  width?: WidthPx
}
const _Container: FunctionComponent<PropsWithChildren<FlyoutProps>> = ({ className, color, children, isOpen, onRequestClose, width = '600px' }) => {
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen)
  useEffect(() => {
    if (isOpen !== isOpenInternal) {
      if (isOpenInternal) {
        const timeout = setTimeout(() => setIsOpenInternal(isOpen), 200)
        return () => clearTimeout(timeout)
      } else {
        setIsOpenInternal(isOpen)
      }
    }
  }, [isOpen])

  return (
    isOpenInternal && (
      <FlyoutRoot onClick={onRequestClose} className={cx([className, isOpen ? 'flyout-open' : 'flyout-close'])}>
        <FlyoutWrapper onClick={(e) => e.stopPropagation()} color={color} width={width}>
          <HeaderClose variant={'tertiary'} onClick={onRequestClose} iconName={'close'} />
          <ActionClose variant={'tertiary'} onClick={onRequestClose} size={'s'}>
            Close
          </ActionClose>
          {children}
        </FlyoutWrapper>
      </FlyoutRoot>
    )
  )
}

const Container = styled(_Container)``

export const Flyout = {
  Content: Fragment,
  Title: FlyoutTitle,
  Body: FlyoutBody,
  Actions: FlyoutActions,
  Container,
}
