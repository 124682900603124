import styled from '@emotion/styled'
import { ControlCenterSite } from '@pubstack/common/src/controlCenter'
import { Stack, StackGroup } from '@pubstack/common/src/stack'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { Icon, IconName } from '~/components/Icon'
import { Spinner } from '~/components/Spinner'
import { ControlCenterViewModel } from '~/modules/adstack/inventory/controlCenter/PureAdStackControlCenterPage'
import { WithClassName } from '~/types/utils'
import { CollapsibleStackList } from './CollapsibleStackList'

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & ${Spinner} {
    width: 43px;
    height: 43px;
    margin-top: 10px;
  }

  & span {
    color: ${Colors.Jet};
    margin-top: 8px;
    font-weight: 500;
    ${Fonts.H2}
  }
`

const Divider = styled.div`
  margin-bottom: 12px;
  height: 1px;
  max-height: 1px;
  min-height: 1px;
  flex: 1;
  background-color: ${Colors.Platinum};
`

const SiteList = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.span`
  color: ${Colors.Hurricane};
  ${Fonts.P1}
`

const NoStacks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & ${Icon} {
    margin-top: 22px;
  }

  & ${Label} {
    margin-top: 7px;
    ${Fonts.H2}
    font-weight: bold;
  }
`

const ExtraEmptyDescription = styled.span`
  margin-top: 6px;
  ${Fonts.H2}
`

type ControlCenterSectionProps = WithClassName & {
  title: string
  icon: ReactNode
  description: ReactNode
  data: ControlCenterSite[]
  emptyIcon: ReactNode
  emptyDescription: ReactNode
  isLoading: boolean
  isReadOnly?: boolean
  extraEmptyDescription?: ReactNode
  actionLabel?: string
  actionIconName?: IconName
  onActionClick?: (sites: ControlCenterViewModel[]) => Promise<void>
  onDetailedView: (stack: Stack, stackGroup: StackGroup) => void
  confirmLabelModal?: string
  titleModal?: string
  descriptionModal?: ReactNode
  search?: string
  canAbTest?: boolean
}
const _ControlCenterSection: FunctionComponent<ControlCenterSectionProps> = ({
  isReadOnly = false,
  icon,
  title,
  description,
  data,
  emptyIcon,
  emptyDescription,
  extraEmptyDescription,
  isLoading,
  actionLabel,
  actionIconName,
  onActionClick,
  onDetailedView,
  confirmLabelModal,
  descriptionModal,
  titleModal,
  search,
  canAbTest,
  className,
}) => {
  return (
    <CollapsiblePanel
      className={className}
      title={
        <>
          {icon}
          <span>{title}</span>
        </>
      }
      isCollapsible={true}
      defaultState={'open'}
      canHideChildren={true}
    >
      <Divider />
      <SiteList>
        <Label>{description}</Label>
        {isLoading ? (
          <Loader>
            <Spinner isColored={true} />
            <span>Loading stacks...</span>
          </Loader>
        ) : data.length === 0 ? (
          <NoStacks>
            {emptyIcon}
            <Label>{emptyDescription}</Label>
            <ExtraEmptyDescription>{extraEmptyDescription}</ExtraEmptyDescription>
          </NoStacks>
        ) : (
          <CollapsibleStackList
            data={data}
            isReadOnly={isReadOnly}
            actionIconName={actionIconName}
            onActionClick={onActionClick}
            onDetailedView={onDetailedView}
            actionLabel={actionLabel}
            confirmLabelModal={confirmLabelModal}
            titleModal={titleModal}
            descriptionModal={descriptionModal}
            search={search}
            canAbTest={canAbTest}
          />
        )}
      </SiteList>
    </CollapsiblePanel>
  )
}

export const ControlCenterSection = styled(_ControlCenterSection)``
