interface ValidInput {
  valid: true
}

export interface InvalidInput {
  valid: false
  errorMessage: string
}

export const validInput: ValidInput = { valid: true }
export type InputValidation = ValidInput | InvalidInput

export const validateMatch = (value: string, toMatch: string): InputValidation => {
  if (value !== toMatch) {
    return {
      valid: false,
      errorMessage: `Passwords don't match`,
    }
  }
  return validInput
}

export const validatePassword = (value: string): InputValidation => {
  if (!RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\\\#?!@$%^&*-/€_"\\(\\)\\[\\]~\\{\\}]).{8,}$').test(value)) {
    return {
      valid: false,
      errorMessage: 'Use at least 8 characters with upper case letter, lower case letter, special character and number.',
    }
  }
  return validInput
}

export const validateRequired = (value?: string | number | null): InputValidation => {
  if (value === null || value === undefined || value === '') {
    return {
      valid: false,
      errorMessage: 'This field is required.',
    }
  }
  return validInput
}
// eslint-disable-next-line no-useless-escape
export const emailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const noSpaceQuoteAllowedRegExp = /^[^'"\s]+$/
export const noLeadingAndTrailingSpace = /^[\S]+(?:\s?[\S]+)*$/
export const noStartDigitAndSpaceValidation = /^\D[a-zA-Z0-9_]*$/
export const integerRegExp = RegExp('^[-0-9]+$')
export const integerAndCommaRegExp = RegExp('^[-0-9,]+$')
export const floatRegExp = RegExp('^[0-9]*[,.]?[0-9]+$')
export const positiveIntegerRegExp = /^[0-9]+$/
export const semanticVersionValidation = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/
/**
 * Validation regexp for DivId in adunit
 * Matches no spaces or quotes except if it starts with css: (css selector)
 */
export const divIdRegexp = /^(?:(?=css:).*|[^'"\s]+)$/

// eslint-disable-next-line no-useless-escape
export const NO_SPACE_OR_SPECIAL_CHAR_EXCEPT_HYPHEN_UNDERSCORE = /^[a-zA-Z0-9_\-]+$/

export const validateInteger = (value: string, fieldName: string): InputValidation => {
  if (!integerRegExp.test(value)) {
    return {
      valid: false,
      errorMessage: `${fieldName} should be only composed of digits`,
    }
  }
  return validInput
}

export const validateLength = (value: string, size: number): InputValidation => {
  if (value.length !== size) {
    return {
      valid: false,
      errorMessage: `Code should be ${size} characters long`,
    }
  }
  return validInput
}

export const validatePercentage = (percentage: string): InputValidation => {
  if (percentage === '') {
    return { valid: false, errorMessage: 'Percentage is required' }
  } else {
    if (!validateInteger(percentage, 'Percentage').valid) {
      return validateInteger(percentage, 'Percentage')
    }
    const parsedNumber = parseInt(percentage, 10)
    if (isNaN(parsedNumber)) {
      return { valid: false, errorMessage: 'Percentage must be a number' }
    } else if (!Number.isInteger(parsedNumber)) {
      return { valid: false, errorMessage: 'Percentage must be an integer' }
    } else if (parsedNumber > 100 || parsedNumber < 0) {
      return { valid: false, errorMessage: 'Percentage must be between 0 and 100' }
    }
    return validInput
  }
}
