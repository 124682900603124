import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${Colors.White};
  ${Fonts.P1}
  ${BorderRadius.style}
  padding: 16px 18px;
  box-sizing: border-box;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardIconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  ${Fonts.P0}
`

const CardBody = styled.div``

export type SettingStatusCardProps = WithClassName & { title: string; content: ReactNode; icon: IconName; active?: boolean }
const _SettingStatusCard: FunctionComponent<SettingStatusCardProps> = ({ className, title, content, icon, active }) => {
  return (
    <CardWrapper className={className}>
      <CardHeader>
        <CardIconTitle>
          <Icon name={icon} fill={Colors.Jet} />
          {title}
        </CardIconTitle>
        {active && <Icon name={'check'} fill={Colors.Success} />}
      </CardHeader>
      <CardBody>{content}</CardBody>
    </CardWrapper>
  )
}

export const SettingStatusCard = styled(_SettingStatusCard)``
