import { User as Auth0User } from '@auth0/auth0-react'
import { Feature, UserRole, UserViewMode, getScopeRole, hasFeature, isPubstackUserRole } from '@pubstack/common/src/rolesAndFeatures'
import { AlertEmail } from '@pubstack/common/src/user'

interface UserConfig {
  profile: Auth0User
  accessToken: string
  idToken: string
  refreshToken: string
  features: string[] // ['d5629df8-a263-4238-a3ab-efc2ececbe81:feature:adx']
  userRoles: UserRole // {'d5629df8-a263-4238-a3ab-efc2ececbe81': 'user'}
  currentScopeId: string // 'd5629df8-a263-4238-a3ab-efc2ececbe81'
  alertEmail?: AlertEmail
  view?: UserViewMode
}

export default class User {
  public profile: Auth0User
  public accessToken: string
  public idToken: string
  public refreshToken: string
  public features: string[]
  public userRoles: UserRole
  public currentScopeId: string
  public alertEmail?: AlertEmail
  public view?: UserViewMode

  constructor(config: UserConfig) {
    this.profile = config.profile
    this.accessToken = config.accessToken
    this.idToken = config.idToken
    this.refreshToken = config.refreshToken
    this.features = config.features
    this.userRoles = config.userRoles
    this.currentScopeId = config.currentScopeId
    this.alertEmail = config.alertEmail
    this.view = config.view
  }

  get isAdmin() {
    return isPubstackUserRole(this.userRoles)
  }
  getScopeRole() {
    return getScopeRole(this.currentScopeId, this.userRoles)
  }

  hasFeature(feature: Feature) {
    return hasFeature(this.currentScopeId, this.features, feature)
  }
}
