import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { HeaderBiddingRule } from '@pubstack/common/src/stack'
import { FunctionComponent, useEffect } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { PureAdStackEditPage } from '~/modules/adstack/PureAdStackEditPage'
import { WithClassName } from '~/types/utils'
import { BidderSelector } from './components/BidderSelector'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-width: 1000px;
  ${Fonts.P1}
  h2 {
    ${Fonts.H2}
    font-weight: 500;
  }

  ${_Input} {
    width: 394px;
    padding: 20px 0;
  }

  & > ${_Input} {
    margin-bottom: 12px;
  }
`

const Subtitle = styled.div`
  ${Fonts.P1}
  span {
    display: inline-flex;
    align-items: center;
  }
`

const FormButtonsWrapper = styled.div`
  text-align: right;
  ${Button} {
    margin-left: 15px;
  }
`

const Help = styled.div`
  ${Fonts.P2}
  ${Fonts.colors.SlateGrey}
  margin-left: 50px;
  margin-top: -4px;
`

const FormToggle = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 20px;

  & ${Help} {
    flex-basis: 100%;
  }
`

const ToggleWrapper = styled.div`
  padding-top: 2px;
`

const BlockListWrapper = styled.div`
  flex: auto;
  gap: 8px;
  flex-wrap: wrap;
`

const Wrapper = styled.span`
  display: block;
  div {
    display: inline-block;
    vertical-align: top;
    padding-top: 3px;
    margin-bottom: -3px;
  }
`

const DescriptionWrapper = styled.div`
  display: block;
  ${Fonts.P2}
  ${Fonts.colors.SlateGrey}
`

type PureAdStackHeaderBiddingEditPageProps = WithClassName & {
  headerBiddingRule?: HeaderBiddingRule
  onBack: () => void
  breadcrumbs: React.ReactNode
  onCreateHeaderBiddingRule: (headerBiddingRule: HeaderBiddingRule) => Promise<void>
  onUpdateHeaderBiddingRule: (headerBiddingRule: HeaderBiddingRule) => Promise<void>
  existingHeaderBiddingRules: HeaderBiddingRule[]
  selectableBidders: BidderAdapter[]
  isEditing: boolean
  isLoading: boolean
}

const _PureAdStackHeaderBiddingEditPage: FunctionComponent<PureAdStackHeaderBiddingEditPageProps> = ({
  className,
  headerBiddingRule,
  onBack,
  onCreateHeaderBiddingRule: onCreateHeaderBiddingRule,
  breadcrumbs,
  existingHeaderBiddingRules: existingHeaderBiddingRules,
  isEditing,
  onUpdateHeaderBiddingRule: onUpdateHeaderBiddingRule,
  isLoading,
  selectableBidders,
}) => {
  const defaultValues = headerBiddingRule ?? {
    name: '',
    bidderNames: [],
    isBlockList: true,
    scopeId: '',
  }
  const {
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
    control,
    reset,
    trigger,
  } = useForm<HeaderBiddingRule>({ mode: 'onChange', defaultValues })

  const { name, isBlockList, bidderNames } = useWatch({ control })

  const onBiddersSelection = (selectedBidders: BidderAdapter[]) => {
    setValue(
      'bidderNames',
      isBlockList ? selectableBidders.filter((b) => !selectedBidders.includes(b)).map((b) => b.code) : selectableBidders.filter((b) => selectedBidders.includes(b)).map((b) => b.code),
      { shouldDirty: true }
    )
  }

  const onBlockListtoggle = () => {
    setValue('isBlockList', !isBlockList, { shouldDirty: true })
    // toggling the isBlockList boolean inverts the content of bidderNames
    setValue(
      'bidderNames',
      selectableBidders.filter((b) => !bidderNames?.includes(b.code)).map((b) => b.code),
      { shouldDirty: true }
    )
    trigger()
  }

  const onSubmit: SubmitHandler<HeaderBiddingRule> = (newRule) => {
    const toEdit = { ...newRule }
    isEditing ? onUpdateHeaderBiddingRule(toEdit) : onCreateHeaderBiddingRule(toEdit)
  }

  const selectedBidders = isBlockList ? selectableBidders.filter((b) => !bidderNames?.includes(b.code)) : selectableBidders.filter((b) => bidderNames?.includes(b.code))

  useEffect(() => {
    reset(headerBiddingRule ? { ...headerBiddingRule } : undefined)
  }, [headerBiddingRule])

  return (
    <PureAdStackEditPage title={isEditing ? `Edit: ${headerBiddingRule?.name ?? ''}` : 'New bidder rule'} breadcrumbs={breadcrumbs} color={Colors.Forest} onBackClick={onBack}>
      {!isLoading && (
        <form className={className} id={'newRuleForm'} onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <Subtitle>
              <h2>Identification</h2>
            </Subtitle>
            <Input
              type={'text'}
              label={'Name'}
              control={control}
              name={'name'}
              error={errors.name?.message}
              rules={{
                required: { value: true, message: 'Name required.' },
                validate: {
                  value: (formValue) => {
                    return (formValue as string) !== headerBiddingRule?.name && existingHeaderBiddingRules.map((f) => f.name).includes(formValue as string)
                      ? 'A rule with this name already exists'
                      : true
                  },
                },
              }}
              maxLength={100}
            />
            <Subtitle>
              <h2>Bidders selection</h2>
            </Subtitle>
            <FormToggle>
              <ToggleWrapper>
                <Toggle id={'isBlockList'} onClick={onBlockListtoggle} value={isBlockList} />
              </ToggleWrapper>
              <BlockListWrapper>
                <Wrapper>
                  Add new bidders automatically
                  <Tooltip title={'When a bidder is activated, all stacks using the rule will be out-of-sync and will need to be updated and deployed again to use the new bidder.'} align={'start'}>
                    <Icon fill={Colors.Hurricane} width={'20px'} name={'info'} />
                  </Tooltip>
                </Wrapper>
                <DescriptionWrapper>When a bidder is activated in the integration, it will be added to this rule.</DescriptionWrapper>
              </BlockListWrapper>
            </FormToggle>
            <Wrapper>
              Select wanted bidders in the following list of active bidders
              <Tooltip title={'A bidder must be mapped with at least one ad unit to be there.'} align={'start'}>
                <Icon fill={Colors.Hurricane} width={'20px'} name={'info'} />
              </Tooltip>
            </Wrapper>
            <BidderSelector selectableBidders={selectableBidders} selectedBidders={selectedBidders} onBiddersSelection={onBiddersSelection} />
          </FormWrapper>
          <FormButtonsWrapper>
            <Button variant={'tertiary'} type={'button'} onClick={onBack}>
              Cancel
            </Button>
            <Button variant={'primary'} type={'submit'} disabled={!isDirty || !isValid}>
              Save rule
            </Button>
          </FormButtonsWrapper>
        </form>
      )}
    </PureAdStackEditPage>
  )
}

export const PureAdStackHeaderBiddingEditPage = styled(_PureAdStackHeaderBiddingEditPage)``
