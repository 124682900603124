import { useCallback, useState } from 'react'
import { createSearchParams } from 'react-router-dom'

export type QueryParams = {
  [key: string]: string
}

const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + qsValue

  window.history.pushState({ path: newUrl }, '', newUrl)
}

const setQueryString = (value: QueryParams) => {
  const paramsString = createSearchParams(value).toString()
  setQueryStringWithoutPageReload(`${paramsString ? '?' + paramsString : ''}`)
}

const parseLocation = (): QueryParams => {
  const params = new URLSearchParams(window.location.search)
  return Array.from(params.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as QueryParams)
}

export const useUrlSync = (): [QueryParams, (params: QueryParams) => void] => {
  const [value, setValue] = useState(parseLocation())
  const onSetValue = useCallback((newValue: QueryParams) => {
    setValue(newValue)
    setQueryString(newValue)
  }, [])

  return [value, onSetValue]
}
