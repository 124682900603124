import styled from '@emotion/styled'
import { ScopeUser } from '@pubstack/common/src/user'
import { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'
import { USER_ROLE_OPTIONS } from './SettingsMembersPage'

const EditMemberForm = styled.form`
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  & ${Button} {
    align-self: flex-start;
  }
`

const EditActionsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 24px;
`
type PureSettingsMembersPageEditFlyoutProps = WithClassName & {
  member: ScopeUser | undefined
  setMember: Dispatch<SetStateAction<ScopeUser | undefined>>
  onSaveChanges: (member: ScopeUser) => unknown
}
const _PureSettingsMembersPageEditFlyout: FunctionComponent<PureSettingsMembersPageEditFlyoutProps> = ({ member, setMember, onSaveChanges }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<ScopeUser>({
    defaultValues: member,
  })

  const saveChanges = (member: ScopeUser) => {
    onSaveChanges(member)
    setMember(undefined)
  }

  useEffect(() => {
    reset(member)
  }, [member])

  return (
    <Flyout
      color={SETTINGS_NAV_CONFIG.members.color}
      onClose={() => setMember(undefined)}
      title={member?.nickname}
      isOpen={!!member}
      actions={
        isDirty && (
          <EditActionsWrapper>
            <Button onClick={() => reset(member)} iconName={'restore'} variant={'tertiary'}>
              Restore
            </Button>
            <Button onClick={handleSubmit(saveChanges)}>Save changes</Button>
          </EditActionsWrapper>
        )
      }
    >
      {member && (
        <EditMemberForm>
          <Input readOnly disabled control={control} name={'nickname'} label={'Name'} />
          <Input readOnly disabled control={control} name={'email'} label={'E-mail address'} />
          <Select control={control} name={'role'} options={USER_ROLE_OPTIONS} label={'Role'} />
        </EditMemberForm>
      )}
    </Flyout>
  )
}

export const PureSettingsMembersPageEditFlyout = styled(_PureSettingsMembersPageEditFlyout)``
