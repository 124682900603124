import styled from '@emotion/styled'
import {
  GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST,
  GAM_DASHBOARD_NOT_APPLICABLE_AD_UNIT_LEVEL,
  GAM_DASHBOARD_OTHERS,
  GAM_DASHBOARD_UNMAPPED_ADUNIT,
  GAM_DASHBOARD_UNMAPPED_SITE,
  GamOverviewMetricsByTime,
} from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DateTime } from 'luxon'
import { FunctionComponent, useState } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { Color } from '~/assets/style/colors'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'
import { LegendProps } from '~/components/Legend'
import { TabProp, Tabs } from '~/components/Tabs'
import { TimelineMetricProps } from '~/components/TimelineMetric'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { eCPMObj, impressionCountObj, impressionRevenueObj, videoCompletionRate, videoViewershipTotalErrorCount, viewabilityMRCObj } from '~/modules/analytics/formulas'
import { sumObj } from '~/modules/analytics/formulas/operation-obj'
import { WithClassName } from '~/types/utils'
import { CHART_DATE_FORMAT_NO_HOUR } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { GAM_OVERVIEW_DIMENSION_COLORS, GAM_OVERVIEW_GENERAL_COLOR, GamOverviewDimensions } from '~/utils/gamOverview'

type PureGamOverviewTimelineProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    rawData: GamOverviewMetricsByTime
    currencySymbol: CurrencySymbol
    onMetricChange: (metric: string) => void
    dimension: GamOverviewDimensions
    displayVideoData: boolean
  }
type GamOverviewFormulas = typeof impressionRevenueObj | typeof impressionCountObj | typeof eCPMObj | typeof viewabilityMRCObj | typeof videoCompletionRate | typeof videoViewershipTotalErrorCount

type GamOverviewMetric = {
  name: string
  formula: GamOverviewFormulas
  seriesType: 'bars' | 'line'
  tooltipText?: keyof typeof ANALYTICS_TOOLTIPS
  percentage: boolean
  isVideo?: boolean
}

type InputDataToConvertForChart = {
  rawData: GamOverviewMetricsByTime
  currentFormula: GamOverviewMetric
  chartDataPoints: string[]
  currencySymbol: CurrencySymbol
  colors: { [key: string]: Color }
  type: 'percentage' | 'number'
}
const convertData = ({ rawData, currentFormula, chartDataPoints, currencySymbol, colors, type }: InputDataToConvertForChart) => {
  if (!rawData) return []

  return rawData.epoch.map((epoch, index) => {
    const result: (string | Date | number)[] = [
      DateTime.fromISO(epoch).toFormat('LLLL dd, yyyy'),
      getChartTooltip(DateTime.fromISO(epoch), currentFormula, rawData, index, chartDataPoints, currencySymbol, colors, type),
      ...Object.entries((type === 'percentage' ? currentFormula.formula.percentage : currentFormula.formula.compute)(rawData as any, index))
        .sort((a, b) => chartDataPoints.indexOf(a[0]) - chartDataPoints.indexOf(b[0]))
        .map((e) => e[1]),
    ]
    return result
  })
}

const filterObject = (object: { [key: string]: string | number }, keysToKeep: string[]): { [key: string]: number | string } => {
  return Object.keys(object)
    .filter((k) => keysToKeep.includes(k))
    .reduce<{ [key: string]: number | string }>((acc, current) => {
      acc[current] = object[current]
      return acc
    }, {})
}

const keysOrderByImpressionCpmSum = (rawData: GamOverviewMetricsByTime): string[] => {
  const firstFiveOrder = Object.entries(sumObj(rawData).impressionCpmSum)
    .filter((e) => e[0] !== GAM_DASHBOARD_OTHERS)
    .sort((a, b) => b[1] - a[1])
    .map((v) => v[0])
    .slice(0, 5)
  return Object.keys(rawData.mappings).length > 5 ? [...firstFiveOrder, GAM_DASHBOARD_OTHERS] : firstFiveOrder
}

const aggregateRawData = (rawData: GamOverviewMetricsByTime): GamOverviewMetricsByTime => {
  const keysToAggregate = keysOrderByImpressionCpmSum(rawData)
  // "aggregate" term is to do a parralel with aggregateRawDataValues method, in fact we just filter mapping + add an(other) one
  const aggregateRawDataMapping = (object: { [key: string]: string }, keysToKeep: string[]): { [key: string]: number | string } => {
    const data = filterObject(object, keysToKeep)
    if (keysToKeep.includes(GAM_DASHBOARD_OTHERS)) {
      data[GAM_DASHBOARD_OTHERS] = GAM_DASHBOARD_OTHERS // "Others" label is always at the end
    }
    return data
  }
  const aggregateRawDataValues = (object: { [key: string]: number }, keysToKeep: string[]): { [key: string]: number | string } => {
    const data = filterObject(object, keysToKeep)
    const othersValue = object[GAM_DASHBOARD_OTHERS] ?? 0
    if (keysToKeep.includes(GAM_DASHBOARD_OTHERS)) {
      data[GAM_DASHBOARD_OTHERS] = Object.keys(object)
        .filter((k) => !keysToKeep.includes(k))
        .reduce((acc, current) => {
          return acc + object[current]
        }, othersValue) // "Others" label is always at the end, with the sum of metrics not included in the order
    }
    return data
  }

  let clonedData = JSON.parse(JSON.stringify(rawData)) // TODO 2022-08-26 NRA VMA check to improve type
  clonedData = Object.entries<{ [key: string]: number }[]>(clonedData)
    .filter((entry) => entry[0] !== 'epoch' && entry[0] !== 'mappings')
    .reduce((acc, [currentKey, currentValue]) => {
      const metricsValues = currentValue.map((v) => aggregateRawDataValues(v, keysToAggregate))
      return { ...acc, [currentKey]: metricsValues }
    }, {})
  clonedData.mappings = aggregateRawDataMapping(rawData.mappings, keysToAggregate)
  clonedData.epoch = [...rawData.epoch]
  return clonedData
}

const getChartTooltip = (
  epoch: DateTime,
  metricConfig: GamOverviewMetric,
  rawData: GamOverviewMetricsByTime,
  index: number,
  chartDataPoints: string[],
  currencySymbol: CurrencySymbol,
  colors: { [p: string]: Color },
  type: 'percentage' | 'number'
) => {
  const chartData = (type === 'percentage' ? metricConfig.formula.percentage : metricConfig.formula.compute)(rawData as any, index)
  const total = metricConfig.formula.sum(rawData as any, index)
  const metrics: ChartTooltipMetricProps[][] = [
    chartDataPoints.map((label) => ({
      label: rawData.mappings[label],
      value: (type === 'percentage' ? metricConfig.formula.displayablePercentage : metricConfig.formula.displayable)(chartData[label], currencySymbol).toString(),
      iconColor: colors[label],
    })),
    [{ label: metricConfig.formula.name, value: metricConfig.formula.displayable(total, currencySymbol).toString(), iconName: 'sigma' }],
  ]
  return ReactDOMServer.renderToString(<ChartTooltip date={epoch.toFormat(CHART_DATE_FORMAT_NO_HOUR)} metrics={metrics} />)
}

export type BidderResponsesDataType = 'percentage' | 'number'
const metricsFormulas: GamOverviewMetric[] = [
  {
    name: 'REVENUE',
    formula: impressionRevenueObj,
    seriesType: 'bars',
    percentage: true,
    tooltipText: 'GAM_REVENUE',
  },
  {
    name: 'IMPRESSION_COUNT',
    formula: impressionCountObj,
    seriesType: 'bars',
    percentage: true,
  },
  {
    name: 'ECPM',
    formula: eCPMObj,
    seriesType: 'line',
    percentage: false,
    tooltipText: 'ECPM',
  },
  {
    name: 'VIEWABILITY',
    formula: viewabilityMRCObj,
    seriesType: 'line',
    percentage: false,
    tooltipText: 'VIEWABILITY',
  },
  {
    name: 'VIDEO_COMPLETION_RATE',
    formula: videoCompletionRate,
    seriesType: 'line',
    percentage: false,
    tooltipText: 'VIDEO_COMPLETION_RATE',
    isVideo: true,
  },
  {
    name: 'VIDEO_TOTAL_ERROR_COUNT',
    formula: videoViewershipTotalErrorCount,
    seriesType: 'line',
    percentage: false,
    tooltipText: 'VIDEO_ERROR_RATE',
    isVideo: true,
  },
]

const dataTypes: { type: BidderResponsesDataType; label: string }[] = [
  { type: 'number', label: '123' },
  { type: 'percentage', label: '%' },
]
const _PureGamOverviewTimeline: FunctionComponent<PureGamOverviewTimelineProps> = ({ rawData, onMetricChange, currencySymbol, dimension, displayVideoData, ...props }) => {
  const data: GamOverviewMetricsByTime = aggregateRawData(rawData)
  const [currentMetricIndex, setCurrentMetricIndex] = useState<number>(0)
  const [typeTab, setTypeTab] = useState(0)
  const type = dataTypes[typeTab].type
  const onTabClick = (tab: TabProp) => {
    const index = tabs.indexOf(tab)
    setTypeTab(index)
  }

  const currentMetric = metricsFormulas[currentMetricIndex]
  const tabs: TabProp[] = dataTypes.map((type, index) => ({
    label: type.label,
    active: typeTab === index,
    disabled: type.type === 'percentage' && !currentMetric.percentage,
  }))

  const metrics: TimelineMetricProps[] = metricsFormulas.map((metric, index) => ({
    active: currentMetric.formula.name === metric.formula.name,
    label: metric.formula.name,
    value: `${metric.formula.displayable(metric.formula.sum(data as any), currencySymbol)}`,
    notApplicable: !metric.formula.isComputable(data) || (metric.isVideo && !displayVideoData),
    onClick: () => {
      onMetricChange && onMetricChange(metric.name)
      if (!metric.percentage) {
        setTypeTab(0)
      }
      ;((!metric.isVideo && metric.formula.isComputable(data)) || displayVideoData) && setCurrentMetricIndex(index)
    },
    tooltipText: metric.tooltipText ? ANALYTICS_TOOLTIPS[metric.tooltipText] : undefined,
  }))

  const chartDataPoints = keysOrderByImpressionCpmSum(data)
  const colors = chartDataPoints
    .filter((d) => d !== GAM_DASHBOARD_OTHERS && d !== GAM_DASHBOARD_UNMAPPED_SITE && d !== GAM_DASHBOARD_UNMAPPED_ADUNIT && d !== GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST)
    .reduce<{ [key: string]: Color }>((acc, currentValue, index) => {
      return {
        ...acc,
        [currentValue]:
          (['pubstackDemandChannel', 'device'].includes(dimension) ? GAM_OVERVIEW_DIMENSION_COLORS[dimension][currentValue] : GAM_OVERVIEW_DIMENSION_COLORS[dimension][index]) ||
          GAM_OVERVIEW_GENERAL_COLOR.NotApplicable, // default color (safeguard)
      }
    }, {})
  if (data.mappings[GAM_DASHBOARD_OTHERS]) {
    colors[GAM_DASHBOARD_OTHERS] = GAM_OVERVIEW_GENERAL_COLOR.Others
  }
  if (data.mappings[GAM_DASHBOARD_UNMAPPED_ADUNIT]) {
    colors[GAM_DASHBOARD_UNMAPPED_ADUNIT] = GAM_OVERVIEW_GENERAL_COLOR.NotApplicable
  }
  if (data.mappings[GAM_DASHBOARD_UNMAPPED_SITE]) {
    colors[GAM_DASHBOARD_UNMAPPED_SITE] = GAM_OVERVIEW_GENERAL_COLOR.NotApplicable
  }
  if (data.mappings[GAM_DASHBOARD_NOT_APPLICABLE_AD_UNIT_LEVEL]) {
    colors[GAM_DASHBOARD_NOT_APPLICABLE_AD_UNIT_LEVEL] = GAM_OVERVIEW_GENERAL_COLOR.NotApplicable
  }
  if (data.mappings[GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST]) {
    colors[GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST] = GAM_OVERVIEW_GENERAL_COLOR.NotApplicable
  }

  const chartOptions = {
    ...AnalyticsDefaultChartOptions,
    colors: chartDataPoints.map((i) => colors[i]),
    seriesType: currentMetric.seriesType,
    isStacked: true,
    vAxis: type === 'percentage' ? { ...AnalyticsDefaultChartOptions.vAxis, format: "#'%'", minValue: 0 } : { ...AnalyticsDefaultChartOptions.vAxis },
  }

  const chartData = [
    [
      'Date',
      {
        type: 'string',
        role: 'tooltip',
        p: { html: true },
      },
      ...chartDataPoints,
    ],
    ...convertData({ rawData: data, currentFormula: currentMetric, chartDataPoints, currencySymbol, colors, type }),
  ]
  const legends: LegendProps[] = chartDataPoints.map((k) => ({ iconColor: colors[k], label: data.mappings[k] }))

  return (
    <AnalyticsChartWidget
      {...props}
      icon={'chart_bar'}
      title={'Performance'}
      metrics={metrics}
      legends={legends}
      chart={{ chartType: 'ComboChart', options: chartOptions, data: rawData ? chartData : undefined }}
      info={<Tabs tabs={tabs} fluid={false} onClick={onTabClick} />}
    />
  )
}

export const PureGamOverviewTimeline = styled(_PureGamOverviewTimeline)``
