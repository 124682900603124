import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { WithClassName } from '~/types/utils'

const PetalColors = ['#1db19d', '#50a9df', '#8b6aac', '#afcc46']
type SpinnerProps = { isColored?: boolean } & WithClassName

const Svg = styled.svg`
  width: 24px;
  height: 24px;

  animation: 1.2s cubic-bezier(0.19, 0.64, 0.82, 0.45) infinite anim;
  @keyframes anim {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const _Spinner: FunctionComponent<SpinnerProps> = ({ className, isColored }) => {
  return (
    <Svg viewBox={'0 0 32 32'} xmlns={'http://www.w3.org/2000/svg'} className={className} fill={'currentColor'}>
      <path
        d={'m7.8066 30.992c-1.34443-1.3445-1.34443-3.526 0-4.8704l5.7582-5.7583c1.3445-1.3444 3.526-1.3444 4.8704 0s1.3444 3.526 0 4.8704l-5.7582 5.7583c-1.3444 1.3444-3.52597 1.3444-4.8704 0z'}
        fill={isColored ? PetalColors[0] : undefined}
      />
      <path
        d={
          'm1.00832 7.80667c1.34443-1.34444 3.52596-1.34444 4.87039 0l5.78359 5.75823c1.3444 1.3444 1.3444 3.526 0 4.8704-1.3444 1.3445-3.52596 1.3445-4.87039 0l-5.78359-5.7836c-1.344427-1.3191-1.344427-3.5006 0-4.84503z'
        }
        fill={isColored ? PetalColors[1] : undefined}
      />
      <path
        d={
          'm20.3377 13.6157c1.3445-1.3444 3.526-1.3444 4.8704 0l5.7836 5.7837c1.3444 1.3444 1.3444 3.5259 0 4.8704-1.3444 1.3444-3.526 1.3444-4.8704 0l-5.7836-5.7836c-1.3444-1.3445-1.3444-3.526 0-4.8705z'
        }
        fill={isColored ? PetalColors[2] : undefined}
      />
      <path
        d={
          'm13.5648 11.7385c-1.3444-1.3445-1.3444-3.52602 0-4.87046l5.8597-5.85971c1.3445-1.344439 3.526-1.344439 4.8704 0 1.3444 1.34443 1.3444 3.52597 0 4.87041l-5.8597 5.85976c-1.3444 1.3444-3.5259 1.3444-4.8704 0z'
        }
        fill={isColored ? PetalColors[3] : undefined}
      />
    </Svg>
  )
}

export const Spinner = styled(_Spinner)``
