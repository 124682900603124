import styled from '@emotion/styled'
import { FC, PropsWithChildren, ReactElement, useEffect, useState } from 'react'
import { ArrowContainer, Popover, PopoverAlign, PopoverPosition } from 'react-tiny-popover'
import { useEventListener } from 'usehooks-ts'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevelValues } from '~/assets/style/tokens'
import Button from '~/components/Button'
import Chip from '~/components/Chip'
import { WithClassName } from '~/types/utils'

type TooltipProps = WithClassName & {
  sizes: string[]
  onSizeClick: (size: string) => void
  onAddAllSizes: (sizes: string[]) => void
  onClose: () => void
  align?: PopoverAlign
  positions?: PopoverPosition[]
  reposition?: boolean
  offset?: number
  open: boolean
}

const TooltipContent = styled.div`
  background: ${Colors.Ghost};
  padding: 10px;
  border-radius: 2px;
  border: 1px solid ${Colors.Platinum};
  ${Fonts.P2}

  display: flex;
  flex-direction: column;
  gap: 6px;

  justify-content: start;

  & .recommended-sizes-popover-arrow {
    fill: ${Colors.Ghost};
  }
`

const Actions = styled.div`
  display: flex;
  gap: 6px;
  justify-content: space-between;
  align-items: center;
`

const SizeChips = styled.div`
  display: inline-flex;
  gap: 2px;
`
const _SizeRecommendationPopover: FC<PropsWithChildren<TooltipProps>> = ({ className, sizes, onAddAllSizes, onSizeClick, onClose, children, align, positions, reposition, offset = 0, open }) => {
  const [key, setKey] = useState('')
  const [internalOpen, setInternalOpen] = useState(open || false)

  useEffect(() => {
    setInternalOpen(open)
    if (!open) {
      onClose()
    }
  }, [open])

  const randomKey = () => {
    setKey(`${Math.random()}`)
  }
  useEventListener('resize', randomKey)

  return (
    <Popover
      containerStyle={{ zIndex: `${ElevationLevelValues.high}` }}
      isOpen={internalOpen}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer position={position} childRect={childRect} popoverRect={popoverRect} arrowColor={'transparent'} arrowSize={10}>
          <TooltipContent className={className}>
            We recommend to use the following sizes for this format :
            <Actions>
              <SizeChips>
                {sizes.map((size, i) => {
                  return <Chip text={size} color={Colors.Sora} iconRight={'plus'} onRightIconClick={() => onSizeClick(size)} iconLeft={'stars'} key={i} />
                })}
              </SizeChips>
              <Button onClick={() => onAddAllSizes(sizes)} size="s" variant="tertiary" iconName="add_circle">
                Add all sizes
              </Button>
            </Actions>
          </TooltipContent>
        </ArrowContainer>
      )}
      reposition={reposition ?? false}
      positions={positions || ['top', 'bottom', 'left', 'right']}
      onClickOutside={() => {
        setInternalOpen(false)
        onClose()
      }}
      clickOutsideCapture
      align={align ?? 'center'}
      padding={offset}
      key={key}
    >
      {children as ReactElement}
    </Popover>
  )
}

export const SizeRecommendationPopover = styled(_SizeRecommendationPopover)``
