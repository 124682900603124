import { GamNetworkConfig } from '@pubstack/common/src/gam/adx'
import { AdminScope } from '@pubstack/common/src/scope'
import { SnoozeAlarm } from '@pubstack/common/src/snoozeAlarm'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useAdminScopes, useGamNetwork, useSnoozeAlarm } from '~/api/admin-api.hook'
import { Skeleton } from '~/components/Skeleton'
import { useToast } from '~/components/Toast/useToasts'
import { PureAdminSnoozeAlarm } from './PureAdminSnoozeAlarm'

export type Ressources = {
  scopes: Pick<AdminScope, 'name' | 'id'>[]
  gamNetworks: Pick<GamNetworkConfig, 'id'>[]
}

export const AdminSnoozeAlarm: FunctionComponent = () => {
  const [snoozeAlarms, setSnoozeAlarms] = useState<SnoozeAlarm[]>([])
  const [ressources, setRessources] = useState<Ressources>({ scopes: [], gamNetworks: [] })
  const { all: allSnoozeAlarm, byId: snoozeAlarmById } = useSnoozeAlarm(null)
  const { all: adminScopes, byId: adminScopeById } = useAdminScopes()
  const { all: allGamNetwork } = useGamNetwork()
  const toast = useToast()

  const loadSnoozeAlarm = async () => {
    if (allSnoozeAlarm.loading) {
      allSnoozeAlarm.abort()
    }
    try {
      const res = await allSnoozeAlarm.get()
      setSnoozeAlarms(res)
    } catch (e) {
      toast.alert('An error occurred while fetching the snoozed alarm')
    }
  }
  const loadScopeAndGamNetwork = async () => {
    if (adminScopeById.loading || allGamNetwork.loading) {
      adminScopeById.abort()
      allGamNetwork.abort()
    }
    try {
      const scopes = (await adminScopes.get()).map((t) => ({ name: t.name, id: t.id }))
      const gamNetworks = (await allGamNetwork.get()).map((t) => ({ id: t.id }))
      setRessources({ scopes, gamNetworks })
    } catch (e) {
      toast.alert('An error occurred while fetching the snoozed alarm')
    }
  }

  useEffect(() => {
    loadSnoozeAlarm()
    loadScopeAndGamNetwork()
  }, [])

  const createSnoozeAlarm = async (type: string, resourceId: string) => {
    if (snoozeAlarmById.loading) {
      snoozeAlarmById.abort()
    }
    try {
      await snoozeAlarmById.post({ type, resourceId, expirationTime: DateTime.now().plus({ days: 8 }).toISO() })
      await loadSnoozeAlarm()
    } catch (e) {
      toast.alert('An error occurred when creating the snoozed alarm')
    }
  }

  const deleteSnoozeAlarm = async (id: string) => {
    if (snoozeAlarmById.loading) {
      snoozeAlarmById.abort()
    }
    try {
      await snoozeAlarmById.del(id)
      await loadSnoozeAlarm()
    } catch (e) {
      toast.alert('An error occurred when deleting the snoozed alarm')
    }
  }

  return allSnoozeAlarm.loading ? (
    <Skeleton bigger width={'100%'} />
  ) : (
    <PureAdminSnoozeAlarm onRefreshClick={loadSnoozeAlarm} snoozeAlarms={snoozeAlarms} deleteSnoozeAlarm={deleteSnoozeAlarm} createSnoozeAlarm={createSnoozeAlarm} ressources={ressources} />
  )
}
