import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'

const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

type PureSettingsRefreshExceptionsDeleteFileModalProps = WithClassName & { fileName: string; onValidate: () => unknown }
const _PureSettingsRefreshExceptionsDeleteFileModal: FunctionComponent<PureSettingsRefreshExceptionsDeleteFileModalProps> = ({ fileName, onValidate }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Remove list</Modal.Title>

      <Modal.Body>
        <Message>
          <div>
            You are about to remove <strong>{fileName}</strong>
          </div>
          <div>Do you confirm ?</div>
        </Message>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button
          variant={'negative'}
          onClick={() => {
            close()
            onValidate()
          }}
        >
          Remove list
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

export const PureSettingsRefreshExceptionsDeleteFileModal = styled(_PureSettingsRefreshExceptionsDeleteFileModal)``
