import { SerializedStyles, css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, FC, MouseEventHandler, useRef, useState } from 'react'
import { useHover } from 'usehooks-ts'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevel, Sizes } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

type ButtonVariant = 'primary' | 'secondary' | 'negative' | 'tertiary'
type ButtonStates = 'default' | 'hover' | 'active'
type ButtonSize = 's' | 'm'

const ButtonVariantDefaultTextColor: { [key in ButtonVariant]: SerializedStyles } = {
  primary: Fonts.colors.White,
  secondary: Fonts.colors.King,
  tertiary: Fonts.colors.King,
  negative: Fonts.colors.Alert,
}

const ButtonVariantBackground: { [key in ButtonVariant]: { [prop in ButtonStates]: SerializedStyles } } = {
  primary: {
    active: css`
      background-color: ${Colors.Navy};
    `,
    default: css`
      background-color: ${Colors.King};
    `,
    hover: css`
      background-color: ${Colors.Cobalt};
    `,
  },
  secondary: {
    active: css`
      background-color: ${Colors.Navy};
    `,
    default: css`
      background-color: ${Colors.White};
    `,
    hover: css`
      background-color: ${Colors.Topaze};
    `,
  },
  tertiary: {
    active: css`
      background-color: ${Colors.Navy};
    `,
    default: css`
      background-color: transparent;
    `,
    hover: css`
      background-color: ${Colors.Topaze};
    `,
  },
  negative: {
    active: css`
      background-color: ${Colors.Cherry};
    `,
    default: css`
      background-color: ${Colors.White};
    `,
    hover: css`
      background-color: ${Colors.Mew};
    `,
  },
}

const ButtonVariantStyles: { [key in ButtonVariant]: SerializedStyles } = {
  primary: css`
    ${ButtonVariantBackground['primary'].default}
    ${ButtonVariantDefaultTextColor['primary']}
    :hover:enabled {
      ${ButtonVariantBackground['primary'].hover}
    }
    :active:enabled {
      ${Fonts.colors.White}
      ${ButtonVariantBackground['primary'].active}
    }
  `,
  secondary: css`
    ${ButtonVariantBackground['secondary'].default}
    ${ButtonVariantDefaultTextColor['secondary']}
    border-color: ${Colors.King};
    :hover:enabled {
      ${ButtonVariantBackground['secondary'].hover}
    }
    :active:enabled {
      ${Fonts.colors.White}
      ${ButtonVariantBackground['secondary'].active}
    }
  `,
  tertiary: css`
    ${ButtonVariantDefaultTextColor['tertiary']}
    ${ButtonVariantBackground['tertiary'].default}
    border-color: transparent;
    :hover:enabled {
      ${Fonts.colors.King}
      ${ButtonVariantBackground['tertiary'].hover}
    }
    :active:enabled {
      ${Fonts.colors.White}
      ${ButtonVariantBackground['tertiary'].active}
    }
  `,
  negative: css`
    ${ButtonVariantDefaultTextColor['negative']}
    ${ButtonVariantBackground['negative'].default}
    border-color: ${Colors.Alert};
    :hover:enabled {
      ${ButtonVariantBackground['negative'].hover}
    }
    :active:enabled {
      border-color: ${Colors.Cherry};
      ${Fonts.colors.White}
      ${ButtonVariantBackground['negative'].active}
    }
  `,
}

const getButtonSize = (variant: ButtonVariant, size: ButtonSize): SerializedStyles => {
  switch (size) {
    case 's':
      return css`
        ${Fonts.P2}
        padding: 0px 6px;
      `
    case 'm':
      if (variant === 'tertiary') {
        return css`
          ${Fonts.P1}
          padding: 6px 12px;
        `
      }
      return css`
        ${Fonts.P1}
        padding: 9px 11px;
      `
  }
}

type MouseEventInfo = { type?: MouseEvent['type']; isRippling: boolean; x: number; y: number; height: number; width: number }

const rippleAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0.2;
  }
`
const rippleDuration = 250

const NormalButtonStyle = css`
  display: inline-flex;
  align-items: center;
  & > * + * {
    margin-left: 4px;
  }
`

const IconButtonStyle = ({ iconSize, variant }: { iconSize: string; variant: ButtonVariant }) => css`
  padding: ${variant === 'tertiary' ? '6px' : '9px'};
  height: ${iconSize};
  min-height: ${iconSize};
  max-height: ${iconSize};
  width: ${iconSize};
  min-width: ${iconSize};
  max-width: ${iconSize};

  ${
    variant === 'tertiary' &&
    css`
    border: none;
    border-radius: 50%;
  `
  };
`

const ButtonWrapper = styled.button<{ variant: ButtonVariant; iconOnly: boolean; iconSize: string; mouseEventInfo?: MouseEventInfo; iconSpin?: boolean; size: ButtonSize }>`
  ${({ variant, size }) => getButtonSize(variant, size)}
  font-weight: bold;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  ${({ variant }) => ButtonVariantStyles[variant]}
  ${({ variant, iconOnly, iconSize }) => (iconOnly ? IconButtonStyle({ iconSize, variant }) : NormalButtonStyle)}
  box-sizing: content-box;
  transition-property: color, background-color;
  transition-duration: ${rippleDuration / 4}ms, ${rippleDuration / 2}ms;
  transition-delay: 0ms, ${rippleDuration / 2}ms;
  position: relative;

  :disabled {
    ${Fonts.colors.Silver}
    background-color: ${({ variant }) => (variant === 'tertiary' ? 'transparent' : Colors.Platinum)};
    cursor: not-allowed;
    border-color: transparent;
  }

  & * {
    pointer-events: none;
  }

  ${({ iconSpin }) =>
    iconSpin &&
    css`
      & ${Icon} {
        animation: 1.2s cubic-bezier(0.19, 0.64, 0.82, 0.45) infinite anim;
        @keyframes anim {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    `}

  ${({ mouseEventInfo: clickInfo, variant }) =>
    clickInfo?.isRippling &&
    css`
      overflow: hidden;

      ${clickInfo.type === 'mousedown' ? Fonts.colors.White : ButtonVariantDefaultTextColor[variant]}

      & > *, & > ${Icon} svg {
        position: relative;
        ${ElevationLevel.low}
      }

      &::after {
        content: '';
        height: ${clickInfo.width * 2}px;
        width: ${clickInfo.width * 2}px;
        display: block;
        position: absolute;
        top: ${clickInfo.y - clickInfo.width}px;
        left: ${clickInfo.x - clickInfo.width}px;
        border-radius: 100%;
        animation: ${rippleAnimation} ${rippleDuration}ms ease-in;
        ${clickInfo.type === 'mousedown' ? ButtonVariantBackground[variant].active : ButtonVariantBackground[variant].hover}
      }
    `}
`

type ButtonProps = WithClassName &
  ComponentProps<'button'> & {
    variant?: ButtonVariant
    iconName?: IconName
    iconSize?: string
    iconSpin?: boolean
    isHover?: boolean
    size?: ButtonSize
  }
const _Button: FC<ButtonProps> = ({ variant = 'primary', children, className, iconName, iconSize, onMouseEnter, iconSpin, isHover: forceHover, type = 'button', size = 'm', ...props }) => {
  const iconOnly = !!iconName && !children
  const defaultIconSize = size === 's' ? Sizes[16] : iconOnly ? Sizes[26] : Sizes[20]

  const buttonRef = useRef<HTMLButtonElement>(null)
  const isHover = useHover(buttonRef)
  const [mouseEventInfo, setMouseEventInfo] = useState<MouseEventInfo>({ isRippling: false, x: 0, y: 0, height: 0, width: 0 })

  //TODO tmu 2022-10-17 fix events boucing (state machine maybe?)
  const handleMouseEvent: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (event.type === 'mousedown' || event.type === 'mouseenter') {
      const rect = buttonRef.current?.getBoundingClientRect()
      if (rect && !mouseEventInfo.isRippling) {
        const buttonClickPosition = { x: event.clientX - rect.x, y: event.clientY - rect.y }
        setMouseEventInfo({ type: event.type, isRippling: true, ...buttonClickPosition, height: rect.height, width: rect.width })
        setTimeout(() => {
          setMouseEventInfo({ type: event.type, isRippling: false, x: 0, y: 0, height: 0, width: 0 })
        }, rippleDuration)
      }
    }
  }

  return (
    <ButtonWrapper
      ref={buttonRef}
      {...props}
      onMouseEnter={(event) => {
        handleMouseEvent(event)
        onMouseEnter && onMouseEnter(event)
      }}
      onMouseDown={(event) => {
        handleMouseEvent(event)
      }}
      mouseEventInfo={mouseEventInfo}
      iconOnly={iconOnly}
      iconSize={iconSize ?? defaultIconSize}
      variant={variant}
      className={className}
      iconSpin={iconSpin}
      type={type}
      size={size}
    >
      {iconName && <Icon width={iconSize ?? defaultIconSize} name={iconName} isHover={forceHover ?? isHover} />}
      {children && <span>{children}</span>}
    </ButtonWrapper>
  )
}

const Button = styled(_Button)<ButtonProps>``
export default Button
