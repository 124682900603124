import styled from '@emotion/styled'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { noSpaceQuoteAllowedRegExp } from '@pubstack/common/src/input'
import { ConditionGroup, StackContext } from '@pubstack/common/src/stackContext'
import { FunctionComponent, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Input, _Input } from '~/components/Input'
import { PureAdStackEditPage } from '~/modules/adstack/PureAdStackEditPage'
import { WithClassName } from '~/types/utils'
import { ContextConditionsForm } from './ContextConditionsForm'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-width: 1000px;
  ${Fonts.P1}
  h2 {
    ${Fonts.H2}
    font-weight: 500;
  }

  & > ${_Input} {
    width: 292px;
    margin-bottom: 36px;
    padding: 20px 0;
  }
`

const Subtitle = styled.div`
  ${Fonts.P1}
  span {
    display: inline-flex;
    align-items: center;
  }
`

const FormButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px;
  ${Button} {
    margin-left: 15px;
  }
`

type PureAdStackContextEditPageProps = WithClassName & {
  isEditing: boolean
  context: StackContext
  contextKeys: ContextKey[]
  onBack: () => void
  breadcrumbs: React.ReactNode
  createContext: (context: StackContext) => Promise<void>
}

const _PureAdStackContextEditPage: FunctionComponent<PureAdStackContextEditPageProps> = ({ className, context, contextKeys, isEditing, onBack, createContext, breadcrumbs }) => {
  const defaultConditions: ConditionGroup = {
    ...context?.conditions,
    elements: context.conditions.elements.length ? context.conditions.elements : [{ op: 'IN', key: '', values: [] }],
  }
  const {
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    control,
  } = useForm<StackContext>({ defaultValues: { ...context, conditions: defaultConditions }, mode: 'onChange' })

  useEffect(() => {
    reset({
      ...context,
      conditions: defaultConditions,
    })
  }, [context])

  const onSubmit: SubmitHandler<StackContext> = (newContext) => {
    createContext(newContext)
  }

  return (
    <PureAdStackEditPage title={isEditing ? 'Edit context' : 'New context'} breadcrumbs={breadcrumbs} color={Colors.Violet} onBackClick={onBack}>
      <form className={className} id={'newContextForm'} onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <Subtitle>
            <h2>Name</h2>
          </Subtitle>
          <Input
            type={'text'}
            label={'Name'}
            control={control}
            name={'name'}
            error={errors.name?.message}
            rules={{
              required: { value: true, message: 'Name required.' },
              pattern: { value: noSpaceQuoteAllowedRegExp, message: 'Quotes and spaces are not allowed.' },
            }}
            disabled={isEditing}
            helper={!isEditing && 'Cannot be edited later.'}
            maxLength={100}
          />
          <Subtitle>
            <h2>Conditions</h2>
          </Subtitle>
          <ContextConditionsForm control={control} errors={errors} contextKeys={contextKeys} onKeyChange={(index) => resetField(`conditions.elements.${index}.values`)} />
        </FormWrapper>
        <FormButtonsWrapper>
          <Button variant={'tertiary'} onClick={onBack}>
            Cancel
          </Button>
          {isEditing ? (
            <Button variant={'primary'} type={'submit'}>
              Update
            </Button>
          ) : (
            <Button variant={'primary'} type={'submit'}>
              Validate
            </Button>
          )}
        </FormButtonsWrapper>
      </form>
    </PureAdStackEditPage>
  )
}

export const PureAdStackContextEditPage = styled(_PureAdStackContextEditPage)``
