import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { MappedName } from '@pubstack/common/src/analytics/query'
import { sameDay } from '@pubstack/common/src/date'
import { Feature } from '@pubstack/common/src/rolesAndFeatures'
import { DateTime, Interval } from 'luxon'
import type { SetterOrUpdater } from 'recoil'
import { Color, DataColors } from '~/assets/style/colors'
import User from '~/auth/User'
import { IconName } from '~/components/Icon'
import {
  bidCpm,
  bidRate,
  bidResponses,
  bidRpm,
  bidTimeoutRate,
  bidTimeouts,
  bidWinCpm,
  bidWinRpm,
  hbECPM,
  hbImpressionRevenue,
  hbWinningCpmSum,
  impressionRpm,
  noBid,
  noBidRate,
} from '~/modules/analytics/formulas'
import { Context, Filter } from '~/state'
import { FilterCategory } from '~/types/analytics'
import { Logger } from './logger'

export const getFilterSidebarContent = (): FilterCategory[] => {
  return [
    { dimension: 'tagId', title: 'Sites', icon: 'site', open: false, expanded: false, loading: false },
    { dimension: 'bidder', title: 'Bidders', icon: 'bidder', open: false, expanded: false, loading: false },
    { dimension: 'adUnit', title: 'Ad units', icon: 'picture_in_picture', open: false, expanded: false, loading: false },
    { dimension: 'pubstackDemandChannel', title: 'Demand channels', icon: 'bidder', open: false, expanded: false, loading: false },
    { dimension: 'mediaType', title: 'Media type', icon: 'video', open: false, expanded: false, loading: false },
    { dimension: 'device', title: 'Devices', icon: 'device', open: false, expanded: false, loading: false },
    { dimension: 'country', title: 'Countries', icon: 'position', open: false, expanded: false, loading: false },
    { dimension: 'size', title: 'Sizes', icon: 'size', open: false, expanded: false, loading: false },
    { dimension: 'pubstackRefresh', title: 'Pubstack Refresh', icon: 'refresh', open: false, expanded: false, loading: false },
    { dimension: 'abTestPopulation', title: 'A/B test', icon: 'population_split', open: false, expanded: false, loading: false },
    { dimension: 'stackIdVersion', title: 'Stacks', icon: 'ad_stack', open: false, expanded: false, loading: false },
    { dimension: 'adUnitLevel1', title: 'Ad unit path: Level 1', icon: 'path', open: false, expanded: false, loading: false },
    { dimension: 'adUnitLevel2', title: 'Ad unit path: Level 2', icon: 'path', open: false, expanded: false, loading: false },
    { dimension: 'adUnitLevel3', title: 'Ad unit path: Level 3', icon: 'path', open: false, expanded: false, loading: false },
    { dimension: 'adUnitLevel4', title: 'Ad unit path: Level 4', icon: 'path', open: false, expanded: false, loading: false },
    { dimension: 'adUnitLevel5', title: 'Ad unit path: Level 5', icon: 'path', open: false, expanded: false, loading: false },
  ]
}

export type TabConfig = {
  dimension: Dimension
  label: string
  iconName: IconName
}

export const ANALYTICS_TABS_CONFIG: TabConfig[] = [
  { dimension: 'tagId', label: 'Sites', iconName: 'site' },
  { dimension: 'bidder', label: 'Bidders', iconName: 'bidder' },
  { dimension: 'adUnit', label: 'Ad units', iconName: 'picture_in_picture' },
  { dimension: 'device', label: 'Devices', iconName: 'device' },
  { dimension: 'country', label: 'Countries', iconName: 'position' },
  { dimension: 'size', label: 'Sizes', iconName: 'size' },
  { dimension: 'pubstackRefresh', label: 'Pubstack Refresh', iconName: 'refresh' },
  { dimension: 'abTestPopulation', label: 'A/B Test', iconName: 'population_split' },
  { dimension: 'stackIdVersion', label: 'Stacks', iconName: 'ad_stack' },
]

export const ANALYTICS_CHART_COLOR_CONFIG: { [key: string]: { title: string; color: Color } } = {
  hb: { title: 'Prebid', color: DataColors.Ming },
  adx: { title: 'AdX', color: DataColors.Pool },
  openBidding: { title: 'Open Bidding', color: DataColors.Lilac },
  auction: { title: 'Auctions', color: DataColors.Petrol },
  comparisonLine: { title: 'Comparison', color: DataColors.Hurricane },
  bidWin: { title: 'Bid win', color: DataColors.Violet },
  firstCall: { title: 'First call', color: DataColors.Milka },
  refresh: { title: 'Refresh', color: DataColors.Turquoise },
  hbImpressionRevenue: { title: hbImpressionRevenue.name, color: DataColors.Ming },
  hbWinningCpmSum: { title: hbWinningCpmSum.name, color: DataColors.Pool },
  noBidRate: { title: noBidRate.name, color: DataColors.Silver },
  bidRate: { title: bidRate.name, color: DataColors.Pool },
  bidTimeoutRate: { title: bidTimeoutRate.name, color: DataColors.Alert },
  noBid: { title: noBid.name, color: DataColors.Silver },
  bidResponses: { title: bidResponses.name, color: DataColors.Pool },
  bidTimeouts: { title: bidTimeouts.name, color: DataColors.Alert },
  hbECPM: { title: hbECPM.name, color: DataColors.Ming },
  bidWinCpm: { title: bidWinCpm.name, color: DataColors.Pool },
  bidCpm: { title: bidCpm.name, color: DataColors.Pumpkin },
  impressionRpm: { title: impressionRpm.name, color: DataColors.Ming },
  bidWinRpm: { title: bidWinRpm.name, color: DataColors.Pool },
  bidRpm: { title: bidRpm.name, color: DataColors.Pumpkin },
}

export const TIME_RANGE_CONFIG = {
  TODAY: {
    label: 'Today',
    shortLabel: 'Today',
  },
  YESTERDAY: {
    label: 'Yesterday',
    shortLabel: 'YSD',
  },
  LAST_7_DAYS: {
    label: 'Last 7 days',
    shortLabel: '7D',
  },
  LAST_14_DAYS: {
    label: 'Last 14 days',
    shortLabel: '14D',
  },
  LAST_31_DAYS: {
    label: 'Last 31 days',
    shortLabel: '31D',
  },
  THIS_MONTH: {
    label: 'This month',
    shortLabel: 'This month',
  },
  CUSTOM: {
    label: 'Custom ...',
    shortLabel: '',
  },
} as const

export const getTimePresets = (today: DateTime) => {
  const startOfDay = today.startOf('day')
  const to = today.endOf('day').minus({ days: 1 })
  return [
    { ...TIME_RANGE_CONFIG.TODAY, from: startOfDay, to: startOfDay },
    { ...TIME_RANGE_CONFIG.YESTERDAY, from: startOfDay.minus({ days: 1 }), to },
    { ...TIME_RANGE_CONFIG.LAST_7_DAYS, from: startOfDay.minus({ days: 7 }), to },
    { ...TIME_RANGE_CONFIG.LAST_14_DAYS, from: startOfDay.minus({ days: 14 }), to },
    { ...TIME_RANGE_CONFIG.LAST_31_DAYS, from: startOfDay.minus({ days: 31 }), to },
    { ...TIME_RANGE_CONFIG.THIS_MONTH, from: startOfDay.startOf('month'), to },
  ]
}

export const filterContextDimByName = (context: Context, name: MappedName, dimension: Dimension): Context => {
  const filteredContext = {
    ...context,
    filters: {
      ...context.filters,
      [dimension]: {
        mode: 'include',
        name: dimension,
        values: [name],
      },
    },
  }
  const contextFilterValues = context.filters[dimension]?.values
  if (contextFilterValues?.length === 1 && contextFilterValues?.find((n) => n.value === name.value)) {
    delete filteredContext.filters[dimension]
  }
  return filteredContext
}

/**
 * Returns a function with a MappedName as parameter, which overwrite filter
 * @param logger
 * @param dimension filter's dimension
 * @param setContext method to update app's context
 * @param checkEmptyData (optionnal) if it's true returns the context as it is, but still logs action
 */
export const onBreakdownRowClick = (logger: Logger, dimension: Dimension, setContext: SetterOrUpdater<Context>, checkEmptyData?: boolean) => (name: MappedName) => {
  logger.info({ action: 'click', type: 'filter', name: dimension, data: name.value })
  setContext((context) => {
    if (checkEmptyData) return context
    return filterContextDimByName(context, name, dimension)
  })
}

export const logSortBreakdownAction = (logger: Logger) => (metric: string) => {
  logger.info({ action: 'click', type: 'array-sort', metric: metric })
}

export const isNotApplicable = (value: string | 'NA') => value === 'NA'

/**
 * ie: Wed, 17 Aug. 9:00 am
 */
export const CHART_DATE_FORMAT = 'ccc, dd LLL. h:mm a'
/**
 * ie: Wed, 17 Aug.
 */
export const CHART_DATE_FORMAT_NO_HOUR = 'ccc, dd LLL.'

/**
 * computes the correct time span label to display for comparison data, returns only one day if span is over a day
 * @param epochSpan epochs array
 * @returns Compared to: {CHART_DATE_FORMAT_NO_HOUR} → end {CHART_DATE_FORMAT_NO_HOUR}
 */
export const getComparisonDataLegendLabel = (epochSpan: string[]): string => {
  if (!epochSpan.length) {
    return 'Compared to: N/A'
  }
  const [spanStart, spanEnd] = [DateTime.fromISO(epochSpan[0]), DateTime.fromISO(epochSpan[epochSpan.length - 1])]
  if (sameDay(spanStart, spanEnd)) {
    return `Compared to: ${spanStart.toFormat(CHART_DATE_FORMAT_NO_HOUR)}`
  }
  return `Compared to: ${spanStart.toFormat(CHART_DATE_FORMAT_NO_HOUR)} → ${spanEnd.toFormat(CHART_DATE_FORMAT_NO_HOUR)}`
}

/**
 * returns the correct date format for chart tooltips based on if days or hours are to be displayed
 * @param epochSpan epoch array
 * @returns CHART_DATE_FORMAT_NO_HOUR if epochs span > 3 days, else CHART_DATE_FORMAT
 */
export const getChartTooltipDateFormat = (epochSpan: string[]) => {
  return Math.floor(DateTime.fromISO(epochSpan[epochSpan.length - 1]).diff(DateTime.fromISO(epochSpan[0]), 'days').days) > 3 ? CHART_DATE_FORMAT_NO_HOUR : CHART_DATE_FORMAT
}

export const isFilterAvailable = (filter: Filter, dimensions: Dimension[]) => dimensions.includes(filter.name)

const DimensionFeatureMapping: { [key in Dimension]?: Feature[] } = {
  pubstackRefresh: ['refresh', 'admRefresh'],
  abTestPopulation: ['abtest'],
  stackIdVersion: ['adstack'],
}

export const filterDimensionsByUserFeatures = (dimensions: Dimension[], user: User | null): Dimension[] =>
  dimensions.filter((dimension) => {
    const features = DimensionFeatureMapping[dimension]
    return features ? user && features.some((f) => user.hasFeature(f)) : true
  })

export function isContextInSelectableInterval(context: Context, maxNumberOfDayInTimerangeSelected = 93): boolean {
  const today = DateTime.now().startOf('day')
  const selectable = Interval.fromDateTimes(today.minus({ days: maxNumberOfDayInTimerangeSelected }), today.endOf('day'))
  const { from, to, tz } = context.timeRange
  const selectedInterval = Interval.fromDateTimes(DateTime.fromJSDate(from.toJSDate(), { zone: tz }), DateTime.fromJSDate(to.toJSDate()))
  return selectable.engulfs(selectedInterval) && selectedInterval.length('day') < maxNumberOfDayInTimerangeSelected
}
