import { withAuthenticationRequired } from '@auth0/auth0-react'
import { AccessRole, Feature, canAccess } from '@pubstack/common/src/rolesAndFeatures'
import { ComponentType } from 'react'
import { Forbidden } from '~/components/error/Forbidden'
import { AnalyticsDemoRequestPage } from '~/modules/analytics/demoRequest/AnalyticsDemoRequestPage'
import { useUser } from './user.hooks'

export interface PubstackRouteMeta<T = never> {
  feature?: Feature
  allowDemoRole: boolean
  accessRole: AccessRole
  dashboardId?: string
  allowAnalyticsDemoRequest?: boolean
  breadcrumbLabel?: string | ((params: { search: URLSearchParams; additionalParams?: T }) => string)
  breadcrumbSubtitle?: string
}

const ProtectedRoute = ({ component, metaData, ...props }: { component: ComponentType; metaData: PubstackRouteMeta }) => {
  const user = useUser()
  const WrappedComponent = withAuthenticationRequired(component, {
    loginOptions: {
      appState: {
        target: window.location.pathname + window.location.search,
      },
    },
  })

  if (user) {
    if (!metaData.allowDemoRole && user.getScopeRole() === 'demo') {
      return <Forbidden />
    }
    if (!canAccess(user.currentScopeId, user.userRoles, metaData.accessRole)) {
      return <Forbidden />
    }
    if (metaData.feature) {
      // RefreshPage case: visible for both refresh features
      if (metaData.feature === 'refresh') {
        if (!user.hasFeature('refresh') && !user.hasFeature('admRefresh')) {
          return <Forbidden />
        }
      } else if (!user.hasFeature(metaData.feature)) {
        return <Forbidden />
      }
    }
    if (metaData.allowAnalyticsDemoRequest && !user.hasFeature('analytics')) {
      return <AnalyticsDemoRequestPage />
    }
  }

  return <WrappedComponent {...props} />
}

export default ProtectedRoute
