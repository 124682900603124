import { FloorRule } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFloorRules } from '~/api/adm-api.hook'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureAdStackFloorPricePage } from './PureAdStackFloorPricePage'

export const AdStackFloorPricePage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const [floorRules, setFloorRules] = useState<FloorRule[]>([])
  const currencySymbol = useScopeCurrency()

  const { all } = useFloorRules(null)

  const loadFloorRules = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setFloorRules(result)
  }

  useEffect(() => {
    loadFloorRules()
  }, [])

  return (
    <PureAdStackFloorPricePage
      floorPriceRules={floorRules}
      currencySymbol={currencySymbol}
      isLoading={all.loading}
      breadcrumbs={breadcrumbs}
      onCreateRule={() => navigate(`/adstack/floor-price/new`)}
      onEditRule={(id: string) => navigate(`/adstack/floor-price/edit/${id}`)}
    />
  )
}
