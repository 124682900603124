import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'

const BoldText = styled.span`
  font-weight: bold;
`

type PureUnarchiveStackModalProps = { stackName: string; onUnarchive: () => Promise<void> }
const _PureUnarchiveStackModal: FunctionComponent<PureUnarchiveStackModalProps> = ({ stackName, onUnarchive }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Unarchive {stackName}</Modal.Title>

      <Modal.Body>
        <div>
          You are about to unarchive <BoldText>{stackName}</BoldText>.
        </div>
        <div>Please verify the stack integrity to use it again. Some elements (ad units, rules, integrations) may be updated since last time.</div>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onUnarchive}>
          Check stack settings
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

export const PureUnarchiveStackModal = styled(_PureUnarchiveStackModal)``
