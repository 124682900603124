import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ControlCenterSite } from '@pubstack/common/src/controlCenter'
import { positiveIntegerRegExp } from '@pubstack/common/src/input'
import { Stack, StackGroup } from '@pubstack/common/src/stack'
import { Fragment, FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Required } from 'utility-types'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Transitions } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { Icon, IconName } from '~/components/Icon'
import { Input } from '~/components/Input'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { StackStatus } from '~/modules/adstack/StackStatus'
import { ControlCenterViewModel } from '~/modules/adstack/inventory/controlCenter/PureAdStackControlCenterPage'
import { groupStacks } from '~/modules/adstack/stack'
import { WithClassName } from '~/types/utils'
import { ControlCenterActionConfirmModal } from './ControlCenterModal'

const CollapsibleStackListSection = styled.div`
  margin-top: 12px;
`

const Divider = styled.div`
  margin: 8px 0;
  height: 1px;
  max-height: 1px;
  min-height: 1px;
  flex: 1;
  background-color: ${Colors.Platinum};
`

const SiteCheckbox = styled(Checkbox)``

const CollapsibleTrigger = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`

const CollapsibleStackListIcon = styled(Icon)<{ opened?: boolean }>`
  ${Fonts.colors.King};
  padding: 0 3px;
  transition: transform ${Transitions.quick};
  transform: rotate(${({ opened }) => (opened ? '-180deg' : '0')});
`

const Site = styled.div`
  display: flex;
  flex-direction: column;

  &:last-of-type > ${Divider}:last-of-type {
    margin-bottom: 0;
  }
`

const SiteItem = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;

  ${Fonts.P1};
  font-weight: 500;
`

const SiteName = styled.span`
  align-self: center;
`

const StackCheckBoxName = styled.div`
  flex: 0 0 400px;
`

const InputRatio = styled(Input)`
  width: 94px;
  height: 30px;
  align-self: center;
  ${Fonts.P1}

  input {
    padding: 2px 0;
  }
`

const StackItem = styled.div<{ secondary: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1 0;
  cursor: pointer;
  height: 30px;

  ${({ secondary }) =>
    secondary &&
    css`
      font-style: italic;
      ${Fonts.colors.Ash};
    `}

  & ${StackCheckBoxName} {
    align-self: center;
  }

  & ${StackStatus} {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 150px;
  }

  & ${Button}[name="view"] {
    margin-left: auto;
  }
`

const CheckboxWrapper = styled.div`
  flex: 0;
  & ${Checkbox} {
    height: 30px;
  }
`

const StackGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 24px;
`

const StackGroupStacks = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 4px;
  gap: 4px;
`

const GlobalAction = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

const EmptySearch = styled.span`
  display: flex;
  justify-content: center;
  margin-top: 7px;
  ${Fonts.H2}
  font-weight: bold;
  color: ${Colors.Hurricane};
`

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  ${Fonts.colors.Alert};
  ${Fonts.P2};

  > * {
    margin-right: 4px;
  }
`

type CollapsibleStackListProps = WithClassName & {
  data: ControlCenterSite[]
  isReadOnly?: boolean
  actionLabel?: string
  actionIconName?: IconName
  onActionClick?: (sites: ControlCenterViewModel[]) => Promise<void>
  onDetailedView: (stack: Stack, stackGroup: StackGroup) => void
  confirmLabelModal?: string
  titleModal?: string
  descriptionModal?: ReactNode
  search?: string
  canAbTest?: boolean
}

const isAbTest = (stack: Stack): stack is Required<Stack, 'ratio'> => !!(stack.ratio && stack.ratio < 100)

const buildControlCenterVm = (controlCenterSites: ControlCenterSite[], search?: string): ControlCenterViewModel[] => {
  const insensitiveIncludesSearch = (a: string) => a.toLowerCase().includes((search ?? '').toLowerCase())
  return controlCenterSites
    .map(({ stacks, ...site }) => ({
      site,
      stackGroups: groupStacks(stacks)
        .activeGroups.filter((group) => insensitiveIncludesSearch(site.name) || insensitiveIncludesSearch(group.groupName))
        .map((group) => ({
          ...group,
          stacks: group.stacks.sort((a, b) => ((a.version ?? 0) > (b.version ?? 0) ? -1 : 1)),
        })),
    }))
    .filter((vm) => vm.stackGroups.length > 0)
}

const buildStackUniqueId = ({ site }: ControlCenterViewModel, { stackId }: { stackId: Stack['id'] }, stack?: Stack) => `${site.id}_${stackId}${stack ? `_${stack.id}` : ''}`

const useToggle = () => {
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({})

  return {
    isSiteOpen: useCallback(({ site: { id } }: ControlCenterViewModel) => isOpen[id], [isOpen]),
    toggleOpenSite: useCallback(
      ({ site: { id } }: ControlCenterViewModel) => {
        setIsOpen({ ...isOpen, [id]: !isOpen[id] })
      },
      [isOpen]
    ),
  }
}

const useChecked = (displayedSites: ControlCenterViewModel[]) => {
  const [isChecked, setIsChecked] = useState<{ [key: string]: boolean }>({})
  const isAllChecked = displayedSites.every((site) => site.stackGroups.every((group) => isChecked[buildStackUniqueId(site, group)]))
  const atLeastOneChecked = displayedSites.some((site) => site.stackGroups.some((group) => isChecked[buildStackUniqueId(site, group)]))
  const isAllIndeterminate = atLeastOneChecked && !isAllChecked
  const isActionDisabled = !atLeastOneChecked

  const onSelectSite = useCallback(
    (site: ControlCenterViewModel) => {
      const allChecked = site.stackGroups.every((group) => isChecked[buildStackUniqueId(site, group)])
      setIsChecked(site.stackGroups.reduce((isChecked, group) => ({ ...isChecked, [buildStackUniqueId(site, group)]: !allChecked }), isChecked))
    },
    [isChecked]
  )

  const onSelectAll = useCallback(() => {
    setIsChecked(
      displayedSites.reduce(
        (isChecked, controlCenterViewModel) => ({
          ...isChecked,
          ...controlCenterViewModel.stackGroups.reduce((isChecked, group) => ({ ...isChecked, [buildStackUniqueId(controlCenterViewModel, group)]: !isAllChecked }), {}),
        }),
        isChecked
      )
    )
  }, [isAllChecked, displayedSites])

  const isSiteChecked = useCallback(
    (controlCenterViewModel: ControlCenterViewModel) => {
      return controlCenterViewModel.stackGroups.length > 0 && controlCenterViewModel.stackGroups.every((group) => isChecked[buildStackUniqueId(controlCenterViewModel, group)])
    },
    [isChecked]
  )

  const isSiteIndeterminate = useCallback(
    (controlCenterViewModel: ControlCenterViewModel) => {
      const isSiteChecked = controlCenterViewModel.stackGroups.length > 0 && controlCenterViewModel.stackGroups.every((group) => isChecked[buildStackUniqueId(controlCenterViewModel, group)])
      const isSiteAtLeastOneChecked = controlCenterViewModel.stackGroups.length > 0 && controlCenterViewModel.stackGroups.some((group) => isChecked[buildStackUniqueId(controlCenterViewModel, group)])
      return isSiteAtLeastOneChecked && !isSiteChecked
    },
    [isChecked]
  )

  const isStackGroupChecked = useCallback(
    (controlCenterViewModel: ControlCenterViewModel, stackGroup: StackGroup) => {
      return isChecked[`${controlCenterViewModel.site.id}_${stackGroup.stackId}`]
    },
    [isChecked]
  )

  const onSelectStackGroup = useCallback(
    (controlCenterViewModel: ControlCenterViewModel, stackGroup: StackGroup) => {
      const stackId = buildStackUniqueId(controlCenterViewModel, stackGroup)
      setIsChecked({ ...isChecked, [stackId]: !isChecked[stackId] })
    },
    [isChecked]
  )

  return {
    isAllIndeterminate,
    isActionDisabled,
    isAllChecked,
    onSelectAll,
    onSelectSite,
    isSiteChecked,
    isSiteIndeterminate,
    onSelectStackGroup,
    isStackGroupChecked,
  }
}

const _CollapsibleStackList: FunctionComponent<CollapsibleStackListProps> = ({
  data,
  isReadOnly = false,
  actionIconName,
  actionLabel,
  onActionClick,
  onDetailedView,
  titleModal,
  descriptionModal,
  confirmLabelModal,
  search,
  canAbTest,
}) => {
  const sites = useMemo(() => buildControlCenterVm(data), [data])
  const filteredSites = useMemo(() => buildControlCenterVm(data, search), [data, search])
  const { isSiteOpen, toggleOpenSite } = useToggle()
  const { isAllIndeterminate, isActionDisabled, isAllChecked, onSelectAll, onSelectSite, isSiteChecked, isSiteIndeterminate, onSelectStackGroup, isStackGroupChecked } = useChecked(filteredSites)

  const defaultValues = sites
    .flatMap((site) =>
      site.stackGroups.flatMap((stackGroup) =>
        stackGroup.stacks.flatMap((stack, index) => ({
          viewModel: site,
          stackGroup,
          stack,
          isNewestStack: index === 0,
        }))
      )
    )
    .reduce((defaultValues, { viewModel, stackGroup, stack, isNewestStack }) => {
      let ratio = 0
      if (isAbTest(stack)) {
        ratio = stack.ratio
      } else if (isNewestStack) {
        // We set ratio = 0 to the newest version
        ratio = 100
      }
      return {
        ...defaultValues,
        [buildStackUniqueId(viewModel, stackGroup, stack)]: ratio,
      }
    }, {})
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<{ [key: string]: number | string }>({
    mode: 'onChange',
    defaultValues,
  })

  const modal = useGlobalModal()
  const openConfirmModal = () => {
    modal.open(ControlCenterActionConfirmModal, {
      onConfirm: async (sites: ControlCenterViewModel[]) => {
        if (onActionClick) {
          await onActionClick(sites)
          modal.close()
        }
      },
      sites: sites
        .map((site) => ({
          ...site,
          stackGroups: site.stackGroups
            .filter((stackGroup) => isStackGroupChecked(site, stackGroup))
            .map((stackGroup) => ({
              ...stackGroup,
              stacks: stackGroup.stacks
                .filter((_, index) => (canAbTest ? true : index === 0)) // We filter stacks by either canAbTest = true -> no filter in deployment section / either we are in OutOfSync and we can only sync the newest version
                .map((stack) => (canAbTest ? { ...stack, ratio: Number(getValues(buildStackUniqueId(site, stackGroup, stack))) } : stack)),
            })),
        }))
        .filter((site) => site.stackGroups.length > 0),
      confirmLabel: confirmLabelModal ?? '',
      description: descriptionModal,
      title: titleModal ?? '',
      canAbTest: canAbTest ?? false,
    })
  }

  return filteredSites.length > 0 ? (
    <CollapsibleStackListSection>
      {!isReadOnly && (
        <GlobalAction>
          <Checkbox label={'Select all stacks'} checked={isAllChecked} indeterminate={isAllIndeterminate} onClick={onSelectAll} />
          <Button iconName={actionIconName} variant={'primary'} onClick={openConfirmModal} disabled={isActionDisabled || !isValid}>
            {actionLabel}
          </Button>
        </GlobalAction>
      )}
      <Divider />
      {filteredSites.map((controlCenterVm) => {
        return (
          <Site key={controlCenterVm.site.id}>
            <SiteItem>
              {!isReadOnly ? (
                <SiteCheckbox
                  label={controlCenterVm.site.name}
                  checked={isSiteChecked(controlCenterVm)}
                  indeterminate={isSiteIndeterminate(controlCenterVm)}
                  onClick={() => onSelectSite(controlCenterVm)}
                />
              ) : (
                <SiteName>{controlCenterVm.site.name}</SiteName>
              )}
              <CollapsibleTrigger onClick={() => toggleOpenSite(controlCenterVm)}>
                <CollapsibleStackListIcon opened={isSiteOpen(controlCenterVm)} width={'24px'} name={'chevron_down'} />
              </CollapsibleTrigger>
            </SiteItem>
            <Divider />
            {isSiteOpen(controlCenterVm) &&
              controlCenterVm.stackGroups.map((stackGroup) => {
                const hasError = Object.keys(errors).some((key) => key.startsWith(buildStackUniqueId(controlCenterVm, stackGroup)))

                return (
                  <Fragment key={buildStackUniqueId(controlCenterVm, stackGroup)}>
                    <StackGroupWrapper>
                      {!isReadOnly && (
                        <CheckboxWrapper>
                          <Checkbox checked={isStackGroupChecked(controlCenterVm, stackGroup)} onClick={() => onSelectStackGroup(controlCenterVm, stackGroup)} />
                        </CheckboxWrapper>
                      )}
                      <StackGroupStacks>
                        {stackGroup.stacks.map((stack, index, sortedStacks) => (
                          <StackItem key={buildStackUniqueId(controlCenterVm, stackGroup, stack)} onClick={() => onSelectStackGroup(controlCenterVm, stackGroup)} secondary={index > 0 && !canAbTest}>
                            <StackCheckBoxName>
                              {stack.name} - v{stack.version}
                            </StackCheckBoxName>
                            <StackStatus stack={stack} />
                            {canAbTest && isStackGroupChecked(controlCenterVm, stackGroup) && (
                              <InputRatio
                                isClearable={false}
                                control={control}
                                disabled={stackGroup.stacks.length === 1}
                                type={'number'}
                                name={buildStackUniqueId(controlCenterVm, stackGroup, stack)}
                                onClick={(event) => event.stopPropagation()}
                                onKeyDown={(e) => {
                                  if (['e', '-', '+', ',', '.'].includes(e.key)) {
                                    e.preventDefault()
                                  }
                                }}
                                onChange={(event) => {
                                  const value = Number(event.target.value)
                                  setValue(buildStackUniqueId(controlCenterVm, stackGroup, sortedStacks[1 - index]), 100 - value, { shouldValidate: true }) // as we only have 2 stacks, [1 - index] is the index of the other stack
                                }}
                                rules={{
                                  required: true,
                                  validate: {
                                    max: (value: number | string) => (Number(value) <= 100 ? true : 'Should be lower than 100'),
                                    min: (value: number | string) => (Number(value) >= 0 ? true : 'Should be greater than 0'),
                                    isNumber: (value: string) => (positiveIntegerRegExp.test(value) ? true : 'Should be a number'),
                                  },
                                }}
                                isError={Object.keys(errors).includes(buildStackUniqueId(controlCenterVm, stackGroup, stack))}
                                required={true}
                                min={0}
                                max={100}
                                iconRight={'percent'}
                                isMessageBlock={true}
                              />
                            )}
                            <Button
                              name={'view'}
                              iconName={'view'}
                              variant={'tertiary'}
                              iconSize={'18px'}
                              title={'View'}
                              onClick={async (event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                onDetailedView(stack, stackGroup)
                              }}
                            />
                          </StackItem>
                        ))}
                        {isStackGroupChecked(controlCenterVm, stackGroup) && hasError && (
                          <ErrorMessage>
                            <Icon name={'alert'} width={'0.875rem'} />
                            Values should be between 0 and 100
                          </ErrorMessage>
                        )}
                      </StackGroupStacks>
                    </StackGroupWrapper>
                    <Divider />
                  </Fragment>
                )
              })}
          </Site>
        )
      })}
    </CollapsibleStackListSection>
  ) : (
    <EmptySearch>No stacks match your research.</EmptySearch>
  )
}

export const CollapsibleStackList = styled(_CollapsibleStackList)``
