import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { FunctionComponent } from 'react'
import { Controller, type FieldValues, type UseControllerProps } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Transitions } from '~/assets/style/tokens'
import type { WithClassName } from '~/types/utils'
import Button from './Button'
import { Icon, IconName } from './Icon'

const RadioButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  ${Button} {
    display:flex;
  }
`

const Input = styled.input`
  opacity: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: absolute;
`

const BulletIcon = styled(Icon)`
  color: ${Colors.King};
  transition: ${Transitions.quick};
  & svg path:first-of-type {
    fill: white;
    transition: ${Transitions.quick};
  }
`

const RadioItemWrapper = styled.label<{ selected: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  :hover {
    ${({ selected, disabled }) =>
      disabled
        ? css`
        ${BulletIcon} {
          color: ${Colors.Ash};
        }
      `
        : !selected &&
          css`
        ${BulletIcon} {
          color: ${Colors.Cobalt};
        }
      `}
  }
  :active {
    ${BulletIcon} {
      color: ${Colors.King};
    }
  }

  ${({ disabled, selected }) =>
    disabled
      ? css`
      // CSS applied on disabled radio item (no hover)
      color: ${Colors.Ash};
      cursor: not-allowed;
      ${BulletIcon} {
        color: ${Colors.Ash};
      }
    `
      : selected &&
        css`
      ${BulletIcon} {
        color: ${Colors.King};
        & svg path:first-of-type {
          fill: currentColor;
        }
      }
    `}
`

type RadioButtonValueType = string | number | boolean

export type RadioOption = {
  value: RadioButtonValueType
  label: string
  disabled?: boolean
  iconName?: IconName
}

type BaseRadioButtonProps = WithClassName & {
  options: RadioOption[]
  selectedValue: RadioButtonValueType
  onChange?: (value: RadioButtonValueType) => void
  onIconClick?: (value: any) => void
}

const _RadioButtonGroup: FunctionComponent<BaseRadioButtonProps> = ({ className, options, selectedValue, onChange, onIconClick }) => {
  return (
    <RadioButtonWrapper className={className}>
      {options.map((option) => (
        <RadioItemWrapper key={option.label} selected={option.value === selectedValue} disabled={!!option.disabled}>
          <Input type={'radio'} onChange={!option.disabled ? () => onChange?.(option.value) : undefined} checked={option.value === selectedValue} />
          <BulletIcon name={'radio'} width={'20px'} />
          <span>{option.label}</span>
          {option.iconName && (
            <Button
              variant={'tertiary'}
              disabled={option.disabled}
              iconName={option.iconName}
              iconSize={'16px'}
              onClick={(event) => {
                if (onIconClick) {
                  event.stopPropagation()
                  onIconClick(option.value)
                }
              }}
            />
          )}
        </RadioItemWrapper>
      ))}
    </RadioButtonWrapper>
  )
}

export const BaseRadioButtonGroup = styled(_RadioButtonGroup)``

type RadioButtonGroupProps<T extends FieldValues> = Omit<UseControllerProps<T>, 'defaultValue'> & Omit<BaseRadioButtonProps, 'selectedValue'>
const RadioButtonGroup = <T extends FieldValues>({ control, onChange, name, shouldUnregister, rules, ...props }: RadioButtonGroupProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={shouldUnregister}
      rules={rules}
      render={({ field }) => (
        <BaseRadioButtonGroup
          {...props}
          selectedValue={field.value as string}
          onChange={(option) => {
            field.onChange(option)
            onChange?.(option)
          }}
        />
      )}
    />
  )
}

export { RadioButtonGroup }
