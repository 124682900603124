import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Flyout } from '~/components/Flyout'
import { WithClassName } from '~/types/utils'

type PureAdstackRefreshHelpFlyoutProps = WithClassName & {
  isOpen: boolean
  onRequestClose: () => unknown
}

const HelpText = styled.div`
  h2 {
    padding: 24px 0 12px 0;
  }
`

const _PureAdstackRefreshHelpFlyout: FunctionComponent<PureAdstackRefreshHelpFlyoutProps> = ({ className, isOpen, onRequestClose }) => {
  return (
    <Flyout className={className} isOpen={isOpen} onClose={onRequestClose} title={'Tips & help'} color={Colors.Lilac}>
      <HelpText>
        <h2>What is a refresh rule?</h2>
        <p>It&apos;s the refresh settings to use on your stacks. A rule contains the timer between two impressions. You can define maximum impressions allowed (capping).</p>
        <h2>I don&apos;t want to refresh a bidder or a size.</h2>
        <p>In “global settings”, define which bidders and sizes will never be refreshed for all of your sites.</p>
        <h2>I don&apos;t want to refresh specific campaigns or advertisers.</h2>
        <p>In Google Ad Manager, control your demand exclusions by applying the label &quot;Pubstack no refresh&quot; on the following entities:</p>
        <ul>
          <li>Advertisers</li>
          <li>Orders</li>
          <li>Line Items</li>
        </ul>
        <h2>I want to use a specific timer for a bidder.</h2>
        <p>In &quot;global settings&quot;, you can set a timer for a bidder. It will be applied to all of your sites.</p>
        <h2>What if an impression matches several rules criteria?</h2>
        <p>The longest timer will be used, which will ensure an optimal ad experience on your website.</p>
      </HelpText>
    </Flyout>
  )
}

export const PureAdstackRefreshHelpFlyout = styled(_PureAdstackRefreshHelpFlyout)``
