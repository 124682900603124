import styled from '@emotion/styled'
import { SiteMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { TimelineConfiguration, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import { adxECPM, allECPM, hbECPM, openBiddingECPM } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { displayWithCurrency } from '~/utils/string'
import { PureSiteChartTitle } from './PureSiteChartTitle'

const useConfig = (data: SiteMetricsByTime) =>
  useMemo((): TimelineConfiguration<SiteMetricsByTime> => {
    return {
      getChartOptions: ({ currencySymbol }) => ({
        seriesType: 'line',
        vAxis: { format: displayWithCurrency('#,##', currencySymbol), minValue: 0 },
      }),
      legendConfig: ['hb', 'adx', 'openBidding'],
      tooltipConfig: ['hb', 'adx', 'openBidding'],
      dataConfig: [
        {
          name: 'hb',
          label: hbECPM.name,
          iconName: 'data_line',
          getFormula: () => hbECPM,
          isComputable: ({ data }) => hbECPM.isComputable(data),
          withValue: true,
        },
        {
          name: 'adx',
          label: adxECPM.name,
          iconName: 'data_line',
          getFormula: () => adxECPM,
          isComputable: ({ data }) => adxECPM.isComputable(data),
          withValue: true,
        },
        {
          name: 'openBidding',
          label: openBiddingECPM.name,
          iconName: 'data_line',
          getFormula: () => openBiddingECPM,
          isComputable: ({ data }) => openBiddingECPM.isComputable(data),
          withValue: true,
        },
      ],
    }
  }, [data])

type PureSiteEcpmChartProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    data: SiteMetricsByTime
    currencySymbol: CurrencySymbol
    timezone: Timezone
    currentEpoch: DateTime
  }
const _PureSiteEcpmChart: FunctionComponent<PureSiteEcpmChartProps> = ({ data, currencySymbol, currentEpoch, timezone, ...props }) => {
  const averageECPM = displayWithCurrency(allECPM.displayable(allECPM.isComputable(data) ? allECPM.sum(data) : 0), currencySymbol)
  const title = <PureSiteChartTitle title={allECPM.name} infoLabel={'average'} infoValue={averageECPM} />
  const currentConfig = useConfig(data)

  const { chart, legends } = useTimelineChart({ currentConfig, data, currencySymbol, currentEpoch, timezone })
  return <AnalyticsChartWidget {...props} icon={'site'} title={title} chart={chart} legends={legends} />
}
export const PureSiteEcpmChart = styled(_PureSiteEcpmChart)``
