import { AdmQAReportCategory, AdmQaReport, AdmQaReportsTimeRange } from '@pubstack/common/src/adm-qa-reports'
import { ClientSideDelivery, LatestClientSideDelivery } from '@pubstack/common/src/clientSideDelivery'
import { GamIntegration, GamNetworkConfig } from '@pubstack/common/src/gam/adx'
import { GamManagerRunning } from '@pubstack/common/src/gam/gamManager'
import { KleanadsScopeConfig, PbjsDesiredVersion } from '@pubstack/common/src/kleanadsScopeConfig'
import { AdminScope } from '@pubstack/common/src/scope'
import { SnoozeAlarm } from '@pubstack/common/src/snoozeAlarm'
import { AdminApiKey, AdminTag } from '@pubstack/common/src/tag'
import { useFetch } from 'use-http'
import { useFetchWithException } from './api-access'

export const useAdminScopes = (deps: unknown[] | null = []) => {
  const fetchAll = useFetch<AdminScope[]>('/admin/scopes', {}, deps || undefined)
  const fetch = useFetch<AdminScope>('/admin/scopes')
  return { all: { ...fetchAll }, byId: { ...fetch } }
}

export const useAdminScope = (deps: unknown[] | null = []) => {
  const fetch = useFetch<AdminScope>(`/admin/scopes/`, {}, deps || undefined)
  const get = (scopeId: AdminScope['id']) => fetch.get(`${scopeId}`)
  const post = (scopeId: AdminScope['id'], scope: AdminScope) => fetch.post(`${scopeId}`, scope)
  const fetchCSV = useFetch('/admin/scopes/csv', deps || undefined)
  return { byId: { ...fetch, get, post }, scopeCSV: fetchCSV }
}

export const useAdminTags = (deps: unknown[] | null = [], scopeId: AdminScope['id']) => {
  const fetch = useFetchWithException<AdminTag>(`/admin/scopes/${scopeId}/tags`, {}, deps || undefined)
  const fetchAll = useFetchWithException<AdminTag[]>(`/admin/scopes/${scopeId}/tags`, {}, deps || undefined)
  const getById = (id: AdminTag['id']) => fetch.get(id)
  return { all: { ...fetchAll }, byId: { ...fetch, get: getById } }
}

export const useAdminUser = (deps: unknown[] | null = []) => {
  const fetch = useFetch('/admin/users', {}, deps || undefined)
  const fetchCSV = useFetch('/admin/users/csv', {}, deps || undefined)
  return { byId: { ...fetch }, usersCSV: fetchCSV }
}

export const useAdminMarketplace = (deps: unknown[] | null = []) => {
  const uploadFetch = useFetchWithException<void>(`/admin/marketplace`, {}, deps || undefined)
  const upload = (json: File) => {
    const data = new FormData()
    data.append('sellers', json)
    return uploadFetch.post(data)
  }
  return { upload }
}

export const useToggleView = (deps: unknown[] | null = []) => {
  return { byId: { ...useFetch('/admin/users/toggle-view', {}, deps || undefined) } }
}

export const useAdminAPIKey = (deps: unknown[] | null = [], scopeId: AdminScope['id']) => {
  const fetchAll = useFetch<AdminApiKey[]>(`/admin/scopes/${scopeId}/api-keys`, {}, deps || undefined)
  const fetch = useFetch<AdminApiKey>(`/admin/scopes/${scopeId}/api-keys`, {}, deps || undefined)
  return { all: { ...fetchAll }, byId: { ...fetch } }
}

export const useAdminKleanadsScopeConfig = (deps: unknown[] | null = [], scopeId: AdminScope['id']) => {
  const fetchAll = useFetchWithException<KleanadsScopeConfig>(`/admin/scopes/${scopeId}/adstack-config`, {}, deps || undefined)
  const fetch = useFetchWithException<KleanadsScopeConfig>(`/admin/scopes/${scopeId}/adstack-config`, {}, deps || undefined)
  const findFetch = () => fetch.get('/find')
  const fetchPocOverrides = useFetchWithException<KleanadsScopeConfig['pocOverrides']>(`/admin/scopes/${scopeId}/adstack-config/poc-overrides`, {}, deps || undefined)
  const fetchPbjsDesired = useFetchWithException<PbjsDesiredVersion>(`/admin/scopes/${scopeId}/adstack-config/pbjs-desired`, {}, deps || undefined)
  return {
    ...fetchAll,
    getById: fetch.get,
    post: fetch.post,
    put: fetchPbjsDesired.put,
    loadingAll: fetchAll.loading,
    errorAll: fetchAll.error,
    abortAll: fetchAll.abort,
    putPocOverrides: fetchPocOverrides.put,
    find: findFetch,
  }
}

export const useGamManagerAdStackConfig = (deps: unknown[] | null = [], scopeId: AdminScope['id']) => {
  const fetch = useFetchWithException<GamManagerRunning>(`/admin/scopes/${scopeId}/gam-manager/object-creation`, {}, deps || undefined)
  const get = (networkId: string) => fetch.get(networkId)
  const put = (networkId: string) => fetch.put(networkId)
  return {
    byId: { ...fetch, get, put },
  }
}

// TODO: 2023-09-15 SLE: change path /integration/ by /integrations/ (otherwise i don't understand the /gam subpath)
export const useGamIntegration = (deps: unknown[] | null = [], scopeId: AdminScope['id']) => {
  return { byId: { ...useFetch<GamIntegration>(`/admin/scopes/${scopeId}/integration/gam`, {}, deps || undefined) } }
}

export const useCreateServiceAccount = (deps: unknown[] | null = [], scopeId: AdminScope['id']) => {
  const fetch = useFetch(`/admin/scopes/${scopeId}/integration/gam/service-account`, {}, deps || undefined)
  const post = (scopeName: AdminScope['name']) => fetch.post({ scopeName })
  return { byId: { ...fetch, post } }
}

export const useGamNetwork = (deps: unknown[] | null = [], scopeId?: AdminScope['id']) => {
  const fetch = useFetch<GamNetworkConfig>(`/admin/scopes/${scopeId}/integration/gam/gam-network`, {}, deps || undefined)
  const fetchAll = useFetch<GamNetworkConfig[]>(`/admin/scopes/gam-network`, {}, deps || undefined)
  const put = (network: GamNetworkConfig) => fetch.put(network.networkCode, network)
  const post = (scopeName: AdminScope['name'], networkCode: string) => fetch.post({ networkCode, scopeName })
  return { all: { ...fetchAll }, byId: { ...fetch, put, post } }
}

export const useGamLaunchChecks = (deps: unknown[] | null = [], scopeId: AdminScope['id']) => {
  const fetch = useFetch<GamNetworkConfig>(`/admin/scopes/${scopeId}/integration/gam/launch-checks`, {}, deps || undefined)
  const post = (networkCode: number) => fetch.post({ networkCode })
  return { byId: { ...fetch, post } }
}

export const useDelivery = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<LatestClientSideDelivery>('/admin/delivery', deps || undefined)
  const post = (config: Pick<ClientSideDelivery, 'mainName' | 'abTest' | 'component'>) => fetch.post(config)
  return { byId: { ...fetch, post } }
}
export const useGetDeployedFiles = (deps: unknown[] | null = []) => {
  const fetch = useFetch<string[]>(`/admin/delivery/tag-files`, deps || undefined)
  return { byId: { ...fetch } }
}

export const useSnoozeAlarm = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<SnoozeAlarm[]>(`/admin/snooze-alarm`, deps || undefined)
  const fetch = useFetchWithException<SnoozeAlarm>(`/admin/snooze-alarm`, deps || undefined)
  return { all: { ...fetchAll }, byId: { ...fetch } }
}

export const useAdmQAReports = (deps: unknown[] | null = [], scopeId: string) => {
  const {
    abort: reportAbort,
    loading: reportLoading,
    get: reportGet,
    post: reportPost,
    delete: reportDelete,
  } = useFetchWithException<AdmQaReport>(`/admin/scopes/${scopeId}/qa/reports`, {}, deps || undefined)
  const { abort: reportsAbort, loading: reportsLoading, get: reportsGet } = useFetchWithException<AdmQaReport[]>(`/admin/scopes/${scopeId}/qa/reports`, {}, deps || undefined)
  const { abort: reportDataAbort, loading: reportDataLoading, get: reportDataGet } = useFetchWithException<string>(`/admin/scopes/${scopeId}/qa/reports`, {}, deps || undefined)

  return {
    abort: {
      reportAbort,
      reportsAbort,
      reportDataAbort,
    },
    loading: {
      reportLoading,
      reportsLoading,
      reportDataLoading,
    },
    reports: {
      all: reportsGet,
      byId: (reportId: string) => reportGet(reportId),
      dataById: (reportId: string, category: AdmQAReportCategory) => reportDataGet(`${reportId}/${category}/data`),
      new: (siteIdA: string, siteIdB: string, deployDate: string, range: AdmQaReportsTimeRange) => {
        return reportPost({ siteIdA, siteIdB, deployDate, range })
      },
      delete: (reportId: string) => reportDelete(reportId),
    },
  }
}
