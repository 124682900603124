import styled from '@emotion/styled'
import { Stack } from '@pubstack/common/src/stack'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Icon } from '~/components/Icon'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

const StatusWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
  flex-direction: row;
  align-items: center;

  ${Tooltip} {
    display: inline-flex;
  }
`

const buildLiveLabel = (stack: StackStatusType) => `Live ${stack.ratio && stack.ratio < 100 ? `(${stack.ratio}%)` : ''}`

const ReadyToDeploy = (props: WithClassName) => (
  <StatusWrapper {...props}>
    <Icon width={'18px'} name={'send'} fill={Colors.Success} />
    Ready to deploy
  </StatusWrapper>
)

const Live = ({ stack, ...props }: WithClassName & { stack: StackStatusType }) => (
  <StatusWrapper {...props}>
    <Icon width={'18px'} name={'check_circled'} fill={Colors.Success} />
    {buildLiveLabel(stack)}
  </StatusWrapper>
)

const Draft = (props: WithClassName) => (
  <StatusWrapper {...props}>
    <Icon width={'18px'} name={'draft'} fill={Colors.Ash} />
    Draft
  </StatusWrapper>
)

const OutOfSync = ({ stack, ...props }: WithClassName & { stack: StackStatusType }) => (
  <StatusWrapper {...props}>
    <Tooltip title={`Synchronization required`}>
      <Icon width={'18px'} name={'sync_problem'} fill={Colors.Warning} />
    </Tooltip>
    <Tooltip title={generateSyncTooltip(stack)}>{stack.status === 'live' ? buildLiveLabel(stack) : 'Ready to deploy'}</Tooltip>
  </StatusWrapper>
)

const Archived = (props: WithClassName) => (
  <StatusWrapper {...props}>
    <Icon width={'18px'} name={'folder'} fill={Colors.Silver} />
    Archived
  </StatusWrapper>
)

const SyncTooltip = styled.ul`
  padding: 10px;
  list-style-position: inside;
`
const EllipsisList = styled.li`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const generateSyncTooltip = (stack: StackStatusType) => (
  <SyncTooltip>
    {stack.elementsToSync.map(({ category, elements }) => (
      <EllipsisList key={category}>
        {category === 'Ad units' && elements.length} {category} edited {category === 'Ad units' && `(${elements.map((element) => element.name).join(', ')})`}
      </EllipsisList>
    ))}
  </SyncTooltip>
)

type StackStatusType = Pick<Stack, 'ratio' | 'status' | 'elementsToSync' | 'enabled'>

const StackStatuses: { [key in Stack['status']]: FunctionComponent<WithClassName & { stack: StackStatusType }> } = {
  commit: ReadyToDeploy,
  live: Live,
  draft: Draft,
}
const _StackStatus = (props: WithClassName & { stack: StackStatusType }) => {
  if (!props.stack.enabled) return <Archived {...props} />
  const StatusComponent = props.stack.elementsToSync.length > 0 ? OutOfSync : StackStatuses[props.stack.status]
  return <StatusComponent {...props} />
}

export const StackStatus = styled(_StackStatus)``
