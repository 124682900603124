import styled from '@emotion/styled'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { JSONEditorInput } from '~/components/JSONEditor'
import { WithClassName } from '~/types/utils'

const Footer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`

const Error = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${Colors.Alert};
`

const Actions = styled.div`
  display: inline-flex;
  gap: 20px;
  align-items: center;
`

const JSON_SPACE_COUNT = 2

export type JSONEditorStatus = {
  error?: boolean
  dirty?: boolean
}

type PurePocOverrideJSONEditorProps<T extends { [key: string]: any }> = WithClassName & {
  data: T
  statusUpdate?: (status: JSONEditorStatus) => void
  onValidate: (objectValue: T) => void
}
const _PocOverrideJSONEditor = <T extends { [key: string]: any }>({ className, data, statusUpdate, onValidate }: PurePocOverrideJSONEditorProps<T>) => {
  const jsonValue = JSON.stringify(data, undefined, JSON_SPACE_COUNT)
  const defaultValues = {
    jsonValue,
  }
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<{ jsonValue: string }>({
    defaultValues,
    mode: 'onChange',
  })
  const update: SubmitHandler<{ jsonValue: string }> = (formValue) => {
    const res = JSON.parse(formValue.jsonValue)
    onValidate({ ...res })
    reset(formValue)
    statusUpdate && statusUpdate({ dirty: false, error: false })
  }

  const restore = () => {
    reset(defaultValues)
    statusUpdate && statusUpdate({ dirty: false, error: false })
  }

  useEffect(() => {
    statusUpdate && statusUpdate({ dirty: !errors.jsonValue && isDirty, error: !!errors.jsonValue })
  }, [errors.jsonValue, isDirty])

  return (
    <form className={className} onSubmit={handleSubmit(update)}>
      <JSONEditorInput control={control} name={'jsonValue'} />
      <Footer>
        <Error>
          {errors.jsonValue?.message && (
            <>
              <Icon width={'16px'} name={'alert'} />
              <span>{errors.jsonValue?.message}</span>
            </>
          )}
        </Error>
        <Actions>
          {isDirty && (
            <Button onClick={restore} iconName={'restore'} variant={'tertiary'}>
              Restore
            </Button>
          )}
          <Button disabled={!!errors.jsonValue || !isDirty} type={'submit'}>
            Validate
          </Button>
        </Actions>
      </Footer>
    </form>
  )
}

export const PocOverrideJSONEditor = styled(_PocOverrideJSONEditor)``
