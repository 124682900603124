import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST, GAM_DASHBOARD_NOT_APPLICABLE_AD_UNIT_LEVEL } from '@pubstack/common/src/analytics/query'
import { Color, Colors, DataColors } from '~/assets/style/colors'
import { ANALYTICS_TOOLTIPS } from './constants'

export type GamOverviewDimensions = Extract<
  Dimension,
  'pubstackDemandChannel' | 'tagId' | 'adUnit' | 'device' | 'adUnitLevel1' | 'adUnitLevel2' | 'adUnitLevel3' | 'adUnitLevel4' | 'adUnitLevel5' | 'abTestPopulation'
>

export const GAM_OVERVIEW_GENERAL_COLOR = {
  Others: Colors.SlateGrey,
  NotApplicable: Colors.Jet,
}

export const GAM_OVERVIEW_DIMENSION_COLORS: {
  [key in GamOverviewDimensions]: { [value: string | number]: Color }
} = {
  pubstackDemandChannel: {
    Prebid: DataColors.Ming,
    'Google demand': DataColors.Pool,
    'Open Bidding': DataColors.Lilac,
    TAM: DataColors.Pumpkin,
    'Guaranteed campaign': DataColors.Forest,
    'Non Guaranteed campaign': DataColors.Violet,
    Filler: DataColors.Rose,
    Deals: DataColors.Milka,
    NotApplicable: GAM_OVERVIEW_GENERAL_COLOR.NotApplicable,
    Other: GAM_OVERVIEW_GENERAL_COLOR.Others,
  },
  tagId: {
    0: DataColors.Turquoise,
    1: DataColors.Frog,
    2: DataColors.Forest,
    3: DataColors.Salmon,
    4: DataColors.Sun,
  },
  adUnit: {
    0: Colors.Cobalt,
    1: Colors.SkyBlue,
    2: Colors.Violet,
    3: Colors.Rose,
    4: Colors.Milka,
  },
  device: {
    desktop: DataColors.Forest,
    tablet: DataColors.Frog,
    mobile: Colors.SkyBlue,
    other: GAM_OVERVIEW_GENERAL_COLOR.Others,
  },
  adUnitLevel1: { 0: DataColors.Lilac, 1: DataColors.Petrol, 2: DataColors.SkyBlue, 3: DataColors.Sun, 4: DataColors.Orchid },
  adUnitLevel2: { 0: DataColors.Petrol, 1: DataColors.SkyBlue, 2: DataColors.Sun, 3: DataColors.Orchid, 4: DataColors.Lilac },
  adUnitLevel3: { 0: DataColors.SkyBlue, 1: DataColors.Sun, 2: DataColors.Orchid, 3: DataColors.Lilac, 4: DataColors.Petrol },
  adUnitLevel4: { 0: DataColors.Sun, 1: DataColors.Orchid, 2: DataColors.Lilac, 3: DataColors.Petrol, 4: DataColors.SkyBlue },
  adUnitLevel5: { 0: DataColors.Orchid, 1: DataColors.Lilac, 2: DataColors.Petrol, 3: DataColors.SkyBlue, 4: DataColors.Sun },
  abTestPopulation: { 0: DataColors.Violet, 1: DataColors.Kaiminus, 2: DataColors.Lilac, 3: DataColors.Sun },
}

const nonCategorizedData = 'Non categorized data'
export const GAM_OVERVIEW_BREAKDOWN_GAUGE_TOOLTIPS = {
  'Google demand': 'Including: Ad Exchange, Ad sense, Ad Mob, Preferred deals',
  'Guaranteed campaign': 'Including: Sponsorship, Standard campaigns',
  'Non Guaranteed campaign': 'Including: Price priority, Bulk, Network',
  Filler: 'Including: House',
  Other: nonCategorizedData,
  'Unmapped Adunit': nonCategorizedData,
  'Unmapped Site': nonCategorizedData,
  [GAM_DASHBOARD_NOT_APPLICABLE_AD_UNIT_LEVEL]: nonCategorizedData,
  [GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST]: nonCategorizedData,
} as const

export const GAM_OVERVIEW_BREAKDOWN_REVENUE_TOOLTIPS = {
  'Guaranteed campaign': ANALYTICS_TOOLTIPS.GAM_REVENUE,
  'Non Guaranteed campaign': ANALYTICS_TOOLTIPS.GAM_REVENUE,
  Filler: ANALYTICS_TOOLTIPS.GAM_REVENUE,
} as const

export const GAM_OVERVIEW_BREAKDOWN_ECPM_TOOLTIPS = {
  'Guaranteed campaign': ANALYTICS_TOOLTIPS.GAM_REVENUE,
  'Non Guaranteed campaign': ANALYTICS_TOOLTIPS.GAM_REVENUE,
  Filler: ANALYTICS_TOOLTIPS.GAM_REVENUE,
} as const
