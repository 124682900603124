import { StackContext } from '@pubstack/common/src/stackContext'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContextKeys, useContexts } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackContextsPage } from './PureAdStackContextsPage'

export const AdStackContextsPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const [contexts, setContexts] = useState<StackContext[]>([])
  const { all: allContexts, byId: contextsById } = useContexts(null)
  const { all: allContextKeys } = useContextKeys(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const toast = useToast()
  const loadContextPageData = async () => {
    if (allContexts.loading) {
      allContexts.abort()
    }
    const result = await allContexts.get()
    setContexts(result)
  }

  async function onCreateContext() {
    const contextKeys = await allContextKeys.get()
    if (contextKeys?.length > 0) {
      navigate(`/adstack/context/contexts/new`)
    } else {
      setIsModalVisible(true)
    }
  }

  const archiveContext = async (context: StackContext) => {
    try {
      await contextsById.put({ ...context, enabled: false })
      toast.success(
        <>
          <strong>{context.name}</strong> was successfuly archived.
        </>
      )
      await loadContextPageData()
    } catch (e) {
      toast.alert(
        <>
          An error occurred while trying to archive <strong>{context.name}</strong>.
        </>
      )
    }
  }

  const unArchiveContext = async (context: StackContext) => {
    try {
      await contextsById.put({ ...context, enabled: true })
      toast.success(
        <>
          <strong>{context.name}</strong> was successfuly unarchived.
        </>
      )
      await loadContextPageData()
    } catch (e) {
      toast.alert(
        <>
          An error occurred while trying to unarchive <strong>{context.name}</strong>.
        </>
      )
    }
  }

  useEffect(() => {
    loadContextPageData()
  }, [])

  return (
    <PureAdStackContextsPage
      contexts={contexts}
      isLoading={allContexts.loading}
      onCreateContext={onCreateContext}
      onArchiveContext={archiveContext}
      onUnArchiveContext={unArchiveContext}
      breadcrumbs={breadcrumbs}
      isModalVisible={isModalVisible}
      setIsOpen={setIsModalVisible}
      onCreateContextKey={() => navigate(`/adstack/context/context-keys`)}
    />
  )
}
