import styled from '@emotion/styled'
import { AdUnit, getAdUnitMediatypes } from '@pubstack/common/src/adunit'
import { FunctionComponent } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import Button from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { Input } from '~/components/Input'
import { Qualifier } from '~/components/Qualifier'
import Table, { handleTableSearch, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'

const NoWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`

const SearchBar = styled.form`
  margin-bottom: 12px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  ${Button} {
    margin-left: 16px;
    margin-top: 4px;
  }
`

const Behaviour = styled.div`
  display: flex;
  gap: 8px;
`
const EllipsisWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
`

type AdUnitSelectionTableProps = WithClassName & {
  selectableAdUnits: AdUnit[]
  selectedAdUnits: AdUnit[]
  onAdUnitsSelection: (adUnits: AdUnit[]) => unknown
}
const _AdUnitSelectionTable: FunctionComponent<AdUnitSelectionTableProps> = ({ className, selectableAdUnits, selectedAdUnits, onAdUnitsSelection }) => {
  const {
    resetField,
    control,
    formState: { isDirty },
  } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })
  const search = useWatch({ control, name: 'search' })

  const displayedRows = handleTableSearch(selectableAdUnits, search, ['name'])

  const isSelected = (adUnit: AdUnit) => selectedAdUnits.some((a) => a.id === adUnit.id)

  function getUniqueListBy(arr: AdUnit[]) {
    return [...new Map(arr.map((item) => [item['id'], item])).values()]
  }
  const onSelectAll = () => {
    if (displayedRows.every(isSelected)) {
      onAdUnitsSelection(selectedAdUnits.filter((s) => !displayedRows.some((a) => a.id === s.id)))
    } else {
      onAdUnitsSelection(getUniqueListBy([...selectedAdUnits, ...displayedRows]))
    }
  }

  const columns: TableColumns = [
    {
      name: (
        <NoWrap>
          <Checkbox checked={displayedRows.every(isSelected)} onClick={onSelectAll} /> Ad unit
        </NoWrap>
      ),
      isSortable: false,
    },
    { name: <NoWrap>Ad server name</NoWrap>, isSortable: false },
    { name: <NoWrap>Div ID</NoWrap>, isSortable: false },
    { name: 'Demand', isSortable: false },
    { name: 'Settings', isSortable: false },
  ]

  const onSelection = (row: AdUnit) => {
    if (isSelected(row)) {
      onAdUnitsSelection(selectedAdUnits.filter((s) => s.id !== row.id))
    } else {
      onAdUnitsSelection([...selectedAdUnits, row])
    }
  }

  const getTooltip = (state: boolean) => {
    return state ? 'allowed' : 'disabled'
  }

  return (
    <>
      <SearchBar>
        <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
        {isDirty && <Button iconName={'close'} variant={'tertiary'} onClick={() => resetField('search')} />}
      </SearchBar>
      <Table className={className} columns={columns} isLoading={false}>
        {displayedRows.map((row) => (
          <TableRow
            key={row.id}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onSelection(row)
            }}
          >
            <TableCell>
              <NoWrap>
                <Checkbox
                  checked={isSelected(row)}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onSelection(row)
                  }}
                />
                {row.name}
              </NoWrap>
            </TableCell>
            <TableCell>
              <EllipsisWrapper title={row.adServerAdUnitName}>{row.adServerAdUnitName}</EllipsisWrapper>
            </TableCell>
            <TableCell>
              <EllipsisWrapper title={row.divId}>{row.divId}</EllipsisWrapper>
            </TableCell>
            <TableCell>
              <Qualifier iconName={'header_bidding'} tooltipText={`Header bidding ${getTooltip(row.headerBiddingEnabled)}`} active={row.headerBiddingEnabled} enabled={true} />
            </TableCell>
            <TableCell>
              <Behaviour>
                <Qualifier iconName={'dynamic'} tooltipText={`Dynamic ${getTooltip(row.dynamicEnabled)}`} active={row.dynamicEnabled} enabled={true} />
                <Qualifier iconName={'banner'} tooltipText={`Banner ${getTooltip(getAdUnitMediatypes(row).includes('Banner'))}`} active={getAdUnitMediatypes(row).includes('Banner')} enabled={true} />
                <Qualifier
                  iconName={'outstream2'}
                  tooltipText={`Outstream ${getTooltip(getAdUnitMediatypes(row).includes('Outstream'))}`}
                  active={getAdUnitMediatypes(row).includes('Outstream')}
                  enabled={true}
                />
              </Behaviour>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  )
}
export const AdUnitSelectionTable = styled(_AdUnitSelectionTable)``
