import OpenReplay from '@openreplay/tracker'
import trackerFetch from '@openreplay/tracker-fetch'
import { StartOptions } from '@openreplay/tracker/lib/app'
import { FunctionComponent, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useUser } from '~/auth/user.hooks'
import { transactionId } from '~/main'
import { currentScopeState } from '~/state'
import { getEnvFromUrl } from '~/utils/environment'

export const OpenReplayIntegration: FunctionComponent = () => {
  const user = useUser()
  const scope = useRecoilValue(currentScopeState)
  useEffect(() => {
    const tracker = new OpenReplay({
      projectKey: '1u8A41sJzmrst7oIkXE8',
    })

    tracker.use(trackerFetch({ overrideGlobal: true }))
    let startOpts: Partial<StartOptions> = {}
    if (user) {
      startOpts = {
        userID: user.profile.email,
        metadata: {
          scope: scope.name,
          transactionId,
          environment: getEnvFromUrl(window.location.host),
        },
      }
    }
    tracker.start(startOpts)
  }, [])

  return null
}
