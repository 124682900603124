import { AdUnit } from '@pubstack/common/src/adunit'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAdUnits, useSitesStacks } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useGetBiddersWithOutstream } from '~/utils/useGetBiddersWithOutstream'
import { PureAdStackAdUnitEditPage } from './PureAdStackAdUnitEditPage'

const emptyAdunit: AdUnit = {
  id: '',
  name: '',
  divId: '',
  adServerAdUnitName: '',
  scopeId: '',
  enabled: true,
  creationTime: 1,
  devices: [],
  sizes: [],
  customSizes: [],
  fluid: false,
  canOutstream: false,
  dynamicEnabled: false,
  headerBiddingEnabled: true,
  slotKeyValues: [],
  biddersOutstream: [],
  containerMaxSize: {
    height: undefined,
    width: undefined,
  },
}

export const AdStackAdUnitEditPage = () => {
  const navigate = useNavigate()
  const [adUnit, setAdUnit] = useState<AdUnit>(emptyAdunit)
  const { adUnitId } = useParams()
  const toast = useToast()
  const { byId, validateName } = useAdUnits(null)
  const breadcrumbs = useBreadcrumbs(adUnit)
  const { getBiddersWithOutstream } = useGetBiddersWithOutstream()
  const isSaving = useRef(false)
  const { all: allStacks } = useSitesStacks(null, '')

  async function loadAdUnit(adUnitId?: string) {
    if (adUnitId) {
      try {
        const adUnitToUpdate = await byId.get(adUnitId)
        setAdUnit({ ...adUnitToUpdate, containerMaxSize: adUnitToUpdate.containerMaxSize ?? { width: undefined, height: undefined } })
      } catch (exception) {
        toast.alert('An error occurred while fetching this adUnit.')
        throw exception
      }
    }
  }

  async function createAdUnit(adUnit: AdUnit) {
    if (isSaving.current) return
    isSaving.current = true
    try {
      adUnit = { ...adUnit, biddersOutstream: await getBiddersWithOutstream() }
      const newAdUnit: AdUnit = await byId.post(adUnit)
      setAdUnit(newAdUnit)
      toast.success(`${newAdUnit.name} added to your inventory.`)
      isSaving.current = false
      navigate('/adstack/adunits')
    } catch (exception) {
      toast.alert('An error occurred while saving this adUnit.')
      isSaving.current = false
      throw exception
    }
  }

  async function updateAdUnit(adUnit: AdUnit) {
    if (isSaving.current) return
    isSaving.current = true
    try {
      const updatedAdUnit: AdUnit = await byId.put(adUnit)
      setAdUnit(updatedAdUnit)
      const result = await allStacks.fetchSiteStacks()
      toast.admSuccess(updatedAdUnit.name, !!result[updatedAdUnit.name], navigate)
      isSaving.current = false
      navigate('/adstack/adunits')
    } catch (exception) {
      toast.alert('An error occurred while saving this adUnit.')
      isSaving.current = false
      throw exception
    }
  }

  const validateAdUnitUniqueName = async (name?: string) => {
    return name ? validateName(name) : true
  }

  useEffect(() => {
    loadAdUnit(adUnitId)
  }, [adUnitId])

  return (
    <PureAdStackAdUnitEditPage
      isLoading={byId.loading}
      isEditing={!!adUnit.id}
      adUnit={adUnit}
      breadcrumbs={breadcrumbs}
      onCreateAdUnit={createAdUnit}
      onUpdateAdUnit={updateAdUnit}
      onAdUnitsEditBackClick={() => navigate(-1)}
      validateUniqueName={validateAdUnitUniqueName}
      isSaving={isSaving.current}
    />
  )
}

export default AdStackAdUnitEditPage
