import styled from '@emotion/styled'
import { AdUnit } from '@pubstack/common/src/adunit'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FloorRule, LazyLoadingRule, RefreshRule } from '@pubstack/common/src/stack'
import { FunctionComponent } from 'react'
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { _Input } from '~/components/Input'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { WithClassName } from '~/types/utils'
import { displayWithCurrency } from '~/utils/string'
import { StackFormData } from './PureStackEdit'
import { AdunitDeviceRuleSelector } from './components/AdunitDeviceRuleSelector'
import { LazyLoadingRuleSelector } from './components/LazyLoadingRuleSelector'

const Subtitle = styled.div`
  ${Fonts.P1};
  ${Colors.Jet};
  margin-bottom: 20px;
`

const Title = styled.h2`
  ${Fonts.H2};
  ${Colors.Jet};
  font-weight: 500;
`

const HeaderBiddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > ${_Input} {
    width: 132px;
  }
`

const LazyLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 4px;
`

const AdunitDeviceRulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const CommitMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const CommitSubtitle = styled.div`
  ${Fonts.P1};
  ${Colors.Jet};
`

const CommitTextArea = styled.textarea`
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  resize: none;
  border: 1px solid ${Colors.Platinum};
  white-space: pre;
  height: 120px;
  width: 100%;
  background-color: ${Colors.White};
  padding: 10px 12px;
  margin-bottom: 4px;
  :placeholder-shown {
    font-style: italic;
    ${Fonts.P1};
    ${Colors.SlateGrey};
  }
  &:active,
  &:focus {
    border-color: ${Colors.Silver};
  }
`

const Divider = styled.div`
  margin: 20px 0;
  height: 1px;
  max-height: 1px;
  min-height: 1px;
  flex: 1;
  background-color: ${Colors.Platinum};
`

type PureStackRulesStepProps = WithClassName & {
  control: Control<StackFormData>
  trigger: UseFormTrigger<StackFormData>
  setValue: UseFormSetValue<StackFormData>
  errors: FieldErrors<StackFormData>
  selectableAdUnits: AdUnit[]
  selectableFloorRules: FloorRule[]
  selectableRefreshRules: RefreshRule[]
  selectableLazyLoadingRules: LazyLoadingRule[]
  currencySymbol: CurrencySymbol
  siteName: string
  hasAdmRefresh: boolean
}
const _PureStackRulesStep: FunctionComponent<PureStackRulesStepProps> = ({
  className,
  control,
  selectableLazyLoadingRules,
  selectableFloorRules,
  selectableRefreshRules,
  selectableAdUnits,
  currencySymbol,
  siteName,
  hasAdmRefresh,
  trigger,
  setValue,
}) => {
  const lazyLoadingSelectOptions: SelectOptionProp<string>[] = [...selectableLazyLoadingRules.map((l) => ({ label: l.name, value: l.id })), { label: 'No lazy loading', value: 'NO_RULE' }]
  const floorSelectOptions: SelectOptionProp<string>[] = [
    ...selectableFloorRules.map((l) => ({ label: `${l.name} (${displayWithCurrency((l.floor / 100).toFixed(2), currencySymbol)})`, value: l.id })),
    { label: 'No floor price', value: 'NO_RULE' },
  ]
  const refreshSelectOptions: SelectOptionProp<string>[] = [
    ...selectableRefreshRules.map((refreshRule) => ({ label: refreshRule.name, value: refreshRule.id })),
    { label: 'No refresh', value: 'NO_RULE' },
  ]

  const [desktopAdUnits, mobileAdUnits] = useWatch({ control, name: ['desktopAdUnits', 'mobileAdUnits'] })
  const availableAdUnits = {
    desktop: selectableAdUnits.filter((adUnit) => desktopAdUnits?.map((adUnitConfig) => adUnitConfig.adUnitId).includes(adUnit.id)),
    mobile: selectableAdUnits.filter((adUnit) => mobileAdUnits?.map((adUnitConfig) => adUnitConfig.adUnitId).includes(adUnit.id)),
  }

  return (
    <div className={className}>
      <Subtitle>Set the rules applied to this stack.</Subtitle>
      <LazyLoadingWrapper>
        <LazyLoadingRuleSelector control={control} availableDevices={['desktop', 'mobile']} availableRules={lazyLoadingSelectOptions} />
      </LazyLoadingWrapper>
      <Divider />
      <AdunitDeviceRulesWrapper>
        <AdunitDeviceRuleSelector
          fieldRulesName={'floorsRules'}
          title={'Floor price'}
          setValue={setValue}
          trigger={trigger}
          control={control}
          availableAdUnits={availableAdUnits}
          availableDevices={['desktop', 'mobile']}
          availableRules={floorSelectOptions}
        />
      </AdunitDeviceRulesWrapper>
      <Divider />
      {hasAdmRefresh && (
        <>
          <AdunitDeviceRulesWrapper>
            <AdunitDeviceRuleSelector
              fieldRulesName={'refreshRules'}
              title={'Refresh'}
              extraInformation={'Only the ad units with refresh enabled are available below.'}
              setValue={setValue}
              trigger={trigger}
              control={control}
              availableAdUnits={availableAdUnits}
              availableDevices={['desktop', 'mobile']}
              availableRules={refreshSelectOptions}
            />
          </AdunitDeviceRulesWrapper>
          <Divider />
        </>
      )}
      <CommitMessageWrapper>
        <CommitSubtitle>Keep track, describe the stack.</CommitSubtitle>
        <Controller
          control={control}
          name={'commitMessage'}
          render={({ field: { value: commitMessage, onChange } }) => {
            return <CommitTextArea value={commitMessage} onChange={onChange} placeholder={`Just created a stack for ${siteName}`} />
          }}
        />
      </CommitMessageWrapper>
    </div>
  )
}

export const PureStackRulesStep = styled(_PureStackRulesStep)``
