import styled from '@emotion/styled'
import { AdUnit } from '@pubstack/common/src/adunit'
import { noStartDigitAndSpaceValidation } from '@pubstack/common/src/input'
import { FunctionComponent, useEffect } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { BaseChipInput, ChipListInput } from '~/components/ChipListInput'
import { Input, _Input } from '~/components/Input'

type AdUnitKeyValues = Required<AdUnit>['slotKeyValues']

const KeyValueForm = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
`
const KeyValueWrapper = styled.section`
  display: inline-flex;
  gap: 20px;
  align-items: baseline;
  ${_Input} {
    width: 304px;
  }
  ${BaseChipInput} {
    ${_Input} {
      width: 576px;
    }
  }

  & ${Button} {
    align-self: center;
  }
`

export const AdUnitKeyValuesForm: FunctionComponent<{
  keyValues: AdUnitKeyValues
  updateAdUnit: (keyValueTargetings: AdUnitKeyValues) => unknown
}> = ({ keyValues, updateAdUnit }) => {
  const {
    control,
    formState: { isValid, errors },
  } = useForm<{ adUnitKeys: AdUnitKeyValues }>({
    defaultValues: {
      adUnitKeys: keyValues.length ? keyValues : [],
    },
    mode: 'onChange',
  })
  const { fields, append, remove } = useFieldArray({ control, name: 'adUnitKeys' })

  const formKeyValues = useWatch({
    control,
    name: 'adUnitKeys',
  })

  useEffect(() => {
    updateAdUnit([...formKeyValues.filter((v) => !!v.key && !!v.values.length)])
  }, [formKeyValues])

  return (
    <KeyValueForm>
      {fields.map((field, index) => (
        <KeyValueWrapper key={field.id}>
          <Input
            error={errors?.adUnitKeys?.[index]?.key?.message}
            label={'Key'}
            control={control}
            name={`adUnitKeys.${index}.key`}
            rules={{
              required: { value: !!formKeyValues[index]?.values?.length, message: 'Key required' },
              pattern: { value: noStartDigitAndSpaceValidation, message: 'Incorrect syntax' },
            }}
          />
          <ChipListInput
            chipColor={Colors.Turquoise}
            isColorVariable
            helper={'Type a value then press Enter or Space (max 10 values)'}
            label={'Values'}
            control={control}
            name={`adUnitKeys.${index}.values`}
            error={errors?.adUnitKeys?.[index]?.values?.message}
            rules={{
              required: { value: formKeyValues.length > 1 || !!formKeyValues[index]?.key?.length, message: 'Minimum one value required (max 10 values)' },
            }}
            maxChipCount={10}
          />
          <Button
            onClick={() => {
              remove(index)
            }}
            variant={'tertiary'}
            iconName={'delete'}
          />
        </KeyValueWrapper>
      ))}
      {fields.length < 5 && (
        <Button variant={'secondary'} iconName={'add'} disabled={!isValid} onClick={() => append({ key: '', values: [] }, { shouldFocus: false })}>
          Add key-value
        </Button>
      )}
    </KeyValueForm>
  )
}
