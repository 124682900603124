import styled from '@emotion/styled'
import { BidMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { Chart } from 'react-google-charts'
import type { ChartWrapperOptions } from 'react-google-charts/dist/types'
import { DataColors } from '~/assets/style/colors'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'
import { Icon } from '~/components/Icon'
import { Tooltip } from '~/components/Tooltip'
import { Widget, WidgetProps } from '~/components/Widget'
import { AnalyticsDefaultChartOptions, ChartContainer, ChartWrapperSmall } from '~/modules/analytics/AnalyticsCharts'
import {
  asPercentage,
  bidCpm,
  bidRate,
  bidRequests,
  bidResponseCpmSum,
  bidResponses,
  bidWinCpm,
  hbBidWinCount,
  hbECPM,
  hbImpressionCount,
  hbImpressionRevenue,
  hbWinRate,
  hbWinningCpmSum,
  impressionRate,
} from '~/modules/analytics/formulas'
import { Formula } from '~/modules/analytics/formulas/operation'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

export type BidderDetailsFunnelChartMetricTooltip = {
  name: string
  displayable: (data: BidMetricsByTime) => string
}
type FunnelChartTooltipProps = WithClassName & {
  title: string
  metrics: BidderDetailsFunnelChartMetricTooltip[]
  data: BidMetricsByTime
}
const _FunnelChartTooltip: FunctionComponent<FunnelChartTooltipProps> = ({ title, metrics, data }) => {
  const metricsLines: ChartTooltipMetricProps[] = metrics.map((metric) => {
    const value = metric.displayable(data)
    return {
      label: metric.name,
      value,
      iconName: 'empty',
    }
  })
  return <ChartTooltip date={title} metrics={[metricsLines]} />
}
const BidderDetailsFunnelChartTooltip = styled(_FunnelChartTooltip)``

type BidderDetailsFunnelMetric = {
  name: string
  formula: Formula<any>
  compute?: (data: BidMetricsByTime, index: number) => number
  sum?: (data: BidMetricsByTime) => number
  displayable?: (value: number) => string
  tooltip?: string
  annotation: string | undefined
}
const TooltipContent = () => (
  <>
    <div>
      {bidRequests.name}: {ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_REQUESTS}
    </div>
    <br />
    <div>
      {bidResponses.name}: {ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_RESPONSES}
    </div>
    <br />
    <div>
      {hbBidWinCount.name}: {ANALYTICS_TOOLTIPS.BIDDER_DETAILS_PREBID_WIN_BIDS}
    </div>
    <br />
    <div>
      {hbImpressionCount.name}: {ANALYTICS_TOOLTIPS.BIDDER_DETAILS_PREBID_IMPRESSIONS}
    </div>
  </>
)

const getTooltip = (metric: BidderDetailsFunnelMetric, data: BidMetricsByTime, currencySymbol: CurrencySymbol) => {
  let title = ''
  let metrics: BidderDetailsFunnelChartMetricTooltip[] = []

  const bidResponsesSum = bidResponses.isComputable(data) ? bidResponses.sum(data) : 0
  const bidWinCountSum = hbBidWinCount.isComputable(data) ? hbBidWinCount.sum(data) : 0
  const impressionCountSum = hbImpressionCount.isComputable(data) ? hbImpressionCount.sum(data) : 0
  const bidResponseCpmSumSum = bidResponseCpmSum.isComputable(data) ? bidResponseCpmSum.sum(data) : 0
  const headerBiddingWinningCpmSumSum = hbWinningCpmSum.isComputable(data) ? hbWinningCpmSum.sum(data) : 0
  if (metric.name === 'bidRequests') {
    title = bidRequests.name
    metrics = [
      {
        name: 'Count',
        displayable: () => (bidRequests.isComputable(data) ? bidRequests.displayable(bidRequests.sum(data)) : 'NA'),
      },
    ]
  } else if (metric.name === 'bidResponses') {
    title = bidResponses.name
    metrics = [
      {
        name: 'Count',
        displayable: () => `${bidResponses.displayable(bidResponsesSum)} (${bidRate.isComputable(data) ? bidRate.displayable(bidRate.sum(data)) : 'NA'})`,
      },
      {
        name: 'Amount',
        displayable: () => bidResponseCpmSum.displayable(bidResponseCpmSumSum, currencySymbol),
      },
      {
        name: bidCpm.name,
        displayable: () => bidCpm.displayable(bidCpm.isComputable(data) ? bidCpm.sum(data) : 0, currencySymbol),
      },
      {
        name: bidRate.name,
        displayable: () => (bidRate.isComputable(data) ? bidRate.displayable(bidRate.sum(data)) : 'NA'),
      },
    ]
  } else if (metric.name === 'hbBidWinCount') {
    title = hbBidWinCount.name
    metrics = [
      {
        name: 'Count',
        displayable: () => `${hbBidWinCount.displayable(bidWinCountSum)} (${bidRequests.isComputable(data) ? asPercentage(bidRequests.percentage(bidWinCountSum, data) * 100) : 'NA'})`,
      },
      {
        name: 'Amount',
        displayable: () =>
          `${hbWinningCpmSum.displayable(hbWinningCpmSum.isComputable(data) ? hbWinningCpmSum.sum(data) : 0, currencySymbol)} (${(
            (headerBiddingWinningCpmSumSum / bidResponseCpmSumSum) *
            100
          ).toPrecision(3)}%)`,
      },
      {
        name: bidWinCpm.name,
        displayable: () => bidWinCpm.displayable(bidWinCpm.isComputable(data) ? bidWinCpm.sum(data) : 0, currencySymbol),
      },
      {
        name: hbWinRate.name,
        displayable: () => hbWinRate.displayable(hbWinRate.isComputable(data) ? hbWinRate.sum(data) : 0),
      },
    ]
  } else if (metric.name === 'hbImpressionCount') {
    title = hbImpressionCount.name
    metrics = [
      {
        name: 'Count',
        displayable: () => `${hbImpressionCount.displayable(impressionCountSum)} (${bidRequests.isComputable(data) ? asPercentage(bidRequests.percentage(impressionCountSum, data) * 100) : 'NA'})`,
      },
      {
        name: 'Revenue',
        displayable: () =>
          `${hbImpressionRevenue.displayable(hbImpressionRevenue.isComputable(data) ? hbImpressionRevenue.sum(data) : 0, currencySymbol)} (${(
            ((hbImpressionRevenue.isComputable(data) ? hbImpressionRevenue.sum(data) : 0) / bidResponseCpmSumSum) *
            100
          ).toPrecision(3)}%)`,
      },
      {
        name: hbECPM.name,
        displayable: () => hbECPM.displayable(hbECPM.isComputable(data) ? hbECPM.sum(data) : 0, currencySymbol),
      },
      {
        name: impressionRate.name,
        displayable: () => impressionRate.displayable(impressionRate.isComputable(data) ? impressionRate.sum(data) : 0),
      },
    ]
  }
  return ReactDOMServer.renderToString(<BidderDetailsFunnelChartTooltip title={title} metrics={metrics} data={data} />)
}

const convertData = (data: BidMetricsByTime, metrics: BidderDetailsFunnelMetric[], currencySymbol: CurrencySymbol) => {
  if (!data.epoch.length) {
    return []
  }
  return metrics.map((metric) => [metric.formula.name, getTooltip(metric, data, currencySymbol), metric.formula.isComputable(data) ? metric.formula.sum(data) : 0, metric.annotation])
}

type BuildedData = {
  chartData: any[][]
  chartOptions: ChartWrapperOptions['options']
}
const buildData = ({ data, currencySymbol }: { data: BidMetricsByTime; currencySymbol: CurrencySymbol }): BuildedData => {
  const metrics: BidderDetailsFunnelMetric[] = data.epoch.length
    ? [
        { name: 'bidRequests', formula: bidRequests, annotation: undefined },
        {
          name: 'bidResponses',
          formula: bidResponses,
          annotation: bidRate.isComputable(data) ? `${bidRate.name} ${bidRate.displayable(bidRate.sum(data))}` : undefined,
        },
        {
          name: 'hbBidWinCount',
          formula: hbBidWinCount,
          annotation: `${hbWinRate.name} ${hbWinRate.displayable(hbWinRate.isComputable(data) ? hbWinRate.sum(data) : 0)}`,
        },
        {
          name: 'hbImpressionCount',
          formula: hbImpressionCount,
          annotation: `${impressionRate.name} ${impressionRate.displayable(impressionRate.isComputable(data) ? impressionRate.sum(data) : 0)}`,
        },
      ]
    : []
  const chartOptions: ChartWrapperOptions['options'] = {
    ...AnalyticsDefaultChartOptions,
    bar: { groupWidth: '100%' },
    seriesType: 'bars',
    crosshair: { ...AnalyticsDefaultChartOptions.crosshair, trigger: 'both' },
    vAxis: {
      ...AnalyticsDefaultChartOptions.vAxis,
      gridlines: { count: -1 },
      format: 'short',
      minValue: 0,
    },
    annotations: {
      style: 'point',
      alwaysOutside: true,
    },
    colors: [DataColors.Ming],
    isStacked: false,
  }
  const chartData = [
    [
      'Label',
      { type: 'string', role: 'tooltip', p: { html: true } },
      'value',
      {
        type: 'string',
        role: 'annotation',
      },
    ],
    ...convertData(data, metrics, currencySymbol),
  ]

  return {
    chartData,
    chartOptions,
  }
}

type PureBidderWidgetFunnelProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon' | 'info'> & {
    data: BidMetricsByTime
    currencySymbol: CurrencySymbol
  }
const _PureBidderWidgetFunnel: FunctionComponent<PureBidderWidgetFunnelProps> = ({ data, currencySymbol, ...props }) => {
  const { chartData, chartOptions } = buildData({ data, currencySymbol })
  return (
    <Widget
      {...props}
      icon={'bidder'}
      title={'Bidder funnel'}
      info={
        <Tooltip title={<TooltipContent />} positions={['left']} align={'start'}>
          <Icon name={'help_outline'} />
        </Tooltip>
      }
    >
      <ChartContainer>
        <ChartWrapperSmall>
          {!!data.epoch.length && <Chart chartType={'ColumnChart'} width={'100%'} height={'100%'} options={chartOptions} data={chartData} chartVersion={'upcoming'} />}
        </ChartWrapperSmall>
      </ChartContainer>
    </Widget>
  )
}
export const PureBidderWidgetFunnel = styled(_PureBidderWidgetFunnel)``
