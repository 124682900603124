import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { UserViewMode } from '@pubstack/common/src/rolesAndFeatures'
import { Scope } from '@pubstack/common/src/scope'
import { FunctionComponent, VoidFunctionComponent, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useToggleView } from '~/api/admin-api.hook'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import User from '~/auth/User'
import { useUser } from '~/auth/user.hooks'
import { Icon, IconName } from '~/components/Icon'
import { loadingState } from '~/state'
import { useLogger } from '~/utils/logger'
import { copyToClipboard } from '~/utils/string'

const ProfilePopoverContentWrapper = styled.div`
  ${BorderRadius.style}
  display: flex;
  flex-direction: column;
`

const ProfilePopoverLine = styled.div`
  ${Fonts.colors.Hurricane}
  background: ${Colors.White};
  padding: 8px 22px 8px 12px;
  display: inline-flex;
  gap: 4px;
  user-select: none;
  cursor: pointer;
  align-items: center;

  &:hover {
    background: ${Colors.Topaze};
  }
`

const ProfileSwitchViewMode: FunctionComponent<{ mode: UserViewMode; labelOn: string; labelOff: string; condition: (user: User | null) => boolean; icon: IconName }> = ({
  mode,
  labelOn,
  labelOff,
  icon,
  condition,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const user = useUser()
  const { byId: toggleView } = useToggleView(null)
  const [, setLoading] = useRecoilState(loadingState)
  const toggleViewMode = async (mode: UserViewMode) => {
    const loading = toggleView.post({ view: user?.view === mode ? undefined : mode }).then(() => getAccessTokenSilently({ cacheMode: 'off' }))
    setLoading(true)
    await loading
    window.location.reload()
  }
  const label = user?.view === mode ? labelOff : labelOn
  const display = condition(user)
  return display ? (
    <ProfilePopoverLine onClick={() => toggleViewMode(mode)}>
      <Icon width={'20px'} name={icon} />
      <span>{label}</span>
    </ProfilePopoverLine>
  ) : null
}

const ProfilePopoverContent: VoidFunctionComponent<{ currentScope: Scope }> = ({ currentScope }) => {
  const logger = useLogger()
  const { logout } = useAuth0()
  const [copyIcon, setCopyIcon] = useState<IconName>('copy')

  const onClickCopy = (scopeId: string) => {
    //in fine the copy will be available
    setTimeout(() => {
      setCopyIcon('copy')
    }, 900)
    try {
      copyToClipboard(window.document, scopeId)
      setCopyIcon('check')
    } catch (exception) {
      setCopyIcon('alert')
      throw exception
    }
  }

  const onLogoutClick = () => {
    logger.info({ action: 'click', type: 'logout' })
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <ProfilePopoverContentWrapper>
      <ProfileSwitchViewMode mode={'owner'} icon={'view'} labelOff={'View as Pubstack'} labelOn={'View as owner'} condition={(user) => !!(user?.view === 'owner' || user?.isAdmin)} />
      <ProfileSwitchViewMode mode={'user'} icon={'view'} labelOff={'View as Pubstack'} labelOn={'View as user'} condition={(user) => !!(user?.view === 'user' || user?.isAdmin)} />
      <ProfileSwitchViewMode mode={'demo'} icon={'present'} labelOff={'Leave demo mode'} labelOn={'Demo mode'} condition={(user) => !!(user?.view || user?.isAdmin)} />
      <ProfilePopoverLine onClick={() => onClickCopy(currentScope.id)}>
        <Icon width={'20px'} name={copyIcon} />
        <span>Copy scope ID</span>
      </ProfilePopoverLine>

      <ProfilePopoverLine onClick={onLogoutClick}>
        <Icon width={'20px'} name={'logout'} />
        <span>Log out</span>
      </ProfilePopoverLine>
    </ProfilePopoverContentWrapper>
  )
}

export default ProfilePopoverContent
