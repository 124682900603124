export type AdmQaReportsTimeRange = keyof typeof ADM_QA_REPORTS_TIME_RANGES

export const ADM_QA_REPORTS_TIME_RANGES = {
  '30-minutes': {
    span: {
      minutes: 30,
    },
    minus: {
      minutes: 31,
    },
    plus: {
      minutes: 31,
    },
  },
  '1-hour': {
    span: {
      hour: 1,
    },
    minus: {
      hour: 1,
      minute: 1,
    },
    plus: {
      hour: 1,
      minute: 1,
    },
  },
  '2-hours': {
    span: {
      hour: 2,
    },
    minus: {
      hour: 3,
    },
    plus: {
      hour: 3,
    },
  },
  '1-day': {
    span: {
      day: 1,
    },
    minus: {
      day: 1,
    },
    plus: {
      day: 1,
    },
  },
  '1-day-last-week': {
    span: {
      day: 1,
    },
    minus: {
      day: 6,
    },
    plus: {
      day: 1,
    },
  },
  '1-week': {
    span: {
      week: 1,
    },
    minus: {
      day: 8,
    },
    plus: {
      day: 8,
    },
  },
} as const

export enum AdmQAReportCategory {
  BIDS = 'bids',
  AUCTIONS_IMPRESSIONS = 'auction_impressions',
  VIEWABLITY = 'viewability',
  USER_SESSION = 'user_session',
}

export type AdmQaReport = {
  id: string
  range: AdmQaReportsTimeRange
  admLiveDate: string
  createdAt: string
  scopeId: string
  siteIdA: string
  startDateSiteA: string
  endDateSiteA: string
  siteIdB: string
  startDateSiteB: string
  endDateSiteB: string
}
