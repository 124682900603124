import { useAuth0 } from '@auth0/auth0-react'
import { ReactNode, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { CachePolicies, Provider } from 'use-http'
import { IncomingOptions } from 'use-http/dist/cjs/types'
import { useUser } from '~/auth/user.hooks'
import { configurationState } from '~/state'

export const HttpProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently } = useAuth0()
  const appConfig = useRecoilValue(configurationState)
  const user = useUser()
  const options = useMemo<IncomingOptions>(
    () => ({
      cachePolicy: CachePolicies.NO_CACHE,
      interceptors: {
        request: async ({ options }) => {
          const headers = new Headers(options.headers)
          const accessToken = await getAccessTokenSilently()
          headers.append('x-scope-id', user?.currentScopeId || '')
          headers.append('Authorization', `Bearer ${accessToken}`)
          return {
            ...options,
            headers,
          }
        },
      },
    }),
    [getAccessTokenSilently, user, user?.currentScopeId]
  )

  return (
    <Provider url={appConfig.BACKEND_ENDPOINT} options={options}>
      {children}
    </Provider>
  )
}
