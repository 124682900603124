import { Color, Colors } from '~/assets/style/colors'
import { IconName } from '~/components/Icon'

export const SETTINGS_LABELS = ['profile', 'members', 'security', 'sites', 'refreshGroups', 'refreshExceptions', 'abTest'] as const
type SettingsAccessLabels = typeof SETTINGS_LABELS
export type SettingsAccessLabel = SettingsAccessLabels[number]

export const SETTINGS_NAV_CONFIG: { [key in SettingsAccessLabel]: { title: string; content: string; color: Color; icon: IconName } } = {
  profile: {
    title: 'Profile',
    content: 'Manage your personal information and preferences.',
    color: Colors.Milka,
    icon: 'account_circle',
  },
  members: {
    title: 'Members',
    content: 'See your team members.',
    color: Colors.Violet,
    icon: 'group',
  },
  sites: {
    title: 'Sites',
    content: 'Add and remove sites connected to Pubstack.',
    color: Colors.Sun,
    icon: 'site',
  },
  refreshGroups: {
    title: 'Refresh groups',
    content: 'Manage different rules on different segments.',
    color: Colors.Petrol,
    icon: 'folder',
  },
  refreshExceptions: {
    title: 'Refresh exceptions',
    content: 'Manage exceptions that will be applied to all refresh groups.',
    color: Colors.Forest,
    icon: 'settings',
  },
  abTest: {
    title: 'A/B tests',
    content: 'Monitor A/B tests running with Prebid and/or GAM',
    color: Colors.Pool,
    icon: 'population_split',
  },
  security: {
    title: 'Security',
    content: 'Manage authentification settings for the company',
    color: Colors.Lilac,
    icon: 'key',
  },
} as const
