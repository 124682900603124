import styled from '@emotion/styled'
import { FunctionComponent, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TabProp } from '~/components/Tabs'
import { WithClassName } from '~/types/utils'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackRefreshPage } from './PureAdStackRefreshPage'

type TabLabel = 'Rules' | 'Global settings'

const refreshTabs: TabProp[] = [{ label: 'Rules' }, { label: 'Global settings' }]

const tabLabelToPath: { [key in TabLabel]: string } = {
  Rules: 'rules',
  'Global settings': 'global-settings',
}

type AdStackRefreshPageProps = WithClassName & {
  //
}

const _AdStackRefreshPage: FunctionComponent<AdStackRefreshPageProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const tabLabelFromLocation = (Object.keys(tabLabelToPath) as Array<keyof typeof tabLabelToPath>).find(
    (key) => tabLabelToPath[key] === (location.pathname.split('/').find((dir) => Object.values(tabLabelToPath).includes(dir)) ?? 'rules')
  )

  const [activeTab, setActiveTab] = useState<TabLabel>(tabLabelFromLocation ?? 'Rules')

  useEffect(() => {
    setActiveTab(tabLabelFromLocation ?? 'Rules')
  }, [tabLabelFromLocation])

  const breadcrumbs = useBreadcrumbs()

  return (
    <PureAdStackRefreshPage
      breadcrumbs={breadcrumbs}
      onTabClick={(tab) => {
        setActiveTab(tab.label as TabLabel)
        navigate(tabLabelToPath[tab.label as TabLabel])
      }}
      tabs={refreshTabs.map((t) => ({ ...t, active: t.label === activeTab }))}
    >
      <Outlet />
    </PureAdStackRefreshPage>
  )
}

export const AdStackRefreshPage = styled(_AdStackRefreshPage)``
