import styled from '@emotion/styled'
import { AdxValidationType, GamIntegration, GamNetworkConfig } from '@pubstack/common/src/gam/adx'
import { ForwardRefRenderFunction, forwardRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Skeleton } from '~/components/Skeleton'
import { Spinner } from '~/components/Spinner'
import { Status, StatusState } from '~/components/Status'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { WidgetMessage } from '~/components/WidgetMessage'
import { WithClassName } from '~/types/utils'

const NetworkFormWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

const NetworkIntegrationButton = styled(Button)`
  width: 150px;
  display: flex;
  justify-content: center;
`

type GamNetwork = GamNetworkConfig & { networkCode: string }
type GamNetworkFormProps = { network: GamNetwork; isLoading: boolean; createGamNetwork: (network: GamNetworkConfig) => unknown }
const GamNetworkForm = ({ network, createGamNetwork, isLoading }: GamNetworkFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<GamNetworkConfig & { networkCode: string }>({
    defaultValues: {
      networkCode: network.networkCode,
    },
    resolver: async (values) => {
      if (/^(0|[1-9]\d*)$/.test(values.networkCode)) {
        return { values, errors: {} }
      }
      return { values: { ...values, networkCode: '' }, errors: { networkCode: { type: 'pattern', message: 'The Network code should be a number.' } } }
    },
  })

  const onSubmit = (newNetwork: GamNetworkConfig) => {
    createGamNetwork({ ...network, networkCode: newNetwork.networkCode })
  }

  useEffect(() => {
    reset({
      networkCode: network.networkCode,
    })
  }, [network])

  return (
    <NetworkFormWrapper>
      <Input label={'Network code'} control={control} name={'networkCode'} error={errors.networkCode?.message} disabled={!!network.networkCode} />

      {isLoading ? (
        <Spinner />
      ) : (
        <NetworkIntegrationButton disabled={(!isDirty && !!errors.networkCode?.message) || !!network.networkCode} onClick={handleSubmit(onSubmit)}>
          Save network code
        </NetworkIntegrationButton>
      )}
    </NetworkFormWrapper>
  )
}

const FeaturesGamIntegrationWrapper = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${Colors.Platinum};
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1300px;
`

const NetworkSection = styled.div`
  border-bottom: 1px solid ${Colors.Platinum};
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const StatusSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > ${WidgetMessage} {
    align-self: flex-start;
  }
`

const NetworkDataIngestionToggle = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const PrimaryGamIntegrationButton = styled(Button)`
  align-self: flex-start;
`

type PureAdminScopeFeaturesGamIntegrationProps = WithClassName & {
  loading: {
    isIntegrationLoading: boolean
    isNetworkLoading: boolean
    isCheckLoading: boolean
  }
  integration?: GamIntegration
  createServiceAccount: () => unknown
  createGamNetwork: (network: GamNetworkConfig) => unknown
  launchChecks: (networkCode: number) => unknown
  toggleAdxIngestion: (network: GamNetworkConfig) => unknown
  toggleOpenBiddingIngestion: (network: GamNetworkConfig) => unknown
  toggleGamIntegrationIngestion: (network: GamNetworkConfig) => unknown
  startAdxIngestionTesting: (network: GamNetworkConfig) => unknown
}

const _PureAdminScopeFeaturesGamIntegration: ForwardRefRenderFunction<HTMLDivElement, PureAdminScopeFeaturesGamIntegrationProps> = (
  { className, loading, integration, createServiceAccount, createGamNetwork, launchChecks, toggleAdxIngestion, toggleOpenBiddingIngestion, toggleGamIntegrationIngestion, startAdxIngestionTesting },
  ref
) => {
  const { control, reset } = useForm({
    defaultValues: {
      serviceAccount: integration?.serviceAccount ?? '',
    },
  })

  const [gamNetworks, setGamNetworks] = useState<GamNetwork[]>([])

  useEffect(() => {
    if (integration?.serviceAccount) {
      reset(integration)
    }
    if (integration?.gamNetworks) {
      setGamNetworks([...(integration?.gamNetworks ?? [])])
    }
  }, [integration])

  const addNetwork = () => {
    setGamNetworks([
      ...gamNetworks,
      {
        id: '',
        scopeId: '',
        dataIngestionStatus: 'STOPPED',
        networkCode: '',
        openBiddingEnabled: false,
        gamIntegrationEnabled: false,
        adxValidationState: {
          inventory: 'NA',
          report: 'NA',
          adUnit: 'NA',
          targeting: 'NA',
        },
      },
    ])
  }

  const VALIDATION_STATUS_CONFIG: { [key in AdxValidationType]: StatusState } = {
    NA: 'inactive',
    OK: 'active',
    KO: 'error',
    NO_SECRET: 'error',
    NO_SECRET_NAME: 'error',
    LESS_THAN_1000: 'error',
    NO_RESULTS: 'error',
    NETWORK_NOT_FOUND: 'error',
  }

  return (
    <FeaturesGamIntegrationWrapper className={className} ref={ref}>
      {loading.isIntegrationLoading ? (
        <>
          <Skeleton bigger width={'200px'} />
          <Skeleton bigger width={'200px'} />
          <Skeleton bigger width={'500px'} />
        </>
      ) : integration?.serviceAccount ? (
        <>
          <Input control={control} name={'serviceAccount'} label={'Service account'} disabled />
          {gamNetworks.map((network, index) => {
            const validations = network.adxValidationState
            const orderedValidations: { key: keyof typeof validations; label: string }[] = [
              { key: 'inventory', label: 'Inventory access' },
              { key: 'report', label: 'Report access' },
              { key: 'adUnit', label: 'Ad unit path status' },
              { key: 'targeting', label: 'Targeting status' },
            ]
            const validationsOk = orderedValidations.every((v) => validations[v.key] === 'OK')

            return (
              <NetworkSection key={index}>
                <GamNetworkForm isLoading={loading.isNetworkLoading} network={network} createGamNetwork={createGamNetwork} />
                {!!network.networkCode && (
                  <>
                    <StatusSection>
                      {orderedValidations.map((validation, index) => {
                        return (
                          <Tooltip key={index} title={validations[validation.key]} align={'center'}>
                            <Status state={VALIDATION_STATUS_CONFIG[validations[validation.key]]}>{validation.label}</Status>
                          </Tooltip>
                        )
                      })}
                      {loading.isCheckLoading ? (
                        <Spinner />
                      ) : (
                        <NetworkIntegrationButton disabled={validationsOk} onClick={() => launchChecks(parseInt(network.networkCode))}>
                          Start validation
                        </NetworkIntegrationButton>
                      )}
                    </StatusSection>

                    {validationsOk && (
                      <>
                        <StatusSection>
                          {(network.dataIngestionStatus === 'INITIALIZED' || network.dataIngestionStatus === 'TESTING') && (
                            <WidgetMessage
                              icon={'info'}
                              message={
                                <>
                                  Activating the reporting phase <em>will not</em> start the AdX data ingestion yet.
                                </>
                              }
                            />
                          )}

                          {network.dataIngestionStatus === 'ACTIVE' && (
                            <WidgetMessage
                              icon={'info'}
                              message={
                                <>
                                  Deactivating AdX data ingestion is not easily reversible. Consider turning <em>user access</em> off instead.
                                </>
                              }
                            />
                          )}

                          <NetworkIntegrationButton disabled={!validationsOk || network.dataIngestionStatus !== 'INITIALIZED'} onClick={() => startAdxIngestionTesting(network)}>
                            Activate reporting
                          </NetworkIntegrationButton>
                        </StatusSection>

                        {network.dataIngestionStatus !== 'INITIALIZED' && (
                          <>
                            {network.dataIngestionStatus === 'TESTING' && (
                              <WidgetMessage icon={'warning'} message={'Make sure that testing data are correctly ingested before toggling AdX data ingestion for good.'} />
                            )}
                            {network.dataIngestionStatus === 'ACTIVE' && !network.openBiddingEnabled && (
                              <WidgetMessage icon={'warning'} message={'Make sure that enough time has passed (7 days) before enabling Open Bidding data ingestion.'} />
                            )}

                            <StatusSection>
                              <Status state={network.dataIngestionStatus === 'ACTIVE' ? 'active' : 'inactive'}>Ingestion status: {network.dataIngestionStatus}</Status>
                              <NetworkDataIngestionToggle>
                                <span>AdX data ingestion</span>
                                <Toggle disabled={network.dataIngestionStatus === 'STOPPED'} value={network.dataIngestionStatus === 'ACTIVE'} onClick={() => toggleAdxIngestion(network)} />
                              </NetworkDataIngestionToggle>
                            </StatusSection>

                            <StatusSection>
                              <Status state={network.openBiddingEnabled ? 'active' : 'inactive'}>Open Bidding status: {network.openBiddingEnabled ? 'ACTIVE' : 'DISABLED'}</Status>
                              <NetworkDataIngestionToggle>
                                <span>Open Bidding data ingestion</span>
                                <Toggle disabled={network.dataIngestionStatus !== 'ACTIVE'} value={network.openBiddingEnabled} onClick={() => toggleOpenBiddingIngestion(network)} />
                              </NetworkDataIngestionToggle>
                            </StatusSection>

                            <StatusSection>
                              <Status state={network.gamIntegrationEnabled ? 'active' : 'inactive'}>GAM Dashboards ingestion: {network.gamIntegrationEnabled ? 'ACTIVE' : 'DISABLED'}</Status>
                              <NetworkDataIngestionToggle>
                                <span>GAM Dashboards ingestion</span>
                                <Toggle disabled={network.dataIngestionStatus !== 'ACTIVE'} value={network.gamIntegrationEnabled} onClick={() => toggleGamIntegrationIngestion(network)} />
                              </NetworkDataIngestionToggle>
                            </StatusSection>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </NetworkSection>
            )
          })}

          <PrimaryGamIntegrationButton variant={'secondary'} onClick={addNetwork}>
            Add network
          </PrimaryGamIntegrationButton>
        </>
      ) : (
        <PrimaryGamIntegrationButton variant={'secondary'} onClick={createServiceAccount}>
          Create service account
        </PrimaryGamIntegrationButton>
      )}
    </FeaturesGamIntegrationWrapper>
  )
}

export const PureAdminScopeFeaturesGamIntegration = styled(forwardRef<HTMLDivElement, PureAdminScopeFeaturesGamIntegrationProps>(_PureAdminScopeFeaturesGamIntegration))``
