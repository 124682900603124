import styled from '@emotion/styled'
import { GamNetworkConfig } from '@pubstack/common/src/gam/adx'
import { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Modal } from '~/components/Modal'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { useGlobalModal } from '~/components/layout/GlobalModal'

const GamManagerActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ModalMessage = styled(Message)`
  margin-bottom: 20px;
`

const InputContainer = styled.div`
  height: 70px;
`

const ValidateActionModal: FunctionComponent<{
  runCreationProcess: (network: GamNetworkConfig) => void
  gamNetwork: GamNetworkConfig
}> = ({ runCreationProcess, gamNetwork }) => {
  const { close } = useGlobalModal()

  const { control, watch } = useForm<{
    network: string
  }>()

  return (
    <Modal.Content>
      <Modal.Title>GAM object creation</Modal.Title>

      <Modal.Body>
        <ModalMessage
          status={'warning'}
          title={'Warning'}
          description={
            <Wrapper>
              <span>
                You are about to configure the GAM account of {gamNetwork.id} ({gamNetwork.networkCode}) .
              </span>
              <span>Please enter the network Id {gamNetwork.id} to validate the operation</span>
            </Wrapper>
          }
        />
        <InputContainer>
          <Input type={'text'} control={control} name={'network'} label={'networkId'} required={true} />
        </InputContainer>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'negative'} disabled={watch('network') !== gamNetwork.id} onClick={() => runCreationProcess(gamNetwork)}>
          Validate operation
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

type PureGamManagerProps = {
  networks: GamNetworkConfig[]
  runCreationProcess: (network: GamNetworkConfig) => void
  onNetworkChange: (network: GamNetworkConfig) => unknown
  isRunning: boolean
  isLoading: boolean
}

const _PureGamManager: FunctionComponent<PureGamManagerProps> = ({ networks, runCreationProcess, onNetworkChange, isRunning, isLoading }) => {
  const modal = useGlobalModal()

  const { control, handleSubmit } = useForm<{
    network: string
  }>()

  const siteOptions: SelectOptionProp<string>[] = networks.map((s) => ({
    label: `${s.id} (${s.networkCode})`,
    value: s.id,
  }))

  const [hasSelect, setHasSelect] = useState(false)

  return (
    <Wrapper>
      <Message
        status={'warning'}
        title={'Warning'}
        description={
          <Wrapper>
            <span>You are about to configure the GAM account of a customer.</span>
            <span>Be wary that all operations cannot be undone.</span>
            <span>Double check if you&#39;re configuring the right network.</span>
            <span>Read the documentation before performing the operation.</span>
            <span>
              <Link label={'GAM ADM Setup'} href={'https://www.notion.so/GAM-Adm-Setup-via-Autobox-9de3eed1fa0a458198a81e7629c287cf'} />
            </span>
          </Wrapper>
        }
      />

      <GamManagerActions>
        <Select
          searchable
          control={control}
          name={'network'}
          label={'Network'}
          options={siteOptions}
          onChange={(option: SelectOptionProp) => {
            const networkId = option.value
            const gamNetwork = networks.find((n) => n.id === networkId)
            if (gamNetwork) {
              setHasSelect(true)
              onNetworkChange(gamNetwork)
            }
          }}
        />
        <Button
          disabled={isLoading || isRunning || !hasSelect}
          iconName={isLoading ? 'loading' : undefined}
          iconSpin={isLoading}
          onClick={handleSubmit((data) => {
            const { network } = data
            const gamNetwork = networks.find((n) => n.id === network)
            if (gamNetwork) {
              modal.open(ValidateActionModal, { runCreationProcess, gamNetwork })
            }
          })}
        >
          Configure GAM account
        </Button>
        <span>{isRunning ? `A job is already running for the selected network` : ''}</span>
      </GamManagerActions>
    </Wrapper>
  )
}

export const PureGamManager = styled(_PureGamManager)``
