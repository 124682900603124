import styled from '@emotion/styled'
import { Color, ColorUtil, Colors } from '~/assets/style/colors'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'
import { Tooltip } from './Tooltip'

const IconBackground = styled.div<{ color: Color }>`
  background-color: ${({ color }) => color};
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

type QualifierProps = WithClassName & {
  iconName: IconName
  tooltipText: string
  enabled: boolean
  color?: Color
  active?: boolean
}

const Qualifier = ({ className, iconName, tooltipText, color = Colors.Success, active, enabled = true }: QualifierProps) => {
  const active_opacity = 1
  const archived_opacity = 0.3
  return (
    <Tooltip className={className} title={tooltipText}>
      <IconBackground color={ColorUtil.hexOpacity(active ? color : Colors.Platinum, enabled ? active_opacity : archived_opacity)}>
        <Icon name={iconName} fill={Colors.White} width={'20px'} />
      </IconBackground>
    </Tooltip>
  )
}

export { Qualifier }
