import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { Icon } from '~/components/Icon'
import { WithClassName } from '~/types/utils'
import { StackUsage } from '../../StackUsage'
import { HeaderBiddingRuleWithBidders } from './AdStackHeaderBiddingPage'

type PureAdStackHeaderBiddingDetailsFlyoutProps = WithClassName & {
  isOpen: boolean
  onClose: () => void
  headerBiddingRule: Omit<HeaderBiddingRuleWithBidders, 'numberOfBidderAdapters'>
  onEdit: (id: string) => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Title = styled.div`
  ${Fonts.H2};
  font-weight: 500;
`

const Subtitle = styled.div`
    ${Fonts.P2};
    color: ${Colors.SlateGrey};
`

const TextWithIcon = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    margin-bottom: 4px;
`

const pipeSeparator = `\xa0 | \xa0`

const _PureAdStackHeaderBiddingDetailsFlyout: FunctionComponent<PureAdStackHeaderBiddingDetailsFlyoutProps> = ({ isOpen, onClose, headerBiddingRule, onEdit }) => {
  const selectedBidders = headerBiddingRule.isBlockList
    ? headerBiddingRule.bidderAdapters.filter((b) => !headerBiddingRule.bidderNames?.includes(b.code))
    : headerBiddingRule.bidderAdapters.filter((b) => headerBiddingRule.bidderNames?.includes(b.code))
  const prebidBidders = selectedBidders.map((b) => b.displayName)
  return (
    <Flyout
      color={Colors.Forest}
      width={`816px`}
      title={`${headerBiddingRule.name} - details`}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Button
          variant={'secondary'}
          onClick={() => {
            onEdit(headerBiddingRule.id)
          }}
          iconName={'edit'}
        >
          Edit
        </Button>
      }
    >
      <Wrapper>
        <Block>
          <Title>Infos</Title>
          <div>
            <Subtitle>Rule name</Subtitle>
            <p>{headerBiddingRule.name}</p>
          </div>
        </Block>
        <Block>
          <Title>Bidders</Title>
          <TextWithIcon>
            {headerBiddingRule.isBlockList ? <Icon name="check" fill={Colors.Success} /> : <Icon name="close" fill={Colors.Ash} />}
            Add new bidders automatically
          </TextWithIcon>
          <div>
            <Subtitle>Prebid bidders</Subtitle>
            <p>{prebidBidders.join(pipeSeparator)}</p>
          </div>
        </Block>
        <Block>
          <StackUsage usedInElement={'Rule'} sitesStacks={headerBiddingRule.usedInStacks} />
        </Block>
      </Wrapper>
    </Flyout>
  )
}

export const PureAdStackHeaderBiddingDetailsFlyout = styled(_PureAdStackHeaderBiddingDetailsFlyout)``
