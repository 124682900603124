import styled from '@emotion/styled'
import { useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon } from '~/components/Icon'
import { Link, LinkIcon } from '~/components/Link'
import { SelectOptionProp, SelectableOptionsPopover } from '~/components/SelectableOptionsPopover'
import { Tooltip } from '~/components/Tooltip'
import { DataTableColumnData, DataTableSorting } from './DataTableTypes'

const HeaderTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
`

const HeaderTooltipIcon = styled(Icon)`
  margin-left: 3px;
`
const Header = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
`
const HeaderRow = styled.tr``
const HeaderCell = styled.th`
  min-width: min-content;
  padding: 0 10px 6px;

  box-shadow: inset 0 -1px 0 ${Colors.Platinum};

  &:first-of-type {
    padding-left: 0;
  }
`
const HeaderCellContent = styled.div`
  ${Fonts.P2};
  ${Fonts.colors.SlateGrey}
  font-style: normal;
  font-weight: 400;
  max-width: 200px;

  display: grid;
  justify-content: start;
  grid-template-columns: minmax(40px, auto) auto;
`

const HeaderColumnNameTooltip = styled(Tooltip)`
  word-wrap: normal;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

const SortSelect = styled(SelectableOptionsPopover)`
  margin-left: 4px;
  ${Fonts.P2}
`
const SortLink = styled(Link)<{ open: boolean }>`
  ${LinkIcon} {
    transition: 0.25s;
    transform: rotate(${(props) => (props.open ? '-180deg' : '0')});
  }
`

type DataTableHeaderProps<T> = {
  columns: DataTableColumnData<T>[]
  sorting?: DataTableSorting
  onSortChange?: (option: SelectOptionProp) => void
}
export const DataTableHeader = <T,>({ columns, sorting, onSortChange }: DataTableHeaderProps<T>) => {
  const [sortOpen, setSortOpen] = useState<boolean>(false)
  const onChange = (option: SelectOptionProp) => {
    onSortChange && onSortChange(option)
    sorting && sorting.setSortValue(option)
  }
  return (
    <Header>
      <HeaderRow>
        {columns.map((column, index) => (
          <HeaderCell key={index}>
            <HeaderCellContent>
              {column.displaySort ? (
                sorting ? (
                  <>
                    <span>Sort by: </span>
                    <SortSelect
                      options={sorting.sortOptions}
                      onChange={onChange}
                      trigger={<SortLink open={sortOpen} label={sorting.sortValue?.label ?? ''} icon={'chevron_down'} />}
                      open={sortOpen}
                      setOpen={setSortOpen}
                    />
                  </>
                ) : undefined
              ) : (
                <>
                  <HeaderColumnNameTooltip title={column.name} align={'center'} reposition={true}>
                    {column.name}
                  </HeaderColumnNameTooltip>
                  {!!column.tooltip && (
                    <HeaderTooltip title={column.tooltip} align={'center'} reposition={true}>
                      <HeaderTooltipIcon name={'info'} width={'16px'} />
                    </HeaderTooltip>
                  )}
                </>
              )}
            </HeaderCellContent>
          </HeaderCell>
        ))}
      </HeaderRow>
    </Header>
  )
}
