import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevel } from '~/assets/style/tokens'
import { Icon } from '~/components/Icon'
import { Skeleton } from '~/components/Skeleton'
import { WithClassName } from '~/types/utils'
import TableCell from './TableCell'
import TableRow from './TableRow'

type SortOrder = 'ascending' | 'descending' | 'none'

export interface Column {
  name: ReactNode
  isSortable: false
}

/**
 * Here you can define your own sort algorithm on the column which will be handled by handleTableSort() function
 */
export interface SortableColumn<T = any> {
  name: ReactNode
  isSortable: true
  order: SortOrder
  attributeSort: keyof T
  sort?: (a: T, b: T) => number
}

export type TableColumns<T = any> = (Column | SortableColumn<T>)[]

export const onColumnSort = <T,>(columns: TableColumns<T>, column: SortableColumn<T>, setColumns: (columns: TableColumns<T>) => void) => {
  setColumns(
    columns.map((c) => {
      if (c === column) {
        return { ...c, order: c.order === 'ascending' ? 'descending' : 'ascending' }
      } else {
        return c.isSortable ? { ...c, order: 'none' } : c
      }
    })
  )
}

const sortByType = (a: any, b: any): number => {
  switch (typeof a) {
    case 'number':
    case 'boolean':
      return Number(b) - Number(a)
    case 'string':
      return a.localeCompare(b)
  }
  return 0
}

/**
 * Return a sorted array of elements based on columns configuration
 * @param columns
 * @param elements
 */
export const handleTableSort = <T,>(columns: TableColumns<T>, elements: T[] | undefined): T[] => {
  const sortedColumn = (columns.find((c) => c.isSortable && c.order !== 'none') || columns[0]) as SortableColumn<T>
  return [...(elements || [])].sort(
    (a, b) => sortedColumn.sort?.(a, b) ?? (sortedColumn.order === 'ascending' ? 1 : -1) * sortByType(a[sortedColumn.attributeSort] ?? false, b[sortedColumn.attributeSort] ?? false)
  )
}

// TODO tmu 2022-11-15 type correctly to disallow passing searchAttributes pointing to objects
/**
 * Return a filtered array containing values matching a search over specific stringable attributes
 * @param data array of objects to filter
 * @param search string value currently searched
 * @param searchAttributes object's properties on which we want to search
 */
export const handleTableSearch = <T extends { [key in keyof T]: object | string | number | boolean | null | undefined }>(data: T[], search: string, searchAttributes: (keyof T)[]): T[] => {
  return data.filter((d) => {
    return searchAttributes.some((attr) => {
      const value = d[attr as keyof T]
      if (value) {
        return value.toString().toLowerCase().includes(search.toLowerCase())
      }
    })
  })
}

export const handleTableSearchAndSort = <T extends { [key in keyof T]: object | string | number | boolean | null | undefined }>(
  columns: TableColumns<T>,
  data: T[],
  search: string,
  searchAttributes: (keyof T)[]
): T[] => {
  return handleTableSearch(handleTableSort(columns, data), search, searchAttributes)
}

export const EmptyTable = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 40px;
`

const Th = styled.th<{ isSortable?: boolean }>`
  background: ${Colors.White};
  position: sticky;
  top: 0;
  ${ElevationLevel.low}
  cursor: ${(props) => (props.isSortable ? 'pointer' : 'auto')};
  padding: 0;
  margin: 0;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border-top: 1px solid ${Colors.Platinum};
    border-bottom: 1px solid ${Colors.Platinum};
  }
  &:last-child {
    border-radius: 0 4px 0 0;
    border-top: none;
    border-bottom: none;
  }
  &:last-child:before {
    border-right: 1px solid ${Colors.Platinum};
    border-radius: 0 4px 0 0;
  }
  &:first-of-type {
    border-radius: 4px 0 0 0;
    border-top: none;
  }
  &:first-of-type:before {
    border-left: 1px solid ${Colors.Platinum};
    border-radius: 4px 0 0 0;
  }
`

const Tr = styled.tr<{ noWrapHeader?: boolean }>`
  padding: 0;
  margin: 0;
  ${({ noWrapHeader }) =>
    !!noWrapHeader &&
    css`
      white-space: nowrap;
    `}
`

const TableStyled = styled.table`
  border-collapse: collapse;
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  background: ${Colors.White};

  & ${TableRow} {
    & ${TableCell}:first-of-type:not(:only-of-type) {
      font-weight: bold;
    }
  }
`

const Thead = styled.thead`
  background: ${Colors.White};
`
const HeaderItem = styled.div<{ isSortable: boolean }>`
  display: flex;
  align-items: center;
  padding: 16px 12px;
  width: 100%;
  font-weight: 400;
  ${Fonts.colors.SlateGrey};
  ${Fonts.P1};

  ${({ isSortable }) =>
    isSortable &&
    css`
      cursor: pointer;
    `}
`

const HeaderItemName = styled.span`
  margin: 0;
  display: block;
`

const HeaderSortIcon = styled(Icon)`
  margin-left: 2px;
`

const TableContainer = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
`

const fakeColumns = (columns: TableColumns, rowIndex: number, random: () => number) =>
  columns.map((_, i) => (
    <TableCell key={`cell-${i}-${rowIndex}`}>
      <Skeleton width={Math.floor(random() * 31 + 40) + '%'} />
    </TableCell>
  ))

type TableProps<T = any> = WithClassName & {
  isLoading: boolean
  columns: TableColumns<T>
  onClickHeading?: (column: SortableColumn) => void
  noWrapHeader?: boolean
}
const _Table: FC<PropsWithChildren<TableProps>> = ({ children, className, noWrapHeader, ...props }) => {
  const seed = (s: number) => {
    return () => {
      s = Math.sin(s) * 10000
      return s - Math.floor(s)
    }
  }

  const random = seed(seed(seed(42)())())

  const headingClick = (column: Column | SortableColumn): void => {
    if (column.isSortable) {
      props.onClickHeading?.(column)
    }
  }

  const renderTh = (props.columns || []).map((column, i) => (
    <Th key={`row-${i}`} onClick={() => headingClick(column)} isSortable={column.isSortable}>
      <HeaderItem isSortable={column.isSortable}>
        <HeaderItemName>{column.name}</HeaderItemName>
        {column.isSortable && <HeaderSortIcon name={column.order === 'ascending' ? 'organize_up' : column.order === 'descending' ? 'organize_down' : 'organize'} width={'18px'} />}
      </HeaderItem>
    </Th>
  ))
  const bodyLoading = new Array(5).fill(0).map((_, i) => <TableRow key={`row-${i}`}>{fakeColumns(props.columns, i, random)}</TableRow>)
  return (
    <TableContainer className={className}>
      <TableStyled>
        <Thead>
          <Tr noWrapHeader={noWrapHeader}>{renderTh}</Tr>
        </Thead>
        <tbody>{props.isLoading ? bodyLoading : children}</tbody>
      </TableStyled>
    </TableContainer>
  )
}
const Table = styled(_Table)``
export default Table
