import styled from '@emotion/styled'
import { AdmQAReportCategory, AdmQaReport, AdmQaReportsTimeRange } from '@pubstack/common/src/adm-qa-reports'
import { AdminTag } from '@pubstack/common/src/tag'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import Table, { EmptyTable, TableColumns, handleTableSort, onColumnSort } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { AdmQaReportsLoading } from './AdmQaReportsPage'
import { ADM_QA_REPORTS_TIME_RANGE_LABELS } from './admQaReports'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const ActionsCell = styled(TableCell)`
  & > div {
    display: flex;
    align-items: flex-end;
    gap: 16px;
  }
  width: 0;
`

const QAReportForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > ${Button} {
    margin-top: 16px;
    align-self: flex-start;
  }
`

const ReportSiteSelection = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  & input {
    width: 250px;
  }
`

const ProductionDate = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  & input {
    width: 250px;
  }
`

const getHTMLInputDateFormat = (date: DateTime): string => {
  return date.toFormat("yyyy-MM-dd'T'HH:mm")
}

const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

const getLocaleOffsetString = (): string => {
  const localeOffset = -new Date().getTimezoneOffset() / 60
  const localeOffsetString = 'UTC' + (localeOffset >= 0 ? `+${localeOffset}` : `${localeOffset}`)
  return localeOffsetString
}

const localeOffsetString = getLocaleOffsetString()

const reportColumns: TableColumns<AdmQaReport> = [
  {
    name: `Created on (${localeOffsetString})`,
    isSortable: true,
    order: 'descending',
    attributeSort: 'createdAt',
  },
  {
    name: `Report`,
    isSortable: false,
  },
  {
    name: `Live date (${localeOffsetString})`,
    isSortable: false,
  },
  {
    name: 'Range',
    isSortable: false,
  },
  {
    name: 'Actions',
    isSortable: false,
  },
]

type PureAdmQaReportsPageProps = {
  loading: AdmQaReportsLoading
  reports: AdmQaReport[]
  sites: AdminTag[]
  timeRanges: SelectOptionProp[]
  createReport: (siteA: string, siteB: string, deployDate: string, range: AdmQaReportsTimeRange) => void
  deleteReport: (report: AdmQaReport) => void
  downloadReport: (report: AdmQaReport, category: AdmQAReportCategory) => void
  onReportClick: (report: AdmQaReport) => void
}
const _PureAdmQaReportsPage = ({ loading, reports, sites, timeRanges, createReport, deleteReport, downloadReport, onReportClick }: PureAdmQaReportsPageProps) => {
  const [columns, setColumns] = useState<TableColumns<AdmQaReport>>(reportColumns)
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<{
    siteA?: string
    siteB?: string
    deployDate: string
    range: AdmQaReportsTimeRange
  }>({
    defaultValues: {
      deployDate: getHTMLInputDateFormat(DateTime.now().minus({ minutes: 30 })),
    },
  })

  const siteOptions = sites.map((s) => ({
    label: s.name,
    value: s.id,
  }))

  const sortedReports = handleTableSort(reportColumns, reports)

  return (
    <Container>
      <h2>Ad management QA reports</h2>
      <QAReportForm>
        <ReportSiteSelection>
          <Select
            rules={{
              required: true,
            }}
            searchable
            control={control}
            name={'siteA'}
            label={'Site A'}
            options={siteOptions}
          />
          <Select
            rules={{
              required: true,
            }}
            searchable
            control={control}
            name={'siteB'}
            label={'Site B'}
            options={siteOptions}
          />
        </ReportSiteSelection>
        <ProductionDate>
          <Input
            rules={{
              required: true,
            }}
            label={`AdM Live date`}
            control={control}
            type={'datetime-local'}
            name={'deployDate'}
            min={getHTMLInputDateFormat(
              DateTime.now().minus({
                month: 1,
              })
            )}
            helper={`Timezone: ${timezoneName} (${localeOffsetString})`}
          />
          <Select
            rules={{
              required: true,
            }}
            control={control}
            name={'range'}
            label={'Analysis range'}
            options={timeRanges}
          />
        </ProductionDate>
        <Button
          disabled={loading.reportLoading || !isValid}
          iconName={loading.reportLoading ? 'loading' : undefined}
          iconSpin={loading.reportLoading}
          onClick={handleSubmit((data) => {
            const { siteA, siteB, deployDate, range } = data
            if (siteA && siteB && deployDate && range) {
              createReport(siteA, siteB, deployDate, range)
            }
          })}
        >
          Generate report
        </Button>
      </QAReportForm>
      <Table columns={columns} isLoading={loading.reportsLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {sortedReports.length ? (
          sortedReports.map((report, index) => {
            const siteA = sites.find((site) => site.id === report.siteIdA)?.name ?? report.siteIdA
            const siteB = sites.find((site) => site.id === report.siteIdB)?.name ?? report.siteIdB
            return (
              <TableRow key={index} onClick={() => onReportClick(report)}>
                <TableCell>{DateTime.fromISO(report.createdAt).setLocale('en-GB').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}</TableCell>
                <TableCell>
                  {siteA} vs {siteB}
                </TableCell>
                <TableCell>{DateTime.fromISO(report.admLiveDate).setLocale('en-GB').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}</TableCell>
                <TableCell>{ADM_QA_REPORTS_TIME_RANGE_LABELS[report.range]}</TableCell>
                <ActionsCell>
                  <div>
                    <Button variant={'tertiary'} iconName={'chart_bar'}>
                      Analysis
                    </Button>
                    <Button
                      variant={'tertiary'}
                      disabled={loading.reportDataLoading}
                      iconName={loading.reportDataLoading ? 'loading' : 'download'}
                      iconSpin={loading.reportDataLoading}
                      onClick={(e) => {
                        e.stopPropagation()
                        downloadReport(report, AdmQAReportCategory.AUCTIONS_IMPRESSIONS)
                      }}
                    >
                      Auctions
                    </Button>
                    <Button
                      variant={'tertiary'}
                      disabled={loading.reportDataLoading}
                      iconName={loading.reportDataLoading ? 'loading' : 'download'}
                      iconSpin={loading.reportDataLoading}
                      onClick={(e) => {
                        e.stopPropagation()
                        downloadReport(report, AdmQAReportCategory.BIDS)
                      }}
                    >
                      Bids
                    </Button>
                    <Button
                      variant={'tertiary'}
                      disabled={loading.reportDataLoading}
                      iconName={loading.reportDataLoading ? 'loading' : 'download'}
                      iconSpin={loading.reportDataLoading}
                      onClick={(e) => {
                        e.stopPropagation()
                        downloadReport(report, AdmQAReportCategory.VIEWABLITY)
                      }}
                    >
                      Viewability
                    </Button>
                    <Button
                      variant={'tertiary'}
                      disabled={loading.reportDataLoading}
                      iconName={loading.reportDataLoading ? 'loading' : 'download'}
                      iconSpin={loading.reportDataLoading}
                      onClick={(e) => {
                        e.stopPropagation()
                        downloadReport(report, AdmQAReportCategory.USER_SESSION)
                      }}
                    >
                      Sessions
                    </Button>
                    <Button
                      variant={'tertiary'}
                      iconName={'delete'}
                      onClick={(e) => {
                        e.stopPropagation()
                        deleteReport(report)
                      }}
                    />
                  </div>
                </ActionsCell>
              </TableRow>
            )
          })
        ) : (
          <TableRow>
            <TableCell colspan={columns.length}>
              <EmptyTable>
                <div>
                  <h2>No QA reports</h2>
                  <p>Create a report by selecting two sites, a live date and an analysis range. </p>
                </div>
              </EmptyTable>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </Container>
  )
}

export const PureAdmQaReportsPage = styled(_PureAdmQaReportsPage)``
