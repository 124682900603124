import styled from '@emotion/styled'
import { MappedName, SiteMetricsByTagId } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent } from 'react'
import { MultipleDetailedDataTable } from '~/components/DataTable/DataTable'
import { DataTableDetailedPropertyProvider } from '~/components/DataTable/DataTableDetailedProperties'
import { Widget, WidgetProps } from '~/components/Widget'
import { useAnalyticsDataTable } from '~/modules/analytics/analyticsDataTable.hooks'
import { allAuctionFillRate, allECPM, allImpressionRevenue, auctionCount, bidAdUnitCount, bidBidderCount, hbBidDensity } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

type PureSitesOverviewTableProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    rawData?: SiteMetricsByTagId
    onRowClick: (name: MappedName) => void
    currencySymbol: CurrencySymbol
    onSortChange: (metric: string) => void
  }

const _PureSitesOverviewTable: FunctionComponent<PureSitesOverviewTableProps> = ({ rawData, onRowClick, currencySymbol, onSortChange, ...props }) => {
  const { data, columns, sorting } = useAnalyticsDataTable({
    rawData,
    currencySymbol,
    dataConfigs: [
      [
        {
          displayedAs: 'detail',
          getDetails: ({ data }) => [DataTableDetailedPropertyProvider.bidBidderCount(data.bidBidderCount.value), DataTableDetailedPropertyProvider.bidAdUnitCount(data.bidAdUnitCount.value)],
          status: {
            alert: 30,
            warning: 60,
            defaultValue: 'success',
          },
          sortLabel: 'Name',
        },
        {
          formula: allImpressionRevenue,
          propertyName: 'impressionRevenue',
          tooltip: ANALYTICS_TOOLTIPS.REVENUE,
        },
        {
          formula: auctionCount,
          propertyName: 'auctionCount',
          tooltip: ANALYTICS_TOOLTIPS.AUCTION_COUNT,
        },
        {
          formula: allAuctionFillRate,
          propertyName: 'auctionFillRate',
          status: {
            warning: 15,
            alert: 5,
          },
          tooltip: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
        },
        {
          formula: allECPM,
          propertyName: 'eCPM',
          tooltip: ANALYTICS_TOOLTIPS.ECPM,
        },
        {
          formula: hbBidDensity,
          propertyName: 'hbBidDensity',
          tooltip: ANALYTICS_TOOLTIPS.PREBID_DENSITY,
        },
        {
          formula: bidBidderCount,
          propertyName: 'bidBidderCount',
        },
        {
          formula: bidAdUnitCount,
          propertyName: 'bidAdUnitCount',
        },
      ],
    ],
    columnsConfigs: ['impressionRevenue', 'auctionCount', 'auctionFillRate', 'eCPM', 'hbBidDensity'],
  })

  return (
    <Widget {...props} icon={'site'} title={'Overview per sites'} subTitle={'Click on a site to see details'}>
      <MultipleDetailedDataTable
        sorting={sorting}
        rows={data}
        onRowClick={({ index }) => onRowClick(data[index][0].name)}
        columns={columns}
        onSortChange={(option) => onSortChange(option.value as string)}
      />
    </Widget>
  )
}

export const PureSitesOverviewTable = styled(_PureSitesOverviewTable)``
