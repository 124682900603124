import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { HeaderBiddingRule } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useBidderAdapters, useHeaderBiddingRules } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackHeaderBiddingEditPage } from './PureAdStackHeaderBiddingEditPage'

export const AdStackHeaderBiddingEditPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const breadcrumbs = useBreadcrumbs()
  const [headerBiddingRules, setHeaderBiddingRules] = useState<HeaderBiddingRule[]>([])

  const [headerBiddingRule, setHeaderBiddingRule] = useState<HeaderBiddingRule>()
  const [selectableBidders, setSelectableBidders] = useState<BidderAdapter[]>([])
  const { headerBiddingRuleId } = useParams()
  const { all: allBidderAdapters } = useBidderAdapters(null)

  const { all, byId } = useHeaderBiddingRules(null)

  async function loadHeaderBiddingRule(headerBiddingRuleId?: string) {
    if (headerBiddingRuleId) {
      try {
        const headerBiddingRuleToUpdate = await byId.get(headerBiddingRuleId)
        setHeaderBiddingRule(headerBiddingRuleToUpdate)
      } catch (exception) {
        toast.alert('An error occurred while fetching this bidder rule.')
        throw exception
      }
    }
  }

  const loadHeaderBiddingRules = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setHeaderBiddingRules(result)
  }

  const createHeaderBiddingRule = async (headerBiddingRule: HeaderBiddingRule) => {
    try {
      const newRule = await byId.post(headerBiddingRule)
      toast.success(`${newRule.name} successfully created.`)
      navigate('/adstack/header-bidding')
    } catch (exception) {
      toast.alert('An error occurred while saving this rule.')
      throw exception
    }
  }

  const updateHeaderBiddingRule = async (headerBiddingRule: HeaderBiddingRule) => {
    try {
      const updatedHeaderBiddingRule = await byId.put(headerBiddingRule)
      toast.admSuccess(headerBiddingRule.name, headerBiddingRule.usedInStacks && headerBiddingRule.id !== updatedHeaderBiddingRule.id, navigate)
      navigate('/adstack/header-bidding')
    } catch (exception) {
      toast.alert('An error occurred while saving this rule.')
      throw exception
    }
  }

  const loadBidderAdapters = async () => {
    if (allBidderAdapters.loading) {
      allBidderAdapters.abort()
    }
    const usedBidders = await allBidderAdapters.get()
    setSelectableBidders(usedBidders)
  }

  useEffect(() => {
    loadHeaderBiddingRules()
    loadHeaderBiddingRule(headerBiddingRuleId)
  }, [headerBiddingRuleId])

  useEffect(() => {
    loadBidderAdapters()
  }, [])

  return (
    <PureAdStackHeaderBiddingEditPage
      isEditing={!!headerBiddingRuleId}
      isLoading={byId.loading || all.loading || allBidderAdapters.loading}
      headerBiddingRule={headerBiddingRule}
      selectableBidders={selectableBidders}
      existingHeaderBiddingRules={headerBiddingRules}
      breadcrumbs={breadcrumbs}
      onCreateHeaderBiddingRule={createHeaderBiddingRule}
      onUpdateHeaderBiddingRule={updateHeaderBiddingRule}
      onBack={() => navigate(-1)}
    />
  )
}
