import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, ReactElement, ReactNode, cloneElement, useEffect, useRef, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ScrollbarStyle, ScrollbarWidth } from '~/assets/style/utils'
import { WithClassName } from '~/types/utils'
import { Icon } from './Icon'
import { Popover } from './Popover'
import { Status, StatusState } from './Status'

export type SelectOptionProp<T = unknown> = {
  value: T
  label: string
  status?: StatusState
  sublabel?: string
  disabled?: boolean
}

type SelectPopoverProps = WithClassName & {
  onChange?: (option: SelectOptionProp) => unknown
  options: SelectOptionProp[]
  trigger: ReactElement
  open: boolean
  setOpen: (open: boolean) => void
  allowMultipleSelection?: boolean
}

const Option = styled.div<{ disabled?: boolean }>`
  --item__bg--hover: ${Colors.Topaze};
  --item__bg--active: ${Colors.King};
  --item__bg: ${Colors.White};
  ${Fonts.P1};
  ${Fonts.colors.Hurricane}
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;

  background: var(--item__bg);

  &:last-child {
    margin-bottom: 0;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          font-style: italic;
          color: ${Colors.Ash};
        `
      : css`
          cursor: pointer;

          :hover {
            background: var(--item__bg--hover);
          }

          :active {
            color: ${Colors.White};
            background: var(--item__bg--active);
          }
        `}
`

const NoResult = styled.div`
  ${Fonts.P1};
  ${Fonts.colors.Hurricane}
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: italic;
  background: ${Colors.White};
  margin-bottom: 0;
`

const OptionPopover = styled(Popover)`
  margin-left: calc(${ScrollbarWidth} + 4px);
`

const OptionWithSublabel = styled.div`
  display: flex;
  flex-direction: column;
`

const OptionWithStatus = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
`

const Sublabel = styled.span`
  ${Fonts.P3};
  ${Fonts.colors.SlateGrey};
`

const SelectOptions = styled.div`
  border-radius: 4px;
  padding: 0;
  max-height: 272px;
  overflow-y: auto;
  box-sizing: border-box;
  ${ScrollbarStyle}
`

const _SelectableOptionsPopover: FunctionComponent<SelectPopoverProps> = ({ className, options, onChange, trigger, open, setOpen, allowMultipleSelection }) => {
  const triggerRef = useRef<HTMLElement>(null)
  const [width, setWidth] = useState(0)
  const setter = () => setWidth(triggerRef.current?.clientWidth || 0)
  const [currentHover, setCurrentHover] = useState<number | undefined>(undefined)
  const [canHover, setCanHover] = useState(false)

  useEffect(() => {
    setter()
  })

  useEffect(() => {
    const listener = () => setTimeout(setter, 50)
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  const onOptionClick = (option: SelectOptionProp) => {
    if (option.disabled) {
      return
    }

    onChange && onChange(option)
    setCurrentHover(undefined)
    if (!allowMultipleSelection) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (open) {
      // ReactTinyPopover renders dropdown in front of input before translate it so cursor hover it few seconds that could open a sub-list
      setTimeout(() => setCanHover(true), 100)
    }
    setCurrentHover(undefined)
  }, [open])

  const generateOptionContent = (option: SelectOptionProp<unknown>): ReactNode => (
    <OptionWithStatus>
      {option.status && <Status state={option.status} />}
      <OptionWithSublabel>
        {option.label}
        <Sublabel>{option.sublabel}</Sublabel>
      </OptionWithSublabel>
    </OptionWithStatus>
  )

  return (
    <Popover trigger={cloneElement(trigger, { className, ref: triggerRef })} open={open} setOpen={setOpen} isToggleable={allowMultipleSelection}>
      <SelectOptions style={{ minWidth: width + 20, width: '100%' }}>
        {options.length ? (
          (options || []).map((option, optionIndex) =>
            option.value instanceof Array ? (
              <OptionPopover
                key={`popover.${optionIndex}`}
                open={optionIndex === currentHover}
                trigger={
                  <Option
                    key={optionIndex}
                    onMouseEnter={() => {
                      if (canHover) {
                        setCurrentHover(optionIndex)
                      }
                    }}
                    disabled={option.disabled}
                  >
                    {generateOptionContent(option)}
                    <Icon name={'chevron_right'} />
                  </Option>
                }
                positions={['right', 'left']}
              >
                <SelectOptions style={{ minWidth: width + 20, width: '100%' }}>
                  {option.value.map((subOption, subOptionIndex) => (
                    <Option key={`${optionIndex}subOption${subOptionIndex}`} onClick={() => onOptionClick(subOption)} disabled={subOption.disabled}>
                      {generateOptionContent(subOption)}
                    </Option>
                  ))}
                </SelectOptions>
              </OptionPopover>
            ) : (
              <Option
                key={optionIndex}
                onClick={() => onOptionClick(option)}
                onMouseEnter={() => {
                  if (canHover) {
                    setCurrentHover(optionIndex)
                  }
                }}
                disabled={option.disabled}
              >
                {generateOptionContent(option)}
              </Option>
            )
          )
        ) : (
          <NoResult>No result matches your research.</NoResult>
        )}
      </SelectOptions>
    </Popover>
  )
}
export const SelectableOptionsPopover = styled(_SelectableOptionsPopover)``
