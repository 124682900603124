import { AllMetricsObj, MappedName } from '@pubstack/common/src/analytics/query'
import { NonNullable } from '@pubstack/common/src/assertion'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { CellContentWithSublabel, DataTableCell, DetailedDataTableCell, GaugeDataTableCell } from '~/components/DataTable/DataTableCell'
import { DataTableColumnData, DataTableSorting, DetailInfo } from '~/components/DataTable/DataTableTypes'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Tooltip } from '~/components/Tooltip'
import { Status } from '~/types/utils'
import { GEO_COUNTRIES, GeoCountryCode } from '~/utils/GeoCountries'
import { isNotApplicable } from '~/utils/analytics'
import { FormulaObj } from './formulas/operation-obj'

type ColumnStatusConfig = {
  value: number | undefined
  warning: number
  alert: number
  defaultValue?: 'success' | undefined
}
type BaseConfig = {
  status?: Omit<ColumnStatusConfig, 'value'>
  tooltip?: string | ReactElement
}
type NameConfig<P extends string> = Pick<BaseConfig, 'status'> & {
  sortLabel: string
  mappedName?: MappedName
  showLabelAsTooltip?: boolean
} & (
    | {
        displayedAs: 'gauge'
        getRawValue: (_: { data: Data<P>; index: number }) => number
        max?: number
      }
    | {
        displayedAs: 'detail'
        getDetails: (_: { data: Data<P>; index: number }) => DetailInfo[]
      }
    | {
        displayedAs?: 'text'
      }
  )
export type AnalyticsDataTableOtherConfig<P extends string, T extends Partial<AllMetricsObj> = any> = BaseConfig & {
  propertyName: P
  formula: FormulaObj<T>
  isComputable?: (_: { rawData: RawData; index: number; mappedName: MappedName }) => boolean
}

export type AnalyticsDataTableConfigs<P extends string> = [NameConfig<P>, ...AnalyticsDataTableOtherConfig<P>[]][]

type RawData = Partial<AllMetricsObj> & {
  mappings: Record<string, MappedName>
}

type Data<K extends string> = {
  name: MappedName
} & {
  [key in K]: {
    rawValue: number | undefined
    percentage: number | undefined
    value: string
  }
}
export type AnalyticsDataTableFilterData<K extends string> = Parameters<Data<K>[][]['filter']>[0]

export const getColumnStatus = ({ value, warning, alert, defaultValue }: ColumnStatusConfig): Status | undefined => {
  let mode
  if (warning > alert) {
    mode = 'below'
  } else {
    mode = 'above'
  }

  if (value === undefined) {
    return undefined
  }

  if (mode === 'above') {
    if (value >= alert) {
      return 'alert'
    }
    if (value >= warning) {
      return 'warning'
    }
  } else {
    if (value <= alert) {
      return 'alert'
    }
    if (value <= warning) {
      return 'warning'
    }
  }
  return defaultValue
}

const isMetrics = <T,>(value: T | T[] | undefined): value is T => value !== undefined && !Array.isArray(value)
const asCountryName = (name: GeoCountryCode | string): string => GEO_COUNTRIES[name as GeoCountryCode] || name

const getData = <P extends string>({
  rawData,
  sortValue,
  currencySymbol,
  dataConfigs,
  filterData,
  maxResults,
}: {
  rawData: RawData | undefined
  sortValue: P
  currencySymbol: CurrencySymbol
  dataConfigs: AnalyticsDataTableConfigs<P>
  filterData?: AnalyticsDataTableFilterData<P>
  maxResults?: number
}): Data<P>[][] => {
  if (!isMetrics(rawData)) {
    return []
  }

  return Object.values(rawData.mappings)
    .map((mappedName) =>
      dataConfigs.map((configs) => {
        const [nameConfig, ...otherConfigs] = configs
        return otherConfigs.reduce(
          (acc, config) => {
            const computable =
              config.formula.isComputable(rawData) &&
              (config.isComputable?.({
                rawData,
                index: 0,
                mappedName,
              }) ??
                true)

            const d: Data<P> = {
              ...acc,
              [config.propertyName]: {
                rawValue: computable ? config.formula.compute(rawData, 0)[mappedName.value] : undefined,
                percentage: computable ? config.formula.percentage(rawData, 0)[mappedName.value] : undefined,
                value: computable ? config.formula.displayable(config.formula.compute(rawData, 0)[mappedName.value], currencySymbol) : 'NA',
              },
            }

            return d
          },
          {
            name: nameConfig.mappedName ?? {
              value: rawData.mappings[mappedName.value].value,
              label: asCountryName(rawData.mappings[mappedName.value].label),
              sublabel: rawData.mappings[mappedName.value]?.sublabel,
            },
          } as Data<P>
        )
      })
    )
    .filter(filterData ?? (() => true))
    .sort(([a], [b]) => {
      if (sortValue === 'name') {
        return b['name']['label'].localeCompare(a['name']['label']) * -1
      } else {
        return (b[sortValue]?.rawValue ?? 0) - (a[sortValue]?.rawValue ?? 0)
      }
    })
    .slice(0, maxResults ?? Number.MAX_SAFE_INTEGER)
}

const getColumns = <P extends string>(configs: AnalyticsDataTableConfigs<P>, columnsConfigs: P[]): DataTableColumnData<Data<P>>[] => {
  const [, ...otherConfigs] = configs[0]
  return [
    {
      name: 'name',
      displaySort: true,
      render: ({ index, secondary, key, rows }) => {
        const { label, sublabel } = rows[index].name
        const nameConfig = configs[index][0]
        const Label = () => (nameConfig.showLabelAsTooltip ? <Tooltip title={label}>{label}</Tooltip> : label)
        const CellContent = () => (sublabel ? <CellContentWithSublabel label={<Label />} sublabel={sublabel || ''} /> : <Label />)

        if (nameConfig.displayedAs === 'text') {
          return (
            <DataTableCell key={key} secondary={secondary}>
              <CellContent />
            </DataTableCell>
          )
        } else if (nameConfig.displayedAs === 'gauge') {
          const value = nameConfig.getRawValue?.({ data: rows[index], index }) ?? 0
          const status = nameConfig.status
            ? getColumnStatus({
                ...nameConfig.status,
                value,
              })
            : undefined
          return (
            <GaugeDataTableCell key={key} secondary={secondary} status={status} gaugeValues={{ value, max: nameConfig.max }}>
              <CellContent />
            </GaugeDataTableCell>
          )
        } else if (nameConfig.displayedAs === 'detail') {
          return (
            <DetailedDataTableCell key={key} secondary={secondary} details={nameConfig.getDetails({ data: rows[index], index })}>
              <CellContent />
            </DetailedDataTableCell>
          )
        }
        return null as never
      },
    },
    ...columnsConfigs
      .map((cc) => otherConfigs.find((c) => c.propertyName === cc))
      .filter(NonNullable)
      .map(
        (config): DataTableColumnData<Data<P>> => ({
          name: config.formula.name,
          render: ({ index, key, secondary, rows }) => {
            const { rawValue, value } = rows[index][config.propertyName]
            const status = config.status
              ? getColumnStatus({
                  ...config.status,
                  value: rawValue,
                })
              : undefined
            return (
              <DataTableCell key={key} secondary={secondary} status={status} isNotApplicable={isNotApplicable(value)}>
                {value}
              </DataTableCell>
            )
          },
          tooltip: config.tooltip,
        })
      ),
  ]
}

const useSorting = <P extends string>(configs: AnalyticsDataTableConfigs<P>, columnsConfigs: P[], defaultSort?: string): DataTableSorting<P | 'name'> | undefined => {
  const [, ...otherConfigs] = configs[0]
  const sortOptions = useMemo(
    () => [
      {
        label: 'Name',
        value: 'name' as const,
      },
      ...columnsConfigs
        .map((cc) => otherConfigs.find((c) => c.propertyName === cc))
        .filter(NonNullable)
        .map((config) => ({ label: config.formula.name, value: config.propertyName })),
    ],
    [configs]
  )

  const getDefaultOption = () => sortOptions.find(({ value }) => value === defaultSort) ?? sortOptions[1]

  const [sortValue, setSortValue] = useState<SelectOptionProp<P | 'name'>>(getDefaultOption())

  useEffect(() => {
    if (!sortOptions.find((o) => o.value === sortValue?.value)) {
      setSortValue(getDefaultOption())
    }
  }, [sortOptions])

  return {
    sortValue,
    sortOptions,
    setSortValue,
  }
}

type AnalyticsDataTableProps<P extends string> = {
  rawData?: RawData
  currencySymbol: CurrencySymbol
  dataConfigs: AnalyticsDataTableConfigs<P>
  columnsConfigs: P[]
  filterData?: AnalyticsDataTableFilterData<P>
  maxResults?: number
  defaultSort?: P
}
export const useAnalyticsDataTableObj = <P extends string>({ dataConfigs, columnsConfigs, maxResults, filterData, rawData, currencySymbol, defaultSort }: AnalyticsDataTableProps<P>) => {
  const sorting = useSorting(dataConfigs, columnsConfigs, defaultSort)

  const data = useMemo(
    () => getData({ rawData, currencySymbol, sortValue: sorting?.sortValue?.value ?? 'name', dataConfigs, filterData, maxResults }),
    [rawData, currencySymbol, sorting?.sortValue?.value, dataConfigs, filterData, maxResults]
  )
  const columns = useMemo(() => getColumns(dataConfigs, columnsConfigs), [dataConfigs, columnsConfigs])

  return {
    data,
    columns,
    sorting,
  }
}
