import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { TamConfig } from '@pubstack/common/src/tam/tamConfig'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useTamConfig, useTamSiteConfig } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { configurationState } from '~/state'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackTamIntegrationPage } from './PureAdStackTamIntegrationPage'

export const AdStackTamIntegrationPage: FunctionComponent = () => {
  const breadcrumbs = useBreadcrumbs('TAM')
  const [tamConfig, setTamConfig] = useState<TamConfig>({ scopeId: '', apsPubId: '', siteConfigs: [], sites: [] })
  const toast = useToast()
  const navigate = useNavigate()
  const { byId: tamConfigById } = useTamConfig(null)
  const { byId: tamSiteConfigById } = useTamSiteConfig(null)
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)

  const getCurrentTamConfig = async () => {
    const res = await tamConfigById.get()
    setTamConfig(res)
  }

  const onCreatePublisherId = async (publisherId: string) => {
    try {
      const res = await tamConfigById.put(publisherId)
      setTamConfig(res)
      toast.success(`The publisher id has been saved.`)
    } catch (err) {
      toast.alert('An error occurred when trying to save the publisher id.')
    }
  }

  const onCreateSiteConfig = async (siteConfig: SiteConfig) => {
    try {
      await tamSiteConfigById.put(siteConfig)
      await getCurrentTamConfig()
      toast.admSuccess(tamConfig.sites.find((site) => site.id === siteConfig.siteId)?.name ?? '', true, navigate)
    } catch (err) {
      toast.alert(`An error occurred when trying to save the site configuration.`)
    }
  }

  useEffect(() => {
    getCurrentTamConfig()
  }, [])

  return (
    <PureAdStackTamIntegrationPage
      isLoading={tamConfigById.loading}
      breadcrumbs={breadcrumbs}
      tamConfig={tamConfig}
      onCreatePublisherId={onCreatePublisherId}
      onCreateSiteConfig={onCreateSiteConfig}
      baseCDNUrl={STATIC_ASSETS_CDN}
      onBackClick={() => navigate(-1)}
    />
  )
}
