import styled from '@emotion/styled'
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { PopoverAlign, PopoverPosition, Popover as ReactTinyPopover } from 'react-tiny-popover'
import { useEventListener } from 'usehooks-ts'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevelValues } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'

type TooltipProps = WithClassName & {
  title: ReactNode
  align?: PopoverAlign
  positions?: PopoverPosition[]
  reposition?: boolean
  offset?: number
  open?: boolean
  disabled?: boolean
}

const TooltipContent = styled.div`
  background: #4c5774;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  ${Fonts.P2}
  max-width: 500px;
`

const TooltipTrigger = styled.div`
  display: inline;
`

const _Tooltip: FC<PropsWithChildren<TooltipProps>> = ({ className, children, title, align, positions, reposition, offset = 0, open, disabled }) => {
  const [key, setKey] = useState('')
  const [internalOpen, setInternalOpen] = useState(open || false)
  useEffect(() => setInternalOpen(open || false), [open])
  const manual = open !== undefined

  const randomKey = () => {
    setKey(`${Math.random()}`)
  }
  useEventListener('resize', randomKey)

  return (
    <ReactTinyPopover
      containerStyle={{ zIndex: `${ElevationLevelValues.high}` }}
      isOpen={internalOpen}
      content={<TooltipContent>{title}</TooltipContent>}
      reposition={reposition ?? false}
      positions={positions || ['top', 'bottom', 'left', 'right']}
      onClickOutside={() => setInternalOpen(false)}
      align={align ?? 'center'}
      padding={offset}
      key={key}
    >
      <TooltipTrigger className={className} onMouseLeave={() => !manual && setInternalOpen(false)} onMouseEnter={() => !disabled && setInternalOpen(true)}>
        {children}
      </TooltipTrigger>
    </ReactTinyPopover>
  )
}

export const Tooltip = styled(_Tooltip)``
