import { DEFAULT_KLEANADS_SCOPE_CONFIG, KleanadsScopeConfig } from '@pubstack/common/src/kleanadsScopeConfig'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAdminKleanadsScopeConfig } from '~/api/admin-api.hook'
import { FetchError } from '~/api/api-access'
import { useToast } from '~/components/Toast/useToasts'
import { PurePocOverridesConfig } from './PurePocOverridesConfig'

export const AdminScopePocOverridesConfig: FunctionComponent = () => {
  const { scopeId } = useParams()
  const { get, loading, abort, putPocOverrides } = useAdminKleanadsScopeConfig(null, scopeId ?? '')
  const [scopeConfig, setScopeConfig] = useState<KleanadsScopeConfig>()
  const toast = useToast()

  const loadScopeConfig = async () => {
    if (loading) {
      abort()
    }
    try {
      const scopeConfig = await get()
      setScopeConfig(scopeConfig)
    } catch (e) {
      if (e instanceof FetchError) {
        toast.alert(`An error has occurred, : ${e.response.status} - ${e.response.statusText}`)
      } else if (e instanceof Error) {
        toast.alert(`An error has occurred, : ${e.message}`)
      }
    }
  }

  const onPocOverridesUpdate = async (pocOverrides: KleanadsScopeConfig['pocOverrides']) => {
    try {
      const updatedPocOverrides = await putPocOverrides(pocOverrides)
      toast.success(`The POC overrides have been updated. Check #kleanads-ops for the changes.`)
      if (scopeConfig) {
        setScopeConfig({ ...scopeConfig, pocOverrides: updatedPocOverrides })
      } else {
        loadScopeConfig()
      }
    } catch (e) {
      toast.alert('An error has occurred, please retry later.')
    }
  }

  useEffect(() => {
    setScopeConfig(DEFAULT_KLEANADS_SCOPE_CONFIG)
    loadScopeConfig()
  }, [scopeId])

  return <PurePocOverridesConfig isLoading={loading} isScopeConfigMissing={!scopeConfig} pocOverridesData={scopeConfig?.pocOverrides ?? {}} onUpdate={onPocOverridesUpdate} />
}
