import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { MappedName, UserSessionMetricsByDim } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useCallback } from 'react'
import { DataTableSorting } from '~/components/DataTable/DataTableTypes'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsBreakdown, useAnalyticsBreakdown } from '~/modules/analytics/AnalyticsBreakdown'
import { allImpressionRevenue, impressionPerPage, impressionPerSession, pageView, sessionCount, sessionRpm } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { TabConfig } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

type PureUserSessionBreakdownProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    rawData?: UserSessionMetricsByDim
    onTabChange: (dimension: Dimension) => void
    currencySymbol: CurrencySymbol
    onRowClick: (name: MappedName) => void
    sorting?: DataTableSorting
    defaultTabIndex?: number
    onSortChange: (metric: string) => void
    tabsConfig: TabConfig[]
  }
const _PureUserSessionBreakdown: FunctionComponent<PureUserSessionBreakdownProps> = ({ currencySymbol, rawData, onTabChange, tabsConfig, defaultTabIndex, ...props }) => {
  const breakdownProps = useAnalyticsBreakdown({
    currencySymbol,
    rawData,
    onTabChange,
    tabsConfig,
    defaultTabIndex,
    getConfigs: useCallback(
      () => [
        [
          {
            displayedAs: 'text',
            propertyName: 'name',
            sortLabel: 'Site name',
          },
          {
            formula: allImpressionRevenue,
            propertyName: 'impressionRevenue',
            tooltip: ANALYTICS_TOOLTIPS.REVENUE,
          },
          {
            formula: sessionCount,
            propertyName: 'sessionCount',
            tooltip: (
              <>
                Total number of distinct session. <br />
                Pubstack records a session every single time someone loads a page on your website. <br />A session ends at midnight or after 30 minutes of inactivity. <br />
                If a same visitor comes back one or several hour later, a new session is counted.
              </>
            ),
          },
          {
            formula: sessionRpm,
            propertyName: 'sessionRpm',
            tooltip: ANALYTICS_TOOLTIPS.SESSIONS_RPM,
          },
          {
            formula: impressionPerSession,
            propertyName: 'impressionPerSession',
            tooltip: ANALYTICS_TOOLTIPS.SESSIONS_IMPRESSIONS,
          },
          {
            formula: pageView,
            propertyName: 'pageView',
            tooltip: ANALYTICS_TOOLTIPS.SESSIONS_PAGEVIEW,
          },
          {
            formula: impressionPerPage,
            propertyName: 'impressionPerPage',
            tooltip: ANALYTICS_TOOLTIPS.PAGE_IMPRESSIONS,
          },
        ],
      ],
      []
    ),
    getColumnsConfigs: useCallback(() => ['impressionRevenue', 'sessionCount', 'sessionRpm', 'impressionPerSession', 'pageView', 'impressionPerPage'], []),
  })
  return <AnalyticsBreakdown {...props} {...breakdownProps} />
}
export const PureUserSessionBreakdown = styled(_PureUserSessionBreakdown)``
