import styled from '@emotion/styled'
import { StacksBySite } from '@pubstack/common/src/stack'
import { lowerCaseFirstLetter } from '@pubstack/common/src/stringUtils'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { FoldingContent } from '~/components/FoldingContent'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

type StackUsageProps = WithClassName & {
  sitesStacks?: StacksBySite
  usedInElement: string
}

const Title = styled.h2`
  ${Fonts.H2};
  font-weight: 500;
  padding: 10px 0 8px 0;
`

const SiteStacksWrapper = styled(FoldingContent)`
  padding: 8px 0 0 0;
  strong {
    font-weight: 500;
  }
  div {
    padding-bottom: 8px;
  }
`

const ArchivedStack = styled.span`
  color: ${Colors.Ash};
  font-style: italic;
`

const NoValueText = styled.div`
  color: ${Colors.Ash};
  font-style: italic;
  padding: 0;
`

const generateStackName = (stack: { stackName: string; version: string; enabled?: boolean }): ReactNode => {
  const stackDisplayed: string = stack.stackName + '-' + stack.version
  return !stack.enabled ? (
    <Tooltip title={'Archived'}>
      <ArchivedStack>{stackDisplayed}</ArchivedStack>
    </Tooltip>
  ) : (
    stackDisplayed
  )
}

const pipeSeparator = `\xa0 | \xa0`

const _StackUsage: FunctionComponent<StackUsageProps> = ({ className, sitesStacks, usedInElement }) => {
  return (
    <div className={className}>
      <Title>{usedInElement} used in</Title>
      {sitesStacks ? (
        Object.entries(sitesStacks).map(([siteName, stackVersions]) => {
          return (
            <SiteStacksWrapper
              key={siteName}
              title={
                <span>
                  <strong>{siteName}</strong> ({stackVersions.length} stack{stackVersions.length > 1 && 's'} and versions)
                </span>
              }
              isFolding
            >
              <span>{stackVersions.map((stack) => generateStackName(stack)).reduce((stack, acc) => [acc, pipeSeparator, stack])}</span>
            </SiteStacksWrapper>
          )
        })
      ) : (
        <NoValueText>0 stack uses this {lowerCaseFirstLetter(usedInElement)}.</NoValueText>
      )}
    </div>
  )
}

export const StackUsage = styled(_StackUsage)``
