import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { FunctionComponent } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { Input } from '~/components/Input'
import { handleTableSearch } from '~/components/table/Table'
import { WithClassName } from '~/types/utils'

type BidderSelectorProps = WithClassName & {
  selectableBidders: BidderAdapter[]
  selectedBidders: BidderAdapter[]
  onBiddersSelection: (bidders: BidderAdapter[]) => unknown
}

const CheckboxWrapper = styled.div`
  flex: 0;
  padding: 4px 0;
  border-bottom: 1px solid #e5e5e5;
  & ${Checkbox} {
    height: 30px;
  }
`

const SearchBar = styled.form`
  margin-bottom: 12px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  ${Button} {
    margin-left: 16px;
    margin-top: 4px;
  }
`

const SelectAll = styled.div`
  & ${CheckboxWrapper} {
    ${Fonts.colors.Ash};
  }
`

const useBidderSelection = (selectableBidders: BidderAdapter[], selectedBidders: BidderAdapter[], onBiddersSelection: (bidders: BidderAdapter[]) => unknown) => {
  const isSelected = (bidder: BidderAdapter) => selectedBidders.includes(bidder)
  function getUniqueListBy(arr: BidderAdapter[]) {
    return [...new Set(arr).values()]
  }

  const onSelectAll = () => {
    if (selectableBidders.every(isSelected)) {
      onBiddersSelection(selectedBidders.filter((s) => !selectableBidders.includes(s)))
    } else {
      onBiddersSelection(getUniqueListBy([...selectedBidders, ...selectableBidders]))
    }
  }

  const onSelection = (bidder: BidderAdapter) => {
    if (isSelected(bidder)) {
      onBiddersSelection(selectedBidders.filter((s) => s !== bidder))
    } else {
      onBiddersSelection([...selectedBidders, bidder])
    }
  }

  const isAllIndeterminate = selectableBidders.some(isSelected) && selectableBidders.some((b) => !isSelected(b))
  const isAllSelected = selectableBidders.every(isSelected)
  return { isAllSelected, isAllIndeterminate, onSelectAll, onSelection, isSelected }
}

const _BidderSelector: FunctionComponent<BidderSelectorProps> = ({ selectableBidders, selectedBidders, onBiddersSelection }) => {
  const {
    resetField,
    control,
    formState: { isDirty },
  } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })

  const search = useWatch({ control, name: 'search' })
  const displayedBidders = handleTableSearch(selectableBidders, search, ['code'])
  const { isAllSelected, isAllIndeterminate, onSelectAll, onSelection, isSelected } = useBidderSelection(displayedBidders, selectedBidders, onBiddersSelection)

  return (
    <>
      <SearchBar>
        <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
        {isDirty && <Button type={'button'} iconName={'close'} variant={'tertiary'} onClick={() => resetField('search')} />}
      </SearchBar>
      <SelectAll>
        <CheckboxWrapper>
          <Checkbox checked={isAllSelected} indeterminate={isAllIndeterminate} onClick={onSelectAll} /> Select {selectedBidders.length === selectableBidders.length ? 'all' : selectedBidders.length}{' '}
          {selectedBidders.length > 1 ? 'bidders' : 'bidder'}
        </CheckboxWrapper>
      </SelectAll>
      <div>
        {displayedBidders.map((bidder) => {
          return (
            <CheckboxWrapper key={bidder.code}>
              <Checkbox
                key={bidder.code}
                checked={isSelected(bidder)}
                onClick={(e) => {
                  onSelection(bidder)
                }}
              />{' '}
              {bidder.displayName}
            </CheckboxWrapper>
          )
        })}
      </div>
    </>
  )
}

export const BidderSelector = styled(_BidderSelector)``
