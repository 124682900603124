import { AdStackRefreshGlobalSettingsData, AdmRefreshGlobalSettings } from '@pubstack/common/src/adstack/refresh-global-settings'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRefreshRules } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { PureAdStackRefreshGlobalSettingsPage } from './PureAdStackRefreshGlobalSettingsPage'

export const AdStackRefreshGlobalSettingsPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const { loadExcludedCampaigns, fetchGlobalSettings, saveGlobalSettings: apiSave } = useRefreshRules(null)
  const [globalSettings, setGlobalSettings] = useState<AdStackRefreshGlobalSettingsData>({
    excludedCampaignsData: null,
    refreshGlobalSettings: null,
    bidders: [],
  })
  const toast = useToast()

  async function onLoadExcludedCampaigns() {
    try {
      await loadExcludedCampaigns()
      toast.success('The fetch of the excluded campaign list has started.')
    } catch (e) {
      toast.alert('An error occurred while trying to load the excluded campaign.')
    }
  }

  const setGlobalSettingsData = (data: AdStackRefreshGlobalSettingsData) => {
    setGlobalSettings({
      ...data,
      refreshGlobalSettings:
        data.refreshGlobalSettings !== null
          ? {
              ...data.refreshGlobalSettings,
              settings: data.refreshGlobalSettings.settings.map((setting) => ({
                ...setting,
                bidders: setting.bidders.map((bidder) => data.bidders.find((b) => b.code === bidder)?.displayName ?? ''),
              })),
            }
          : null,
    })
  }

  async function getGlobalSettings() {
    if (fetchGlobalSettings.loading) {
      fetchGlobalSettings.abort()
    }
    try {
      const res = await fetchGlobalSettings.get()
      setGlobalSettingsData(res)
    } catch (e) {
      toast.alert('An error occurred while trying to retrieve the global settings data.')
    }
  }

  async function saveGlobalSettings(newGlobalSettings: AdmRefreshGlobalSettings, isFormDirty: boolean) {
    try {
      if (newGlobalSettings.id) {
        await apiSave.put(newGlobalSettings)
      } else {
        await apiSave.post(newGlobalSettings)
      }
      toast.admSuccess('Settings', isFormDirty, navigate)
      if (newGlobalSettings.id) {
        setGlobalSettingsData({ ...globalSettings, refreshGlobalSettings: newGlobalSettings })
      } else {
        await getGlobalSettings()
      }
    } catch (exception) {
      toast.alert('An error occurred while saving the global settings.')
      throw exception
    }
  }

  useEffect(() => {
    getGlobalSettings()
  }, [])

  return (
    <PureAdStackRefreshGlobalSettingsPage
      globalSettings={globalSettings}
      onLoadExcludedCampaigns={onLoadExcludedCampaigns}
      onSaveGlobalSettings={saveGlobalSettings}
      isLoading={fetchGlobalSettings.loading}
    />
  )
}
