import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, ReactElement, cloneElement } from 'react'
import { ColorUtil, Colors } from '~/assets/style/colors'
import { BorderRadius } from '~/assets/style/tokens'
import { Icon, IconName } from '~/components/Icon'
import { WithClassName } from '~/types/utils'
import { TOAST_TYPE_COLOR, ToastType } from './ToastConfig'
import { ToastMessageProps } from './ToastMessage'

const Wrapper = styled.div<{ type: ToastType }>`
  ${BorderRadius.style}
  background-color: ${Colors.White};
  box-shadow: 0px 3px 10px 0px ${ColorUtil.hexOpacity(Colors.Jet, 0.3)};
  cursor: default;

  max-width: 625px;

  display: inline-flex;
`

const LoadingAnimation = keyframes(`
  from {
    width: 0;
  }
  to {
    width: calc( 100% - 2px );
  }
`)

const Loading = styled.div<{ type: ToastType; max: number }>`
  position: absolute;
  border-radius: 0 ${BorderRadius.value} ${BorderRadius.value};
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: ${({ type }) => TOAST_TYPE_COLOR[type].color};
  animation: ${LoadingAnimation} ${({ max }) => max / 1000}s linear;
`

const Close = styled.div`
  display: inline-flex;
  padding: 16px;
  border-left: 1px solid ${Colors.Platinum};
  align-items: center;
  cursor: pointer;

  :hover ${Icon} {
    transform: scale(1.15);
  }
`

type ToastProps = WithClassName & {
  type: ToastType
  icon?: IconName
  message: string | ReactElement<ToastMessageProps> | null
  duration?: number
  closeHandler?: () => unknown
}
const _Toast: FunctionComponent<ToastProps> = ({ className, type, icon, message, duration, closeHandler }) => {
  return (
    <Wrapper className={className} type={type}>
      {typeof message === 'string' || !message ? message : cloneElement(message, { icon, type })}
      <Close onClick={closeHandler}>
        <Icon width={'18px'} name={'close'} />
      </Close>
      {duration && <Loading type={type} max={duration} />}
    </Wrapper>
  )
}

export const Toast = styled(_Toast)``
