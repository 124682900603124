import styled from '@emotion/styled'
import { ViewabilityMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { MetricConfiguration, TimelineConfiguration, useMetrics, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import {
  adxAuctionFillRate,
  adxAuctionRpm,
  adxAverageViewedTime,
  adxECPM,
  adxViewabilityMRC,
  adxViewedMRCImpressionCount,
  allAuctionFillRate,
  allAuctionRpm,
  allAverageViewedTime,
  allECPM,
  allViewabilityMRC,
  allViewedMRCImpressionCount,
  auctionCount,
  hbAuctionFillRate,
  hbAuctionRpm,
  hbAverageViewedTime,
  hbECPM,
  hbViewabilityMRC,
  hbViewedMRCImpressionCount,
} from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { displayWithCurrency } from '~/utils/string'

const viewabilityMetricsConfig: (MetricConfiguration<ViewabilityMetricsByTime> & TimelineConfiguration<ViewabilityMetricsByTime>)[] = [
  {
    getChartOptions: () => ({
      seriesType: 'bars',
      isStacked: false,
      vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: "#'%'", minValue: 0 },
    }),
    metric: {
      name: 'viewabilityMRC',
      tooltipText: ANALYTICS_TOOLTIPS.VIEWABILITY,
    },
    legendConfig: ['hb', 'adx'],
    tooltipConfig: [['hb', 'adx'], ['viewabilityMRC']],
    dataConfig: [
      {
        name: 'hb',
        isComputable: ({ data }) => hbViewabilityMRC.isComputable(data) && hbViewedMRCImpressionCount.isComputable(data) && hbAverageViewedTime.isComputable(data as ViewabilityMetricsByTime),
        getFormula: () => hbViewabilityMRC,
      },
      {
        name: 'adx',
        isComputable: ({ data }) => adxViewabilityMRC.isComputable(data) && adxViewedMRCImpressionCount.isComputable(data),
        getFormula: () => adxViewabilityMRC,
      },
      {
        name: 'viewabilityMRC',
        label: allViewabilityMRC.name,
        getFormula: () => allViewabilityMRC,
        iconName: 'empty',
      },
    ],
  },
  {
    getChartOptions: () => ({
      seriesType: 'bars',
      isStacked: true,
    }),
    metric: {
      name: 'viewedMRCImpressionCount',
      tooltipText: ANALYTICS_TOOLTIPS.VIEWABLE_IMPRESSIONS,
    },
    legendConfig: ['hb', 'adx'],
    tooltipConfig: [['hb', 'adx'], ['viewedMRCImpressionCount']],
    dataConfig: [
      {
        name: 'hb',
        getFormula: () => hbViewedMRCImpressionCount,
        isComputable: ({ data }) => hbViewabilityMRC.isComputable(data) && hbViewedMRCImpressionCount.isComputable(data) && hbAverageViewedTime.isComputable(data as ViewabilityMetricsByTime),
      },
      {
        name: 'adx',
        getFormula: () => adxViewedMRCImpressionCount,
        isComputable: ({ data }) => adxViewabilityMRC.isComputable(data) && adxViewedMRCImpressionCount.isComputable(data),
      },
      {
        name: 'viewedMRCImpressionCount',
        label: allViewedMRCImpressionCount.name,
        getFormula: () => allViewedMRCImpressionCount,
        iconName: 'sigma',
      },
    ],
  },
  {
    getChartOptions: () => ({
      seriesType: 'bars',
      isStacked: false,
      vAxis: {
        ...AnalyticsDefaultChartOptions.vAxis,
        format: '#.##s',
      },
    }),
    metric: {
      name: 'averageViewedTime',
      tooltipText: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
    },
    legendConfig: ['hb', 'adx'],
    tooltipConfig: [['hb', 'adx'], ['averageViewedTime']],
    dataConfig: [
      {
        name: 'hb',
        getFormula: () => hbAverageViewedTime,
        isComputable: ({ data }) => hbViewabilityMRC.isComputable(data) && hbViewedMRCImpressionCount.isComputable(data) && hbAverageViewedTime.isComputable(data as ViewabilityMetricsByTime),
      },
      {
        name: 'adx',
        getFormula: () => adxAverageViewedTime,
        isComputable: ({ data }) => adxAverageViewedTime.isComputable(data),
      },
      {
        name: 'averageViewedTime',
        label: allAverageViewedTime.name,
        getFormula: ({ data }) => {
          if (hbAverageViewedTime.isComputable(data) && adxAverageViewedTime.isComputable(data as any)) {
            return allAverageViewedTime
          }
          if (hbAverageViewedTime.isComputable(data)) {
            return hbAverageViewedTime
          }
          if (adxAverageViewedTime.isComputable(data)) {
            return adxAverageViewedTime
          }
          return undefined
        },
        iconName: 'empty',
      },
    ],
  },
  {
    getChartOptions: ({ currencySymbol }) => ({
      seriesType: 'bars',
      isStacked: true,
      vAxis: {
        ...AnalyticsDefaultChartOptions.vAxis,
        format: displayWithCurrency('#.##', currencySymbol),
      },
    }),
    metric: {
      name: 'auctionRpm',
      tooltipText: ANALYTICS_TOOLTIPS.AUCTION_RPM,
    },
    legendConfig: ['hb', 'adx'],
    tooltipConfig: [['hb', 'adx'], ['auctionRpm']],
    dataConfig: [
      {
        name: 'hb',
        getFormula: () => hbAuctionRpm,
        isComputable: ({ data }) => auctionCount.isComputable(data) && hbAuctionRpm.isComputable(data) && hbAuctionFillRate.isComputable(data),
      },
      {
        name: 'adx',
        getFormula: () => adxAuctionRpm,
        isComputable: ({ data }) => auctionCount.isComputable(data) && adxAuctionRpm.isComputable(data) && adxAuctionFillRate.isComputable(data),
      },
      {
        name: 'auctionRpm',
        label: allAuctionRpm.name,
        getFormula: ({ data }) => {
          return auctionCount.isComputable(data) ? allAuctionRpm : undefined
        },
        iconName: 'sigma',
      },
    ],
  },
  {
    getChartOptions: () => ({
      seriesType: 'bars',
      isStacked: true,
      vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: "#'%'", minValue: 0 },
    }),
    metric: {
      name: 'auctionFillRate',
      tooltipText: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
    },
    legendConfig: ['hb', 'adx'],
    tooltipConfig: [['hb', 'adx'], ['auctionFillRate']],
    dataConfig: [
      {
        name: 'hb',
        getFormula: () => hbAuctionFillRate,
        isComputable: ({ data }) => auctionCount.isComputable(data) && hbAuctionRpm.isComputable(data) && hbAuctionFillRate.isComputable(data),
      },
      {
        name: 'adx',
        getFormula: () => adxAuctionFillRate,
        isComputable: ({ data }) => auctionCount.isComputable(data) && adxAuctionRpm.isComputable(data) && adxAuctionFillRate.isComputable(data),
      },
      {
        name: 'auctionFillRate',
        label: allAuctionFillRate.name,
        getFormula: ({ data }) => (auctionCount.isComputable(data) ? allAuctionFillRate : undefined),
        iconName: 'sigma',
      },
    ],
  },
  {
    getChartOptions: ({ currencySymbol }) => ({
      seriesType: 'line',
      isStacked: false,
      vAxis: {
        ...AnalyticsDefaultChartOptions.vAxis,
        format: displayWithCurrency('#.##', currencySymbol),
      },
    }),
    metric: {
      name: 'eCPM',
      tooltipText: ANALYTICS_TOOLTIPS.ECPM,
    },
    legendConfig: ['hb', 'adx'],
    tooltipConfig: [['hb', 'adx'], ['eCPM']],
    dataConfig: [
      {
        name: 'hb',
        getFormula: () => hbECPM,
        isComputable: ({ data }) => hbECPM.isComputable(data),
      },
      {
        name: 'adx',
        getFormula: () => adxECPM,
        isComputable: ({ data }) => adxECPM.isComputable(data),
      },
      {
        name: 'eCPM',
        label: allECPM.name,
        getFormula: () => allECPM,
        iconName: 'empty',
      },
    ],
  },
]

type PureViewabilityTimelineProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    data: ViewabilityMetricsByTime
    currencySymbol: CurrencySymbol
    currentEpoch: DateTime
    onMetricChange: (metric: string) => void
    timezone: Timezone
  }
const _PureViewabilityTimeline: FunctionComponent<PureViewabilityTimelineProps> = ({ data, currencySymbol, currentEpoch, onMetricChange, timezone, ...props }) => {
  const { metrics, currentConfig, currentMetric, metricMessage } = useMetrics({ config: viewabilityMetricsConfig, data, currencySymbol, onMetricChange })
  const { chart, legends } = useTimelineChart({ currentConfig, data: currentMetric.notApplicable ? { epoch: [] as string[] } : data, currencySymbol, currentEpoch, timezone })

  return <AnalyticsChartWidget {...props} icon={'chart_bar'} title={'Viewability metrics'} metrics={metrics} chart={chart} legends={legends} metricMessage={metricMessage} />
}
export const PureViewabilityTimeline = styled(_PureViewabilityTimeline)``
