import { BidderCatalogAlias, BidderParam, MappedBidder } from '@pubstack/common/src/bidderCatalog'
import { BidderParamValidation, BidderParamValidationType } from '@pubstack/common/src/bidderParamValidation'
import { SiteStacksByProperty } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useBidderParams, useSiteMapping, useSitesStacks } from '~/api/adm-api.hook'
import { FetchError } from '~/api/api-access'
import { useToast } from '~/components/Toast/useToasts'
import { downloadCSVdata } from '~/utils/csv'
import { PureSiteMapping } from './PureSiteMapping'
import { SitePageContext } from './SitePage'

export const SiteMapping: React.FunctionComponent = () => {
  const [mappedBidders, setMappedBidders] = useState<MappedBidder[]>([])
  const [sitesStacks, setSitesStacks] = useState<SiteStacksByProperty>({})
  const { bySiteId, loading } = useSiteMapping(null)
  const { currentSite } = useOutletContext<SitePageContext>()
  const toast = useToast()
  const bidderParamsAPI = useBidderParams(null)
  const { all: allSitesStacks } = useSitesStacks(null, '')
  const navigate = useNavigate()

  const loadMappedBidders = async () => {
    const result = await bySiteId(currentSite.id)
    setMappedBidders(result)
  }

  const loadSiteStacks = async () => {
    const result = await allSitesStacks.fetchSiteStacks()
    setSitesStacks(result)
  }

  useEffect(() => {
    loadMappedBidders()
    loadSiteStacks()
  }, [currentSite])

  const onMappingUpload = async (alias: BidderCatalogAlias, file: File, options?: { partialUpload?: boolean }) => {
    toast.dismiss()
    try {
      const bidderName = alias.bidderLabel
      const res = await bidderParamsAPI.uploadBySite(bidderName, file, currentSite.id, options?.partialUpload)
      toast.admSuccess(`${bidderName} integration`, true, navigate)
      if (!res.isUsed) {
        toast(`Your file contains no bid parameters. This will result in no integration for ${bidderName}.`, { duration: 10000 })
      }
      loadMappedBidders()
    } catch (e) {
      if (e instanceof FetchError) {
        const errorMessages = getMappingUploadErrorMessages(e)
        errorMessages.length > 10
          ? toast.alert('Too many errors to display. Please check you uploaded the right file and try again.')
          : errorMessages.forEach((message) => toast.alert(message, { duration: Infinity }))
      } else {
        toast.alert('An unknown error occurred while updating bidder params.', { duration: Infinity })
      }
    }
  }

  const onMappingUploadByAdUnit = async (adUnitName: string, bidderParams: BidderParam[]) => {
    toast.dismiss()
    try {
      await bidderParamsAPI.uploadBySiteAndAdunit(bidderParams, currentSite.id, adUnitName)
    } catch (e) {
      if (e instanceof FetchError) {
        const errorMessages = getMappingUploadErrorMessages(e)
        errorMessages.length > 10
          ? toast.alert('Too many errors to display. Please check you uploaded the right file and try again.')
          : errorMessages.forEach((message) => toast.alert(message, { duration: Infinity }))
      } else {
        toast.alert(`An unknown error occurred while updating bidder params for the adunit ${adUnitName}.`, { duration: Infinity })
      }
    }
    toast.admSuccess(`${adUnitName} integration`, true, navigate)
    loadMappedBidders()
  }

  const onMappingDownload = async (alias: BidderCatalogAlias, options?: { fullMapping?: boolean }) => {
    const bidderName = alias.bidderLabel
    const res = await bidderParamsAPI.downloadBySite(bidderName, currentSite.id, options?.fullMapping)
    const downloadType = options?.fullMapping ? 'mapping' : 'template'
    downloadCSVdata(res, `${currentSite.name}-${bidderName}-${downloadType}`)
  }

  const getMappingUploadErrorMessages = (e: FetchError<{ bidderName: string; isUsed: boolean }>): string[] => {
    const errorPayload = JSON.parse(e.message).data
    if (typeof errorPayload === 'string') {
      return [errorPayload]
    }
    if ('validationErrors' in errorPayload && Array.isArray(errorPayload.validationErrors)) {
      return errorPayload.validationErrors.map((error: BidderParamValidation<BidderParamValidationType>) => {
        switch (error.type) {
          case BidderParamValidationType.DUPLICATE_ENTRY: {
            const e = error as BidderParamValidation<BidderParamValidationType.DUPLICATE_ENTRY>
            return `Duplicate entry on line ${e.line}. ${e.detail.siteName} - ${e.detail.device} - ${e.detail.adUnitName} already exists.`
          }
          // TODO - rbu tmu sle 2023-03-07+2024-02-27 handle error message in the front end instead of just displaying backend's
          case BidderParamValidationType.MISSING_LINES: {
            const e = error as BidderParamValidation<BidderParamValidationType.MISSING_LINES>
            return `Missing lines in the file. Expected ${e.detail.expectedLines} but got ${e.detail.actualLines}.`
          }
          case BidderParamValidationType.WRONG_TYPE: {
            const e = error as BidderParamValidation<BidderParamValidationType.WRONG_TYPE>
            return `Wrong type on line ${e.line} : ${e.detail.paramName} should be of type ${e.detail.expectedType}`
          }
          case BidderParamValidationType.MISSING_PARAMS: {
            const e = error as BidderParamValidation<BidderParamValidationType.MISSING_PARAMS>
            return `Missing a required parameter on line ${e.line} : ${e.detail.paramName}`
          }
          default:
            return `Unknown error : ${JSON.stringify(error)}`
        }
      })
    }
    return []
  }

  return (
    <PureSiteMapping
      bidderList={mappedBidders}
      isLoading={loading || allSitesStacks.loading}
      site={currentSite}
      isUploading={bidderParamsAPI.uploadLoading}
      isMappingDownloading={bidderParamsAPI.downloadMappingLoading}
      isTemplateDownloading={bidderParamsAPI.downloadTemplateLoading}
      onMappingDownload={(alias, options) => onMappingDownload(alias, options)}
      onMappingUpload={(alias, file, options) => {
        onMappingUpload(alias, file, options)
      }}
      onMappingUploadByAdUnit={(adUnitName, bidderParams) => {
        onMappingUploadByAdUnit(adUnitName, bidderParams)
      }}
      onCreateNewBidder={() => navigate('/adstack/integrations/all-integrations?type=prebidBidder')}
      stacksByAdunit={sitesStacks}
    />
  )
}
