import styled from '@emotion/styled'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { integerAndCommaRegExp } from '@pubstack/common/src/input'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { FunctionComponent, useEffect } from 'react'
import { SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { useToast } from '~/components/Toast/useToasts'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

const SiteConfigForm = styled.form`
  ${_Input} {
    width: 200px;
  }
`

const AdUnitPathConfig = styled.div`
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
`
const Title = styled.div`
  padding-top: 20px;
  ${Fonts.H2};
  font-weight: bold;
  color: ${Colors.Jet};
`
const ContextKeySelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const DefaultValue = styled.div`
  display: flex;
  & p {
    ${Fonts.P2};
    margin-left: 10px;
  }
`

const MCM = styled.div`
  padding: 20px 20px 0px 20px;
`
const AdUnitPathExample = styled.p`
  color: ${Colors.Hurricane};
  ${Fonts.P2};
  font-weight: italic;
  & span {
    font-weight: bold;
  }
`

const Save = styled.div`
  display: flex;
  justify-content: flex-end;
`

type PureAdStackSiteConfigFormProps = WithClassName & {
  siteId: string
  scopeId: string
  siteConfig?: SiteConfig
  contextKeys: ContextKey[]
  createSiteConfig: (siteConfig: SiteConfig) => void
}

const _PureAdStackGamSiteConfigForm: FunctionComponent<PureAdStackSiteConfigFormProps> = ({ createSiteConfig, siteConfig, contextKeys, siteId, scopeId }) => {
  const contextKeyNameOptions: SelectOptionProp<string>[] = contextKeys.map((contextKey) => {
    return { label: contextKey.name, value: contextKey.name }
  })
  const toast = useToast()
  contextKeyNameOptions.push({ label: 'Ad server ad unit name', value: 'adServerAdUnitName' })
  const defaultValues = { ...siteConfig, siteId, scopeId: scopeId, adUnitPathConfig: siteConfig?.adUnitPathConfig?.map((a) => ({ value: a })) }
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm<Omit<SiteConfig, 'adUnitPathConfig'> & { adUnitPathConfig: { value: string }[] }>({
    defaultValues,
    mode: 'onChange',
  })

  const onSiteConfigSubmit: SubmitHandler<Omit<SiteConfig, 'adUnitPathConfig'> & { adUnitPathConfig: { value: string }[] }> = (formValue) => {
    const nameIndex = formValue.adUnitPathConfig.findIndex((e) => e.value === 'adServerAdUnitName')
    const emptyValue = formValue.adUnitPathConfig.findIndex((e) => !e.value)
    const adUnitPathConfigArray = formValue.adUnitPathConfig?.map((a) => a.value)
    const hasDuplicateValues = new Set(adUnitPathConfigArray).size !== adUnitPathConfigArray.length
    if (nameIndex > -1 && nameIndex !== formValue.adUnitPathConfig?.length - 1) {
      toast.alert(`The key "Ad server ad unit name" should be the last one.`, { icon: 'alert' })
    } else if (hasDuplicateValues) {
      toast.alert(`A key can be used only once.`, { icon: 'alert' })
    } else if (emptyValue > -1) {
      toast.alert(`A level can't be empty. Please fill it or remove it.`, { icon: 'alert' })
    } else {
      createSiteConfig({ ...formValue, adUnitPathConfig: adUnitPathConfigArray })
    }
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'adUnitPathConfig',
  })

  const { adUnitPathConfig } = useWatch({ control })

  useEffect(() => {
    reset(defaultValues)
  }, [siteConfig])

  return (
    <>
      <Title>Ad unit path</Title>
      <span>Using context keys, describe the architecture of the ad unit path</span>
      <SiteConfigForm id={'siteConfigForm'} onSubmit={handleSubmit(onSiteConfigSubmit)}>
        <AdUnitPathConfig>
          {fields.map((field, index) => (
            <ContextKeySelect key={field.id}>
              <Select
                control={control}
                error={errors.adUnitPathConfig?.[index]?.value?.message}
                name={`adUnitPathConfig.${index}.value`}
                label={`Level ${index + 1} based on`}
                options={contextKeyNameOptions.map((opt) => ({ ...opt, disabled: adUnitPathConfig?.some((a, i) => i !== index && a.value === opt.value) }))}
                searchable={true}
              />
              {adUnitPathConfig?.[index] && adUnitPathConfig?.[index].value && !errors.adUnitPathConfig?.[index]?.value?.message && (
                <DefaultValue>
                  {adUnitPathConfig?.[index].value !== 'adServerAdUnitName' ? (
                    contextKeys.find((c) => adUnitPathConfig?.[index].value === c.name)?.defaultValue ? (
                      <p>ex: {contextKeys.find((c) => adUnitPathConfig?.[index].value === c.name)?.defaultValue} </p>
                    ) : (
                      <>
                        <p>ex: {contextKeys.find((c) => adUnitPathConfig?.[index].value === c.name)?.values[0]}</p>
                        <Tooltip title={'There is no default value for this context key. If the value is missing on the page, it may not be monetized'}>
                          <Icon name={'warning'} fill={Colors.Pumpkin} height={'16px'} />
                        </Tooltip>
                      </>
                    )
                  ) : (
                    <p>ex: MyAdUnit</p>
                  )}
                </DefaultValue>
              )}
            </ContextKeySelect>
          ))}
          {fields && (
            <>
              {fields.length === 0 && (
                <Select
                  control={control}
                  error={errors.adUnitPathConfig?.[0]?.value?.message}
                  name={`adUnitPathConfig.0.value`}
                  label={'Level 1 based on'}
                  options={contextKeyNameOptions}
                  searchable={true}
                />
              )}
              {fields.length > 0 && (
                <Tooltip title={'Remove last level'}>
                  <Button
                    variant={'tertiary'}
                    iconName={'delete'}
                    onClick={() => {
                      remove(fields.length - 1)
                    }}
                  />
                </Tooltip>
              )}
              {fields.length < 5 && (
                <Button variant={'secondary'} iconName={'add'} onClick={() => append({ value: '' })}>
                  Add level
                </Button>
              )}
            </>
          )}
        </AdUnitPathConfig>

        <Title>MCM Id (optional)</Title>
        <MCM>
          <Input
            name={'mcm'}
            control={control}
            placeholder={'ex: 123456'}
            error={errors.mcm?.message}
            rules={{
              pattern: { value: integerAndCommaRegExp, message: 'MCM id should be numeric.' },
            }}
          />
        </MCM>
        <Save>
          <Button disabled={!isDirty} variant={'primary'} type={'submit'}>
            Save
          </Button>
        </Save>
      </SiteConfigForm>
    </>
  )
}

export const PureAdStackGamSiteConfigForm = styled(_PureAdStackGamSiteConfigForm)``
