import { ContextKey } from './contextKey'

/**
 * Generic object for Kleanads scope config
 */
export interface KleanadsScopeConfig {
  id: string
  scopeId: string
  description: string
  adCallTimeout: number
  apsPubId?: string
  configUrl: string
  screenSizeBreakpoints: ScreenSizeBreakpoint[]
  metricThrottling: boolean
  pbjsConfig: PbjsConfig
  pbjsModuleBidAdapters: string[]
  pbjsModuleOther: string[]
  pbjsModulesHash?: string
  pbjs: PbjsVersion
  pbjsDesired?: PbjsDesiredVersion
  networkId: number
  context?: ContextKey[]
  priceRuleBuckets?: PriceRule[]
  refreshEnable: boolean
  globalRefreshConfig: GlobalRefreshConfig
  pocOverrides?: { [key: string]: any }
  spaConfig?: SpaConfig
}

export interface GlobalRefreshConfig {
  refreshModuleBaseUrl: string
  prebidOrderIds: number[]
  hasExclusions: boolean
}

export interface ScreenSizeBreakpoint {
  size: number
  device: string
}

export interface PriceRule {
  max: string
  increment: string
}

export interface PbjsConfig {
  bidderAliases?: BidderAlias[]
  userSync?: string
  floors?: string
  schain?: string
}

export interface PbjsVersion {
  mainVersion: string
  newVersion?: string
  newRatio?: number
}

export interface PbjsDesiredVersion {
  desiredVersion: string
  desiredRatio: number
}

export interface BidderAlias {
  bidderLabel: string
  bidderSource: string
}
export interface BidderAdjustment {
  bidder: string
  adjustment: number
}

export type SpaMode = 'manual' | 'auto'
export interface SpaConfig {
  [siteId: string]: { mode: SpaMode }
}
export type SiteSpaConfig = {
  enabled: boolean
  mode: SpaMode
}

const DEFAULT_SCOPE_PREBID_VERSION = '8.48.0'
export const DEFAULT_KLEANADS_SCOPE_CONFIG: KleanadsScopeConfig = {
  id: '',
  pbjsConfig: JSON.parse(`{"bidderAliases": []}`),
  scopeId: '[SCOPE_ID]',
  description: '[SCOPE_NAME]',
  context: [],
  networkId: 0,
  pbjsModuleBidAdapters: [],
  pbjsModuleOther: ['consentManagement', 'currency', 'priceFloors', 'schain'],
  adCallTimeout: 3000,
  configUrl: 'https://cdn-adm.pbstck.com',
  screenSizeBreakpoints: [
    {
      device: 'mobile',
      size: 0,
    },
    {
      device: 'desktop',
      size: 768,
    },
  ],
  metricThrottling: false,
  pbjsModulesHash: ' ',
  pbjs: {
    mainVersion: DEFAULT_SCOPE_PREBID_VERSION,
  },
  refreshEnable: true,
  globalRefreshConfig: {
    prebidOrderIds: [],
    refreshModuleBaseUrl: 'https://cdn-kleanads-poc.pbstck.com/kleanads-refresh',
    hasExclusions: false,
  },
  priceRuleBuckets: [
    {
      increment: '0.05',
      max: '5',
    },
  ],
}
