import styled from '@emotion/styled'
import { AbTestConfiguration } from '@pubstack/common/src/abTest'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'

const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > * {
    display: inline-flex;
    flex-direction: column;
  }
`

type PureActivateAbTestModalProps = { abTest?: AbTestConfiguration; onToggle: () => void; navigateToView?: () => void }
export const PureActivateAbTestModal: FunctionComponent<PureActivateAbTestModalProps> = ({ abTest, onToggle, navigateToView }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Activate {abTest?.name}</Modal.Title>

      <Modal.Body>
        <Paragraphs>
          <p>
            <span>Only one A/B test can be live at the same time.</span>
            <span>If you activate this test, the current A/B test will be disabled.</span>
          </p>
        </Paragraphs>
      </Modal.Body>

      <Modal.Actions>
        <Button
          onClick={async () => {
            await close()
            navigateToView?.()
          }}
          variant={'tertiary'}
        >
          Keep current test enabled
        </Button>
        <Button
          onClick={() => {
            onToggle()
            return close()
          }}
        >
          Activate A/B test
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}
