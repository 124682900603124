import { RefreshGroup } from '@pubstack/common/src/refresh'
import { FunctionComponent, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Input } from '~/components/Input'

export const RefreshGroupNameForm: FunctionComponent<{ name: string; updateRefreshGroup: (value: string) => unknown }> = ({ name, updateRefreshGroup }) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Pick<RefreshGroup, 'name'>>({
    defaultValues: {
      name: name,
    },
    mode: 'onChange',
  })

  const updatedName = useWatch({ control, name: 'name' })
  useEffect(() => {
    if (updatedName !== name) {
      updateRefreshGroup(updatedName)
    }
  }, [updatedName])

  useEffect(() => {
    if (name.length) {
      if (getValues('name') !== name) {
        setValue('name', name, { shouldValidate: true })
      }
    }
  }, [name])

  return <Input error={errors.name?.message} label={'Group name'} labelIsPlaceholder control={control} name={'name'} rules={{ required: 'Please fill this to continue' }} />
}
