import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { AdUnit } from '@pubstack/common/src/adunit'
import { FloorRule, HeaderBiddingRule, LazyLoadingRule, RefreshRule, SaveStack, Stack } from '@pubstack/common/src/stack'
import { StackContext } from '@pubstack/common/src/stackContext'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAdUnits, useBidderAdapters, useContexts, useFloorRules, useHeaderBiddingRules, useLazyLoadingRules, useRefreshRules, useSitesStacks, useStacks } from '~/api/adm-api.hook'
import { useSites } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { useToast } from '~/components/Toast/useToasts'
import { WithClassName } from '~/types/utils'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureStackEdit, StackFormData } from './PureStackEdit'

const useData = (siteId: string, stackId: string, template: string) => {
  const { byId: sitesById } = useSites(null)
  const { byId: stackById } = useStacks(null)
  const { all: allStacks, byId: stacksById, validateName: validateStack } = useSitesStacks(null, siteId ?? '')
  const [site, setSite] = useState<Site | undefined>(undefined)
  const [stack, setStack] = useState<Stack | undefined>(undefined)
  const [contexts, setContexts] = useState<StackContext[]>([])
  const [stacks, setStacks] = useState<Stack[] | undefined>([])
  const [stackForm, setStackForm] = useState<StackFormData>({
    desktopAdUnits: [],
    mobileAdUnits: [],
    headerBiddingEnabled: true,
    headerBiddingRuleId: 'NO_RULE',
    adCallTimeout: 3000,
    commitMessage: '',
    name: 'Default',
  })
  const [adUnits, setAdUnits] = useState<AdUnit[]>([])
  const [floorRules, setFloorRules] = useState<FloorRule[]>([])
  const [lazyLoadingRules, setLazyLoadingRules] = useState<LazyLoadingRule[]>([])
  const [refreshRules, setRefreshRules] = useState<RefreshRule[]>([])
  const [headerBiddingRules, setHeaderBiddingRules] = useState<HeaderBiddingRule[]>([])
  const isSaving = useRef(false)
  const { all: allAdUnits } = useAdUnits(null)
  const { all: allContexts } = useContexts(null)
  const { all: allFloorRules } = useFloorRules(null)
  const { all: allLazyLoadingRules } = useLazyLoadingRules(null)
  const { all: allRefreshRules } = useRefreshRules(null)
  const { all: allHeaderBiddingRules } = useHeaderBiddingRules(null)
  const [bidderAdapters, setBiddersAdapters] = useState<BidderAdapter[]>([])
  const { all: allBidderAdapters } = useBidderAdapters(null)
  const toast = useToast()
  const navigate = useNavigate()
  const contextOptions: SelectOptionProp[] = contexts
    ? contexts.filter((c) => c.enabled).map((s) => ({ value: s.id, label: s.name, disabled: stacks?.find((t) => t.contextId === s.id && t.stackId !== stackId) ? true : false }))
    : [{ value: '', label: '' }]

  async function loadSite() {
    if (siteId) {
      try {
        const fetchSite = await sitesById.get(siteId)
        setSite(fetchSite)
      } catch (exception) {
        toast.alert('An error occurred while fetching this site.')
        throw exception
      }
    }
  }

  async function loadStacks() {
    setStacks(await allStacks.get())
  }

  async function loadAdUnits() {
    setAdUnits(await allAdUnits.get())
  }

  async function loadContexts() {
    const contexts = await allContexts.get()
    setContexts(contexts)
  }

  async function loadFloorRules() {
    setFloorRules(await allFloorRules.get())
  }
  async function loadLazyRules() {
    setLazyLoadingRules(await allLazyLoadingRules.get())
  }
  async function loadRefreshRules() {
    setRefreshRules(await allRefreshRules.get())
  }
  async function loadHeaderBiddingRules() {
    setHeaderBiddingRules(await allHeaderBiddingRules.get())
  }
  async function loadBidderAdapters() {
    setBiddersAdapters(await allBidderAdapters.get())
  }

  async function loadStack() {
    //TODO sle tmu 2023 06 20 - find a better way to use our adunits options in chiplistinput
    //currently we store adunit names in the adUnitIds array to accomodate the chiplistinput, but we need to store adunit ids in the saved stack
    //we really need it...  sle 2023 10 23
    const buildFloorRules = (stack: Stack) =>
      stack.floorsRules?.map((ruleConfig) => ({
        ...ruleConfig,
        adUnitIds: ruleConfig.adUnitIds.map((id) => adUnits.find((adunit) => adunit.id === id)?.name).filter((name): name is string => !!name),
      }))
    const buildRefreshRules = (stack: Stack) =>
      stack.refreshRules?.map((ruleConfig) => ({
        ...ruleConfig,
        adUnitIds: ruleConfig.adUnitIds.map((id) => adUnits.find((adunit) => adunit.id === id)?.name).filter((name): name is string => !!name),
      }))
    if (stackId) {
      try {
        const fetchStack = await stacksById.get(stackId)
        const floorsRules = buildFloorRules(fetchStack)
        const refreshRules = buildRefreshRules(fetchStack)
        setStack({ ...fetchStack, floorsRules, refreshRules }) // todo do we have to fill the modulerules here? (for stacks existing before the module rules were a thing)
      } catch (exception) {
        toast.alert('An error occurred while fetching this stack.')
        throw exception
      }
    } else {
      const lastVersionStackToPrefill = await stackById.get(template)
      if (!template) {
        setStackForm({ ...stackForm, name: stacks && stacks?.length > 0 ? '' : 'Default' })
      } else if (!lastVersionStackToPrefill) {
        setStackForm({ ...stackForm, name: '' })
        toast(`The stack ${template} was not found so no template is used.`) // e.g. when template is set directly in URL (so can be a non-existing stack)
      } else {
        const formData: StackFormData = {
          desktopAdUnits: lastVersionStackToPrefill.desktopAdUnits || [],
          mobileAdUnits: lastVersionStackToPrefill.mobileAdUnits || [],
          floorsRules: buildFloorRules(lastVersionStackToPrefill),
          refreshRules: buildRefreshRules(lastVersionStackToPrefill),
          lazyLoadingRules: lastVersionStackToPrefill.lazyLoadingRules,
          headerBiddingRuleId: lastVersionStackToPrefill.headerBiddingRuleId,
          headerBiddingEnabled: lastVersionStackToPrefill.headerBiddingEnabled,
          adCallTimeout: lastVersionStackToPrefill.adCallTimeout,
          name: stacks && stacks?.length > 0 ? '' : 'Default',
        }
        setStackForm(formData)
      }
    }
  }

  const validateStackUniqueName = async (name?: string) => {
    return name ? validateStack(name) : true
  }

  useEffect(() => {
    loadSite()
    loadStacks()
    loadContexts()
    loadAdUnits()
    loadFloorRules()
    loadLazyRules()
    loadRefreshRules()
    loadHeaderBiddingRules()
    loadBidderAdapters()
  }, [siteId])

  useEffect(() => {
    if (adUnits?.length > 0) {
      loadStack()
    }
  }, [adUnits, stackId, stacks])

  async function saveStack(stack: StackFormData, saveAsDraft: boolean) {
    if (isSaving.current) return

    isSaving.current = true

    //TODO sle tmu 2023 06 20 - find a better way to use our adunits options in chiplistinput
    //currently we store adunit names in the adUnitIds array to accomodate the chiplistinput, but we need to store adunit ids in the saved stack
    const floorsRules = stack.floorsRules?.map((ruleConfig) => ({
      ...ruleConfig,
      adUnitIds: ruleConfig.adUnitIds.map((name) => adUnits.find((adunit) => adunit.name === name)?.id).filter((id): id is string => !!id),
    }))

    const refreshRules = stack.refreshRules?.map((ruleConfig) => ({
      ...ruleConfig,
      adUnitIds: ruleConfig.adUnitIds.map((name) => adUnits.find((adunit) => adunit.name === name)?.id).filter((id): id is string => !!id),
    }))

    const formData: SaveStack = {
      ...stack,
      siteId,
      saveAsDraft,
      floorsRules,
      refreshRules,
    }
    if (stackId) {
      try {
        const stackUpdated = await stacksById.put(stackId, formData)
        toast.success(
          <>
            <strong>{stackUpdated.name}</strong> successfully updated !<div>Deploy the new version to use the changes on your site.</div>
          </>,
          { duration: 10000 }
        )
        navigate(`/adstack/sites/${siteId}/stacks`)
        isSaving.current = false
      } catch (exception) {
        toast.alert('Something went wrong during stack update.')
      }
    } else {
      try {
        const stackCreated = await stacksById.post(formData)
        toast.success(`${stackCreated.name} stack successfully created`)
        navigate(`/adstack/sites/${siteId}/stacks`)
        isSaving.current = false
      } catch (exception) {
        toast.alert('Something went wrong during stack creation.')
      }
    }
  }

  return useMemo(
    () => ({
      site,
      stack,
      stackForm,
      adUnits,
      contextOptions,
      floorRules,
      lazyLoadingRules,
      refreshRules,
      headerBiddingRules,
      saveStack,
      validateStackUniqueName,
      isSaving: isSaving.current,
      isLoading:
        stacksById.loading ||
        allContexts.loading ||
        allStacks.loading ||
        sitesById.loading ||
        allAdUnits.loading ||
        stackById.loading ||
        allBidderAdapters.loading ||
        allFloorRules.loading ||
        allLazyLoadingRules.loading ||
        allRefreshRules.loading ||
        allHeaderBiddingRules.loading,
      bidderAdapters,
    }),
    [site, stack, stackForm, adUnits, contextOptions, floorRules, lazyLoadingRules, refreshRules, headerBiddingRules, isSaving.current, bidderAdapters]
  )
}

type StackEditProps = WithClassName & {
  //
}
const _StackEdit: FunctionComponent<StackEditProps> = ({ className }) => {
  const navigate = useNavigate()
  const { siteId, stackId } = useParams()
  const [searchParams, _] = useSearchParams()
  const { site, stack, stackForm, adUnits, contextOptions, floorRules, lazyLoadingRules, refreshRules, headerBiddingRules, saveStack, validateStackUniqueName, isSaving, isLoading, bidderAdapters } =
    useData(siteId ?? '', stackId ?? '', searchParams.get('template') ?? '')
  const breadcrumbs = useBreadcrumbs(site ?? { name: '' })
  const currencySymbol = useScopeCurrency()
  const hasAdmRefresh = !!useUser()?.hasFeature('admRefresh')
  return (
    <PureStackEdit
      className={className}
      breadcrumbs={breadcrumbs}
      onCancel={() => navigate(-1)}
      isSaving={isSaving}
      isEditing={!!stack?.id}
      isLoading={isLoading}
      hasAdmRefresh={hasAdmRefresh}
      onSave={saveStack}
      validateStackUniqueName={validateStackUniqueName}
      stack={stack ?? stackForm}
      currencySymbol={currencySymbol}
      selectableLazyLoadingRules={lazyLoadingRules}
      selectableRefreshRules={refreshRules}
      selectableAdUnits={adUnits}
      contextOptions={contextOptions}
      selectableFloorRules={floorRules}
      selectableHeaderBiddingRules={headerBiddingRules}
      siteName={site?.name ?? ''}
      bidderAdapters={bidderAdapters}
      onHeaderBiddingRuleEdit={(id: string) => navigate(`/adstack/header-bidding/edit/${id}`)}
    />
  )
}
export const StackEdit = styled(_StackEdit)``
