import styled from '@emotion/styled'
import { FunctionComponent, ReactElement } from 'react'
import { Colors } from '~/assets/style/colors'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

const Title = styled.div`
  font-weight: bold;
  line-height: 24px;
  grid-area: title;
`
const Description = styled.div`
  grid-area: description;
`
const MessageIcon = styled(Icon)`
  margin-right: 8px;
  grid-area: icon;
`
const Wrapper = styled.div<{ status: MessageStatus }>`
  border-radius: 4px;
  padding: 12px 16px;
  background-color: ${({ status }) => colors[status].bg};
  display: grid;
  grid-template-areas:
    'icon title'
    'empty description';
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr;
  align-items: center;

  ${Title} {
    color: ${({ status }) => colors[status].text};
  }
`

//TODO 2022-06-26 cfo - change with theme colors
const colors: { [key in MessageStatus]: { bg: string; text: string } } = {
  info: { bg: '#f7f8fa', text: Colors.Jet },
  error: { bg: '#ffebe6', text: Colors.Alert },
  warning: { bg: '#fff4e5', text: Colors.Warning },
  success: { bg: '#e5f6f5', text: Colors.Success },
}

type MessageStatus = Extract<IconName, 'info' | 'warning'> | 'error' | 'success'
type MessageProps = WithClassName & {
  iconName?: IconName
  title?: string | ReactElement
  description?: string | ReactElement
  status?: MessageStatus
}
const _Message: FunctionComponent<MessageProps> = ({ className, title, description, status = 'info' }) => (
  <Wrapper className={className} status={status}>
    <MessageIcon name={status === 'error' ? 'alert' : status === 'success' ? 'check_circled' : status} width={'20px'} fill={colors[status].text} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
)
export const Message = styled(_Message)``
