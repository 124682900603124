import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useBidderAdapters, useContextKeys, useGamConfig, useGamSiteConfig, usePrebidSiteConfig, useTamConfig, useTamSiteConfig } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useSiteWithRoute } from '~/modules/adstack/useSiteWithRoute'
import { configurationState } from '~/state'
import { PureGamAndWrappers } from './PureGamAndWrappers'

export const _GamAndWrappers: React.FunctionComponent = () => {
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)
  const [contextKeys, setContextKeys] = useState<ContextKey[]>([])
  const [bidderAdapters, setBidderAdapters] = useState<BidderAdapter[]>([])
  const { site, siteId } = useSiteWithRoute()
  const [siteConfig, setSiteConfig] = useState<SiteConfig>({ scopeId: '', siteId: site.id })
  const [hasNetworkId, setHasNetworkId] = useState<boolean>(true)
  const { byId: gamConfigById } = useGamConfig(null)
  const { byId: gamSiteConfigById } = useGamSiteConfig(null)
  const { prebidSiteConfig: prebidSiteConfigById } = usePrebidSiteConfig(null)
  const { byId: tamConfigById } = useTamConfig(null)
  const { byId: tamSiteConfigById } = useTamSiteConfig(null)
  const { all: allContextKeys } = useContextKeys()
  const { all: allBidderAdapters } = useBidderAdapters(null)

  const toast = useToast()
  const navigate = useNavigate()

  const getCurrentGamConfig = async () => {
    const gamConfig = await gamConfigById.get()
    const tamConfig = await tamConfigById.get()
    const currentSiteConfig = gamConfig.siteConfigs.find((g) => g.siteId === siteId) || { scopeId: gamConfig.scopeId, siteId: site.id }
    const currentTamConfig = tamConfig.siteConfigs.find((s) => site.id === s.siteId) || { siteId: site.id, scopeId: gamConfig.scopeId, isTamEnabled: !!tamConfig.apsPubId }
    currentSiteConfig.isTamEnabled = currentSiteConfig.isTamEnabled === undefined ? !!currentTamConfig.isTamEnabled : currentSiteConfig.isTamEnabled
    setSiteConfig(currentSiteConfig)
    setHasNetworkId(!!gamConfig.networkId)
  }

  const getContextKeys = async () => {
    const res = await allContextKeys.get()
    setContextKeys(res)
  }
  const getBidderAdapters = async () => {
    if (allBidderAdapters.loading) {
      allBidderAdapters.abort()
    }
    const usedBidders = await allBidderAdapters.get()
    setBidderAdapters(usedBidders)
  }

  const getPageData = async () => {
    getCurrentGamConfig()
    getContextKeys()
    getBidderAdapters()
  }

  const onCreateSiteConfig = async (siteConfig: SiteConfig, type: string) => {
    try {
      if (type === 'GAM') {
        await gamSiteConfigById.put(siteConfig)
      } else if (type === 'Prebid') {
        await prebidSiteConfigById.put(siteConfig)
      } else if (type === 'TAM') {
        await tamSiteConfigById.put(siteConfig)
      }
      await getCurrentGamConfig()
      toast.admSuccess(type, true, navigate)
    } catch (err) {
      toast.alert(`An error occurred when trying to save the site configuration.`)
    }
  }

  useEffect(() => {
    getPageData()
  }, [])

  return (
    <PureGamAndWrappers
      siteConfig={siteConfig}
      baseCDNUrl={STATIC_ASSETS_CDN}
      bidderAdapters={bidderAdapters}
      contextKeys={contextKeys}
      hasNetworkId={hasNetworkId}
      onCreateSiteConfig={onCreateSiteConfig}
      isLoading={gamConfigById.loading}
    />
  )
}
export const GamAndWrappers = styled(_GamAndWrappers)``
