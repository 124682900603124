import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { Feature } from '@pubstack/common/src/rolesAndFeatures'
import { useMemo } from 'react'
import User from '~/auth/User'
import { ANALYTICS_TABS_CONFIG, TabConfig } from './analytics'
export const useBreakdownTabs = <T extends Dimension>(user: User | null, dimensions: Dimension[], optional?: { [key in T]: Feature }) => {
  return useMemo(() => {
    const tabsConfig: TabConfig[] = ANALYTICS_TABS_CONFIG.filter((tab) => dimensions.includes(tab.dimension)).map((tab) => ({
      iconName: tab.iconName,
      label: tab.label,
      dimension: tab.dimension,
    }))
    ;(Object.entries(optional ?? {}) as [Dimension, Feature][]).forEach(([dimension, feature]) => {
      if (user && user.hasFeature(feature)) {
        const tabConfig = ANALYTICS_TABS_CONFIG.find((value) => value.dimension === dimension)
        tabConfig &&
          tabsConfig.push({
            iconName: tabConfig.iconName,
            label: tabConfig.label,
            dimension: tabConfig.dimension,
          })
      }
    })
    return tabsConfig
  }, [user, dimensions, optional])
}
