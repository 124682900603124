import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { CATALOG_ITEM_TYPE, CatalogItem, CatalogItemType, REAL_TIME_DATA_MODULES_CONFIG } from '@pubstack/common/src/catalogItem'
import { ID_MODULES } from '@pubstack/common/src/userSync'
import { FunctionComponent, useEffect, useState } from 'react'
import { Color, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import { AdStackIntegrationLogo } from '~/components/AdStackIntegrationLogo'
import Button from '~/components/Button'
import Chip from '~/components/Chip'
import { Icon } from '~/components/Icon'
import { _Input } from '~/components/Input'
import { RouterUnstyledLink } from '~/components/RouterUnstyledLink'
import { _Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Skeleton } from '~/components/Skeleton'
import { Status } from '~/components/Status'
import { WithClassName } from '~/types/utils'
import { QueryParams } from '~/utils/useUrlSync'

type ModuleTypeConfig = {
  label: string
  color: Color
  borderColor: Color | string
}

const MODULE_TYPE_CONFIG: { [key in (typeof CATALOG_ITEM_TYPE)[CatalogItemType]]: ModuleTypeConfig } = {
  prebidModule: {
    label: 'Prebid module',
    color: Colors.Kaiminus,
    borderColor: 'rgba(40, 160, 179, 0.2)',
  },
  prebidBidder: {
    label: 'Prebid bidder',
    color: Colors.Ming,
    borderColor: 'rgba(67, 89, 205, 0.2)',
  },
  wrapper: {
    label: 'Wrapper',
    color: Colors.Lava,
    borderColor: 'rgba(237, 107, 91, 0.2)',
  },
  adServer: {
    label: 'Ad server',
    color: Colors.Violet,
    borderColor: 'rgba(175, 75, 206, 0.2)',
  },
  otherModules: {
    label: 'Other module',
    color: Colors.Kaiminus,
    borderColor: 'rgba(40, 160, 179, 0.2)',
  },
}

const CatalogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  gap: 20px;
`

const ModuleCard = styled.div<{ isUsed: boolean; type: CatalogItemType }>`
  cursor: pointer;
  width: 254px;
  display: flex;
  flex-direction: column;
  height: 138px;
  justify-content: space-between;
  padding: 10px 16px 16px 16px;
  background-color: ${Colors.White};
  ${BorderRadius.style}
  & img {
    align-self: center;
    max-height: 35px;
    max-width: 190px;
  }

  transition: 0.15s linear;
  &:hover {
    box-shadow: 0px 3px 10px rgba(54, 54, 54, 0.3);
    transform: translateY(-3px);
  }

  & ${Chip} {
    width: fit-content;
    height: 20px;
    color: ${({ type }) => MODULE_TYPE_CONFIG[type].color};
    border: ${({ type }) => `1px solid ${MODULE_TYPE_CONFIG[type].borderColor}`};

    & span {
      ${Fonts.P3};
    }
  }

  & div {
    display: flex;
    flex-direction: column;

    & > span {
      ${Fonts.P0};
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
    }
  }

  & ${Status} {
    display: flex;
    flex-direction: row;
    width: fit-content;
    ${Fonts.P2};
    color: ${Colors.Ash};

    ${(props) =>
      props.isUsed &&
      css`
        color: ${Colors.Jet};
      `}
  }
`
const FilterAction = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  margin: 16px 0;
  gap: 10px;
  ${_Input} {
    width: 388px;
  }
`

const NoModules = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 28px;
  text-align: center;
  ${Fonts.P1}
  h1 {
    ${Fonts.H1}
    font-weight: 400;
  }
`

const getTypeLabel = (type: CatalogItemType) => {
  return MODULE_TYPE_CONFIG[type].label
}

const getItemLink = (item: CatalogItem): string => {
  if (item.type === 'adServer') {
    return `adserver/${item.id}`
  }
  if (item.type === 'wrapper') {
    if (item.code === 'prebid') {
      return 'prebid/'
    } else {
      return `wrapper/${item.id}`
    }
  }
  if (item.type === 'prebidModule') {
    // types are not precise enough, we are routing based on what subtype of module it is
    if (ID_MODULES.find((module) => item.id === module.id)) {
      return `identity/${item.id}`
    }
    if (REAL_TIME_DATA_MODULES_CONFIG.find((module) => item.id === module.id)) {
      return `real-time-data/${item.id}`
    }
  }
  if (item.type === 'prebidBidder') {
    return `bidder/${item.id}`
  }

  throw new Error('Missing route for this catalog item')
}

type PureAdStackCatalogPageProps = WithClassName & {
  modules: CatalogItem[]
  catalogItemOptions: SelectOptionProp[]
  isLoading: boolean
  baseCDNUrl: string
  onCheckIntegrations: () => void
  search: string
  type: string
  setQueryParams: (params: QueryParams) => void
}
const _PureAdStackCatalogPage: FunctionComponent<PureAdStackCatalogPageProps> = ({
  className,
  modules,
  onCheckIntegrations,
  catalogItemOptions,
  isLoading,
  baseCDNUrl,
  search: searchParam,
  type: typeParam,
  setQueryParams,
}) => {
  const [search, setSearch] = useState(searchParam)
  const [typeFilter, setTypeFilter] = useState(typeParam)
  const filteredModules = modules.filter((module) => module.displayName.toLowerCase().includes(search.toLowerCase())).filter((b) => (typeFilter !== 'all' ? typeFilter === b.type && b : b))

  useEffect(() => {
    if (search === '' && typeFilter === 'all') {
      setQueryParams({})
    } else {
      setQueryParams({
        search,
        type: typeFilter,
      })
    }
  }, [search, typeFilter])
  return (
    <>
      <FilterAction>
        <_Input type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} value={search} onChange={(event) => setSearch(event.target.value)} />
        <_Select label={'Integration type'} options={catalogItemOptions} value={typeFilter} onChange={(option) => setTypeFilter(option.value as string)} />
        <Button
          iconName={'close'}
          variant={'tertiary'}
          onClick={() => {
            setSearch('')
            setTypeFilter('all')
          }}
        />
      </FilterAction>
      {!isLoading ? (
        <CatalogWrapper className={className}>
          {modules.length > 0 ? (
            filteredModules.length > 0 ? (
              filteredModules.map((module) => (
                <RouterUnstyledLink to={`/adstack/integrations/${getItemLink(module) ?? ''}`} key={module.id}>
                  <ModuleCard isUsed={module.isUsed} type={module.type}>
                    <Chip text={getTypeLabel(module.type as CatalogItemType)} color={Colors.White} />
                    <AdStackIntegrationLogo baseCDNUrl={baseCDNUrl} name={module.code} />
                    <div>
                      <span>
                        {module.displayName} <Icon width={'20px'} name={'chevron_right'} />
                      </span>
                      <Status state={module.isUsed ? 'active' : 'inactive'}>{module.isUsed ? 'Active' : 'Not used'}</Status>
                    </div>
                  </ModuleCard>
                </RouterUnstyledLink>
              ))
            ) : (
              <NoModules>
                <Icon name={'night_sky'} width={'190px'} />
                <h1>Oops</h1>
                <span>We can’t find what you seek. Please try another module.</span>
              </NoModules>
            )
          ) : (
            <NoModules>
              <Icon name={'night_sky'} width={'190px'} />
              <h1>You don’t have any integration yet.</h1>
              <Button variant={'primary'} onClick={onCheckIntegrations}>
                Check the available integrations
              </Button>
            </NoModules>
          )}
        </CatalogWrapper>
      ) : (
        <CatalogWrapper>
          <Skeleton bigger width={'286px'} />
          <Skeleton bigger width={'286px'} />
          <Skeleton bigger width={'286px'} />
        </CatalogWrapper>
      )}
    </>
  )
}

export const PureAdStackCatalogPage = styled(_PureAdStackCatalogPage)``
