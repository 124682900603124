import { cx } from '@emotion/css'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ForwardedRef, useEffect, useState } from 'react'
import * as React from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { Color, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import mergeRefs from '~/utils/mergeRefs'
import { Icon, IconName } from './Icon'

/**
 * @param isLabelStatic - if true, forces placing the label to its top position
 * @param internalInputRenderer - rendering function exposing the internal input field to customize its rendering
 * @see ChiplistInput to see internalInputRenderer in use
 */
export type BaseInputProps = WithClassName &
  React.ComponentProps<'input'> & {
    iconRight?: IconName
    iconRightColor?: Color
    label?: string
    labelIsPlaceholder?: boolean
    iconLeft?: IconName
    iconLeftColor?: Color
    error?: string | React.ReactNode
    isError?: boolean
    helper?: string | React.ReactNode
    isLabelStatic?: boolean
    internalInputRenderer?: (inputComponent: React.ReactNode) => React.ReactNode
    isMessageBlock?: boolean
    variant?: 'regular' | 'small'
    isClearable?: boolean
    onClear?: () => void
    additionalAction?: { icon: IconName; onClick: () => void; disabled?: boolean; renderer?: (actionComponent: React.ReactNode) => React.ReactNode }
  }

const _Input = styled.input<{ hasIconLeft?: boolean; hasIconRight?: boolean; variant?: 'regular' | 'small'; value: BaseInputProps['value'] }>`
  border: none;
  box-sizing: border-box;
  ${({ variant }) => (variant === 'small' ? Fonts.P2 : Fonts.P1)}
  margin: 0;
  flex: 1 1 auto;
  border-radius: 3px;
  color: ${Colors.Jet};
  padding: ${({ variant }) => (variant === 'small' ? '4px 0' : '10px 0')};
  min-width: 0;
    
  &:focus {
    outline: none;
  }
`

const Label = styled.label<{ inputWidth: number; isPlaceholder?: boolean; variant?: 'regular' | 'small' }>`
  font-weight: 400;
  font-style: italic;
  ${Fonts.P1}
  position: absolute;
  top: 10px;
  ${Fonts.colors.SlateGrey};
  pointer-events: none;
  transition: 400ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition-property: top, left, font-size, font-style, padding-left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: ${({ inputWidth }) => inputWidth}px;

  ${Icon} ~ & {
    left: ${({ variant }) => (variant === 'small' ? '28px' : '36px')}; ;
  }

  ${({ variant }) =>
    variant === 'small' &&
    css`
      top: 4px;
      left: 5px;
      ${Fonts.P2};
    `}

  ${(props) =>
    props.isPlaceholder
      ? css`
          .hasValue & {
            display: none;
          }
        `
      : css`
          .inputFocus &,
          .hasValue & {
            top: -5px;
            left: 10px;
            font-size: ${props.variant === 'small' ? Sizes[10] : Sizes[12]};
            font-style: normal;
            line-height: 12px;
            padding-left: 5px;
          }
        `}
`

const LegendLabel = styled.span`
  padding: 0 4px;

  &:empty {
    padding: 0;
    display: none;
  }
`
const Legend = styled.legend<{ variant?: 'regular' | 'small' }>`
  visibility: hidden;
  max-width: 0.01px;
  float: unset;
  overflow: hidden;
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: ${(props) => (props.variant === 'small' ? Sizes[10] : Sizes[12])};
  line-height: 12px;
  transition: max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  white-space: nowrap;
`
const Fieldset = styled.fieldset`
  position: absolute;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  border: 1px solid ${Colors.Platinum};
  border-radius: 3px;
  pointer-events: none;
  padding-right: 10px;
  padding-left: 10px;

  .inputHover & {
    border-color: ${Colors.Silver};
  }

  .inputFocus & {
    border-color: ${Colors.King};
  }

  .inputFocus &,
  .hasValue & {
    ${Legend} {
      max-width: 100%;
    }
  }
`

const MessageWrapper = styled.div`
  position: relative;
`

const Message = styled.span<{ isMessageBlock?: boolean }>`
  ${Fonts.P2}
  ${({ isMessageBlock }) =>
    isMessageBlock
      ? css`
          margin-top: 2px;
        `
      : css`
          position: absolute;
          top: 2px;
        `}
  display: inline-flex;
  align-items: center;
`

const ErrorMessage = styled(Message)`
  ${Fonts.colors.Alert}

  > * {
    margin-right: 4px;
  }
`

const HelpMessage = styled(Message)`
  ${Fonts.colors.SlateGrey}
  margin-left: 12px;
`

const InputButton = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  align-items: center;
  display: inline-flex;    
  `

const ActionButton = styled(InputButton)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
    cursor: default;
  `}
  > ${Icon} {
    color: ${({ disabled }) => (disabled ? Colors.Ash : Colors.King)};
  }

  :hover > ${Icon} {
    color: ${({ disabled }) => (disabled ? Colors.Ash : Colors.Cobalt)};
  }
  
  :active > ${Icon} {
    color: ${({ disabled }) => (disabled ? Colors.Ash : Colors.Navy)};
  }
`

const ClearButton = styled(InputButton)`
  > ${Icon} {
    visibility: hidden;
    color: ${Colors.Hurricane};
  }
  :hover > ${Icon} {
    color: ${Colors.Ash};
  }
  :active > ${Icon} {
    color: ${Colors.Jet};
  }
`

const Wrapper = styled.div``

const TextInputWrapper = styled.div<{ error: boolean; disabled?: boolean; variant?: 'regular' | 'small' }>`
  display: flex;
  position: relative;
  align-items: center;
  gap: 2px;
  padding: 0 ${({ variant }) => (variant === 'small' ? '4px' : '12px')};
  background-color: ${Colors.White};
  box-sizing: content-box;
  :hover{
    ${ClearButton}  ${Icon} {
      visibility: visible;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${Colors.Ghost};
      ${_Input} {
        background-color: ${Colors.Ghost};
        font-style: italic;
        ${Fonts.colors.SlateGrey}
      }

      .inputHover ${Fieldset} {
        border-color: ${Colors.Platinum};
      }

      .inputFocus ${Fieldset} {
        border-color: ${Colors.Platinum};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      ${Label} {
        ${Fonts.colors.Alert};
      }

      ${Fieldset} {
        border-color: ${Colors.Alert};
      }

      .inputHover ${Fieldset} {
        border-color: ${Colors.Alert};
      }

      .inputFocus ${Fieldset} {
        border-color: ${Colors.Alert};
      }

      & > ${Icon} {
        ${Fonts.colors.Alert};
      }
    `}
`

const _BaseInput = (
  {
    className,
    children,
    label,
    labelIsPlaceholder,
    isLabelStatic = false,
    iconLeft,
    iconRight,
    onChange,
    value,
    error,
    isError,
    helper,
    internalInputRenderer,
    isMessageBlock,
    iconRightColor = Colors.SlateGrey,
    iconLeftColor = Colors.SlateGrey,
    additionalAction,
    onClear,
    isClearable,
    ...props
  }: BaseInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [hasValue, setHasValue] = useState(!!value || isLabelStatic)
  const [inputHover, setInputHover] = useState(false)
  const [inputFocus, setInputFocus] = useState(false)
  const [inputWidth, setInputWidth] = useState(0)

  useEffect(() => {
    if (!isLabelStatic) {
      // TODO 2022-10-05 NRA TMU check falsy values instead of only 0
      if (value === 0) {
        setHasValue(true)
      } else {
        setHasValue(!!value)
      }
    } else {
      setHasValue(true)
    }
  }, [value, isLabelStatic])

  const localInputRef = React.useRef<HTMLInputElement>(null)
  useEffect(() => {
    // automatically set the width of the label to the width of the input
    setInputWidth(localInputRef.current?.offsetWidth ?? 0)
  }, [])

  const internalInput = (
    <_Input
      {...props}
      onChange={(event) => {
        if (!isLabelStatic) {
          setHasValue(!!event.target.value)
        }
        onChange && onChange(event)
      }}
      hasIconLeft={!!iconLeft}
      hasIconRight={!!iconRight}
      onMouseEnter={(event) => {
        setInputHover(true)
        props.onMouseEnter && props.onMouseEnter(event)
      }}
      onMouseLeave={(event) => {
        setInputHover(false)
        props.onMouseLeave && props.onMouseLeave(event)
      }}
      onBlur={(event) => {
        setInputFocus(false)
        props.onBlur && props.onBlur(event)
      }}
      onFocus={(event) => {
        setInputFocus(true)
        props.onFocus && props.onFocus(event)
      }}
      value={value}
      ref={mergeRefs([localInputRef, ref])}
    />
  )

  const clearAction = onClear ? onClear : () => onChange?.({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
  const isClearButtonVisible = !props.disabled && isClearable === undefined ? true : isClearable
  const additionalActionComponent = additionalAction && (
    <ActionButton
      disabled={additionalAction.disabled}
      type={'button'}
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        additionalAction.onClick()
      }}
    >
      <Icon name={additionalAction.icon} />
    </ActionButton>
  )

  useEffect(() => {
    if (props.type === 'number') {
      // restore onWheel update on number input
      const onWheel = () => {}
      localInputRef.current?.addEventListener('wheel', onWheel)
      return () => localInputRef.current?.removeEventListener('wheel', onWheel)
    }
  }, [localInputRef])

  return (
    <Wrapper className={cx([className, { hasValue, inputFocus, inputHover }])}>
      <TextInputWrapper error={!!error || !!isError} disabled={props.disabled} variant={props.variant}>
        {iconLeft && <Icon fill={iconLeftColor} name={iconLeft} width={'22px'} />}
        {internalInputRenderer ? internalInputRenderer(internalInput) : internalInput}
        <Label inputWidth={inputWidth} isPlaceholder={labelIsPlaceholder} variant={props.variant}>
          {label}
        </Label>
        {isClearButtonVisible && (
          <ClearButton
            type={'button'}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              clearAction()
            }}
          >
            <Icon name="close" />
          </ClearButton>
        )}
        {additionalAction && (additionalAction.renderer ? additionalAction.renderer(additionalActionComponent) : additionalActionComponent)}
        {iconRight && <Icon fill={iconRightColor} name={iconRight} width={'22px'} />}
        <Fieldset aria-hidden={'true'}>
          <Legend variant={props.variant}>
            <LegendLabel>{!labelIsPlaceholder && label}</LegendLabel>
          </Legend>
        </Fieldset>
      </TextInputWrapper>
      <MessageWrapper>
        {error ? (
          <ErrorMessage isMessageBlock={isMessageBlock}>
            <Icon name={'alert'} width={'0.875rem'} />
            {error}
          </ErrorMessage>
        ) : (
          helper && <HelpMessage isMessageBlock={isMessageBlock}>{helper}</HelpMessage>
        )}
      </MessageWrapper>
    </Wrapper>
  )
}

const BaseInput = styled(React.forwardRef<HTMLInputElement, BaseInputProps>(_BaseInput))``

export { BaseInput as _Input }

type InputProps<T extends FieldValues> = Omit<UseControllerProps<T>, 'defaultValue'> & Omit<BaseInputProps, 'value' | 'ref'>
// TODO 2022-10-27 NRA - name is mandatory but not control so it's painful when using the component
export const Input = <T extends FieldValues>({ name, control, rules, shouldUnregister, onChange, ...props }: InputProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <BaseInput
          {...props}
          value={field.value as string}
          onChange={(v) => {
            field.onChange(v.target.value)
            onChange && onChange(v)
          }}
        />
      )}
    />
  )
}
