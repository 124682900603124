import { cx } from '@emotion/css'
import styled from '@emotion/styled'
import { BidParam, BidderParam, BidderParamValue } from '@pubstack/common/src/bidderCatalog'
import { BidderParamValidationType } from '@pubstack/common/src/bidderParamValidation'
import { ComponentProps, FunctionComponent, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { CellComponent, Matrix, Spreadsheet } from 'react-spreadsheet'
import { useDebounceCallback } from 'usehooks-ts'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'
import { CellHeader, Header, RowIndicator } from './PureAdStackIntegrationSpreadsheet'

const Container = styled.div`
  Table {
    width: 100%;
    box-sizing: border-box;

    tr {
      th:first-of-type,
      td:first-of-type {
        width: 5ch;
      }
    }
  }
`

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 12px 0px;
  ${_Input} {
    width: 286px;
  }
`

const SpreadsheetContainer = styled.div`
  max-height: 450px;
  width: 100%;
  margin: 10px 0px;
`

const NoDataSpreadsheet = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  ${Fonts.P0}
  height: 200px;
`

const getOffsetRect = (el: HTMLElement) => {
  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
    left: el.offsetLeft,
    top: el.offsetTop,
  }
}

const CellContainer = styled.td<{ hasError?: boolean }>`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 0 4px;
    width: 100%;
    height: 100%;
    line-height: 20px;
    font-size: 0.875rem;
  }
  ${({ hasError }) => hasError && `background-color: ${Colors.Mew};`};
  --readonly-text-color: ${Colors.Ash};
`

type CellProps = {
  value: string | number | boolean | BidderParamValue | unknown[]
  readOnly: boolean
  rowId: string
}
const Cell: FunctionComponent<ComponentProps<CellComponent<CellProps>> & { validation?: SpreadsheetValidationEntry[]; bidParams: BidParam[]; fixedHeaders: CellHeader[] }> = ({
  column,
  row,
  selected,
  DataViewer: Dv,
  activate,
  active,
  data,
  dragging,
  evaluatedData,
  mode,
  select,
  setCellData,
  setCellDimensions,
  validation: currentValidation,
  bidParams,
  fixedHeaders,
}) => {
  const rootRef = useRef<HTMLTableCellElement | null>(null)
  const point = useMemo(
    (): { row: number; column: number } => ({
      row,
      column,
    }),
    [row, column]
  )
  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLTableCellElement>) => {
      if (mode === 'view') {
        setCellDimensions(point, getOffsetRect(event.currentTarget))

        if (event.shiftKey) {
          select(point)
        } else {
          activate(point)
        }
      }
    },
    [mode, setCellDimensions, point, select, activate]
  )

  const handleMouseOver = useCallback(
    (event: React.MouseEvent<HTMLTableCellElement>) => {
      if (dragging) {
        setCellDimensions(point, getOffsetRect(event.currentTarget))
        select(point)
      }
    },
    [setCellDimensions, select, dragging, point]
  )

  const errorMessage: string | undefined = useMemo(() => {
    if (currentValidation) {
      // get the validation entries for the current cell if any
      const validationEntries = currentValidation?.filter((v) => getRowId(v) === (evaluatedData?.rowId ?? ''))
      const cellInError = validationEntries?.some((v) => v.param.name === bidParams[column - fixedHeaders.length]?.name)
      const errorMessage = cellInError ? generateTooltipErrorMessageContent(validationEntries?.[0]?.errorType, bidParams[column - fixedHeaders.length]) : undefined
      return errorMessage
    }
  }, [data, currentValidation])

  useEffect(() => {
    const root = rootRef.current
    if (selected && root) {
      setCellDimensions(point, getOffsetRect(root))
    }
    if (root && active && mode === 'view') {
      root.focus()
    }
  }, [setCellDimensions, selected, active, mode, point, data])

  return (
    <CellContainer
      hasError={!!errorMessage}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      tabIndex={0}
      className={cx('Spreadsheet__cell', data?.readOnly && 'Spreadsheet__cell--readonly')}
    >
      <div>
        <Dv row={row} column={column} cell={data} evaluatedCell={evaluatedData} setCellData={setCellData} />
        {errorMessage && (
          <Tooltip reposition title={errorMessage}>
            <span>{<Icon width={'16px'} fill={Colors.Alert} name={'warning'} />}</span>
          </Tooltip>
        )}
      </div>
    </CellContainer>
  )
}

const ValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 5px;
`

const ValidationMessageContainer = styled.div`
  display: inline-flex;
  align-items: center;
  ${Fonts.colors.Alert}
  ${BorderRadius.style};
`

const SpreadSheetActions = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 24px;
  justify-content: flex-end;
`

const ValidationMessage: FunctionComponent<WithClassName & { children: ReactNode }> = ({ className, children }) => {
  return (
    <ValidationMessageContainer className={className}>
      <Icon name={'warning'} height={'16px'} fill={Colors.Alert} />
      <span>{children}</span>
    </ValidationMessageContainer>
  )
}

export type SpreadsheetValidationEntry = {
  device: 'desktop' | 'mobile'
  siteName: string
  adUnitName: string
  bidderName: string
  bidderParamIndex: number
  param: BidParam
  errorType: BidderParamValidationType.MISSING_PARAMS | BidderParamValidationType.WRONG_TYPE
}

/**
 * Get a unique row comp id for a bidder param consisting of siteName, device and adUnitName concatenated together
 */
const getRowId = (param: Pick<BidderParam, 'adUnitName' | 'siteName' | 'device' | 'bidderName'>) => `${param.siteName}-${param.device}-${param.adUnitName}-${param.bidderName}`

/**
 * Return the error message content based on the error type for every line
 */
const generateErrorMessageContent = (errorType: BidderParamValidationType, param: BidParam, rowIndexes: number[]) => {
  const adjustedRowIndexes = rowIndexes.map((index) => index + 1)
  switch (errorType) {
    case BidderParamValidationType.MISSING_PARAMS:
      return `Missing required parameter ${param.name} on the following rows : ${adjustedRowIndexes.join(', ')}`
    case BidderParamValidationType.WRONG_TYPE:
      return `${param.name} is expected to be of ${param.type} type on lines : ${adjustedRowIndexes.join(', ')}`
  }
}
/**
 * Return the error message content based on the error type for every line
 */
const generateTooltipErrorMessageContent = (errorType: BidderParamValidationType, param: BidParam) => {
  switch (errorType) {
    case BidderParamValidationType.MISSING_PARAMS:
      return `Missing required parameter ${param.name}`
    case BidderParamValidationType.WRONG_TYPE:
      return `${param.name} is expected to be of ${param.type} type`
  }
}

type SpreadsheetValidationDisplayProps = {
  getRowIndex: (entry: SpreadsheetValidationEntry) => number
  validation: SpreadsheetValidationEntry[]
  params: BidParam[]
}

const SpreadsheetValidationDisplay: FunctionComponent<SpreadsheetValidationDisplayProps> = ({ getRowIndex, validation, params }) => {
  return (
    <ValidationContainer>
      {params
        // group by param
        .filter((param) => validation.some((v) => v.param.name === param.name))
        // and show error on all lines for each param
        .map((param, i) => (
          <ValidationMessage key={i}>
            {generateErrorMessageContent(validation.find((v) => v.param.name === param.name)!.errorType, param, validation.filter((v) => v.param.name === param.name).map(getRowIndex))}
          </ValidationMessage>
        ))}
    </ValidationContainer>
  )
}

type PureAdStackIntegrationSpreadsheetProps = WithClassName & {
  bidderParams: BidderParam[]
  bidParamsMap: { [key: string]: BidParam[] }
  validateBidderParams: (bidderParamsValue: BidderParam[]) => SpreadsheetValidationEntry[]
  onBidderParamsUpdate: (bidderParamsValue: BidderParam[]) => void
}

const _PureAdStackSiteMappingSpreadsheet: FunctionComponent<PureAdStackIntegrationSpreadsheetProps> = ({ className, bidderParams, bidParamsMap, validateBidderParams, onBidderParamsUpdate }) => {
  const [validation, setValidation] = useState<SpreadsheetValidationEntry[]>([])
  const [currentBidderParams, setCurrentBidderParams] = useState<BidderParam[]>([...bidderParams]) // Now we can have multiple bidders here

  useEffect(() => {
    setCurrentBidderParams([...bidderParams])
  }, [bidderParams])

  const { control } = useForm({
    defaultValues: {
      bidderSearch: '',
    },
  })

  const bidderSearch = useWatch({ control, name: 'bidderSearch' })

  const fixedHeaders: CellHeader[] = [
    {
      name: 'Bidder',
      width: 260,
    },
    {
      name: 'Device',
      width: 100,
    },
  ]
  const bidParamsHeadersMap = Object.entries(bidParamsMap).reduce(
    (acc, [key, value]) => {
      acc[key] = value.map((param) => param.name)
      return acc
    },
    {} as { [key: string]: any }
  )
  const headersMap = Object.entries(bidParamsHeadersMap).reduce(
    (acc, [key, value]) => {
      acc[key] = [...fixedHeaders.map((f) => f.name), ...(value.length ? value : [])]
      return acc
    },
    {} as { [key: string]: any[] }
  )

  const bidderParamToSpreadsheetRow = (bidderParam: BidderParam) => {
    return bidParamsMap[bidderParam.bidderName]
      ? [
          { value: bidderParam.bidderName, readOnly: true, rowId: getRowId(bidderParam) },
          { value: bidderParam.device, readOnly: true, rowId: getRowId(bidderParam) },
          ...bidParamsMap[bidderParam.bidderName].map((param) => ({ value: bidderParam.params[param.name], readOnly: false, rowId: getRowId(bidderParam) })),
        ]
      : []
  }

  const currentBidderParamsMap = currentBidderParams.reduce(
    (acc, e) => {
      if (acc[e.bidderName]) {
        acc[e.bidderName].push(e)
      } else {
        acc[e.bidderName] = [e]
      }
      return acc
    },
    {} as { [key: string]: BidderParam[] }
  )

  const spreadsheetDataMap = Object.entries(currentBidderParamsMap).reduce(
    (acc, [key, value]) => {
      const spreadsheetData = value.filter((bp) => bp.bidderName.toLowerCase().includes(bidderSearch.toLowerCase())).map(bidderParamToSpreadsheetRow)
      acc[key] = spreadsheetData
      return acc
    },
    {} as { [key: string]: Matrix<CellProps> }
  )

  /**
   * Use unique rowId to get a specific row in our spreadsheet data
   */
  const getRowIndex = (rowId: string) => {
    return Object.values(spreadsheetDataMap)
      .flatMap((e) => e)
      .findIndex((row) => row[0]?.rowId === rowId)
  }

  const spreadsheetDataToBidderParams = (data: Matrix<BidderParamValue>) => {
    const newBidderParams = [...currentBidderParams]
    // We need to update the bidderParams with the new values
    data.forEach((row, index) => {
      const rowIndex = currentBidderParams.findIndex((bidderParam) => row[0]?.rowId === getRowId(bidderParam))
      const foundBidderParam = currentBidderParams[rowIndex]
      if (foundBidderParam) {
        const newParams = Object.fromEntries(
          headersMap[foundBidderParam.bidderName]
            .map((header, hIndex) => (fixedHeaders.some((f) => f.name === header) ? [] : [header, data[index][hIndex]?.value ?? '']))
            .filter((entry) => entry.length && !!entry[1])
        )
        if (rowIndex >= 0) {
          newBidderParams.splice(rowIndex, 1, {
            ...currentBidderParams[rowIndex],
            params: newParams,
          })
        }
      }
    })
    setValidation(validateBidderParams(newBidderParams))
    setCurrentBidderParams(newBidderParams)
  }

  // Debounce to avoid unnecessary rendering bogging down our UI
  const debouncedOnChange = useDebounceCallback(spreadsheetDataToBidderParams, 100, {})

  const bidderLabels: Set<string> = new Set(bidderParams.map((bp) => bp.bidderName))

  return (
    <Container className={className}>
      <Filters>
        <Input name={'bidderSearch'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search bidder name or alias'} control={control} />
        <Button disabled={!!validation?.length} onClick={() => onBidderParamsUpdate(currentBidderParams)}>
          Save mapping
        </Button>
      </Filters>

      {!Object.values(spreadsheetDataMap).flatMap((e) => e).length && (
        <NoDataSpreadsheet>
          <Icon name={'night_sky'} width={'144px'} />
          <div>No data to display, please check your filters above. </div>
        </NoDataSpreadsheet>
      )}
      {Array.from(bidderLabels).map((bidderLabel) => {
        const spreadsheetData = spreadsheetDataMap[bidderLabel]
        const bidParams = bidParamsMap[bidderLabel]

        return (
          <div key={bidderLabel}>
            <SpreadsheetContainer>
              {spreadsheetData.length > 0 && (
                // TODO tmu sle 2024-03-05 - we need to have a proper spreadsheet component handling all this nonsense in a pure and structured way. Don't have time right now
                <Spreadsheet
                  ColumnIndicator={({ column, onSelect, selected, label }) => {
                    const isRequired = bidParams[column - fixedHeaders.length]?.required
                    return (
                      <Header
                        onClick={(event) => onSelect(column, event.shiftKey)}
                        className={cx(['Spreadsheet__header', selected && 'Spreadsheet__header--selected'])}
                        fixedWidth={fixedHeaders[column]?.width}
                      >
                        {label} {isRequired && '*'}
                      </Header>
                    )
                  }}
                  Cell={(props) => {
                    return <Cell bidParams={bidParams} fixedHeaders={fixedHeaders} validation={validation} {...props} />
                  }}
                  RowIndicator={({ onSelect, row, selected, label }) => {
                    const hasError = validation?.some((v) => v.bidderName === bidderLabel && getRowIndex(getRowId(v)) === row)
                    return (
                      <RowIndicator
                        hasError={hasError}
                        selected={selected}
                        onClick={(event) => onSelect(row, event.shiftKey)}
                        className={cx(['Spreadsheet__header', selected && 'Spreadsheet__header--selected'])}
                      >
                        {label ?? row + 1}
                      </RowIndicator>
                    )
                  }}
                  css={{
                    overflowY: 'auto',
                    overflowX: 'auto',
                    maxHeight: '440px',
                    maxWidth: '100%',
                  }}
                  data={spreadsheetData}
                  columnLabels={headersMap[bidderLabel]}
                  onChange={(data) => {
                    debouncedOnChange(data)
                  }}
                  // Needs to be set to an empty function to avoid performance issues
                  // we don't use the formula parser in this context
                  createFormulaParser={() => () => {}}
                />
              )}
            </SpreadsheetContainer>
            {validation?.length ? <SpreadsheetValidationDisplay getRowIndex={(v) => getRowIndex(getRowId(v))} validation={validation} params={bidParams} /> : undefined}
          </div>
        )
      })}
      <SpreadSheetActions>
        <Button disabled={!!validation?.length} onClick={() => onBidderParamsUpdate(currentBidderParams)}>
          Save mapping
        </Button>
      </SpreadSheetActions>
    </Container>
  )
}

export const PureAdStackSiteMappingSpreadsheet = styled(_PureAdStackSiteMappingSpreadsheet)``
