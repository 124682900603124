import styled from '@emotion/styled'
import { FunctionComponent, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useAnalyticsRequestEmailQuery } from '~/api/api.hook'
import { currentScopeState } from '~/state'
import { WithClassName } from '~/types/utils'
import { PureAnalyticsDemoRequestPage } from './PureAnalyticsDemoRequestPage'

const AnalyticsDemoRequestWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

type AnalyticsDemoRequestPageProps = WithClassName
export const AnalyticsDemoRequestPage: FunctionComponent<AnalyticsDemoRequestPageProps> = ({ className }) => {
  const [currentScope, setCurrentScope] = useRecoilState(currentScopeState)
  const pending = currentScope.requestAnalyticsSent

  const [sent, setSent] = useState(false)
  const { byId } = useAnalyticsRequestEmailQuery(null)

  const onRequestSend = async () => {
    if (byId.loading) {
      byId.abort()
    }
    await byId.put()
    setSent(true)
    setCurrentScope((scope) => ({ ...scope, requestAnalyticsSent: true }))
  }

  return (
    <AnalyticsDemoRequestWrapper>
      <PureAnalyticsDemoRequestPage className={className} isEmailSent={sent} isRequestPending={pending} isSending={byId.loading} onSendRequestClick={onRequestSend} />
    </AnalyticsDemoRequestWrapper>
  )
}
