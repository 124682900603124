import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Color, ColorUtil, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius, Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

const CardSide = styled.div`
  display: flex;
  flex-direction: column;
  padding: 11px 12px 11px 11px;
  border-radius: ${BorderRadius.value} 0 0 ${BorderRadius.value};
  border: 1px solid transparent;
  border-right: none;
`
const CardIcon = styled(Icon)``

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 11px 15px 11px 16px;
  border-radius: 0 ${BorderRadius.value} ${BorderRadius.value} 0;
  border: 1px solid transparent;
  border-left: none;
  flex-grow: 1;
  transition: all ${Transitions.quick};
`

const CardBodyHeader = styled.div`
  display: flex;
  align-items: center;
`
const CardTitle = styled.span`
  ${Fonts.P0}
`

const CardWrapper = styled.div<{ color: Color }>`
  display: flex;
  background: ${Colors.White};
  ${Fonts.P1}
  cursor: pointer;

  :hover {
    ${CardBody} {
      border: 1px solid ${({ color }) => ColorUtil.hexOpacity(color, 0.2)};
      border-left: none;
    }
  }

  :active {
    & ${CardSide} {
      background-color: ${({ color }) => ColorUtil.hexOpacity(color, 0.3)};
    }

    ${CardBody} {
      border: 1px solid ${({ color }) => ColorUtil.hexOpacity(color, 0.3)};
      border-left: none;
    }
  }

  & ${CardSide} {
    background-color: ${({ color }) => ColorUtil.hexOpacity(color, 0.2)};

    & ${CardIcon} {
      color: ${({ color }) => color};
    }
  }
`

export type AccessCardProps = WithClassName & { title: string; content: string; color: Color; icon: IconName; onClick: () => unknown }
const _AccessCard: FunctionComponent<AccessCardProps> = ({ className, title, content, onClick, color, icon }) => {
  return (
    <CardWrapper color={color} className={className} onClick={onClick}>
      <CardSide>
        <CardIcon width={'30px'} name={icon} />
      </CardSide>
      <CardBody>
        <CardBodyHeader>
          <CardTitle>{title}</CardTitle>
          <Icon width={'20px'} name={'chevron_right'} />
        </CardBodyHeader>
        <p>{content}</p>
      </CardBody>
    </CardWrapper>
  )
}

export const AccessCard = styled(_AccessCard)``
