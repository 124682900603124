import styled from '@emotion/styled'
import { ColorUtil, Colors } from '~/assets/style/colors'

export const DataTableRow = styled.tr`
  border-bottom: 1px solid ${Colors.Platinum};
`

export const DataTableBody = styled.tbody`
  :last-of-type ${DataTableRow}:last-of-type {
    border-bottom: none;
  }

  :hover {
    cursor: pointer;
    background: linear-gradient(
      90deg,
      ${ColorUtil.hexOpacity(Colors.Platinum, 0.2)} 0%,
      ${ColorUtil.hexOpacity(Colors.Platinum, 0.3)} 17.19%,
      ${ColorUtil.hexOpacity(Colors.Platinum, 0.3)} 83.85%,
      ${ColorUtil.hexOpacity(Colors.Platinum, 0.2)} 100%
    );
  }
`
