import styled from '@emotion/styled'
import { FunctionComponent, useEffect } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { ChipListInput } from '~/components/ChipListInput'
import { Input, _Input } from '~/components/Input'
import { WidgetMessage } from '~/components/WidgetMessage'

type RefreshGroupKeyValues = Array<{ key: string; value: string[] }>

const KeyValueWrapper = styled.section`
  display: inline-flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;

  ${_Input} {
    min-width: 360px;
  }
`

export const RefreshGroupKeyValuesForm: FunctionComponent<{
  keyValues: RefreshGroupKeyValues
  updateRefreshGroup: (keyValueTargetings: RefreshGroupKeyValues) => unknown
}> = ({ keyValues, updateRefreshGroup }) => {
  const {
    control,
    formState: { isValid },
    trigger,
  } = useForm<{ gamKeys: RefreshGroupKeyValues }>({
    defaultValues: { gamKeys: keyValues.length ? keyValues : [{ key: '', value: [] }] },
  })
  const { fields, append, remove } = useFieldArray({ control, name: 'gamKeys' })

  const values = useWatch({
    control,
    name: 'gamKeys',
  })

  useEffect(() => {
    // this manual trigger of validation allows react-hook form to reevaluate the "required" conditions on our fields
    trigger('gamKeys')
    updateRefreshGroup([...values.filter((v) => !!v.key && !!v.value.length)])
  }, [values])

  return (
    <>
      <span>You can target one or several key-values to enable refresh only when they are present on the page.</span>
      <WidgetMessage message={'We recommend to set key-value at page level when possible'} icon={'tips'} />
      {fields.map((field, index) => (
        <KeyValueWrapper key={field.id}>
          <Input autoFocus label={'Target key'} control={control} name={`gamKeys.${index}.key`} rules={{ required: values.length > 1 || !!values[index]?.value?.length }} />
          <ChipListInput
            chipColor={Colors.Pool}
            isColorVariable
            helper={'Type a value then press space or enter.'}
            label={'Values'}
            control={control}
            name={`gamKeys.${index}.value`}
            rules={{ required: values.length > 1 || !!values[index]?.key.length }}
          />
          {fields.length > 1 && index !== 0 && (
            <Button
              onClick={() => {
                remove(index)
              }}
              variant={'tertiary'}
              iconName={'delete'}
            />
          )}
        </KeyValueWrapper>
      ))}
      {/* shouldFocus is disabled in order to leave the native autofocus handle it */}
      <Button variant={'secondary'} disabled={!isValid} onClick={() => append({ key: '', value: [] }, { shouldFocus: false })}>
        Add other key-value
      </Button>
    </>
  )
}
