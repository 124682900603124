import { ContextKey } from '@pubstack/common/src/contextKey'
import { GamConfig } from '@pubstack/common/src/gam/gamConfig'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useContextKeys, useGamConfig, useGamSiteConfig } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useSiteWithRoute } from '~/modules/adstack/useSiteWithRoute'
import { configurationState } from '~/state'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackGamIntegrationPage } from './PureAdStackGamIntegrationPage'

export const AdStackGamIntegrationPage: React.FunctionComponent = () => {
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)
  const [contextKeys, setContextKeys] = useState<ContextKey[]>([])
  const [gamConfig, setGamConfig] = useState<GamConfig>({ scopeId: '', networkId: '', sites: [], siteConfigs: [] })
  const { site, siteId } = useSiteWithRoute()
  const breadcrumbs = useBreadcrumbs(site ?? 'Google Ad Manager (GAM)')
  const toast = useToast()
  const navigate = useNavigate()
  const { byId: gamConfigById } = useGamConfig(null)
  const { byId: gamSiteConfigById } = useGamSiteConfig(null)
  const { all: allContextKeys } = useContextKeys()

  const getCurrentGamConfig = async () => {
    const gamConfig = await gamConfigById.get()
    setGamConfig(gamConfig)
  }

  const getContextKeys = async () => {
    const res = await allContextKeys.get()
    setContextKeys(res)
  }

  const getPageData = async () => {
    getCurrentGamConfig()
    getContextKeys()
  }

  const createNetworkId = async (networkId: string) => {
    try {
      const res = await gamConfigById.put(networkId)
      setGamConfig(res)
      toast.success(`The network id has been saved.`)
    } catch (err) {
      toast.alert('An error occurred when trying to save the network id.')
    }
  }

  const createSiteConfig = async (siteConfig: SiteConfig) => {
    try {
      await gamSiteConfigById.put(siteConfig)
      await getCurrentGamConfig()
      toast.admSuccess(gamConfig.sites.find((site) => site.id === siteConfig.siteId)?.name ?? '')
    } catch (err) {
      toast.alert(`An error occurred when trying to save the site configuration.`)
    }
  }

  const onNavigateToContextKeys = () => {
    navigate('/adstack/context/context-keys')
  }
  const onNavigateToAdUnits = () => {
    navigate('/adstack/adunits')
  }

  useEffect(() => {
    getPageData()
  }, [])

  return (
    <PureAdStackGamIntegrationPage
      isLoading={gamConfigById.loading}
      breadcrumbs={breadcrumbs}
      createNetworkId={createNetworkId}
      gamConfig={gamConfig}
      contextKeys={contextKeys}
      createSiteConfig={createSiteConfig}
      onNavigateToContextKeys={onNavigateToContextKeys}
      onNavigateToAdUnits={onNavigateToAdUnits}
      onBackClick={() => navigate(-1)}
      baseCDNUrl={STATIC_ASSETS_CDN}
      fromSiteId={siteId}
    />
  )
}
