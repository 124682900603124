import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'

const BoldText = styled.span`
  font-weight: bold;
`

type PureArchiveStackModalProps = { stackName: string; onArchive: () => Promise<void> }
const _PureArchiveStackModal: FunctionComponent<PureArchiveStackModalProps> = ({ stackName, onArchive }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Archive {stackName}</Modal.Title>

      <Modal.Body>
        <div>
          You are about to archive <BoldText>{stackName}</BoldText> and <BoldText>all its versions</BoldText>.
        </div>
        <div>To continue to monetize the ad units, make sure to use them in another stack. </div>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onArchive}>
          Archive stack
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

export const PureArchiveStackModal = styled(_PureArchiveStackModal)``
