export interface AbTestConfiguration {
  id: string
  scopeId: string
  name: string
  variants: Array<{ variant: string }>
  active: boolean
}

export const EMPTY_AB_TEST_CONFIGURATION: AbTestConfiguration = {
  id: '',
  scopeId: '',
  name: '',
  active: false,
  variants: [],
}
