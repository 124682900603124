import { css } from '@emotion/react'

export const BorderRadius = {
  value: '4px',
  style: css`
    border-radius: 4px;
  `,
}

export const Transitions = {
  quick: '0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  default: '0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95)',
}

export const Sizes = {
  2: '0.125rem',
  4: '0.25rem',
  6: '0.375rem',
  8: '0.5rem',
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  22: '1.375rem',
  24: '1.5rem',
  26: '1.625rem',
  28: '1.75rem',
} as const

/**
 * Raw values for {@link ElevationLevel} `z-index` properties
 */
export const ElevationLevelValues = {
  high: 5,
  semiHigh: 4,
  middle: 3,
  low: 1,
}

/**
 * Applies an "elevation level" in the current stacking context to a component by applying a `z-index` value
 * - `high`: `z-index: 5`, i.e. modals, tooltips, popovers
 * - `middle`: `z-index: 3`, i.e. flyout
 * - `low`: `z-index: 1`, i.e. table headers (content which should be higher on z but still remain at page level)
 */
export const ElevationLevel = {
  high: css`
    z-index: ${ElevationLevelValues.high};
  `,
  semiHigh: css`
    z-index: ${ElevationLevelValues.semiHigh};
  `,
  middle: css`
    z-index: ${ElevationLevelValues.middle};
  `,
  low: css`
    z-index: ${ElevationLevelValues.low};
  `,
}
