import styled from '@emotion/styled'
import { SiteMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo, useState } from 'react'
import { TabProp, Tabs } from '~/components/Tabs'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { TimelineConfiguration, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import {
  adxAuctionFillRate,
  adxImpressionCount,
  allAuctionFillRate,
  allImpressionCount,
  auctionCount,
  hbAuctionFillRate,
  hbBidWinCount,
  hbImpressionCount,
  openBiddingAuctionFillRate,
  openBiddingImpressionCount,
} from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'

export type SiteInventoryTabLabel = 'Event' | 'Rate'

const useConfig = (tab: SiteInventoryTabLabel, data: SiteMetricsByTime) =>
  useMemo((): TimelineConfiguration<SiteMetricsByTime> => {
    if (tab === 'Rate') {
      return {
        getChartOptions: () => ({
          seriesType: 'line',
          series: {},
          vAxis: {
            format: "#'%'",
            maxValue: 100,
          },
        }),
        legendConfig: ['hb', 'adx', 'openBidding'],
        tooltipConfig: [['hb', 'adx', 'openBidding'], ['allAuctionFillRate']],
        dataConfig: [
          {
            name: 'allAuctionFillRate',
            label: allAuctionFillRate.name,
            getFormula: () => allAuctionFillRate,
            isComputable: ({ data }) => allAuctionFillRate.isComputable(data),
            iconName: 'sigma',
          },
          {
            name: 'hb',
            label: hbAuctionFillRate.name,
            getFormula: () => hbAuctionFillRate,
            isComputable: ({ data }) => hbAuctionFillRate.isComputable(data),
            withValue: true,
          },
          {
            name: 'adx',
            label: adxAuctionFillRate.name,
            getFormula: () => adxAuctionFillRate,
            isComputable: ({ data }) => adxAuctionFillRate.isComputable(data),
            withValue: true,
          },
          {
            name: 'openBidding',
            label: openBiddingAuctionFillRate.name,
            getFormula: () => openBiddingAuctionFillRate,
            isComputable: ({ data }) => openBiddingAuctionFillRate.isComputable(data),
            withValue: true,
          },
        ],
      }
    } else {
      const hasAuction = auctionCount.isComputable(data)
      const hasBidWin = hbImpressionCount.isComputable(data) && hbBidWinCount.isComputable(data)
      return {
        getChartOptions: () => ({
          seriesType: 'area',
          series: {
            ...(hasAuction ? { [0]: { type: 'line' } } : {}),
            ...(hasBidWin ? { [hasAuction ? 1 : 0]: { type: 'line', lineDashStyle: [7, 5] } } : {}),
          },
        }),
        legendConfig: ['auction', 'bidWin', 'hb', 'adx', 'openBidding'],
        tooltipConfig: [['auction'], ['bidWin'], ['hb', 'adx', 'openBidding'], ['allImpressionCount']],
        dataConfig: [
          {
            name: 'allImpressionCount',
            label: allImpressionCount.name,
            getFormula: () => allImpressionCount,
            isComputable: ({ data }) => allImpressionCount.isComputable(data),
            iconName: 'sigma',
          },
          {
            name: 'auction',
            label: auctionCount.name,
            getFormula: () => auctionCount,
            isComputable: ({ data }) => auctionCount.isComputable(data),
            iconName: 'data_line',
            withValue: true,
          },
          {
            name: 'bidWin',
            label: hbBidWinCount.name,
            isComputable: ({ data }) => hbImpressionCount.isComputable(data) && hbBidWinCount.isComputable(data),
            getFormula: () => hbBidWinCount,
            iconName: 'data_line_dashed',
            withValue: true,
          },
          {
            name: 'hb',
            label: hbImpressionCount.name,
            isComputable: ({ data }) => hbImpressionCount.isComputable(data) && hbBidWinCount.isComputable(data),
            getFormula: () => hbImpressionCount,
            withValue: true,
          },
          {
            name: 'adx',
            label: adxImpressionCount.name,
            getFormula: () => adxImpressionCount,
            isComputable: ({ data }) => adxImpressionCount.isComputable(data),
            withValue: true,
          },
          {
            name: 'openBidding',
            label: openBiddingImpressionCount.name,
            getFormula: () => openBiddingImpressionCount,
            isComputable: ({ data }) => openBiddingImpressionCount.isComputable(data),
            withValue: true,
          },
        ],
      }
    }
  }, [tab, data])

type PureSiteInventoryChartProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    data: SiteMetricsByTime
    currencySymbol: CurrencySymbol
    onTabChange: (tab: SiteInventoryTabLabel) => void
    timezone: Timezone
    currentEpoch: DateTime
  }
const _PureSiteInventoryChart: FunctionComponent<PureSiteInventoryChartProps> = ({ data, currencySymbol, currentEpoch, timezone, onTabChange, ...props }) => {
  const [tab, setTab] = useState<'Event' | 'Rate'>('Event')
  const tabs = [{ label: 'Event' }, { label: 'Rate' }]

  const onTabClick = (tab: TabProp) => {
    const tabLabel = tab.label as SiteInventoryTabLabel
    setTab(tabLabel)
    onTabChange(tabLabel)
  }
  const currentConfig = useConfig(tab, data)

  const { chart, legends } = useTimelineChart({ currentConfig, data, currencySymbol, currentEpoch, timezone })
  return (
    <AnalyticsChartWidget
      {...props}
      icon={'site'}
      title={'Inventory filling'}
      info={<Tabs fluid={false} tabs={tabs.map((t) => ({ ...t, active: t.label === tab }))} onClick={onTabClick} />}
      chart={chart}
      legends={legends}
    />
  )
}

export const PureSiteInventoryChart = styled(_PureSiteInventoryChart)``
