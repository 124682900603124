import styled from '@emotion/styled'
import { Scope } from '@pubstack/common/src/scope'
import React, { FunctionComponent } from 'react'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Icon } from '~/components/Icon'
import { Modal } from '~/components/Modal'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'

const SecurityContentCard = styled(ContentCard)`
  display: flex;
  flex-direction: column;
  gap: 43px;
`

const SecurityContentCardCategory = styled.div``

const SecurityContentCardTitle = styled.h2`
  ${Fonts.colors.Jet}
  ${Fonts.H2}
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 16px;
`
const SecurityProfileNotification = styled.div``

const SecurityProfileNotificationSettings = styled.div`
  ${Fonts.P1}
  ${Fonts.colors.Jet}
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 16px;
`

const MfaDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const MfaCheckbox = styled.div`
  display: flex;
  gap: 15px;
`

const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
type PureEnableConfirmationModalProps = { onValidate: () => void }
const PureEnableConfirmationModal: React.FunctionComponent<PureEnableConfirmationModalProps> = ({ onValidate }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Enable MFA</Modal.Title>
      <Modal.Body>
        <Paragraphs>
          <p>You are about to activate Multi-Factor Authentication.</p>
          <p>
            <b>All members will be prompted to activate MFA.</b>
          </p>
          <p>
            This action <b>cannot be undone</b> without the help of your Customer Success Manager.
          </p>
        </Paragraphs>
      </Modal.Body>
      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button
          variant={'primary'}
          onClick={() => {
            onValidate()
            close()
          }}
        >
          Validate
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

type PureSettingsSecurityPageProps = WithClassName & { scope: Scope; onActivateMfa: () => unknown }
const _PureSettingsSecurityPage: FunctionComponent<PureSettingsSecurityPageProps> = ({ className, scope, onActivateMfa }) => {
  const modal = useGlobalModal()
  return (
    <SecurityContentCard className={className} color={SETTINGS_NAV_CONFIG['security'].color}>
      <SecurityContentCardCategory>
        <SecurityProfileNotification>
          <SecurityContentCardTitle>
            <Icon name={'notification'} width={'22px'} />
            Multi-Factor Authentication
          </SecurityContentCardTitle>
          <SecurityProfileNotificationSettings>
            <MfaCheckbox>
              <Tooltip title={scope.mfaRequired ? 'Reach out to your Customer Success Manager to disable MFA.' : 'Activate the MFA'}>
                <Toggle onClick={() => modal.open(PureEnableConfirmationModal, { onValidate: onActivateMfa })} value={scope.mfaRequired} disabled={scope.mfaRequired} />
              </Tooltip>
              <span>Activate MFA for all users</span>
            </MfaCheckbox>
            <MfaDescription>
              <p>
                When activated, all members will be prompted to activate Multi-Factor Authentication. Using an authenticator app on your phone is mandatory for the first connection. Once the setup is
                done, you will be free to keep it, or use email as your second authentication method.
              </p>
              <p>Once activated, you won’t be able to disable MFA by yourself.</p>
              <p>
                <a href={'https://help.pubstack.io/multiple-factor-authentication'}>More details here</a>
              </p>
            </MfaDescription>
          </SecurityProfileNotificationSettings>
        </SecurityProfileNotification>
      </SecurityContentCardCategory>
    </SecurityContentCard>
  )
}

export const PureSettingsSecurityPage = styled(_PureSettingsSecurityPage)``
