import { AbTestConfiguration } from '@pubstack/common/src/abTest'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAbTest } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { useToast } from '~/components/Toast/useToasts'
import { PureSettingsAbTestPage } from './PureSettingsAbTestPage'

export const SettingsAbTestPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const [abTests, setAbTests] = useState<AbTestConfiguration[]>([])
  const user = useUser()

  const { all: allAbTests, byId: abTestsById, toggleAbTest } = useAbTest(null)

  const toast = useToast()

  useEffect(() => {
    loadAbTests()
  }, [])

  const loadAbTests = async () => {
    setAbTests(await allAbTests.get())
  }

  const toDelete = async (abTest: AbTestConfiguration) => {
    try {
      await abTestsById.del(abTest.id)
      setAbTests(abTests.filter((r) => r.id !== abTest.id))
      toast.success(
        <>
          <strong>{abTest.name}</strong> has been deleted.
        </>
      )
    } catch (e) {
      toast(`Something went wrong when trying to delete ${abTest.name}`)
    }
  }

  const toggleStatus = async (abTest: AbTestConfiguration) => {
    try {
      const updated = await toggleAbTest(abTest.id)
      toast.success(
        <>
          <strong>{updated.name}</strong> has been {updated.active ? 'enabled' : 'disabled'}.
        </>
      )
      await loadAbTests()
    } catch (e) {
      toast.alert(`Something went wrong when trying to change ${abTest.name}'s status`)
    }
  }

  const onView = async (abTest?: AbTestConfiguration) => {
    if (abTest) {
      navigate(`view/${abTest?.id}`)
    }
  }

  return (
    <PureSettingsAbTestPage
      abTests={abTests}
      isLoading={abTestsById.loading || allAbTests.loading}
      isError={!!allAbTests.error}
      onDeleteAbTest={toDelete}
      onCreateAbTest={() => navigate('new')}
      onViewAbTest={onView}
      onToggleAbTestStatus={toggleStatus}
      canEnableAbTest={!!(user?.getScopeRole() === 'owner' || user?.isAdmin)}
    />
  )
}
