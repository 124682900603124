import { Scope } from '@pubstack/common/src/scope'
import { VoidFunctionComponent } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useUser } from '~/auth/user.hooks'
import { currentScopeState, sidebarOpenState } from '~/state'
import { useLogger } from '~/utils/logger'
import { PureHeader } from './PureHeader'

const Header: VoidFunctionComponent<{ scopes: Scope[] }> = ({ scopes }) => {
  const logger = useLogger()
  const user = useUser()
  const currentScope = useRecoilValue(currentScopeState)
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarOpenState)

  const goToDocs = () => {
    logger.info({ action: 'click', type: 'documentation' })
    window.open('https://help.pubstack.io/', '_blank')
  }

  return (
    <PureHeader
      currentScope={currentScope}
      scopes={scopes}
      user={user}
      onGoToDocsClick={goToDocs}
      toggleSidebarOpen={() => setSidebarOpen({ open: !sidebarOpen.open, pinned: false })}
      isSidebarPinned={sidebarOpen.pinned}
      isSidebarOpen={sidebarOpen.open}
    />
  )
}

export default Header
