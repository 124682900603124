import styled from '@emotion/styled'
import { BidMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo, useState } from 'react'
import type { ChartWrapperOptions } from 'react-google-charts/dist/types'
import { TabProp, Tabs } from '~/components/Tabs'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { TimelineConfiguration, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import { bidCpm, bidRequests, bidRpm, bidTimeouts, bidWinCpm, bidWinRpm, hbECPM, impressionRpm } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { displayWithCurrency } from '~/utils/string'

const useConf = (type: BidderCPMDataType, currencySymbol: CurrencySymbol) => {
  return useMemo((): TimelineConfiguration<BidMetricsByTime> => {
    const chartOptions: ChartWrapperOptions['options'] = {
      ...AnalyticsDefaultChartOptions,
      annotations: { ...AnalyticsDefaultChartOptions.annotations, style: 'point' },
      crosshair: { ...AnalyticsDefaultChartOptions.crosshair, trigger: 'both' },
      vAxis: {
        ...AnalyticsDefaultChartOptions.vAxis,
        gridlines: { count: -1 },
        minorGridlines: { color: 'none' },
        format: displayWithCurrency('#.##', currencySymbol),
        minValue: 0,
      },
    }
    return type === 'CPM'
      ? {
          getChartOptions: () => chartOptions,
          legendConfig: ['hbECPM', 'bidWinCpm', 'bidCpm'],
          tooltipConfig: ['hbECPM', 'bidWinCpm', 'bidCpm'],
          dataConfig: [
            {
              name: 'hbECPM',
              isComputable: ({ data }) => hbECPM.isComputable(data),
              getFormula: () => hbECPM,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_PREBID_ECPM,
              iconName: 'data_line',
              withValue: true,
            },
            {
              name: 'bidWinCpm',
              isComputable: ({ data }) => bidWinCpm.isComputable(data),
              getFormula: () => bidWinCpm,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_WIN_CPM,
              iconName: 'data_line',
              withValue: true,
            },
            {
              name: 'bidCpm',
              isComputable: ({ data }) => bidCpm.isComputable(data),
              getFormula: () => bidCpm,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_CPM,
              iconName: 'data_line',
              withValue: true,
            },
          ],
        }
      : {
          getChartOptions: () => chartOptions,
          legendConfig: ['impressionRpm', 'bidWinRpm', 'bidRpm'],
          tooltipConfig: ['impressionRpm', 'bidWinRpm', 'bidRpm'],
          dataConfig: [
            {
              name: 'impressionRpm',
              isComputable: ({ data }) => impressionRpm.isComputable(data),
              getFormula: () => impressionRpm,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_IMP_RPM,
              iconName: 'data_line',
              withValue: true,
            },
            {
              name: 'bidWinRpm',
              isComputable: ({ data }) => bidWinRpm.isComputable(data),
              getFormula: () => bidWinRpm,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_WIN_RPM,
              iconName: 'data_line',
              withValue: true,
            },
            {
              name: 'bidRpm',
              isComputable: ({ data }) => bidRpm.isComputable(data),
              getFormula: () => bidRpm,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_RPM,
              iconName: 'data_line',
              withValue: true,
            },
          ],
        }
  }, [type])
}

export type BidderCPMDataType = 'CPM' | 'RPM'
const dataTypes: { type: BidderCPMDataType; label: string }[] = [
  { type: 'CPM', label: 'CPM' },
  { type: 'RPM', label: 'RPM' },
]

type PureBidderWidgetCPMProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon' | 'info'> & {
    data: BidMetricsByTime
    currentEpoch: DateTime
    currencySymbol: CurrencySymbol
    onTabChange?: (type: BidderCPMDataType) => void
    timezone: Timezone
  }
const _PureBidderWidgetCPM: FunctionComponent<PureBidderWidgetCPMProps> = ({ onTabChange, data, currencySymbol, timezone, currentEpoch, ...props }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const tabs: TabProp[] = dataTypes.map((type, index) => ({ label: type.label, active: currentTab === index }))
  const allComputable = data && bidRequests.isComputable(data) && bidTimeouts.isComputable(data)
  const showTabs = !props.empty && allComputable
  const onTabClick = (tab: TabProp) => {
    const index = tabs.indexOf(tab)
    setCurrentTab(index)
    onTabChange && onTabChange(dataTypes[index].type)
  }

  const type = dataTypes[currentTab].type
  const currentConfig = useConf(type, currencySymbol)

  const { chart, legends } = useTimelineChart({ currentConfig, data, currencySymbol, currentEpoch, timezone })

  return (
    <AnalyticsChartWidget
      {...props}
      icon={'bidder'}
      title={`${type} Breakdown`}
      info={showTabs && <Tabs tabs={tabs} fluid={false} onClick={onTabClick} />}
      smallChart={true}
      chart={chart}
      legends={legends}
    />
  )
}
export const PureBidderWidgetCPM = styled(_PureBidderWidgetCPM)``
