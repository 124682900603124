import { FunctionComponent } from 'react'
import { useOutletContext } from 'react-router-dom'
import { SitePageContext } from '~/modules/adstack/inventory/sites/SitePage'
import { PureSiteExtraScript } from '~/modules/adstack/inventory/sites/extra-scripts/PureSiteExtraScript'
import { WithClassName } from '~/types/utils'

type SiteExtraScriptProps = WithClassName & {}

export const SiteExtraScript: FunctionComponent<SiteExtraScriptProps> = () => {
  const { onExtraScriptCreate, onExtraScriptUpdate, extraScripts, isLoading } = useOutletContext<SitePageContext>()
  return <PureSiteExtraScript onExtraScriptCreate={onExtraScriptCreate} onExtraScriptUpdate={onExtraScriptUpdate} extraScripts={extraScripts} isLoading={isLoading} />
}
