import styled from '@emotion/styled'
import { emailValidation } from '@pubstack/common/src/input'
import { ScopeUser } from '@pubstack/common/src/user'
import { FunctionComponent } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'
import { USER_ROLE_OPTIONS } from './SettingsMembersPage'

const ModalActions = styled.div`
  display: flex;
  gap: 24px;
`

const AddMemberForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

type PureSettingsMembersPageAddFlyoutProps = WithClassName & { isOpen: boolean; onRequestClose: () => unknown; onAdd: (member: ScopeUser) => unknown; validateMember: (email: string) => boolean }
const _PureSettingsMembersPageAddFlyout: FunctionComponent<PureSettingsMembersPageAddFlyoutProps> = ({ isOpen, onRequestClose, onAdd, validateMember }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<Omit<ScopeUser, 'nickname'>>({
    defaultValues: {
      email: '',
      role: 'user',
    },
    mode: 'onChange',
  })

  const close = () => {
    clearErrors(['role', 'email'])
    reset({
      email: '',
      role: 'user',
    })
    onRequestClose()
  }

  const onSubmit = (member: Omit<ScopeUser, 'nickname'>) => {
    onAdd({ ...member, nickname: '' })
    close()
  }

  const getErrorMessage = (errors: FieldErrors<Omit<ScopeUser, 'nickname'>>) => {
    if (errors.email?.type === 'required') {
      return 'E-mail is required'
    }
    if (errors.email?.type === 'pattern') {
      return 'Please enter a valid e-mail address'
    }
    if (errors.email?.type === 'validate') {
      return 'This e-mail is already in use. Please choose another.'
    }
  }

  return (
    <Flyout
      color={SETTINGS_NAV_CONFIG.members.color}
      title={'Add member'}
      isOpen={isOpen}
      onClose={close}
      actions={
        <ModalActions>
          <Button onClick={close} variant={'tertiary'}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>Add member</Button>
        </ModalActions>
      }
    >
      <AddMemberForm>
        <Input error={getErrorMessage(errors)} rules={{ required: true, pattern: emailValidation, validate: validateMember }} control={control} name={'email'} label={'E-mail address'} />
        <Select rules={{ required: true }} label={'Role'} options={USER_ROLE_OPTIONS} control={control} name={'role'} />
      </AddMemberForm>
    </Flyout>
  )
}

export const PureSettingsMembersPageAddFlyout = styled(_PureSettingsMembersPageAddFlyout)``
