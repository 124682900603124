import { Dimension } from './dimension'

export type AlertingQuery = 'check.backlog'
export type AllQuery = AllAnalyticsQuery | AlertingQuery

export interface AnalyticsQueryContext {
  from: string
  to: string
  tz: string
  filters: AnalyticsQueryFilter[]
}

export type AnalyticsQueryDashboardName =
  | 'viewability'
  | 'bidders'
  | 'bidder'
  | 'sites'
  | 'overview'
  | 'refresh'
  | 'user-session'
  | 'gam-overview-web'
  | 'gam-overview-amp'
  | 'gam-overview-app'
  | 'explore'
export type AnalyticsQueryDashboard = {
  name: AnalyticsQueryDashboardName
  filterType: 'auctions' | 'revenues'
}
export interface AnalyticsQueryArgs {
  dashboard?: AnalyticsQueryDashboard
  dimension?: string
  inventoryType?: string
  tagId?: string
  bidderName?: string
}
export interface AnalyticsQuery {
  context: AnalyticsQueryContext
  query: AllQuery
  args?: AnalyticsQueryArgs
}
export interface AnalyticsQueryFilter {
  dimension: Dimension
  excluded: boolean
  values: string[]
}

export interface AnalyticsResponse<T> {
  values?: T
  mappings?: { [key: string]: DimensionMapping }
  dimensions?: string[]
  size?: number
  error?: string
  took?: number
}

export interface DimensionMapping {
  [key: string]: string
}

export interface MappedName {
  label: string
  sublabel?: string
  value: string
}

export interface AllValuesWithSize {
  sites: Array<{ id: string; name: string }>
  adunits: Array<{ id: string; name: string }>
  bidders: Array<string>
}

export const BidderQueries = ['bid.metrics.by.bidder', 'bid.metrics.by.dim', 'bid.metrics.by.time'] as const
export const GenericQueries = ['top.values.by.dim', 'all.values.with.size'] as const
export const OverviewQueries = ['top.revenues.by.dim', 'overview.metrics.by.time'] as const
export const ViewabilityQueries = ['viewability.metrics.by.time', 'viewability.metrics.by.dim'] as const
export const SiteQueries = ['site.metrics.by.tagid', 'site.metrics.by.dim', 'site.metrics.by.time'] as const
export const RefreshQueries = ['refresh.metrics.by.time', 'refresh.metrics.by.rank', 'refresh.metrics.by.dim'] as const
export const UserSessionQueries = ['user-session.metrics.by.time', 'user-session.metrics.by.dim'] as const
export const GamOverviewQueries = ['gam-overview.metrics.by.time'] as const
export const ExploreQueries = ['explore.metrics.by.time', 'explore.metrics.by.dim'] as const
export const AllAnalyticsQueries = [
  ...BidderQueries,
  ...GenericQueries,
  ...OverviewQueries,
  ...SiteQueries,
  ...ViewabilityQueries,
  ...RefreshQueries,
  ...UserSessionQueries,
  ...GamOverviewQueries,
  ...ExploreQueries,
] as const

export type GenericQuery = (typeof GenericQueries)[number]
export type BidderQuery = (typeof BidderQueries)[number]
export type OverviewQuery = (typeof OverviewQueries)[number]
export type ViewabilityQuery = (typeof ViewabilityQueries)[number]
export type SiteQuery = (typeof SiteQueries)[number]
export type RefreshQuery = (typeof RefreshQueries)[number]
export type UserSessionQuery = (typeof UserSessionQueries)[number]
export type GamOverviewQuery = (typeof GamOverviewQueries)[number]
export type ExploreQuery = (typeof ExploreQueries)[number]
export type AllAnalyticsQuery = (typeof AllAnalyticsQueries)[number]

export const isBidderQuery = (queryName: string): queryName is BidderQuery => {
  return BidderQueries.includes(queryName as BidderQuery)
}
export const isGenericQuery = (queryName: string): queryName is GenericQuery => {
  return GenericQueries.includes(queryName as GenericQuery)
}
export const isOverviewQuery = (queryName: string): queryName is OverviewQuery => {
  return OverviewQueries.includes(queryName as OverviewQuery)
}
export const isSiteQuery = (queryName: string): queryName is SiteQuery => {
  return SiteQueries.includes(queryName as SiteQuery)
}
export const isViewabilityQuery = (queryName: string): queryName is ViewabilityQuery => {
  return ViewabilityQueries.includes(queryName as ViewabilityQuery)
}
export const isRefreshQuery = (queryName: string): queryName is RefreshQuery => {
  return RefreshQueries.includes(queryName as RefreshQuery)
}
export const isUserSessionQuery = (queryName: string): queryName is UserSessionQuery => {
  return UserSessionQueries.includes(queryName as UserSessionQuery)
}
export const isGamOverviewQuery = (queryName: string): queryName is GamOverviewQuery => {
  return GamOverviewQueries.includes(queryName as GamOverviewQuery)
}
export const isExploreQuery = (queryName: string): queryName is ExploreQuery => {
  return ExploreQueries.includes(queryName as ExploreQuery)
}
export type QueryName = BidderQuery | GenericQuery | OverviewQuery | SiteQuery | ViewabilityQuery | RefreshQuery | UserSessionQuery | GamOverviewQuery | ExploreQuery

export type ISOReadableTime = string

type MetricsObjArray = { [key: string]: number }[]

export type AllMetrics = {
  bidRequestCount: (number | null)[]
  bidResponseCount: (number | null)[]
  bidResponseCpmSum: (number | null)[]
  bidTimeoutCount: (number | null)[]
  bidWinCount: (number | null)[]
  bidCpmSum: (number | null)[]
  bidWinCpmSum: (number | null)[]
  bidBidderCount: (number | null)[]
  bidAdUnitCount: (number | null)[]
  bidTagIdCount: (number | null)[]
  impressionCpmSum: (number | null)[]
  adxImpressionCpmSum: (number | null)[]
  // TODO 2020-01-21 : remove adxAuctionCount because it doesn't make sens for now
  adxAuctionCount: (number | null)[]
  adxImpressionCount: (number | null)[]
  openBiddingImpressionCount: (number | null)[]
  impressionCount: (number | null)[]
  auctionCount: (number | null)[]
  hbAuctionCount: (number | null)[]
  measurableImpressionCount: (number | null)[]
  adxMeasurableImpressionCount: (number | null)[]
  mrcViewableImpressionCount: (number | null)[]
  adxMrcViewableImpressionCount: (number | null)[]
  adxMeasuredImpressionCount: (number | null)[]
  adxMeasuredImpressionViewedTimeSum: (number | null)[]
  measuredImpressionViewedTimeSum: (number | null)[]
  measuredImpressionCount: (number | null)[]
  openBiddingImpressionCpmSum: (number | null)[]
  refreshImpressionCount: (number | null)[]
  firstCallImpressionCount: (number | null)[]
  refreshImpressionCpmSum: (number | null)[]
  firstCallImpressionCpmSum: (number | null)[]
  refreshAuctionCount: (number | null)[]
  firstCallAuctionCount: (number | null)[]
  firstCallMeasurableImpressionCount: (number | null)[]
  refreshMeasurableImpressionCount: (number | null)[]
  firstCallMeasuredImpressionViewedTimeSum: (number | null)[]
  refreshMeasuredImpressionViewedTimeSum: (number | null)[]
  firstCallMeasuredImpressionCount: (number | null)[]
  refreshMeasuredImpressionCount: (number | null)[]
  firstCallMrcViewableImpressionCount: (number | null)[]
  refreshMrcViewableImpressionCount: (number | null)[]
  videoViewershipComplete: (number | null)[]
  videoErrorCount: (number | null)[]
  videoStart: (number | null)[]
  videoFirstQuartile: (number | null)[]
  videoMidpoint: (number | null)[]
  videoThirdQuartile: (number | null)[]
  sessionCount: (number | null)[]
  pageCount: (number | null)[]
  total: TotalMetric
}

export type AllMetricsObj = {
  [key in keyof Omit<AllMetrics, 'total'>]: MetricsObjArray
}

export type TotalMetric = {
  [key in keyof Omit<AllMetrics, 'total'>]: number | null
}

export interface GenericTopValuesByDim extends Partial<Pick<AllMetrics, 'auctionCount' | 'impressionCpmSum'>> {
  name: MappedName[]
}

export interface UniqueCountByDim extends Partial<Pick<AllMetrics, 'bidBidderCount' | 'bidAdUnitCount' | 'bidTagIdCount'>> {
  name: string[]
}

export interface BidMetricsByDim
  extends Partial<Pick<AllMetrics, 'bidRequestCount' | 'bidTimeoutCount'>>,
    Pick<AllMetrics, 'bidWinCount' | 'bidResponseCount' | 'bidCpmSum' | 'impressionCpmSum' | 'impressionCount'> {
  name: MappedName[]
}

export interface BidMetricsByTime
  extends Partial<
    Pick<AllMetrics, 'bidRequestCount' | 'bidTimeoutCount' | 'bidResponseCount' | 'bidCpmSum' | 'bidWinCpmSum' | 'impressionCpmSum' | 'bidWinCount' | 'impressionCount' | 'bidResponseCpmSum'>
  > {
  epoch: ISOReadableTime[]
}

export interface SiteMetricsByTime
  extends Partial<
    Pick<
      AllMetrics,
      | 'auctionCount'
      | 'hbAuctionCount'
      | 'adxImpressionCpmSum'
      | 'openBiddingImpressionCpmSum'
      | 'impressionCpmSum'
      | 'bidWinCpmSum'
      | 'impressionCount'
      | 'adxImpressionCount'
      | 'openBiddingImpressionCount'
      | 'bidResponseCount'
      | 'bidWinCount'
    >
  > {
  epoch: ISOReadableTime[]
}

export interface SiteMetricsByTagId
  extends Partial<
    Pick<
      AllMetrics,
      | 'auctionCount'
      | 'hbAuctionCount'
      | 'bidResponseCount'
      | 'adxImpressionCpmSum'
      | 'adxImpressionCount'
      | 'openBiddingImpressionCount'
      | 'openBiddingImpressionCpmSum'
      | 'impressionCpmSum'
      | 'impressionCount'
      | 'bidBidderCount'
      | 'bidAdUnitCount'
    >
  > {
  name: MappedName[]
}

export interface SiteMetricsByDim
  extends Partial<
    Pick<
      AllMetrics,
      | 'auctionCount'
      | 'hbAuctionCount'
      | 'bidResponseCount'
      | 'impressionCpmSum'
      | 'adxImpressionCpmSum'
      | 'openBiddingImpressionCpmSum'
      | 'impressionCount'
      | 'adxImpressionCount'
      | 'openBiddingImpressionCount'
    >
  > {
  name: MappedName[]
}

export interface BidMetricsByBidder
  extends Partial<Pick<AllMetrics, 'bidRequestCount' | 'bidTimeoutCount'>>,
    Pick<AllMetrics, 'bidWinCount' | 'bidResponseCount' | 'bidCpmSum' | 'impressionCpmSum' | 'bidAdUnitCount' | 'bidTagIdCount'> {
  name: MappedName[]
}

export interface OverviewMetricsByTime
  extends Partial<
    Pick<AllMetrics, 'auctionCount' | 'adxImpressionCount' | 'adxImpressionCpmSum' | 'openBiddingImpressionCount' | 'openBiddingImpressionCpmSum' | 'impressionCount' | 'impressionCpmSum'>
  > {
  epoch: ISOReadableTime[]
}

export interface TopRevenuesByDim extends Partial<Pick<AllMetrics, 'impressionCpmSum' | 'adxImpressionCpmSum' | 'openBiddingImpressionCpmSum'>> {
  name: MappedName[]
  total: Partial<Pick<TotalMetric, keyof Omit<TopRevenuesByDim, 'name' | 'total'>>>
}

export interface ViewabilityMetricsByTime
  extends Partial<
      Pick<
        AllMetrics,
        | 'mrcViewableImpressionCount'
        | 'adxMrcViewableImpressionCount'
        | 'adxMeasuredImpressionCount'
        | 'adxMeasuredImpressionViewedTimeSum'
        | 'measurableImpressionCount'
        | 'impressionCount'
        | 'adxImpressionCount'
        | 'impressionCpmSum'
        | 'adxImpressionCpmSum'
        | 'adxMeasurableImpressionCount'
        | 'measuredImpressionViewedTimeSum'
        | 'measuredImpressionCount'
      >
    >,
    Partial<Pick<AllMetrics, 'auctionCount'>> {
  epoch: ISOReadableTime[]
}

export interface ViewabilityMetricsByDim
  extends Partial<
    Pick<
      AllMetrics,
      | 'mrcViewableImpressionCount'
      | 'measurableImpressionCount'
      | 'adxMrcViewableImpressionCount'
      | 'adxMeasuredImpressionCount'
      | 'adxImpressionCpmSum'
      | 'adxMeasurableImpressionCount'
      | 'impressionCount'
      | 'adxImpressionCount'
      | 'impressionCpmSum'
      | 'bidResponseCount'
      | 'bidWinCount'
      | 'bidRequestCount'
      | 'bidCpmSum'
      | 'measuredImpressionViewedTimeSum'
      | 'measuredImpressionCount'
      | 'auctionCount'
      | 'hbAuctionCount'
      | 'adxMeasuredImpressionViewedTimeSum'
    >
  > {
  name: MappedName[]
}

export interface RefreshMetricsByTime
  extends Partial<
    Pick<
      AllMetrics,
      | 'refreshAuctionCount'
      | 'firstCallAuctionCount'
      | 'firstCallImpressionCount'
      | 'refreshImpressionCount'
      | 'firstCallImpressionCpmSum'
      | 'refreshImpressionCpmSum'
      | 'firstCallMrcViewableImpressionCount'
      | 'refreshMrcViewableImpressionCount'
      | 'firstCallMeasuredImpressionCount'
      | 'refreshMeasuredImpressionCount'
      | 'firstCallMeasurableImpressionCount'
      | 'refreshMeasurableImpressionCount'
      | 'firstCallMeasuredImpressionViewedTimeSum'
      | 'refreshMeasuredImpressionViewedTimeSum'
    >
  > {
  epoch: ISOReadableTime[]
}

export interface RefreshMetricsByDim
  extends Partial<
    Pick<
      AllMetrics,
      | 'refreshAuctionCount'
      | 'firstCallAuctionCount'
      | 'firstCallImpressionCount'
      | 'refreshImpressionCount'
      | 'firstCallImpressionCpmSum'
      | 'refreshImpressionCpmSum'
      | 'firstCallMrcViewableImpressionCount'
      | 'refreshMrcViewableImpressionCount'
      | 'firstCallMeasuredImpressionCount'
      | 'refreshMeasuredImpressionCount'
      | 'firstCallMeasurableImpressionCount'
      | 'refreshMeasurableImpressionCount'
      | 'firstCallMeasuredImpressionViewedTimeSum'
      | 'refreshMeasuredImpressionViewedTimeSum'
    >
  > {
  name: MappedName[]
}

export interface RefreshMetricsByRank
  extends Partial<
    Pick<
      AllMetrics,
      'auctionCount' | 'impressionCount' | 'impressionCpmSum' | 'mrcViewableImpressionCount' | 'measuredImpressionCount' | 'measurableImpressionCount' | 'measuredImpressionViewedTimeSum'
    >
  > {
  name: MappedName[]
}

export interface UserSessionMetricsByTime extends Partial<Pick<AllMetrics, 'impressionCount' | 'impressionCpmSum' | 'auctionCount' | 'pageCount' | 'sessionCount'>> {
  epoch: ISOReadableTime[]
}

export interface UserSessionMetricsByDim extends Partial<Pick<AllMetrics, 'impressionCount' | 'impressionCpmSum' | 'pageCount' | 'sessionCount'>> {
  name: MappedName[]
}

export type ImpressionCpmSumInTopRevenuesByDim = Required<Pick<TopRevenuesByDim, 'impressionCpmSum'>> & Pick<TopRevenuesByDim, 'name' | 'total' | 'adxImpressionCpmSum' | 'openBiddingImpressionCpmSum'>
export function isImpressionCpmSumPresentInTopRevenuesByDim(value: TopRevenuesByDim): value is ImpressionCpmSumInTopRevenuesByDim {
  return value.impressionCpmSum !== null && value.impressionCpmSum !== undefined && value.impressionCpmSum.length > 0
}

export type AdxImpressionCpmSumInTopRevenuesByDim = Required<Pick<TopRevenuesByDim, 'adxImpressionCpmSum'>> &
  Pick<TopRevenuesByDim, 'name' | 'total' | 'impressionCpmSum' | 'openBiddingImpressionCpmSum'>
export function isAdxImpressionCpmSumPresentInTopRevenuesByDim(value: TopRevenuesByDim): value is AdxImpressionCpmSumInTopRevenuesByDim {
  return value.adxImpressionCpmSum !== null && value.adxImpressionCpmSum !== undefined && value.adxImpressionCpmSum.length > 0
}

export type OpenBiddingImpressionCpmSumInTopRevenuesByDim = Required<Pick<TopRevenuesByDim, 'openBiddingImpressionCpmSum'>> &
  Pick<TopRevenuesByDim, 'name' | 'total' | 'impressionCpmSum' | 'openBiddingImpressionCpmSum'>

export function isOpenBiddingImpressionCpmSumPresentInTopRevenuesByDim(value: TopRevenuesByDim): value is OpenBiddingImpressionCpmSumInTopRevenuesByDim {
  return value.openBiddingImpressionCpmSum !== null && value.openBiddingImpressionCpmSum !== undefined && value.openBiddingImpressionCpmSum.length > 0
}

export interface GamOverviewMetricsByTime
  extends Pick<
    AllMetricsObj,
    | 'impressionCount'
    | 'impressionCpmSum'
    | 'measurableImpressionCount'
    | 'mrcViewableImpressionCount'
    | 'videoViewershipComplete'
    | 'videoErrorCount'
    | 'videoStart'
    | 'videoFirstQuartile'
    | 'videoMidpoint'
    | 'videoThirdQuartile'
  > {
  epoch: ISOReadableTime[]
  mappings: { [key: string]: string }
}

type ExploreValueData<K extends string> = {
  [key in K]?: number
}

export type ExploreDataObj<METRIC extends string> = {
  mappings: Record<string, MappedName>
} & {
  [key in METRIC]: ExploreValueData<string>[]
}

export type ExploreTimelineData<METRIC extends string> = {
  epoch: ISOReadableTime[]
} & ExploreDataObj<METRIC>

export type ExploreDimData<METRIC extends string = string> = ExploreDataObj<METRIC>

// TODO 2022-09-02 NRA VMA not need to be common. Think about where move it, and do something same & clean for countries and devices
export type DemandChannel = 'Prebid' | 'Google demand' | 'Open Bidding' | 'TAM' | 'Guaranteed campaign' | 'Non Guaranteed campaign' | 'Filler' | 'Deals' | 'Other'
export const DemandChannelLabel: { [key in DemandChannel]: { label: string } } = {
  'Google demand': { label: 'Google demand' },
  'Guaranteed campaign': { label: 'Guaranteed campaigns' },
  TAM: { label: 'Amazon TAM' },
  Deals: { label: 'Preferred deals' },
  Filler: { label: 'Filler campaigns' },
  'Non Guaranteed campaign': { label: 'Non guaranteed campaigns' },
  Prebid: { label: 'Prebid' },
  'Open Bidding': { label: 'Open Bidding' },
  Other: { label: 'N/A' },
}

export const GAM_DASHBOARD_OTHERS = 'Others'
export const GAM_DASHBOARD_UNMAPPED_SITE = 'Unmapped Site'
export const GAM_DASHBOARD_UNMAPPED_ADUNIT = 'Unmapped Adunit'
export const GAM_DASHBOARD_NOT_APPLICABLE_AD_UNIT_LEVEL = ''
export const GAM_DASHBOARD_NOT_APPLICABLE_AB_TEST = 'N/A'
