import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { TabProp, Tabs } from '~/components/Tabs'
import { WithClassName } from '~/types/utils'

const PageWrapper = styled.div`
  padding: 20px;
  margin-bottom: 20px;
`

const Label = styled.div`
  color: ${Colors.SlateGrey};
  ${Fonts.P2};
  padding-bottom: 20px;
`

type PureAdStackCatalogTabsProps = WithClassName & {
  breadcrumbs: ReactNode
  onTabClick: (tab: TabProp) => unknown
  tabs: TabProp[]
  children?: ReactNode | ReactNode[]
}
const _PureAdStackCatalogTabs: FunctionComponent<PureAdStackCatalogTabsProps> = ({ breadcrumbs, onTabClick, tabs, children }) => {
  return (
    <PageWrapper>
      {breadcrumbs}
      <Label>Manage the modules connected to your inventory</Label>
      <Tabs tabs={tabs} onClick={onTabClick} />
      <div>{children}</div>
    </PageWrapper>
  )
}

export const PureAdStackCatalogTabs = styled(_PureAdStackCatalogTabs)``
