import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'
import { usePortal } from './layout/usePortal'

export const BANNER_PORTAL_ID = 'banner-portal'

const Wrapper = styled.div<{ haveMessage: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ haveMessage }) => (haveMessage ? 'space-between' : 'flex-end')};
  padding: 20px 224px;
  background: ${Colors.White};
  box-shadow: 0 0 20px rgba(54, 54, 54, 0.3);
  ${Fonts.P1};
`

const Actions = styled.div`
  display: flex;
  gap: 20px;
`

export type PureSaveBannerProps = WithClassName & {
  message?: string
  actions: ReactNode
  isOpen: boolean
}
const _Banner: FunctionComponent<PureSaveBannerProps> = ({ className, message, actions, isOpen }) => {
  const portal = usePortal(BANNER_PORTAL_ID)
  return portal(
    isOpen && (
      <Wrapper className={className} haveMessage={!!message}>
        {message}
        <Actions>{actions}</Actions>
      </Wrapper>
    )
  )
}

export const Banner = styled(_Banner)``
