import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { UserSessionMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useAnalyticsQuery } from '~/api/api.hook'
import { displayTimeshift } from '~/modules/analytics/PureAnalyticsPage'
import { Context } from '~/state'
import { WithClassName } from '~/types/utils'
import { useLogger } from '~/utils/logger'
import { PureUserSessionTimeline } from './PureUserSessionTimeline'

type UserSessionTimelineProps = WithClassName & { context: Context; currencySymbol: CurrencySymbol }
const _UserSessionTimeline: FunctionComponent<UserSessionTimelineProps> = ({ className, context, currencySymbol }) => {
  const logger = useLogger()
  const dimensions: Dimension[] = ['tagId', 'device', 'country']
  const [timeData, setTimeData] = useState<UserSessionMetricsByTime | undefined>(undefined)
  const [timeComparisonData, setTimeComparisonData] = useState<UserSessionMetricsByTime | undefined>(undefined)

  const { byId: analyticsQueryTime } = useAnalyticsQuery<UserSessionMetricsByTime>(null, dimensions, context)

  const { byId: analyticsQueryTimeComparison } = useAnalyticsQuery<UserSessionMetricsByTime>(null, dimensions, {
    ...context,
    timeRange: {
      ...context.timeRange,
      from: context.timeRange.from.minus({ weeks: 1 }),
      to: context.timeRange.to.minus({ weeks: 1 }),
    },
  })

  const loadTimeLineData = async () => {
    if (analyticsQueryTime.loading) {
      analyticsQueryTime.abort()
    }
    const d = await analyticsQueryTime.post('user-session.metrics.by.time', {})
    setTimeData(d.values)
  }

  const loadTimeLineComparisonData = async () => {
    if (displayTimeshift(context.timeRange.from, context.timeRange.to)) {
      if (analyticsQueryTimeComparison.loading) {
        analyticsQueryTimeComparison.abort()
      }
      const d = await analyticsQueryTimeComparison.post('user-session.metrics.by.time', {})
      setTimeComparisonData(d.values)
    } else {
      setTimeComparisonData(undefined)
    }
  }

  const loadTimeLineAllData = () => {
    loadTimeLineData()
    loadTimeLineComparisonData()
  }

  const onMetricChange = (metric: string) => {
    logger.info({ type: 'graph-action', action: 'click', graphName: 'user-session-timeline', detail: metric })
  }

  useEffect(() => {
    setTimeout(() => {
      loadTimeLineAllData()
    })
  }, [context])

  return (
    <PureUserSessionTimeline
      className={className}
      isLoading={analyticsQueryTime.loading || analyticsQueryTimeComparison.loading}
      error={!!analyticsQueryTime.error || !!analyticsQueryTimeComparison.error}
      empty={!analyticsQueryTime.loading && !analyticsQueryTimeComparison.loading && !timeData?.epoch.length && !timeComparisonData?.epoch.length}
      onRefreshClick={loadTimeLineAllData}
      data={timeData ?? { epoch: [] }}
      comparisonData={timeComparisonData ?? { epoch: [] }}
      currencySymbol={currencySymbol}
      onMetricChange={onMetricChange}
      currentEpoch={DateTime.now()}
      timezone={context.timeRange.tz}
    />
  )
}

export const UserSessionTimeline = styled(_UserSessionTimeline)``
