import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import Button from '~/components/Button'
import { WithClassName } from '~/types/utils'

const SettingsPageWrapper = styled.div`
  padding: 20px;
`
const SettingsPageHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
const SettingsPageContent = styled.div``

type PureSettingsPageProps = WithClassName & { breadcrumbs: ReactNode; hideNavigateBack?: boolean; onNavigateBack: () => void }
const _PureSettingsPage: FunctionComponent<PropsWithChildren<PureSettingsPageProps>> = ({ className, children, breadcrumbs, hideNavigateBack, onNavigateBack }) => {
  return (
    <SettingsPageWrapper className={className}>
      <SettingsPageHeader>
        {breadcrumbs}
        {!hideNavigateBack && (
          <Button onClick={onNavigateBack} variant={'tertiary'}>
            Back
          </Button>
        )}
      </SettingsPageHeader>
      <SettingsPageContent>{children}</SettingsPageContent>
    </SettingsPageWrapper>
  )
}

export const PureSettingsPage = styled(_PureSettingsPage)``
