import { useLayoutEffect } from 'react'
import { useStep } from 'usehooks-ts'

export function useCustomStep(maxStep: number) {
  const step = useStep(maxStep)
  useLayoutEffect(() => {
    document.getElementById('PureLayoutContent')?.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [step[0]])
  return step
}
