import { FunctionComponent, useEffect } from 'react'
import { ToastBar, ToastType, Toaster, toast } from 'react-hot-toast'
import { IconName } from '~/components/Icon'
import { Toast } from './Toast'
import { ToastType as PbstckToastType } from './ToastConfig'

export const ToastWrapper: FunctionComponent = () => {
  // different wording between hot-toast and design system
  const toastTypes: { [key in ToastType | 'warning']: PbstckToastType } = {
    success: 'success',
    error: 'alert',
    blank: 'neutral',
    loading: 'neutral',
    custom: 'neutral',
    warning: 'warning',
  }
  return (
    <Toaster
      gutter={16}
      containerStyle={{
        position: 'relative',
      }}
      toastOptions={{
        style: {
          padding: 0,
          background: 'transparent',
          maxWidth: '600px',
          boxShadow: 'none',
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {() => {
            useEffect(() => {
              //overriding "pause on hover" behaviour at the product designer's demand
              if (t.duration && t.duration < Infinity) {
                const autoClose = setTimeout(() => {
                  toast.dismiss(t.id)
                }, t.duration)
                return () => clearTimeout(autoClose)
              }
            }, [])

            return (
              <Toast
                duration={t.duration}
                type={toastTypes[t.type]}
                icon={t.icon as IconName}
                // react-hot-toast expects a value or function
                // if it is a function it should receive the toast as parameter
                // although our message should never be function because we always call it in useToast with a component
                message={typeof t.message === 'function' ? t.message(t) : t.message}
                closeHandler={() => {
                  toast.dismiss(t.id)
                }}
              />
            )
          }}
        </ToastBar>
      )}
    </Toaster>
  )
}
