/**
 * Used to merge local (internal) ref with a forwadedRef, to support both in a low level component
 * also takes care of compatibility between object ref and function ref
 * @param refs - an array containing your local and forwarded ref
 *
 * @example
 * usage :
 * ```
 * const ExampleComp = (props: ExampleProps, ref: ForwardedRef<HTMLElement>) => {
 *    const localRef = React.useRef<HTMLElement>(null)
 *    return <MyComponent ref={mergeRefs([localRef, forwardedRef])} />
 * }
 * ```
 */
export default function mergeRefs<T = unknown>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref) {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}
