import styled from '@emotion/styled'
import { ClientSideDelivery } from '@pubstack/common/src/clientSideDelivery'
import { DateTime } from 'luxon'
import { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import Table, { Column, EmptyTable } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  margin: 20px;
`

const NewConfigForm = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const H3WithMargin = styled.h3`
  margin-top: 10px;
`

const TableConfigs: FunctionComponent<{ configs: ClientSideDelivery[] }> = ({ configs }) => {
  return configs.length > 0 ? (
    configs
      .sort((a, b) => b.timestamp - a.timestamp)
      .map((c) => (
        <TableRow key={c.timestamp}>
          <TableCell>{DateTime.fromSeconds(c.timestamp).toFormat('yyyy-LL-dd HH:mm:ss')}</TableCell>
          <TableCell>{c.mainName}</TableCell>
          <TableCell>{c.abTest?.newName ?? ''}</TableCell>
          <TableCell>{c.abTest?.ratio ?? 0}%</TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow>
      <TableCell colspan={5}>
        <EmptyTable>
          <div>
            <p>No history for this component</p>
          </div>
        </EmptyTable>
      </TableCell>
    </TableRow>
  )
}

const COMPONENTS: SelectOptionProp[] = [
  { value: 'collector', label: 'Collector' },
  { value: 'index-monitoring', label: 'Monitoring' },
  { value: 'user-sessions', label: 'User Sessions' },
  { value: 'kleanads', label: 'Kleanads.js' },
]
export type ConfigFormValues = { mainScript: string; newScript: string; ratio: number; currentComponent: string }
type PureAdminDeliveryProps = WithClassName & {
  currentComponent: string
  currentConfig?: ClientSideDelivery
  mainScripts: string[]
  newScripts: string[]
  createConfig: (mainScript: string, newScript: string, ratio: number) => unknown
  onRefreshClick: () => unknown
  onComponentChange: (newComponent: SelectOptionProp) => unknown
  history?: ClientSideDelivery[]
}
const _PureAdminDelivery: FunctionComponent<PureAdminDeliveryProps> = ({
  className,
  mainScripts,
  newScripts,
  currentConfig,
  createConfig,
  onRefreshClick,
  onComponentChange,
  currentComponent,
  history,
}) => {
  const { control, handleSubmit } = useForm<ConfigFormValues>({
    defaultValues: {
      currentComponent: currentComponent,
      mainScript: '',
      newScript: '',
      ratio: 0,
    },
  })
  const onSubmit = (formValues: ConfigFormValues) => {
    createConfig(formValues.mainScript, formValues.newScript, formValues.ratio)
  }
  const mainScriptsOptions: SelectOptionProp[] = mainScripts.map((s) => ({ value: s, label: s }))
  const newScriptsOptions: SelectOptionProp[] = newScripts.map((s) => ({ value: s, label: s.length ? s : 'N/A' }))

  const COLUMNS: Column[] = [
    {
      name: 'Creation date',
      isSortable: false,
    },
    {
      name: 'Main tag',
      isSortable: false,
    },
    {
      name: 'New tag',
      isSortable: false,
    },
    {
      name: 'Ratio',
      isSortable: false,
    },
  ]

  return (
    <Wrapper className={className}>
      <NewConfigForm>
        <p>Create a new delivery config with A/B test (optional)</p>
        <Select control={control} name={'currentComponent'} label={'Component'} options={COMPONENTS} onChange={onComponentChange} />
        {currentComponent && (
          <>
            <Select control={control} name={'mainScript'} label={'Main script'} options={mainScriptsOptions} />
            <Select control={control} name={'newScript'} label={'New script'} options={newScriptsOptions} />
            <Input min={0} max={100} type={'number'} control={control} name={'ratio'} label={'Ratio'} />
            <Button onClick={handleSubmit(onSubmit)}>Create config</Button>
          </>
        )}
      </NewConfigForm>
      {currentComponent && (
        <>
          <h3>Bootstrap {currentComponent} current configuration</h3>
          <Table isLoading={false} columns={COLUMNS}>
            {currentConfig ? (
              <TableRow>
                <TableCell>{DateTime.fromSeconds(currentConfig.timestamp).toFormat('yyyy-LL-dd HH:mm:ss')}</TableCell>
                <TableCell>{currentConfig.mainName}</TableCell>
                <TableCell>{currentConfig.abTest?.newName ?? ''}</TableCell>
                <TableCell>{currentConfig.abTest?.ratio ?? 0}%</TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colspan={5}>
                  <EmptyTable>
                    <div>
                      <h3>Something happened</h3>
                      <p>An error occurred when getting current configuration.</p>
                    </div>
                    <Button onClick={onRefreshClick}>Try again</Button>
                  </EmptyTable>
                </TableCell>
              </TableRow>
            )}
          </Table>
          {history && (
            <>
              <H3WithMargin>Bootstrap {currentComponent} configuration history</H3WithMargin>
              <Table isLoading={false} columns={COLUMNS}>
                <TableConfigs configs={history} />
              </Table>
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}

export const PureAdminDelivery = styled(_PureAdminDelivery)``
