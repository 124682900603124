export const AdUnitLevelDimensions = ['adUnitLevel1', 'adUnitLevel2', 'adUnitLevel3', 'adUnitLevel4', 'adUnitLevel5'] as const
export const AllDimensions = [
  'tagId',
  'bidder',
  'adUnit',
  'device',
  'country',
  'size',
  'pubstackRefresh',
  'pubstackDemandChannel',
  'pubstackInventoryType',
  'abTestPopulation',
  'stackIdVersion',
  'mediaType',
  ...AdUnitLevelDimensions,
] as const
export type Dimension = (typeof AllDimensions)[number]
export type MobileInventoryType = 'Web' | 'AMP' | 'App' | 'Other'
