import { useOutletContext } from 'react-router-dom'
import { CatalogPageContext } from './AdStackCatalogTabs'
import { PureAdStackCatalogPage } from './PureAdStackCatalogPage'

const _AdStackCatalogPage: React.FunctionComponent = () => {
  const { baseCDNUrl, catalogItemOptions, isLoading, modules, onCheckIntegrations, search, type, setQueryParams } = useOutletContext<CatalogPageContext>()
  const props = {
    baseCDNUrl,
    catalogItemOptions,
    isLoading,
    modules,
    onCheckIntegrations,
    search,
    type,
    setQueryParams,
  }

  return <PureAdStackCatalogPage {...props} />
}

export const AdStackCatalogPage = _AdStackCatalogPage
