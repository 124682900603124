import styled from '@emotion/styled'
import { ExtraScript } from '@pubstack/common/src/extraScript'
import { DateTime } from 'luxon'
import { ChangeEventHandler, FunctionComponent, useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { RadioGroup } from '~/components/RadioGroup'
import { ToggleInput } from '~/components/Toggle'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { isoToLocal } from '~/modules/adstack/inventory/sites/extra-scripts/PureSiteExtraScript'
import { WithClassName } from '~/types/utils'

const ContentFormWrapper = styled.form`
    input[type='file'] {
        display: none;
    }
    
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const getExtraScriptNameAndDate = (fileName: string) => {
  const regexResult = /(?<fileNameWithoutExtension>.*?)\.js$/.exec(fileName)
  if (!regexResult) {
    throw new Error('File is not a Javascript file')
  }
  if (!regexResult.groups) {
    throw new Error('Could not extract file name')
  }
  const name = regexResult.groups.fileNameWithoutExtension
  const date = DateTime.now().toISO()

  return { name, date }
}

type PureSiteExtraScriptEditModalProps = WithClassName & {
  onExtraScriptCreate: (extraScript: ExtraScript, file?: File | null) => Promise<void>
  onExtraScriptUpdate: (extraScript: ExtraScript, file?: File) => Promise<void>
  extraScript?: ExtraScript
}

type ExtraScriptForm = {
  type: 'file' | 'externalUrl'
  extraScript: ExtraScript
  file?: File | null
  externalScriptUrl?: string
}

export const PureSiteExtraScriptEditModal: FunctionComponent<PureSiteExtraScriptEditModalProps> = ({ onExtraScriptCreate, onExtraScriptUpdate, extraScript }) => {
  const modal = useGlobalModal()
  const { control, handleSubmit, watch, setValue, formState, resetField } = useForm<ExtraScriptForm>({
    defaultValues: {
      type: 'file',
      extraScript: extraScript ?? {
        beforeStart: false,
        synchronous: false,
        enabled: true,
        name: '',
        date: '',
      },
      externalScriptUrl: '',
      file: null,
    },
    mode: 'onChange',
  })

  const extraScriptEdit = watch('extraScript')
  const type = watch('type')
  const isNew = extraScript === undefined
  const canSave = isNew ? extraScriptEdit?.name !== '' : formState.isDirty

  const inputRef = useRef<HTMLInputElement>(null)
  const handleUploadClick = () => {
    inputRef.current?.click()
  }
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      setValue('file', event.target.files[0])
      const { name, date } = getExtraScriptNameAndDate(event.target.files[0].name)
      setValue('extraScript.name', name, { shouldDirty: true })
      setValue('extraScript.date', date)
      event.target.value = ''
    }
  }
  const saveExtraScript: SubmitHandler<ExtraScriptForm> = (form) => {
    if (isNew) {
      onExtraScriptCreate(form.extraScript, form.file).then(modal.close)
    } else {
      onExtraScriptUpdate(form.extraScript).then(modal.close)
    }
  }

  return (
    <Modal.Content>
      <Modal.Title>{isNew ? 'Add new extra-script' : 'Edit extra-script'}</Modal.Title>
      <Modal.Body>
        <ContentFormWrapper id={'extraScriptForm'} onSubmit={(e) => e.preventDefault()}>
          {isNew && (
            <>
              <div>
                <RadioGroup
                  control={control}
                  name={'type'}
                  options={[
                    { label: 'File', value: 'file' },
                    { label: 'External Url', value: 'externalUrl' },
                  ]}
                  onChange={() => {
                    resetField('extraScript')
                    setValue('file', null)
                  }}
                />
              </div>
              <div>
                {type === 'file' ? (
                  <>
                    <input onChange={handleFileChange} ref={inputRef} type={'file'} accept={'text/javascript'} />
                    <Button variant={'primary'} onClick={handleUploadClick} iconName={'upload'}>
                      Choose file
                    </Button>
                  </>
                ) : (
                  <Input
                    label={'External script url'}
                    control={control}
                    name={'extraScript.name'}
                    error={formState.errors.extraScript?.name?.message}
                    isMessageBlock={true}
                    rules={{
                      required: { value: true, message: 'Url required' },
                      pattern: {
                        value: /^https?:\/\/.*/,
                        message: 'Invalid url',
                      },
                    }}
                  />
                )}
              </div>
            </>
          )}
          {!!extraScriptEdit.name && formState.errors.extraScript?.name === undefined && (
            <div>
              <div>Name: {extraScriptEdit.name}</div>
              {extraScriptEdit?.date && <div>Date: {extraScriptEdit.date ? isoToLocal(extraScriptEdit.date) : ''}</div>}
              <div>
                <ToggleInput
                  id={'enabled'}
                  control={control}
                  name={'extraScript.enabled'}
                  onChange={() => {
                    setValue('extraScript.synchronous', false)
                    setValue('extraScript.beforeStart', false)
                  }}
                />{' '}
                Enabled
              </div>
              <div>
                <ToggleInput id={'synchronous'} control={control} name={'extraScript.synchronous'} disabled={!extraScriptEdit.enabled} /> Synchronous
              </div>
              <div>
                <ToggleInput id={'beforeStart'} control={control} name={'extraScript.beforeStart'} disabled={!extraScriptEdit.enabled} /> Early start
              </div>
            </div>
          )}
        </ContentFormWrapper>
      </Modal.Body>
      <Modal.Actions>
        <Button variant={'tertiary'} onClick={modal.close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={handleSubmit(saveExtraScript)} disabled={!canSave}>
          {isNew ? 'Add' : 'Save'}
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}
