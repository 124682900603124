import { CurrencyCode } from './currency'
import { GamNetworkConfig } from './gam/adx'
import { Feature } from './rolesAndFeatures'
import { Timezone } from './timezone'

export type ScopeId = string
export type ShortScopeId = string

export function shortScopeId(scopeId: ScopeId): ShortScopeId {
  return scopeId.substring(0, 8)
}

export interface Scope {
  id: string
  name: string
  currencyCode: CurrencyCode
  enabled: boolean
  pbjs: string
  requestAnalyticsSent: boolean
  gamKeyNamesForPrebid: string[]
  abTestValues?: string[] | null
  timezone: Timezone
  mfaRequired: boolean
}

export interface AdminScope extends Scope {
  features?: Feature[]
  viewabilityEnabled: boolean
  refreshEnabled: boolean
  standaloneUserSessionEnabled: boolean
  sessionTrackingDisabled: boolean
  kleanadsEnabled: boolean
  smartEnabled: boolean
}

export interface AdminScopeWithGamNetwork extends AdminScope {
  gamNetworkConfigs: GamNetworkConfig[]
}

export const EMPTY_ADMIN_SCOPE: AdminScope = {
  id: '',
  name: '',
  enabled: false,
  pbjs: '',
  currencyCode: 'EUR',
  requestAnalyticsSent: false,
  gamKeyNamesForPrebid: [],
  features: [],
  viewabilityEnabled: false,
  refreshEnabled: false,
  standaloneUserSessionEnabled: false,
  sessionTrackingDisabled: false,
  kleanadsEnabled: false,
  abTestValues: undefined,
  smartEnabled: false,
  timezone: 'Europe/Paris',
  mfaRequired: false,
}

export type AdminScopeDataIngestionFlags = Pick<AdminScope, 'viewabilityEnabled' | 'refreshEnabled' | 'standaloneUserSessionEnabled' | 'smartEnabled' | 'sessionTrackingDisabled'>
