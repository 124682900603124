import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { BorderRadius } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'

type SkeletonProps = WithClassName & {
  bigger?: boolean
  width?: string
}

const SkeletonWrapper = styled.div<SkeletonProps>`
  width: ${({ width }) => width};
  position: relative;
  height: ${({ bigger }) => (bigger ? 24 : 12)}px;
  ${BorderRadius.style}
  animation-name: skeletonAnimation;
  animation-duration: 1.7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background: linear-gradient(45deg, ${Colors.Silver} 0%, ${Colors.Platinum} 50%, ${Colors.Silver} 100%);
  background-size: 200%;

  @keyframes skeletonAnimation {
    0% {
      background-position: 100% 0;
    }

    100% {
      background-position: -100% 0;
    }
  }
`
const _Skeleton: FunctionComponent<SkeletonProps> = ({ width = '80%', ...props }) => <SkeletonWrapper {...props} width={width} />
export const Skeleton = styled(_Skeleton)``
