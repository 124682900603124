import { UnknownCurrencyCode } from './exception'

// ensure all exchange-rates are provisionned in https://github.com/pbstck/intake/blob/master/code/rust/mithrandir/src/filters.rs
export const currencies = {
  EUR: '€',

  AED: 'Dh',
  ARS: '$',
  AUD: '$',
  BGN: 'лв',
  BRL: 'R$',
  CAD: '$',
  CHF: 'CHF',
  CNY: '¥',
  CZK: 'Kč',
  DKK: 'Kr.',
  GBP: '£',
  HRK: 'Kn',
  HUF: 'Ft',
  IDR: 'Rp',
  INR: '₹',
  JPY: '¥',
  NOK: 'Kr',
  NZD: '$',
  PLN: 'zł',
  RUB: '₽',
  SEK: 'Kr',
  TRY: 'TRY',
  TWD: 'NT$',
  UAH: '₴',
  USD: '$',
} as const

export type CurrencyCode = keyof typeof currencies
export type CurrencySymbol = (typeof currencies)[CurrencyCode]

export const toCurrencyCode = (input: string): CurrencyCode => {
  if (currencies[input as CurrencyCode] === undefined) {
    throw new UnknownCurrencyCode()
  }

  return input as CurrencyCode
}
