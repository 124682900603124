import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { NonNullable } from '@pubstack/common/src/assertion'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DisplayedStack, Stack, StackGroup, buildStackTemplates, isStackNameReserved } from '@pubstack/common/src/stack'
import { StackContext } from '@pubstack/common/src/stackContext'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { Qualifier } from '~/components/Qualifier'
import { Select } from '~/components/Select'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { StackStatus } from '~/modules/adstack/StackStatus'
import { useStacksGroups } from '~/modules/adstack/stack'
import { WithClassName } from '~/types/utils'
import { PureAdstackStackDetailsFlyout } from './PureAdStackStackDetailsFlyout'
import { PureArchiveStackModal } from './PureArchiveStackModal'
import { PureStackTemplateFlyout } from './PureStackTemplateFlyout'
import { PureUnarchiveStackModal } from './PureUnarchiveStackModal'

const SubTitle = styled.p`
  margin-bottom: 20px;
`
const Header = styled.div`
  display: flex;
`
const FilterBar = styled.form`
  margin-bottom: 12px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;

  display: inline-flex;
  gap: 12px;

  ${Button} {
    margin-left: 16px;
  }
`
const ActionBar = styled.form`
  margin-bottom: 12px;

  ${Button} {
    margin-left: 16px;
  }
`

const ActionsTableCell = styled(TableCell)<{ hideBorderRow?: boolean }>`
  width: 0; /** necessary to get the last cell to shrink down to hug action buttons */

  > div {
    display: flex;
    gap: 10px;
    justify-content: end;
  }

  // Hide the border of the last action button
  ${({ hideBorderRow }) =>
    hideBorderRow &&
    css`
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -1px;
        height: 2px;
        right: 0px;
        border-bottom: 2px solid ${Colors.White};
        width: 33%;
        box-sizing: content-box;
      }
    `}
`

const HideBorderCell = styled(TableCell)<{ hideBorder: boolean }>`
  ${({ hideBorder }) =>
    hideBorder &&
    css`
      border-bottom: transparent;

      :first-of-type {
        border-bottom: transparent;
      }
    `}
`

const ArrowTableCell = styled(HideBorderCell)`
  width: 100px;
`
const PriorityTableCell = styled(HideBorderCell)`
  width: 50px;
`
const NameTableCell = styled(HideBorderCell)`
  width: 240px;
  font-weight: bold;
`

const Qualifiers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const AbTest = styled.div`
  margin-top: 22px;
  display: flex;
  flex-wrap: nowrap;
  gap: 28px;
  flex-direction: column;
`

const AbTestForm = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  flex-direction: row;
  align-items: center;

  ${_Input} {
    width: 95px;
  }
`

const AbTestInfos = styled.div`
  ${Fonts.P1};
  font-style: italic;
  flex: 0 1;
`

const ArchivedRow = styled(TableRow)`
  ${Fonts.colors.Ash};
`

type StackDeployModalProps = {
  onDeploy: (abTest: number) => Promise<void>
  onConnectToPubstack: () => void
  stack: Stack
  liveStack?: Stack
}

type ChangePrioritiesModalProps = {
  onValidate: () => void
}

type NewStackNoContextModalProps = {
  onNewContext: () => void
}

const StackDeployModal: FunctionComponent<StackDeployModalProps> = ({ onDeploy, onConnectToPubstack, stack, liveStack }) => {
  const { close } = useGlobalModal()
  const [isDeploying, setDeploying] = useState(false)
  const {
    control,
    reset,
    formState: { isValid },
    handleSubmit,
  } = useForm<{ abTest: number }>({
    defaultValues: {
      abTest: 100,
    },
  })
  const onSubmit = async (data: { abTest: number }) => {
    if (isDeploying) return
    setDeploying(true)
    await onDeploy(liveStack ? data.abTest : 100)
  }

  return (
    <Modal.Content>
      <Modal.Title>Deploy to production</Modal.Title>

      {/* TODO 2024-02-02 cfo - Ugly, all modals should have 544px max width according to Charline, remove once it's done */}
      <Modal.Body style={{ maxWidth: '544px' }}>
        <div>
          You are about to deploy the stack{' '}
          <strong>
            {stack.name} v{stack.version}.
          </strong>
        </div>
        <div>Before deploying it, make sure to connect the site to Pubstack with the right tag.</div>
        {liveStack && (
          <form onSubmit={(e) => e.preventDefault()}>
            <Controller
              control={control}
              name={'abTest'}
              rules={{ required: true, min: 1, max: 100 }}
              render={({ field: { value: abTest } }) => {
                const contextHasChanged = stack.contextId !== liveStack.contextId
                const hasAbTest = 100 - abTest > 0 && 100 - abTest < 100
                return (
                  <AbTest>
                    <AbTestForm>
                      <span>Deploy this stack on </span>
                      <Input isClearable={false} name={'abTest'} type={'number'} iconRight={'percent'} control={control} min={1} max={100} disabled={contextHasChanged} />
                      <span> of the traffic.</span>
                    </AbTestForm>
                    {contextHasChanged ? (
                      <AbTestInfos>As this version uses a different context than the current one, it must be fully deployed and can’t be tested on the same stack.</AbTestInfos>
                    ) : (
                      hasAbTest && (
                        <AbTestInfos>
                          {liveStack?.name} v{liveStack?.version} will be deployed on {100 - abTest}% of the traffic.
                        </AbTestInfos>
                      )
                    )}
                  </AbTest>
                )
              }}
            />
          </form>
        )}
      </Modal.Body>

      <Modal.Actions>
        <Button
          variant={'tertiary'}
          onClick={() => {
            reset()
            close()
          }}
        >
          Cancel
        </Button>
        <Button variant={'secondary'} onClick={onConnectToPubstack}>
          Connect to Pubstack
        </Button>
        <Button variant={'primary'} onClick={handleSubmit(onSubmit)} disabled={isDeploying || !isValid}>
          Deploy
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const findLiveStack = (stack: Stack, stacks: Stack[]) => stacks.find((s) => s.stackId === stack?.stackId && s.status === 'live' && s.id !== stack.id)
const ChangePrioritiesModal: FunctionComponent<ChangePrioritiesModalProps> = ({ onValidate }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Validate priority changes</Modal.Title>

      <Modal.Body>
        <div>
          Changing stacks priorities can have a <strong>significative impact</strong> on your monetisation.
        </div>
        <div> Are you sure you want to deploy the new configuration?</div>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onValidate}>
          Deploy
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const NewStackNoContextModal: FunctionComponent<NewStackNoContextModalProps> = ({ onNewContext }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>No context, no new stack</Modal.Title>

      <Modal.Body>
        <div>A stack is always associated to a context. As there is no context created yet or all contexts are already used, you can’t create a new stack.</div>
        <div>To create a new stack, you must create a new context first.</div>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onNewContext}>
          New context
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const SyncWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 19px;
`
const SyncSection = styled.div`
  border-top: 1px solid ${Colors.Platinum};
  padding-top: 8px;
  padding-bottom: 12px;
  :last-of-type {
    border-bottom: 1px solid ${Colors.Platinum};
  }
`

const SyncCategory = styled.div`
  ${Fonts.H2};
  ${Fonts.colors.Jet};
  padding-bottom: 8px;
`

const SyncElement = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

type StackSynchronizeModalProps = {
  onSynchronize: () => Promise<void>
  stack?: Stack
}
const StackSynchronizeModal: FunctionComponent<StackSynchronizeModalProps> = ({ onSynchronize, stack }) => {
  const { close } = useGlobalModal()
  const [isDeploying, setDeploying] = useState(false)
  const onSynchronizeSubmit = async () => {
    if (isDeploying) return
    setDeploying(true)
    await onSynchronize()
  }
  return (
    <Modal.Content>
      <Modal.Title>Synchronize</Modal.Title>

      <Modal.Body>
        <div>
          You are about to synchronize <strong>{stack?.name}.</strong>
        </div>
        <SyncWrapper>The following elements will be updated: </SyncWrapper>
        {stack?.elementsToSync.map(({ category, elements }) => (
          <SyncSection key={category}>
            <SyncCategory>{category}</SyncCategory>
            {elements.map((syncElement) => (
              <SyncElement key={syncElement.name}>
                <Icon fill={Colors.King} name={'circle'} height={'6px'} width={'6px'} />
                <div>
                  <strong>{syncElement.name}:</strong> {syncElement.changes.join(', ')}
                </div>
              </SyncElement>
            ))}
          </SyncSection>
        ))}
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onSynchronizeSubmit} disabled={isDeploying}>
          Create updated version
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const canEdit = (stack: Stack, stacks: Stack[]) => stack.status !== 'live' || !stacks.some((s) => s !== stack && s.stackId === stack.stackId)

const useStacksSortedAndFiltered = (stacks: Stack[], search: string) => {
  const { activeGroups: stackGroups, archivedGroups: disabledGroups } = useStacksGroups(stacks)

  const searchFilter = (stackGroup: StackGroup) => stackGroup.groupName.toLowerCase().includes(search.toLowerCase())

  const activeStacks = useMemo(() => [...(stackGroups || [])].filter((stackGroup) => searchFilter(stackGroup)), [stacks, search])
  const archivedStacks = useMemo(() => [...(disabledGroups || [])].filter((stackGroup) => searchFilter(stackGroup)), [stacks, search])

  const maxPriorityNonDefault =
    stackGroups
      .filter((g) => !isStackNameReserved(g.groupName))
      .map(({ priority }) => priority)
      .sort()
      .pop() ?? 1
  return {
    activeStacks: activeStacks,
    archivedStacks,
    maxPriorityNonDefault,
  }
}

const useStacksPriority = (stacks: Stack[]) => {
  const { activeGroups: sgs } = useStacksGroups(stacks)
  const [stackGroups, setStackGroups] = useState(sgs)

  useEffect(() => setStackGroups(sgs), [sgs])

  const updatePriority = (stackId: string, priority: -1 | 1) => {
    const stackIdIndex = stackGroups.findIndex((sg) => sg.stackId === stackId)
    const otherIndex = stackIdIndex + priority
    const stackPriority = stackGroups[stackIdIndex].priority
    const otherPriority = stackGroups[otherIndex].priority

    const newStackGroups = [...stackGroups]
    newStackGroups.splice(stackIdIndex, 1, { ...stackGroups[stackIdIndex], priority: otherPriority })
    newStackGroups.splice(otherIndex, 1, { ...stackGroups[otherIndex], priority: stackPriority })

    newStackGroups.sort((a, b) => a.priority - b.priority)
    setStackGroups(newStackGroups)
  }

  const changedStacksPriorities = stackGroups
    .filter((sg) => {
      const oldSg = sgs.find((sg1) => sg1.stackId === sg.stackId)
      return oldSg?.priority !== sg.priority
    })
    .map(({ stackId, priority }) => ({ stackId, priority }))

  return {
    stacks: stackGroups,
    changedStacksPriorities,
    updatePriority,
  }
}

type PureSiteStackProps = WithClassName & {
  stacks: Stack[]
  stackDetails: DisplayedStack
  templates: Stack[]
  sites: Site[]
  currentSite: Site
  isStackDetailsLoading: boolean
  contexts: StackContext[]
  canDeploy: boolean
  canEditPriorities: boolean
  onEdit: (stackId: string) => void
  onEditPriorities: () => void
  onCancelEditPriorities: () => void
  onValidatePriorities: (changedStacksPriorities: { stackId: string; priority: number }[]) => Promise<void>
  onCreate: (template: string) => Promise<void>
  onDeploy: (stack: Stack, abTest: number) => Promise<void>
  onSynchronize: (stack: Stack) => Promise<void>
  onConnectToPubstack: () => void
  onNewContext: () => void
  onDetailedView: (id: string, siteId: string) => void
  isLoading: boolean
  hasNoContext: boolean
  isEditingPriorities: boolean
  hasAdmRefresh: boolean
  currencySymbol: CurrencySymbol
  stackConfigUrl: string
  canSeeStackLinks: boolean
  onArchive: (stack: Stack) => Promise<void>
  onUnarchive: (stack: Stack) => Promise<void>
}

/* A stack can be deployed if
 * canDeploy is true
 * stack is abtest
 * stack is ready to deploy and not out of sync
 */
export const canDeployStack = (canDeploy: boolean, stack: Stack): boolean =>
  canDeploy && ((stack.ratio !== undefined && stack.ratio !== 100) || (stack.elementsToSync.length === 0 && stack.status === 'commit'))

/* A stack can be synchronized if:
 * stack is out of sync
 * stack is live without abtest and there is no ready to deploy (draft accepted)
 * OR if stack is ready to deploy
 */
export const isSyncButtonDisplayed = (canDeploy: boolean, stack: Stack, stacks: Stack[]): boolean =>
  canDeploy && stack.elementsToSync.length > 0 && (stack.status === 'commit' || (stack.status === 'live' && !stacks.some(({ stackId, status }) => stackId === stack.stackId && status === 'commit')))

export const isSyncEnabled = (stack: Stack): boolean => stack.ratio === undefined || stack.ratio === 100

const ConfigButtonsCell = ({ stackConfigUrl, stack }: { stackConfigUrl: string; stack: Stack }) => {
  const devices = ['desktop', 'mobile'] as const

  return (
    <TableCell>
      {devices.map((device) => {
        return (
          <a key={device} href={`${stackConfigUrl}/${stack.stackId}/${stack.version}/${device}.json`} download target={'_blank'} rel={'noreferrer'}>
            <Button
              variant={'tertiary'}
              iconName={device}
              disabled={stack.status === 'draft'}
              onClick={(event) => {
                event.stopPropagation()
              }}
            />
          </a>
        )
      })}
    </TableCell>
  )
}

enum STACK_SELECT_FILTER {
  ALL = 'all',
  ACTIVE_ONLY = 'active',
  ARCHIVED_ONLY = 'archived',
}

const HeaderColumnNameTooltip = styled(Tooltip)`
  position: sticky;
`

const PRIORITY_TOOLTIP = 'If more than one stack matches the context keys on the page, the stack with the highest priority will be used.'

const _PureSiteStack: FunctionComponent<PureSiteStackProps> = ({
  className,
  isLoading,
  stacks,
  stackDetails,
  templates,
  sites,
  currentSite,
  isStackDetailsLoading,
  contexts,
  canDeploy,
  canEditPriorities,
  onDeploy,
  onSynchronize,
  onEdit,
  onEditPriorities,
  onCancelEditPriorities,
  onValidatePriorities,
  onCreate,
  onConnectToPubstack,
  onNewContext,
  onDetailedView,
  hasNoContext,
  isEditingPriorities,
  hasAdmRefresh,
  currencySymbol,
  stackConfigUrl,
  canSeeStackLinks,
  onArchive,
  onUnarchive,
}) => {
  const modal = useGlobalModal()
  const [isOpenStackDetailsFlyout, setIsOpenStackDetailsFlyout] = useState<boolean>(false)
  const [isOpenStackTemplateFlyout, setIsOpenStackTemplateFlyout] = useState<boolean>(false)
  const [isNoContextModalOpen, setNoContextModalOpen] = useState(false)
  const [canEditDetailedStack, setCanEditDetailedStack] = useState<boolean>(false)
  const columns: TableColumns = (
    [
      {
        name: (
          <>
            <HeaderColumnNameTooltip title={PRIORITY_TOOLTIP} reposition>
              #
            </HeaderColumnNameTooltip>
          </>
        ),
        isSortable: false,
      },
      {
        name: 'Name',
        isSortable: false,
      },
      {
        name: 'Version',
        isSortable: false,
      },
      {
        name: 'Status',
        isSortable: false,
      },
      {
        name: 'Context',
        isSortable: false,
      },
      {
        name: 'Rules',
        isSortable: false,
      },
      canSeeStackLinks
        ? {
            name: 'Download',
            isSortable: false,
          }
        : undefined,
      {
        name: 'Actions',
        isSortable: false,
      },
    ] as TableColumns
  ).filter(NonNullable)

  if (isEditingPriorities) {
    columns.unshift({
      name: '',
      isSortable: false,
    })
  }

  const {
    resetField,
    control,
    formState: { isDirty },
  } = useForm<{ search: string; filter: STACK_SELECT_FILTER }>({
    defaultValues: {
      search: '',
      filter: STACK_SELECT_FILTER.ACTIVE_ONLY,
    },
  })
  const search = useWatch({ control, name: 'search' })
  const filter = useWatch({ control, name: 'filter' })
  const { activeStacks, archivedStacks, maxPriorityNonDefault } = useStacksSortedAndFiltered(stacks, search)
  const { stacks: stacksForPriority, changedStacksPriorities, updatePriority } = useStacksPriority(stacks)

  const displayedStacks = isEditingPriorities ? stacksForPriority : activeStacks
  const displayedActiveStacks = filter !== STACK_SELECT_FILTER.ARCHIVED_ONLY ? displayedStacks.filter((s) => s.stacks.every((s) => s.enabled)) : []
  const displayedArchivedStacks = filter !== STACK_SELECT_FILTER.ACTIVE_ONLY ? archivedStacks.filter((s) => s.stacks.some((s) => !s.enabled)) : []
  const defaultStack = stacks.find((s) => isStackNameReserved(s.name))
  const isDefaultStackNotLive = (stack: Stack): boolean => defaultStack?.status !== 'live' && defaultStack?.stackId !== stack.stackId

  // TODO cfo 2023-11-08 - Ugly special case. Need some rethinking
  useEffect(() => {
    if (isNoContextModalOpen && !modal.isOpen) {
      modal.open(NewStackNoContextModal, {
        onNewContext: () => {
          onNewContext()
          setNoContextModalOpen(false)
          modal.close()
        },
      })
    }

    if (!modal.isOpen) {
      setNoContextModalOpen(false)
    }
  }, [isNoContextModalOpen])

  return (
    <>
      {isEditingPriorities && <SubTitle>Order the stacks by priority from top (high priority) to bottom (low).</SubTitle>}
      <Header>
        <FilterBar>
          <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} disabled={isEditingPriorities} />
          <Select
            label={'Filter'}
            labelIsPlaceholder
            name={'filter'}
            control={control}
            options={[
              { value: STACK_SELECT_FILTER.ALL, label: 'All' },
              { value: STACK_SELECT_FILTER.ACTIVE_ONLY, label: 'Active stacks' },
              { value: STACK_SELECT_FILTER.ARCHIVED_ONLY, label: 'Archived stacks', disabled: !archivedStacks.length },
            ]}
            disabled={isEditingPriorities}
          />
        </FilterBar>
        <ActionBar>
          {canEditPriorities &&
            (!isEditingPriorities ? (
              <Tooltip title={PRIORITY_TOOLTIP} reposition>
                <Button variant={'primary'} onClick={onEditPriorities} disabled={displayedActiveStacks.length < 3}>
                  Edit priorities
                </Button>
              </Tooltip>
            ) : (
              <>
                <Button variant={'tertiary'} onClick={onCancelEditPriorities}>
                  Cancel
                </Button>
                <Button
                  variant={'primary'}
                  onClick={(event) => {
                    event.stopPropagation()
                    modal.open(ChangePrioritiesModal, {
                      onValidate: async () => {
                        await onValidatePriorities(changedStacksPriorities)
                        modal.close()
                      },
                    })
                  }}
                >
                  Validate priorities
                </Button>
              </>
            ))}
          {defaultStack?.status !== 'live' ? (
            <Tooltip title={'Deploy Default before creating another stack'} align={'end'}>
              <Button variant={'primary'} disabled>
                New stack
              </Button>
            </Tooltip>
          ) : (
            <Button
              variant={'primary'}
              onClick={(event) => {
                event.stopPropagation()
                if (hasNoContext) {
                  setNoContextModalOpen(true)
                } else {
                  setIsOpenStackTemplateFlyout(true)
                }
              }}
              disabled={isEditingPriorities}
            >
              New stack
            </Button>
          )}
        </ActionBar>
      </Header>
      <Table className={className} isLoading={isLoading} columns={columns}>
        {displayedActiveStacks.map((stackGroup) =>
          stackGroup.stacks.map((stack, index) => {
            const hideBorder = index === 0 && stackGroup.stacks.length > 1
            const isEditable = canEdit(stack, stackGroup.stacks)
            const openDetailedView = () => {
              onDetailedView(stack.id, stack.siteId)
              setCanEditDetailedStack(isEditable)
              setIsOpenStackDetailsFlyout(true)
            }
            const isDefaultStack = isStackNameReserved(stack.name)
            const isTheGreaterVersion = [...stackGroup.stacks].sort((a, b) => (a.version ?? 0) - (b.version ?? 0)).pop()?.id === stackGroup.stacks[index].id
            return (
              <TableRow
                key={stack.id}
                onClick={(event) => {
                  event.stopPropagation()
                  openDetailedView()
                }}
              >
                {index === 0 ? (
                  <>
                    {isEditingPriorities && (
                      <ArrowTableCell hideBorder={hideBorder}>
                        <Button
                          variant={'tertiary'}
                          iconName={'chevron_up'}
                          onClick={(event) => {
                            event.stopPropagation()
                            updatePriority(stack.stackId, -1)
                          }}
                          disabled={isStackNameReserved(stack.name) || isDirty || stackGroup.priority === 1}
                        />
                        <Button
                          variant={'tertiary'}
                          iconName={'chevron_down'}
                          onClick={(event) => {
                            event.stopPropagation()
                            updatePriority(stack.stackId, 1)
                          }}
                          disabled={isStackNameReserved(stack.name) || isDirty || stackGroup.priority === maxPriorityNonDefault}
                        />
                      </ArrowTableCell>
                    )}
                    <PriorityTableCell hideBorder={hideBorder}>{stackGroup.priority}</PriorityTableCell>
                    <NameTableCell hideBorder={hideBorder}>{stackGroup.groupName}</NameTableCell>
                  </>
                ) : (
                  <>
                    {isEditingPriorities && <TableCell />}
                    <TableCell />
                    <TableCell />
                  </>
                )}
                <TableCell>{stack.status === 'draft' ? '-' : `v${stack.version}`}</TableCell>
                <TableCell>
                  <StackStatus stack={stack} />
                </TableCell>
                <TableCell>{contexts.find((c) => c.id === stack.contextId)?.name || 'default'}</TableCell>
                <TableCell>
                  <Qualifiers>
                    <Qualifier iconName={'header_bidding'} tooltipText={`Header bidding ${stack.headerBiddingEnabled ? 'allowed' : 'disabled'}`} active={!!stack.headerBiddingEnabled} enabled />
                    <Qualifier iconName={'loading'} tooltipText={`Lazy loading ${stack.lazyLoadingRules?.length ? 'allowed' : 'disabled'}`} active={!!stack.lazyLoadingRules?.length} enabled />
                    <Qualifier iconName={'floor'} tooltipText={`Floor price ${stack.floorsRules?.length ? 'allowed' : 'disabled'}`} active={!!stack.floorsRules?.length} enabled />
                    {hasAdmRefresh && <Qualifier iconName={'refresh'} tooltipText={`Refresh ${stack.refreshRules?.length ? 'allowed' : 'disabled'}`} active={!!stack.refreshRules?.length} enabled />}
                  </Qualifiers>
                </TableCell>
                {canSeeStackLinks && <ConfigButtonsCell stackConfigUrl={stackConfigUrl} stack={stack} />}
                <ActionsTableCell hideBorderRow={index !== stackGroup.stacks.length - 1}>
                  <div>
                    {isSyncButtonDisplayed(canDeploy, stack, stacks) && (
                      <Tooltip
                        title={isSyncEnabled(stack) ? 'Create updated version' : 'Creating an updated version is not possible: you need to deploy a version at 100% first.'}
                        positions={['left']}
                      >
                        <Button
                          variant={'tertiary'}
                          iconName={'sync'}
                          disabled={!isSyncEnabled(stack)}
                          onClick={(event) => {
                            event.stopPropagation()
                            modal.open(StackSynchronizeModal, {
                              stack,
                              onSynchronize: async () => {
                                await onSynchronize(stack)
                                modal.close()
                              },
                            })
                          }}
                        />
                      </Tooltip>
                    )}

                    {canDeployStack(canDeploy, stack) && (
                      <Tooltip title={isDefaultStackNotLive(stack) ? 'Please deploy your default stack first' : 'Deploy'} align={'end'}>
                        <Button
                          variant={'tertiary'}
                          iconName={'send'}
                          onClick={(event) => {
                            event.stopPropagation()
                            modal.open(StackDeployModal, {
                              stack,
                              liveStack: findLiveStack(stack, stacks),
                              onDeploy: async (abTest: number) => {
                                await onDeploy(stack!, abTest)
                                modal.close()
                              },
                              onConnectToPubstack: () => {
                                onConnectToPubstack()
                                modal.close()
                              },
                            })
                          }}
                          disabled={isEditingPriorities || isDefaultStackNotLive(stack)}
                        />
                      </Tooltip>
                    )}
                    {
                      <Tooltip key={index} title={'Edit'}>
                        <Button
                          variant={'tertiary'}
                          iconName={'edit'}
                          onClick={(event) => {
                            event.stopPropagation()
                            onEdit(stack.stackId)
                          }}
                          disabled={isEditingPriorities || !isEditable}
                        />
                      </Tooltip>
                    }

                    <Tooltip title={'View'}>
                      <Button
                        variant={'tertiary'}
                        iconName={'view'}
                        onClick={(event) => {
                          event.stopPropagation()
                          openDetailedView()
                        }}
                      />
                    </Tooltip>

                    {isTheGreaterVersion ? (
                      <Tooltip reposition title={isDefaultStack ? 'Default stack cannot be archived' : 'Archive'}>
                        <Button
                          disabled={isDefaultStack || isEditingPriorities}
                          iconName={'archive'}
                          variant={'tertiary'}
                          onClick={async (event) => {
                            event.stopPropagation()
                            modal.open(PureArchiveStackModal, {
                              onArchive: async () => {
                                await onArchive(stack)
                                modal.close()
                              },
                              stackName: stack.name,
                            })
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Icon width={'38px'} name={'empty'} />
                    )}
                  </div>
                </ActionsTableCell>
              </TableRow>
            )
          })
        )}
        {displayedArchivedStacks.map((stackGroup) =>
          stackGroup.stacks.map((stack, index) => {
            const hideBorder = index === 0 && stackGroup.stacks.length > 1
            return (
              <ArchivedRow key={stack.id}>
                {isEditingPriorities && <TableCell />}
                <PriorityTableCell hideBorder={hideBorder}>-</PriorityTableCell>
                <NameTableCell hideBorder={hideBorder}>{stackGroup.groupName}</NameTableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  <StackStatus stack={stack} />
                </TableCell>
                <TableCell>{contexts.find((c) => c.id === stack.contextId)?.name || 'default'}</TableCell>
                <TableCell>
                  <Qualifiers>
                    <Qualifier
                      iconName={'header_bidding'}
                      tooltipText={`Header bidding ${stack.headerBiddingEnabled ? 'allowed' : 'disabled'}`}
                      active={!!stack.headerBiddingEnabled}
                      enabled={false}
                    />
                    <Qualifier iconName={'loading'} tooltipText={`Lazy loading ${stack.lazyLoadingRules?.length ? 'allowed' : 'disabled'}`} active={!!stack.lazyLoadingRules?.length} enabled={false} />
                    <Qualifier iconName={'floor'} tooltipText={`Floor price ${stack.floorsRules?.length ? 'allowed' : 'disabled'}`} active={!!stack.floorsRules?.length} enabled={false} />
                    {hasAdmRefresh && (
                      <Qualifier iconName={'refresh'} tooltipText={`Refresh ${stack.refreshRules?.length ? 'allowed' : 'disabled'}`} active={!!stack.refreshRules?.length} enabled={false} />
                    )}
                  </Qualifiers>
                </TableCell>
                {canSeeStackLinks && <ConfigButtonsCell stackConfigUrl={stackConfigUrl} stack={stack} />}
                <ActionsTableCell>
                  <div>
                    <Tooltip title={'Unarchive'}>
                      <Button
                        iconName={'unarchive'}
                        variant={'tertiary'}
                        onClick={async (event) => {
                          event.stopPropagation()
                          modal.open(PureUnarchiveStackModal, {
                            onUnarchive: async () => {
                              await onUnarchive(stack)
                              onEdit(stack.stackId)
                              modal.close()
                            },
                            stackName: stack.name,
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                </ActionsTableCell>
              </ArchivedRow>
            )
          })
        )}
      </Table>
      <PureAdstackStackDetailsFlyout
        isOpen={isOpenStackDetailsFlyout}
        isLoading={isStackDetailsLoading}
        onClose={() => {
          setIsOpenStackDetailsFlyout(false)
        }}
        detailedStack={stackDetails}
        canEdit={canEditDetailedStack}
        onEditStack={onEdit}
        currencySymbol={currencySymbol}
        hasAdmRefresh={hasAdmRefresh}
      />
      {currentSite.id && (
        <PureStackTemplateFlyout
          isOpen={isOpenStackTemplateFlyout}
          isLoading={isLoading}
          sites={sites}
          currentSiteId={currentSite.id}
          templates={buildStackTemplates(templates)}
          detailedStack={stackDetails}
          onClose={() => setIsOpenStackTemplateFlyout(false)}
          onCreateStack={(template) => onCreate(template)}
          onDetailedView={(id, siteId) => onDetailedView(id, siteId)}
          currencySymbol={currencySymbol}
        />
      )}
    </>
  )
}
export const PureSiteStack = styled(_PureSiteStack)``
