import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon } from './Icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
`

const Trail = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  ${Fonts.colors.Jet};
  ${Fonts.H1};
  gap: 4px;
`

const Breadcrumb = styled.div<{ isInteractive: boolean }>`
  display: inline-block;
  ${({ isInteractive }) =>
    isInteractive &&
    css`
      cursor: pointer;
    `}
`

const Subtitle = styled.span`
  ${Fonts.P2}
  ${Fonts.colors.Hurricane}
`
export type BreadcrumbProps = {
  label: string
  route?: string
}

export type BreadcrumbsProps = WithClassName & {
  data: BreadcrumbProps[]
  subtitle?: string
  onClick?: (breadcrumb: BreadcrumbProps) => void
}
const _Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ data, subtitle, onClick, className }) => (
  <Wrapper>
    <Trail className={className}>
      {data.map((breadcrumb, index) => {
        const interactive = !!onClick && !!breadcrumb.route
        return (
          <Fragment key={index}>
            <Breadcrumb onClick={() => interactive && onClick(breadcrumb)} isInteractive={interactive}>
              {breadcrumb.label}
            </Breadcrumb>
            {index !== data.length - 1 && <Icon name={'chevron_right'} width={Sizes['20']} />}
          </Fragment>
        )
      })}
    </Trail>
    <Subtitle> {subtitle}</Subtitle>
  </Wrapper>
)
export const Breadcrumbs = styled(_Breadcrumbs)``
