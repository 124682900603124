import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, FunctionComponent, PropsWithChildren } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevel, Transitions } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import { Icon } from '~/components/Icon'
import { WithClassName } from '~/types/utils'
import { GroupItem, Section, SimpleItem } from './menu.config'

const MenuItemName = styled.span<{ hasNotifications?: boolean }>`
  opacity: 1;
  white-space: nowrap;
  transition: 0.15s ease-in-out;
  display: flex;
  align-items: center;
  position: relative;

  ${({ hasNotifications }) =>
    hasNotifications &&
    css`
      :before {
        content: '';
        --menu-item-notification-size: 6px;
        background: ${Colors.Alert};
        min-width: var(--menu-item-notification-size);
        width: var(--menu-item-notification-size);
        max-width: var(--menu-item-notification-size);
        height: var(--menu-item-notification-size);
        border-radius: var(--menu-item-notification-size);
        position: absolute;
        top: 4px;
        right: -8px;
        ${ElevationLevel.low}
      }
    `}
`

const MenuItemWrapper = styled.div<{ active?: boolean; level: number; isOpen?: boolean }>`
  ${Fonts.colors.Hurricane};
  ${Fonts.P2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 22px;
  cursor: pointer;
  position: relative;
  transition: ease-in-out 300ms;
  border-radius: 2px;
  padding: 4px 4px 4px var(--mentu-item-wrapper-padding-left);

  ${({ level }) =>
    level === 1
      ? css`
          font-weight: 500;
          --mentu-item-wrapper-padding-left: 14px;
        `
      : css`
          --mentu-item-wrapper-padding-left: 20px;
        `}
  &:hover {
    text-decoration: none;
    background: ${Colors.Cloud};
    padding-left: calc(var(--mentu-item-wrapper-padding-left) + 4px);
  }

  ${({ active }) =>
    active &&
    css`
      color: ${Colors.King};
      background: ${Colors.Topaze};

      &:hover {
        background: ${Colors.Cloud};
      }
    `}

  & ${Icon} {
    transition: transform ${Transitions.default};
    transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0')});
  }
`
type MenuItemProps = PureMenuItem & {
  onClick: () => void
  active: boolean
  level: number
  isGroup?: boolean
  isOpen?: boolean
}
const MenuItem: FunctionComponent<MenuItemProps> = ({ onClick, name, active, level, notifications, isGroup, isOpen }) => {
  return (
    <MenuItemWrapper onClick={onClick} active={active} level={level} isOpen={isOpen}>
      <MenuItemName hasNotifications={!!notifications}>{name}</MenuItemName>
      {isGroup && <Icon name={'chevron_down'} width={'14px'} />}
    </MenuItemWrapper>
  )
}

type MenuGroupProps = PureMenuGroup & {
  onClick: () => void
  open?: boolean
}
const MenuGroup: FunctionComponent<MenuGroupProps> = ({ onClick, name, open, items }) => {
  return (
    <>
      <MenuItem onClick={onClick} active={false} level={1} name={name} isGroup isOpen={open} />
      {!!open && items.map((item, i) => <MenuItem {...item} key={i} level={2} />)}
    </>
  )
}

const MenuSectionTitle = styled.div`
  ${Fonts.colors.SlateGrey};
  ${Fonts.P3};
  line-height: 24px;
  padding-left: 8px;
  font-weight: 500;
  opacity: 1;
  width: auto;
  transition: 0.15s ease-in-out;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  ${Icon} {
    flex-grow: 0;
  }
`

const MenuSectionWrapper = styled.div`
  margin-top: 18px;
  &:first-of-type {
    margin-top: 0;
  }

  transition: 0.15s ease-in-out;

  display: flex;
  flex-direction: column;
  gap: 4px;
`

type MenuSectionProps = PureMenuSection
const MenuSection: FC<PropsWithChildren<MenuSectionProps>> = ({ title, items, icon }) => {
  return (
    <MenuSectionWrapper>
      {title && (
        <MenuSectionTitle>
          {!!icon && <Icon name={icon} width={'15px'} />}
          {title}
        </MenuSectionTitle>
      )}
      {items.map((item, i) => (item.type === 'group' ? <MenuGroup {...item} key={i} /> : <MenuItem {...item} key={i} level={1} />))}
    </MenuSectionWrapper>
  )
}

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  height: 100%;
  padding: 0 8px;

  > div:first-child {
    overflow: auto;
    ${ScrollbarStyle};
    flex: 1 1 0;
  }
`

export type PureMenuItem = Omit<SimpleItem, 'to'> & { onClick: () => void; active: boolean }
export type PureMenuGroup = Omit<GroupItem, 'items'> & { items: PureMenuItem[] } & { onClick: () => void }
export type PureMenuSection = Omit<Section, 'items'> & { items: (PureMenuItem | PureMenuGroup)[] }
export type PureMenuProps = WithClassName & {
  sections: PureMenuSection[]
  bottomItems: PureMenuItem[]
}
const _PureMenu: FunctionComponent<PureMenuProps> = ({ sections, bottomItems, className }) => {
  return (
    <MenuWrapper className={className}>
      <div>
        {sections.map((section, index) => (
          <MenuSection {...section} key={index} />
        ))}
      </div>
      <div>
        {bottomItems.map((item, index) => (
          <MenuItem {...item} key={index} level={1} />
        ))}
      </div>
    </MenuWrapper>
  )
}
export const PureMenu = styled(_PureMenu)``
