import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AllQuery, AnalyticsQueryFilter } from '@pubstack/common/src/analytics/query'
import { ReportNameType } from '@pubstack/common/src/report'
import { ScopeId } from '@pubstack/common/src/scope'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useUser } from '~/auth/user.hooks'
import { configurationState, currentScopeState } from '~/state'
import { getEnvFromUrl } from './environment'
import { removeTraillingSlash } from './string'

// 2020-09-02: A product action is always a click
interface BaseProductAction {
  action: 'click'
}

export interface SwitchScopeProductAction extends BaseProductAction {
  type: 'switch-scope'
  toScope: string
}

export interface SwitchGraphProductAction extends BaseProductAction {
  type: 'graph-action'
  graphName: string
  detail: string
}

export interface TemporalFilterProductAction extends BaseProductAction {
  type: 'temporal'
  range: string // TODO: cfo 2022-08-16 - type this better - TimeRangeConfigItem['label']
  toDate: number
  fromDate: number
  nbOfDaysBetweenQueryAndToday: number
}

export interface FacetFilterProductAction extends BaseProductAction {
  type: 'facet'
  name: Dimension
}
export interface FacetFilterExpandProductAction extends FacetFilterProductAction {
  type: 'facet'
  detail: 'expand'
  name: Dimension
}

export interface FacetFilterSelectProductAction extends FacetFilterProductAction {
  type: 'facet'
  name: Dimension
  detail: 'filter'
  check: boolean
  value: string
}

export interface DimensionFilterProductAction extends BaseProductAction {
  type: 'filter'
  name: Dimension
  data: string
}

export interface SimpleProductAction extends BaseProductAction {
  type: 'refresh' | 'documentation' | 'logout'
}

export interface GoToProductAction extends BaseProductAction {
  type: 'navigation'
  fromRoute?: string
  toRoute: string
}

export interface ArrayProductAction extends BaseProductAction {
  type: 'array-sort'
  metric: string
}

export interface BreakdownProductAction extends BaseProductAction {
  type: 'bidder-detail-breakdown' | 'site-detail-breakdown' | 'viewability-breakdown' | 'user-session-breakdown' | 'refresh-breakdown'
  detail: Dimension
}

export interface ReportProductAction extends BaseProductAction {
  type: 'report'
  detail: 'refresh' | 'report-creation'
  reportName?: ReportNameType
}

export interface AlertProductAction extends BaseProductAction {
  type: 'alert-detail'
}

export interface ConfigurationSiteProductAction extends BaseProductAction {
  type: 'site-settings'
  siteName: string | null
  detail: 'edit-name' | 'edit-domain' | 'add-sites' | 'export-sites' | 'edit-refresh-population-percentage' | 'edit-refresh-adunits' | null
}

export interface ConfigurationIntegrationProductAction extends BaseProductAction {
  type: 'integrations'
  detail: string
}

export interface ConfigurationUserProductAction extends BaseProductAction {
  type: 'user-settings'
  userEmail: string
  detail: 'add' | 'edit-role' | 'delete' | null
}
export interface QueryTechnicalAction {
  queryFrom?: number
  queryTo?: number
  queryDays?: number
  queryType?: AllQuery
  queryDimension?: string
  queryFilter?: { [key: string]: AnalyticsQueryFilter }
  time: number
  url: string
  type: 'queryDuration'
}
export interface ErrorTechnicalAction {
  message: string
  error: Error
}

type TechnicalAction = QueryTechnicalAction | ErrorTechnicalAction

export interface ControlCenterProductAction extends BaseProductAction {
  type: 'control-center'
  actionName: 'sync' | 'deploy'
  allSelected: boolean
  allStacksCount: number
  selectedCount: number
}

export interface DetailedFlyoutProductAction extends BaseProductAction {
  type: 'detailed-view-flyout'
  actionName: 'adUnit' | 'stack'
  from: string
}

export interface ShowMinorDataToggleProductAction extends BaseProductAction {
  type: 'show-minor-data'
  value: boolean
}

type ProductAction =
  | SwitchScopeProductAction
  | SwitchGraphProductAction
  | TemporalFilterProductAction
  | FacetFilterExpandProductAction
  | FacetFilterSelectProductAction
  | DimensionFilterProductAction
  | SimpleProductAction
  | GoToProductAction
  | ArrayProductAction
  | BreakdownProductAction
  | ReportProductAction
  | AlertProductAction
  | ConfigurationSiteProductAction
  | ConfigurationIntegrationProductAction
  | ConfigurationUserProductAction
  | ControlCenterProductAction
  | DetailedFlyoutProductAction
  | ShowMinorDataToggleProductAction

export type Action = ProductAction | TechnicalAction

export interface Context {
  username?: string
  page?: string
  scopeId?: ScopeId
  scopeName?: string
}

export type Logger = {
  info: (message: Action) => Promise<Response | undefined>
}

export const useLogger = (): Logger => {
  const config = useRecoilValue(configurationState)
  const user = useUser()
  const location = useLocation()
  const currentScope = useRecoilValue(currentScopeState)
  const url = `https://${config.LOGGING_ENDPOINT}?appname=app.pubstack.io`
  const page = removeTraillingSlash(location.pathname)
  const context = {
    appname: 'app.pubstack.io',
    env: process.env.NODE_ENV,
    username: user?.profile.email,
    scopeId: user?.currentScopeId,
    page,
    scopeName: currentScope.name,
    environment: getEnvFromUrl(window.location.host),
  }
  const info = async (message: Action): Promise<Response | undefined> => {
    const body = {
      ...message,
      ...context,
      '@timestamp': DateTime.utc().toJSDate(),
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    }
    if (process.env.NODE_ENV === 'development') {
      // biome-ignore lint/nursery/noConsole: it's a logger
      console.log(body)
    } else {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(body),
      })
    }
  }

  return { info }
}
