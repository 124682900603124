import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { Color, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'
import { Legend } from './Legend'

type ChartTooltipProps = WithClassName & {
  date: string
  metrics: ChartTooltipMetricProps[][]
}

export type ChartTooltipMetricProps = WithClassName & {
  iconName?: IconName
  iconColor?: Color
  label: string
  value: string
  secondary?: boolean
}

const ChartTooltipWrapper = styled.div`
  display: inline-block;
  width: 300px;
  padding: 12px 0;
`

const ChartTooltipMetric = styled.div<{ secondary?: boolean }>`
  & ${Icon} {
    margin-right: 6px;
  }

  color: ${({ secondary }) => (secondary ? Colors.SlateGrey : Colors.Jet)};

  ${Fonts.P2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: ${({ secondary }) => (secondary ? 'italic' : 'normal')};

  &:not(:last-of-type) {
    margin-bottom: 6px;
  }
`

const ChartToolTipMetricValue = styled.div`
  font-weight: bold;
`

const ChartToolTipMetricSeparator = styled.hr`
  border: 0;
  border-top: 1px solid ${Colors.Platinum};
`

const ChartTooltipTitle = styled.div`
  padding: 0 12px;
  margin-bottom: 12px;
`

const ChartTooltipMetricGroup = styled.div`
  padding: 0 12px;
`

const _ChartTooltip: FunctionComponent<ChartTooltipProps> = ({ date, className, metrics }) => {
  return (
    <ChartTooltipWrapper className={className}>
      <ChartTooltipTitle>{date}</ChartTooltipTitle>
      {metrics.map((metricGroup, index) => {
        return (
          <Fragment key={`group-${index}`}>
            <ChartTooltipMetricGroup>
              {metricGroup.map((metric, i) => {
                return (
                  <ChartTooltipMetric key={`metric-${i}`} secondary={metric.secondary}>
                    <Legend label={metric.label} iconName={metric.iconName} iconColor={metric.iconColor ?? Colors.Jet} fontColor={metric.secondary ? Colors.SlateGrey : Colors.Jet} />

                    <ChartToolTipMetricValue>{metric.value}</ChartToolTipMetricValue>
                  </ChartTooltipMetric>
                )
              })}
            </ChartTooltipMetricGroup>
            {index !== metrics.length - 1 && <ChartToolTipMetricSeparator />}
          </Fragment>
        )
      })}
    </ChartTooltipWrapper>
  )
}

export const ChartTooltip = styled(_ChartTooltip)``
