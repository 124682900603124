import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { ElevationLevel } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { TabProp, Tabs } from '~/components/Tabs'
import { WithClassName } from '~/types/utils'
import { PureAdstackRefreshHelpFlyout } from './PureAdStackRefreshHelpFlyout'

const TitleBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const TitleButtons = styled.div`
  display: flex;
  gap: 20px;
`
const ContextTabsWrapper = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`
const ScopeDetailHeader = styled.div`
  background-color: ${Colors.Ghost};
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  position: sticky;
  top: 0px;
  ${ElevationLevel.middle}
`

export const RefreshWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

type PureAdStackRefreshPageProps = WithClassName & {
  onTabClick: (tab: TabProp) => unknown
  tabs: TabProp[]
  breadcrumbs?: React.ReactNode
}
const _PureAdStackRefreshPage: FunctionComponent<PropsWithChildren<PureAdStackRefreshPageProps>> = ({ className, breadcrumbs, onTabClick, tabs, children }) => {
  const [isOpenHelpFlyout, setIsOpenHelpFlyout] = useState<boolean>(false)

  return (
    <ContextTabsWrapper className={className}>
      <TitleBar>
        {breadcrumbs}
        <TitleButtons>
          <Button variant={'tertiary'} iconName={'help'} onClick={() => setIsOpenHelpFlyout(true)}>
            Help
          </Button>
        </TitleButtons>
      </TitleBar>
      <ScopeDetailHeader>
        <Tabs tabs={tabs} onClick={onTabClick} />
      </ScopeDetailHeader>
      {children}
      <PureAdstackRefreshHelpFlyout isOpen={isOpenHelpFlyout} onRequestClose={() => setIsOpenHelpFlyout(false)} />
    </ContextTabsWrapper>
  )
}

export const PureAdStackRefreshPage = styled(_PureAdStackRefreshPage)``
