import styled from '@emotion/styled'
import { BidderCatalogAlias, BidderParam, MappedBidder } from '@pubstack/common/src/bidderCatalog'
import { SiteStacksByProperty } from '@pubstack/common/src/stack'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useState } from 'react'
import Button from '~/components/Button'
import { WithClassName } from '~/types/utils'
import { PureSiteMappingAdunitView } from './PureSiteMappingAdunitView'
import { PureSiteMappingBidderView } from './PureSiteMappingBidderView'

const Menu = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 26px 0px;
  gap: 8px;
  ${Button} {
    border-radius: 32px;
    font-weight: 400;
    padding: 6px 10px;
  }
`

type PureSiteMappingProps = WithClassName & {
  bidderList: MappedBidder[]
  isLoading: boolean
  onCreateNewBidder: () => void
  isUploading: boolean
  isTemplateDownloading: boolean
  isMappingDownloading: boolean
  onMappingDownload: (alias: BidderCatalogAlias, options?: { fullMapping?: boolean }) => void
  onMappingUpload: (alias: BidderCatalogAlias, file: File, options?: { partialUpload?: boolean }) => void
  onMappingUploadByAdUnit: (adUnitName: string, bidderParams: BidderParam[]) => void
  site: Site
  stacksByAdunit: SiteStacksByProperty
}

const _PureSiteMapping: FunctionComponent<PureSiteMappingProps> = ({
  isLoading,
  bidderList,
  isUploading,
  isMappingDownloading,
  isTemplateDownloading,
  onMappingDownload,
  onMappingUpload,
  onMappingUploadByAdUnit,
  onCreateNewBidder,
  site,
  stacksByAdunit,
}) => {
  const [isBidderView, setIsBidderView] = useState(true)

  return (
    <>
      <Menu>
        Display mapping by
        <Button variant={isBidderView ? 'primary' : 'tertiary'} onClick={() => setIsBidderView(true)}>
          Bidders
        </Button>
        <Button variant={!isBidderView ? 'primary' : 'tertiary'} onClick={() => setIsBidderView(false)}>
          Ad units
        </Button>
      </Menu>

      {isBidderView ? (
        <PureSiteMappingBidderView
          bidderList={bidderList}
          isLoading={isLoading}
          isMappingDownloading={isMappingDownloading}
          isTemplateDownloading={isTemplateDownloading}
          isUploading={isUploading}
          onCreateNewBidder={onCreateNewBidder}
          onMappingDownload={onMappingDownload}
          onMappingUpload={onMappingUpload}
          siteId={site.id}
        />
      ) : (
        <PureSiteMappingAdunitView
          bidderList={bidderList}
          isLoading={isLoading}
          onCreateNewBidder={onCreateNewBidder}
          onMappingUploadByAdUnit={onMappingUploadByAdUnit}
          stacksByAdunit={stacksByAdunit}
          siteName={site.name}
        />
      )}
    </>
  )
}

export const PureSiteMapping = styled(_PureSiteMapping)``
