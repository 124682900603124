import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, ViewabilityMetricsByDim, ViewabilityMetricsByTime } from '@pubstack/common/src/analytics/query'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useLocalStorage } from 'usehooks-ts'
import { useAnalyticsQuery } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { logSortBreakdownAction, onBreakdownRowClick } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useBreakdownTabs } from '~/utils/useBreakdownTabs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureViewabilityBreakdown } from './PureViewabilityBreakdown'
import { PureViewabilityTimeline } from './PureViewabilityTimeline'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 16px;
  }
`

type ViewabilityPageProps = WithClassName
const _ViewabilityPage: FunctionComponent<ViewabilityPageProps> = ({ className }) => {
  const dimensions: Dimension[] = ['tagId', 'bidder', 'adUnit', 'device', 'country', 'size', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion']
  const dashboard: AnalyticsQueryDashboard = { name: 'viewability', filterType: 'auctions' }

  const [context, setContext] = useRecoilState(contextState)
  const [breakdownDimension, setBreakdownDimension] = useState<Dimension>('tagId')
  const [timeData, setTimeData] = useState<ViewabilityMetricsByTime | undefined>(undefined)
  const [dimData, setDimData] = useState<ViewabilityMetricsByDim | undefined>(undefined)
  const { byId: analyticsQueryByTime } = useAnalyticsQuery<ViewabilityMetricsByTime>(null, dimensions, context)
  const { byId: analyticsQueryByDim } = useAnalyticsQuery<ViewabilityMetricsByDim>(null, dimensions, context)
  const currencySymbol = useScopeCurrency()
  const user = useUser()
  const tabsConfig = useBreakdownTabs(user, ['tagId', 'bidder', 'adUnit', 'device', 'country', 'size'], { abTestPopulation: 'abtest', stackIdVersion: 'adstack' })
  const [showMinorData] = useLocalStorage<boolean>('showMinorData', false)

  const loadTimeLineData = async () => {
    if (analyticsQueryByTime.loading) {
      analyticsQueryByTime.abort()
    }
    const d = await analyticsQueryByTime.post('viewability.metrics.by.time', {})
    setTimeData(d.values)
  }

  const loadBreakdownData = async (dimension: Dimension) => {
    if (analyticsQueryByDim.loading) {
      analyticsQueryByDim.abort()
    }
    const d = await analyticsQueryByDim.post('viewability.metrics.by.dim', { dimension })
    setDimData(d.values)
  }

  const loadAllData = async () => {
    await loadBreakdownData(breakdownDimension)
    await loadTimeLineData()
  }

  const logger = useLogger()
  const onBreakdownTabChange = (dimension: Dimension) => {
    logger.info({ action: 'click', type: 'viewability-breakdown', detail: dimension })
    setBreakdownDimension(dimension)
    loadBreakdownData(dimension)
  }

  const onMetricChange = (metric: string) => {
    logger.info({ type: 'graph-action', action: 'click', graphName: 'viewability-timeline', detail: metric })
  }

  useEffect(() => {
    setTimeout(() => {
      loadTimeLineData()
      loadBreakdownData(breakdownDimension)
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context])

  const breadcrumbs = useBreadcrumbs()
  return (
    <AnalyticsPage className={className} onRefreshClick={loadAllData} filterDimensions={dimensions} dashboard={dashboard} title={breadcrumbs} showMinorDataToggle={true}>
      <PageContent>
        <PureViewabilityTimeline
          isLoading={analyticsQueryByTime.loading}
          error={!!analyticsQueryByTime.error}
          empty={!analyticsQueryByTime.loading && !timeData?.epoch.length}
          onRefreshClick={loadTimeLineData}
          data={timeData ?? { epoch: [] }}
          currencySymbol={currencySymbol}
          currentEpoch={DateTime.now()}
          onMetricChange={onMetricChange}
          timezone={context.timeRange.tz}
        />
        <PureViewabilityBreakdown
          isLoading={analyticsQueryByDim.loading}
          error={!!analyticsQueryByDim.error}
          empty={!analyticsQueryByDim.loading && !dimData?.name.length}
          onRefreshClick={() => loadBreakdownData(breakdownDimension)}
          rawData={dimData ?? { name: [] }}
          onTabChange={onBreakdownTabChange}
          currencySymbol={currencySymbol}
          onRowClick={onBreakdownRowClick(logger, breakdownDimension, setContext, !dimData)}
          onSortChange={logSortBreakdownAction(logger)}
          tabsConfig={tabsConfig}
          showMinorData={showMinorData}
        />
      </PageContent>
    </AnalyticsPage>
  )
}
export const ViewabilityPage = styled(_ViewabilityPage)``
