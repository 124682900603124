import { useAdUnits } from '~/api/adm-api.hook'

export const useGetBiddersWithOutstream = () => {
  const { all: allAdUnits } = useAdUnits(null)

  const getBiddersWithOutstream = async () => {
    //TODO 2024 02 14 TMU SLE project only biddersOutstream instead of pulling everything
    const a = (await allAdUnits.get())[0]
    return a ? a.biddersOutstream : []
  } // all current ad units have the same biddersOutstream
  return { isLoading: allAdUnits.loading, getBiddersWithOutstream }
}
