import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { GroupOverlap, RefreshGroup } from '@pubstack/common/src/refresh'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Checkbox } from '~/components/Checkbox'
import { toggleValueInArray } from '~/utils/array'

export type RefreshGroupDevice = RefreshGroup['devices'][number]

const OverlapLegendStyle = css`
  background-color: ${Colors.Mew};
  padding: 0 10px 0 10px;
  border-radius: 4px;
`

const RefreshGroupDeviceCheckbopx = styled(Checkbox)<{ overlap?: boolean }>`
  ${({ overlap }) =>
    overlap &&
    css`
      ${OverlapLegendStyle}
      padding: 4px 10px 4px 0;
    `}
`

export const RefreshGroupDevicesForm: FunctionComponent<{
  deviceList: RefreshGroupDevice[]
  refreshGroupDevices: RefreshGroupDevice[]
  updateRefreshGroup: (device: RefreshGroupDevice[]) => unknown
  overlap: GroupOverlap
}> = ({ deviceList, refreshGroupDevices, updateRefreshGroup, overlap }) => {
  // building checkboxes: we check ad units that are in refresh group's ones, then filter them according to the search value
  const options = deviceList.map((s) => ({ label: s.name, value: s.id, checked: refreshGroupDevices.some((rs) => rs.id === s.id) }))

  const onToggle = (device: RefreshGroupDevice) => {
    // toggling the device
    const newDevices = toggleValueInArray(refreshGroupDevices, device, 'id')

    // dispatch the new value
    updateRefreshGroup(newDevices)
  }

  const onSelectAll = () => {
    if (options.every((o) => o.checked)) {
      updateRefreshGroup([])
    } else {
      updateRefreshGroup(deviceList)
    }
  }

  return (
    <>
      <Checkbox checked={options.every((o) => o.checked)} label={'Select all'} onClick={onSelectAll} />
      {options.map((option, index) => (
        <RefreshGroupDeviceCheckbopx
          overlap={overlap.devices.includes(option.value) && option.checked}
          variant={overlap.devices.includes(option.value) && option.checked ? 'alert' : 'primary'}
          onClick={() => onToggle({ id: option.value, name: option.label })}
          key={`${option.value}${index}`}
          checked={option.checked}
          label={option.label}
        />
      ))}
    </>
  )
}
