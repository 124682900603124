import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Transitions } from '~/assets/style/tokens'
import { Icon } from '~/components/Icon'
import { WithClassName } from '~/types/utils'
import { TableColumns } from './Table'
import TableCell from './TableCell'
import TableRow from './TableRow'

const ChevronIcon = styled(Icon)``

const CollapsibleTableRow = styled(TableRow)<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    css`
      & > td {
        border-bottom: none !important;
      }
    `}

  & ${ChevronIcon} {
    transition: transform ${Transitions.quick};
    transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0')});
  }
`

const CollapsibleContent = styled(TableRow)<{ isOpen: boolean }>`
  &:hover {
    background: none;
  }

  & {
    display: none;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      & {
        display: table-row;
      }
    `}
`

const ChevronTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down */
  color: ${Colors.King};
`

type TableCollapsibleRowProps = WithClassName & {
  children: ReactNode
  content: ((toggleCollapse: () => void) => ReactNode) | ReactNode
  isOpen: boolean
  onToggleCollapse: () => void
}
const _TableCollapsibleRow: FunctionComponent<TableCollapsibleRowProps> = ({ className, children, content, isOpen, onToggleCollapse: clickOnCollapse }) => {
  return (
    <>
      <CollapsibleTableRow isOpen={isOpen} onClick={clickOnCollapse} className={className}>
        {children}
        <ChevronTableCell>
          <ChevronIcon name={'chevron_down'} />
        </ChevronTableCell>
      </CollapsibleTableRow>
      <CollapsibleContent isOpen={isOpen}>
        <TableCell colspan={999}>{typeof content === 'function' ? content(clickOnCollapse) : content}</TableCell>
      </CollapsibleContent>
    </>
  )
}

/**
 * An empty column is necessary to display the TableCollapsibleRow end of line chevron properly
 */
export const TableCollapsibleRowChevronColumn: TableColumns[number] = {
  name: '',
  isSortable: false,
}

/**
 * Use TableCollapsibleRowChevronColumn as the last column of your table to display correctly
 */
export const TableCollapsibleRow = styled(_TableCollapsibleRow)``
