import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Skeleton } from '~/components/Skeleton'
import { WithClassName } from '~/types/utils'
import { PocOverrideJSONEditor } from './PurePocOverrideJSONEditor'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

type PurePocOverridesConfigProps = WithClassName & { isLoading: boolean; isScopeConfigMissing: boolean; pocOverridesData: { [key: string]: any }; onUpdate: (value: { [key: string]: any }) => void }
const _PurePocOverridesConfig: FunctionComponent<PurePocOverridesConfigProps> = ({ className, isLoading, pocOverridesData, isScopeConfigMissing, onUpdate }) => {
  return (
    <Wrapper className={className}>
      <Message
        status={'warning'}
        title={'Warning'}
        description={
          <Wrapper>
            <span>Poc overrides edition, please be careful.</span>
            <span>
              Be wary that NO VALIDATION is done on{' '}
              <Link label={'specific POC OVERRIDES FORMAT'} href={'https://www.notion.so/Trials-and-Onboarding-Board-4bbe1c95898f44928d6a8f79a5a5efd5#49535502adb84cea829a134cba398a08'} />, only on
              JSON format.
            </span>
            <span>Double check everything you are doing.</span>
          </Wrapper>
        }
      />
      {isLoading ? (
        <Skeleton bigger />
      ) : isScopeConfigMissing ? (
        <Message status={'error'} title={'Error'} description={'Scope config is missing, please select a scope with a proper scope config.'} />
      ) : (
        <PocOverrideJSONEditor data={pocOverridesData} onValidate={onUpdate} statusUpdate={() => {}} />
      )}
    </Wrapper>
  )
}

export const PurePocOverridesConfig = styled(_PurePocOverridesConfig)``
