import styled from '@emotion/styled'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'

const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > * {
    display: inline-flex;
    flex-direction: column;
  }
`

export const PureSettingsSitesArchiveSiteModal: FunctionComponent<{ site: Site; onArchive: (site: Site) => unknown }> = ({ site, onArchive }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Archive site</Modal.Title>
      <Modal.Body>
        <Paragraphs>
          <p>
            <span>
              You are about to archive <strong>{site.name}</strong>
            </span>
            <span>You won’t be able to view its data in Pubstack anymore.</span>
          </p>
          <p>Do you confirm your choice ?</p>
        </Paragraphs>
      </Modal.Body>
      <Modal.Actions>
        <Button onClick={close} variant={'tertiary'}>
          Cancel
        </Button>
        <Button
          iconName={'archive'}
          onClick={() => {
            onArchive(site)
            close()
          }}
          variant={'negative'}
        >
          Archive site
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}
