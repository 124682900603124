import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, MappedName } from '@pubstack/common/src/analytics/query'
import { FunctionComponent } from 'react'
import { useRecoilState } from 'recoil'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { filterContextDimByName } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { OverviewRevenue } from './revenue/OverviewRevenue'
import { OverviewTimeline } from './timeline/OverviewTimeline'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 16px;
  }
`

type OverviewPageProps = WithClassName
const _OverviewPage: FunctionComponent<OverviewPageProps> = ({ className }) => {
  const logger = useLogger()
  const dimensions: Dimension[] = ['tagId', 'bidder', 'adUnit', 'device', 'country', 'size', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion']
  const dashboard: AnalyticsQueryDashboard = { name: 'overview', filterType: 'auctions' }
  const [context, setContext] = useRecoilState(contextState)
  const currencySymbol = useScopeCurrency()

  const breadcrumbs = useBreadcrumbs()

  const onValueSelect = (name: MappedName, dimension: Dimension) => {
    logger.info({ action: 'click', type: 'filter', name: dimension, data: name.value })
    setContext((context) => filterContextDimByName(context, name, dimension))
  }

  return (
    <AnalyticsPage
      className={className}
      onRefreshClick={() => {
        setContext({ ...context })
      }}
      filterDimensions={dimensions}
      dashboard={dashboard}
      comparedTo={true}
      title={breadcrumbs}
      showMinorDataToggle={true}
    >
      <PageContent>
        <OverviewTimeline context={context} dimensions={dimensions} currencySymbol={currencySymbol} />
        <OverviewRevenue context={context} dimensions={dimensions} currencySymbol={currencySymbol} onRevenueChartSelect={onValueSelect} />
      </PageContent>
    </AnalyticsPage>
  )
}
export const OverviewPage = styled(_OverviewPage)``
