import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

const CollapsiblePanelSubjectTitle = styled.span`
  ${Fonts.H2}
  display: inline-flex;
  align-items: center;
  gap: 4px;
  user-select: none;
  width: 100%; // TODO tmu sle 2024-02-15 - FIX ME - has impact on all collapsible with actions
`

const CollapsibleTriggerIcon = styled(Icon)``

const CollapsibleTrigger = styled.button<{ opened?: boolean }>`
  ${Fonts.P2}
  ${Fonts.colors.King}
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  display: inline-flex;
  align-items: center;

  :hover {
    ${Fonts.colors.Cobalt}
  }

  & ${CollapsibleTriggerIcon} {
    transition: transform ${Transitions.quick};
    transform: rotate(${({ opened }) => (opened ? '-180deg' : '0')});
  }
`

const CollapsiblePanelSubjectActions = styled.div`
  display: flex;
`
const CollapsiblePanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CollapsiblePanelWrapper = styled.div<{ isClickable?: boolean; isOpen: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.Platinum};
  border-radius: 4px;
  padding: 16px 12px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      padding-bottom: 0;
    `}
`

const CollapsibleContent = styled.div<{ isOpen: boolean; hasBorderWhenOpened?: boolean }>`
  cursor: auto;
  padding: 12px 0;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `}

  ${({ isOpen, hasBorderWhenOpened }) =>
    isOpen &&
    hasBorderWhenOpened &&
    css`
      margin-top: 16px;
      border-top: 1px solid ${Colors.Platinum};
    `}
`

const COLLAPSIBLE_STATE = {
  open: 'open',
  closed: 'closed',
}

type CollapsibleProps = WithClassName & {
  title: React.ReactNode
  icon?: IconName
  actions?: React.ReactNode
  isCollapsible?: boolean
  forceState?: keyof typeof COLLAPSIBLE_STATE
  onToggle?: (isOpen: boolean) => void
  hideCollapseIcon?: boolean
  defaultState?: keyof typeof COLLAPSIBLE_STATE
  canHideChildren?: boolean
  hasBorderWhenOpened?: boolean
}

const _CollapsiblePanel: FunctionComponent<PropsWithChildren<CollapsibleProps>> = ({
  className,
  children,
  isCollapsible,
  forceState,
  onToggle,
  actions,
  title,
  icon,
  hideCollapseIcon,
  defaultState,
  canHideChildren,
  hasBorderWhenOpened,
}) => {
  const [isOpen, setIsOpen] = useState(forceState === COLLAPSIBLE_STATE.open)

  const toggle = () => {
    onToggle && onToggle(!isOpen)
    !forceState && setIsOpen(!isOpen)
  }

  useEffect(() => {
    setIsOpen(forceState === COLLAPSIBLE_STATE.open)
  }, [forceState])

  useEffect(() => {
    if (defaultState !== undefined) {
      setIsOpen(defaultState === COLLAPSIBLE_STATE.open)
    }
  }, [defaultState])

  return (
    <CollapsiblePanelWrapper
      className={className}
      isClickable={isCollapsible && (!forceState || !!onToggle)}
      isOpen={isOpen}
      onClick={(event) => {
        event.preventDefault()
        isCollapsible && toggle()
      }}
    >
      <CollapsiblePanelHeader>
        <CollapsiblePanelSubjectTitle>
          {icon && <Icon name={icon} width={'22px'} />} {title}
        </CollapsiblePanelSubjectTitle>
        <CollapsiblePanelSubjectActions>
          {actions}
          {isCollapsible && !hideCollapseIcon && (
            <CollapsibleTrigger opened={isOpen}>
              <CollapsibleTriggerIcon width={'24px'} name={'chevron_down'} />
            </CollapsibleTrigger>
          )}
        </CollapsiblePanelSubjectActions>
      </CollapsiblePanelHeader>
      {((isCollapsible && (isOpen || canHideChildren)) || (!isCollapsible && children)) && (
        <CollapsibleContent
          hasBorderWhenOpened={hasBorderWhenOpened}
          isOpen={isOpen || (!isCollapsible && !!children && !canHideChildren && forceState !== COLLAPSIBLE_STATE.closed)}
          onClick={(event) => event?.stopPropagation()}
        >
          {children}
        </CollapsibleContent>
      )}
    </CollapsiblePanelWrapper>
  )
}

export const CollapsiblePanel = styled(_CollapsiblePanel)``
