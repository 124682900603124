import { AbTestConfiguration } from '@pubstack/common/src/abTest'
import { Alert } from '@pubstack/common/src/analytics/check'
import { AllDimensions, Dimension } from '@pubstack/common/src/analytics/dimension'
import { AllQuery, AnalyticsQueryArgs, AnalyticsQueryFilter, AnalyticsResponse, GenericTopValuesByDim } from '@pubstack/common/src/analytics/query'
import { defaultInterval, formatToDateWithoutTime, getNumberOfDays } from '@pubstack/common/src/date'
import { GamIntegration } from '@pubstack/common/src/gam/adx'
import { MarketplaceBidder } from '@pubstack/common/src/marketplaceBidder'
import { ExclusionFileUploadAction, ExclusionType, GroupOverlap, RefreshException, RefreshGroup } from '@pubstack/common/src/refresh'
import { Report } from '@pubstack/common/src/report'
import { Scope } from '@pubstack/common/src/scope'
import { Site } from '@pubstack/common/src/tag'
import { ScopeUser } from '@pubstack/common/src/user'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useFetch } from 'use-http'
import { Context, Filter, TimeRange } from '~/state'
import { isFilterAvailable } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useFetchWithException } from './api-access'
import { useCachedRequest } from './cache.hook'

export const useScopes = (deps: unknown[] | null = []) => {
  const fetchAll = useFetch<Scope[]>('/scopes', { suspense: true }, deps || undefined)
  const fetch = useFetch<Scope>('/scopes/scope', { suspense: true }, deps || undefined)
  return { all: { ...fetchAll }, byId: { ...fetch } }
}

export const useSites = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<Site[]>(`/tags`, deps || undefined)
  const getAll = (search: { getDisabledSites?: boolean; search?: string }) => {
    const paramsObj = new URLSearchParams()
    if (search.search?.length) {
      paramsObj.append('search', search.search)
    }
    if (search.getDisabledSites) {
      paramsObj.append('getDisabledSites', String(search.getDisabledSites))
    }
    return fetchAll.get(paramsObj.toString().length ? `?${paramsObj}` : '')
  }
  const fetch = useFetchWithException<Site>(`/tags`)
  const del = (site: Site) => fetch.delete(site.id)
  const put = (site: Site) => fetch.put(site.id, site)
  const getById = (id: Site['id']) => fetch.get(id)
  return { all: { ...fetchAll, get: getAll }, byId: { ...fetch, get: getById, put, del } }
}

export const useHubspotChatToken = (deps: unknown[] | null = []) => {
  return { byId: { ...useFetch('/users/hubspot-token', {}, deps || undefined) } }
}

export const useUserMetadata = (deps: unknown[] | null = []) => {
  return { byId: { ...useFetch('/users/me', {}, deps || undefined) } }
}

export const useUserData = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<ScopeUser[]>('/users', {}, deps || undefined)
  const fetch = useFetchWithException<ScopeUser>('/users', {}, deps || undefined)
  const post = (user: ScopeUser) => fetch.post(user)
  const put = (user: ScopeUser) => fetch.put(user.email, user)
  const del = (user: ScopeUser) => fetch.delete(user.email)
  return { all: { ...fetchAll }, byId: { ...fetch, post, put, del } }
}

export const useReports = (deps: unknown[] | null = []) => {
  const fetchAll = useFetch<Report[]>('/reports', {}, deps || undefined)
  const fetch = useFetch<Report>('/reports', {}, deps || undefined)
  return { all: { ...fetchAll }, byId: { ...fetch } }
}

export const useAnalyticsRequestEmailQuery = (deps: unknown[] | null = []) => {
  return { byId: { ...useFetch('/scopes/scope/request-analytics-sent', {}, deps || undefined) } }
}

const getFilterForLogging = (filters: AnalyticsQueryFilter[] = []) => {
  return filters.reduce((acc: { [key: string]: AnalyticsQueryFilter }, current) => {
    acc[current.dimension] = current
    return acc
  }, {})
}

const useLogAnalyticsQuery = () => {
  const logger = useLogger()
  return {
    info: (timeRange: TimeRange, path: string, timeBefore: DateTime, body?: { query: AllQuery; args: AnalyticsQueryArgs; context: { filters: AnalyticsQueryFilter[]; from: string; to: string } }) =>
      logger.info({
        queryFrom: timeRange.from.toSeconds(),
        queryTo: timeRange.to.toSeconds(),
        queryDays: getNumberOfDays(timeRange.from, timeRange.to),
        queryType: body?.query,
        queryDimension: body?.args?.dimension ?? defaultInterval(timeRange.from, timeRange.to),
        queryFilter: getFilterForLogging(body?.context.filters),
        time: DateTime.utc().diff(timeBefore).as('seconds'),
        url: path,
        type: 'queryDuration',
      }),
  }
}

const getAnalyticsQueryFiltersFromContext = (context: Context, dimensions: Dimension[] = [...AllDimensions]): AnalyticsQueryFilter[] =>
  (Object.entries(context.filters) as [Dimension, Filter][])
    .filter(([dimension]) => dimensions.includes(dimension))
    .filter(([_, filter]) => isFilterAvailable(filter, dimensions))
    .map(([dimension, filter]) => {
      return { dimension: dimension as Dimension, excluded: filter.mode === 'exclude', values: filter.values.map((v) => v.value) }
    })

export const useAnalyticsQuery = <T>(deps: unknown[] | null = [], dimensions: Dimension[], context: Context) => {
  const [loading, setLoading] = useState(false)
  const path = '/analytics/query'
  const fetch = useFetch<AnalyticsResponse<T>>('/analytics/query', {}, deps || undefined)
  const cachedRequest = useCachedRequest<AnalyticsResponse<T>>()
  const logger = useLogAnalyticsQuery()
  const analyticsQueryFilters: AnalyticsQueryFilter[] = getAnalyticsQueryFiltersFromContext(context, dimensions)
  const post = async (query: AllQuery, args: AnalyticsQueryArgs = {}) => {
    const body = {
      query,
      args,
      context: { filters: analyticsQueryFilters, from: formatToDateWithoutTime(context.timeRange.from), to: formatToDateWithoutTime(context.timeRange.to), tz: context.timeRange.tz },
    }
    setLoading(true)
    const timeBefore = DateTime.utc()
    const req = await cachedRequest({ ...body, path }, () => fetch.post(body))
    logger.info(context.timeRange, path, timeBefore, body)
    setTimeout(() => setLoading(false), 0)
    return req
  }
  return { byId: { ...fetch, loading, post } }
}

export const useAnalyticsSearch = (deps: unknown[] | null = [], dimensions: Dimension[] = [...AllDimensions], context: Context) => {
  const path = '/analytics/search'
  const fetch = useFetch(path, {}, deps || undefined)
  const cachedRequest = useCachedRequest<GenericTopValuesByDim>()
  const logger = useLogAnalyticsQuery()
  const analyticsQueryFilters: AnalyticsQueryFilter[] = getAnalyticsQueryFiltersFromContext(context, dimensions)
  const post = (args: AnalyticsQueryArgs & { term: string }) => {
    const body = {
      args,
      context: {
        filters: analyticsQueryFilters.filter((filter) => filter.dimension !== args.dimension),
        from: formatToDateWithoutTime(context.timeRange.from),
        to: formatToDateWithoutTime(context.timeRange.to),
        tz: context.timeRange.tz,
      },
    }
    const timeBefore = DateTime.utc()
    const genericTopValuesByDimPromise = cachedRequest({ ...body, path }, () => fetch.post(body))
    logger.info(context.timeRange, path, timeBefore)
    return genericTopValuesByDimPromise
  }
  return { search: post }
}

export const useAlerts = (deps: unknown[] | null = []) => {
  return { all: { ...useFetch<Alert[]>('/checks/backlog', {}, deps || undefined) } }
}

export const useTestEmail = (deps: unknown[] | null = []) => {
  const fetch = useFetch(`/users/me/send-alert-email`, deps || undefined)
  return { byId: { ...fetch } }
}

export const useRefreshGroups = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<RefreshGroup[]>('/refresh/group', deps || undefined)
  const fetch = useFetchWithException<RefreshGroup>('/refresh/group', deps || undefined)
  const checkFetch = useFetch<void | { conflicts: GroupOverlap }>('/refresh/group', deps || undefined)
  const getById = (groupId: string) => fetch.get(groupId)
  const check = (refreshGroup: RefreshGroup) => checkFetch.post('/check', refreshGroup)
  return { all: { ...fetchAll }, byId: { ...fetch, get: getById }, check }
}

export const useRefreshException = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<RefreshException>('/refresh/exceptions', deps || undefined)
  const fetchFiles = useFetchWithException<RefreshException>('/refresh/exceptions/files', deps || undefined)
  const downloadFiles = useFetchWithException<Buffer>('/refresh/exceptions/files', deps || undefined)

  const downloadFile = (type: ExclusionType) => {
    const params = new URLSearchParams()
    params.append('type', type)
    return downloadFiles.get(`?${params.toString()}`)
  }

  type RefreshExceptionFiles = {
    lineItemIdFile: File | null
    orderIdFile: File | null
    orderIdAction: ExclusionFileUploadAction
    lineItemIdAction: ExclusionFileUploadAction
    mode: string
  }

  /**
   * Takes care of updating exclusion files through sending multipart formData
   */
  const updateFiles = ({ lineItemIdFile, orderIdFile, orderIdAction, lineItemIdAction, mode }: RefreshExceptionFiles) => {
    const data = new FormData()
    data.append('orderIdAction', orderIdAction)
    data.append('lineItemIdAction', lineItemIdAction)
    if (orderIdFile) {
      data.append('orderIdFile', orderIdFile)
    }
    if (lineItemIdFile) {
      data.append('lineItemIdFile', lineItemIdFile)
    }
    data.append('mode', mode)
    return fetchFiles.post(data)
  }

  /**
   * Chains the two promises updating `refreshException` and its files separately and returning the last version of the updated `refreshException`
   */
  const updateRefreshException = async ({ refreshException, exceptionFiles }: { refreshException?: RefreshException; exceptionFiles?: RefreshExceptionFiles }) => {
    const promises: ((() => Promise<RefreshException>) | undefined)[] = [refreshException && (() => fetch.post(refreshException)), exceptionFiles && (() => updateFiles(exceptionFiles))]
    return promises.reduce(async (prev, cur) => prev.then(cur), Promise.resolve(refreshException))
  }

  return { byId: { ...fetch }, downloadFile, updateRefreshException, filesLoading: fetchFiles.loading, filesAbort: fetchFiles.abort }
}

export const useAbTest = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<AbTestConfiguration[]>('/ab-test-configuration', deps || undefined)
  const fetch = useFetchWithException<AbTestConfiguration>('/ab-test-configuration', deps || undefined)
  const toggleStatus = useFetchWithException<AbTestConfiguration>(`/ab-test-configuration/toggleStatus`, {}, deps || undefined)
  const getById = (abTestId: string) => fetch.get(abTestId)
  const toggleAbTest = (abTestId: string) => toggleStatus.put(abTestId)
  return { all: { ...fetchAll }, byId: { ...fetch, get: getById }, toggleAbTest }
}

export const useIntegration = (deps: unknown[] | null = []) => {
  return { byId: { ...useFetch<GamIntegration>(`/integrations/gam`, {}, deps || undefined) } }
}

export const useIntegrationMarketplace = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<MarketplaceBidder[]>('/marketplace/integration', deps || undefined)
  const post = (bidder: MarketplaceBidder) => fetch.post(bidder)
  return { all: { ...fetch }, byId: { post } }
}
