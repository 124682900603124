import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard } from '@pubstack/common/src/analytics/query'
import { FunctionComponent, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useUser } from '~/auth/user.hooks'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { onBreakdownRowClick } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { UserSessionBreakdown } from './UserSessionBreakdown'
import { UserSessionTimeline } from './UserSessionTimeline'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 16px;
  }
`

const SubTitle = styled.div`
  margin-top: 6px;
`

const useSubTitle = () => {
  const user = useUser()
  return useMemo(() => {
    if (!user) {
      return ''
    }
    return `Data displayed: Prebid${user.hasFeature('adx') ? ', AdX' : ''}${user.hasFeature('openBidding') ? ', OB' : ''}`
  }, [user])
}

type UserSessionPageProps = WithClassName
const _UserSessionPage: FunctionComponent<UserSessionPageProps> = ({ className }) => {
  const dimensions: Dimension[] = ['tagId', 'device', 'country']
  const dashboard: AnalyticsQueryDashboard = { name: 'user-session', filterType: 'auctions' }
  const currencySymbol = useScopeCurrency()
  const [context, setContext] = useRecoilState(contextState)

  const [breakdownDimension, setBreakdownDimension] = useState<Dimension>('tagId')

  const logger = useLogger()
  const onBreakdownTabChange = (dimension: Dimension) => {
    logger.info({ action: 'click', type: 'user-session-breakdown', detail: dimension })
    setBreakdownDimension(dimension)
  }

  const loadAllData = async () => {
    setContext({ ...context })
  }

  const subTitleMessage = useSubTitle()
  const breadcrumbs = useBreadcrumbs()
  const title = (
    <>
      {breadcrumbs}
      <SubTitle>{subTitleMessage}</SubTitle>
    </>
  )

  return (
    <AnalyticsPage title={title} className={className} onRefreshClick={loadAllData} filterDimensions={dimensions} dashboard={dashboard}>
      <PageContent>
        <UserSessionTimeline context={context} currencySymbol={currencySymbol} />
        <UserSessionBreakdown
          onRowClick={onBreakdownRowClick(logger, breakdownDimension, setContext)}
          onTabChange={onBreakdownTabChange}
          context={context}
          dimension={breakdownDimension}
          currencySymbol={currencySymbol}
        />
      </PageContent>
    </AnalyticsPage>
  )
}
export const UserSessionPage = styled(_UserSessionPage)``
