import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Transitions } from '~/assets/style/tokens'
import { Icon } from '~/components/Icon'
import { WithClassName } from '~/types/utils'

const Input = styled.input`
  opacity: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: absolute;
`

const Title = styled.span`
  grid-area: title;
  user-select: none;
  ${Fonts.P1};
  font-weight: 700;
`
const Description = styled.span`
  grid-area: description;
  user-select: none;
  ${Fonts.P2};
`

const BulletIcon = styled(Icon)`
  color: ${Colors.King};
  transition: ${Transitions.quick};
  margin-right: 4px;
  & svg path:first-of-type {
    fill: white;
    transition: ${Transitions.quick};
  }
`

const Wrapper = styled.label<{ checked: boolean }>`
  --background-color: ${Colors.White};
  --border-color: ${Colors.Platinum};
  width: 100%;
  box-sizing: border-box;
  margin: 0.5rem 0;
  background: var(--background-color);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 11px;
  cursor: pointer;
  transition: ${Transitions.quick};

  ${Fonts.colors.Jet};
  display: grid;
  grid-template-areas:
    'bullet title'
    'empty description';
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr;
  align-items: center;
  :hover {
    --background-color: ${Colors.Topaze};
    ${({ checked }) =>
      !checked &&
      css`
        ${BulletIcon} {
          color: ${Colors.Cobalt};
        }
      `}
  }

  :focus,
  :focus-visible,
  :focus-within {
    border: 1px solid var(--border-color);
    outline: none;
    box-shadow: 0 0 0 2px ${Colors.Topaze};
  }

  :active {
    --background-color: ${Colors.White};
    --border-color: ${Colors.King};
    ${BulletIcon} {
      color: ${Colors.King};
    }
  }

  ${({ checked }) =>
    checked &&
    css`
      --background-color: ${Colors.White};
      --border-color: ${Colors.King};
      ${BulletIcon} {
        color: ${Colors.King};
        & svg path:first-of-type {
          fill: currentColor;
        }
      }
    `}
`

export type PureReportsOptionProps = WithClassName & {
  checked: boolean
  title: string | ReactNode
  description: string | ReactNode
  onChange: (value: boolean) => void
  name: string
}
const _PureReportsOption: FunctionComponent<PureReportsOptionProps> = ({ className, title, checked, description, onChange, name }) => (
  <Wrapper className={className} checked={checked} tabIndex={0}>
    <Input type={'radio'} checked={checked} onChange={(e) => onChange(e.target.checked)} name={name} />
    <BulletIcon name={'radio'} width={'20px'} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
)
export const PureReportsOption = styled(_PureReportsOption)``
