import { ISODateTime } from './date'

export const ReportStatus = ['CREATED', 'FAILED', 'INPROGRESS'] as const
type Granularity = 'days' | 'months'
export type ReportPeriod = {
  unit: number
  granularity: Granularity
}

export const GranularityToUnit: { [key in Granularity]: 'day' | 'month' } = {
  days: 'day',
  months: 'month',
}

type ReportConfiguration = { maxSelectableIntervalInDays: number; maxRetentionInDays: number; title: string; description: string; defaultSelectedPeriod: ReportPeriod; pubstackOnly?: boolean }
export type ReportNameType =
  | 'GDPR Report'
  | 'Bidder Uplift'
  | 'Historical Data'
  | 'Price Bucket'
  | 'Floors'
  | 'Bids rate 0'
  | 'Data by url'
  | 'AdUnits & Bidders Homogeneity'
  | 'Sizes & Bidders Homogeneity'
  | 'Browser and Privacy Report'
  | 'Prebid Version Report'
  | 'SSPs Incremental Time'
  | 'Core Web Vitals'
  | 'Prebid mapping report (Pubstack only)'

export type ReportsConfiguration = { [key in ReportNameType]: ReportConfiguration }

/**
 * Caution! If you need to rename a report, see `documentation/modules/ROOT/pages/report-renaming.adoc`
 */
export const reportsConfiguration: ReportsConfiguration = {
  'GDPR Report': {
    maxSelectableIntervalInDays: 7,
    maxRetentionInDays: 40,
    defaultSelectedPeriod: { unit: 3, granularity: 'days' },
    title: 'GDPR Consent',
    description: 'Monitor GDPR consent status (Accepted/Refused/Not Available)',
  },
  'Bidder Uplift': {
    maxSelectableIntervalInDays: 30,
    maxRetentionInDays: 130,
    defaultSelectedPeriod: { unit: 7, granularity: 'days' },
    title: 'SSPs Incremental Value',
    description: 'Monitor unique incremental value from each SSP',
  },
  'Historical Data': {
    maxSelectableIntervalInDays: 31,
    maxRetentionInDays: 130,
    defaultSelectedPeriod: { unit: 1, granularity: 'months' },
    title: 'Historical data',
    description: 'Analyze websites and bidders historical impression and revenue',
  },
  'Price Bucket': {
    maxSelectableIntervalInDays: 7,
    maxRetentionInDays: 130,
    defaultSelectedPeriod: { unit: 7, granularity: 'days' },
    title: 'Price Bucket',
    description: 'Analyze your impressions and revenue distribution per price bucket',
  },
  Floors: {
    maxSelectableIntervalInDays: 7,
    maxRetentionInDays: 120,
    defaultSelectedPeriod: { unit: 7, granularity: 'days' },
    title: 'Floors',
    description: 'Provides an overview of existing floor prices',
  },
  'Bids rate 0': {
    maxSelectableIntervalInDays: 1,
    maxRetentionInDays: 2,
    defaultSelectedPeriod: { unit: 1, granularity: 'days' },
    title: '0% Bid Rate',
    description: 'Lists all instances where Bidders never respond',
  },
  'Data by url': {
    maxSelectableIntervalInDays: 15,
    maxRetentionInDays: 120,
    defaultSelectedPeriod: { unit: 15, granularity: 'days' },
    title: 'Data by url',
    description: 'Analyze your monetization performance at url level',
  },
  'AdUnits & Bidders Homogeneity': {
    maxSelectableIntervalInDays: 1,
    maxRetentionInDays: 2,
    defaultSelectedPeriod: { unit: 1, granularity: 'days' },
    title: 'AdUnits & Bidders Homogeneity',
    description: 'Quickly find missing SSPs at site x adunit level',
  },
  'Sizes & Bidders Homogeneity': {
    maxSelectableIntervalInDays: 1,
    maxRetentionInDays: 2,
    defaultSelectedPeriod: { unit: 1, granularity: 'days' },
    title: 'Sizes & Bidders Homogeneity',
    description: 'Quickly find missing sizes at site x adunit x bidder level',
  },
  'Browser and Privacy Report': {
    maxSelectableIntervalInDays: 3,
    maxRetentionInDays: 30,
    defaultSelectedPeriod: { unit: 1, granularity: 'days' },
    title: 'Browser & Privacy',
    description: 'Displays insights on your ID Solutions, browser and privacy',
  },
  'Prebid Version Report': {
    maxSelectableIntervalInDays: 3,
    maxRetentionInDays: 30,
    defaultSelectedPeriod: { unit: 1, granularity: 'days' },
    title: 'Prebid Version',
    description: 'Displays the Prebid versions in use by website',
  },
  'SSPs Incremental Time': {
    maxSelectableIntervalInDays: 1,
    maxRetentionInDays: 90,
    defaultSelectedPeriod: { unit: 1, granularity: 'days' },
    title: 'SSPs Incremental Time',
    description: 'Monitor the added latency each SSP is responsible for',
  },
  'Core Web Vitals': {
    maxSelectableIntervalInDays: 7,
    maxRetentionInDays: 90,
    defaultSelectedPeriod: { unit: 7, granularity: 'days' },
    title: 'Core Web Vitals',
    description: 'Get insights on your Core Web Vitals performances',
  },
  'Prebid mapping report (Pubstack only)': {
    maxSelectableIntervalInDays: 14,
    maxRetentionInDays: 30,
    defaultSelectedPeriod: { unit: 7, granularity: 'days' },
    title: 'Prebid mapping report (Pubstack only)',
    description: 'List all bidders and sizes called on Prebid ad units and the resulting impressions',
    pubstackOnly: true,
  },
} as const

export const PubstackOnlyReportNames: ReportNameType[] = Object.entries(reportsConfiguration)
  .filter(([, config]) => config.pubstackOnly)
  .map(([name]) => name) as ReportNameType[]

export interface Report {
  id: string
  reportName: ReportNameType
  startDate: ISODateTime
  endDate: ISODateTime
  timezone: string
  created: ISODateTime
  status: (typeof ReportStatus)[number]
  url?: string
}
export type NewReport = Omit<Report, 'id' | 'status' | 'created' | 'url'>
