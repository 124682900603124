import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, MouseEvent, PropsWithChildren } from 'react'
import { ColorUtil, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'

type TableRowProps = WithClassName & {
  onClick?: (e: MouseEvent<HTMLTableRowElement>) => void
  archived?: boolean
  className?: string
}

const Tr = styled.tr<{ isClickable: boolean; archived?: boolean }>`
  background: ${Colors.White};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};
  ${Fonts.colors.Jet};
  ${Fonts.P1};

  ${({ archived }) =>
    archived &&
    css`
      ${Fonts.colors.Silver};
    `}

  &:hover {
    background: linear-gradient(
      90deg,
      ${ColorUtil.hexOpacity(Colors.Platinum, 0.2)} 0%,
      ${ColorUtil.hexOpacity(Colors.Platinum, 0.3)} 20%,
      ${ColorUtil.hexOpacity(Colors.Platinum, 0.3)} 80%,
      ${ColorUtil.hexOpacity(Colors.Platinum, 0.2)} 100%
    );
  }
`

export const _TableRow: FC<PropsWithChildren<TableRowProps>> = ({ children, onClick, archived, className }) => (
  <Tr isClickable={!!onClick && !archived} onClick={(e) => onClick && onClick(e)} className={className} archived={archived}>
    {children}
  </Tr>
)

const TableRow = styled(_TableRow)``

export default TableRow
