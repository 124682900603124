import { ContextKey } from '@pubstack/common/src/contextKey'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useContextKeys } from '~/api/adm-api.hook'
import { useUser } from '~/auth/user.hooks'
import { useToast } from '~/components/Toast/useToasts'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import PureAdStackContextKeyEditPage from './PureAdStackContextKeyEditPage'

const emptyContextKey: ContextKey = {
  name: '',
  values: [],
  defaultValue: '',
}
export const AdStackContextKeyEditPage = () => {
  const navigate = useNavigate()
  const [contextKey, setContextKey] = useState<ContextKey>(emptyContextKey)
  const { contextKeyName } = useParams()
  const toast = useToast()
  const { byId, validateName } = useContextKeys(null)
  const breadcrumbs = useBreadcrumbs(contextKey)
  const user = useUser()

  async function loadContextKey(contextKeyName?: string) {
    if (contextKeyName) {
      try {
        const contextKeyToUpdate = await byId.get(contextKeyName)
        contextKeyToUpdate.values.sort((a, b) => b.localeCompare(a))
        setContextKey(contextKeyToUpdate)
      } catch (exception) {
        toast.alert('An error occurred while fetching this context key.')
        throw exception
      }
    }
  }

  async function createOrUpdateContextKey(contextKey: ContextKey) {
    try {
      const newContextKey: ContextKey = await byId.put(contextKey)
      toast.success(`${newContextKey.name} added to your inventory.`)
      navigate('/adstack/context/context-keys')
    } catch (exception) {
      toast.alert('An error occurred while saving this contextKey.')
      throw exception
    }
  }

  const validateContextKeyUniqueName = async (name?: string) => {
    return name ? validateName(name) : true
  }
  useEffect(() => {
    loadContextKey(contextKeyName)
  }, [contextKeyName])

  return (
    <PureAdStackContextKeyEditPage
      isEditing={!!contextKey?.name}
      contextKey={contextKey}
      breadcrumbs={breadcrumbs}
      createOrUpdateContextKey={createOrUpdateContextKey}
      onBack={() => navigate(-1)}
      validateUniqueName={validateContextKeyUniqueName}
      isAdmin={user ? user.isAdmin : false}
    />
  )
}
