import { AbTestConfiguration } from '@pubstack/common/src/abTest'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { FetchError } from '~/api/api-access'
import { useAbTest } from '~/api/api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { currentScopeState } from '~/state'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAddAbTestPage } from './PureAddAbTestPage'

export type AbTestFormData = AbTestConfiguration

export const AddAbTestPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const currentScope = useRecoilValue(currentScopeState)
  const { all: allAbTests, byId: abTestsById, toggleAbTest } = useAbTest(null)
  const [abTests, setAbTests] = useState<AbTestConfiguration[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [createdAbTest, setCreatedAbTest] = useState<AbTestConfiguration | undefined>()

  const toast = useToast()

  useEffect(() => {
    loadAbTests()
  }, [])

  const loadAbTests = async () => {
    const allScopeAbTests = await allAbTests.get()
    setAbTests(allScopeAbTests)
  }

  const onSave = async (data: AbTestFormData) => {
    const newAbTest: AbTestConfiguration = {
      ...data,
      variants: data.variants.map(({ variant }) => ({ variant: variant.trim() })),
      scopeId: currentScope.id,
    }
    const active = abTests.find((value) => value.active)
    try {
      const createdAbTest = await abTestsById.post({ ...newAbTest })
      setCreatedAbTest(createdAbTest)
      if (active) {
        setIsModalOpen(true)
      } else {
        const newUpdateAbTest = await toggleAbTest(createdAbTest.id)
        if (newUpdateAbTest) {
          navigateToView(newUpdateAbTest?.id)
        }
      }
    } catch (e) {
      if (e instanceof FetchError) {
        const error = JSON.parse(e.message)
        toast.alert(error.data.message)
      } else {
        toast.alert('An error occurred while saving your A/B test, please try again later.')
      }
    }
  }

  async function activateAbTest() {
    if (createdAbTest) {
      const activeABtest = await toggleAbTest(createdAbTest.id)
      toast.success('A/B test activated.')
      if (activeABtest) {
        navigateToView(activeABtest?.id)
        setIsModalOpen(false)
      }
      setCreatedAbTest(undefined)
    }
  }

  const closeModal = (id?: string) => {
    setIsModalOpen(false)
  }
  const navigateToView = (id?: string) => {
    if (id) {
      navigate(`/settings/ab-test/view/${id}`)
    }
  }

  return (
    <PureAddAbTestPage
      breadcrumbs={breadcrumbs}
      onSave={onSave}
      isOpenModal={isModalOpen}
      createdAbTest={createdAbTest}
      onRequestClose={closeModal}
      onActivate={activateAbTest}
      navigateToView={navigateToView}
    />
  )
}
