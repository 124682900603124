import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { NavigateFunction } from 'react-router-dom'
import { Link } from '../Link'
import { ToastHandlerMessage, ToastOptions } from './ToastConfig'
import { ToastMessage } from './ToastMessage'

type ToastHandler = (message: ToastHandlerMessage, options?: ToastOptions) => void
type ToastAdmSuccess = (name: string, stacksImpacted?: boolean, navigate?: NavigateFunction, options?: ToastOptions) => void
type ToastHook = ToastHandler & { success: ToastHandler; alert: ToastHandler; dismiss: (toastId?: string) => void; warn: ToastHandler; admSuccess: ToastAdmSuccess }

export const useToast = (): ToastHook => {
  const DEFAULT_TOAST_TIMER = 4000
  const DEFAULT_TOAST_ERROR_TIMER = 6000

  const toastSuccess: ToastHandler = useCallback((message, options) => {
    const toastOptions: ToastOptions = {
      id: options?.id,
      icon: options?.icon ?? 'check_circled',
      duration: options?.duration ?? DEFAULT_TOAST_TIMER,
    }
    toast.success(<ToastMessage message={message} />, toastOptions)
  }, [])

  const toastAlert: ToastHandler = useCallback((message, options) => {
    const toastOptions: ToastOptions = {
      id: options?.id,
      icon: options?.icon ?? 'alert',
      duration: options?.duration ?? DEFAULT_TOAST_ERROR_TIMER,
    }
    toast.error(<ToastMessage message={message} />, toastOptions)
  }, [])

  const toastNeutral: ToastHandler = useCallback((message, options) => {
    const toastOptions: ToastOptions = {
      id: options?.id,
      icon: options?.icon,
      duration: options?.duration ?? DEFAULT_TOAST_TIMER,
    }
    toast(<ToastMessage message={message} iconFillColor={options?.iconFillColor} />, toastOptions)
  }, [])

  const toastWarning: ToastHandler = useCallback((message, options) => {
    const toastOptions: ToastOptions = {
      id: options?.id,
      icon: options?.icon ?? 'warning',
      duration: options?.duration ?? DEFAULT_TOAST_TIMER,
      type: 'warning',
    }
    toast(<ToastMessage message={message} />, toastOptions)
  }, [])

  const toastAdmSuccess: ToastAdmSuccess = useCallback((name, stacksImpacted, navigate, options) => {
    const toastOptions: ToastOptions = {
      id: options?.id,
      icon: options?.icon ?? 'check_circled',
      duration: options?.duration ?? stacksImpacted ? 10000 : DEFAULT_TOAST_TIMER,
    }

    toast.success(
      <ToastMessage
        message={
          <>
            <strong>{name}</strong> sucessfully updated !
            {stacksImpacted && (
              <div>
                Some stacks are impacted by this change. They need to be updated and deployed again. Find them in the “out-of-sync” section of the{' '}
                <Link onClick={() => navigate && navigate('/adstack/control-center')} label={'control center'} />.
              </div>
            )}
          </>
        }
      />,
      toastOptions
    )
  }, [])

  return useCallback(Object.assign(toastNeutral, { success: toastSuccess, alert: toastAlert, dismiss: toast.dismiss, warn: toastWarning, admSuccess: toastAdmSuccess }), [])
}
