import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { OverviewMetricsByTime, TopRevenuesByDim } from '@pubstack/common/src/analytics/query'
import { useState } from 'react'
import { useAnalyticsQuery } from '~/api/api.hook'
import { displayTimeshift } from '~/modules/analytics/PureAnalyticsPage'
import { Context } from '~/state'

export const useTimelineQueries = (context: Context, dimensions: Dimension[]) => {
  const [timeData, setTimeData] = useState<OverviewMetricsByTime | undefined>(undefined)
  const { byId: analyticsQueryTime } = useAnalyticsQuery<OverviewMetricsByTime>(null, dimensions, context)
  const [timeComparisonData, setTimeComparisonData] = useState<OverviewMetricsByTime | undefined>(undefined)
  const { byId: analyticsQueryTimeComparison } = useAnalyticsQuery<OverviewMetricsByTime>(null, dimensions, {
    ...context,
    timeRange: {
      ...context.timeRange,
      from: context.timeRange.from.minus({ weeks: 1 }),
      to: context.timeRange.to.minus({ weeks: 1 }),
    },
  })

  const loadTimeLineData = async () => {
    if (analyticsQueryTime.loading) {
      analyticsQueryTime.abort()
    }
    const d = await analyticsQueryTime.post('overview.metrics.by.time', {})
    setTimeData(d.values)
  }
  const loadTimeLineComparisonData = async () => {
    if (displayTimeshift(context.timeRange.from, context.timeRange.to)) {
      if (analyticsQueryTimeComparison.loading) {
        analyticsQueryTimeComparison.abort()
      }
      const d = await analyticsQueryTimeComparison.post('overview.metrics.by.time', {})
      setTimeComparisonData(d.values)
    } else {
      setTimeComparisonData(undefined)
    }
  }

  const loadAllData = async () => {
    loadTimeLineComparisonData()
    loadTimeLineData()
  }

  return {
    timelineMain: {
      data: timeData,
      loading: analyticsQueryTime.loading,
      error: analyticsQueryTime.error,
      load: loadTimeLineData,
    },
    timelineComparison: {
      data: timeComparisonData,
      loading: analyticsQueryTimeComparison.loading,
      error: analyticsQueryTimeComparison.error,
      load: loadTimeLineComparisonData,
    },
    timelineLoadAll: loadAllData,
  }
}

export const useRevenueQuery = (dimension: Dimension, context: Context, dimensions: Dimension[]) => {
  const { byId: analyticsQuery } = useAnalyticsQuery<TopRevenuesByDim>(null, dimensions, context)
  const [data, setData] = useState<TopRevenuesByDim | undefined>(undefined)

  const load = async () => {
    if (analyticsQuery.loading) {
      analyticsQuery.abort()
    }
    const d = await analyticsQuery.post('top.revenues.by.dim', { dimension })
    setData(d.values)
  }

  const loading = analyticsQuery.loading
  const error = analyticsQuery.error

  return { data, loading, error, load }
}
