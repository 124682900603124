import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { Checkbox } from '~/components/Checkbox'
import { FilterCategoryDataItem } from '~/types/analytics'
import { WithClassName } from '~/types/utils'

type FilterSidebarCategoryItemProps = WithClassName &
  Omit<FilterCategoryDataItem, 'key'> & {
    onCheckboxClick: () => void
    onLabelClick: () => void
  }
const FilterSidebarCategoryItemLabel = styled.div`
  grid-area: label;
  flex: 1 1 auto;
  margin: 0 5px 0 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const FilterSidebarCategoryItemSubLabel = styled.div`
  grid-area: sublabel;
  flex: 1 1 auto;
  margin: 0 5px 0 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${Fonts.P3};
  ${Fonts.colors.Ash};
`
const FilterSidebarCategoryItemValue = styled.div`
  grid-area: count;
  padding: 0 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80px;
  min-width: 40px;
  text-align: right;
  flex: 1 0 auto;
`
const FilterSidebarCategoryItemWrapper = styled.div<{ hasSubLabel: boolean }>`
  display: grid;
  ${({ hasSubLabel }) =>
    hasSubLabel
      ? css`
          grid-template-areas:
            'checkbox label count'
            '. sublabel .';
          grid-template-rows: 1fr 1fr;
        `
      : css`
          grid-template-areas: 'checkbox label count';
          grid-template-rows: 1fr;
        `}
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  margin: 0 0 12px 0;
  cursor: pointer;
  ${Fonts.P2};
  ${Fonts.colors.Jet};
  clip-path: inset(0px 0px -2px 0px);
  box-sizing: border-box;

  ${Checkbox} {
    grid-area: checkbox;
  }
`

const _FilterSidebarCategoryItem: FunctionComponent<FilterSidebarCategoryItemProps> = ({ className, checked, value, formattedValue, label, subLabel, onCheckboxClick, onLabelClick }) => (
  <FilterSidebarCategoryItemWrapper className={className} hasSubLabel={!!(subLabel || '').trim()}>
    <Checkbox checked={checked} onChange={onCheckboxClick} />
    <FilterSidebarCategoryItemLabel title={label} onClick={onLabelClick}>
      {label}
    </FilterSidebarCategoryItemLabel>
    {!!(subLabel || '').trim() && (
      <FilterSidebarCategoryItemSubLabel title={subLabel} onClick={onLabelClick}>
        {subLabel}
      </FilterSidebarCategoryItemSubLabel>
    )}
    <FilterSidebarCategoryItemValue title={'' + value} onClick={onLabelClick}>
      {formattedValue}
    </FilterSidebarCategoryItemValue>
  </FilterSidebarCategoryItemWrapper>
)
export const FilterSidebarCategoryItem = styled(_FilterSidebarCategoryItem)``
