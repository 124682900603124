import { Global } from '@emotion/react'
import * as Sentry from '@sentry/react'
import { DateTime } from 'luxon'
import { Suspense, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil'
import { HttpProvider } from './api/HttpProvider'
import globalStyle from './assets/style/global'
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory'
import { Context, configurationState, contextState, loadingState } from './state'
import { routes } from './utils/router'
import { useUrlSync } from './utils/useUrlSync'
// eslint-disable-next-line import/no-unresolved
import 'virtual:vite-plugin-sentry/sentry-config'

export const transactionId = Math.random().toString(36).substring(2, 9)

if (['app.dev.pubstack.io', 'app.beta.pubstack.io', 'app.pubstack.io'].includes(window.location.host)) {
  // now you can use this variable like so
  const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release

  Sentry.init({
    dsn: 'https://0cff9748601d4704819c98b849d07535@o454756.ingest.sentry.io/5447903',
    integrations: [Sentry.browserTracingIntegration()],
    release,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: window.location.host,
  })

  Sentry.getCurrentScope().setTag('transaction_id', transactionId)
}

function SyncApp() {
  const [context, setContext] = useRecoilState(contextState)
  const [queries] = useUrlSync()
  useEffect(() => {
    const newContext: Context = {
      filters: queries['ctx'] ? JSON.parse(decodeURIComponent(atob(queries['ctx']))) : {},
      timeRange: {
        ...context.timeRange,
        from: queries['from'] ? DateTime.fromISO(queries['from']) : DateTime.local().startOf('day'),
        to: queries['to'] ? DateTime.fromISO(queries['to']) : DateTime.local().endOf('day'),
      },
    }
    setContext(newContext)
  }, [])

  return null
}

function AppDataLoader() {
  useRecoilValue(configurationState)

  return (
    <Auth0ProviderWithHistory>
      <HttpProvider>
        <RouterProvider router={createBrowserRouter(routes)} />
      </HttpProvider>
      <Global styles={[globalStyle]} />
    </Auth0ProviderWithHistory>
  )
}

const BaseLoader = () => (
  <div className={'loader-parent'}>
    <img id={'main-loading'} src={'/picto_400x400.svg'} width={'80px'} height={'80px'} alt={'pubstack loading rotating logo'} />
    <img src={'/Pubstack_Logo.svg'} alt={'pustack name logo'} />
  </div>
)

const Loader = () => {
  const loading = useRecoilValue(loadingState)
  return loading ? <BaseLoader /> : null
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)
root.render(
  <RecoilRoot>
    <Suspense fallback={<BaseLoader />}>
      <Loader />
      <SyncApp />
      <AppDataLoader />
    </Suspense>
  </RecoilRoot>
)
