import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { LEADERBOARD, SKIN_SINGLE_AD } from '@pubstack/common/src/adFormat'
import { capitalizeFirstLetter } from '@pubstack/common/src/stringUtils'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { AdUnitForm } from '~/modules/adstack/inventory/adunits/PureAdStackAdUnitEditPage'
import { WithClassName } from '~/types/utils'

const Card = styled.div`
  background-color: ${Colors.White};
  width: 184px;
  border-radius: 4px;
  position: sticky;
  top: 20px;
`

const Content = styled.div`
  padding: 12px 6px 12px 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Label = styled.span<{ isDefined: boolean }>`
  ${Fonts.P3};
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ isDefined }) =>
    !isDefined &&
    css`
      color: ${Colors.Ash};
      font-style: italic;
    `}
`

const Title = styled.div`
  ${Fonts.H2};
`
const SubTitle = styled.div`
  ${Fonts.P2};
  font-weight: 700;
`
const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const AdFormatMediatype = styled.span<{ isPresent: boolean }>`
  color: ${({ isPresent }) => (isPresent ? Colors.Jet : Colors.Silver)};
`
const pipeSeparator = `\xa0 | \xa0`

export type SummaryCardProps = WithClassName & {
  adUnit: AdUnitForm
}

const _SummaryCard: FunctionComponent<SummaryCardProps> = ({ className, adUnit }) => {
  const sizesSum = adUnit.sizes.length + adUnit.customSizes.length
  return (
    <Card className={className}>
      <Content>
        <Title>Summary</Title>
        <Block>
          <SubTitle>Identification</SubTitle>
          <Label isDefined={!!adUnit.name}>{adUnit.name ? adUnit.name : 'Name'}</Label>
          <Label isDefined={!!adUnit.adServerAdUnitName}>{adUnit.adServerAdUnitName ? adUnit.adServerAdUnitName : 'Ad unit code'}</Label>
          <Label isDefined={!!adUnit.divId}>{adUnit.divId ? adUnit.divId : 'Technical identification (div ID)'}</Label>
        </Block>
        <Block>
          <SubTitle>Devices</SubTitle>
          <Label isDefined={adUnit.devices.length > 0}>{adUnit.devices.length > 0 ? adUnit.devices.map(capitalizeFirstLetter).join(pipeSeparator) : 'Not selected'}</Label>
        </Block>
        <Block>
          <SubTitle>Format</SubTitle>
          <Label isDefined={!!adUnit.adFormat}>{adUnit.adFormat ? adUnit.adFormat.name : 'Not selected'}</Label>
        </Block>
        <Block>
          <SubTitle>Additional behaviour</SubTitle>
          {!!adUnit.adFormat && <Label isDefined={adUnit.dynamicEnabled}>{adUnit.dynamicEnabled ? 'Dynamic enabled' : 'Dynamic disabled'}</Label>}
          {!!adUnit.adFormat && <Label isDefined={!!adUnit.anchor?.mode}>{adUnit.anchor ? 'Anchor enabled' : 'Anchor disabled'}</Label>}
          {!!adUnit.adFormat && <Label isDefined={!!adUnit.parallaxEnabled}>{adUnit.parallaxEnabled ? 'Parallax enabled' : 'Parallax disabled'}</Label>}
        </Block>
        <Block>
          <SubTitle>Demand source</SubTitle>
          <Label isDefined={!!adUnit.adFormat}>{adUnit.adFormat ? `Header bidding ${adUnit.headerBiddingEnabled ? 'enabled' : 'disabled'}` : 'Not selected'}</Label>
        </Block>
        <Block>
          <SubTitle>Mediatypes</SubTitle>
          {adUnit.adFormat ? (
            <>
              {adUnit.adFormat.mediatypes.includes('Banner') && (
                <>
                  <Label isDefined={true}>Banner {adUnit.bannerEnabled ? `enabled ${sizesSum === 0 ? '' : `(${sizesSum} size${sizesSum > 1 ? 's' : ''})`}` : 'disabled'}</Label>
                  {adUnit.bannerEnabled && (
                    <>
                      {adUnit.adFormat && (adUnit.adFormat.id === LEADERBOARD.id || adUnit.adFormat.id === SKIN_SINGLE_AD.id) && (
                        <Label isDefined={true}>Skin {adUnit.skinEnabled ? 'enabled' : 'disabled'}</Label>
                      )}
                      <Label isDefined={true}>Fluid (GAM) {adUnit.fluid ? 'enabled' : 'disabled'}</Label>
                    </>
                  )}
                </>
              )}
              {adUnit.adFormat.mediatypes.includes('Outstream') && <Label isDefined={true}>Outstream {adUnit.canOutstream ? 'enabled' : 'disabled'}</Label>}
            </>
          ) : (
            <Label isDefined={false}>Not selected</Label>
          )}
        </Block>
        <Block>
          <SubTitle>Key-values</SubTitle>
          {!!adUnit.slotKeyValues && adUnit.slotKeyValues?.length > 0 ? (
            <Label isDefined={true}>
              {adUnit.slotKeyValues?.map((kv) => (
                <div key={kv.key}>{kv.key + ' (' + kv.values.length + ' values)'}</div>
              ))}
            </Label>
          ) : (
            <Label isDefined={false}>No key-values</Label>
          )}
        </Block>
      </Content>
    </Card>
  )
}

export const SummaryCard = styled(_SummaryCard)``
