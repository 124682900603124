import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { ColorUtil, Colors } from '~/assets/style/colors'
import { ScrollbarStyle } from '~/assets/style/utils'
import { Checkbox } from '~/components/Checkbox'
import { Input, _Input } from '~/components/Input'

type ComboCheckboxOptions = { label: string; value: string; checked: boolean }

const ComboSearchWrapper = styled.div`
  padding: 16px;
  box-shadow: 0px 3px 10px 0px ${ColorUtil.hexOpacity(Colors.Jet, 0.4)};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  align-self: stretch;

  & ${_Input} {
    max-width: 350px;
  }
`

const SearchInputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 15px;
`

const ComboSearchResult = styled.div`
  /** calculating 4 columns with 12px gap and 12px right margin */
  width: calc(100% / 4 - 12px / 4 - 12px);
  box-sizing: border-box;

  overflow: hidden;
  white-space: nowrap;
  /** TODO tmu nra 2022-10-26 ellipsis truncation does not work because there is no set size on the checkbox's label, to be discussed */
  text-overflow: ellipsis;
  box-sizing: border-box;
`

const ComboSearchOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  /** 8 lines maximum + 12px gap, with 12px extra to show there's something to scroll down to */
  max-height: calc((20px + 12px) * 8 + 12px);
  overflow-y: auto;
  ${ScrollbarStyle}
`

const OverlapLegendStyle = css`
  background-color: ${Colors.Mew};
  padding: 0 10px 0 10px;
  border-radius: 4px;
`

const ComboSearchCheckbox = styled(Checkbox)<{ overlap?: boolean }>`
  ${({ overlap }) =>
    overlap &&
    css`
      ${OverlapLegendStyle}
      padding: 4px 10px 4px 0;
    `}
`

export const RefreshGroupComboSearch: FunctionComponent<{
  options: ComboCheckboxOptions[]
  onSearch: (value: string) => unknown
  onCheckboxToggle: (index: number) => unknown
  onAllCheckboxToggle: () => unknown
  overlapIds: string[]
}> = ({ options, onSearch, onAllCheckboxToggle, onCheckboxToggle, overlapIds }) => {
  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })
  const [lastSearch, setLastSearch] = useState('')

  const search = useWatch({ control, name: 'search' })
  useEffect(() => {
    if (lastSearch !== search) {
      onSearch(search)
      setLastSearch(search)
    }
  }, [search])

  return (
    <ComboSearchWrapper>
      <SearchInputWrapper>
        <Input label={'Search'} control={control} name={'search'} iconLeft={'search'} labelIsPlaceholder />
      </SearchInputWrapper>
      {options.length ? <Checkbox checked={options.every((o) => o.checked)} label={'Select all'} onClick={onAllCheckboxToggle} /> : <span>No results.</span>}
      <ComboSearchOptionsWrapper>
        {options.map((option, index) => (
          <ComboSearchResult key={index}>
            <ComboSearchCheckbox
              overlap={overlapIds.includes(option.value) && option.checked}
              variant={overlapIds.includes(option.value) && option.checked ? 'alert' : 'primary'}
              onClick={() => onCheckboxToggle(index)}
              key={`${option.value}${index}`}
              checked={option.checked}
              label={option.label}
            />
          </ComboSearchResult>
        ))}
      </ComboSearchOptionsWrapper>
    </ComboSearchWrapper>
  )
}
