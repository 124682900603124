import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { HeaderBiddingRule } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCatalogItems, useHeaderBiddingRules } from '~/api/adm-api.hook'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackHeaderBiddingPage } from './PureAdStackHeaderBiddingPage'

export type HeaderBiddingRuleWithBidders = Omit<HeaderBiddingRule, 'creationTime' | 'technicalId' | 'scopeId'> & {
  bidderAdapters: BidderAdapter[]
  numberOfBidderAdapters: number
}

export const AdStackHeaderBiddingPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const [headerBiddingRules, setHeaderBiddingRules] = useState<HeaderBiddingRuleWithBidders[]>([])

  const { all: allHeaderBiddingRules } = useHeaderBiddingRules(null)
  const { all: allCatalogItems } = useCatalogItems(null)

  const loadHeaderBiddingRules = async () => {
    if (allHeaderBiddingRules.loading) {
      allHeaderBiddingRules.abort()
    }
    if (allCatalogItems.loading) {
      allCatalogItems.abort()
    }
    const headerBiddingRules = await allHeaderBiddingRules.get()
    const allItems = await allCatalogItems.get()
    const bidderAdapters = allItems.filter((m) => m.isUsed && m.type === 'prebidBidder')
    const headerBiddingRulesWithBidders = headerBiddingRules.map((headerBiddingRule) => {
      const bidderAdaptersForRule = headerBiddingRule.isBlockList
        ? bidderAdapters.filter((bidderAdapter) => !headerBiddingRule.bidderNames.includes(bidderAdapter.code))
        : bidderAdapters.filter((bidderAdapter) => headerBiddingRule.bidderNames.includes(bidderAdapter.code))
      return {
        ...headerBiddingRule,
        bidderAdapters: bidderAdaptersForRule,
        numberOfBidderAdapters: bidderAdaptersForRule.length,
      }
    })
    setHeaderBiddingRules(headerBiddingRulesWithBidders)
  }

  useEffect(() => {
    loadHeaderBiddingRules()
  }, [])

  return (
    <PureAdStackHeaderBiddingPage
      headerBiddingRules={headerBiddingRules}
      isLoading={allHeaderBiddingRules.loading || allCatalogItems.loading}
      breadcrumbs={breadcrumbs}
      onCreateRule={() => navigate(`/adstack/header-bidding/new`)}
      onEditRule={(id: string) => navigate(`/adstack/header-bidding/edit/${id}`)}
    />
  )
}
