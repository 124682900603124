import { Site } from '@pubstack/common/src/tag'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSites } from '~/api/api.hook'
import { useToast } from '~/components/Toast/useToasts'

const emptySite: Site = {
  id: '',
  name: '',
  adManagementEnabled: false,
}

export const useSiteWithRoute = (): { site: Site; setSite: React.Dispatch<React.SetStateAction<Site>>; siteId?: string } => {
  const { siteId } = useParams()
  const [site, setSite] = useState<Site>(emptySite)
  const { byId: sitesById } = useSites(null)
  const toast = useToast()

  async function loadSite(siteId?: string) {
    if (siteId) {
      try {
        const fetchSite = await sitesById.get(siteId)
        setSite(fetchSite)
      } catch (exception) {
        toast.alert('An error occurred while fetching this site.')
        throw exception
      }
    }
  }
  useEffect(() => {
    loadSite(siteId)
  }, [siteId])

  return { site, setSite, siteId }
}
