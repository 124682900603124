import styled from '@emotion/styled'
import { MappedName, TopRevenuesByDim } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useEffect, useMemo } from 'react'
import Chart, { ChartWrapperOptions, ReactGoogleChartEvent } from 'react-google-charts'
import { Legend } from '~/components/Legend'
import { Widget, WidgetProps } from '~/components/Widget'
import { AnalyticsDefaultChartOptions, ChartWrapper, Legends } from '~/modules/analytics/AnalyticsCharts'
import { adxImpressionRevenue, allImpressionRevenue, asPercentage, hbImpressionRevenue, openBiddingImpressionRevenue } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_CHART_COLOR_CONFIG } from '~/utils/analytics'
import { displayWithCurrency } from '~/utils/string'
import { RevenueDataConfig } from './PureOverviewRevenue.types'
import { getChartTooltip } from './PureOverviewRevenueTooltip'

const ChartContainer = styled.div`
  flex: 1 1 auto;

  & > div {
    height: 230px;
  }
`

const WidgetTitle = styled.div`
  span {
    font-weight: bold;
  }
`

const getChartDataConfig = (rawData: TopRevenuesByDim): RevenueDataConfig[] =>
  [
    {
      name: ANALYTICS_CHART_COLOR_CONFIG['hb'].title,
      color: ANALYTICS_CHART_COLOR_CONFIG['hb'].color,
      formula: hbImpressionRevenue,
    },
    {
      name: ANALYTICS_CHART_COLOR_CONFIG['adx'].title,
      color: ANALYTICS_CHART_COLOR_CONFIG['adx'].color,
      formula: adxImpressionRevenue,
    },
    {
      name: ANALYTICS_CHART_COLOR_CONFIG['openBidding'].title,
      color: ANALYTICS_CHART_COLOR_CONFIG['openBidding'].color,
      formula: openBiddingImpressionRevenue,
    },
  ].filter((dataConfig) => dataConfig.formula.isComputable(rawData))

const computeOthersData = ({
  rawData,
  revenueDisplayThreshold,
  currencySymbol,
  chartDataConfig,
}: {
  rawData: TopRevenuesByDim
  revenueDisplayThreshold: number
  currencySymbol: CurrencySymbol
  chartDataConfig: RevenueDataConfig[]
}) => {
  let revenueSum = 0
  let otherSum = 0
  const data = chartDataConfig.map((dc) => {
    const data = rawData as Required<TopRevenuesByDim>

    const value = dc.formula.others(data)
    otherSum += value
    revenueSum += dc.formula.sum(data)

    return value
  })

  const otherData = [
    'Others',
    ...chartDataConfig.flatMap((dc, index) => {
      // because we filter out non computable data in chartDataConfig we can use `as Required<TopRevenuesByDim>`
      const value = data[index]
      const allRevenuePercentage = asPercentage(allImpressionRevenue.percentage(otherSum, rawData as Required<TopRevenuesByDim>) * 100)
      const allRevenueDisplayable = `${allImpressionRevenue.displayable(otherSum)}`
      const details = chartDataConfig
        .map((conf, index) => ({
          name: conf.name,
          color: conf.color,
          displayable: `${conf.formula.displayable(data[index])}`,
          percentage: asPercentage((data[index] / otherSum) * 100),
        }))
        .filter((_, index) => data[index] > revenueDisplayThreshold)

      return [
        value > 0 ? value : Number.NaN,
        dc.color,
        getChartTooltip({
          name: { value: '', label: 'Others' },
          currencySymbol,
          details,
          allImpressionRevenue: { name: allImpressionRevenue.name, percentage: allRevenuePercentage, displayable: allRevenueDisplayable },
        }),
      ]
    }),
  ]

  if (otherSum > 0 && otherSum / revenueSum > revenueDisplayThreshold) {
    return [otherData] // NOT a typo, will destructure to an array
  }

  return []
}

const computeChartData = ({ rawData, revenueDisplayThreshold, currencySymbol }: { rawData: TopRevenuesByDim; revenueDisplayThreshold: number; currencySymbol: CurrencySymbol }) => {
  const chartDataConfig = getChartDataConfig(rawData)
  const filterUnderThreshold = (_: unknown, index: number) =>
    allImpressionRevenue.isComputable(rawData) ? allImpressionRevenue.percentage(allImpressionRevenue.compute(rawData, index), rawData) : 0 > revenueDisplayThreshold
  const chartNames = rawData.name.filter(filterUnderThreshold)
  const chartData = rawData.name
    .map((name, index) => [
      name.label,
      ...chartDataConfig.flatMap((dc) => {
        // because we filter out non computable data in chartDataConfig we can use `as Required<TopRevenuesByDim>`
        const data = rawData as Required<TopRevenuesByDim>
        const value = dc.formula.compute(data, index)
        const allRevenue = allImpressionRevenue.compute(data, index)
        const allRevenuePercentage = asPercentage(allImpressionRevenue.percentage(allRevenue, data) * 100)
        const allRevenueDisplayable = `${allImpressionRevenue.displayable(allRevenue)}`
        const details = chartDataConfig
          .filter((dc) => dc.formula.compute(data, index) > 0)
          .map((dc) => ({
            name: dc.name,
            color: dc.color,
            displayable: `${dc.formula.displayable(dc.formula.compute(rawData as Required<TopRevenuesByDim>, index))}`,
            percentage: asPercentage((dc.formula.compute(data, index) / allImpressionRevenue.compute(data, index)) * 100),
          }))
        return [
          value > 0 ? value : Number.NaN,
          dc.color,
          getChartTooltip({ name, currencySymbol, details, allImpressionRevenue: { name: allImpressionRevenue.name, percentage: allRevenuePercentage, displayable: allRevenueDisplayable } }),
        ]
      }),
    ])
    // filtering out values under a certain threshold
    .filter(filterUnderThreshold)

  const othersData = computeOthersData({ rawData, revenueDisplayThreshold, currencySymbol, chartDataConfig })

  return {
    chartData: [['label', ...chartDataConfig.flatMap((dc) => [dc.formula.name, { role: 'style' }, { type: 'string', role: 'tooltip', p: { html: true } }])], ...chartData, ...othersData],
    selectableNames: chartNames,
  }
}

const getChartLegend = (rawData: TopRevenuesByDim) => {
  const chartDataConfig = getChartDataConfig(rawData)
  return chartDataConfig.map((dataConfig, index) => <Legend key={index} iconColor={dataConfig.color} label={dataConfig.name} />)
}

const useChartEvents = (onValueSelect: (name: MappedName) => void, selectableNames: MappedName[]) => {
  // WARNING: this hook takes leverage of the prototype because of a bug in the react-google-charts library
  // The events are initialized when the component is mounted but never updated.
  // So each time it gets called, it's the first callback version with an older lexical environment
  // If you need data coming from outside the callback, you need to use the same pattern

  const chartEvents: ReactGoogleChartEvent[] = useMemo(
    () => [
      {
        eventName: 'select',
        callback: function ({ chartWrapper }) {
          const { row } = chartWrapper.getChart().getSelection()[0]
          if (row < chartEvents[0].callback.prototype.selectableNames.length) {
            const data = chartEvents[0].callback.prototype.selectableNames[row]
            onValueSelect(data)
          }
        },
      },
    ],
    []
  )

  useEffect(() => {
    chartEvents[0].callback.prototype.selectableNames = selectableNames
  }, [selectableNames])

  return chartEvents
}

type PureOverviewRevenueProps = WithClassName &
  WidgetProps & { hideHeader?: false } & {
    rawData: TopRevenuesByDim
    currencySymbol: CurrencySymbol
    revenueDisplayThreshold: number
    onValueSelect: (name: MappedName) => void
  }
const _PureOverviewRevenue: FunctionComponent<PureOverviewRevenueProps> = ({ rawData, currencySymbol, revenueDisplayThreshold = 0.01, title, onValueSelect, ...props }) => {
  const chartOptions: ChartWrapperOptions['options'] = {
    ...AnalyticsDefaultChartOptions,
    chartArea: { ...AnalyticsDefaultChartOptions.chartArea, left: 100, right: 20, bottom: 20, top: 20 },
    hAxis: {
      ...AnalyticsDefaultChartOptions.hAxis,
      minValue: 0,
      gridlines: { ...AnalyticsDefaultChartOptions.hAxis?.gridLines, count: 3 },
      format: displayWithCurrency('#.##', currencySymbol),
    },
    vAxis: {
      ...AnalyticsDefaultChartOptions.vAxis,
      gridlines: { color: 'none' },
    },
    focusTarget: undefined,
    animation: {
      ...AnalyticsDefaultChartOptions.animation,
      easing: 'inAndOut',
    },
  }

  const { chartData, selectableNames } = computeChartData({ rawData, revenueDisplayThreshold, currencySymbol })
  const chartDataLegend = getChartLegend(rawData)

  const chartEvents = useChartEvents(onValueSelect, selectableNames)

  return (
    <Widget
      {...props}
      title={
        <WidgetTitle>
          Revenue per <span>{title}</span>
        </WidgetTitle>
      }
    >
      <ChartContainer>
        <ChartWrapper>{chartData.length > 1 && <Chart chartType={'BarChart'} options={chartOptions} width={'100%'} height={'100%'} data={chartData} chartEvents={chartEvents} />}</ChartWrapper>
      </ChartContainer>
      <Legends>{chartDataLegend}</Legends>
    </Widget>
  )
}

export const PureOverviewRevenue = styled(_PureOverviewRevenue)``
