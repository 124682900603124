import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  padding: 10px ${Sizes[12]};

  border-radius: ${Sizes[4]};
  border: 1px solid ${Colors.King};
  background: ${Colors.Ghost};

  ${Icon} {
    margin-right: ${Sizes[4]};
  }

  ${Fonts.P2};
  ${Fonts.colors.Jet};
`

type WidgetMessageProps = WithClassName & {
  message: string | ReactNode
  icon?: IconName
}
const _WidgetMessage: FunctionComponent<WidgetMessageProps> = ({ className, icon, message }) => (
  <Wrapper className={className}>
    {icon && <Icon name={icon} width={'20px'} fill={Colors.Jet} />}
    <span>{message}</span>
  </Wrapper>
)
export const WidgetMessage = styled(_WidgetMessage)``
