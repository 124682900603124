import styled from '@emotion/styled'
import { GamOverviewMetricsByTime } from '@pubstack/common/src/analytics/query'
import { FunctionComponent } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { Chart } from 'react-google-charts'
import type { ChartWrapperOptions } from 'react-google-charts/dist/types'
import { DataColors } from '~/assets/style/colors'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'
import { Icon } from '~/components/Icon'
import { Tooltip } from '~/components/Tooltip'
import { Widget, WidgetProps } from '~/components/Widget'
import { AnalyticsDefaultChartOptions, ChartContainer, ChartWrapperSmall } from '~/modules/analytics/AnalyticsCharts'
import { videoFirstQuartile, videoMidpoint, videoStart, videoThirdQuartile, videoViewershipComplete } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

export type GamOverwiewFunnelChartMetricTooltip = {
  name: string
  displayable: (data: GamOverviewMetricsByTime) => string
}
type FunnelChartTooltipProps = WithClassName & {
  title: string
  metrics: GamOverwiewFunnelChartMetricTooltip[]
  data: GamOverviewMetricsByTime
}
const _FunnelChartTooltip: FunctionComponent<FunnelChartTooltipProps> = ({ title, metrics, data }) => {
  const metricsLines: ChartTooltipMetricProps[] = metrics.map((metric) => {
    const value = metric.displayable(data)
    return {
      label: metric.name,
      value,
      iconName: 'empty',
    }
  })
  return <ChartTooltip date={title} metrics={[metricsLines]} />
}
const GamOverwiewFunnelChartTooltip = styled(_FunnelChartTooltip)``

type GamOverviewFormulas = typeof videoStart | typeof videoFirstQuartile | typeof videoMidpoint | typeof videoThirdQuartile | typeof videoViewershipComplete

type GamOverwiewFunnelMetric = {
  name: string
  formula: GamOverviewFormulas
  compute?: (data: GamOverviewMetricsByTime, index: number) => number
  sum?: (data: GamOverviewMetricsByTime) => number
  displayable?: (value: number) => string
  tooltip?: string
  annotation: string | undefined
}
const TooltipContent = () => (
  <>
    <div>
      {videoStart.name}: {ANALYTICS_TOOLTIPS.VIDEO_START}
    </div>
    <br />
    <div>
      {videoFirstQuartile.name}: {ANALYTICS_TOOLTIPS.VIDEO_FIRST_QUARTILE}
    </div>
    <br />
    <div>
      {videoMidpoint.name}: {ANALYTICS_TOOLTIPS.VIDEO_MIDPOINT}
    </div>
    <br />
    <div>
      {videoThirdQuartile.name}: {ANALYTICS_TOOLTIPS.VIDEO_THIRD_QUARTILE}
    </div>
    <br />
    <div>
      {videoViewershipComplete.name}: {ANALYTICS_TOOLTIPS.VIDEO_COMPLETE}
    </div>
  </>
)

const getTooltip = (metric: GamOverwiewFunnelMetric, data: GamOverviewMetricsByTime) => {
  const title = metric.name
  const metrics: GamOverwiewFunnelChartMetricTooltip[] = [
    {
      name: metric.name,
      displayable: () => (metric.formula.isComputable(data) ? metric.formula.displayable(metric.formula.sum(data)) : 'N/A'),
    },
  ]
  return ReactDOMServer.renderToString(<GamOverwiewFunnelChartTooltip title={title} metrics={metrics} data={data} />)
}

const convertData = (data: GamOverviewMetricsByTime, metrics: GamOverwiewFunnelMetric[]) => {
  if (!data.epoch.length) {
    return []
  }
  return metrics.map((metric) => [metric.formula.name, getTooltip(metric, data), metric.formula.isComputable(data) ? metric.formula.sum(data) : 0, metric.annotation])
}

const generateAnnotation = (videoFormula: GamOverviewFormulas, data: GamOverviewMetricsByTime): string | undefined => {
  return videoFormula.isComputable(data) ? `${videoFormula.displayablePercentage((videoFormula.sum(data) / videoStart.sum(data)) * 100)}` : undefined
}

type BuildedData = {
  chartData: any[][]
  chartOptions: ChartWrapperOptions['options']
}
const buildData = ({ data }: { data: GamOverviewMetricsByTime }): BuildedData => {
  const metrics: GamOverwiewFunnelMetric[] = data.epoch.length
    ? [
        { name: videoStart.name, formula: videoStart, annotation: undefined },
        {
          name: videoFirstQuartile.name,
          formula: videoFirstQuartile,
          annotation: generateAnnotation(videoFirstQuartile, data),
        },
        {
          name: videoMidpoint.name,
          formula: videoMidpoint,
          annotation: generateAnnotation(videoMidpoint, data),
        },
        {
          name: videoThirdQuartile.name,
          formula: videoThirdQuartile,
          annotation: generateAnnotation(videoThirdQuartile, data),
        },
        {
          name: videoViewershipComplete.name,
          formula: videoViewershipComplete,
          annotation: generateAnnotation(videoViewershipComplete, data),
        },
      ]
    : []
  const chartOptions: ChartWrapperOptions['options'] = {
    ...AnalyticsDefaultChartOptions,
    bar: { groupWidth: '80%' },
    seriesType: 'bars',
    crosshair: { ...AnalyticsDefaultChartOptions.crosshair, trigger: 'both' },
    vAxis: {
      ...AnalyticsDefaultChartOptions.vAxis,
      gridlines: { count: -1 },
      format: 'short',
      minValue: 0,
    },
    annotations: {
      style: 'point',
      alwaysOutside: true,
    },
    colors: [DataColors.Ming],
    isStacked: false,
  }
  const chartData = [
    [
      'Label',
      { type: 'string', role: 'tooltip', p: { html: true } },
      'value',
      {
        type: 'string',
        role: 'annotation',
      },
    ],
    ...convertData(data, metrics),
  ]

  return {
    chartData,
    chartOptions,
  }
}

type PureGamOverwiewWidgetFunnelProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon' | 'info'> & {
    data: GamOverviewMetricsByTime
  }
const _PureGamOverwiewWidgetFunnel: FunctionComponent<PureGamOverwiewWidgetFunnelProps> = ({ data, ...props }) => {
  const { chartData, chartOptions } = buildData({ data })
  return (
    <Widget
      {...props}
      icon={'video'}
      title={'Video funnel'}
      info={
        <Tooltip title={<TooltipContent />} positions={['left']} align={'start'}>
          <Icon name={'help_outline'} />
        </Tooltip>
      }
    >
      <ChartContainer>
        <ChartWrapperSmall>
          {!!data.epoch.length && <Chart chartType={'ColumnChart'} width={'100%'} height={'100%'} options={chartOptions} data={chartData} chartVersion={'upcoming'} />}
        </ChartWrapperSmall>
      </ChartContainer>
    </Widget>
  )
}
export const PureGamOverwiewWidgetFunnel = styled(_PureGamOverwiewWidgetFunnel)``
