import styled from '@emotion/styled'
import { AdUnit } from '@pubstack/common/src/adunit'
import { AdUnitDevice } from '@pubstack/common/src/adunitDevice'
import { AdunitDeviceRuleConfig } from '@pubstack/common/src/stack'
import { capitalizeFirstLetter } from '@pubstack/common/src/stringUtils'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Control, Controller, FieldArrayWithId, UseFieldArrayReturn, UseFormSetValue, UseFormTrigger, useFieldArray, useFormState, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ChipListInput } from '~/components/ChipListInput'
import { Icon } from '~/components/Icon'
import { Message } from '~/components/Message'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Toggle } from '~/components/Toggle'
import { StackFormData } from '~/modules/adstack/inventory/sites/stack/PureStackEdit'
import { WithClassName } from '~/types/utils'

/* Thanks to:
 - https://www.piotrl.net/typescript-condition-subset-types/
 - https://stackoverflow.com/questions/74272393/how-to-distinguish-different-functions-signature-with-conditional-type-checks/74272675
 - https://github.com/microsoft/TypeScript/issues/27024

 we're looking for the fields implementing AdunitDeviceRuleConfig[]|undefined and return the relevant keys
 */
type KeyOfTypeInto<Structure, TypeSeeked> = keyof {
  [Field in keyof Structure as [Structure[Field]] extends [TypeSeeked] ? Field : never]: any
}
type AdUnitDeviceRuleFieldsFromStackFormData = KeyOfTypeInto<StackFormData, AdunitDeviceRuleConfig[] | undefined>

type CommonAdunitDeviceRulesSelectorProps = {
  fieldRulesName: AdUnitDeviceRuleFieldsFromStackFormData
  control: Control<StackFormData>
  availableRules: SelectOptionProp<string>[]
  setValue: UseFormSetValue<StackFormData>
}

type DeviceAdunitDeviceRuleSelectorProps = CommonAdunitDeviceRulesSelectorProps & {
  device: AdUnitDevice
  availableAdUnits: SelectOptionProp<string>[]
  currentData: AdunitDeviceRuleConfig[]
  fields: (FieldArrayWithId<StackFormData, AdUnitDeviceRuleFieldsFromStackFormData, 'id'> & { originalIndex: number })[]
} & Pick<UseFieldArrayReturn<StackFormData, AdUnitDeviceRuleFieldsFromStackFormData, 'id'>, 'append' | 'remove'>

type AdunitDeviceRuleSelectorProps = WithClassName &
  CommonAdunitDeviceRulesSelectorProps & {
    title: string
    extraInformation?: string
    availableDevices: AdUnitDevice[]
    availableAdUnits: { [key in AdUnitDevice]: AdUnit[] }
    trigger: UseFormTrigger<StackFormData>
  }

const Title = styled.h2`
  ${Fonts.H2};
  ${Colors.Jet};
  font-weight: 500;
`

const AdunitDeviceRuleSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const AdunitDeviceRuleTitle = styled.span`
  display: inline-flex;
  gap: 12px;
`

const DeviceErrorMessageWrapper = styled.span`
  display: inline-flex;
  color: ${Colors.Alert};
  align-items: center;
  gap: 4px;
`

const DeviceAdunitDeviceRuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  & > ${Message} {
    width: 760px;
    box-sizing: border-box;
  }

  & > ${Button} {
    align-self: flex-start;
  }
`
const InformationWrapper = styled.div`
  display: block;
  gap: 200px;
`
const ComplementInformationWrapper = styled.span`
  font-style: italic;
  display: block;
`
const AdUnitRuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
    display: flex;
    gap: 12px;
    > :not(${Button}) {
      width: 760px;
    }
  }
`
const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 4px;
`

const DeviceErrorMessage = ({ message }: { message?: string }) => (
  <DeviceErrorMessageWrapper>
    <Icon width={'16px'} name={'alert'} />
    {message}
  </DeviceErrorMessageWrapper>
)

const DeviceAdunitDeviceRuleSelector: FunctionComponent<DeviceAdunitDeviceRuleSelectorProps> = ({
  control,
  currentData,
  setValue,
  availableAdUnits,
  device,
  availableRules,
  fields,
  append,
  remove,
  fieldRulesName,
}) => {
  const { errors } = useFormState({ control, name: fieldRulesName })

  const remainingAdUnits = availableAdUnits.filter((adUnit) => currentData?.find((field) => field.adUnitIds.includes(adUnit.value)) === undefined).sort((a, b) => a.label.localeCompare(b.label))
  const remainingRules = availableRules.filter((rule) => currentData?.find((field) => field.ruleId === rule.value) === undefined).sort((a, b) => a.label.localeCompare(b.label))

  const selectedAdUnits = currentData?.flatMap((rule) => rule.adUnitIds) ?? []
  const deviceLabel = `${capitalizeFirstLetter(device)} (${selectedAdUnits.length}/${availableAdUnits.length} ad units)`

  const deviceAdUnitsErrorMessage = useMemo(() => {
    // type of error message defines the device on wich it applies
    const errorMessage = errors?.[fieldRulesName]?.find?.((error) => error?.type === device)?.message
    return errorMessage ? <DeviceErrorMessage message={errorMessage} /> : null
  }, [errors[fieldRulesName]])

  const addAvailableAdUnits = (value: AdunitDeviceRuleConfig, index: number) => {
    setValue(`${fieldRulesName}.${index}.adUnitIds`, [...value.adUnitIds, ...remainingAdUnits.map((adUnit) => adUnit.value)])
  }

  return (
    <DeviceAdunitDeviceRuleWrapper>
      <AdunitDeviceRuleTitle>
        <Title>{deviceLabel} </Title>
        <span>{deviceAdUnitsErrorMessage}</span>
      </AdunitDeviceRuleTitle>

      {fields.map((field, index) => {
        const selectedRule = currentData?.[index] ? availableRules.find((rule) => rule.value === currentData?.[index].ruleId) : undefined
        const rulesOptionsAvailable = [...remainingRules, selectedRule].filter((rule): rule is SelectOptionProp<string> => !!rule).sort((a, b) => a.label.localeCompare(b.label))
        return (
          <Controller
            key={field.id}
            control={control}
            name={`${fieldRulesName}.${field.originalIndex}`}
            rules={{
              validate: {
                // dirty trick : defining the validate rule by device here allows us to select it for display purposes
                [device]: () => selectedAdUnits.length === availableAdUnits.length || 'You must assign all available ad units to a rule for this device.',
              },
            }}
            render={({ field: { value } }) => {
              return (
                <AdUnitRuleWrapper>
                  <div>
                    <Select
                      searchable
                      isMessageBlock
                      rules={{
                        required: 'You must select a rule.',
                        validate: (ruleValue) => (!value.adUnitIds.length && !!ruleValue ? 'You must assign at least one ad unit to this rule, or delete it.' : true),
                      }}
                      error={errors?.[fieldRulesName]?.[field.originalIndex]?.ruleId?.message}
                      control={control}
                      name={`${fieldRulesName}.${field.originalIndex}.ruleId`}
                      label={'Rule'}
                      options={rulesOptionsAvailable}
                    />
                    {index > 0 && <Button variant={'tertiary'} iconName={'delete'} onClick={() => remove(field.originalIndex)} />}
                  </div>
                  <div>
                    <ChipListInput
                      isSpaceAllowed
                      label={'Apply rule to'}
                      control={control}
                      name={`${fieldRulesName}.${field.originalIndex}.adUnitIds`}
                      selectableOptions={remainingAdUnits}
                      error={errors[fieldRulesName]?.[field.originalIndex]?.adUnitIds?.message}
                      rules={{
                        validate: () => {
                          return value.adUnitIds.every((id) => availableAdUnits.find((adUnit) => adUnit.value === id)) || 'Some ad units are no longer available for this device.'
                        },
                      }}
                    />
                    <Button disabled={!remainingAdUnits.length} variant={'tertiary'} onClick={() => addAvailableAdUnits(value, field.originalIndex)}>
                      Add available ad units
                    </Button>
                  </div>
                </AdUnitRuleWrapper>
              )
            }}
          />
        )
      })}
      <Button disabled={remainingRules.length === 0 || remainingAdUnits.length === 0} variant={'secondary'} onClick={() => append({ adUnitIds: [], ruleId: '', device })}>
        Add group
      </Button>
    </DeviceAdunitDeviceRuleWrapper>
  )
}

const _AdunitDeviceRuleSelector: FunctionComponent<AdunitDeviceRuleSelectorProps> = ({
  className,
  setValue,
  trigger,
  control,
  availableAdUnits,
  availableDevices,
  availableRules,
  fieldRulesName,
  title,
  extraInformation,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldRulesName,
  })

  const formData = useWatch({ control, name: fieldRulesName })
  useEffect(() => {
    // have to trigger validation manually on form change because fieldArrays are not firing changes on their own if sub fields are changed
    trigger(fieldRulesName)
  }, [formData])

  const [visible, setVisible] = useState(!!formData?.length)
  const [formDataMemo, setFormDataMemo] = useState<AdunitDeviceRuleConfig[] | undefined>(formData)

  const devicesUsedToSelectRules = availableDevices.filter((device: AdUnitDevice) => {
    return availableAdUnits[device] && availableAdUnits[device].length > 0
  })

  return (
    <Controller
      control={control}
      name={fieldRulesName}
      rules={{
        validate: (value) => {
          // no value means no control. On field array init, value is an empty array
          return !value?.length || devicesUsedToSelectRules.every((device) => value?.find((rule) => rule.device === device)) ? true : 'You must create at least a rule for each device.'
        },
      }}
      render={({ field: { onChange }, formState: { errors } }) => {
        return (
          <AdunitDeviceRuleSelectorWrapper className={className}>
            <ToggleWrapper>
              <Toggle
                value={visible}
                // keep value on toggle off, but erase it in the form
                onClick={() => {
                  setVisible(!visible)
                  if (visible) {
                    onChange(undefined)
                    setFormDataMemo(formData)
                  } else {
                    onChange(formDataMemo)
                  }
                }}
              />
              <AdunitDeviceRuleTitle>
                <Title>{title}</Title>
                {visible && errors[fieldRulesName]?.root && <DeviceErrorMessage message={errors[fieldRulesName]?.root?.message} />}
              </AdunitDeviceRuleTitle>
            </ToggleWrapper>
            {visible && (
              <>
                <InformationWrapper>
                  Create groups containing a rule and ad units that will use it.
                  {extraInformation && <ComplementInformationWrapper>{extraInformation}</ComplementInformationWrapper>}
                </InformationWrapper>
                {devicesUsedToSelectRules.map((device: AdUnitDevice, index: number) => (
                  <DeviceAdunitDeviceRuleSelector
                    fieldRulesName={fieldRulesName}
                    setValue={setValue}
                    currentData={formData?.filter((rule) => rule.device === device) ?? []}
                    key={index}
                    control={control}
                    availableAdUnits={availableAdUnits[device].map((adUnit) => ({ label: adUnit.name, value: adUnit.name }) satisfies SelectOptionProp<string>)}
                    device={device}
                    availableRules={availableRules}
                    fields={
                      // fields are common between devices, so we have to filter them by device and pass down the proper index for update purposes
                      fields
                        .map((field, index) => ({
                          // keep track of this specific field even after fields have been filtered
                          originalIndex: index,
                          ...field,
                        }))
                        .filter((field) => field.device === device)
                    }
                    append={append}
                    remove={remove}
                  />
                ))}
              </>
            )}
          </AdunitDeviceRuleSelectorWrapper>
        )
      }}
    />
  )
}

export const AdunitDeviceRuleSelector = styled(_AdunitDeviceRuleSelector)``
