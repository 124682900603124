import styled from '@emotion/styled'
import { Report, ReportStatus, reportsConfiguration } from '@pubstack/common/src/report'
import { DateTime } from 'luxon'
import { FunctionComponent, useState } from 'react'
import { Color, Colors } from '~/assets/style/colors'
import { Sizes } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Tooltip } from '~/components/Tooltip'
import Table, { handleTableSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'

const Status = styled.span`
  display: inline-flex;
  align-items: center;
`

const StatusDot = styled.div<{ color?: Color }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background-color: ${(props) => props.color ?? Colors.Hurricane};
  margin-right: ${Sizes[4]};
`

const NoReport = styled.p`
  text-align: center;
`

const STATUS_CONFIG: { [key in (typeof ReportStatus)[number]]: { label: (report: Report) => string; color: Color } } = {
  CREATED: {
    label: (report) => `Created on  ${DateTime.fromISO(report.created).toFormat('MMM dd, yyyy')}`,
    color: Colors.Success,
  },
  FAILED: {
    label: () => 'Creation Failed',
    color: Colors.Alert,
  },
  INPROGRESS: {
    label: () => 'Creation in Progress',
    color: Colors.Pumpkin,
  },
}

type PureReportsTableProps = WithClassName & {
  isLoading: boolean
  reports?: Report[]
  onRowClick: (report: Report) => void
  onDeleteClick: (report: Report) => void
}
const _PureReportsTable: FunctionComponent<PureReportsTableProps> = ({ className, reports, isLoading, onRowClick, onDeleteClick }) => {
  const [columns, setColumns] = useState<TableColumns<Report>>([
    {
      name: 'Report type',
      isSortable: true,
      order: 'none',
      attributeSort: 'reportName',
    },
    {
      name: 'Time period',
      isSortable: true,
      order: 'none',
      attributeSort: 'startDate',
    },
    {
      name: 'Status',
      isSortable: true,
      order: 'descending',
      attributeSort: 'created',
    },
    {
      name: 'Action',
      isSortable: false,
    },
  ])
  const reportsSorted = handleTableSort<Report>(columns, reports)

  const getTimePeriodLabel = (report: Report) => `from ${DateTime.fromISO(report.startDate).toFormat('MMM dd, yyyy')} to ${DateTime.fromISO(report.endDate).toFormat('MMM dd, yyyy')}`
  return (
    <Table isLoading={isLoading} columns={columns} onClickHeading={(column) => onColumnSort(columns, column, setColumns)} className={className}>
      {reportsSorted.length ? (
        reportsSorted.map((report, index) => {
          const canDelete = report.status !== 'INPROGRESS'
          const deleteButton = (
            <Button
              key={`report_${report.id}_delete`}
              variant={'tertiary'}
              iconName={'delete'}
              disabled={!canDelete}
              onClick={async (event) => {
                event.preventDefault()
                event.stopPropagation()
                onDeleteClick(report)
              }}
            />
          )
          return (
            <TableRow key={index} onClick={() => onRowClick(report)}>
              <TableCell>{reportsConfiguration[report.reportName].title}</TableCell>
              <TableCell>{getTimePeriodLabel(report)}</TableCell>
              <TableCell>
                <Status>
                  <StatusDot color={STATUS_CONFIG[report.status].color} /> {STATUS_CONFIG[report.status].label(report)}
                </Status>
              </TableCell>
              <TableCell>
                {canDelete ? (
                  deleteButton
                ) : (
                  <Tooltip
                    title={
                      <>
                        Cannot delete while creation is in progress. <br /> Wait for completion.
                      </>
                    }
                  >
                    {deleteButton}
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          )
        })
      ) : (
        <TableRow>
          <TableCell colspan={columns.length}>
            <NoReport>No report created yet.</NoReport>
          </TableCell>
        </TableRow>
      )}
    </Table>
  )
}
export const PureReportsTable = styled(_PureReportsTable)``
