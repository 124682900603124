import styled from '@emotion/styled'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { StackContext } from '@pubstack/common/src/stackContext'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useContextKeys, useContexts } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { WithClassName } from '~/types/utils'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackContextEditPage } from './PureAdStackContextEditPage'

const emptyContext: StackContext = {
  id: '',
  name: '',
  conditions: { op: 'AND', elements: [] },
  scopeId: '',
  creationTime: 0,
  enabled: true,
}
type AdStackContextEditPageProps = WithClassName & {
  //
}

const _AdStackContextEditPage: FunctionComponent<AdStackContextEditPageProps> = ({ className }) => {
  const navigate = useNavigate()
  const [context, setContext] = useState<StackContext>(emptyContext)
  const [contextKeys, setContextKeys] = useState<ContextKey[]>([])
  const { contextId } = useParams()
  const toast = useToast()
  const { byId } = useContexts(null)
  const { all } = useContextKeys(null)
  const breadcrumbs = useBreadcrumbs(context)

  async function loadContext(contextId?: string) {
    if (contextId) {
      try {
        const contextToUpdate = await byId.get(contextId)
        setContext(contextToUpdate)
      } catch (exception) {
        toast.alert('An error occurred while fetching this context.')
        throw exception
      }
    }
  }

  async function loadContextKeys() {
    try {
      const contextKeys = await all.get()
      setContextKeys(contextKeys)
    } catch (exception) {
      toast.alert('An error occurred while fetching contextKeys for this scope.')
      throw exception
    }
  }

  async function createContext(context: StackContext) {
    try {
      const newContext: StackContext = await byId.post(context)
      toast.success(`${newContext.name} added to your inventory.`)
      navigate('/adstack/context/contexts')
    } catch (exception) {
      toast.alert('An error occurred while saving this context.')
      throw exception
    }
  }

  useEffect(() => {
    loadContext(contextId)
  }, [contextId])

  useEffect(() => {
    loadContextKeys()
  }, [])

  return (
    <PureAdStackContextEditPage
      className={className}
      isEditing={!!context?.id}
      context={context}
      contextKeys={contextKeys}
      breadcrumbs={breadcrumbs}
      createContext={createContext}
      onBack={() => navigate(-1)}
    />
  )
}
export const AdStackContextEditPage = styled(_AdStackContextEditPage)``
