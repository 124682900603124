import styled from '@emotion/styled'
import { BidMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo, useState } from 'react'
import type { ChartWrapperOptions } from 'react-google-charts/dist/types'
import { TabProp, Tabs } from '~/components/Tabs'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { TimelineConfiguration, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import { bidRate, bidRequests, bidResponses, bidTimeoutRate, bidTimeouts, noBid, noBidRate } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

const useConf = (type: BidderResponsesDataType) => {
  return useMemo((): TimelineConfiguration<BidMetricsByTime> => {
    const chartOptions: ChartWrapperOptions['options'] = {
      ...AnalyticsDefaultChartOptions,
      annotations: { ...AnalyticsDefaultChartOptions.annotations, style: 'point' },
      crosshair: { ...AnalyticsDefaultChartOptions.crosshair, trigger: 'both' },
      vAxis: {
        ...AnalyticsDefaultChartOptions.vAxis,
        gridlines: { count: -1 },
        minorGridlines: { color: 'none' },
        format: type === 'percentage' ? 'percent' : 'short',
        minValue: 0,
      },
      isStacked: type === 'percentage' ? 'relative' : true,
    }
    return type === 'percentage'
      ? {
          getChartOptions: () => chartOptions,
          legendConfig: ['bidTimeoutRate', 'bidRate', 'noBidRate'],
          tooltipConfig: ['noBidRate', 'bidRate', 'bidTimeoutRate'],
          dataConfig: [
            {
              name: 'bidTimeoutRate',
              isComputable: ({ data }) => bidTimeoutRate.isComputable(data),
              getFormula: () => bidTimeoutRate,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_TIMEOUT_RATE,
              withValue: true,
            },
            {
              name: 'bidRate',
              isComputable: ({ data }) => bidRate.isComputable(data),
              getFormula: () => bidRate,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_RATE,
              withValue: true,
            },
            {
              name: 'noBidRate',
              isComputable: ({ data }) => noBidRate.isComputable(data),
              getFormula: () => noBidRate,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_NO_BID_RATE,
              withValue: true,
            },
          ],
        }
      : {
          getChartOptions: () => chartOptions,
          legendConfig: ['bidTimeouts', 'bidResponses', 'noBid'],
          tooltipConfig: ['noBid', 'bidResponses', 'bidTimeouts'],
          dataConfig: [
            {
              name: 'bidTimeouts',
              isComputable: ({ data }) => bidTimeouts.isComputable(data),
              getFormula: () => bidTimeouts,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_TIMEOUT,
              withValue: true,
            },
            {
              name: 'bidResponses',
              isComputable: ({ data }) => bidResponses.isComputable(data),
              getFormula: () => bidResponses,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_RESPONSES,
              withValue: true,
            },
            {
              name: 'noBid',
              isComputable: ({ data }) => noBid.isComputable(data),
              getFormula: () => noBid,
              legendTooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_NO_BID,
              withValue: true,
            },
          ],
        }
  }, [type])
}

export type BidderResponsesDataType = 'percentage' | 'number'
const dataTypes: { type: BidderResponsesDataType; label: string }[] = [
  { type: 'percentage', label: '%' },
  { type: 'number', label: '123' },
]

type PureBidderWidgetResponsesProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon' | 'info'> & {
    data: BidMetricsByTime
    onTabChange?: (type: BidderResponsesDataType) => void
    timezone: Timezone
    currentEpoch: DateTime
    currencySymbol: CurrencySymbol
  }
const _PureBidderWidgetResponses: FunctionComponent<PureBidderWidgetResponsesProps> = ({ onTabChange, data, currencySymbol, currentEpoch, timezone, ...props }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const tabs: TabProp[] = dataTypes.map((type, index) => ({ label: type.label, active: currentTab === index }))
  const allComputable = data && bidRequests.isComputable(data) && bidTimeouts.isComputable(data)
  const showTabs = !props.empty && allComputable
  const onTabClick = (tab: TabProp) => {
    const index = tabs.indexOf(tab)
    setCurrentTab(index)
    onTabChange && onTabChange(dataTypes[index].type)
  }

  const currentConfig = useConf(dataTypes[currentTab].type)
  const { chart, legends } = useTimelineChart({ currentConfig, data, currencySymbol, currentEpoch, timezone, chartType: 'AreaChart' })

  return (
    <AnalyticsChartWidget
      {...props}
      empty={props.empty || (!props.isLoading && !allComputable)}
      icon={'bidder'}
      title={'Bidder responses'}
      info={showTabs && <Tabs tabs={tabs} fluid={false} onClick={onTabClick} />}
      smallChart={true}
      chart={chart}
      legends={legends.reverse()}
    />
  )
}
export const PureBidderWidgetResponses = styled(_PureBidderWidgetResponses)``
