import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, BidMetricsByBidder, MappedName } from '@pubstack/common/src/analytics/query'
import { FunctionComponent, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useLocalStorage } from 'usehooks-ts'
import { useAnalyticsQuery } from '~/api/api.hook'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { isContextInSelectableInterval, logSortBreakdownAction } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureBiddersOverview } from './PureBiddersOverview'

type BiddersOverviewProps = WithClassName
const _BiddersOverview: FunctionComponent<BiddersOverviewProps> = ({ className }) => {
  const logger = useLogger()
  const navigate = useNavigate()
  const dimensions: Dimension[] = ['tagId', 'bidder', 'adUnit', 'device', 'country', 'size', 'pubstackRefresh', 'stackIdVersion']
  const dashboard: AnalyticsQueryDashboard = { name: 'bidders', filterType: 'auctions' }
  const context = useRecoilValue(contextState)
  const [data, setData] = useState<BidMetricsByBidder | undefined>(undefined)
  const { byId: analyticsQuery } = useAnalyticsQuery<BidMetricsByBidder>(null, dimensions, context)
  const currencySymbol = useScopeCurrency()
  const breadcrumbs = useBreadcrumbs()
  const [showMinorData] = useLocalStorage<boolean>('showMinorData', false)
  const threshold = showMinorData ? 0 : undefined
  const maxNumberOfDayInTimerangeSelected = 31

  const loadData = async () => {
    if (analyticsQuery.loading) {
      analyticsQuery.abort()
    }
    const d = await analyticsQuery.post('bid.metrics.by.bidder')
    setData(d.values)
  }

  const onRefreshClick = () => {
    return
  }

  const onRowClick = (bidderName: MappedName) => {
    navigate({
      pathname: `/analytics/bidders/${bidderName.label}`,
      search: `?${createSearchParams({
        from: context.timeRange.from.toFormat('yyyy-MM-dd'),
        to: context.timeRange.to.toFormat('yyyy-MM-dd'),
        mapping: btoa(
          JSON.stringify({
            bidder: bidderName.value,
            bidderName: bidderName.label,
          })
        ),
      })}`,
    })
  }

  useEffect(() => {
    setTimeout(() => {
      if (isContextInSelectableInterval(context, maxNumberOfDayInTimerangeSelected)) {
        loadData()
      }
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context])

  return (
    <AnalyticsPage
      className={className}
      onRefreshClick={onRefreshClick}
      filterDimensions={dimensions}
      dashboard={dashboard}
      title={breadcrumbs}
      maxNumberOfDayInTimerangeSelected={maxNumberOfDayInTimerangeSelected}
      showMinorDataToggle={true}
    >
      <PureBiddersOverview
        isLoading={analyticsQuery.loading}
        error={!!analyticsQuery.error}
        empty={!analyticsQuery.loading && !data?.name.length}
        onRefreshClick={onRefreshClick}
        rawData={data}
        currencySymbol={currencySymbol}
        onRowClick={onRowClick}
        onSortChange={logSortBreakdownAction(logger)}
        displayableBidRequestsPercentageThreshold={threshold}
        displayableImpressionRevenuePercentageThreshold={threshold}
      />
    </AnalyticsPage>
  )
}
export const BiddersOverview = styled(_BiddersOverview)``
