import styled from '@emotion/styled'
import { pluralize } from '@pubstack/common/src/localization'
import { FunctionComponent, useEffect } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import Button from '~/components/Button'
import { _Input as BaseInput, Input } from '~/components/Input'
import { _Select as BaseSelect, Select } from '~/components/Select'

type RefreshGroupSpecificRule = { bidderName: string; timer: number }
export type RefreshGroupRules = { timer: number; specificRules: RefreshGroupSpecificRule[] }

const SpecificRule = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  & ${BaseInput} {
    max-width: 75px;
  }
  & ${BaseSelect} {
    max-width: 200px;
  }
`

const RefreshSpecificRulesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin: 0;
`
const RefreshGlobalRuleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  & ${BaseInput} {
    width: 70px;
  }
`

export const RefreshGroupRulesForm: FunctionComponent<{
  bidders: string[]
  refreshRules: RefreshGroupRules
  updateRefreshGroup: (refreshRules: RefreshGroupRules) => unknown
}> = ({ refreshRules, updateRefreshGroup, bidders }) => {
  const {
    control,
    formState: { isValid, errors },
    trigger,
  } = useForm<RefreshGroupRules>({
    defaultValues: { ...refreshRules, timer: refreshRules.timer },
    mode: 'onChange',
  })
  const { fields, append, remove } = useFieldArray({ control, name: 'specificRules' })

  const values = useWatch({ control, name: ['specificRules', 'timer'] })
  useEffect(() => {
    const [specificRules, timer] = values
    updateRefreshGroup({ specificRules: [...specificRules], timer })
  }, [values])

  return (
    <>
      <RefreshGlobalRuleWrapper>
        Trigger a refresh when an impression is in view during
        <Input
          error={errors.timer?.message}
          min={1}
          control={control}
          name={'timer'}
          type={'number'}
          rules={{ required: true, min: { value: 0, message: 'Your timer should be a positive number' } }}
        />
        seconds.
      </RefreshGlobalRuleWrapper>
      {!!fields.length && <p>Specific rules:</p>}

      <RefreshSpecificRulesList>
        {fields.map((field, index) => (
          <li key={field.id}>
            <SpecificRule>
              <span>On ads served by</span>
              <Select
                error={errors.specificRules?.[index]?.bidderName?.message}
                control={control}
                name={`specificRules.${index}.bidderName`}
                label={'Select bidder'}
                options={bidders.map((b) => ({ label: b, value: b }))}
                rules={{ required: { value: true, message: 'Please select a bidder.' } }}
              />
              <span>trigger a refresh when an impression is in view during</span>
              <Input
                min={0}
                label={'Timer'}
                labelIsPlaceholder
                control={control}
                type={'number'}
                name={`specificRules.${index}.timer`}
                rules={{ required: true, min: { value: 0, message: 'Your timer should be a positive number' } }}
                onBlur={() => trigger('specificRules')}
              />
              <span>{pluralize('second', values[1])}.</span>
              <Button
                onClick={() => {
                  remove(index)
                }}
                variant={'tertiary'}
                iconName={'delete'}
              />
            </SpecificRule>
          </li>
        ))}
      </RefreshSpecificRulesList>
      {/* shouldFocus is disabled in order to leave the native autofocus handle it */}
      <Button variant={'secondary'} disabled={!isValid} onClick={() => append({ timer: 30, bidderName: '' }, { shouldFocus: false })}>
        Add specific rule
      </Button>
    </>
  )
}
