import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'
import { CopyButton } from './CopyButton'

type CodeSnippetProps = WithClassName & {
  contentText: string
  copyButtonHidden?: boolean
  onCodeCopy?: () => unknown
}
const CodeSnippetWrapper = styled.div`
  display: flex;
  padding: 12px 0px;
  code {
    background: ${Colors.Ghost};
    border-radius: 4px;
    border: 1px solid ${Colors.Topaze};
    padding: 16px;
    margin-right: 12px;
    position: relative;
    overflow: auto;
    max-width: calc(100% - 80px);
  }
  pre {
    margin: 0;
    ${Fonts.MonoSmall};
  }
  pre::-webkit-scrollbar {
    display: none;
  }
  pre.singleLine {
    white-space: nowrap;
  }
`

const _CodeSnippet: FunctionComponent<CodeSnippetProps> = ({ contentText, copyButtonHidden = false, onCodeCopy }) => {
  return (
    <CodeSnippetWrapper>
      <code>
        <pre>{contentText}</pre>
      </code>
      {!copyButtonHidden && <CopyButton onCopy={onCodeCopy} contentText={contentText} />}
    </CodeSnippetWrapper>
  )
}

export const CodeSnippet = styled(_CodeSnippet)``
