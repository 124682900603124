import styled from '@emotion/styled'
import { ComponentProps, ForwardedRef, forwardRef, useRef } from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'
import mergeRefs from '~/utils/mergeRefs'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & input {
    display: none;
  }
`
const ListLabel = styled.span`
  display: inline-flex;
  gap: 0.25rem;
`

const NoList = styled.span`
  ${Fonts.colors.SlateGrey};
  font-style: italic;
`

type BaseListTransferProps = WithClassName & ComponentProps<'input'> & { label: string; filename?: string; onDownload: (filename: string) => void; onDelete: (filename: string) => void }
const _BaseListTransfer = ({ className, label, filename, onDownload, onDelete, disabled, ...props }: BaseListTransferProps, ref: ForwardedRef<HTMLInputElement>) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  return (
    <Wrapper className={className}>
      <input {...props} ref={mergeRefs([inputRef, ref])} type={'file'} accept={'text/csv'} />
      <ListLabel>
        <span>{label} -</span>
        {filename ? <Link label={filename} icon={'download'} onClick={() => onDownload(filename)} /> : <NoList>no list</NoList>}
      </ListLabel>
      {filename ? (
        <Button disabled={disabled} onClick={() => onDelete(filename)} variant={'secondary'} iconName={'delete'} />
      ) : (
        <Button disabled={disabled} onClick={handleUploadClick} variant={'secondary'} iconName={'upload'}>
          Upload csv
        </Button>
      )}
    </Wrapper>
  )
}

export const BaseListTransfer = styled(forwardRef<HTMLInputElement, BaseListTransferProps>(_BaseListTransfer))``

type ListTransferProps<T extends FieldValues> = Omit<UseControllerProps<T>, 'defaultValue'> & Omit<BaseListTransferProps, 'value' | 'ref'>
export const ListTransfer = <T extends FieldValues>({ name, control, rules, shouldUnregister, onChange, ...props }: ListTransferProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field: { onChange: fieldOnChange, ...field } }) => (
        <BaseListTransfer
          {...props}
          {...field}
          onChange={(event) => {
            fieldOnChange(event)
            onChange && onChange(event)
          }}
        />
      )}
    />
  )
}
