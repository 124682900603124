import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { PrebidConfig } from '@pubstack/common/src/prebidConfig'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useBidderAdapters, usePrebidConfig, usePrebidSiteConfig } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { configurationState } from '~/state'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackPrebidIntegrationPage } from './PureAdStackPrebidIntegrationPage'

export const AdStackPrebidIntegrationPage: React.FunctionComponent = () => {
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)
  const toast = useToast()
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs('Prebid Wrapper')
  const [currentPrebidConfig, setPrebidConfig] = useState<PrebidConfig>({ scopeId: '', sites: [], siteConfigs: [] })
  const [bidderAdapters, setBidderAdapters] = useState<BidderAdapter[]>([])
  const { prebidConfig } = usePrebidConfig()
  const { prebidSiteConfig } = usePrebidSiteConfig()
  const { all: allBidderAdapters } = useBidderAdapters(null)

  const getCurrentPrebidConfig = async () => {
    const currentPrebidConfig = await prebidConfig.get()
    setPrebidConfig(currentPrebidConfig)
  }
  const getBidderAdapters = async () => {
    if (allBidderAdapters.loading) {
      allBidderAdapters.abort()
    }
    const usedBidders = await allBidderAdapters.get()
    setBidderAdapters(usedBidders)
  }

  const onCreateSiteConfig = async (siteConfig: SiteConfig) => {
    try {
      await prebidSiteConfig.put(siteConfig)
      await getCurrentPrebidConfig()
      toast.admSuccess(currentPrebidConfig.sites.find((site) => site.id === siteConfig.siteId)?.name ?? '', true, navigate)
    } catch (err) {
      toast.alert(`An error occurred when trying to save the site configuration.`)
    }
  }

  useEffect(() => {
    getCurrentPrebidConfig()
    getBidderAdapters()
  }, [])

  return (
    <PureAdStackPrebidIntegrationPage
      breadcrumbs={breadcrumbs}
      isLoading={prebidConfig.loading || prebidSiteConfig.loading}
      prebidConfig={currentPrebidConfig}
      onCreateSiteConfig={onCreateSiteConfig}
      baseCDNUrl={STATIC_ASSETS_CDN}
      bidderAdapters={bidderAdapters}
      onBackClick={() => navigate(-1)}
    />
  )
}
