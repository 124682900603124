import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { VoidFunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div<{ expanded?: boolean }>`
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: 1.3pt;
  cursor: pointer;
  ${Fonts.P1};
  clip-path: inset(0px 0px -2px 0px);
  flex-direction: row;
  align-items: center;

  ${({ expanded }) =>
    expanded &&
    css`
      padding: 12px 0 0;
      border-top: 1px solid ${Colors.Silver};
    `}
`
const _FilterSidebarCategoryExpandAction: VoidFunctionComponent<WithClassName & { expanded?: boolean; onClick: () => void }> = ({ className, expanded, onClick }) => (
  <Wrapper className={className} expanded={expanded} onClick={onClick}>
    <Link label={expanded ? 'Less' : 'More'} />
  </Wrapper>
)

export const FilterSidebarCategoryExpandAction = styled(_FilterSidebarCategoryExpandAction)``
