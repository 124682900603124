import { AdminApiKey } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAdminAPIKey } from '~/api/admin-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { PureAdminScopeApiKeys } from './PureAdminScopeApiKeys'

export const AdminScopeApiKeys: FunctionComponent = () => {
  const { scopeId } = useParams()
  const { all, byId } = useAdminAPIKey(null, scopeId ?? '')
  const [apiKeys, setApiKeys] = useState<AdminApiKey[]>([])
  const toast = useToast()

  const loadKeys = async () => {
    if (byId.loading) {
      byId.abort()
    }
    const keys = await all.get()
    setApiKeys(keys)
  }

  const createKey = async (name: string) => {
    if (name && scopeId) {
      const newKey = {
        scopeId,
        name,
      }
      try {
        const res = await byId.post(newKey)
        setApiKeys([res, ...apiKeys])
        toast.success(`${res.name} was successfully created`)
      } catch (err) {
        //TODO tmu nra 2022-09-26 display this in a toaster maybe ?
        throw new Error('Error on creating API Key')
      }
    }
  }
  useEffect(() => {
    loadKeys()
  }, [scopeId])

  //TODO tmu nra 2022-09-21 add error state
  return <PureAdminScopeApiKeys keys={apiKeys} isLoading={byId.loading && !byId.error} onNewKey={createKey} />
}
