import { DateTime } from 'luxon'

export function sameMonth(first: DateTime, second: DateTime): boolean {
  return first.month === second.month && first.year === second.year
}

export function sameDay(first: DateTime, second: DateTime): boolean {
  return sameMonth(first, second) && first.day === second.day
}

export function formatHumanReadable(datetime: DateTime): string {
  // Need to clone before formatting when using week with Luxon because it mutates object with its cache to access weekdata
  const dateTime = DateTime.fromJSDate(datetime.toJSDate())

  return dateTime.toFormat('ccc, dd LLL. yyyy')
}

export function getNumberOfDays(from: DateTime, to: DateTime): number {
  return Math.abs(from.diff(to, 'days').as('days')) + 1 // add one day to the difference between two dates to get the number of days
}

export function getNumberOfWeeks(from: DateTime, to: DateTime): number {
  return Math.trunc(Math.abs(from.diff(to, 'weeks').as('weeks')))
}

export function getIndexesDates(from: DateTime, to: DateTime, today: DateTime) {
  const junctionDay = today.minus({ day: 14 }).startOf('day').setZone(from.zone, { keepLocalTime: true })
  const junctionDayMidnightZoned = junctionDay.setZone(from.zone, { keepLocalTime: true })
  const junctionDayBeforeEndOfDay = junctionDay.minus({ day: 1 }).endOf('day')
  const fromDaily = from
  const toDaily = DateTime.fromMillis(Math.min(junctionDayBeforeEndOfDay.valueOf(), to.valueOf()))
  const fromHourly = DateTime.fromMillis(Math.max(junctionDayMidnightZoned.valueOf(), from.valueOf()))
  const toHourly = to

  return {
    daily:
      fromDaily >= toDaily
        ? undefined
        : {
            from: fromDaily,
            to: toDaily,
          },
    hourly:
      fromHourly > toHourly
        ? undefined
        : {
            from: fromHourly,
            to: toHourly,
          },
  }
}

export function defaultInterval(from: DateTime, to: DateTime, today: DateTime = DateTime.now()): string {
  const dates = getIndexesDates(from, to, today)

  if (dates.daily) {
    // forces to 1d interval because we can't have hourly buckets with daily data
    return '1d'
  }

  const days = Math.abs(from.diff(to, 'days').as('days'))
  if (days <= 4) return '1h'
  return '1d'
}

/**
 * Transform a luxon DateTime in a string which represents only the date as follow 'yyyy-MM-dd'
 * Used to communicate between frontend and backend
 * @param dateTime datetime to format in string
 * @return string - a string format as follow 'yyyy-MM-dd'
 * @example
 * formatToDateWithoutTime(DateTime.local());
 */
export function formatToDateWithoutTime(dateTime: DateTime): string {
  return dateTime.toFormat('yyyy-MM-dd')
}

/**
 * Transform a string which represents a date with this pattern 'yyyy-MM-dd' to a DateTime Luxon
 * Used to communicate between frontend and backend
 * @param date string a string format as follow 'yyyy-MM-dd'
 * @param zone string the user timezone
 * @return DateTime - the string converted to DateTime Luxon
 * @example
 * parseFromDateWithoutTime('2021-04-15');
 */
export function parseFromDateWithoutTime(date: string, zone?: string): DateTime {
  const dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd', { zone })
  if (!dateTime.isValid) {
    throw new Error(dateTime.invalidExplanation ?? `'${date}' can't be parsed as format yyyy-MM-dd`)
  }
  return dateTime
}

export type ISODateTime = string

export function formatHumanReadablePreviousDays(datetime: DateTime): string {
  const dateTime = DateTime.fromJSDate(datetime.toJSDate())
  let day = dateTime.toFormat('dd LLL. yyyy')

  if (dateTime.hasSame(DateTime.now(), 'day')) {
    day = 'Today'
  } else if (dateTime.hasSame(DateTime.now().plus({ day: -1 }), 'day')) {
    day = 'Yesterday'
  }
  return dateTime.toFormat(`'${day} at 'HH:mm`)
}
