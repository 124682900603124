import styled from '@emotion/styled'
import { ModuleRTDNames, ProviderJSON } from '@pubstack/common/src/catalogItem'
import { IdModuleNames, UserSyncUserIdJSON } from '@pubstack/common/src/userSync'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { JSONEditorInput } from '~/components/JSONEditor'
import { WithClassName } from '~/types/utils'

const JsonTextArea = styled.textarea`
  ${BorderRadius.style};
  outline: none;
  resize: none;
  border: 1px solid ${Colors.Platinum};
  white-space: pre;
  min-height: 200px;
  width: 100%;
  background-color: ${Colors.Ghost};
  ${Fonts.Mono};
  ${Fonts.colors.Jet};

  padding: 8px;

  &:active,
  &:focus {
    border-color: ${Colors.Silver};
  }
`
const Footer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`

const Error = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${Colors.Alert};
`

const Actions = styled.div`
  display: inline-flex;
  gap: 20px;
  align-items: center;
`

const JSON_SPACE_COUNT = 2

export type JSONEditorStatus = {
  error?: boolean
  dirty?: boolean
}

type PureAdStackPrebidModuleJSONEditorProps<
  ModuleName extends IdModuleNames | ModuleRTDNames,
  ModuleType = ModuleName extends IdModuleNames ? UserSyncUserIdJSON<ModuleName> : ModuleName extends ModuleRTDNames ? ProviderJSON<ModuleName> : never,
> = WithClassName & {
  params: ModuleType
  statusUpdate: (status: JSONEditorStatus) => void
  onValidate: (objectValue: ModuleType) => void
}
const _PureAdStackPrebidModuleJSONEditor = <ModuleName extends IdModuleNames | ModuleRTDNames>({
  className,
  params,
  statusUpdate,
  onValidate,
  ...props
}: PureAdStackPrebidModuleJSONEditorProps<ModuleName>) => {
  const paramsJsonValue = JSON.stringify(params, undefined, JSON_SPACE_COUNT)
  const defaultValues = {
    jsonValue: paramsJsonValue,
  }
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
  } = useForm<{ jsonValue: string }>({
    defaultValues,
    mode: 'onChange',
  })

  const update: SubmitHandler<{ jsonValue: string }> = (formValue) => {
    const res = JSON.parse(formValue.jsonValue)
    onValidate({ ...res })
    reset(formValue)
    statusUpdate({ dirty: false, error: false })
  }

  const restore = () => {
    reset(defaultValues)
    statusUpdate({ dirty: false, error: false })
  }

  useEffect(() => {
    statusUpdate({ dirty: !errors.jsonValue && isDirty, error: !!errors.jsonValue })
  }, [errors.jsonValue, isDirty])

  return (
    <form className={className} onSubmit={handleSubmit(update)}>
      <JSONEditorInput control={control} name={'jsonValue'} />
      <Footer>
        <Error>
          {errors.jsonValue?.message && (
            <>
              <Icon width={'16px'} name={'alert'} />
              <span>{errors.jsonValue?.message}</span>
            </>
          )}
        </Error>
        <Actions>
          {isDirty && (
            <Button onClick={restore} iconName={'restore'} variant={'tertiary'}>
              Restore
            </Button>
          )}
          <Button disabled={!!errors.jsonValue || !isDirty} type={'submit'}>
            Validate
          </Button>
        </Actions>
      </Footer>
    </form>
  )
}

export const PureAdStackPrebidModuleJSONEditor = styled(_PureAdStackPrebidModuleJSONEditor)``
