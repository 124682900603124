import styled from '@emotion/styled'
import { RefreshRule } from '@pubstack/common/src/stack'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import Button from '~/components/Button'
import { Input, _Input } from '~/components/Input'
import { Tooltip } from '~/components/Tooltip'
import Table, { handleTableSearch, handleTableSort, onColumnSort, SortableColumn, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { PureUsedStackTooltip } from '~/modules/adstack/rules/PureUsedStackTooltip'
import { countStacksUse } from '~/modules/adstack/rules/countStacksUsed'
import { WithClassName } from '~/types/utils'
import { RefreshWrapper } from './PureAdStackRefreshPage'

type PureAdStackRefreshRulesPageProps = WithClassName & {
  refreshRules: RefreshRule[]
  isLoading: boolean
  onCreateRule: () => void
  onEditRule: (id: string) => void
}

const TitleBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const ActionsTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down to hug action buttons */
`

const ButtonActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

const FilterAction = styled.form`
  margin: 12px 0px;
  display: flex;
  ${_Input} {
    width: 376px;
  }
`

type RefreshRuleListed = Omit<RefreshRule, 'creationTime' | 'technicalId'>

const _PureAdStackRefreshRulesPage: FunctionComponent<PureAdStackRefreshRulesPageProps> = ({ className, refreshRules, isLoading, onCreateRule, onEditRule }) => {
  const [columns, setColumns] = useState<TableColumns<RefreshRuleListed>>([
    {
      name: 'Rule',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'Viewability timer',
      isSortable: true,
      order: 'none',
      attributeSort: 'timer',
    },
    {
      name: 'Capping',
      isSortable: true,
      order: 'none',
      attributeSort: 'capping',
    },
    {
      name: 'Used on',
      isSortable: true,
      order: 'none',
      attributeSort: 'stacksUse',
    },
    {
      name: 'Actions',
      isSortable: false,
    },
  ])

  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })
  const refreshRulesWithNoRule: RefreshRuleListed[] = [...refreshRules, { id: 'NO_RULE', name: 'No refresh', timer: 0, capping: 0 }]
  const search = useWatch({ control, name: 'search' })
  const searchedRefreshRules = handleTableSearch(refreshRulesWithNoRule, search, ['name'])
  const sortedColumn = (columns.find((c) => c.isSortable && c.order !== 'none') || columns[0]) as SortableColumn<RefreshRule>
  let displayRefreshRules: RefreshRuleListed[] = []
  if (sortedColumn.attributeSort === 'stacksUse') {
    displayRefreshRules = searchedRefreshRules.sort((a, b) => (sortedColumn.order === 'ascending' ? 1 : -1) * (countStacksUse(b.stacksUse) - countStacksUse(a.stacksUse)))
  } else {
    displayRefreshRules = handleTableSort(columns, searchedRefreshRules)
  }

  return (
    <RefreshWrapper className={className}>
      <div>Set the refresh rules usable on stacks.</div>
      <TitleBar>
        <FilterAction>
          <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
        </FilterAction>
        <Button variant={'primary'} onClick={onCreateRule}>
          New rule
        </Button>
      </TitleBar>
      <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {displayRefreshRules.map((refreshRule) => (
          <TableRow key={refreshRule.id} onClick={refreshRule.scopeId ? () => onEditRule(refreshRule.id) : undefined}>
            <TableCell>{refreshRule.name}</TableCell>
            <TableCell>{refreshRule.id !== 'NO_RULE' ? `${refreshRule.timer}s` : '-'}</TableCell>
            <TableCell>{refreshRule.id !== 'NO_RULE' ? (refreshRule.capping ? `${refreshRule.capping} impressions` : 'No capping') : '-'}</TableCell>
            <TableCell>{refreshRule.id === 'NO_RULE' ? '-' : refreshRule.stacksUse ? <PureUsedStackTooltip stacksBySite={refreshRule.stacksUse} /> : <span>0 stack</span>}</TableCell>
            <ActionsTableCell>
              <ButtonActions>
                <Tooltip title={refreshRule.scopeId ? 'Edit' : `Can't edit a Pubstack rule`} positions={['left', 'bottom', 'top', 'right']}>
                  <Button iconName={'edit'} variant={'tertiary'} title={'Edit'} onClick={() => onEditRule(refreshRule.id)} disabled={!refreshRule.scopeId} />
                </Tooltip>
              </ButtonActions>
            </ActionsTableCell>
          </TableRow>
        ))}
      </Table>
    </RefreshWrapper>
  )
}

export const PureAdStackRefreshRulesPage = styled(_PureAdStackRefreshRulesPage)``
