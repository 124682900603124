import styled from '@emotion/styled'
import { SiteMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent } from 'react'
import { WidgetProps } from '~/components/Widget'
import { WithClassName } from '~/types/utils'
import { PureSiteBidDensityChart } from './PureSiteBidDensityChart'
import { PureSiteEcpmChart } from './PureSiteEcpmChart'
import { PureSiteInventoryChart, SiteInventoryTabLabel } from './PureSiteInventoryChart'
import { PureSiteRevenueChart } from './PureSiteRevenueChart'

const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }
`

const InlineLineChartContainer = styled.div`
  display: flex;

  & > * {
    flex: 1;
  }

  & > * + * {
    margin-left: 20px;
  }
`

type PureSiteChartsContainerProps = WithClassName &
  Omit<WidgetProps, 'title' | 'icon'> & {
    rawData?: SiteMetricsByTime
    currencySymbol: CurrencySymbol
    onInventoryTabChange: (tab: SiteInventoryTabLabel) => void
    timezone: Timezone
    currentEpoch: DateTime
  }
const _PureSiteChartsContainer: FunctionComponent<PureSiteChartsContainerProps> = ({ className, onInventoryTabChange, rawData, ...props }) => {
  const data = rawData ?? {
    epoch: [],
  }
  return (
    <ChartsContainer className={className}>
      <PureSiteRevenueChart data={data} {...props} />
      <PureSiteInventoryChart onTabChange={onInventoryTabChange} data={data} {...props} />
      <InlineLineChartContainer>
        <PureSiteEcpmChart data={data} {...props} />
        <PureSiteBidDensityChart data={data} {...props} />
      </InlineLineChartContainer>
    </ChartsContainer>
  )
}

export const PureSiteChartsContainer = styled(_PureSiteChartsContainer)``
