import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren } from 'react'
import { Color, Colors } from '~/assets/style/colors'
import { WithClassName } from '~/types/utils'

const ContentCardWrapper = styled.div<{ color: Color }>`
  background-color: ${Colors.White};
  padding: 20px;
  border-radius: 4px;
  position: relative;
  border-left: 6px solid ${({ color }) => color};
`

type ContentCardProps = WithClassName & { color: Color }
const _ContentCard: FunctionComponent<PropsWithChildren<ContentCardProps>> = ({ className, children, color }) => (
  <ContentCardWrapper color={color} className={className}>
    {children}
  </ContentCardWrapper>
)

export const ContentCard = styled(_ContentCard)``
