import { css } from '@emotion/react'
import { Fonts } from './fonts'
import { ElevationLevelValues } from './tokens'

const globalStyle = css`
  body {
    ${Fonts.P1}
    ${Fonts.colors.Jet}
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.01em;
  }

  html,
  :root {
    ${Fonts.P1}
    ${Fonts.colors.Jet}
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    tab-size: 4;
    word-break: normal;
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h1 {
    ${Fonts.H1}
    ${Fonts.colors.Jet}
  }

  h2 {
    ${Fonts.H2}
    ${Fonts.colors.Jet}
  }

  h3 {
    ${Fonts.H3}
    ${Fonts.colors.Jet}
  }

  p {
    margin: 0;
    padding: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    font-style: inherit;
    font-variant-ligatures: inherit;
    font-variant-caps: inherit;
    font-variant-numeric: inherit;
    font-variant-east-asian: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  *,
  :after,
  :before {
    background-repeat: no-repeat;
    box-sizing: inherit;
  }

  .google-visualization-tooltip {
    pointer-events: none !important;
    background: #ffffff !important;
    box-shadow: 0px 3px 10px rgba(54, 54, 54, 0.3) !important;
    border-radius: 4px !important;
    border: none !important;
  }

  div#hubspot-messages-iframe-container {
    z-index: ${ElevationLevelValues.low} !important;
  }
`

export default globalStyle
