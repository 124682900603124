import { IconName } from '~/components/Icon'

type BaseItem = {
  name: string
}

export type SimpleItem = BaseItem & {
  type?: 'item'
  to: string
  notifications?: number
}

export type GroupItem = BaseItem & {
  type: 'group'
  items: SimpleItem[]
}
export type Item = SimpleItem | GroupItem

export type Section = {
  title: string
  icon: IconName
  items: Item[]
}
export const buildSections = ({ alertsNotification }: { alertsNotification: number }): Section[] => [
  {
    title: 'Analytics',
    icon: 'chart_bar',
    items: [
      {
        type: 'group',
        name: 'General',
        items: [
          {
            name: 'Overview',
            to: '/analytics/overview',
          },
          {
            name: 'Explore',
            to: '/analytics/explore',
          },
          {
            name: 'Bidders',
            to: '/analytics/bidders',
          },
          {
            name: 'Sites',
            to: '/analytics/sites',
          },
          {
            name: 'Viewability',
            to: '/analytics/viewability',
          },
          {
            name: 'User sessions',
            to: '/analytics/user-session',
          },
          {
            name: 'Refresh',
            to: '/analytics/refresh',
          },
        ],
      },
      {
        type: 'group',
        name: 'Ad server',
        items: [
          {
            name: 'Web overview',
            to: '/analytics/gam-overview-web',
          },
          {
            name: 'App overview',
            to: '/analytics/gam-overview-app',
          },
          {
            name: 'AMP overview',
            to: '/analytics/gam-overview-amp',
          },
        ],
      },
      {
        name: 'Reports',
        to: '/reports',
      },
      {
        name: 'Alerts',
        to: '/monitors/summary',
        notifications: alertsNotification,
      },
    ],
  },
  {
    title: 'Ad management',
    icon: 'build',
    items: [
      {
        type: 'group',
        name: 'Inventory',
        items: [
          {
            name: 'Ad units',
            to: '/adstack/adunits',
          },
          {
            name: 'Contexts',
            to: '/adstack/context/contexts',
          },
          {
            name: 'Sites & stacks',
            to: '/adstack/sites',
          },
        ],
      },
      {
        type: 'group',
        name: 'Rules',
        items: [
          {
            name: 'Bidder',
            to: '/adstack/header-bidding',
          },
          {
            name: 'Lazy loading',
            to: '/adstack/lazy-loading',
          },
          {
            name: 'Floor price',
            to: '/adstack/floor-price',
          },
          {
            name: 'Refresh',
            to: '/adstack/refresh',
          },
        ],
      },
      {
        name: 'Integrations',
        to: '/adstack/integrations/my-integrations',
      },
      {
        name: 'Control center',
        to: '/adstack/control-center',
      },
    ],
  },
  {
    title: 'Marketplace',
    icon: 'store_front',
    items: [
      {
        name: 'Integration',
        to: '/marketplace/integration',
      },
    ],
  },
]
