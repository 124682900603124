import { DEFAULT_KLEANADS_SCOPE_CONFIG, KleanadsScopeConfig, PbjsDesiredVersion } from '@pubstack/common/src/kleanadsScopeConfig'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAdminKleanadsScopeConfig } from '~/api/admin-api.hook'
import { FetchError } from '~/api/api-access'
import { useToast } from '~/components/Toast/useToasts'
import { PureAdStackScopeConfig } from './PureAdStackScopeConfig'

export const AdminScopeAdStackConfig: FunctionComponent = () => {
  const { scopeId } = useParams()
  const { find, post, loading, abort, put } = useAdminKleanadsScopeConfig(null, scopeId ?? '')
  const [scopeConfig, setScopeConfig] = useState<KleanadsScopeConfig>()
  const toast = useToast()

  const loadScopeConfig = async () => {
    if (loading) {
      abort()
    }
    try {
      const scopeConfig = await find()
      setScopeConfig(scopeConfig)
    } catch (e) {
      if (e instanceof FetchError) {
        toast.alert(`An error has occurred, : ${e.response.status} - ${e.response.statusText}`)
      } else if (e instanceof Error) {
        toast.alert(`An error has occurred, : ${e.message}`)
      }
    }
  }

  const createScopeConfigData = async () => {
    try {
      await post(DEFAULT_KLEANADS_SCOPE_CONFIG)
      toast.success(`The scope config has been created. Please wait for 5 min before using it.`)
      loadScopeConfig()
    } catch (e) {
      toast.alert('An error has occurred, please retry later.')
    }
  }

  const updateScopeConfigPbjsVersion = async (pbjsDesiredVersion: PbjsDesiredVersion) => {
    try {
      await put(pbjsDesiredVersion)
      toast.success(`The desired pbjs version will be build soon and served according to the desired ratio.`)
      loadScopeConfig()
    } catch (e) {
      toast.alert('An error has occurred, please retry later.')
    }
  }

  useEffect(() => {
    setScopeConfig(DEFAULT_KLEANADS_SCOPE_CONFIG)
    loadScopeConfig()
  }, [scopeId])

  return <PureAdStackScopeConfig isLoading={loading} scopeConfig={scopeConfig} onCreateScopeConfig={createScopeConfigData} onUpdatePbjsVersion={updateScopeConfigPbjsVersion} />
}
