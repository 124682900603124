import styled from '@emotion/styled'
import { Role } from '@pubstack/common/src/rolesAndFeatures'
import { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { Toggle } from '~/components/Toggle'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'

const ProfileContentCard = styled(ContentCard)`
  display: flex;
  flex-direction: column;
  gap: 43px;
`

const ProfileContentCardCategory = styled.div``

const ProfileContentCardTitle = styled.h2`
  ${Fonts.colors.Jet}
  ${Fonts.H2}
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 16px;
`
const ProfileUserInfo = styled.div`
  display: flex;
  gap: 20px;
  & > * {
    flex-grow: 1;
  }
`
const ProfileNotification = styled.div``

const ProfileNotificationSettings = styled.div`
  ${Fonts.P1}
  ${Fonts.colors.Jet}
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 32px;
`

type PureSettingsProfilePageProps = WithClassName & {
  userInfo: { name: string; email: string; role: Role }
  isEmailNotificationsOn: boolean
  onToggleEmailNotifications: () => unknown
  onTestEmailSend: () => unknown
}
const _PureSettingsProfilePage: FunctionComponent<PureSettingsProfilePageProps> = ({ className, userInfo, onToggleEmailNotifications, isEmailNotificationsOn, onTestEmailSend }) => {
  const { control } = useForm({
    defaultValues: {
      ...userInfo,
    },
  })

  return (
    <ProfileContentCard className={className} color={SETTINGS_NAV_CONFIG['profile'].color}>
      <ProfileContentCardCategory>
        <ProfileContentCardTitle>
          <Icon name={'account_circle'} width={'22px'} />
          Personal information
        </ProfileContentCardTitle>
        <ProfileUserInfo>
          <Input disabled label={'Name'} control={control} name={'name'} />
          <Input disabled label={'E-mail address'} control={control} name={'email'} />
          <Input disabled label={'Role'} control={control} name={'role'} />
        </ProfileUserInfo>
      </ProfileContentCardCategory>
      <ProfileContentCardCategory>
        <ProfileNotification>
          <ProfileContentCardTitle>
            <Icon name={'notification'} width={'22px'} />
            Notifications
          </ProfileContentCardTitle>
          <ProfileNotificationSettings>
            <Toggle onClick={onToggleEmailNotifications} value={isEmailNotificationsOn} /> Receive notifications by e-mail
          </ProfileNotificationSettings>
          <Button variant={'secondary'} iconName={'mail'} onClick={onTestEmailSend}>
            Send test e-mail
          </Button>
        </ProfileNotification>
      </ProfileContentCardCategory>
    </ProfileContentCard>
  )
}

export const PureSettingsProfilePage = styled(_PureSettingsProfilePage)``
