import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { formatToDateWithoutTime } from '@pubstack/common/src/date'
import { useCallback } from 'react'
import { Context } from '~/state'
import { QueryParams, useUrlSync } from '~/utils/useUrlSync'

export function useSyncContext() {
  const [queries, setQueries] = useUrlSync()
  return (context: Context) => {
    let newParams: QueryParams = { ...queries, from: formatToDateWithoutTime(context.timeRange.from), to: formatToDateWithoutTime(context.timeRange.to) }
    if (Object.keys(context.filters).length) {
      newParams = { ...newParams, ctx: btoa(encodeURIComponent(JSON.stringify(context.filters))) }
    } else {
      delete newParams['ctx']
    }

    if (JSON.stringify(newParams) !== JSON.stringify(queries)) {
      setQueries(newParams)
    }
  }
}

export const useBuildContextWithFilterEnforced = (dimension: Dimension, { label, value }: { value: string; label: string }) => {
  return useCallback((context: Context): Context => {
    return {
      ...context,
      filters: {
        ...context.filters,
        [dimension]: {
          values: [{ label, value }],
          name: dimension,
          mode: 'include',
        },
      },
    }
  }, [])
}
