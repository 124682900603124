import { ExploreDimData } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import * as ReactDOMServer from 'react-dom/server'
import { ReactGoogleChartProps } from 'react-google-charts'
import { Color, DataColors } from '~/assets/style/colors'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'
import { LegendProps } from '~/components/Legend'
import { AnalyticsDefaultChartOptions, getAnalyticsChartFormatConfig } from '~/modules/analytics/AnalyticsCharts'
import { FormulaObj, sumObj } from '~/modules/analytics/formulas/operation-obj'
import { ANALYTICS_CHART_COLOR_CONFIG } from '~/utils/analytics'
import { DataDistributionType } from './explore'

const colors: { [key: string | number]: Color } = {
  0: DataColors.Lilac,
  1: DataColors.Pool,
  2: DataColors.Milka,
  3: DataColors.Forest,
  4: DataColors.Frog,
  5: DataColors.Sun,
  6: DataColors.Kaiminus,
  7: DataColors.Rose,
  8: DataColors.Violet,
  9: DataColors.Lava,
  'impression-v1': ANALYTICS_CHART_COLOR_CONFIG.hb.color,
  'impression-adx-v1': ANALYTICS_CHART_COLOR_CONFIG.adx.color,
  'impression-open-bidding-v1': ANALYTICS_CHART_COLOR_CONFIG.openBidding.color,
  firstCall: ANALYTICS_CHART_COLOR_CONFIG.firstCall.color,
  refresh: ANALYTICS_CHART_COLOR_CONFIG.refresh.color,
  others: DataColors.SlateGrey,
}

const getChartTooltip = (name: string, formula: FormulaObj<any>, rawData: RawData, value: number, color: Color, currencySymbol: CurrencySymbol, type: DataDistributionType) => {
  const metricLine = {
    label: formula.name,
    iconColor: color,
    value: (type === 'percentage' ? formula.displayablePercentage : formula.displayable)(value, currencySymbol),
  } as ChartTooltipMetricProps
  const metrics: ChartTooltipMetricProps[][] = [[metricLine]]
  const tooltipContent = <ChartTooltip date={name} metrics={metrics} />
  return ReactDOMServer.renderToString(tooltipContent)
}

const convertData = (rawData: RawData, formula: FormulaObj<any>, currencySymbol: CurrencySymbol, type: DataDistributionType): any[][] => {
  const sum = sumObj(rawData as any, 0)
  const rawValue = type === 'percentage' ? formula.percentage(rawData, 0) : formula.raw(sum as any)
  return Object.entries(rawData.mappings).map(([key, { label }], index) => {
    const value = rawValue[key]
    const color = colors[key] ?? colors[index]
    return [label, value ?? 0, color, getChartTooltip(label, formula, rawData, value, color, currencySymbol, type)]
  })
}

type RawData = ExploreDimData<any>
type PureExploreLeaderboardChartProps = {
  rawData?: RawData
  formula: FormulaObj<any>
  currencySymbol: CurrencySymbol
  type: DataDistributionType
}

export const getExploreLeaderboard = ({ rawData, formula, currencySymbol, type }: PureExploreLeaderboardChartProps): { chart: ReactGoogleChartProps; legends: LegendProps[] } => {
  if (!rawData || Object.keys(rawData.mappings ?? {}).length === 0) {
    return { chart: { data: undefined, chartType: 'BarChart' }, legends: [] }
  }

  const legends: LegendProps[] = Object.entries(rawData.mappings).map(([key, { label }], index) => ({ label, iconColor: colors[key] ?? colors[index] }))
  const chartOptions = {
    ...AnalyticsDefaultChartOptions,
    chartArea: { ...AnalyticsDefaultChartOptions.chartArea, left: 100, right: 20, bottom: 20, top: 20 },
    hAxis: {
      ...AnalyticsDefaultChartOptions.hAxis,
      ...getAnalyticsChartFormatConfig(type === 'percentage' ? type : formula.type, currencySymbol),
      minValue: 0,
      gridlines: { ...AnalyticsDefaultChartOptions.hAxis?.gridLines, count: 3 },
    },
    vAxis: {
      ...AnalyticsDefaultChartOptions.vAxis,
      gridlines: { color: 'none' },
    },
    focusTarget: undefined,
    animation: {
      ...AnalyticsDefaultChartOptions.animation,
      easing: 'inAndOut',
    },
    colors: Object.values(colors),
  }
  const chartData = [['label', 'value', { role: 'style' }, { type: 'string', role: 'tooltip', p: { html: true } }], ...convertData(rawData, formula, currencySymbol, type)]
  return { chart: { chartType: 'BarChart', options: chartOptions, data: chartData }, legends }
}
