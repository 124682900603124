import styled from '@emotion/styled'
import { Mediatype, SKIN_SINGLE_AD } from '@pubstack/common/src/adFormat'
import { AdUnit, isOutOfPage } from '@pubstack/common/src/adunit'
import { AdUnitDevice } from '@pubstack/common/src/adunitDevice'
import { divIdRegexp, noLeadingAndTrailingSpace, noSpaceQuoteAllowedRegExp } from '@pubstack/common/src/input'
import { FunctionComponent } from 'react'
import { Control, Controller, FieldErrors, UseFormSetValue, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Selector } from '~/components/Selector'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'
import { toggleValueInArray } from '~/utils/array'
import { AdFormatDisplay, HIGH_IMPACT_AD_FORMATS, STANDARD_AD_FORMATS, useAdFormat } from './AdFormatDisplay'
import { AdUnitForm, DeviceChoice, ErrorMessage } from './PureAdStackAdUnitEditPage'
import { AdFormatCard } from './components/AdFormatCard'

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const FormWrapper = styled.div`
  padding: 20px 0;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  ${Fonts.P1}
  h2 {
    ${Fonts.H2}
    font-weight: 500;
    gap: 4px;
    display: flex;
    align-items: center;
  }
  ${Tooltip} {
    display: inline-flex;
  }
`

const AdUnitIdentification = styled.div`
  display: flex;
  gap: 20px;
  ${_Input} {
    flex-basis: 304px;
  }
`

const NoAdFormat = styled.div`
  font-style: italic;
`

const AdFormatTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
`

const AdFormatList = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 14px;
  column-gap: 16px;
  flex-wrap: wrap;
  :not(:last-child) {
    margin-bottom: 16px;
  }
`

const AdFormatCardWrapper = styled(AdFormatCard)`
  flex-basis: auto;
`

type AdFormatCategory = {
  name: string
  list: AdFormatDisplay[]
}

const FilterActions = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  ${_Input} {
    width: 468px;
  }
`

const AdFormatCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

type DeviceChangeModalProps = { onClickPrimary: () => unknown; changedProperty: 'device' | 'format' }
const DeviceChangeModal: FunctionComponent<DeviceChangeModalProps> = ({ onClickPrimary, changedProperty }) => {
  const modal = useGlobalModal()

  const close = () => {
    modal.close()
  }

  return (
    <Modal.Content>
      <Modal.Title>Edit {changedProperty}</Modal.Title>

      <Modal.Body>
        <p>
          Changing the {changedProperty} impacts {changedProperty === 'device' ? 'format' : 'mediatypes'} and/or sizes.{' '}
        </p>
        <p>You will have to set them again. </p>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Keep current {changedProperty}
        </Button>
        <Button
          onClick={() => {
            onClickPrimary()
            close()
          }}
        >
          Change {changedProperty}
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

type PureAdStackAdUnitFirstStepProps = WithClassName & {
  control: Control<AdUnitForm>
  errors: FieldErrors<AdUnitForm>
  setValue: UseFormSetValue<AdUnitForm>
  isEditing: boolean
  validateUniqueName: (name?: string) => Promise<boolean>
}

const _PureAdStackAdUnitFirstStep: FunctionComponent<PureAdStackAdUnitFirstStepProps> = ({ control, errors, setValue, isEditing, className, validateUniqueName }) => {
  const modal = useGlobalModal()
  const { adFormat, devices, sizes, customSizes } = useWatch({ control })
  const selectedAdFormat = useAdFormat(adFormat?.id)
  const { control: searchControl } = useForm<{ search: string; filteredMediatype: Mediatype | 'all' }>({
    defaultValues: {
      search: '',
      filteredMediatype: 'all',
    },
  })
  const search = useWatch({ control: searchControl, name: 'search' })
  const filteredMediatype = useWatch({ control: searchControl, name: 'filteredMediatype' })
  const mediatypesOptions: SelectOptionProp[] = [
    {
      label: 'All mediatypes',
      value: 'all',
    },
    {
      label: 'Banner',
      value: 'Banner',
    },
    {
      label: 'Outstream',
      value: 'Outstream',
    },
  ]

  const filterAdFormats = (adFormats: AdFormatDisplay[]): AdFormatDisplay[] => {
    const adFormatsFilteredByDevices = devices && devices.length ? adFormats.filter((adFormatDisplay) => devices.every((device) => adFormatDisplay.devices.includes(device))) : []
    const adFormatsFilteredByMediatype = filteredMediatype === 'all' ? adFormatsFilteredByDevices : adFormatsFilteredByDevices.filter((adFormat) => adFormat.mediatypes.includes(filteredMediatype))
    return adFormatsFilteredByMediatype.filter((adFormat) => adFormat.name.toLowerCase().includes(search.toLowerCase()))
  }
  const adFormatCategories: AdFormatCategory[] = [
    {
      name: 'Standard',
      list: filterAdFormats(STANDARD_AD_FORMATS),
    },
    {
      name: 'High impact',
      list: filterAdFormats(HIGH_IMPACT_AD_FORMATS),
    },
  ]

  const toggleDevice = (onChange: (event: any[]) => void, value: AdUnitDevice[], device: AdUnitDevice) => {
    onChange(toggleValueInArray(value ?? [], device))
    setValue('adFormat', undefined)
    setValue('dynamicEnabled', false)
    setValue('anchor', undefined)
  }

  const toggleFormat = (onChange: (...event: any[]) => void, adFormatDisplay: AdFormatDisplay) => {
    onChange(adFormatDisplay)

    // using default values provided by the adformat if any
    adFormatDisplay.defaultValues &&
      Object.entries(adFormatDisplay.defaultValues).forEach(([key, value]) => {
        setValue(key as keyof AdUnit, value)
      })

    setValue('bannerEnabled', !isOutOfPage(adFormatDisplay.id) && adFormatDisplay.mediatypes.includes('Banner'))
    setValue('canOutstream', !isOutOfPage(adFormatDisplay.id) && adFormatDisplay.mediatypes.includes('Outstream'))
    if (adFormatDisplay.id === SKIN_SINGLE_AD.id) {
      setValue('skinEnabled', true)
    } else {
      setValue('skinEnabled', false)
    }
  }

  return (
    <div className={className}>
      <FormWrapper>
        <FormHeader>
          <h2>Identification</h2>
        </FormHeader>
        <AdUnitIdentification>
          <Input
            type={'text'}
            label={'Name'}
            control={control}
            name={'name'}
            helper={'Used in Ad management only.'}
            error={errors.name?.message}
            rules={{
              required: { value: true, message: 'Please fill this to continue.' },
              pattern: { value: noLeadingAndTrailingSpace, message: 'No leading or trailing space allowed.' },
              validate: {
                uniqueName: async (value) => {
                  if (isEditing) {
                    return true
                  }
                  if (typeof value === 'string') {
                    const isUnique = await validateUniqueName(value)
                    if (!isUnique) {
                      return 'This name is already used.'
                    }
                    return true
                  }
                  return false
                },
              },
            }}
            disabled={isEditing}
          />
          <Input
            type={'text'}
            label={'Ad unit code in the ad server'}
            control={control}
            name={'adServerAdUnitName'}
            helper={'Used in ad server, Analytic and Reports.'}
            error={errors.adServerAdUnitName?.message}
            rules={{
              required: { value: true, message: 'Please fill this to continue.' },
              pattern: { value: noSpaceQuoteAllowedRegExp, message: 'No space or quote allowed.' },
            }}
          />
          <Input
            type={'text'}
            label={'Technical identification (div ID)'}
            control={control}
            name={'divId'}
            helper={'Unique identifier of the HTML element'}
            error={errors.divId?.message}
            rules={{
              required: { value: true, message: 'Please fill this to continue.' },
              pattern: { value: divIdRegexp, message: 'No space or quote allowed.' },
            }}
          />
        </AdUnitIdentification>
      </FormWrapper>
      <FormWrapper>
        <FormHeader>
          <h2>Devices</h2>
          Select all the devices the ad unit will be used on.
        </FormHeader>
        <Controller
          control={control}
          rules={{
            required: 'Choose at least one device.',
          }}
          name={'devices'}
          render={({ field: { onChange, value } }) => (
            <DeviceChoice>
              <Selector
                selected={value.includes('desktop')}
                title={'Desktop'}
                icon={'desktop'}
                onClick={() =>
                  selectedAdFormat
                    ? modal.open(DeviceChangeModal, {
                        onClickPrimary: () => {
                          toggleDevice(onChange, value, 'desktop')
                        },
                        changedProperty: 'device',
                      })
                    : toggleDevice(onChange, value, 'desktop')
                }
                selectedColor={Colors.Petrol}
              />
              <Selector
                selected={value.includes('mobile')}
                title={'Mobile'}
                icon={'mobile'}
                onClick={() =>
                  selectedAdFormat
                    ? modal.open(DeviceChangeModal, {
                        onClickPrimary: () => {
                          toggleDevice(onChange, value, 'mobile')
                        },
                        changedProperty: 'device',
                      })
                    : toggleDevice(onChange, value, 'mobile')
                }
                selectedColor={Colors.Pool}
              />
              {errors.devices && (
                <ErrorMessage>
                  <Icon name={'alert'} width={'0.875rem'} />
                  {errors.devices.message}
                </ErrorMessage>
              )}
            </DeviceChoice>
          )}
        />
      </FormWrapper>
      <FormWrapper>
        <FormHeader>
          <h2>Format</h2>
          Select one format.
        </FormHeader>
        {devices && devices.length ? (
          <div>
            <FilterActions>
              <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={searchControl} />
              <Select name={'filteredMediatype'} options={mediatypesOptions} label={''} control={searchControl} />
            </FilterActions>
            <Controller
              control={control}
              name={'adFormat'}
              rules={{
                required: 'Select at least one ad format.',
              }}
              render={({ field: { onChange } }) => (
                <AdFormatCategoryWrapper>
                  {adFormatCategories.map((adFormatCategory) => (
                    <div key={adFormatCategory.name}>
                      <AdFormatTitle>{adFormatCategory.name}</AdFormatTitle>
                      <AdFormatList>
                        {adFormatCategory.list.map((adFormatDisplay) => (
                          <AdFormatCardWrapper
                            key={adFormatDisplay.name}
                            adFormat={adFormatDisplay}
                            isSelected={selectedAdFormat ? selectedAdFormat.id === adFormatDisplay.id : false}
                            onClick={() => {
                              if (selectedAdFormat?.id !== adFormatDisplay.id) {
                                selectedAdFormat && (sizes?.length || customSizes?.length)
                                  ? modal.open(DeviceChangeModal, {
                                      onClickPrimary: () => {
                                        toggleFormat(onChange, adFormatDisplay)
                                      },
                                      changedProperty: 'format',
                                    })
                                  : toggleFormat(onChange, adFormatDisplay)
                              }
                            }}
                          />
                        ))}
                      </AdFormatList>
                    </div>
                  ))}
                  {errors.adFormat && (
                    <ErrorMessage>
                      <Icon name={'alert'} width={'0.875rem'} />
                      {errors.adFormat.message}
                    </ErrorMessage>
                  )}
                </AdFormatCategoryWrapper>
              )}
            />
          </div>
        ) : (
          <NoAdFormat>Choose devices to see compatible formats.</NoAdFormat>
        )}
      </FormWrapper>
    </div>
  )
}

export const PureAdStackAdUnitFirstStep = styled(_PureAdStackAdUnitFirstStep)``
