import styled from '@emotion/styled'
import type { AdUnit } from '@pubstack/common/src/adunit'
import type { DisplayedBidder, DisplayedStack, LazyLoadingRule, SyncCategory } from '@pubstack/common/src/stack'
import { Colors } from '~/assets/style/colors'
import { Icon } from '~/components/Icon'
import { Tooltip } from '~/components/Tooltip'
import { RichDetails } from '~/modules/adstack/inventory/sites/stack/components/RichDetails'
import type { WithClassName } from '~/types/utils'

const OutOfSyncWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${Colors.Warning};
`
const Disabled = styled.span`
  font-style: italic;
  color: ${Colors.Ash};
`

type OutOfSyncProps = WithClassName & {
  detailedStack: DisplayedStack
  name: string
  category: SyncCategory
  adUnit?: AdUnit
  bidder?: DisplayedBidder
  lazyLoadingRule?: LazyLoadingRule
}

const _OutOfSync = ({ className, detailedStack, category, name, adUnit, bidder, lazyLoadingRule }: OutOfSyncProps) => {
  const categoryElements = detailedStack.elementsToSync?.find((e) => e.category === category)?.elements || []
  const changes = category === 'Floor price rule' ? categoryElements.find((el) => name.startsWith(`${el.name} (`))?.changes ?? [] : categoryElements.find((el) => el.name === name)?.changes ?? []
  const isDisabled = changes.some((el) => el.startsWith('ad unit')) || false

  return changes.length > 0 ? (
    <OutOfSyncWrapper className={className}>
      <Icon width={'18px'} name={'sync_problem'} fill={Colors.Warning} />
      <Tooltip title={<RichDetails adUnit={adUnit} bidder={bidder} lazyLoadingRule={lazyLoadingRule} differences={changes} name={name} isDisabled={isDisabled} />}>
        {isDisabled ? <Disabled>{name}</Disabled> : <span>{name}</span>}
      </Tooltip>
    </OutOfSyncWrapper>
  ) : (
    <Tooltip title={<RichDetails adUnit={adUnit} bidder={bidder} lazyLoadingRule={lazyLoadingRule} name={name} isDisabled={isDisabled} />}>
      <span className={className}>{name}</span>
    </Tooltip>
  )
}

export const OutOfSync = styled(_OutOfSync)``
