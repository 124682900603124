import { SerializedStyles, css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ForwardedRef, ReactNode, forwardRef, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Sizes, Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'

type CheckboxVariant = 'primary' | 'alert'

type CheckboxProps = WithClassName &
  Omit<ComponentProps<'input'>, 'onChange'> & {
    checked: boolean
    disabled?: boolean
    indeterminate?: boolean
    label?: ReactNode
    onChange?: (checked: boolean) => void
    variant?: CheckboxVariant
  }
const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
`
const CheckboxLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  line-height: 20px;
`
const CheckBoxLabelWrapper = styled.div<{ disabled?: boolean }>`
  display: inline;
  margin-left: 4px;
  color: ${({ disabled }) => (disabled ? Colors.Ash : Colors.Jet)};
`
const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  height: 0;
`

const CheckboxIconBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CheckboxColorPalette: { [key in CheckboxVariant]: { [key in 'default' | 'active' | 'checked' | 'focus' | 'disabledDefault' | 'disabledChecked' | 'indeterminate']: SerializedStyles } } = {
  alert: {
    default: css`
      --box-bg: ${Colors.White};
      --box-hover-bg: ${Colors.Mew};
      --box-border: 2px solid ${Colors.Cherry};
      --box-hover-border: 2px solid ${Colors.Alert};
      --box-icon-color: ${Colors.White};
    `,
    checked: css`
      --box-bg: ${Colors.Cherry};
      --box-hover-bg: ${Colors.Alert};
      --box-border: 2px solid ${Colors.Cherry};
      --box-hover-border: 2px solid ${Colors.Alert};
    `,
    focus: css`
      --box-bg: ${Colors.Mew};
      box-shadow: 0 0 0 2px ${Colors.Mew};
      :hover {
        box-shadow: none;
      }
    `,
    active: css`
      --box-bg: ${Colors.Mew};
      --box-hover-bg: ${Colors.Mew};
      --box-border: 2px solid ${Colors.Alert};
      --box-icon-color: ${Colors.Alert};
    `,
    disabledDefault: css`
      --box-bg: ${Colors.Platinum};
      --box-hover-bg: ${Colors.Platinum};
      --box-border: 2px solid ${Colors.Silver};
      --box-icon-color: ${Colors.Silver};
      --box-hover-border: 2px solid ${Colors.Silver};
    `,
    disabledChecked: css`
      --box-bg: ${Colors.Silver};
      --box-hover-bg: ${Colors.Silver};
      --box-border: 2px solid ${Colors.Silver};
      --box-icon-color: ${Colors.Platinum};
      --box-hover-border: 2px solid ${Colors.Silver};
    `,
    indeterminate: css`
      --box-bg: ${Colors.Cherry};
      --box-hover-bg: ${Colors.Alert};
      --box-border: 2px solid ${Colors.Cherry};
      --box-hover-border: 2px solid ${Colors.Alert};
      --icon-fill-color: var(--box-icon-color);
    `,
  },
  primary: {
    default: css`
      --box-bg: ${Colors.White};
      --box-hover-bg: ${Colors.Topaze};
      --box-border: 2px solid ${Colors.King};
      --box-hover-border: 2px solid ${Colors.Cobalt};
      --box-icon-color: ${Colors.White};
    `,
    checked: css`
      --box-bg: ${Colors.King};
      --box-hover-bg: ${Colors.Cobalt};
      --box-border: 2px solid ${Colors.King};
      --box-hover-border: 2px solid ${Colors.Cobalt};
    `,
    focus: css`
      --box-bg: ${Colors.Topaze};
      box-shadow: 0 0 0 2px ${Colors.Topaze};
      :hover {
        box-shadow: none;
      }
    `,
    active: css`
      --box-bg: ${Colors.Topaze};
      --box-hover-bg: ${Colors.Topaze};
      --box-border: 2px solid ${Colors.Cobalt};
      --box-icon-color: ${Colors.Cobalt};
    `,
    disabledDefault: css`
      --box-bg: ${Colors.Platinum};
      --box-hover-bg: ${Colors.Platinum};
      --box-border: 2px solid ${Colors.Silver};
      --box-icon-color: ${Colors.Silver};
      --box-hover-border: 2px solid ${Colors.Silver};
    `,
    disabledChecked: css`
      --box-bg: ${Colors.Silver};
      --box-hover-bg: ${Colors.Silver};
      --box-border: 2px solid ${Colors.Silver};
      --box-icon-color: ${Colors.Platinum};
      --box-hover-border: 2px solid ${Colors.Silver};
    `,
    indeterminate: css`
      --box-bg: ${Colors.King};
      --box-hover-bg: ${Colors.Cobalt};
      --box-border: 2px solid ${Colors.King};
      --box-hover-border: 2px solid ${Colors.Cobalt};
      --icon-fill-color: var(--box-icon-color);
    `,
  },
}

const CheckboxIconWrapper = styled.div<{ checked: boolean; active: boolean; focus: boolean; disabled?: boolean; indeterminate?: boolean; variant: CheckboxVariant }>`
  ${({ variant }) => CheckboxColorPalette[variant].default}

  box-sizing: border-box;
  display: inline-flex;
  height: 15px;
  width: 15px;
  transition: ${Transitions.quick};
  position: relative;
  border: var(--box-border);
  border-radius: 3px;
  background: var(--box-bg);

  :hover {
    background: var(--box-hover-bg);
    border: var(--box-hover-border);
  }

  ${({ focus, variant }) => focus && CheckboxColorPalette[variant].focus}
  ${({ checked, variant }) => checked && CheckboxColorPalette[variant].checked}
  ${({ indeterminate, variant }) => indeterminate && CheckboxColorPalette[variant].indeterminate}
  ${({ active, variant }) => active && CheckboxColorPalette[variant].active}
  ${({ disabled, checked, indeterminate, variant }) => disabled && (checked || indeterminate ? CheckboxColorPalette[variant].disabledChecked : CheckboxColorPalette[variant].disabledDefault)}

  svg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    path {
      fill: var(--icon-fill-color, none);
      transition: ${Transitions.quick};
      ${({ indeterminate, checked }) =>
        indeterminate
          ? css`
              stroke-width: ${Sizes[2]};
              stroke: var(--box-icon-color);
            `
          : css`
              stroke-width: ${Sizes[4]};
              stroke-dashoffset: ${checked ? 0 : 30};
              stroke-dasharray: 30;
              stroke: var(--box-icon-color);
            `}
  }
`

const _Checkbox = ({ checked, disabled, indeterminate, label, onChange, className, variant = 'primary', ...props }: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
  const [isActive, setActive] = useState(false)
  const [isFocus, setFocus] = useState(false)
  return (
    <CheckboxWrapper onMouseDown={() => setActive(true)} onMouseUp={() => setActive(false)} onMouseLeave={() => setActive(false)} className={className}>
      <CheckboxLabel>
        <CheckboxInput
          {...props}
          type={'checkbox'}
          disabled={disabled}
          defaultChecked={checked}
          onChange={() => !!onChange && onChange(indeterminate ? true : !checked)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          ref={ref}
        />
        <CheckboxIconBox>
          <CheckboxIconWrapper variant={variant} checked={checked} active={isActive} focus={isFocus} disabled={disabled} indeterminate={indeterminate}>
            <svg viewBox={'0 0 24 24'}>
              <path
                d={indeterminate ? 'M18 12H6C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14H18C18.5523 14 19 13.5523 19 13C19 12.4477 18.5523 12 18 12Z' : 'M1.73,12.91 8.1,19.28 22.79,4.59'}
              />
            </svg>
          </CheckboxIconWrapper>
        </CheckboxIconBox>
        {!!label && <CheckBoxLabelWrapper disabled={disabled}>{label}</CheckBoxLabelWrapper>}
      </CheckboxLabel>
    </CheckboxWrapper>
  )
}
export const Checkbox = styled(forwardRef<HTMLInputElement, CheckboxProps>(_Checkbox))``
