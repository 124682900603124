import styled from '@emotion/styled'
import { MarketplaceBidder } from '@pubstack/common/src/marketplaceBidder'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { AdStackIntegrationLogo } from '~/components/AdStackIntegrationLogo'
import Button from '~/components/Button'
import Chip from '~/components/Chip'
import { Link } from '~/components/Link'
import { Modal } from '~/components/Modal'
import { Skeleton } from '~/components/Skeleton'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'

type PureIntegrationPageProps = WithClassName & { bidders: MarketplaceBidder[]; loading: boolean; static_CDN_url: string; onToggle: (bidder: MarketplaceBidder) => Promise<void> }

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const SspWrapper = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(286px,1fr));
    gap: 20px;
`

const ModuleCard = styled.div`
    box-sizing: border-box;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 16px 16px 16px;
  background-color: ${Colors.White};
    & img {
        align-self: center;
        max-height: 35px;
        max-width: 190px;
    }
`

const SeeLegalModal: FunctionComponent<{
  bidder: MarketplaceBidder
}> = ({ bidder }) => {
  const { close } = useGlobalModal()

  return (
    <Modal.Content>
      <Modal.Title>
        <h2>{bidder.name}: Legal information</h2>
      </Modal.Title>

      <Modal.Body>
        <div>
          <div>
            <b>Processing description:</b> {bidder.legalInformation.processingDescription}
          </div>
          <div>
            <b>Existence of transfer outside the EU:</b> {bidder.legalInformation.transferOutsideEU}
          </div>
          <div>
            <b>Use of TCF:</b> {bidder.legalInformation.TCF}
          </div>
          <div>
            <b>Restriction on data reuse:</b> {bidder.legalInformation.restrictionDataReuse}
          </div>
          <div>
            <b>DPO contact:</b> {bidder.legalInformation.DpoContact}
          </div>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Close
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const _PureIntegrationPage: FunctionComponent<PureIntegrationPageProps> = ({ className, bidders, loading, onToggle, static_CDN_url }) => {
  const modal = useGlobalModal()
  const sortedBidders = bidders.sort((a, b) => a.name.localeCompare(b.name))
  return (
    <Wrapper>
      <h1>Marketplace Integration</h1>
      {loading ? (
        <Skeleton />
      ) : (
        <SspWrapper>
          {sortedBidders.map((bidder, index) => (
            <ModuleCard key={bidder.id} color={'#fffff'}>
              <AdStackIntegrationLogo baseCDNUrl={static_CDN_url} name={bidder.id} />
              <span>
                <b>SSP Name: </b>
                {bidder.name}
              </span>
              <span>
                <b>DSA Compatible: </b>
                {bidder.dsaCompatible ? <Chip color={Colors.Frog} text={'yes'}></Chip> : <Chip color={Colors.Alert} text={'no'}></Chip>}
              </span>
              <span>
                <b>GDPR Qualification: </b>
                {bidder.gdprQualification}
              </span>
              <Link
                label={'Legal information'}
                onClick={() => {
                  modal.open(SeeLegalModal, { bidder })
                }}
              />
              <Link icon={'open_in_new'} href={bidder.privacyPolicies} target={'_blank'} rel={'noreferrer'} label={'Privacy Policies'} />
              {bidder.termsAndConditions !== 'confidential' ? (
                <Link icon={'open_in_new'} href={bidder.termsAndConditions} target={'_blank'} rel={'noreferrer'} label={'Terms & conditions'} />
              ) : (
                <Tooltip title={'confidential - available on demand'}>Terms & conditions</Tooltip>
              )}
              <Link icon={'open_in_new'} href={bidder.dpa} target={'_blank'} rel={'noreferrer'} label={'DPA'} />
              <Toggle value={bidder.activated} onClick={() => onToggle(bidder)} />
            </ModuleCard>
          ))}
        </SspWrapper>
      )}
    </Wrapper>
  )
}

export const PureIntegrationPage = styled(_PureIntegrationPage)``
