import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'

export type SelectableItemProps = WithClassName & {
  onClick?: () => void
  selected?: boolean
  text: string
}

const SelectableItemWrapper = styled.div<{ selected?: boolean }>`
  border-radius: 32px;
  padding: 6px 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  ${Fonts.P1};
  ${Fonts.colors.King};

  &:hover {
    background: ${Colors.Topaze};
  }

  &:active {
    background: ${Colors.King};
    ${Fonts.colors.White};

    :hover {
      background: ${Colors.Cobalt};
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${Colors.King};
      ${Fonts.colors.White};

      &:hover {
        background: ${Colors.Cobalt};
      }

      &:active {
        background: ${Colors.Cobalt};
      }
    `}
`

const _SelectableItem: FunctionComponent<SelectableItemProps> = ({ text, ...props }) => <SelectableItemWrapper {...props}>{text}</SelectableItemWrapper>

const SelectableItem = styled(_SelectableItem)``
export default SelectableItem
