import styled from '@emotion/styled'
import { formatHumanReadable, sameDay } from '@pubstack/common/src/date'
import { GranularityToUnit, Report, ReportNameType, ReportPeriod, ReportsConfiguration, reportsConfiguration } from '@pubstack/common/src/report'
import { lowerCaseFirstLetter } from '@pubstack/common/src/stringUtils'
import { DateTime, Interval } from 'luxon'
import { FunctionComponent, useMemo, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import Button from '~/components/Button'
import Datepicker from '~/components/Datepicker'
import { Modal } from '~/components/Modal'
import { Popover } from '~/components/Popover'
import { Spinner } from '~/components/Spinner'
import { IntervalWithLabel } from '~/components/datepicker.utils'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'
import { PureReportsSelector, ReportSelectorOption } from './PureReportsSelector'
import { PureReportsTable } from './PureReportsTable'

const Wrapper = styled.div`
  padding: 20px;
`

const Title = styled.div`
  ${Fonts.H1};
`

const SubTitle = styled.div`
  ${Fonts.P1};
  flex: 1 1 auto;
`

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: ${Sizes[20]};
  margin-bottom: ${Sizes[16]};
`

const CreateNewReportZone = styled.div`
  margin-top: ${Sizes[16]};
  margin-bottom: 2.5rem;
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Platinum};
  border-radius: ${Sizes[4]};
  padding: ${Sizes[16]} ${Sizes[12]};

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;

  ${Button} {
    margin: 0 0.5rem;
  }
`

const CreateReportButton = styled(Button)``

const CreateNewReportSelectionWrapper = styled.span`
  flex: 1 1 auto;
  font-size: 0.875rem; // TODO 2022-11-07 nra tmu should not exists
`

const getIntervalAsString = (selectedRange: IntervalWithLabel) => {
  if (selectedRange.label === 'custom') {
    return sameDay(selectedRange.interval.start, selectedRange.interval.end)
      ? formatHumanReadable(selectedRange.interval.start)
      : `${formatHumanReadable(selectedRange.interval.start)} to ${formatHumanReadable(selectedRange.interval.end)}`
  }
  return lowerCaseFirstLetter(selectedRange.label)
}

type ReportTimeSelectorProps = {
  onUpdate: (interval: IntervalWithLabel) => void
  selectedRange?: IntervalWithLabel
  today: DateTime
  selectableInterval: Interval
  maxNumberOfDays: number
  initialSelectedInterval: {
    label: string
    interval: Interval
  }
}
const ReportTimeSelector: FunctionComponent<ReportTimeSelectorProps> = ({ today, onUpdate, selectableInterval, selectedRange, maxNumberOfDays, initialSelectedInterval }) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const timePeriodLabelBefore = selectedRange ? (sameDay(selectedRange.interval.start, selectedRange.interval.end) || selectedRange.label !== 'custom' ? 'on' : 'from') : 'on'
  const intervalLabel = selectedRange ? getIntervalAsString(selectedRange) : 'Time period'
  const disclaimer = useMemo(() => {
    const text = `${formatHumanReadable(selectableInterval.start)} and ${formatHumanReadable(selectableInterval.end.minus({ days: 1 }))}`
    if (maxNumberOfDays) {
      return `You can select up to ${maxNumberOfDays} days between ${text}`
    } else {
      return `You can select any date range between ${text}`
    }
  }, [maxNumberOfDays, selectableInterval])
  return (
    <>
      {timePeriodLabelBefore}
      <Popover trigger={<Button variant={'secondary'}>{intervalLabel}</Button>} open={datePickerOpen} setOpen={setDatePickerOpen} align={'center'}>
        <Datepicker
          selectableInterval={selectableInterval}
          initialSelectedInterval={initialSelectedInterval}
          maxNumberOfDayInTimerangeSelected={maxNumberOfDays}
          today={today}
          disclaimer={disclaimer}
          onTimerangeSelected={(selection) => {
            onUpdate({ label: selection.label, interval: Interval.fromDateTimes(selection.interval.start.startOf('day'), selection.interval.end.endOf('day')) })
            setDatePickerOpen(false)
          }}
        />
      </Popover>
    </>
  )
}

type ReportConfig = ReportsConfiguration[keyof ReportsConfiguration]
const getFormattedPeriodString = (reportPeriod: ReportPeriod): string => {
  if (reportPeriod.unit === 1 && reportPeriod.granularity === 'days') {
    return 'yesterday'
  }
  return `last ${reportPeriod.unit === 1 ? reportPeriod.granularity.slice(0, -1) : `${reportPeriod.unit} ${reportPeriod.granularity}`}`
}

const getSelectableInterval = (reportConfig: ReportConfig | undefined, today: DateTime) => {
  const todayUtc = today.startOf('day')
  if (!reportConfig) {
    return Interval.fromDateTimes(today, today)
  } else {
    let beginInterval = today.minus({ days: reportConfig.maxRetentionInDays })
    const beginIntervalUtc = todayUtc.minus({ days: reportConfig.maxRetentionInDays })
    // here, we want to reduce the interval to the minimum between what the local user wants, and what data range we have.
    // for example, we have access to data until 2020-09-16.
    // If the user is in the timezone Europe/Paris (GMT +2) he cannot query the day 2020-09-16 because part of this day is the end of the UTC 2020-09-15.
    if (beginInterval < beginIntervalUtc) {
      beginInterval = beginIntervalUtc.toLocal()
    }
    let endInterval = today
    if (endInterval > todayUtc) {
      endInterval = todayUtc.toLocal()
    }
    return Interval.fromDateTimes(beginInterval, endInterval)
  }
}

const getInitialSelectedInterval = (reportConfig: ReportConfig | undefined, selectableInterval: Interval, today: DateTime) => {
  if (!reportConfig) {
    return {
      label: '',
      interval: Interval.fromDateTimes(today, today),
    }
  }
  const { defaultSelectedPeriod } = reportConfig
  const start = selectableInterval.end.minus({ [defaultSelectedPeriod.granularity]: defaultSelectedPeriod.unit }).startOf(GranularityToUnit[defaultSelectedPeriod.granularity])
  return {
    label: getFormattedPeriodString(defaultSelectedPeriod),
    interval: Interval.fromDateTimes(start, start.plus({ [defaultSelectedPeriod.granularity]: defaultSelectedPeriod.unit })),
  }
}

const DeleteReportModal: FunctionComponent<{ onDelete: () => Promise<void> }> = ({ onDelete }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Delete ?</Modal.Title>

      <Modal.Body>You won&apos;t be able to view this report in Pubstack anymore. Do you confirm your choice?</Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button
          variant={'negative'}
          iconName={'delete'}
          onClick={async () => {
            await onDelete()
            close()
          }}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

type PureReportsPageProps = WithClassName & {
  canCreateReport: boolean
  isCreateReportLoading: boolean
  onRefreshClick: () => Promise<void>
  onCreateReportClick: (value: ReportNameType, interval: Interval) => Promise<void>
  today: DateTime
  table: {
    reports?: Report[]
    isLoading: boolean
    onRowClick: (report: Report) => void
    onDeleteConfirm: (report: Report) => Promise<void>
  }
  isPubstackRole: boolean
}
const _PureReportsPage: FunctionComponent<PureReportsPageProps> = ({ className, table, canCreateReport, isCreateReportLoading, onRefreshClick, onCreateReportClick, today, isPubstackRole }) => {
  const modal = useGlobalModal()
  const options: ReportSelectorOption[] = (Object.entries(reportsConfiguration) as [ReportNameType, ReportConfig][])
    .filter(([, { pubstackOnly }]) => (pubstackOnly ? isPubstackRole : true))
    .map(([value, { title, description }]) => ({
      value,
      title,
      description,
    }))
  const [reportSelectedIndex, setReportSelectedIndex] = useState<number | undefined>(undefined)
  const [selectedInterval, setSelectedInterval] = useState<IntervalWithLabel | undefined>(undefined)
  const reportSelected = reportSelectedIndex !== undefined ? options[reportSelectedIndex] : undefined
  const reportConfig = reportSelected ? reportsConfiguration[reportSelected.value] : undefined
  const isCreateReportDisabled = !canCreateReport || !reportSelected || !selectedInterval
  const selectableInterval = getSelectableInterval(reportConfig, today)
  const initialSelectedInterval = selectedInterval
    ? { label: selectedInterval.label ?? '', interval: Interval.fromDateTimes(selectedInterval.interval.start.startOf('day'), selectedInterval.interval.end.plus({ days: 1 }).endOf('day')) }
    : getInitialSelectedInterval(reportConfig, selectableInterval, today)

  const onTemplateChange = (index: number) => {
    setSelectedInterval(undefined)
    setReportSelectedIndex(index)

    const reportSelected = options[index]
    const reportConfig = reportsConfiguration[reportSelected.value]
    const selectableInterval = getSelectableInterval(reportConfig, today)
    const initialSelectedInterval = getInitialSelectedInterval(reportConfig, selectableInterval, today)
    const selectedInterval = Interval.fromDateTimes(initialSelectedInterval.interval.start, initialSelectedInterval.interval.end.minus({ days: 1 }))
    setSelectedInterval({ label: getFormattedPeriodString(reportConfig.defaultSelectedPeriod), interval: selectedInterval })
  }

  return (
    <Wrapper className={className}>
      <Title>Reports</Title>

      <SubTitleWrapper>
        <SubTitle>Create new report</SubTitle>
      </SubTitleWrapper>
      <CreateNewReportZone>
        <CreateNewReportSelectionWrapper>
          Create <PureReportsSelector options={options} value={reportSelected} onChange={(_, index) => onTemplateChange(index)} />
          {reportConfig && (
            <ReportTimeSelector
              onUpdate={setSelectedInterval}
              today={today}
              maxNumberOfDays={reportConfig.maxSelectableIntervalInDays}
              selectableInterval={selectableInterval}
              initialSelectedInterval={initialSelectedInterval}
              selectedRange={selectedInterval}
            />
          )}
        </CreateNewReportSelectionWrapper>
        {isCreateReportLoading || table.isLoading ? (
          <Spinner isColored={false} />
        ) : (
          <CreateReportButton
            variant={'primary'}
            iconName={'document'}
            disabled={isCreateReportDisabled}
            onClick={() => reportSelected && selectedInterval && onCreateReportClick(reportSelected?.value, selectedInterval?.interval)}
          >
            Create report
          </CreateReportButton>
        )}
      </CreateNewReportZone>

      <SubTitleWrapper>
        <SubTitle>Last reports</SubTitle>
        <Button variant={'tertiary'} iconName={'refresh'} onClick={onRefreshClick}>
          Refresh
        </Button>
      </SubTitleWrapper>
      <PureReportsTable
        isLoading={table.isLoading}
        onRowClick={table.onRowClick}
        onDeleteClick={(report) => modal.open(DeleteReportModal, { onDelete: () => table.onDeleteConfirm(report) })}
        reports={table.reports}
      />
    </Wrapper>
  )
}
export const PureReportsPage = styled(_PureReportsPage)``
