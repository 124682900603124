import styled from '@emotion/styled'
import { NewReport, Report, ReportNameType } from '@pubstack/common/src/report'
import { isPubstackUserRole } from '@pubstack/common/src/rolesAndFeatures'
import { DateTime, Interval } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useReports } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { WithClassName } from '~/types/utils'
import { useLogger } from '~/utils/logger'
import { PureReportsPage } from './PureReportsPage'

type ReportsPageProps = WithClassName
const _ReportsPage: FunctionComponent<ReportsPageProps> = ({ className }) => {
  const today = DateTime.now()
  const logger = useLogger()
  const [reports, setReports] = useState<Report[] | undefined>(undefined)
  const { all, byId } = useReports(null)
  const user = useUser()
  const isPubstackRole = user ? isPubstackUserRole(user?.userRoles) : false

  const onRefreshClick = async () => {
    logger.info({ action: 'click', type: 'report', detail: 'refresh' })
    await loadReports()
  }

  const onCreateReportClick = async (value: ReportNameType, interval: Interval) => {
    logger.info({ action: 'click', type: 'report', detail: 'report-creation', reportName: value })
    const report: NewReport = {
      reportName: value,
      startDate: interval.start.startOf('day').toISO(),
      endDate: interval.end.endOf('day').toISO(),
      timezone: DateTime.local().zoneName,
    }
    await byId.post({ report })
    await loadReports()
  }

  const onRowClick = (report: Report) => {
    if (report.status === 'CREATED' && report.url) {
      const link = document.createElement('a')
      link.setAttribute('href', report.url)
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const onDeleteConfirm = async (report: Report) => {
    await byId.del(`/${report.id}`)
    await loadReports()
  }

  const loadReports = async () => {
    const r = await all.get()
    setReports(r)
  }

  useEffect(() => {
    loadReports()
  }, [])

  return (
    <PureReportsPage
      className={className}
      isCreateReportLoading={false}
      onRefreshClick={onRefreshClick}
      today={today}
      table={{
        isLoading: byId.loading,
        reports,
        onRowClick,
        onDeleteConfirm,
      }}
      onCreateReportClick={onCreateReportClick}
      canCreateReport={user?.getScopeRole() !== 'demo'}
      isPubstackRole={isPubstackRole}
    />
  )
}
export const ReportsPage = styled(_ReportsPage)``
