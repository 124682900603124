import styled from '@emotion/styled'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { floatRegExp } from '@pubstack/common/src/input'
import { FloorRule } from '@pubstack/common/src/stack'
import { FunctionComponent, useEffect } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Tooltip } from '~/components/Tooltip'
import { PureAdStackEditPage } from '~/modules/adstack/PureAdStackEditPage'
import { WithClassName } from '~/types/utils'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-width: 1000px;
  ${Fonts.P1}
  h2 {
    ${Fonts.H2}
    font-weight: 500;
  }

  ${_Input} {
    width: 394px;
    padding: 20px 0;
  }

  & > ${_Input} {
    margin-bottom: 12px;
  }
`

const Subtitle = styled.div`
  ${Fonts.P1}
  span {
    display: inline-flex;
    align-items: center;
  }
`

const FormButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px;
  ${Button} {
    margin-left: 15px;
  }
`

const FloorPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const CurrencyWrapper = styled.div`
  ${Fonts.P0}
`

const PreviewWrapper = styled.div`
  ${Fonts.P1};
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 4px;
  ${Tooltip} {
    display: inline-flex;
  };
  padding-bottom: 12px;
`

const DynamicFloorPrice = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  ${Fonts.P2};
  border: 1px solid ${Colors.Cobalt};
  border-radius: 4px;
  background-color: ${Colors.Ghost};
  padding: 10px 12px;
`

type PureAdStackFloorPriceEditPageProps = WithClassName & {
  floorRule?: FloorRule
  onBack: () => void
  breadcrumbs: React.ReactNode
  onCreateFloorRule: (floorRule: FloorRule) => Promise<void>
  onUpdateFloorRule: (floorRule: FloorRule) => Promise<void>
  existingFloorRules: FloorRule[]
  currencySymbol: CurrencySymbol
  isEditing: boolean
  isLoading: boolean
}

export const parseFloorPrice = (floor: string) => Number.parseFloat(floor.replace(',', '.'))

const _PureAdStackFloorPriceEditPage: FunctionComponent<PureAdStackFloorPriceEditPageProps> = ({
  className,
  floorRule,
  onBack,
  onCreateFloorRule,
  breadcrumbs,
  existingFloorRules,
  currencySymbol,
  isEditing,
  onUpdateFloorRule,
  isLoading,
}) => {
  const defaultValues: FloorRule | undefined = floorRule ? { ...floorRule, floor: floorRule.floor / 100 } : undefined

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control,
    reset,
  } = useForm<FloorRule>({ mode: 'onChange', defaultValues })

  const { name, floor } = useWatch({ control })

  const onSubmit: SubmitHandler<FloorRule> = (newRule) => {
    const toEdit = { ...newRule, floor: Math.round(parseFloorPrice(newRule.floor.toString()) * 100) }
    isEditing ? onUpdateFloorRule(toEdit) : onCreateFloorRule(toEdit)
  }

  useEffect(() => {
    reset(floorRule ? { ...floorRule, floor: floorRule.floor / 100 } : undefined)
  }, [floorRule])

  return (
    <PureAdStackEditPage title={isEditing ? `Edit: ${floorRule?.name ?? ''}` : 'New floor price rule'} breadcrumbs={breadcrumbs} color={Colors.Forest} onBackClick={onBack}>
      {!isLoading && (
        <form className={className} id={'newRuleForm'} onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <Subtitle>
              <h2>Identification</h2>
            </Subtitle>
            <Input
              type={'text'}
              label={'Name'}
              control={control}
              name={'name'}
              error={errors.name?.message}
              rules={{
                required: { value: true, message: 'Name required.' },
                validate: {
                  value: (formValue) => {
                    return (formValue as string) !== floorRule?.name && existingFloorRules.map((f) => f.name).includes(formValue as string) ? 'A rule with this name already exists' : true
                  },
                },
              }}
              maxLength={100}
            />
            <Subtitle>
              <h2>Rule definition</h2>
            </Subtitle>
            Define the floor price.
            <FloorPriceWrapper>
              <Input
                type={'text'}
                label={'Floor price'}
                control={control}
                name={'floor'}
                error={errors.floor?.message}
                rules={{
                  required: { value: true, message: 'Floor price required.' },
                  pattern: { value: floatRegExp, message: 'Incorrect value.' },
                }}
              />
              <CurrencyWrapper>{currencySymbol}</CurrencyWrapper>
            </FloorPriceWrapper>
          </FormWrapper>
          <PreviewWrapper>
            <div>
              Rule preview: {name && <span>{name}</span>} {floor && !isNaN(parseFloorPrice(floor.toString())) && <span>{`(${parseFloorPrice(floor.toString()).toFixed(2)} ${currencySymbol})`}</span>}
            </div>
            <Tooltip title={'This is how the rule will be displayed in the stacks settings.'} align={'start'}>
              <Icon fill={Colors.Hurricane} width={'16px'} name={'info'} />
            </Tooltip>
          </PreviewWrapper>
          <DynamicFloorPrice>
            <Icon name="tips" width="15px" height="15px" />
            <span>To ensure the best performances, a dynamic floor solution is embedded in all rules: highest header bidding bid is sent as floor price to the ad server.</span>
          </DynamicFloorPrice>
          <FormButtonsWrapper>
            <Button variant={'tertiary'} onClick={onBack}>
              Cancel
            </Button>
            <Button variant={'primary'} type={'submit'} disabled={!isDirty || !isValid}>
              Save rule
            </Button>
          </FormButtonsWrapper>
        </form>
      )}
    </PureAdStackEditPage>
  )
}

export const PureAdStackFloorPriceEditPage = styled(_PureAdStackFloorPriceEditPage)``
