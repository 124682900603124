import styled from '@emotion/styled'
import { SiteMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { TimelineConfiguration, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import { auctionCount, hbBidDensity } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { PureSiteChartTitle } from './PureSiteChartTitle'

const useConfig = (data: SiteMetricsByTime) =>
  useMemo((): TimelineConfiguration<SiteMetricsByTime> => {
    return {
      getChartOptions: () => ({ seriesType: 'line' }),
      legendConfig: ['hb'],
      tooltipConfig: ['hb'],
      dataConfig: [
        {
          name: 'hb',
          label: hbBidDensity.name,
          iconName: 'data_line',
          getFormula: () => hbBidDensity,
          isComputable: ({ data }) => auctionCount.isComputable(data) && hbBidDensity.isComputable(data),
          withValue: true,
        },
      ],
    }
  }, [data])

type PureSiteBidDensityChartProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    data: SiteMetricsByTime
    currencySymbol: CurrencySymbol
    timezone: Timezone
    currentEpoch: DateTime
  }
const _PureSiteBidDensityChart: FunctionComponent<PureSiteBidDensityChartProps> = ({ data, timezone, currencySymbol, currentEpoch, ...props }) => {
  const averageDensity = auctionCount.isComputable(data) && hbBidDensity.isComputable(data) ? hbBidDensity.displayable(hbBidDensity.sum(data)) : ''
  const title = <PureSiteChartTitle title={hbBidDensity.name} infoLabel={'average'} infoValue={averageDensity.toString()} />

  const currentConfig = useConfig(data)
  const { chart, legends } = useTimelineChart({ currentConfig, data, currencySymbol, currentEpoch, timezone })
  return <AnalyticsChartWidget {...props} icon={'site'} title={title} chart={chart} legends={legends} />
}
export const PureSiteBidDensityChart = styled(_PureSiteBidDensityChart)``
