import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Flyout } from '~/components/Flyout'
import { WithClassName } from '~/types/utils'

type PureAdstackLazyLoadingHelpFlyoutProps = WithClassName & {
  isOpen: boolean
  onRequestClose: () => unknown
}

const HelpText = styled.div`
  h2 {
    padding: 24px 0 12px 0;
  }
`

const _PureAdstackLazyLoadingHelpFlyout: FunctionComponent<PureAdstackLazyLoadingHelpFlyoutProps> = ({ className, isOpen, onRequestClose }) => {
  return (
    <Flyout className={className} isOpen={isOpen} onClose={onRequestClose} title={'Tips & help'} color={Colors.Lilac}>
      <HelpText>
        <h2>What’s the impact on header bidding?</h2>
        <p>If header bidding is lazy loaded, you can choose when to start the auction. If not, the auction will start when the user arrives on the page.</p>
        <h2>How to choose the number of viewports?</h2>
        <p>To start an event right before being visible, choose a number close to 0. To start an event early, choose a number far from 0. </p>
      </HelpText>
    </Flyout>
  )
}

export const PureAdstackLazyLoadingHelpFlyout = styled(_PureAdstackLazyLoadingHelpFlyout)``
