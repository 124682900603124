import { DataProviderRTD, ModuleRTD, ModuleRTDNames } from '@pubstack/common/src/catalogItem'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useRealTimeDataModule } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { DataBySite } from '~/modules/adstack/inventory/modules/PureAdStackPrebidModulePage'
import { configurationState } from '~/state'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackRealTimeData } from './PureAdStackRealTimeDataPage'

export const AdStackRealTimeDataModulePage = () => {
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)
  const [module, setModule] = useState<ModuleRTD<ModuleRTDNames>>()

  const { moduleId } = useParams()
  const breadcrumbs = useBreadcrumbs({ integrationName: module?.displayName ?? '' })

  const { all, byId } = useRealTimeDataModule(null)

  const toast = useToast()
  const navigate = useNavigate()

  const updateSiteRTD = async <ModuleName extends ModuleRTDNames>(code: ModuleName, siteData: DataBySite<ModuleName>) => {
    try {
      if (moduleId && module && siteData.moduleData) {
        const update = { name: code, params: siteData.moduleData.params, waitForIt: siteData.moduleData.waitForIt } as DataProviderRTD<ModuleName>
        await byId.put(moduleId, siteData.siteId, update)
        toast.admSuccess(siteData.siteName)
        setModule({ ...module, config: [...module.config.map((data) => (data.siteId === siteData.siteId ? { ...siteData, dataProvider: update } : data))] })
      }
      loadData()
    } catch (e) {
      toast.alert('An error has occurred, please retry later.')
    }
  }

  const loadData = async () => {
    if (moduleId) {
      const res = await all.get(moduleId)
      setModule(res)
    }
  }

  useEffect(() => {
    loadData()
  }, [moduleId])

  return (
    <PureAdStackRealTimeData
      breadcrumbs={breadcrumbs}
      data={module?.config.map((c) => ({ ...c, moduleData: c.dataProvider ? { params: c.dataProvider.params, waitForIt: c.dataProvider.waitForIt } : undefined }) as DataBySite<ModuleRTDNames>) ?? []}
      code={module?.name as ModuleRTDNames}
      helpText={'realTimeData>dataProviders'}
      isLoading={all.loading || byId.loading}
      updateSiteData={updateSiteRTD}
      baseCDNUrl={STATIC_ASSETS_CDN}
      onBackClick={() => navigate(-1)}
    />
  )
}
