import styled from '@emotion/styled'
import { ExtraScript, ExtraScriptWithUrl } from '@pubstack/common/src/extraScript'
import { DateTime } from 'luxon'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { handleTableSearchAndSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { PureSiteExtraScriptEditModal } from '~/modules/adstack/inventory/sites/extra-scripts/PureSiteExtraScriptEditModal'
import { WithClassName } from '~/types/utils'

export const ExtraScriptDateFormat = 'yyyy-MM-dd HH:mm:ss'
export const isoToLocal = (date: string) => {
  return DateTime.fromISO(date).toFormat(ExtraScriptDateFormat)
}

type PureSiteExtraScriptProps = WithClassName & {
  onExtraScriptCreate: (extraScript: ExtraScript, file?: File | null) => Promise<void>
  onExtraScriptUpdate: (extraScript: ExtraScript) => Promise<void>
  extraScripts: ExtraScriptWithUrl[]
  isLoading?: boolean
}

const Header = styled.div`
  display: flex;
  margin-bottom: 12px;
  & input[type='file'] {
    display: none;
  }
`

const ActionBar = styled.form`
    display: flex;
    flex: 1 0 auto;
    justify-content: end;
`

const FilterAction = styled.form`
  display: flex;
`
const ButtonActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

const ActionsTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down to hug action buttons */
`

const _PureSiteExtraScript: FunctionComponent<PureSiteExtraScriptProps> = ({ onExtraScriptCreate, onExtraScriptUpdate, extraScripts, isLoading }) => {
  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })
  const search = useWatch({ control, name: 'search' })
  const modal = useGlobalModal()

  const [columns, setColumns] = useState<TableColumns<ExtraScript>>([
    {
      name: 'File name',
      attributeSort: 'name',
      isSortable: true,
      order: 'none',
    },
    {
      name: 'date',
      attributeSort: 'date',
      isSortable: true,
      order: 'descending',
    },
    {
      name: 'Early start',
      isSortable: false,
    },
    {
      name: 'Synchronous',
      isSortable: false,
    },
    {
      name: 'Actions',
      isSortable: false,
    },
  ])

  const displayedExtraScripts = [
    ...handleTableSearchAndSort(
      columns,
      extraScripts.filter((e) => e.enabled),
      search,
      ['name']
    ),
    ...handleTableSearchAndSort(
      columns,
      extraScripts.filter((e) => !e.enabled),
      search,
      ['name']
    ),
  ]

  const openEditModal = (extraScript?: ExtraScript) => {
    modal.open(PureSiteExtraScriptEditModal, { onExtraScriptCreate, onExtraScriptUpdate, extraScript })
  }

  return (
    <>
      <Header>
        <FilterAction>
          <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
        </FilterAction>
        <ActionBar>
          <Button variant={'primary'} onClick={() => openEditModal()} iconName={'upload'}>
            New extra-script
          </Button>
        </ActionBar>
      </Header>

      <Table columns={columns} isLoading={isLoading ?? false} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {displayedExtraScripts.map((extraScript) => (
          <TableRow key={`${extraScript.name}-${extraScript.date}`} archived={!extraScript.enabled}>
            <TableCell>{extraScript.name}</TableCell>
            <TableCell>{extraScript.date ? isoToLocal(extraScript.date) : ''}</TableCell>
            <TableCell>{extraScript.enabled && <Icon name={extraScript.beforeStart ? 'check' : 'close'} />}</TableCell>
            <TableCell>{extraScript.enabled && <Icon name={extraScript.synchronous ? 'check' : 'close'} />}</TableCell>
            <ActionsTableCell>
              <ButtonActions>
                <a key={extraScript.url} href={extraScript.url} download target={'_blank'} rel={'noreferrer'}>
                  <Button variant={'tertiary'} iconName={'open_in_new'} onClick={(event) => event.stopPropagation()} />
                </a>
                <Button iconName={'edit'} variant={'tertiary'} title={'Edit'} onClick={() => openEditModal(extraScript)} />
              </ButtonActions>
            </ActionsTableCell>
          </TableRow>
        ))}
      </Table>
    </>
  )
}

export const PureSiteExtraScript = styled(_PureSiteExtraScript)``
