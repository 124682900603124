import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, FunctionComponent } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { Sizes, Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon } from './Icon'

const PopoverButtonWrapper = styled.button<{ isOpened?: boolean }>`
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  ${Fonts.H2}
  ${Fonts.colors.Hurricane}

  :hover {
    ${Fonts.colors.Cobalt}
  }

  :disabled {
    ${Fonts.colors.SlateGrey}
    cursor: not-allowed;
  }

  :active {
    ${Fonts.colors.King}
  }

  & ${Icon} {
    margin: 0 0 0 2px;
    transition: ${Transitions.quick};

    ${({ isOpened }) =>
      isOpened &&
      css`
        transform: rotate(-180deg);
      `}
  }
`

type PopoverButtonProps = WithClassName & ComponentProps<'button'> & { isOpened?: boolean; label?: string }
const _PopoverButton: FunctionComponent<PopoverButtonProps> = ({ label, className, isOpened, ...props }) => {
  return (
    <PopoverButtonWrapper {...props} isOpened={isOpened} className={className}>
      <span>{label}</span>
      <Icon width={Sizes[24]} height={Sizes[24]} name={'chevron_down'} />
    </PopoverButtonWrapper>
  )
}

export const PopoverButton = styled(_PopoverButton)``
