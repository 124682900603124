import styled from '@emotion/styled'
import { WithClassName } from '~/types/utils'

type AdStackIntegrationLogoProps = WithClassName & {
  name: string
  width?: string
  height?: string
  //TODO cfo tmu 2023-07-20 - remove this in favour of a global config usable in pure components
  baseCDNUrl: string
}

const _AdStackIntegrationLogo = ({ name, baseCDNUrl, ...props }: AdStackIntegrationLogoProps) => {
  return <img src={`${baseCDNUrl}/integrations/${name}.png`} {...props} />
}
export const AdStackIntegrationLogo = styled(_AdStackIntegrationLogo)``
