import { ExclusionType, RefreshException } from '@pubstack/common/src/refresh'
import { useEffect, useState } from 'react'
import { FetchError } from '~/api/api-access'
import { useRefreshException } from '~/api/api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { downloadCSVdata } from '~/utils/csv'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureSettingsRefreshExceptionsPage, RefreshExceptionFormData } from './PureSettingsRefreshExceptionsPage'

export const SettingsRefreshExceptionsPage = () => {
  const breadcrumbs = useBreadcrumbs()
  const [refreshException, setRefreshException] = useState<RefreshException>({ exclusionBidders: [], exclusionSizes: [] })

  const { byId, updateRefreshException, downloadFile } = useRefreshException(null)

  const toast = useToast()

  const loadData = async () => {
    const data = await byId.get()
    setRefreshException(data)
  }

  const downloadList = async (type: ExclusionType) => {
    const fileData = await downloadFile(type)
    downloadCSVdata(fileData.toString(), refreshException.exclusionFiles?.[`${type}File`] ?? `${type}.csv`)
  }

  const onSave = async (data: RefreshExceptionFormData) => {
    const updatedRefreshException: RefreshException = {
      ...refreshException,
      exclusionBidders: data.exclusionBidders,
      exclusionSizes: data.exclusionSizes,
    }

    try {
      const result = await updateRefreshException({
        refreshException: updatedRefreshException,
        exceptionFiles:
          data.exclusionFiles?.lineItemIdFile || data.exclusionFiles?.orderIdFile || data.exclusionFiles?.mode
            ? {
                lineItemIdFile: data.exclusionFiles?.lineItemIdFile,
                orderIdFile: data.exclusionFiles?.orderIdFile,
                lineItemIdAction: data.exclusionFiles?.lineItemIdFile === null ? 'preserve' : 'upload',
                orderIdAction: data.exclusionFiles?.orderIdFile === null ? 'preserve' : 'upload',
                mode: data.exclusionFiles?.mode ?? '',
              }
            : undefined,
      })
      if (result) {
        setRefreshException(result)
      }
      toast.success('Refresh exceptions applied.')
      // if the user tried to set a mode but no files are available
      if (data.exclusionFiles?.mode && !result?.exclusionFiles) {
        toast('Upload at least one csv to use an allow or block list.')
      }
    } catch (e) {
      if (e instanceof FetchError) {
        const error = JSON.parse(e.message)
        toast.alert(error.data.message)
      } else {
        toast.alert('An error occurred while saving your refresh exceptions, please try again later.')
      }
    }
  }

  const onDelete = async (fileType: ExclusionType) => {
    try {
      const result = await updateRefreshException({
        exceptionFiles: {
          lineItemIdFile: null,
          orderIdFile: null,
          lineItemIdAction: fileType === 'lineItemId' ? 'delete' : 'preserve',
          orderIdAction: fileType === 'orderId' ? 'delete' : 'preserve',
          mode: refreshException.exclusionFiles?.mode ?? '',
        },
      })
      if (result) {
        setRefreshException(result)
      }
      toast.success('Your list was successfully deleted.')
    } catch (e) {
      toast.alert('An error occurred while deleting your list, please try again later.')
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      loadData()
    })
    return () => clearTimeout(timeout)
  }, [])

  return <PureSettingsRefreshExceptionsPage breadcrumbs={breadcrumbs} refreshException={refreshException} onDownload={downloadList} onDelete={onDelete} onSave={onSave} />
}
