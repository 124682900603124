import { AdUnitDevice } from './adunitDevice'
import { CatalogItemType } from './catalogItem'
import { Site } from './tag'

export type BidParamType = 'number' | 'string' | 'boolean' | 'array'

export type BidderAlias = {
  bidderLabel: string
  bidderSource: string
}

export type BidParam = {
  name: string
  required: boolean
  type: BidParamType
}

export type BidderCatalog = {
  id: string
  displayName: string
  code: string
  bidParams: BidParam[]
  isUsed: boolean
  type: CatalogItemType
  disableAliasing: boolean
}

export type BidderCatalogAlias = BidderAlias & {
  isUsed: boolean
  bidderParams: BidderParam[]
  isOutstream: boolean
  bidParams: BidParam[]
  bidAdjustment?: number
}

//TODO tmu 2024-01-22 -- type object properties better
export type BidderParamValue = { [key: string]: string | number | boolean | unknown[] | BidderParamValue }
export type BidderParam = {
  adUnitName: string
  bidderName: string
  device: AdUnitDevice
  params: BidderParamValue
  siteName: Site['name']
}

export type MappedBidder = {
  mappedStacks: {
    stackName: string
    version: string
    enabled: boolean
  }[]
  totalStacks: number
  alias: BidderCatalogAlias
  usedAdUnitNames: string[]
}

export const BIDDER_PARAM_PROPERTIES = {
  'Pbstck Adapter Name': 'bidderName',
  'Pbstck Site': 'siteName',
  'Pbstck Device': 'device',
  'Pbstck Ad Unit': 'adUnitName',
} as const

export const BIDDER_PARAM_CSV_HEADERS: (keyof typeof BIDDER_PARAM_PROPERTIES)[] = ['Pbstck Adapter Name', 'Pbstck Site', 'Pbstck Device', 'Pbstck Ad Unit']

export const isBidderParamProperty = (value: string): value is keyof typeof BIDDER_PARAM_PROPERTIES => {
  return Object.keys(BIDDER_PARAM_PROPERTIES).includes(value)
}
