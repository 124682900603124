import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon } from '~/components/Icon'
import { WithClassName } from '~/types/utils'

const Viewport = styled.div`
  display: flex;
  gap: 14px;
  ${Fonts.P2};
`
const ViewportIconWrapper = styled.div<{ hasFocus: boolean; isFirst: boolean }>`
  border: 1px ${({ hasFocus }) => (hasFocus ? Colors.King : Colors.Silver)} ${({ isFirst }) => (isFirst ? 'solid' : 'dashed')};
  width: 180px;
  height: 100px;
  display: flex;
  align-items: center;
  ${({ isFirst }) =>
    isFirst
      ? css`
          justify-content: start;
          gap: 18px;
        `
      : css`
          justify-content: center;
        `}
`

const ViewportIcon = styled.div<{ state: ViewportState }>`
  width: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ state }) =>
    state === 'ad'
      ? css`
          background-color: ${Colors.King};
          ${Fonts.colors.White};
          ${Fonts.H0};
          font-weight: 200;
        `
      : state === 'loading'
        ? css`
            background-color: ${Colors.Topaze};
          `
        : css`
            background-color: ${Colors.White};
          `}
`

const ViewportTitle = styled.div`
  ${Fonts.colors.Ash};
  margin-bottom: 4px;
`

const ViewportElement = styled.div<{ hasFocus: boolean }>`
  ${({ hasFocus }) => hasFocus && Fonts.colors.King};
`

const UserIcon = styled.div`
  padding-left: 7px;
`

export type ViewportType = 'Auction start' | 'Render impression' | 'GAM request'

export type Viewport = {
  type: ViewportType
  value: number
  hasFocus?: boolean
}

export type ViewportState = 'ad' | 'loading' | 'none'

export type LazyLoadingViewportProps = WithClassName & {
  title: string
  viewports: Viewport[]
}
const _LazyLoadingViewport: FunctionComponent<LazyLoadingViewportProps> = ({ className, title, viewports }) => {
  const hasFocus = viewports.some((v) => v.hasFocus)
  const state: ViewportState = viewports.some((v) => v.type === 'Render impression') ? 'ad' : viewports.length > 0 ? 'loading' : 'none'
  const isFirst = title.startsWith('0 viewport')
  return (
    <Viewport className={className}>
      <ViewportIconWrapper hasFocus={hasFocus} isFirst={isFirst}>
        {isFirst && (
          <UserIcon>
            <Icon name={'present'} width={'30px'} />
          </UserIcon>
        )}
        <ViewportIcon state={state}>
          {state === 'ad' && <p>AD</p>}
          {state === 'loading' && <Icon name={'loading'} width={'32px'} fill={Colors.SlateGrey} />}
        </ViewportIcon>
      </ViewportIconWrapper>
      <div>
        <ViewportTitle>{title}</ViewportTitle>
        {viewports
          .sort((a, b) => a.value - b.value)
          .map((v) => (
            <ViewportElement hasFocus={!!v.hasFocus} key={v.type}>
              {`${v.type} (${v.value})`}
            </ViewportElement>
          ))}
      </div>
    </Viewport>
  )
}
export const LazyLoadingViewport = styled(_LazyLoadingViewport)``
