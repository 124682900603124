import { FC, PropsWithChildren, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useScopes } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { configurationState, contextState, currentScopeState, sidebarOpenState } from '~/state'
import { useLogRouteChange } from '~/utils/router'
import { BeamerIntegration } from './BeamerIntegration'
import { HubspotChat } from './HubspotChat'
import { OpenReplayIntegration } from './OpenReplayIntegration'
import { ProductTrackingIntegration } from './ProductTrackingIntegration'
import { PureLayout } from './PureLayout'
import Header from './header/Header'
import Menu from './menu/Menu'

const LogRoute = () => {
  useLogRouteChange()
  return null
}

const Layout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { all, byId } = useScopes()
  const scopes = all.data
  const scope = byId.data
  const [currentScope, setCurrentScope] = useRecoilState(currentScopeState)
  const [, setContext] = useRecoilState(contextState)
  const configuration = useRecoilValue(configurationState)
  const user = useUser()
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarOpenState)
  const message = configuration.INFORMATION_BANNER
  useEffect(() => {
    if (scope) {
      setContext((context) => ({ ...context, timeRange: { ...context.timeRange, tz: scope.timezone } }))
      setCurrentScope(scope)
    }
  }, [scope])

  const hasLiveChat = user && !user.isAdmin && user.hasFeature('liveChat')
  if (!currentScope.id || !scopes) {
    return null
  }

  return (
    <>
      <LogRoute />
      <OpenReplayIntegration />
      <ProductTrackingIntegration />
      <BeamerIntegration scopes={scopes} />
      {hasLiveChat && <HubspotChat />}
      <PureLayout
        isSidebarOpen={sidebarOpen.open}
        onCloseSidebar={() => {
          setSidebarOpen(() => ({ open: false, pinned: false }))
          setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
          }, 400)
        }}
        isSidebarPinned={sidebarOpen.pinned}
        onPinSidebar={() => {
          setSidebarOpen({ open: true, pinned: true })
          setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
          }, 400)
        }}
        menu={<Menu />}
        content={children}
        header={<Header scopes={scopes} />}
        message={message}
      />
    </>
  )
}

export default Layout
