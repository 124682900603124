import styled from '@emotion/styled'
import { FunctionComponent, KeyboardEvent, useEffect, useState } from 'react'
import { Sizes } from '~/assets/style/tokens'
import { _Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'

const FilterSidebarSearchWrapper = styled.div``
const FilterSidebarSearchInputWrapper = styled.div`
  padding: 4px 10px;
  margin: 4px 0 24px;
`
const FilterSidebarSearchActionsWrapper = styled.div`
  padding: 4px 10px;
  font-size: ${Sizes[10]};
  display: flex;
  justify-content: end;

  a {
    cursor: pointer;
    margin: 0 8px;
  }
`

type FilterSidebarSearchProps = WithClassName & {
  nbResults: number
  searchValue: string
  setSearchValue: (value: string) => void
  onSearch: () => void
  onSelectAllClick: () => void
}
const _FilterSidebarSearch: FunctionComponent<FilterSidebarSearchProps> = ({ className, nbResults, onSearch, searchValue, setSearchValue, onSelectAllClick }) => {
  const select = () => {
    if (!searchValue || !nbResults) {
      return <span>(no value selected)</span>
    } else if (nbResults > 50) {
      return <span>(too many values 50+)</span>
    } else {
      return <a onClick={onSelectAllClick}>select all</a>
    }
  }

  const onInputKeypress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const [lastSearch, setLastSearch] = useState('')
  useEffect(() => {
    if (lastSearch !== searchValue) {
      const timeout = setTimeout(() => {
        onSearch()
        setLastSearch(searchValue)
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [searchValue])

  return (
    <FilterSidebarSearchWrapper className={className}>
      <FilterSidebarSearchInputWrapper>
        <_Input value={searchValue} onChange={(event) => setSearchValue(event.target.value)} label={'Search'} labelIsPlaceholder={true} onKeyPress={onInputKeypress} iconLeft={'search'} />
      </FilterSidebarSearchInputWrapper>
      <FilterSidebarSearchActionsWrapper>
        {select()}
        <Link label={'search'} onClick={onSearch} />
      </FilterSidebarSearchActionsWrapper>
    </FilterSidebarSearchWrapper>
  )
}
export const FilterSidebarSearch = styled(_FilterSidebarSearch)``
