import styled from '@emotion/styled'
import { Color, Colors } from '~/assets/style/colors'

type ProgressBarProps = { color?: Color }

export const ProgressBar = styled.progress<ProgressBarProps>`
  border-radius: 10px;
  border: none;
  height: 6px;
  width: 100%;
  background-color: ${Colors.Platinum};

  &::-webkit-progress-bar {
    border-radius: 10px;
    background-color: ${Colors.Platinum};
  }

  &::-moz-progress-bar {
    background-color: ${(props) => props.color ?? Colors.Ming};
    border-radius: 10px;
    transition: width 500ms ease-in-out;
  }

  &::-webkit-progress-value {
    background-color: ${(props) => props.color ?? Colors.Ming};
    border-radius: 10px;
    transition:
      width 500ms ease-in-out,
      background-color 500ms ease-in-out;
  }
`
