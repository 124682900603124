import styled from '@emotion/styled'
import { MappedName, RefreshMetricsByRank } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, ReactElement, useState } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import type { ChartWrapperOptions } from 'react-google-charts/dist/types'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'
import { LegendProps } from '~/components/Legend'
import { TimelineMetricProps } from '~/components/TimelineMetric'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget, AnalyticsChartWidgetMessage } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { auctionFillRate, averageViewedTime, eCPM, impressionCount, impressionRevenue, viewabilityMRC } from '~/modules/analytics/formulas'
import { Formula } from '~/modules/analytics/formulas/operation'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_CHART_COLOR_CONFIG } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { displayWithCurrency } from '~/utils/string'
import { RefreshUpliftMessage } from './RefreshUpliftMessage'

const getChartTooltip = (name: MappedName, metricConfig: RefreshTimelineMetricConfig, rawData: RefreshMetricsByRank, index: number) => {
  const metric = metricConfig.metric
  const value = metric.chartTooltipDisplay(rawData, index)
  const chartConfigName = index === 0 ? 'firstCall' : 'refresh'
  const iconColor = ANALYTICS_CHART_COLOR_CONFIG[chartConfigName].color
  const metrics: ChartTooltipMetricProps[][] = [value ? [{ label: metric.name, value, iconColor }] : []].filter((v) => !!v.length)
  const tooltipContent = <ChartTooltip date={`Rank: ${name.label}`} metrics={metrics} />
  return ReactDOMServer.renderToString(tooltipContent)
}

const applyFormulaToChartTooltips =
  (formula: Formula<any>, currencySymbol: CurrencySymbol) =>
  (rawData: RefreshMetricsByRank, index: number): string | undefined => {
    return formula.isComputable(rawData) ? `${formula.displayable(formula.compute(rawData, index), currencySymbol)}` : undefined
  }

type RefreshTimelineMetricConfig = {
  chartOptions: ChartWrapperOptions['options']
  metric: {
    name: string
    formula: Formula<any>
    highlight?: (rawData: RefreshMetricsByRank) => AnalyticsChartWidgetMessage | undefined
    chartTooltipDisplay: (rawData: RefreshMetricsByRank, index: number) => string | undefined
    tooltipText?: string | ReactElement
  }
}

const getMetricsConfig = (currencySymbol: CurrencySymbol): RefreshTimelineMetricConfig[] => [
  {
    chartOptions: {
      seriesType: 'bars',
      isStacked: true,
      vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: displayWithCurrency('#,###', currencySymbol), minValue: 0 },
    },
    metric: {
      chartTooltipDisplay: applyFormulaToChartTooltips(impressionRevenue, currencySymbol),
      name: impressionRevenue.name,
      formula: impressionRevenue,
      highlight: (rawData) => {
        if (impressionRevenue.isComputable(rawData)) {
          const firstCallRevenue = rawData.impressionCpmSum[0]
          if (firstCallRevenue) {
            const refreshRevenue = impressionRevenue.sum(rawData) * 1000 - firstCallRevenue
            return {
              icon: 'rocket',
              message: <RefreshUpliftMessage value={refreshRevenue / 1000} percentage={Math.round((refreshRevenue / firstCallRevenue) * 100)} currencySymbol={currencySymbol} />,
            }
          }
        }
        return undefined
      },
      tooltipText: ANALYTICS_TOOLTIPS.REVENUE,
    },
  },
  {
    chartOptions: {
      seriesType: 'bars',
      isStacked: true,
    },
    metric: {
      chartTooltipDisplay: applyFormulaToChartTooltips(impressionCount, currencySymbol),
      name: impressionCount.name,
      formula: impressionCount,
    },
  },
  {
    chartOptions: {
      seriesType: 'bars',
      vAxis: { ...AnalyticsDefaultChartOptions.vaxis, format: "#'%'", minValue: 0, maxValue: 100 },
    },
    metric: {
      chartTooltipDisplay: applyFormulaToChartTooltips(auctionFillRate, currencySymbol),
      name: auctionFillRate.name,
      formula: auctionFillRate,
      tooltipText: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
    },
  },
  {
    chartOptions: {
      seriesType: 'bars',
      vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: displayWithCurrency('#,###', currencySymbol), minValue: 0 },
    },
    metric: {
      chartTooltipDisplay: applyFormulaToChartTooltips(eCPM, currencySymbol),
      name: eCPM.name,
      formula: eCPM,
      tooltipText: ANALYTICS_TOOLTIPS.ECPM,
    },
  },
  {
    chartOptions: {
      seriesType: 'bars',
      vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: "#'%'", minValue: 0, maxValue: 100 },
    },
    metric: {
      chartTooltipDisplay: applyFormulaToChartTooltips(viewabilityMRC, currencySymbol),
      name: viewabilityMRC.name,
      formula: viewabilityMRC,
      tooltipText: ANALYTICS_TOOLTIPS.VIEWABILITY,
    },
  },
  {
    chartOptions: {
      seriesType: 'bars',
      vAxis: { ...AnalyticsDefaultChartOptions.vAxis, format: "#'s'", minValue: 0 },
    },
    metric: {
      chartTooltipDisplay: applyFormulaToChartTooltips(averageViewedTime, currencySymbol),
      name: averageViewedTime.name,
      formula: averageViewedTime,
      tooltipText: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
    },
  },
]

const convertData = ({ rawData, currentFormula }: { rawData: RefreshMetricsByRank | undefined; currentFormula: RefreshTimelineMetricConfig }) => {
  if (!rawData) return []

  return rawData.name.map((name, index) => {
    const color = index === 0 ? ANALYTICS_CHART_COLOR_CONFIG.firstCall.color : ANALYTICS_CHART_COLOR_CONFIG.refresh.color
    const result: (string | Date | number)[] = [name.label, getChartTooltip(name, currentFormula, rawData, index), currentFormula.metric.formula.compute(rawData, index), color]
    return result
  })
}

type PureRefreshRankProps = WithClassName & Omit<WidgetProps, 'title' | 'info' | 'icon'> & { rawData?: RefreshMetricsByRank; currencySymbol: CurrencySymbol }
const _PureRefreshRank: FunctionComponent<PureRefreshRankProps> = ({ rawData, currencySymbol, ...props }) => {
  const [currentFormula, setCurrentFormula] = useState<number>(0)
  const metricsConfig = getMetricsConfig(currencySymbol)
  const metrics = metricsConfig.map<TimelineMetricProps & { highlight: AnalyticsChartWidgetMessage | undefined }>((appliedFormula, index) => {
    const formula = appliedFormula.metric.formula
    const isApplicable = rawData ? formula.isComputable(rawData) : undefined
    const highlight = isApplicable && rawData && appliedFormula.metric.highlight ? appliedFormula.metric.highlight(rawData) : undefined
    return {
      active: currentFormula === index,
      label: appliedFormula.metric.name,
      value: isApplicable ? formula.displayable(formula.sum(rawData), currencySymbol).toString() : undefined,
      notApplicable: !isApplicable,
      highlight,
      onClick: () => {
        isApplicable && setCurrentFormula(index)
      },
      tooltipText: appliedFormula.metric.tooltipText,
    }
  })
  const chartDataPoints = ['firstCall', 'refresh']
  const chartOptions = {
    ...AnalyticsDefaultChartOptions,
    seriesType: 'bars',
    isStacked: true,
    ...metricsConfig[currentFormula].chartOptions,
  }
  const chartData = [
    [
      'Date',
      {
        type: 'string',
        role: 'tooltip',
        p: { html: true },
      },
      'value',
      { role: 'style' },
    ],
    ...convertData({ rawData, currentFormula: metricsConfig[currentFormula] }),
  ]

  const legends: LegendProps[] = chartDataPoints.map((legend) => ({ iconColor: ANALYTICS_CHART_COLOR_CONFIG[legend].color, label: ANALYTICS_CHART_COLOR_CONFIG[legend].title }))

  return (
    <AnalyticsChartWidget
      {...props}
      empty={props.empty || !(rawData && (rawData.name ?? []).length)}
      icon={'chart_bar'}
      title={'Refresh rank (Prebid only)'}
      chart={{ chartType: 'ComboChart', options: chartOptions, data: rawData ? chartData : undefined }}
      legends={legends}
      metrics={metrics}
      metricMessage={metrics[currentFormula].highlight}
    />
  )
}
export const PureRefreshRank = styled(_PureRefreshRank)``
