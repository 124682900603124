import styled from '@emotion/styled'
import { AdUnit } from '@pubstack/common/src/adunit'
import { AdUnitDevice } from '@pubstack/common/src/adunitDevice'
import { AdUnitConfig, RoadblockType, isStackNameReserved } from '@pubstack/common/src/stack'
import { FunctionComponent, useMemo, useState } from 'react'
import { Control, Controller, FieldErrors, FieldValue, Validate } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Skeleton } from '~/components/Skeleton'
import { useToast } from '~/components/Toast/useToasts'
import { WithClassName } from '~/types/utils'
import { StackFormData } from './PureStackEdit'
import { PureStackSelectAdUnitsFlyout } from './PureStackSelectAdUnitsFlyout'
import { AdUnitCard } from './components/AdUnitCard'

const Title = styled.h2`
  ${Fonts.H2};
  ${Colors.Jet};
  font-weight: 500;
  margin-bottom: 16px;
`

const Subtitle = styled.div`
  ${Fonts.P1};
  ${Colors.Jet};
  margin-bottom: 20px;
`
const IdentificationWrapper = styled.div`
  display: inline-flex;
  gap: 15px;
  align-items: baseline;
  padding-bottom: 32px;
  & > ${_Input} {
    width: 350px;
  }
`
const DesktopWrapper = styled.div`
  margin-bottom: 32px;
`

const MobileWrapper = styled.div`
  margin-bottom: 24px;
`

const AdUnitsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(25% - 8px));
  gap: 10px;
  margin-bottom: 14px;
`

const ButtonErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  ${Button} + ${Button} {
    margin-left: 8px;
  }
`
const ErrorMessage = styled.span`
  ${Fonts.P2};
  display: inline-flex;
  align-items: center;
  ${Fonts.colors.Alert};

  > * {
    margin-right: 4px;
  }
`

type PureStackAdUnitSelectionStepProps = WithClassName & {
  control: Control<StackFormData>
  errors: FieldErrors<StackFormData>
  selectableAdUnits: AdUnit[]
  contextOptions: SelectOptionProp[]
  stackName: string
  isEditing: boolean
  isLoading: boolean
  validateUniqueName: (name: string) => Promise<boolean>
}

type FullAdUnitConfig = { adUnit: AdUnit; roadblock?: RoadblockType }

const adUnitCards = (onChange: (event: AdUnitConfig[]) => void, adUnitsConfigs: FullAdUnitConfig[]) => {
  if (!adUnitsConfigs.length) {
    return undefined
  }
  return (
    <AdUnitsWrapper>
      {adUnitsConfigs.map((adUnitConfig) => {
        return (
          <AdUnitCard
            key={adUnitConfig.adUnit.id}
            isError={isDuplicate(adUnitConfig.adUnit, adUnitsConfigs) || isArchived(adUnitConfig.adUnit)}
            isArchived={isArchived(adUnitConfig.adUnit)}
            adUnit={adUnitConfig.adUnit}
            onClose={() => {
              onChange(adUnitsConfigs.filter((c) => c.adUnit !== adUnitConfig.adUnit).map((c) => ({ adUnitId: c.adUnit.id, roadblock: c.roadblock })))
            }}
            roadblock={{
              isRoadblock: !!adUnitConfig.roadblock,
              onToggleClick: () => {
                onChange(
                  adUnitsConfigs.map((config) => {
                    if (config.adUnit.id === adUnitConfig.adUnit.id) {
                      return { adUnitId: config.adUnit.id, roadblock: config.roadblock ? undefined : RoadblockType.Companion }
                    } else {
                      return { adUnitId: config.adUnit.id, roadblock: config.roadblock }
                    }
                  })
                )
              },
              isMaster: adUnitConfig.roadblock === RoadblockType.Master,
              onMasterIconClick: () => {
                onChange(
                  adUnitsConfigs.map((config) => {
                    if (config.adUnit.id === adUnitConfig.adUnit.id) {
                      const roadblock = config.roadblock === RoadblockType.Master ? RoadblockType.Companion : RoadblockType.Master
                      return { adUnitId: config.adUnit.id, roadblock }
                    } else {
                      return { adUnitId: config.adUnit.id, roadblock: config.roadblock === RoadblockType.Master ? RoadblockType.Companion : config.roadblock }
                    }
                  })
                )
              },
            }}
          />
        )
      })}
    </AdUnitsWrapper>
  )
}

const isDuplicate = (adUnit: AdUnit, adUnits: FullAdUnitConfig[]): boolean => adUnits.filter((a) => a.adUnit.divId === adUnit.divId).length > 1
const isArchived = (adUnit: AdUnit): boolean => !adUnit.enabled

/**
 * Control duplicates in given adUnits collection
 * Rules :
 * - Unique div ID
 * - Unique ad server ad unit name
 * @param adUnits
 * @return the potential error, empty otherwise
 */
const findDuplicates = (adUnits: AdUnit[]): string | undefined => {
  const divIdsArr = adUnits.map((adUnit) => adUnit.divId)
  const isDivIdDuplicate = new Set(divIdsArr).size !== divIdsArr.length

  return isDivIdDuplicate ? `Some ad units have the same div ID. You can keep only one of each.` : undefined
}

const validateRoadblock = (adUnits: AdUnitConfig[]): string | true => {
  const roadblockAdUnits = adUnits.filter((adUnit) => adUnit.roadblock)
  if (roadblockAdUnits.length === 0) {
    return true
  }
  if (roadblockAdUnits.length < 2) {
    return 'A roadblock should contain at least two ad units'
  } else {
    return adUnits.filter((adUnit) => adUnit.roadblock === RoadblockType.Master).length === 0 ? "An ad unit must be selected as the roadblock's master" : true
  }
}

const findArchivedAdUnits = (adUnits: AdUnit[]): string | true => {
  return adUnits.find((adUnit) => isArchived(adUnit)) ? `Some ad units are now archived. Please remove them.` : true
}

const _PureStackAdUnitSelectionStep: FunctionComponent<PureStackAdUnitSelectionStepProps> = ({
  className,
  control,
  errors,
  selectableAdUnits,
  contextOptions,
  stackName,
  isEditing,
  isLoading,
  validateUniqueName,
}) => {
  const [isFlyoutDesktopOpen, setIsFlyoutDesktopOpen] = useState<boolean>(false)
  const [isFlyoutMobileOpen, setIsFlyoutMobileOpen] = useState<boolean>(false)
  const adUnitsById = useMemo(() => selectableAdUnits.reduce((acc, adunit) => ({ ...acc, [adunit.id]: adunit }), {} as { [key: string]: AdUnit }), [selectableAdUnits])
  const toAdUnitsConfig = (configs: AdUnitConfig[] | undefined) =>
    (configs || []).map((config) => {
      return { adUnit: adUnitsById[config.adUnitId], roadblock: config.roadblock }
    })
  const toast = useToast()

  const generateFlyout = (onChange: (event: AdUnitConfig[]) => void, adUnitConfigs: FullAdUnitConfig[], isFlyoutOpen: boolean, setIsFlyoutOpen: (open: boolean) => void, flyoutType: AdUnitDevice) => (
    <PureStackSelectAdUnitsFlyout
      title={`Select ${flyoutType} ad units`}
      isOpen={isFlyoutOpen}
      onClose={() => setIsFlyoutOpen(false)}
      onValidate={(newAdUnits: AdUnit[]) => {
        const roadblockByAdUnitId = adUnitConfigs.reduce<Record<string, RoadblockType | undefined>>((acc, config) => ({ ...acc, [config.adUnit.id]: config.roadblock }), {})
        onChange(newAdUnits.map((adUnit) => ({ adUnitId: adUnit.id, roadblock: roadblockByAdUnitId[adUnit.id] })))
        setIsFlyoutOpen(false)
        const error = findDuplicates(newAdUnits)
        if (error) {
          toast.alert(error)
        }
      }}
      selectableAdUnits={selectableAdUnits.filter((adUnit) => adUnit.enabled && adUnit.devices.includes(flyoutType))}
      selectedAdUnits={adUnitConfigs.map((c) => c.adUnit)}
    />
  )

  const validateName: Validate<FieldValue<StackFormData>, StackFormData> = async (value) => {
    const parsedValue = (Array.isArray(value) ? value.join() : value) as string
    if (isStackNameReserved(parsedValue)) {
      return `The name can't be "Default".`
    }
    if (!isEditing) {
      const isUnique = await validateUniqueName(parsedValue)
      if (!isUnique) {
        return 'This name is already in use.'
      }
    }
    return true
  }

  return (
    <div className={className}>
      <>
        <Title>Identification</Title>
        {isLoading ? (
          <Skeleton bigger width={'100px'} />
        ) : isStackNameReserved(stackName) ? (
          <IdentificationWrapper>
            <Input name={'name'} control={control} labelIsPlaceholder label={'Name'} disabled={true} />
            <Select
              options={[
                { label: 'Default', value: null },
                { label: 'Default', value: undefined },
              ]}
              control={control}
              label={'Context'}
              name={`contextId`}
              disabled={true}
            />
          </IdentificationWrapper>
        ) : (
          <IdentificationWrapper>
            <Input
              name={'name'}
              error={errors?.name?.message}
              control={control}
              rules={{
                required: { value: true, message: 'Name required.' },
                validate: validateName,
              }}
              labelIsPlaceholder
              label={'Name'}
              disabled={isEditing}
            />
            <Select
              options={contextOptions}
              error={errors?.contextId?.message}
              label={'Context'}
              control={control}
              name={`contextId`}
              rules={{
                required: 'Context required.',
              }}
              searchable={true}
            />
          </IdentificationWrapper>
        )}
        <Title>Target</Title>
      </>
      <Subtitle>Select the ad unit to use on the stack.</Subtitle>
      <DesktopWrapper>
        <Title>Desktop ad units</Title>
        {isLoading ? (
          <Skeleton bigger width={'242px'} />
        ) : (
          <Controller
            control={control}
            name={'desktopAdUnits'}
            rules={{
              required: 'Select at least one ad unit per device.',
              validate: {
                noDuplicate: (adUnits) => findDuplicates(toAdUnitsConfig(adUnits).map((c) => c.adUnit)),
                roadblock: (adUnits) => validateRoadblock(adUnits ?? []),
                noArchivedAdUnit: (adUnits) => findArchivedAdUnits(toAdUnitsConfig(adUnits).map((c) => c.adUnit)),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <>
                {adUnitCards(onChange, toAdUnitsConfig(value))}
                {generateFlyout(onChange, toAdUnitsConfig(value), isFlyoutDesktopOpen, setIsFlyoutDesktopOpen, 'desktop')}
                <ButtonErrorWrapper>
                  <Button variant={'secondary'} onClick={() => setIsFlyoutDesktopOpen(true)}>
                    Select ad units
                  </Button>
                  {(value || []).length > 0 && (
                    <Button variant={'tertiary'} onClick={() => onChange([])}>
                      Remove all
                    </Button>
                  )}
                  {errors.desktopAdUnits?.message && (
                    <ErrorMessage>
                      <Icon name={'alert'} width={'0.875rem'} /> {errors.desktopAdUnits?.message}
                    </ErrorMessage>
                  )}
                </ButtonErrorWrapper>
              </>
            )}
          />
        )}
      </DesktopWrapper>
      <MobileWrapper>
        <Title>Mobile ad units</Title>
        {isLoading ? (
          <Skeleton bigger width={'242px'} />
        ) : (
          <Controller
            control={control}
            name={'mobileAdUnits'}
            rules={{
              required: 'Select at least one ad unit per device.',
              validate: {
                noDuplicate: (adUnits) => findDuplicates(toAdUnitsConfig(adUnits).map((c) => c.adUnit)),
                roadblock: (adUnits) => validateRoadblock(adUnits ?? []),
                noArchivedAdUnit: (adUnits) => findArchivedAdUnits(toAdUnitsConfig(adUnits).map((c) => c.adUnit)),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <>
                {adUnitCards(onChange, toAdUnitsConfig(value))}
                {generateFlyout(onChange, toAdUnitsConfig(value), isFlyoutMobileOpen, setIsFlyoutMobileOpen, 'mobile')}
                <ButtonErrorWrapper>
                  <Button variant={'secondary'} onClick={() => setIsFlyoutMobileOpen(true)}>
                    Select ad units
                  </Button>
                  {(value || []).length > 0 && (
                    <Button variant={'tertiary'} onClick={() => onChange([])}>
                      Remove all
                    </Button>
                  )}
                  {errors.mobileAdUnits?.message && (
                    <ErrorMessage>
                      <Icon name={'alert'} width={'0.875rem'} /> {errors.mobileAdUnits?.message}
                    </ErrorMessage>
                  )}
                </ButtonErrorWrapper>
              </>
            )}
          />
        )}
      </MobileWrapper>
    </div>
  )
}
export const PureStackAdUnitSelectionStep = styled(_PureStackAdUnitSelectionStep)``
