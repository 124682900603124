import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { MappedName, UserSessionMetricsByDim } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useEffect, useState } from 'react'
import { useAnalyticsQuery } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { Context } from '~/state'
import { WithClassName } from '~/types/utils'
import { logSortBreakdownAction } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreakdownTabs } from '~/utils/useBreakdownTabs'
import { PureUserSessionBreakdown } from './PureUserSessionBreakdown'

type UserSessionBreakdownProps = WithClassName & {
  context: Context
  currencySymbol: CurrencySymbol
  dimension: Dimension
  onRowClick: (name: MappedName) => void
  onTabChange: (dimension: Dimension) => void
}
const _UserSessionBreakdown: FunctionComponent<UserSessionBreakdownProps> = ({ className, dimension, onTabChange, onRowClick, context, currencySymbol }) => {
  const logger = useLogger()
  const dimensions: Dimension[] = ['tagId', 'device', 'country']

  const [dimData, setDimData] = useState<UserSessionMetricsByDim | undefined>(undefined)

  const { byId: analyticsQuery } = useAnalyticsQuery<UserSessionMetricsByDim>(null, dimensions, context)

  const user = useUser()
  const tabsConfig = useBreakdownTabs(user, ['tagId', 'device', 'country'])

  const loadBreakdownData = async (dimension: Dimension) => {
    if (analyticsQuery.loading) {
      analyticsQuery.abort()
    }
    const d = await analyticsQuery.post('user-session.metrics.by.dim', { dimension })
    setDimData(d.values)
  }

  useEffect(() => {
    setTimeout(() => {
      loadBreakdownData(dimension)
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context, dimension])

  return (
    <PureUserSessionBreakdown
      className={className}
      isLoading={analyticsQuery.loading}
      error={!!analyticsQuery.error}
      empty={!analyticsQuery.loading && !dimData?.name.length}
      onRefreshClick={() => loadBreakdownData(dimension)}
      rawData={dimData ?? { name: [] }}
      onTabChange={onTabChange}
      currencySymbol={currencySymbol}
      onRowClick={onRowClick}
      onSortChange={logSortBreakdownAction(logger)}
      tabsConfig={tabsConfig}
    />
  )
}

export const UserSessionBreakdown = styled(_UserSessionBreakdown)``
