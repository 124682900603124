import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Flyout } from '~/components/Flyout'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'

type PureAdStackGamIntegrationHelpFlyoutProps = WithClassName & {
  isOpen: boolean
  onClose: () => unknown
  navigateToContextKeys: () => void
  navigateToAdUnits: () => void
}

const HelpText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  & div {
    margin-bottom: 10px;
  }
  h2 {
    margin-bottom: 12px;
  }

  ul,
  ol {
    margin: 0;
    margin-left: 20px;
    padding: 0;
  }

  & div > p {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
`

const _PureAdStackGamIntegrationHelpFlyout: FunctionComponent<PureAdStackGamIntegrationHelpFlyoutProps> = ({ className, isOpen, onClose, navigateToContextKeys, navigateToAdUnits }) => {
  return (
    <Flyout className={className} isOpen={isOpen} onClose={onClose} title={'Help'} color={Colors.Violet}>
      <HelpText>
        <div>
          <h2>Why do I need to define the Network ID?</h2>
          <p>
            The network code is a unique, numeric identifier for an Ad Manager network. This network code is used to build the Ad request to Google Ad Manager. You can get it directly on your Ad
            manager account.
          </p>
        </div>

        <div>
          <h2>Why do I need to define the network ID for Multiple Customer Management (MCM)?</h2>
          <div>Publishers can delegate management of their Ad Manager network to a third-party publisher upon request and establishes a relationship parent-child.</div>
          <div>To be compliant with this delagation for Google, the Ad unit path declared in Google Publisher Tag must contain explicitly this link.</div>
          <div>
            You may not need to fill in this information, but if you have a delegated relationship with a third party, you must define it otherwise the inventory cannot be monetized. You can get it on
            your Ad Manager account.
          </div>
        </div>

        <div>
          <h2>Why do I need to define the ad unit path?</h2>
          <p>You must provide to Pubstack with the architecture you are using to help it properly handle requests to your Ad Manager network.</p>
        </div>

        <div>
          <h2>How can I define the ad unit path?</h2>
          <span>
            Based on <Link onClick={navigateToContextKeys} label={'context keys'} />, and <Link onClick={navigateToAdUnits} label={'ad units'} /> you have already set in Pubstack app, you have two
            ways to define your ad unit path architecture:
          </span>
          <ul>
            <li>
              First common one relies on context keys and Ad server ad unit name
              <ul>
                <li>Example: ContextKey_1/ContextKey_2/AdServerAdUnitName( site_name/inventory_type/MyAdUnit)</li>
              </ul>
            </li>
            <li>
              The second way depends on context keys. If you don’t have an explicit ad unit to target in your ad manager, Pubstack will send targeting keys to the ad server based on the ad unit name
              and key values defined in the ad unit settings. In that case, ad unit path will be ContextKey_1/ContextKey_2/ContextKey_3 (ex: site_name/inventory_type/section) and Pubstack will
              complete it by sending pbstck_adunit_name=AdServerAdUnitName & associated key values (ex: position=top)
            </li>
          </ul>
        </div>
        <div>
          <h2>What do I need to declare if I use different levels for the ad unit path on the same site?</h2>
          <p>
            If you have manage a site for which you have some specific sections where you have a different level of ad unit path, you have to define the longest one you currently use on your Ad
            Manager.
          </p>
          <p>Pubstack will be able to deal with N levels and N+1 levels. Even is the last level is missing on the page, Pubstack will call the ad unit in GAM with the right path.</p>
        </div>
      </HelpText>
    </Flyout>
  )
}

export const PureAdStackGamIntegrationHelpFlyout = styled(_PureAdStackGamIntegrationHelpFlyout)``
