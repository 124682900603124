export const LEADERBOARD_HELP_TEXT: string = `A leaderboard is a display ad unit with a standard measuring 728 pixels in width and 90 pixels in height. Typically positioned at the top of webpages for immediate visibility upon page load, leaderboard ads are widely recognized.`
export const SKIN_SINGLE_AD_HELP_TEXT: string = `The skin ad format is a large display ad with dimensions of 1800 pixels in width and 1000 pixels in height. Positioned to cover a significant portion of a webpage, it offers ample space for creative and impactful visual content. This format is designed to enhance brand visibility and engagement, making it an effective choice for advertisers seeking a bold and immersive online presence.`
export const SIDEBAR_HELP_TEXT: string = `Positioned on the side of a page for user visibility while scrolling, it is often served with in a multi-size ad slot where both sizes can appear together having a 120x600, 260x600 known as skyscraper or 300x250, 300x600. It is recommended to use multi-size to improve performance.`
export const RECTANGLE_MEDIUM_HELP_TEXT: string = `300×250 – Medium Rectangle (MPU): An MPU, or 300×250, is a top-performing ad unit, known as Med Rec or MREC. Popular for its versatility on desktop and mobile screens, MPUs are the go-to choice for advertisers, ensuring widespread visibility and increased investments.`
export const RECTANGLE_LARGE_HELP_TEXT: string = `The 300×600 ad unit, an IAB standard, sized at 300x600 pixels, is known as Large rectangle, Half Page Unit (HPU). Often used alongside a 300×250, it's highly successful in performance and eCPM. \nPositioned on the side of a page like an MPU, it can also fit a 160×600 ad slot by expanding left or right. Despite its success, it's on the IAB's rising star list due to limited site adoption.`
export const OUTSTREAM_SINGLE_AD_HELP_TEXT: string = `An 'outstream' video ad, also called 'in-read' or 'native video', automatically plays in a large player within text content, like an article. It functions independently of the publisher's video content, diverging from traditional instream placements (pre-roll, mid-roll, post-roll).`
export const MOBILE_BANNER_HELP_TEXT: string = `The 320×50 ad unit, an IAB Mobile standard, measures 320 pixels in width and 50 pixels in height. Primarily designed for mobile phones, the 320×50 ad unit boasts high performance and is listed among the top performers on AdSense.`
export const OUT_OF_PAGE_HELP_TEXT: string = `The out-of-page ad format refers to advertisements that appear outside the typical boundaries of a webpage. These ads often overlay the content or pop up, creating a visually prominent and attention-grabbing display. With the ability to capture user attention effectively, out-of-page ads are designed to increase visibility and engagement, offering a dynamic approach to online advertising.`
export const MOBILE_INTERSTITIAL_HELP_TEXT: string = `The mobile web interstitial ad format presents full-page ads that appear between natural breaks in mobile web content, such as page transitions. These ads offer a visually immersive experience on smaller screens, effectively capturing user attention. With a clear exit option and configurable frequency caps, this format prioritizes a positive user experience while providing advertisers with valuable opportunities for engagement.`
export const IN_CONTENT_HELP_TEXT: string = `The in-content ad format seamlessly integrates advertisements within the natural flow of website content. These ads are strategically placed within articles or other textual content, blending with the user's browsing experience. This format aims to provide a non-disruptive advertising approach, ensuring relevance and engagement without interrupting the user's interaction with the content.`
export const CUSTOM_AD_FORMAT_HELP_TEXT: string = `This format is a sandbox. It has no size recommendation, all settings are available so you can customize the ad unit according to your needs.`
export const IN_CONTENT_PARALLAX_HELP_TEXT =
  'The parallax ad format offers to increase competition by expanding the demand to larger sizes in a fixed, smaller-sized container, without compromising on viewability. Parallax scrolling is a technique where background images move at a different rate than foreground images, allowing for bigger creatives to have their entire content seen as the user scrolls through the page.'
export const ANCHOR_HELP_TEXT =
  'The Anchor ad format allows you to display an ad at the bottom of your web pages with a sticky behaviour that keeps it in view as the user scrolls. Anchor ads allow you to improve monetisation with a high performing format with better viewability, without being too intrusive as users can dismiss the anchor ad at any time by closing the container.'
