import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, BidMetricsByDim, BidMetricsByTime } from '@pubstack/common/src/analytics/query'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useAnalyticsQuery } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { useBuildContextWithFilterEnforced } from '~/modules/analytics/useContext'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { isContextInSelectableInterval, logSortBreakdownAction, onBreakdownRowClick } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useBreakdownTabs } from '~/utils/useBreakdownTabs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureBidderBreakdown } from './PureBidderBreakdown'
import { PureBidderCharts } from './PureBidderCharts'
import { BidderCPMDataType } from './PureBidderWidgetCPM'
import { BidderResponsesDataType } from './PureBidderWidgetResponses'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 20px;
  }
`

type BidderDetailsPageProps = WithClassName
const _BidderDetailsPage: FunctionComponent<BidderDetailsPageProps> = ({ className }) => {
  const filterDimensions: Dimension[] = ['tagId', 'adUnit', 'device', 'country', 'size', 'pubstackRefresh', 'stackIdVersion']
  const queryDimensions: Dimension[] = [...filterDimensions, 'bidder']
  const logger = useLogger()

  const search = useSearchParams()
  const searchParams = JSON.parse(atob(search[0].get('mapping') ?? "{bidder: '', bidderName: ''}"))
  const { bidder, bidderName } = searchParams

  const [context, setContext] = useRecoilState(contextState)
  const buildContext = useBuildContextWithFilterEnforced('bidder', { label: bidderName, value: bidder })
  const currencySymbol = useScopeCurrency()

  const [dataByTime, setDataByTime] = useState<BidMetricsByTime | undefined>(undefined)
  const { byId: analyticsQueryByTime } = useAnalyticsQuery<BidMetricsByTime>(null, queryDimensions, context)

  const [dataByDim, setDataByDim] = useState<BidMetricsByDim | undefined>(undefined)
  const { byId: analyticsQueryByDim } = useAnalyticsQuery<BidMetricsByDim>(null, queryDimensions, context)
  const [breakdownDimension, setBreakdownDimension] = useState<Dimension>('tagId')
  const dashboard: AnalyticsQueryDashboard = { name: 'bidder', filterType: 'auctions' }

  const breadcrumbs = useBreadcrumbs()
  const user = useUser()
  const tabsConfig = useBreakdownTabs(user, ['tagId', 'adUnit', 'device', 'country', 'size'], { stackIdVersion: 'adstack' })
  const maxNumberOfDayInTimerangeSelected = 31

  const loadBidderDataByTime = async () => {
    if (analyticsQueryByTime.loading) {
      analyticsQueryByTime.abort()
    }
    const d = await analyticsQueryByTime.post('bid.metrics.by.time', { bidderName: bidder })
    setDataByTime(d.values)
  }

  const loadBidderDataByDim = async (dimension: Dimension) => {
    if (analyticsQueryByDim.loading) {
      analyticsQueryByDim.abort()
    }
    const d = await analyticsQueryByDim.post('bid.metrics.by.dim', { bidderName: bidder, dimension })
    setDataByDim(d.values)
  }

  const loadAllData = async () => {
    loadBidderDataByTime()
    loadBidderDataByDim(breakdownDimension)
  }

  const onBreakdownTabChange = (dimension: Dimension) => {
    logger.info({ action: 'click', type: 'bidder-detail-breakdown', detail: dimension })
    loadBidderDataByDim(dimension)
    setBreakdownDimension(dimension)
  }

  const onResponsesTabChange = (type: BidderResponsesDataType) => {
    logger.info({ type: 'graph-action', action: 'click', graphName: 'bidder-detail-bidder-responses', detail: type })
  }
  const onCPMTabChange = (type: BidderCPMDataType) => {
    logger.info({ type: 'graph-action', action: 'click', graphName: 'bidder-detail-cpm-breakdown', detail: type })
  }

  useEffect(() => {
    setTimeout(() => {
      if (isContextInSelectableInterval(context, maxNumberOfDayInTimerangeSelected)) {
        loadAllData()
      }
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context])

  return (
    <AnalyticsPage
      className={className}
      onRefreshClick={() => loadAllData()}
      filterDimensions={filterDimensions}
      queryDimensions={queryDimensions}
      buildContext={buildContext}
      dashboard={dashboard}
      title={breadcrumbs}
      maxNumberOfDayInTimerangeSelected={maxNumberOfDayInTimerangeSelected}
    >
      <PageContent>
        <PureBidderCharts
          data={dataByTime ?? { epoch: [] }}
          currentEpoch={DateTime.now()}
          onRefreshClick={loadBidderDataByTime}
          currencySymbol={currencySymbol}
          empty={!analyticsQueryByTime.loading && !dataByTime?.epoch.length}
          error={!!analyticsQueryByTime.error}
          isLoading={analyticsQueryByTime.loading}
          onResponsesTabChange={onResponsesTabChange}
          onCPMTabChange={onCPMTabChange}
          timezone={context.timeRange.tz}
        />
        <PureBidderBreakdown
          rawData={dataByDim}
          onRefreshClick={() => loadBidderDataByDim(breakdownDimension)}
          onTabChange={onBreakdownTabChange}
          onRowClick={onBreakdownRowClick(logger, breakdownDimension, setContext, !dataByDim)}
          currencySymbol={currencySymbol}
          empty={!analyticsQueryByDim.loading && !dataByTime}
          error={!!analyticsQueryByDim.error}
          isLoading={analyticsQueryByDim.loading}
          onSortChange={logSortBreakdownAction(logger)}
          tabsConfig={tabsConfig}
        />
      </PageContent>
    </AnalyticsPage>
  )
}

export const BidderDetailsPage = styled(_BidderDetailsPage)``
