import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { Color, Colors, StatusColor } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ProgressBar } from '~/components/ProgressBar'
import { Status, WithClassName } from '~/types/utils'
import { DataTableDetailedProperties } from './DataTableDetailedProperties'
import { DetailInfo } from './DataTableTypes'

const Cell = styled.td<{ secondary?: boolean; isNotApplicable?: boolean; status?: Status }>`
  padding: 10px;
  ${Fonts.P1};
  color: ${({ status }) => (status ? StatusColor[status] : Colors.Jet)};
  font-weight: 500;

  :first-of-type {
    ${Fonts.P1};
    font-weight: 700;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      :first-of-type > ${CellContent} {
        margin-left: 12px;
      }

      ${Fonts.P1};
    `}

  ${({ isNotApplicable }) =>
    isNotApplicable &&
    css`
      ${Fonts.colors.Silver};
    `}
`
const CellContent = styled.div<{ status?: Status; inline: boolean }>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  align-items: ${({ inline }) => (inline ? 'center' : 'flex-start')};

  &::before {
    content: '';
    display: ${({ status }) => (status === 'alert' ? 'inline-block' : 'none')};
    border-radius: 50%;
    margin-right: 4px;
    height: 8px;
    width: 8px;
    background-color: ${Colors.Alert};
  }
`

const GaugeProgressBar = styled(ProgressBar)`
  width: 220px;
`

type DataTableCellProps = WithClassName & {
  secondary?: boolean
  status?: Status
  inline?: boolean
  isNotApplicable?: boolean
}

type CellContentWithSublabelProps = WithClassName & { label: ReactNode; sublabel: string }

const Sublabel = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  ${Fonts.P2};
  ${Fonts.colors.Ash};
  margin-bottom: 5px;
`

const _CellContentWithSublabel: FunctionComponent<CellContentWithSublabelProps> = ({ className, label, sublabel }) => (
  <div className={className}>
    <div>{label}</div>
    <Sublabel>{sublabel}</Sublabel>
  </div>
)

export const CellContentWithSublabel = styled(_CellContentWithSublabel)``

const _DataTableCell: FunctionComponent<PropsWithChildren<DataTableCellProps>> = ({ className, children, secondary, status, inline = true, isNotApplicable }) => (
  <Cell className={className} secondary={secondary} isNotApplicable={isNotApplicable} status={status}>
    <CellContent inline={inline} status={status}>
      {children}
    </CellContent>
  </Cell>
)

export const DataTableCell = styled(_DataTableCell)``
type GaugeDataTableCellProps = DataTableCellProps & { gaugeValues: GaugeDataTableCellValues; gaugeColor?: Color } //TODO tmu 2022-08-30 - rework so that both color and status arent used concurrently

type GaugeDataTableCellValues = { value: number; max?: number }
const _GaugeDataTableCell: FunctionComponent<PropsWithChildren<GaugeDataTableCellProps>> = ({ className, children, gaugeValues, status, gaugeColor }) => (
  <DataTableCell inline={false} className={className}>
    {children}
    <GaugeProgressBar value={gaugeValues.value} max={gaugeValues.max ?? 100} color={gaugeColor ?? (status ? StatusColor[status] : undefined)} />
  </DataTableCell>
)
export const GaugeDataTableCell = styled(_GaugeDataTableCell)``

type DetailedDataTableCellProps = DataTableCellProps & { details: DetailInfo[] }
const _DetailedDataTableCell: FunctionComponent<PropsWithChildren<DetailedDataTableCellProps>> = ({ className, children, details }) => (
  <DataTableCell inline={false} className={className}>
    {children}
    <DataTableDetailedProperties details={details} />
  </DataTableCell>
)

export const DetailedDataTableCell = styled(_DetailedDataTableCell)``
