import { Config } from '~/types/config'

const CONFIGURATION_FILE = `configuration.json`
const OVERRIDE_CONFIGURATION_FILE = `override.json`

export const loadConfig = async (): Promise<Config> => {
  let response: Response
  if (process.env.NODE_ENV === 'development') {
    response = await fetch('/' + OVERRIDE_CONFIGURATION_FILE)
    try {
      // This line is only here to see if the override.json exist
      await response.clone().json()
    } catch (e) {
      response = await fetch('/' + CONFIGURATION_FILE)
    }
  } else {
    response = await fetch('/' + CONFIGURATION_FILE)
  }
  return response.json()
}
