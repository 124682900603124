import { RefreshRule } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRefreshRules } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackRefreshEditPage } from './PureAdStackRefreshEditPage'

export const AdStackRefreshEditPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const breadcrumbs = useBreadcrumbs()
  const [refreshRules, setRefreshRules] = useState<RefreshRule[]>([])

  const [refreshRule, setRefreshRule] = useState<RefreshRule>()
  const { refreshRuleId } = useParams()

  const { all, byId } = useRefreshRules(null)

  async function loadRefreshRule(refreshRuleId?: string) {
    if (refreshRuleId) {
      try {
        const refreshRuleToUpdate = await byId.get(refreshRuleId)
        setRefreshRule(refreshRuleToUpdate)
      } catch (exception) {
        toast.alert('An error occurred while fetching this refresh rule.')
        throw exception
      }
    }
  }

  const loadRefreshRules = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setRefreshRules(result)
  }

  const createRefreshRule = async (refreshRule: RefreshRule) => {
    try {
      const newRule = await byId.post(refreshRule)
      toast.success(`${newRule.name} added to your inventory.`)
      navigate('/adstack/refresh')
    } catch (exception) {
      toast.alert('An error occurred while saving this rule.')
      throw exception
    }
  }

  const updateRefreshRule = async (refreshRule: RefreshRule) => {
    try {
      const updatedRefreshRule = await byId.put(refreshRule)
      toast.admSuccess(refreshRule.name, refreshRule.stacksUse && refreshRule.id !== updatedRefreshRule.id, navigate)
      navigate('/adstack/refresh')
    } catch (exception) {
      toast.alert('An error occurred while saving this rule.')
      throw exception
    }
  }

  useEffect(() => {
    loadRefreshRules()
    loadRefreshRule(refreshRuleId)
  }, [refreshRuleId])

  return (
    <PureAdStackRefreshEditPage
      isEditing={!!refreshRuleId}
      isLoading={byId.loading}
      refreshRule={refreshRule}
      existingRefreshRules={refreshRules}
      breadcrumbs={breadcrumbs}
      onCreateRefreshRule={createRefreshRule}
      onUpdateRefreshRule={updateRefreshRule}
      onBack={() => navigate(-1)}
    />
  )
}
