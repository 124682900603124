import { AdminScope } from '@pubstack/common/src/scope'
import { FunctionComponent, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAdminScopes } from '~/api/admin-api.hook'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdminScopeDetail } from './PureAdminScopeDetail'

const adminTabs = [
  {
    label: 'Sites',
    iconName: 'site',
  },
  {
    label: 'Features',
    iconName: 'inputs',
  },
  {
    label: 'API keys',
    iconName: 'key',
  },
  {
    label: 'Ad stack settings',
    iconName: 'rocket',
  },
  {
    label: 'POC-overrides',
    iconName: 'fire',
  },
  {
    label: 'Adm QA reports',
    iconName: 'chart_bar',
  },
  {
    label: 'GAM manager',
    iconName: 'shipping',
  },
] as const

type TabLabel = (typeof adminTabs)[number]['label']

const tabLabelToPath: { [key in TabLabel]: string } = {
  Sites: 'sites',
  Features: 'features',
  'API keys': 'api',
  'Ad stack settings': 'adstack-settings',
  'POC-overrides': 'poc-overrides',
  'Adm QA reports': 'qa',
  'GAM manager': 'gam-manager',
}

export const AdminScopeDetail: FunctionComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const tabLabelFromLocation = (Object.keys(tabLabelToPath) as Array<keyof typeof tabLabelToPath>).find(
    (key) => tabLabelToPath[key] === (location.pathname.split('/').find((dir) => Object.values(tabLabelToPath).includes(dir)) ?? 'sites')
  )

  const { scopeId } = useParams()
  const [scopes, setScopes] = useState<AdminScope[]>([])
  const currentAdminScope: AdminScope = scopes.find((s) => s.id === scopeId) ?? scopes[0]
  const { all, byId } = useAdminScopes(null)
  const [activeTab, setActiveTab] = useState<TabLabel>(tabLabelFromLocation ?? 'Sites')

  const breadcrumbs = useBreadcrumbs(currentAdminScope)

  const loadScopes = async () => {
    if (byId.loading) {
      byId.abort()
    }
    const scopes = await all.get() // TODO tmu nra 2022-09-20 handle errors
    if (!scopes.find((s) => s.id === scopeId)) {
      navigate('/analytics/overview')
    }
    setScopes(scopes.filter((scope) => scope.enabled))
  }

  useEffect(() => {
    loadScopes()
  }, [])

  const onScopeChange = (option: SelectOptionProp) => {
    if (!scopes.some((s) => s.id === option.value)) {
      return
    }
    if (option.value !== scopeId && scopeId) {
      const to = location.pathname.replace(scopeId, option.value as string)
      navigate(to)
    }
  }

  return (
    <PureAdminScopeDetail
      breadcrumbs={breadcrumbs}
      scopes={scopes}
      currentScope={currentAdminScope}
      onScopeChange={onScopeChange}
      onTabClick={(tab) => {
        setActiveTab(tab.label as TabLabel)
        navigate(tabLabelToPath[tab.label as TabLabel])
      }}
      tabs={adminTabs.map((t) => ({ ...t, active: t.label === activeTab }))}
    >
      <Outlet />
    </PureAdminScopeDetail>
  )
}
