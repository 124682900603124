import styled from '@emotion/styled'
import { FC, PropsWithChildren } from 'react'
import { Colors } from '~/assets/style/colors'
import { WithClassName } from '~/types/utils'

type TableCellProps = WithClassName & {
  colspan?: number
}

const Td = styled.td`
  padding: 8px 12px;
  position: relative;
  border-bottom: 1px solid ${Colors.Platinum};

  &:last-of-type {
    box-shadow: inset -1px 0 0 0 ${Colors.Platinum};
  }
  &:first-of-type {
    border-radius: 0;
    border-bottom: 1px solid ${Colors.Platinum};
    box-shadow: inset 1px 0 0 0 ${Colors.Platinum};
  }

  &:last-of-type:first-of-type {
    box-shadow:
      inset -1px 0 0 0 ${Colors.Platinum},
      inset 1px 0 0 0 ${Colors.Platinum};
  }
`

export const _TableCell: FC<PropsWithChildren<TableCellProps>> = ({ children, colspan = 1, className }) => (
  <Td colSpan={colspan} className={className}>
    {children}
  </Td>
)

const TableCell = styled(_TableCell)``

export default TableCell
