import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { CONTEXT_ELEMENT_OP_AS_STR, ConditionGroupElement, StackContext } from '@pubstack/common/src/stackContext'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { handleTableSort, onColumnSort, SortableColumn, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { ContextConditions } from '~/modules/adstack/ContextConditions'
import { WithClassName } from '~/types/utils'
import { PureUsedStackTooltip } from '../../rules/PureUsedStackTooltip'
import { countStacksUse } from '../../rules/countStacksUsed'
import { PureAdStackContextsDetailsFlyout } from './PureAdStackContextsDetailsFlyout'

type PureAdStackContextsPageProps = WithClassName & {
  contexts: StackContext[]
  isLoading: boolean
  isModalVisible: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  scopeId?: string
  onCreateContext: () => void
  onArchiveContext: (context: StackContext) => void
  onUnArchiveContext: (context: StackContext) => void
  onCreateContextKey: () => void
  breadcrumbs: React.ReactNode
}

const ContextsWrapper = styled.div`
  padding: 0;
`

const ActionsTableCell = styled(TableCell)`
  width: 0; /** necessary to get the last cell to shrink down to hug action buttons */
`

const ButtonActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

const NoContexts = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  h1 {
    ${Fonts.H1}
    font-weight: 400;
    margin-bottom: 12px;
  }
  p {
    ${Fonts.P1}
  }
`

const FilterActionForm = styled.form`
  margin: 12px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const TableCellName = styled(TableCell)`
  word-break: break-all;
  min-width: 200px;
  max-width: 300px;
`

const ContextConditionsWithoutColor = styled(ContextConditions)<{ archived?: boolean }>`
  ${({ archived }) =>
    !!archived &&
    css`
      * {
        color: inherit !important;
      }
    `}
`

export const PureAdStackContextsNewContextModal: FunctionComponent<{ onCreateContextKey: () => void; onClose: () => void }> = ({ onCreateContextKey, onClose }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>New context</Modal.Title>

      <Modal.Body>
        A context is composed of logical conditions with one or more context keys. <br />
        As no context keys are set yet, you need to create some first.
      </Modal.Body>

      <Modal.Actions>
        <Button
          variant={'tertiary'}
          onClick={() => {
            close()
            onClose()
          }}
        >
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onCreateContextKey}>
          New context key
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const buildConditionAsString = (element: ConditionGroupElement, level = 0): string => {
  if ('elements' in element) {
    const part = `${element.elements.map((e) => buildConditionAsString(e, level + 1)).join(` ${element.op} `)}`
    return level === 0 ? part : `(${part})`
  }

  return `${element.key} ${CONTEXT_ELEMENT_OP_AS_STR[element.op]} [${element.values.join(', ')}]`
}

const _PureAdStackContextsPage: FunctionComponent<PureAdStackContextsPageProps> = ({
  className,
  contexts,
  isLoading,
  isModalVisible,
  setIsOpen,
  onCreateContext,
  onArchiveContext,
  onUnArchiveContext,
  onCreateContextKey,
}) => {
  const [isOpenDetailsFlyout, setIsOpenDetailsFlyout] = useState<boolean>(false)
  const [contextDetail, setContextDetail] = useState<StackContext>()
  const [columns, setColumns] = useState<TableColumns<StackContext>>([
    {
      name: 'Name',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'Conditions',
      isSortable: false,
    },
    {
      name: 'Used on',
      isSortable: true,
      order: 'none',
      attributeSort: 'stacksUse',
    },
    {
      name: 'Actions',
      isSortable: false,
    },
  ])

  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })

  const search = useWatch({ control, name: 'search' })

  const sortAndGroup = (contexts: StackContext[]) => {
    const sortedColumn = (columns.find((c) => c.isSortable && c.order !== 'none') || columns[0]) as SortableColumn<StackContext>
    if (sortedColumn.attributeSort === 'stacksUse') {
      return contexts.sort((a, b) => (sortedColumn.order === 'ascending' ? 1 : -1) * (countStacksUse(b.stacksUse) - countStacksUse(a.stacksUse)))
    } else {
      return handleTableSort<StackContext>(columns, contexts).filter((context) => {
        const includeName = context.name.toLowerCase().includes(search.toLowerCase())
        const includeConditions = buildConditionAsString(context.conditions)?.toLowerCase().includes(search.toLowerCase())
        return includeName || includeConditions
      })
    }
  }
  const groupedContexts = contexts.reduce((acc, context) => ({ ...acc, [`${context.enabled}`]: [...(acc[`${context.enabled}`] || []), context] }), {} as { [key: string]: StackContext[] })
  const sortAndGroupContexts = [...sortAndGroup(groupedContexts['true']), ...sortAndGroup(groupedContexts['false'])]

  const newContextButton = (
    <Button variant={'primary'} onClick={onCreateContext}>
      New context
    </Button>
  )

  const modal = useGlobalModal()
  // TODO cfo 2023-11-08 - Ugly special case. Need some rethinking
  useEffect(() => {
    if (isModalVisible && !modal.isOpen) {
      modal.open(PureAdStackContextsNewContextModal, {
        onCreateContextKey: () => {
          onCreateContextKey()
          setIsOpen(false)
          modal.close()
        },
        onClose: () => {
          setIsOpen(false)
        },
      })
    }

    if (!modal.isOpen) {
      setIsOpen(false)
    }
  }, [isModalVisible])

  return (
    <ContextsWrapper className={className}>
      {isLoading || contexts?.length > 0 ? (
        <>
          <FilterActionForm>
            <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
            {contexts?.length > 0 && newContextButton}
          </FilterActionForm>
          <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)} noWrapHeader={true}>
            {sortAndGroupContexts.map((context) => (
              <TableRow
                archived={!context.enabled}
                key={context.name}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setContextDetail(context)
                  setIsOpenDetailsFlyout(true)
                }}
              >
                <TableCellName>{context.name}</TableCellName>
                <TableCell>
                  <ContextConditionsWithoutColor archived={!context.enabled} group={context.conditions} inline={true} />
                </TableCell>
                <TableCell>{context.stacksUse ? <PureUsedStackTooltip stacksBySite={context.stacksUse} /> : <span>0 stack</span>} </TableCell>

                <ActionsTableCell>
                  <ButtonActions>
                    <Tooltip title={'View'}>
                      <Button
                        iconName={'view'}
                        variant={'tertiary'}
                        title={'View'}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          setContextDetail(context)
                          setIsOpenDetailsFlyout(true)
                        }}
                      />
                    </Tooltip>
                    {context.enabled ? (
                      <Tooltip title={context.stacksUse ? 'A used context cannot be archived.' : 'Archive'} positions={['left']}>
                        <Button
                          iconName={'archive'}
                          variant={'tertiary'}
                          title={'Archive'}
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            onArchiveContext({ ...context, enabled: false })
                          }}
                          disabled={!!context.stacksUse}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Unarchive'}>
                        <Button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            onUnArchiveContext({ ...context, enabled: true })
                          }}
                          variant={'tertiary'}
                          iconName={'unarchive'}
                        />
                      </Tooltip>
                    )}
                  </ButtonActions>
                </ActionsTableCell>
              </TableRow>
            ))}
          </Table>
        </>
      ) : (
        <NoContexts>
          <Icon name={'night_sky'} width={'190px'} />
          <div>
            <h1>No context.</h1>
            <p>
              Contexts give you flexibility and allow you to choose different scenarii for your stack, <br />
              according to the settings you defined with context keys.
            </p>
          </div>
          {newContextButton}
        </NoContexts>
      )}
      {contextDetail && <PureAdStackContextsDetailsFlyout isOpen={isOpenDetailsFlyout} onClose={() => setIsOpenDetailsFlyout(false)} context={contextDetail} />}
    </ContextsWrapper>
  )
}

export const PureAdStackContextsPage = styled(_PureAdStackContextsPage)``
