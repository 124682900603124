import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { MappedName, RefreshMetricsByDim } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useCallback } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsBreakdown, useAnalyticsBreakdown } from '~/modules/analytics/AnalyticsBreakdown'
import {
  firstCallAndRefreshAuctionFillRate,
  firstCallAndRefreshAverageViewedTime,
  firstCallAndRefreshECPM,
  firstCallAndRefreshImpressionCount,
  firstCallAndRefreshImpressionRevenue,
  firstCallAndRefreshViewabilityMRC,
  firstCallAuctionFillRate,
  firstCallAverageViewedTime,
  firstCallECPM,
  firstCallImpressionCount,
  firstCallImpressionRevenue,
  firstCallViewabilityMRC,
  refreshAuctionFillRate,
  refreshAverageViewedTime,
  refreshECPM,
  refreshImpressionCount,
  refreshImpressionRevenue,
  refreshViewabilityMRC,
} from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { TabConfig } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'

type PureRefreshBreakdownProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    rawData: RefreshMetricsByDim
    currencySymbol: CurrencySymbol
    onTabChange: (dimension: Dimension) => void
    onRowClick: (name: MappedName) => void
    defaultTabIndex?: number
    onSortChange: (metric: string) => void
    tabsConfig: TabConfig[]
  }

const _PureRefreshBreakdown: FunctionComponent<PureRefreshBreakdownProps> = ({ rawData, defaultTabIndex, onTabChange, currencySymbol, tabsConfig, ...props }) => {
  const breakdownProps = useAnalyticsBreakdown<string>({
    currencySymbol,
    rawData,
    onTabChange,
    tabsConfig,
    defaultTabIndex,
    getConfigs: useCallback(
      () => [
        [
          {
            displayedAs: 'text',
            sortLabel: 'Name',
            showLabelAsTooltip: true,
          },
          {
            formula: firstCallAndRefreshImpressionRevenue,
            propertyName: 'revenue',
            tooltip: ANALYTICS_TOOLTIPS.REVENUE,
          },
          {
            formula: firstCallAndRefreshImpressionCount,
            propertyName: 'impression',
          },
          {
            formula: firstCallAndRefreshAuctionFillRate,
            propertyName: 'auctionFillRate',
            status: {
              warning: 15,
              alert: 5,
            },
            tooltip: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
            isComputable: ({ mappedName }) => mappedName.value !== 'adx' && mappedName.value !== 'open-bidding',
          },
          {
            formula: firstCallAndRefreshECPM,
            propertyName: 'eCpm',
            tooltip: ANALYTICS_TOOLTIPS.ECPM,
          },
          {
            formula: firstCallAndRefreshViewabilityMRC,
            propertyName: 'viewabilityMRC',
            status: {
              warning: 60,
              alert: 30,
            },
            tooltip: ANALYTICS_TOOLTIPS.VIEWABILITY,
            isComputable: ({ mappedName }) => mappedName.value !== 'open-bidding',
          },
          {
            formula: firstCallAndRefreshAverageViewedTime,
            propertyName: 'averageViewedTime',
            tooltip: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
            isComputable: ({ mappedName }) => mappedName.value !== 'open-bidding',
          },
        ],
        [
          {
            displayedAs: 'text',
            sortLabel: 'Name',
            mappedName: {
              value: 'firstCall',
              label: 'First call',
            },
          },
          {
            formula: firstCallImpressionRevenue,
            propertyName: 'revenue',
            tooltip: ANALYTICS_TOOLTIPS.REVENUE,
          },
          {
            formula: firstCallImpressionCount,
            propertyName: 'impression',
          },
          {
            formula: firstCallAuctionFillRate,
            propertyName: 'auctionFillRate',
            status: {
              warning: 15,
              alert: 5,
            },
            tooltip: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
            isComputable: ({ mappedName }) => mappedName.value !== 'adx' && mappedName.value !== 'open-bidding',
          },
          {
            formula: firstCallECPM,
            propertyName: 'eCpm',
            tooltip: ANALYTICS_TOOLTIPS.ECPM,
          },
          {
            formula: firstCallViewabilityMRC,
            propertyName: 'viewabilityMRC',
            status: {
              warning: 60,
              alert: 30,
            },
            tooltip: ANALYTICS_TOOLTIPS.VIEWABILITY,
            isComputable: ({ mappedName }) => mappedName.value !== 'open-bidding',
          },
          {
            formula: firstCallAverageViewedTime,
            propertyName: 'averageViewedTime',
            tooltip: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
            isComputable: ({ mappedName }) => mappedName.value !== 'open-bidding',
          },
        ],
        [
          {
            displayedAs: 'text',
            sortLabel: 'Name',
            mappedName: {
              value: 'refresh',
              label: 'Refresh',
            },
          },
          {
            formula: refreshImpressionRevenue,
            propertyName: 'revenue',
            tooltip: ANALYTICS_TOOLTIPS.REVENUE,
          },
          {
            formula: refreshImpressionCount,
            propertyName: 'impression',
          },
          {
            formula: refreshAuctionFillRate,
            propertyName: 'auctionFillRate',
            status: {
              warning: 15,
              alert: 5,
            },
            tooltip: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
            isComputable: ({ mappedName }) => mappedName.value !== 'adx' && mappedName.value !== 'open-bidding',
          },
          {
            formula: refreshECPM,
            propertyName: 'eCpm',
            tooltip: ANALYTICS_TOOLTIPS.ECPM,
          },
          {
            formula: refreshViewabilityMRC,
            propertyName: 'viewabilityMRC',
            status: {
              warning: 60,
              alert: 30,
            },
            tooltip: ANALYTICS_TOOLTIPS.VIEWABILITY,
            isComputable: ({ mappedName }) => mappedName.value !== 'open-bidding',
          },
          {
            formula: refreshAverageViewedTime,
            propertyName: 'averageViewedTime',
            tooltip: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
            isComputable: ({ mappedName }) => mappedName.value !== 'open-bidding',
          },
        ],
      ],
      []
    ),
    getColumnsConfigs: useCallback(() => ['revenue', 'impression', 'auctionFillRate', 'eCpm', 'viewabilityMRC', 'averageViewedTime'], []),
  })

  return <AnalyticsBreakdown {...props} {...breakdownProps} />
}
export const PureRefreshBreakdown = styled(_PureRefreshBreakdown)``
