import { AbTestConfiguration, EMPTY_AB_TEST_CONFIGURATION } from '@pubstack/common/src/abTest'
import { hasFeature } from '@pubstack/common/src/rolesAndFeatures'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useAbTest } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { useToast } from '~/components/Toast/useToasts'
import { currentScopeState } from '~/state'
import { PureViewAbTestPage } from './PureViewAbTestPage'

export const ViewAbTestPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { byId } = useAbTest(null)
  const [abTest, setAbTest] = useState<AbTestConfiguration>()
  const currentScope = useRecoilValue(currentScopeState)
  const user = useUser()
  const toast = useToast()
  const loadAbTest = async () => {
    if (id) {
      try {
        const abTest = await byId.get(id)
        setAbTest(abTest)
      } catch (error) {
        navigate(`/settings`)
        toast.alert('An error occurred while fetching the A/B test configuration')
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      loadAbTest()
    })
  }, [id])

  return (
    <PureViewAbTestPage
      abTest={abTest ?? EMPTY_AB_TEST_CONFIGURATION}
      isLoading={byId.loading}
      scope={currentScope}
      hasAdx={user ? hasFeature(user.currentScopeId, user.features, 'adx') : false}
      onClose={() => navigate('/settings/ab-test')}
    />
  )
}
