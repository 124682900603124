import { RefreshRule } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRefreshRules } from '~/api/adm-api.hook'
import { PureAdStackRefreshRulesPage } from './PureAdStackRefreshRulesPage'

export const AdStackRefreshRulesPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [refreshRules, setRefreshRules] = useState<RefreshRule[]>([])

  const { all } = useRefreshRules(null)

  const loadRefreshRules = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setRefreshRules(result)
  }

  useEffect(() => {
    loadRefreshRules()
  }, [])

  return (
    <PureAdStackRefreshRulesPage
      refreshRules={refreshRules}
      isLoading={all.loading}
      onCreateRule={() => navigate(`/adstack/refresh/new`)}
      onEditRule={(id: string) => navigate(`/adstack/refresh/edit/${id}`)}
    />
  )
}
