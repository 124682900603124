import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Color, ColorUtil } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'

const TagWrapper = styled.div<{ color: Color }>`
  ${Fonts.P3};
  border-radius: 100px;
  border: 1px solid ${({ color }) => ColorUtil.hexOpacity(color, 0.2)};
  color: ${({ color }) => color};
  padding: 0 6px;
  display: inline-flex;
`

type TagProps = WithClassName & {
  children: ReactNode | ReactNode[]
  color: Color
}
const _Tag: FunctionComponent<TagProps> = ({ className, children, color }) => (
  <TagWrapper className={className} color={color}>
    {children}
  </TagWrapper>
)
export const Tag = styled(_Tag)``
