import { AdmQAReportCategory, AdmQaReport } from '@pubstack/common/src/adm-qa-reports'
import { AdminTag } from '@pubstack/common/src/tag'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAdmQAReports, useAdminTags } from '~/api/admin-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { isNumber } from '~/utils/string'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureAdmQaReportAnalysisPage } from './PureAdmQaReportAnalysisPage'
import { ADM_QA_REPORTS_DIMS, AdmQAReportsDim, AuctionsImpressionsData, BidsData, UserSessionData, ViewabilityData } from './admQaReports'

const csvToData = <T extends { [key: string]: any }>(csvString: string): T => {
  const lines = csvString
    .split('\n')
    .map((line) => line.trim().replace(/"/g, ''))
    .filter((line) => line.length)
  const headers = lines[0].split(',')

  const result = lines.slice(1).reduce(
    (acc, line) => {
      const currentline = line.split(',')
      headers.forEach((header, index) => {
        const value = currentline[index]?.trim().length ? currentline[index].trim() : null
        acc[header].push(!isNumber(value) ? value : Number(value))
      })
      return acc
    },
    Object.fromEntries(headers.map((header) => [header, []])) as T
  )
  return result
}

const EmptyAdmQaReportsData: AdmQAReportsDim = {
  adunit: [],
  biddercode: [],
  device: [],
  website: [],
  size: [],
  gampath: [],
}

const EmptyAuctionsImpressionsData: AuctionsImpressionsData = {
  ...EmptyAdmQaReportsData,
  auctionCount: [],
  bidWinCount: [],
  bidWinCpmSum: [],
  impressionCount: [],
  impressionCpmSum: [],
}

const EmptyBidsData: BidsData = {
  ...EmptyAdmQaReportsData,
  bidRequestCount: [],
  bidCpmSum: [],
  bidResponseCount: [],
  bidTimeoutCount: [],
  bidWinCount: [],
}

const EmptyViewabilityData: ViewabilityData = {
  ...EmptyAdmQaReportsData,
  impressionCpmSum: [],
  impressionCount: [],
  mrcViewableImpressionCount: [],
  adxMrcViewableImpressionCount: [],
  measuredImpressionViewedTimeSum: [],
  measuredImpressionCount: [],
  adxMeasuredImpressionCount: [],
  adxMeasuredImpressionViewedTimeSum: [],
}

const EmptyUserSessionData: UserSessionData = {
  ...EmptyAdmQaReportsData,
  impressionCount: [],
  pageCount: [],
  sessionCount: [],
}

const EmptyReport: AdmQaReport = {
  admLiveDate: '',
  createdAt: '',
  id: '',
  range: '30-minutes',
  siteIdA: '',
  siteIdB: '',
  scopeId: '',
  startDateSiteA: '',
  endDateSiteA: '',
  startDateSiteB: '',
  endDateSiteB: '',
}

const AdmQaReportAnalysisPage = () => {
  const [sites, setSites] = useState<AdminTag[]>([])
  const [report, setReport] = useState<AdmQaReport>()
  const [isDataLoading, setIsDataLoading] = useState(true)

  const [auctionsImpressionsData, setAuctionImpressionData] = useState<AuctionsImpressionsData>(EmptyAuctionsImpressionsData)
  const [bidsData, setBidData] = useState<BidsData>(EmptyBidsData)
  const [viewabilityData, setViewabilityData] = useState<ViewabilityData>(EmptyViewabilityData)
  const [userSessionData, setUserSessionData] = useState<UserSessionData>(EmptyUserSessionData)

  const { scopeId, reportId } = useParams()
  const { abort, loading, reports } = useAdmQAReports(null, scopeId ?? '')
  const { byId } = useAdminTags(null, scopeId ?? '')

  const navigate = useNavigate()

  const toast = useToast()

  const currency = useScopeCurrency()

  const getSites = async (siteIdA: string, siteIdB: string) => {
    try {
      const sites = await Promise.all([byId.get(siteIdA), byId.get(siteIdB)])
      setSites(sites)
    } catch (e) {
      toast.alert('Error getting sites.')
    }
  }

  const retrieveData = async <T extends { [key: string]: any }>(reportId: string, category: AdmQAReportCategory): Promise<T | undefined> => {
    try {
      const auctionsImpressionsRes = await reports.dataById(reportId, category)
      const data: T = csvToData(auctionsImpressionsRes)
      return data
    } catch (notFoundIGuess) {
      return undefined
    }
  }
  const getReportData = async () => {
    if (reportId) {
      try {
        if (loading.reportLoading) {
          abort.reportAbort()
        }
        const reportMetadata = await reports.byId(reportId)
        await getSites(reportMetadata.siteIdA, reportMetadata.siteIdB)
        setReport(reportMetadata)

        setAuctionImpressionData((await retrieveData(reportId, AdmQAReportCategory.AUCTIONS_IMPRESSIONS)) || EmptyAuctionsImpressionsData)
        setBidData((await retrieveData(reportId, AdmQAReportCategory.BIDS)) || EmptyBidsData)
        setViewabilityData((await retrieveData(reportId, AdmQAReportCategory.VIEWABLITY)) || EmptyViewabilityData)
        setUserSessionData((await retrieveData(reportId, AdmQAReportCategory.USER_SESSION)) || EmptyUserSessionData)

        setIsDataLoading(false)
      } catch (e) {
        toast.alert('Error getting report data.')
      }
    }
  }

  useEffect(() => {
    getReportData()
  }, [scopeId, reportId])

  const dimensions = ADM_QA_REPORTS_DIMS.filter((dim) => {
    if (dim === 'website') {
      return false
    }
    if (report?.range === '1-week') {
      return dim !== 'gampath'
    }
    return true
  })

  return useMemo(
    () => (
      <PureAdmQaReportAnalysisPage
        currencySymbol={currency}
        report={report ?? EmptyReport}
        sites={sites}
        isLoading={isDataLoading}
        auctionsImpressionsData={auctionsImpressionsData}
        bidsData={bidsData}
        viewabilityData={viewabilityData}
        userSessionData={userSessionData}
        onClickBack={() => navigate(-1)}
        applicableDimensions={dimensions}
      />
    ),
    [scopeId, reportId, isDataLoading]
  )
}

export default AdmQaReportAnalysisPage
