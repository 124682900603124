import styled from '@emotion/styled'
import { isEnabledByHeaderBiddingRule } from '@pubstack/common/src/adstack/header-bidding-rule-utils'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DisplayedFloorRule, DisplayedRefreshRule, DisplayedStack } from '@pubstack/common/src/stack'
import { Fragment, FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { OutOfSync } from '~/components/AdStack/OutOfSync'
import { Icon } from '~/components/Icon'
import { Skeleton } from '~/components/Skeleton'
import { Tooltip } from '~/components/Tooltip'
import { ContextConditions } from '~/modules/adstack/ContextConditions'
import { StackStatus } from '~/modules/adstack/StackStatus'
import { WithClassName } from '~/types/utils'
import { displayWithCurrency } from '~/utils/string'
import { DetailsWrapper, StackRuleDetails, TextIcon, Title, pipeSeparator } from '../components/StackRuleDetails'
import { RichDetails } from './components/RichDetails'

type PureStackDetailsProps = WithClassName & {
  isLoading: boolean
  detailedStack: DisplayedStack
  onBack: () => unknown
  currencySymbol: CurrencySymbol
  hasAdmRefresh: boolean
}

const StackDetailWrapper = styled.div`
    display: flex;
  `

const StackDetailBloc = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
  padding: 0 20px 0 0;

  h3 {
    ${Fonts.P2}
    color: ${Colors.SlateGrey};
    font-weight: normal;
  }
`
const SmallStackDetailBloc = styled(StackDetailBloc)`
width: 243px;
`
const TooltipStyled = styled(Tooltip)`
  width: 18px;
  height: 18px;
`

const NoValueText = styled.div`
  color: ${Colors.Ash};
  font-style: italic;
`
const ModuleRuleTitle = styled.div`
  ${Fonts.P2}
  display: flex;
  font-size: 18px;
`

const _PureStackDetails: FunctionComponent<PureStackDetailsProps> = ({ className, isLoading, detailedStack, currencySymbol, hasAdmRefresh }) => {
  const floorPriceEnable =
    ((detailedStack.floorPriceDesktopRules && detailedStack.floorPriceDesktopRules.length > 0) || (detailedStack.floorPriceMobileRules && detailedStack.floorPriceMobileRules.length > 0)) ?? false
  const lazyLoadingEnabled = !!detailedStack.lazyLoadingDesktopRule || !!detailedStack.lazyLoadingMobileRule
  const refreshEnable =
    ((detailedStack.refreshDesktopRules && detailedStack.refreshDesktopRules.length > 0) || (detailedStack.refreshMobileRules && detailedStack.refreshMobileRules.length > 0)) ?? false
  const enabledBidders = detailedStack.prebidBidders?.filter((bidder) => {
    return isEnabledByHeaderBiddingRule(bidder.bidderName, detailedStack.headerBiddingRule)
  })
  return (
    <>
      <Title>Infos</Title>
      <DetailsWrapper>
        <StackDetailWrapper>
          <SmallStackDetailBloc>
            <h3>Site</h3>
            <span> {isLoading ? <Skeleton bigger /> : detailedStack.siteName}</span>
          </SmallStackDetailBloc>

          <SmallStackDetailBloc>
            <h3>Context</h3>
            {isLoading ? (
              <Skeleton bigger />
            ) : detailedStack.conditions ? (
              <Tooltip reposition={true} title={<RichDetails conditions={<ContextConditions group={detailedStack.conditions} />} />} align={'start'}>
                <span>{detailedStack.contextName}</span>
              </Tooltip>
            ) : (
              <span>{detailedStack.contextName}</span>
            )}
          </SmallStackDetailBloc>

          <SmallStackDetailBloc>
            <h3>Status</h3>
            {isLoading ? <Skeleton bigger /> : <StackStatus stack={{ ...detailedStack, elementsToSync: detailedStack.elementsToSync || [], enabled: true }} />}
          </SmallStackDetailBloc>
        </StackDetailWrapper>
      </DetailsWrapper>
      <Title>Ad units</Title>
      <DetailsWrapper>
        <StackDetailBloc>
          <h3>Desktop</h3>
          <TextIcon>
            {isLoading ? (
              <Skeleton bigger />
            ) : (
              detailedStack.desktopAdUnits.map((adUnit, index) => (
                <Fragment key={index}>
                  <OutOfSync category={'Ad units'} detailedStack={detailedStack} name={adUnit.name} adUnit={adUnit} />
                  {index < detailedStack.desktopAdUnits.length - 1 && pipeSeparator}
                </Fragment>
              ))
            )}
          </TextIcon>
        </StackDetailBloc>
        <StackDetailBloc>
          <h3>Mobile</h3>
          <TextIcon>
            {isLoading ? (
              <Skeleton bigger />
            ) : (
              detailedStack.mobileAdUnits.map((adUnit, index) => (
                <Fragment key={index}>
                  <OutOfSync category={'Ad units'} detailedStack={detailedStack} name={adUnit.name} adUnit={adUnit} />
                  {index < detailedStack.mobileAdUnits.length - 1 && pipeSeparator}
                </Fragment>
              ))
            )}
          </TextIcon>
        </StackDetailBloc>
      </DetailsWrapper>
      <TextIcon>
        <Title>Header bidding</Title>
        <Icon width={'26px'} name={detailedStack.headerBiddingEnabled ? 'check' : 'close'} fill={detailedStack.headerBiddingEnabled ? Colors.Success : Colors.Hurricane} />
      </TextIcon>
      <DetailsWrapper>
        {detailedStack.headerBiddingEnabled && (
          <StackDetailBloc>
            <h3>Timeout</h3>
            <span>{isLoading ? <Skeleton bigger /> : `${detailedStack.adCallTimeout} ms`}</span>
          </StackDetailBloc>
        )}
      </DetailsWrapper>
      <Title>Integrations</Title>
      <DetailsWrapper>
        <StackDetailBloc>
          <TextIcon>
            <h3>Ad server</h3>
            <TooltipStyled title={'Ad server used on this site.'}>
              <Icon width={'16px'} name={'info'} fill={Colors.Hurricane} />
            </TooltipStyled>
          </TextIcon>
          <span>{isLoading ? <Skeleton bigger /> : detailedStack.adServer}</span>
        </StackDetailBloc>
        <StackDetailBloc>
          <TextIcon>
            <h3>Prebid bidders</h3>
            <TooltipStyled title={'At least one ad unit of the stack is mapped with those bidders for this site.'}>
              <Icon width={'16px'} name={'info'} fill={Colors.Hurricane} />
            </TooltipStyled>
          </TextIcon>
          <TextIcon>
            {isLoading ? (
              <Skeleton bigger />
            ) : detailedStack.headerBiddingEnabled ? (
              <div>
                <ModuleRuleTitle>
                  <OutOfSync category={'Header bidding rule'} detailedStack={detailedStack} name={detailedStack.headerBiddingRule?.ruleName ?? 'All active bidders'} />
                </ModuleRuleTitle>
                <TextIcon>
                  {enabledBidders?.map((bidder, index) => (
                    <Fragment key={index}>
                      <OutOfSync category={'Integrations rule'} detailedStack={detailedStack} name={bidder.bidderName} bidder={bidder} />
                      {enabledBidders && index < enabledBidders.length - 1 && pipeSeparator}
                    </Fragment>
                  ))}
                </TextIcon>
              </div>
            ) : (
              <NoValueText>Header bidding disabled</NoValueText>
            )}
          </TextIcon>
        </StackDetailBloc>
        <StackDetailBloc>
          <TextIcon>
            <h3>Prebid modules</h3>
            <TooltipStyled title={'Prebid modules used on this site.'}>
              <Icon width={'16px'} name={'info'} fill={Colors.Hurricane} />
            </TooltipStyled>
          </TextIcon>
          <TextIcon>
            {isLoading ? (
              <Skeleton bigger />
            ) : detailedStack.headerBiddingEnabled ? (
              detailedStack.prebidModules?.map((module, index) => (
                <Fragment key={index}>
                  <OutOfSync category={'Prebid module'} detailedStack={detailedStack} name={module} />
                  {detailedStack.prebidModules && index < detailedStack.prebidModules.length - 1 && pipeSeparator}
                </Fragment>
              ))
            ) : (
              <NoValueText>Header bidding disabled</NoValueText>
            )}
          </TextIcon>
        </StackDetailBloc>
      </DetailsWrapper>
      <TextIcon>
        <Title>Lazy loading</Title>
        <Icon width={'26px'} name={lazyLoadingEnabled ? 'check' : 'close'} fill={lazyLoadingEnabled ? Colors.Success : Colors.Hurricane} />
      </TextIcon>
      {isLoading ? (
        <Skeleton bigger width={'750px'} />
      ) : lazyLoadingEnabled ? (
        <DetailsWrapper>
          <StackDetailBloc>
            <h3>Desktop rule</h3>
            <TextIcon>
              {detailedStack.lazyLoadingDesktopRule ? (
                <OutOfSync category={'Lazy loading rule'} detailedStack={detailedStack} name={detailedStack.lazyLoadingDesktopRule.name} lazyLoadingRule={detailedStack.lazyLoadingDesktopRule} />
              ) : (
                <span>No lazy loading</span>
              )}
            </TextIcon>
          </StackDetailBloc>
          <StackDetailBloc>
            <h3>Mobile rule</h3>
            <TextIcon>
              {detailedStack.lazyLoadingMobileRule ? (
                <OutOfSync category={'Lazy loading rule'} detailedStack={detailedStack} name={detailedStack.lazyLoadingMobileRule.name} lazyLoadingRule={detailedStack.lazyLoadingMobileRule} />
              ) : (
                <span>No lazy loading</span>
              )}
            </TextIcon>
          </StackDetailBloc>
        </DetailsWrapper>
      ) : (
        <DetailsWrapper />
      )}
      <StackRuleDetails
        isLoading={isLoading}
        detailedStack={detailedStack}
        title={'Floor price'}
        featureEnabled={floorPriceEnable}
        syncCategory={'Floor price rule'}
        showName={(rule: DisplayedFloorRule) =>
          rule.floor === 'No floor price' ? rule.floor : `${rule.ruleName} (${displayWithCurrency((Number.parseInt(rule.floor) / 100).toFixed(2), currencySymbol)})`
        }
        desktopRules={detailedStack.floorPriceDesktopRules ?? []}
        mobileRules={detailedStack.floorPriceMobileRules ?? []}
      />
      {hasAdmRefresh && (
        <StackRuleDetails
          isLoading={isLoading}
          detailedStack={detailedStack}
          title={'Refresh'}
          featureEnabled={refreshEnable}
          syncCategory={'Refresh rule'}
          showName={(rule: DisplayedRefreshRule) => rule.ruleName}
          desktopRules={detailedStack.refreshDesktopRules ?? []}
          mobileRules={detailedStack.refreshMobileRules ?? []}
        />
      )}
      {detailedStack.commitMessage && (
        <>
          <Title>Comment</Title>
          <div>{isLoading ? <Skeleton bigger width={'750px'} /> : detailedStack.commitMessage}</div>
        </>
      )}
    </>
  )
}

export const PureStackDetails = styled(_PureStackDetails)``
