import styled from '@emotion/styled'
import { LazyLoadingRule } from '@pubstack/common/src/stack'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { Tooltip } from '~/components/Tooltip'
import Table, { handleTableSearch, handleTableSort, onColumnSort, SortableColumn, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { PureUsedStackTooltip } from '~/modules/adstack/rules/PureUsedStackTooltip'
import { countStacksUse } from '~/modules/adstack/rules/countStacksUsed'
import { WithClassName } from '~/types/utils'
import { PureAdstackLazyLoadingHelpFlyout } from './PureAdStackLazyLoadingHelpFlyout'

type PureAdStackLazyLoadingPageProps = WithClassName & {
  lazyLoadingRules: LazyLoadingRule[]
  isLoading: boolean
  breadcrumbs: React.ReactNode
  onCreateRule: () => void
  onEditRule: (id: string) => void
}

const LlRulesWrapper = styled.div`
  padding: 20px;
`

const TitleBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const TitleButtons = styled.div`
  display: flex;
  gap: 20px;
`

const ActionsTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down to hug action buttons */
`

const ButtonActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

const FilterAction = styled.form`
  margin: 12px 0px;
  display: flex;
`

const HeaderBiddingIconWrapper = styled.div`
  display: flex;
`

type LazyLoadingRuleListed = Omit<LazyLoadingRule, 'creationTime' | 'technicalId'>

const _PureAdStackLazyLoadingPage: FunctionComponent<PureAdStackLazyLoadingPageProps> = ({ className, lazyLoadingRules, isLoading, breadcrumbs, onCreateRule, onEditRule }) => {
  const [columns, setColumns] = useState<TableColumns<LazyLoadingRuleListed>>([
    {
      name: 'Rule',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'HB',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'headerBiddingEnabled',
    },
    {
      name: 'GAM',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'gamEnabled',
    },
    {
      name: 'Auction start',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'prebidAuctionViewportsPercent',
    },
    {
      name: 'GAM request',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'googletagFetchViewportsPercent',
    },
    {
      name: 'Render impression',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'googletagRenderViewportsPercent',
    },
    {
      name: 'Used on',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'stacksUse',
    },
    {
      name: 'Actions',
      isSortable: false,
    },
  ])

  const [isOpenHelpFlyout, setIsOpenHelpFlyout] = useState<boolean>(false)

  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })
  const lazyLoadingRulesWithNoRule: LazyLoadingRuleListed[] = [...lazyLoadingRules, { id: 'NO_RULE', creationTime: 0, name: 'No lazy loading', technicalId: '0' }]
  const search = useWatch({ control, name: 'search' })
  const searchedLLRules = handleTableSearch(lazyLoadingRulesWithNoRule, search, ['name'])
  const sortedColumn = (columns.find((c) => c.isSortable && c.order !== 'none') || columns[0]) as SortableColumn<LazyLoadingRule>
  let displayLlRules: LazyLoadingRuleListed[] = []
  if (sortedColumn.attributeSort === 'stacksUse') {
    displayLlRules = searchedLLRules.sort((a, b) => (sortedColumn.order === 'ascending' ? 1 : -1) * (countStacksUse(b.stacksUse) - countStacksUse(a.stacksUse)))
  } else {
    displayLlRules = handleTableSort(columns, searchedLLRules)
  }

  return (
    <LlRulesWrapper className={className}>
      <TitleBar>
        {breadcrumbs}
        <TitleButtons>
          <Button variant={'tertiary'} iconName={'help'} onClick={() => setIsOpenHelpFlyout(true)}>
            Help
          </Button>
          <Button variant={'primary'} onClick={onCreateRule}>
            New rule
          </Button>
        </TitleButtons>
      </TitleBar>
      <>
        <FilterAction>
          <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
        </FilterAction>
        <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
          {displayLlRules.map((llRule) => (
            <TableRow key={llRule.id} onClick={llRule.scopeId ? () => onEditRule(llRule.id) : undefined}>
              <TableCell>{llRule.name}</TableCell>
              <TableCell>
                <HeaderBiddingIconWrapper>{llRule.headerBiddingEnabled ? <Icon name={'check'} fill={Colors.Success} /> : <Icon name={'close'} fill={Colors.Ash} />}</HeaderBiddingIconWrapper>
              </TableCell>
              <TableCell>
                <HeaderBiddingIconWrapper>{llRule.gamEnabled ? <Icon name={'check'} fill={Colors.Success} /> : <Icon name={'close'} fill={Colors.Ash} />}</HeaderBiddingIconWrapper>
              </TableCell>
              <TableCell>{llRule.headerBiddingEnabled ? `${llRule.prebidAuctionViewportsPercent} viewports` : `-`}</TableCell>
              <TableCell>{llRule.googletagFetchViewportsPercent !== undefined ? `${llRule.googletagFetchViewportsPercent} viewport(s)` : `-`}</TableCell>
              <TableCell>{llRule.googletagRenderViewportsPercent !== undefined ? `${llRule.googletagRenderViewportsPercent} viewport(s)` : `-`}</TableCell>
              <TableCell>{llRule.id === 'NO_RULE' ? '-' : llRule.stacksUse ? <PureUsedStackTooltip stacksBySite={llRule.stacksUse} /> : <span>0 stack</span>}</TableCell>
              <ActionsTableCell>
                <ButtonActions>
                  <Tooltip title={llRule.scopeId ? 'Edit' : `Can't edit a Pubstack rule`} positions={['left', 'bottom', 'top', 'right']}>
                    <Button iconName={'edit'} variant={'tertiary'} title={'Edit'} onClick={() => onEditRule(llRule.id)} disabled={!llRule.scopeId} />
                  </Tooltip>
                </ButtonActions>
              </ActionsTableCell>
            </TableRow>
          ))}
        </Table>
      </>
      <PureAdstackLazyLoadingHelpFlyout isOpen={isOpenHelpFlyout} onRequestClose={() => setIsOpenHelpFlyout(false)} />
    </LlRulesWrapper>
  )
}

export const PureAdStackLazyLoadingPage = styled(_PureAdStackLazyLoadingPage)``
