import styled from '@emotion/styled'
import { UserViewMode, scopesAllowed } from '@pubstack/common/src/rolesAndFeatures'
import { Scope } from '@pubstack/common/src/scope'
import { FC } from 'react'
import { Colors } from '~/assets/style/colors'
import { Sizes } from '~/assets/style/tokens'
import PbstckUser from '~/auth/User'
import Button from '~/components/Button'
import { Popover } from '~/components/Popover'
import { PopoverButton } from '~/components/PopoverButton'
import { WithClassName } from '~/types/utils'
import ProfilePopoverContent from './ProfilePopoverContent'
import { ScopesButton } from './ScopeButton'
import ScopesPopoverContent from './ScopePopoverContent'

const HeaderWrapper = styled.div`
  height: 64px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${Sizes[16]};
  background: ${Colors.White};
  border-bottom: 1px solid ${Colors.Platinum};
`

const HeaderNavigation = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 24px;
`

const ScopesAndMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

type PureHeaderProps = WithClassName & {
  user: PbstckUser | null
  scopes: Scope[] | undefined
  currentScope: Scope
  onGoToDocsClick: () => void
  toggleSidebarOpen: () => void
  isSidebarPinned: boolean
  isSidebarOpen: boolean
}
const _PureHeader: FC<PureHeaderProps> = ({ user, scopes, currentScope, onGoToDocsClick, isSidebarPinned, toggleSidebarOpen, isSidebarOpen, className }) => {
  const hasMultiScope: boolean = user ? scopesAllowed(user?.userRoles) === 'all' || scopesAllowed(user?.userRoles).length > 1 : false

  const suffix = (view: UserViewMode | undefined) => {
    return view ? ` (as ${view})` : ''
  }

  return (
    <HeaderWrapper className={className}>
      <ScopesAndMenu>
        {!isSidebarPinned && <Button name={'HEADER_MENU_TOGGLE'} iconName={'logoMenu'} variant={'tertiary'} iconSize={'32px'} onClick={toggleSidebarOpen} isHover={isSidebarOpen || undefined} />}
        {hasMultiScope ? (
          <Popover trigger={<ScopesButton currentScope={currentScope} hasMultiScope={hasMultiScope} />}>{scopes && <ScopesPopoverContent scopes={scopes} />}</Popover>
        ) : (
          <ScopesButton currentScope={currentScope} hasMultiScope={hasMultiScope} />
        )}
      </ScopesAndMenu>
      <HeaderNavigation>
        <Button variant={'tertiary'} id={'beamer'}>
          What&apos;s new?
        </Button>
        <Button variant={'tertiary'} onClick={onGoToDocsClick}>
          Go to docs
        </Button>
        <Popover align={'end'} trigger={<PopoverButton label={`${user?.profile.nickname}${suffix(user?.view)}`} />}>
          <ProfilePopoverContent currentScope={currentScope} />
        </Popover>
      </HeaderNavigation>
    </HeaderWrapper>
  )
}

export const PureHeader = styled(_PureHeader)``
