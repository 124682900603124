import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { EMPTY_GROUP_OVERLAP, GroupOverlap, RefreshGroup } from '@pubstack/common/src/refresh'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { ContentCard } from '~/components/ContentCard'
import { Icon } from '~/components/Icon'
import { Skeleton } from '~/components/Skeleton'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'
import { useValidateRefreshGroup } from './AccordionFormUtils'
import { PureRefreshGroupOverlapModal } from './PureRefreshGroupOverlapModal'
import { PureRefreshGroupRecapModal } from './PureRefreshGroupRecapModal'
import { RefreshGroupAdUnit, RefreshGroupAdUnitsForm } from './RefreshGroupAccordionForms/RefreshGroupAdUnitsForm'
import { RefreshGroupCappingForm } from './RefreshGroupAccordionForms/RefreshGroupCappingForm'
import { RefreshGroupCustomSettingsForm } from './RefreshGroupAccordionForms/RefreshGroupCustomSettingsForm'
import { RefreshGroupDevice, RefreshGroupDevicesForm } from './RefreshGroupAccordionForms/RefreshGroupDevicesForm'
import { RefreshGroupKeyValuesForm } from './RefreshGroupAccordionForms/RefreshGroupKeyValuesForm'
import { RefreshGroupNameForm } from './RefreshGroupAccordionForms/RefreshGroupNameForm'
import { RefreshGroupRulesForm } from './RefreshGroupAccordionForms/RefreshGroupRulesForm'
import { RefreshGroupSitesForm } from './RefreshGroupAccordionForms/RefreshGroupSitesForm'

type AccordionSection = { validation: boolean; title: React.ReactNode; content: React.ReactNode; overlapping?: boolean }

const AccordionContentCard = styled(ContentCard)`
  padding: 0px 20px;
`

const AccordionCollapsibleSection = styled(CollapsiblePanel)`
  border: none;
  border-radius: 0;
  padding: 0;
  box-sizing: content-box;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${Colors.Platinum};
  }
  ${Fonts.P1}
  ${Fonts.colors.Jet}
`

const AccordionCollapsibleSectionTitle = styled.span`
  display: inline-flex;
  padding: 16px 0;
  align-items: center;
  gap: 12px;
  font-weight: 500;
`

const AccordionCollapsibleSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`

const AccordionCollapsibleSectionActions = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
`

const OverlapLegendStyle = css`
  background-color: ${Colors.Mew};
  padding: 0 10px 0 10px;
  border-radius: 4px;
  font-weight: 400;
`

const OverlapTitle = styled.span`
  ${OverlapLegendStyle}
`

type PureSettingsRefreshGroupAccordionPageProps = WithClassName & {
  isPubstackRole: boolean
  isEditing: boolean
  isLoading: boolean
  sites: Site[]
  adUnits: RefreshGroupAdUnit[]
  bidders: string[]
  devices: RefreshGroupDevice[]
  refreshGroup: RefreshGroup
  setRefreshGroup: (refreshGroup: RefreshGroup) => unknown
  onSave: (refreshGroup: RefreshGroup, options?: { activateGroup?: boolean; bypassCheck?: boolean }) => Promise<void | GroupOverlap>
}
export const _PureSettingsRefreshGroupAccordionPage: FunctionComponent<PureSettingsRefreshGroupAccordionPageProps> = ({
  className,
  isPubstackRole,
  isEditing,
  isLoading,
  refreshGroup,
  setRefreshGroup,
  sites,
  adUnits,
  devices,
  bidders,
  onSave,
}) => {
  const modal = useGlobalModal()
  const [currentSection, setCurrentSection] = useState(0)
  const [groupOverlap, setGroupOverlap] = useState<GroupOverlap>(EMPTY_GROUP_OVERLAP)
  const refreshGroupValidation = useValidateRefreshGroup(refreshGroup)

  const sections: AccordionSection[] = [
    {
      validation: refreshGroupValidation.name,
      title: 'Group name',
      content: <RefreshGroupNameForm updateRefreshGroup={(name) => setRefreshGroup({ ...refreshGroup, name: name })} name={refreshGroup.name} />,
    },
    {
      validation: refreshGroupValidation.sites,
      title: 'Sites',
      overlapping: refreshGroup.tagIds.some((site) => groupOverlap.tagIds.includes(site.id)),
      content: (
        <RefreshGroupSitesForm overlap={groupOverlap} updateRefreshGroup={(sites) => setRefreshGroup({ ...refreshGroup, tagIds: sites })} siteList={sites} refreshGroupSites={refreshGroup.tagIds} />
      ),
    },
    {
      validation: refreshGroupValidation.adUnits,
      title: 'Ad units',
      overlapping: refreshGroup.adUnits.some((adunit) => groupOverlap.adUnits.includes(adunit.id)),
      content: (
        <RefreshGroupAdUnitsForm
          overlap={groupOverlap}
          updateRefreshGroup={(adUnits) => setRefreshGroup({ ...refreshGroup, adUnits })}
          adUnitList={adUnits}
          refreshGroupAdUnits={refreshGroup.adUnits}
        />
      ),
    },
    {
      validation: refreshGroupValidation.devices,
      title: 'Devices',
      overlapping: refreshGroup.devices.some((device) => groupOverlap.devices.includes(device.id)),
      content: (
        <RefreshGroupDevicesForm
          overlap={groupOverlap}
          updateRefreshGroup={(devices) => setRefreshGroup({ ...refreshGroup, devices })}
          deviceList={devices}
          refreshGroupDevices={refreshGroup.devices}
        />
      ),
    },
    {
      validation: refreshGroupValidation.keyValueTargetings,
      title: 'Key-values - GAM only (optional)',
      content: <RefreshGroupKeyValuesForm updateRefreshGroup={(keyValues) => setRefreshGroup({ ...refreshGroup, keyValueTargetings: keyValues })} keyValues={refreshGroup.keyValueTargetings ?? []} />,
    },
    {
      validation: refreshGroupValidation.rules,
      title: 'Refresh rules',
      content: (
        <RefreshGroupRulesForm
          bidders={bidders}
          updateRefreshGroup={(rules) => setRefreshGroup({ ...refreshGroup, ...rules })}
          refreshRules={{ timer: refreshGroup.timer, specificRules: refreshGroup.specificRules ?? [] }}
        />
      ),
    },
    {
      validation: refreshGroupValidation.capping,
      title: 'Refresh capping',
      content: <RefreshGroupCappingForm updateRefreshGroup={(capping) => setRefreshGroup({ ...refreshGroup, capping })} refreshCapping={refreshGroup.capping} />,
    },
    ...(isPubstackRole
      ? ([
          {
            validation: refreshGroupValidation.customSettings,
            title: 'Custom settings - Pubstack only',
            content: (
              <RefreshGroupCustomSettingsForm
                updateRefreshGroup={(customSettings) => setRefreshGroup({ ...refreshGroup, ...customSettings })}
                customSettings={{ preventCLS: !!refreshGroup.preventCLS, blockerEnabled: refreshGroup.blockerEnabled, populationSplit: refreshGroup.populationSplit }}
              />
            ),
          },
        ] as AccordionSection[])
      : []),
  ]
  //every section is untouched except for the first one or if we're editing an existing group
  const [sectionsIsTouched, setSectionsIsTouched] = useState<boolean[]>(sections.map((_, i) => isEditing || i === 0))

  const openSection = (sectionIndex: number) => {
    setCurrentSection(sectionIndex)
    setSectionsIsTouched(sectionsIsTouched.map((value, i) => (sectionIndex === i ? true : value)))
  }

  const nextSection = () => {
    if (currentSection + 1 < sections.length) {
      openSection(currentSection + 1)
    }
  }

  const openOverlapModal = (groupOverlap: GroupOverlap) => {
    modal.open(PureRefreshGroupOverlapModal, {
      overlap: { ...groupOverlap, tagIds: groupOverlap.tagIds.map((t) => sites.find((s) => s.id === t)?.name ?? t) },
      onSaveAndDeactivate: () => onSave(refreshGroup, { activateGroup: false, bypassCheck: true }),
    })
  }

  const isLastSection = currentSection === sections.length - 1

  return (
    <AccordionContentCard className={className} color={SETTINGS_NAV_CONFIG.refreshGroups.color}>
      {sections.map((section, index) => (
        <AccordionCollapsibleSection
          key={index}
          title={
            <AccordionCollapsibleSectionTitle>
              {section.title} {sectionsIsTouched[index] && section.validation && <Icon name={'check'} width={'20px'} fill={Colors.Success} />}
              {section.overlapping && <OverlapTitle>Overlapping issues</OverlapTitle>}
            </AccordionCollapsibleSectionTitle>
          }
          hideCollapseIcon
          isCollapsible
          onToggle={index === 0 || sections[index - 1]?.validation ? () => openSection(index) : undefined}
          forceState={index === currentSection ? 'open' : 'closed'}
          actions={sectionsIsTouched[index] && section.validation && index !== currentSection && <Button variant={'tertiary'} iconName={'edit'} onClick={() => openSection(index)} />}
        >
          <AccordionCollapsibleSectionContent>{!isLoading ? section.content : <Skeleton bigger width={'200px'} />}</AccordionCollapsibleSectionContent>
          <AccordionCollapsibleSectionActions>
            {isLastSection && (
              <Button variant={'tertiary'} onClick={() => modal.open(PureRefreshGroupRecapModal, { refreshGroup })}>
                Recap
              </Button>
            )}
            {isLastSection ? (
              <>
                <Button
                  variant={refreshGroup.enabled ? 'primary' : 'secondary'}
                  disabled={Object.values(refreshGroupValidation).some((s) => !s)}
                  onClick={async () => {
                    const v = await onSave(refreshGroup)
                    if (v) {
                      setGroupOverlap(v)
                      openOverlapModal(v)
                    }
                  }}
                >
                  Save
                </Button>
                {!refreshGroup.enabled && (
                  <Button
                    disabled={Object.values(refreshGroupValidation).some((s) => !s)}
                    onClick={async () => {
                      const v = await onSave(refreshGroup, { activateGroup: true })
                      if (v) {
                        setGroupOverlap(v)
                        openOverlapModal(v)
                      }
                    }}
                  >
                    Save & activate
                  </Button>
                )}
              </>
            ) : (
              <Button disabled={!section.validation} onClick={nextSection}>
                Next
              </Button>
            )}
          </AccordionCollapsibleSectionActions>
        </AccordionCollapsibleSection>
      ))}
    </AccordionContentCard>
  )
}

export const PureSettingsRefreshGroupAccordionPage = styled(_PureSettingsRefreshGroupAccordionPage)``
