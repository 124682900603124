import styled from '@emotion/styled'
import { AbTestConfiguration } from '@pubstack/common/src/abTest'
import { FunctionComponent, ReactNode, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Input, _Input } from '~/components/Input'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { PureActivateAbTestModal } from '~/modules/settings/abTest/PureActivateAbTestModal'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'
import { AbTestFormData } from './AddAbTestPage'

const FormWrapper = styled.form`
  max-width: 1000px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CardTitle = styled.h2`
  ${Fonts.colors.Jet}
  ${Fonts.H1}
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.Platinum};
`

const Card = styled(ContentCard)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${_Input} {
    width: 304px;
  }
  input:placeholder-shown {
    font-style: italic;
  }
`

const ValidationWrapper = styled.div`
  text-align: right;
  ${Button} {
    margin-left: 15px;
  }
  padding-top: 8px;
`

const VariantForm = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 28px;
`

const VariantWrapper = styled.section`
  display: inline-flex;
  gap: 12px;
  align-items: baseline;
  ${_Input} {
    width: 304px;
  }
  input:placeholder-shown {
    font-style: italic;
  }

  & ${Button} {
    align-self: center;
  }
`

const VariantsWrapper = styled.div`
  margin-bottom: 4px;
  margin-top: 26px;
`

type PureAddAbTestPage = WithClassName & {
  breadcrumbs: ReactNode
  onSave: (data: AbTestFormData) => void
  isOpenModal: boolean
  createdAbTest?: AbTestFormData
  onActivate: () => void
  onRequestClose: () => void
  navigateToView: (id?: string) => void
}
const _PureAddAbTestPage: FunctionComponent<PureAddAbTestPage> = ({ className, onSave, isOpenModal, createdAbTest, onActivate, onRequestClose, navigateToView }) => {
  const modal = useGlobalModal()
  const defaultValues: AbTestConfiguration = {
    id: '',
    variants: [{ variant: '' }, { variant: '' }],
    name: '',
    scopeId: '',
    active: false,
  }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AbTestFormData>({ defaultValues })

  const { fields, append, remove } = useFieldArray({ control, name: 'variants' })

  const onSubmit = (data: AbTestFormData) => {
    onSave(data)
  }

  // TODO cfo 2023-11-08 - Ugly special case. Need some rethinking
  useEffect(() => {
    if (isOpenModal && !modal.isOpen) {
      modal.open(
        PureActivateAbTestModal,
        {
          abTest: createdAbTest,
          onToggle: onActivate,
          navigateToView: () => navigateToView(createdAbTest?.id),
        },
        {
          onAfterDefaultClose: () => {
            onRequestClose()
            navigateToView(createdAbTest?.id)
          },
        }
      )
    }

    if (!modal.isOpen) {
      onRequestClose()
    }
  }, [isOpenModal])

  return (
    <div className={className}>
      <FormWrapper>
        <Card color={SETTINGS_NAV_CONFIG['abTest'].color}>
          <CardTitle>Setup test</CardTitle>
          <CardContent>
            <h2>Identification</h2>
            <Input error={errors?.name?.message} name={'name'} control={control} placeholder={'Test name'} rules={{ required: 'Name required' }} />
            <div>
              <VariantsWrapper>
                <h2>Variants</h2>
              </VariantsWrapper>
              Set from 2 to 4 variants
            </div>
            <VariantForm>
              {fields.map((field, index) => (
                <VariantWrapper key={field.id}>
                  <Input
                    error={errors?.variants?.[index]?.variant?.message}
                    placeholder={'Variant name'}
                    control={control}
                    name={`variants.${index}.variant`}
                    rules={{
                      required: 'Variant required',
                      pattern: { value: /^[^'"/\\]+$/, message: 'Incorrect syntax' },
                    }}
                  />
                  {index > 1 && (
                    <Button
                      onClick={() => {
                        remove(index)
                      }}
                      variant={'tertiary'}
                      iconName={'delete'}
                    />
                  )}
                </VariantWrapper>
              ))}
              {fields.length < 4 && (
                <Button variant={'secondary'} iconName={'add'} onClick={() => append({ variant: '' }, { shouldFocus: false })}>
                  Add variant
                </Button>
              )}
            </VariantForm>
            <ValidationWrapper>
              <Button onClick={handleSubmit(onSubmit)}>Validate</Button>
            </ValidationWrapper>
          </CardContent>
        </Card>
      </FormWrapper>
    </div>
  )
}

export const PureAddAbTestPage = styled(_PureAddAbTestPage)``
