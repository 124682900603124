import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'

// TODO cfo 2022-11-29 - Remove once emotion is fully compatible with React18
const HiddenTooltipWrapper = styled.div`
  display: none;
  height: 0;
  width: 0;
  max-width: 0;
  max-height: 0;
`

export const AnalyticsChartsTooltipHidden: FunctionComponent = () => {
  const metrics: ChartTooltipMetricProps[][] = [[{ label: 'line 1', value: 'value 1', secondary: false }], [{ label: 'line 2', value: 'value 2', secondary: true }]]
  return (
    <HiddenTooltipWrapper>
      <ChartTooltip date={'0000-00-00'} metrics={metrics} />
    </HiddenTooltipWrapper>
  )
}
