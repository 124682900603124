import styled from '@emotion/styled'
import * as React from 'react'
import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useMemo, useState } from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { BaseInputProps, _Input } from './Input'
import { SelectOptionProp, SelectableOptionsPopover } from './SelectableOptionsPopover'

type BaseSelectProps = WithClassName &
  Omit<BaseInputProps, 'value' | 'onChange' | 'label'> & {
    label: string
    onChange?: (option: SelectOptionProp) => unknown
    options: SelectOptionProp[]
    value: string | number | undefined
    searchable?: boolean
    searchDisabledValueErrorMessage?: string
    error?: string | React.ReactNode // TODO try to write a type with error property only if it's searchable (without raise an issue)
  }

// TODO tmu 2024 06 11 - styling should not be dependent on Input internal tree arrangement (input, div > span > svg)
const SelectInput = styled(_Input)<{ isOpen: boolean; search: boolean }>`
  input {
    cursor: pointer;
    caret-color: ${({ search }) => (search ? Colors.Jet : 'transparent')};
  }

  & div > span svg {
    transition: transform ${Transitions.quick};
    transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0')});
  }
`

const getOptionValue = (options: SelectOptionProp[], value: string | number | undefined) =>
  options.flatMap((option) => (option.value instanceof Array ? option.value : option)).find((option) => option.value === value) || { label: '' }
const filterOptionsBySearch = (options: SelectOptionProp[], searchValue: string): SelectOptionProp[] => {
  const includesSearch = (option: SelectOptionProp) => option.label.toLowerCase().includes(searchValue.toLowerCase())
  return options
    .map((option) => {
      if (option.value instanceof Array) {
        let newValue = filterOptionsBySearch(option.value, searchValue)
        if (!newValue.length) {
          newValue = option.value
        }
        return { ...option, value: newValue }
      }
      return option
    })
    .filter((option) => {
      if (option.value instanceof Array) {
        return includesSearch(option) || option.value.some(includesSearch)
      }
      return includesSearch(option)
    })
}

const useSortedOptions = (options: SelectOptionProp[]) =>
  useMemo((): SelectOptionProp[] => {
    const sortOptions = (options: SelectOptionProp[]) =>
      [...options].sort((o1, o2) => {
        if (o1.disabled && !o2.disabled) {
          return 1
        } else if (!o1.disabled && o2.disabled) {
          return -1
        } else {
          return 0
        }
      })
    return sortOptions(
      [...options].map((option) => {
        if (option.value instanceof Array) {
          return { ...option, value: sortOptions(option.value) }
        } else {
          return option
        }
      })
    )
  }, [options])

const _Select = ({ className, options: unsortedOptions, label, value, onChange, searchable, error, isClearable = false, ...props }: BaseSelectProps, ref: ForwardedRef<HTMLInputElement>) => {
  const options = useSortedOptions(unsortedOptions)
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const filteredOptions = isSearching ? filterOptionsBySearch(options, searchValue.trim()) : options
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    if (!searchable) {
      return
    }

    const value = event.target.value
    setSearchValue(value)
    setIsSearching(true)
    setOpen(true)
  }

  // When the focus is lost while searching we want to select the dropdown option matching with the search if it exists
  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!isSearching) {
      return
    }

    const getOptionMatchingWithSearch = (options: SelectOptionProp[]): SelectOptionProp | undefined => {
      return options.find((o) => (o.value instanceof Array ? getOptionMatchingWithSearch(o.value) : o.label.toLowerCase() === searchValue.toLowerCase()))
    }

    let matchedOption = getOptionMatchingWithSearch(filteredOptions)
    if (onChange) {
      if (matchedOption) {
        // if the select option was a group, we need to pick the real sub-option that matched
        matchedOption = matchedOption.value instanceof Array ? getOptionMatchingWithSearch(matchedOption.value)! : matchedOption
        setSearchValue(matchedOption.label) // force the input to have the good label to avoid an inconsistent display
        onChange(matchedOption)
      } else {
        onChange({ label: searchValue, value: searchValue })
      }
    }
    if (props.onBlur) {
      props.onBlur(e)
    }
  }

  useEffect(() => {
    const option = getOptionValue(options, value)
    setSearchValue(option.label || `${value ?? ''}`)
  }, [options, value])

  useEffect(() => {
    if (!open) {
      setIsSearching(false)
    }
  }, [open])

  return (
    <SelectableOptionsPopover
      trigger={
        <SelectInput
          {...props}
          search={isSearching}
          isOpen={open}
          className={className}
          iconRight={'chevron_down'}
          onKeyDown={(event) => {
            if (event.code === 'Space' && !isSearching) {
              setOpen(!open)
            }
          }}
          label={label}
          value={searchValue}
          onChange={onSearch}
          ref={ref}
          error={error}
          onBlur={onBlur}
          isClearable={isClearable}
          onClear={() => {
            isClearable && onChange && onChange({ label: '', value: '' })
          }}
        />
      }
      open={open}
      setOpen={setOpen}
      onChange={onChange}
      options={filteredOptions}
    />
  )
}

const BaseSelect = styled(forwardRef<HTMLInputElement, BaseSelectProps>(_Select))``

type SelectProps<T extends FieldValues> = Omit<UseControllerProps<T>, 'defaultValue'> & Omit<BaseSelectProps, 'value' | 'ref'> & { error?: string }
const Select = <T extends FieldValues>({ control, onChange, onBlur, name, shouldUnregister, rules, searchDisabledValueErrorMessage, ...props }: SelectProps<T>) => {
  const newRules = { ...rules }
  if (props.searchable) {
    newRules.validate = {
      ...newRules.validate,
      searchExactMatch: (v: SelectOptionProp) => {
        return (
          props.options
            .flatMap((option) => (option.value instanceof Array ? option.value : option))
            .map((o) => o.value)
            .includes(v) || 'Unexpected value.'
        )
      },
      searchDisabled: (v: SelectOptionProp) => {
        return (
          props.options
            .flatMap((option) => (option.value instanceof Array ? option.value : option))
            .filter((o) => !o.disabled)
            .map((o) => o.value)
            .includes(v) ||
          (searchDisabledValueErrorMessage ?? 'Choose another value.')
        )
      },
    }
  }
  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={shouldUnregister}
      rules={newRules}
      render={({ field }) => (
        <BaseSelect
          {...props}
          ref={field.ref}
          value={field.value as string}
          onBlur={(e) => {
            field.onBlur()
            onBlur && onBlur(e)
          }}
          onChange={(option) => {
            field.onChange(option.value as string)
            onChange && onChange(option)
          }}
        />
      )}
    />
  )
}

export { Select, BaseSelect as _Select }
