import { ClientSideDelivery, DeliveryType } from '@pubstack/common/src/clientSideDelivery'
import { FunctionComponent, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDelivery, useGetDeployedFiles } from '~/api/admin-api.hook'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Skeleton } from '~/components/Skeleton'
import { useToast } from '~/components/Toast/useToasts'
import { PureAdminDelivery } from './PureAdminDelivery'

export const AdminDelivery: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams({})
  const [currentComponent, setCurrentComponent] = useState<string>('')
  const [currentConfig, setCurrentConfig] = useState<ClientSideDelivery>()
  const [history, setHistory] = useState<ClientSideDelivery[]>()
  const [scripts, setScripts] = useState<string[]>([])
  const { byId: deployedFilesById } = useGetDeployedFiles(null) // TODO nra tmu 2022-10-03 manage errorScripts
  const { byId: clientSideDeliveryById } = useDelivery(null) // TODO nra tmu 2022-09-30 manage errorConfig

  useEffect(() => {
    const component = searchParams.get('component')
    if (component) {
      setCurrentComponent(component)
      loadAll(component)
    }
  }, [])

  const toast = useToast()

  const loadScripts = async (scriptPrefix: string) => {
    if (deployedFilesById.loading) {
      deployedFilesById.abort()
    }
    try {
      const res = await deployedFilesById.get(scriptPrefix)
      setScripts(res)
    } catch (e) {
      toast.alert('An error occurred while fetching the scripts')
    }
  }

  const loadAll = (component?: string) => {
    loadScripts(component || currentComponent)
    loadLatestConfig(component || currentComponent)
  }

  const loadLatestConfig = async (componentName: string) => {
    if (clientSideDeliveryById.loading) {
      clientSideDeliveryById.abort()
    }
    try {
      const { currentConfig, allConfig } = await clientSideDeliveryById.get(componentName)
      setCurrentConfig(currentConfig)
      setHistory(allConfig.length ? allConfig : undefined)
    } catch (e) {
      toast.alert('An error occurred when fetching the latest configuration')
    }
  }

  const onComponentChange = (newComponent: SelectOptionProp) => {
    setSearchParams({ component: newComponent.value as string })
    setCurrentComponent(newComponent.value as string)
    loadScripts(newComponent.value as string)
    loadLatestConfig(newComponent.value as string)
  }

  const createConfig = async (mainScript: string, newScript: string, ratio = 0) => {
    try {
      const newConfig: Pick<ClientSideDelivery, 'mainName' | 'abTest' | 'component'> = {
        component: currentComponent as DeliveryType,
        mainName: mainScript,
        abTest: {
          newName: scripts.includes(newScript) ? newScript : '',
          ratio: newScript ? ratio : 0,
        },
      }
      if (clientSideDeliveryById.loading) {
        clientSideDeliveryById.abort()
      }
      await clientSideDeliveryById.post(newConfig)
      loadLatestConfig(currentComponent)
      toast.success('Configuration creation successful')
    } catch (e) {
      toast.alert('An error occurred when creating the newest configuration')
    }
  }

  return clientSideDeliveryById.loading || deployedFilesById.loading ? (
    <Skeleton bigger width={'100%'} />
  ) : (
    <PureAdminDelivery
      currentComponent={currentComponent}
      onComponentChange={onComponentChange}
      onRefreshClick={loadAll}
      currentConfig={currentConfig ?? undefined}
      mainScripts={scripts}
      newScripts={['', ...scripts]}
      createConfig={createConfig}
      history={history ?? undefined}
    />
  )
}
