import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, ReactNode, useRef } from 'react'
import { useHover, useOnClickOutside } from 'usehooks-ts'
import { Colors } from '~/assets/style/colors'
import { ElevationLevel } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import { BANNER_PORTAL_ID } from '~/components/Banner'
import { FLYOUT_PORTAL_ID } from '~/components/Flyout'
import { Message } from '~/components/Message'
import { ToastWrapper } from '~/components/Toast/ToastWrapper'
import { GlobalFlyoutWrapper } from '~/components/layout/GlobalFlyout'
import { ConfigInformationBanner } from '~/types/config'
import { WithClassName } from '~/types/utils'
import { GlobalModalWrapper } from './GlobalModal'
import { PureSidebar } from './PureSidebar'

const StyledSidebar = styled(PureSidebar)`
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  ${ElevationLevel.semiHigh};
`

const MenuWrapper = styled.div`
  grid-area: menu;
  position: relative;
`

const SidebarTrigger = styled.div`
  --size: 15px;
  min-width: var(--size);
  width: var(--size);
  min-height: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  ${ElevationLevel.semiHigh};
`

const Wrapper = styled.div<{ isSidebarOpen: boolean; isSidebarPinned: boolean }>`
  --menu: 196px;
  display: grid;
  grid-template-columns: var(--menu) 1fr;
  grid-template-rows: min-content min-content 1fr;
  grid-template-areas:
    'menu header'
    'menu messageBanner'
    'menu content';
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  position: relative;

  ${({ isSidebarOpen }) =>
    !isSidebarOpen &&
    css`
      --menu: 0px;
    `};

  ${({ isSidebarPinned }) =>
    !isSidebarPinned &&
    css`
      --menu: 0px;

      ${StyledSidebar} {
        top: 65px;
        height: calc(100% - 77px);
      }
    `};
`

const HeaderWrapper = styled.div`
  grid-area: header;
`

const ContentWrapper = styled.div`
  grid-area: content;
  background: ${Colors.Ghost};
  overflow: hidden;
  min-width: 1033px; // TODO 2022-06-07 cfo - UGLY ! It's 750px min width of content + min width 283px of filter sidebar ... To change when we work on mobile version
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: start;
`

const Content = styled.div`
  ${ScrollbarStyle};
  overflow: auto;
  flex-grow: 1;
`

const MessageBanner = styled(Message)`
  grid-area: messageBanner;
`

type PureLayoutProps = WithClassName & {
  isSidebarOpen: boolean
  onCloseSidebar: () => void
  isSidebarPinned: boolean
  onPinSidebar: () => void
  message?: ConfigInformationBanner
  menu: ReactNode
  content: ReactNode
  header: ReactNode
}
const _PureLayout: FunctionComponent<PureLayoutProps> = ({ className, header, menu, content, message, isSidebarOpen, onCloseSidebar, isSidebarPinned, onPinSidebar }) => {
  const sidebarRef = useRef<HTMLElement>(null)
  const menuWrapperRef = useRef<HTMLDivElement>(null)
  const isMenuHover = useHover(menuWrapperRef)

  useOnClickOutside(sidebarRef, (event) => {
    if ((event.target as HTMLElement)?.attributes.getNamedItem('name')?.value === 'HEADER_MENU_TOGGLE') {
      return
    }
    if (isSidebarOpen && !isSidebarPinned) {
      onCloseSidebar()
    }
  })
  return (
    <Wrapper className={className} isSidebarOpen={isSidebarOpen || isMenuHover} isSidebarPinned={isSidebarPinned}>
      <HeaderWrapper>{header}</HeaderWrapper>
      <MenuWrapper ref={menuWrapperRef}>
        <SidebarTrigger />
        <StyledSidebar isOpen={isSidebarOpen || isMenuHover} onOpen={onCloseSidebar} isPinned={isSidebarPinned} onPin={onPinSidebar} ref={sidebarRef}>
          {menu}
        </StyledSidebar>
      </MenuWrapper>
      {message && <MessageBanner status={message.level} title={message.title} description={message.description} />}
      <ContentWrapper id={FLYOUT_PORTAL_ID}>
        <ToastWrapper />
        <GlobalModalWrapper>
          <GlobalFlyoutWrapper>
            <Content id="PureLayoutContent">{content}</Content>
          </GlobalFlyoutWrapper>
        </GlobalModalWrapper>
        <div id={BANNER_PORTAL_ID} />
      </ContentWrapper>
    </Wrapper>
  )
}
export const PureLayout = styled(_PureLayout)``
