import { useCallback, useEffect, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { useAlerts as useAlertsApi } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'

export type AcknowledgeAlerts = {
  [key: string]: AcknowledgeAlert[]
}

export type AcknowledgeAlert = {
  time: number
  tagId?: string
  bidder?: string
  adUnit?: string
  acknowledge: boolean
}

const isAcknowledge = (alerts: AcknowledgeAlert[], rawAlert: AcknowledgeAlert): boolean => {
  const compare = (alert: AcknowledgeAlert, rawAlert: AcknowledgeAlert): boolean =>
    alert.time === rawAlert.time && alert.adUnit === rawAlert.adUnit && alert.tagId === rawAlert.tagId && alert.bidder === rawAlert.bidder
  const found = alerts.find((alert) => compare(alert, rawAlert))
  return !!found && found.acknowledge
}

export const useAlerts = () => {
  const user = useUser()
  const { all } = useAlertsApi(null)
  const [alerts, setAlerts] = useLocalStorage<AcknowledgeAlerts>('acknowledgeAlerts', {})

  const loadAlerts = async () => {
    if (!user) return

    const result = await all.post()

    setAlerts((alerts) => ({
      ...alerts,
      [user.currentScopeId]: result
        .map((value) => ({
          acknowledge: false,
          time: value.time,
          tagId: value.keys.tagId?.value,
          bidder: value.keys.bidder?.value,
          adUnit: value.keys.adUnit?.value,
        }))
        .map((value) => ({
          ...value,
          acknowledge: isAcknowledge(alerts[user.currentScopeId] || [], value),
        })),
    }))
  }

  useEffect(() => {
    loadAlerts()
  }, [])

  return useMemo(() => alerts, [alerts])
}

export const useAcknowledgeAlerts = () => {
  const user = useUser()
  const [, setAlerts] = useLocalStorage<AcknowledgeAlerts>('acknowledgeAlerts', {})
  return useCallback(() => {
    if (!user) return

    setAlerts((alerts) => ({
      ...alerts,
      [user.currentScopeId]: (alerts[user.currentScopeId] || []).map((alert) => ({ ...alert, acknowledge: true })),
    }))
  }, [])
}
