import { withAuthenticationRequired } from '@auth0/auth0-react'
import { AdUnit } from '@pubstack/common/src/adunit'
import { RefreshGroup } from '@pubstack/common/src/refresh'
import { UserRole, canAccess, getScopeRole, hasFeature } from '@pubstack/common/src/rolesAndFeatures'
import { AdminScope, ScopeId } from '@pubstack/common/src/scope'
import { Site } from '@pubstack/common/src/tag'
import { ComponentType, FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { NavigateProps, Outlet, RouteMatch, RouteObject, matchRoutes, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import ProtectedRoute, { PubstackRouteMeta } from '~/auth/ProtectedRoute'
import { useUser } from '~/auth/user.hooks'
import Layout from '~/components/layout/Layout'
import { AdminScopeDetail } from '~/modules/admin/AdminScopeDetail'
import { AdminScopePage } from '~/modules/admin/AdminScopePage'
import AdmQaReportAnalysisPage from '~/modules/admin/adm-qa/AdmQaReportAnalysisPage'
import AdmQAReportsPage from '~/modules/admin/adm-qa/AdmQaReportsPage'
import { AdminScopeApiKeys } from '~/modules/admin/api/AdminScopeApiKeys'
import { AdminDelivery } from '~/modules/admin/delivery/AdminDelivery'
import { AdminScopeFeatures } from '~/modules/admin/features/AdminScopeFeatures'
import { AdminScopeAdStackConfig } from '~/modules/admin/features/adstack/AdminScopeAdStackConfig'
import { AdminScopePocOverridesConfig } from '~/modules/admin/features/adstack/poc-overrides/AdminScopePocOverridesConfig'
import { GamManager } from '~/modules/admin/gam-manager/GamManager'
import { AdminScopeSites } from '~/modules/admin/sites/AdminScopeSites'
import { AdminSnoozeAlarm } from '~/modules/admin/snooze-alarm/AdminSnoozeAlarm'
import { AdStackAdUnitEditPage } from '~/modules/adstack/inventory/adunits/AdStackAdUnitEditPage'
import { AdStackAdUnitsPage } from '~/modules/adstack/inventory/adunits/AdStackAdUnitsPage'
import { AdStackIntegrationPage } from '~/modules/adstack/inventory/bidderParams/AdStackIntegrationPage'
import { AdStackCatalogPage } from '~/modules/adstack/inventory/catalog/AdStackCatalogPage'
import { AdStackCatalogTabs } from '~/modules/adstack/inventory/catalog/AdStackCatalogTabs'
import { AdStackGamIntegrationPage } from '~/modules/adstack/inventory/catalog/adServers/AdStackGamIntegrationPage'
import { AdStackPrebidIntegrationPage } from '~/modules/adstack/inventory/catalog/headerBidders/AdStackPrebidIntegrationPage'
import { AdStackTamIntegrationPage } from '~/modules/adstack/inventory/catalog/wrapper/AdStackTamIntegrationPage'
import { AdStackContextEditPage } from '~/modules/adstack/inventory/context/AdStackContextEditPage'
import { AdStackContextTabs } from '~/modules/adstack/inventory/context/AdStackContextTabs'
import { AdStackContextsPage } from '~/modules/adstack/inventory/context/AdStackContextsPage'
import { AdStackContextKeyEditPage } from '~/modules/adstack/inventory/contextKeys/AdStackContextKeyEditPage'
import { AdStackContextKeysPage } from '~/modules/adstack/inventory/contextKeys/AdStackContextKeysPage'
import { AdStackControlCenterPage } from '~/modules/adstack/inventory/controlCenter/AdStackControlCenterPage'
import { AdStackIdentityModulePage } from '~/modules/adstack/inventory/modules/identityModule/AdStackIdentityModulePage'
import { AdStackRealTimeDataModulePage } from '~/modules/adstack/inventory/modules/realTimeDataModule/AdStackRealTimeDataModulePage'
import { GamAndWrappers } from '~/modules/adstack/inventory/sites/GamAndWrappers'
import { ModulesBySite } from '~/modules/adstack/inventory/sites/ModulesBySite'
import { SiteConnectToPubstack } from '~/modules/adstack/inventory/sites/SiteConnectToPubstack'
import { SiteMapping } from '~/modules/adstack/inventory/sites/SiteMapping'
import { SiteOverview } from '~/modules/adstack/inventory/sites/SiteOverview'
import { SitePage } from '~/modules/adstack/inventory/sites/SitePage'
import { SiteStacks } from '~/modules/adstack/inventory/sites/SiteStacks'
import { SitesPage } from '~/modules/adstack/inventory/sites/SitesPage'
import { SiteExtraScript } from '~/modules/adstack/inventory/sites/extra-scripts/SiteExtraScript'
import { StackEdit } from '~/modules/adstack/inventory/sites/stack/StackEdit'
import { AdStackFloorPriceEditPage } from '~/modules/adstack/rules/floor-price/AdStackFloorPriceEditPage'
import { AdStackFloorPricePage } from '~/modules/adstack/rules/floor-price/AdStackFloorPricePage'
import { AdStackHeaderBiddingEditPage } from '~/modules/adstack/rules/header-bidding/AdStackHeaderBiddingEditPage'
import { AdStackHeaderBiddingPage } from '~/modules/adstack/rules/header-bidding/AdStackHeaderBiddingPage'
import { AdStackLazyLoadingEditPage } from '~/modules/adstack/rules/lazy-loading/AdStackLazyLoadingEditPage'
import { AdStackLazyLoadingPage } from '~/modules/adstack/rules/lazy-loading/AdStackLazyLoadingPage'
import { AdStackRefreshEditPage } from '~/modules/adstack/rules/refresh/AdStackRefreshEditPage'
import { AdStackRefreshGlobalSettingsPage } from '~/modules/adstack/rules/refresh/AdStackRefreshGlobalSettingsPage'
import { AdStackRefreshPage } from '~/modules/adstack/rules/refresh/AdStackRefreshPage'
import { AdStackRefreshRulesPage } from '~/modules/adstack/rules/refresh/AdStackRefreshRulesPage'
import { AlertsPage } from '~/modules/alerts/AlertsPage'
import { BiddersOverview } from '~/modules/analytics/bidders/BiddersOverview'
import { BidderDetailsPage } from '~/modules/analytics/bidders/details/BidderDetailsPage'
import { ExplorePage } from '~/modules/analytics/explore/ExplorePage'
import { GamOverviewPage } from '~/modules/analytics/gamOverview/GamOverviewPage'
import { OverviewPage } from '~/modules/analytics/overview/OverviewPage'
import { RefreshPage } from '~/modules/analytics/refresh/RefreshPage'
import { SiteDetailsPage } from '~/modules/analytics/sites/details/SiteDetailsPage'
import { SitesOverviewPage } from '~/modules/analytics/sites/overview/SitesOverviewPage'
import { UserSessionPage } from '~/modules/analytics/userSession/UserSessionPage'
import { ViewabilityPage } from '~/modules/analytics/viewability/ViewabilityPage'
import { IntegrationPage } from '~/modules/marketplace/IntegrationPage'
import { ReportsPage } from '~/modules/reports/ReportsPage'
import { SettingsHomePage } from '~/modules/settings/SettingsHomePage'
import { SettingsPage } from '~/modules/settings/SettingsPage'
import { SettingsAbTestPage } from '~/modules/settings/abTest/SettingsAbTestPage'
import { AddAbTestPage } from '~/modules/settings/abTest/add/AddAbTestPage'
import { ViewAbTestPage } from '~/modules/settings/abTest/view/ViewAbTestPage'
import { SettingsMembersPage } from '~/modules/settings/members/SettingsMembersPage'
import { SettingsProfilePage } from '~/modules/settings/profile/SettingsProfilePage'
import { SettingsRefreshExceptionsPage } from '~/modules/settings/refreshExceptions/SettingsRefreshExceptionsPage'
import { SettingsRefreshGroupAccordionPage } from '~/modules/settings/refreshGroups/RefreshGroupAccordion/SettingsRefreshGroupAccordionPage'
import { SettingsRefreshGroupsPage } from '~/modules/settings/refreshGroups/SettingsRefreshGroupsPage'
import { SettingsSecurityPage } from '~/modules/settings/security/SettingsSecurityPage'
import { SettingsSitesPage } from '~/modules/settings/sites/SettingsSitesPage'
import { currentScopeState } from '~/state'
import { GoToProductAction, useLogger } from './logger'
import { SETTINGS_NAV_CONFIG } from './settings'
import { removeTraillingSlash } from './string'

export const Redirect: FunctionComponent<NavigateProps> = ({ to, replace, state }) => {
  const navigate = useNavigate()
  const navigateRef = useRef(navigate)

  useEffect(() => {
    navigateRef.current = navigate
  }, [navigate])

  useEffect(() => {
    navigateRef.current(to, { replace, state })
  }, [to, replace, state])

  return null
}

const LayoutWithAuthentication = withAuthenticationRequired(Layout, {
  loginOptions: {
    appState: {
      target: window.location.pathname + window.location.search,
    },
  },
})

type RouteConfig = Pick<RouteObject, 'path' | 'index' | 'caseSensitive'> & {
  component: ComponentType
  children?: RouteConfig[]
  metaData: PubstackRouteMeta
}
const routeConfig: RouteConfig[] = [
  {
    path: '',
    component: () => (
      <LayoutWithAuthentication>
        <Outlet />
      </LayoutWithAuthentication>
    ),
    metaData: { accessRole: 'user', allowDemoRole: true },
    children: [
      {
        path: '/',
        component: () => <Redirect to={'/analytics/overview'} />,
        metaData: { accessRole: 'user', allowDemoRole: true },
      },
      {
        path: 'analytics',
        component: () => <Outlet />,
        metaData: { accessRole: 'user', allowDemoRole: true },
        children: [
          {
            path: '',
            component: () => <Redirect to={'/analytics/overview'} />,
            metaData: { accessRole: 'user', allowDemoRole: false },
          },
          {
            path: 'gam-overview-web',
            component: () => <GamOverviewPage inventoryType={'Web'} />,
            metaData: { accessRole: 'user', feature: 'gamOverviewWeb', allowDemoRole: true, breadcrumbLabel: 'Web overview' },
          },
          {
            path: 'gam-overview-amp',
            component: () => <GamOverviewPage inventoryType={'AMP'} />,
            metaData: { accessRole: 'user', feature: 'gamOverviewAmp', allowDemoRole: true, breadcrumbLabel: 'AMP overview' },
          },
          {
            path: 'gam-overview-app',
            component: () => <GamOverviewPage inventoryType={'App'} />,
            metaData: { accessRole: 'user', feature: 'gamOverviewApp', allowDemoRole: true, breadcrumbLabel: 'App overview' },
          },
          {
            path: 'overview',
            component: OverviewPage,
            metaData: { accessRole: 'user', allowDemoRole: true, allowAnalyticsDemoRequest: true, breadcrumbLabel: 'Overview' },
          },
          {
            path: 'bidders',
            component: () => <Outlet />,
            metaData: { accessRole: 'user', allowDemoRole: true, allowAnalyticsDemoRequest: true, breadcrumbLabel: 'Bidders' },
            children: [
              {
                path: '',
                component: BiddersOverview,
                metaData: { accessRole: 'user', allowDemoRole: true, allowAnalyticsDemoRequest: true },
              },
              {
                path: ':bidderName',
                component: BidderDetailsPage,
                metaData: {
                  accessRole: 'user',
                  allowDemoRole: true,

                  allowAnalyticsDemoRequest: true,
                  breadcrumbLabel: ({ search }) => {
                    const searchParams = JSON.parse(atob(search.get('mapping') ?? "{bidder: '', bidderName: ''}"))
                    const { bidderName } = searchParams
                    return bidderName
                  },
                },
              },
            ],
          },
          {
            path: 'sites',
            component: () => <Outlet />,
            metaData: { accessRole: 'user', allowDemoRole: true, allowAnalyticsDemoRequest: true, breadcrumbLabel: 'Sites' },
            children: [
              {
                path: '',
                component: SitesOverviewPage,
                metaData: { accessRole: 'user', allowDemoRole: true, allowAnalyticsDemoRequest: true },
              },
              {
                path: ':siteName',
                component: SiteDetailsPage,
                metaData: {
                  accessRole: 'user',
                  allowDemoRole: true,
                  allowAnalyticsDemoRequest: true,
                  breadcrumbLabel: ({ search }) => {
                    const searchParams = JSON.parse(atob(search.get('mapping') ?? "{site: '', siteName:''}"))
                    const { siteName } = searchParams
                    return siteName
                  },
                },
              },
            ],
          },
          {
            path: 'viewability',
            component: ViewabilityPage,
            metaData: { accessRole: 'user', allowDemoRole: true, feature: 'viewability', breadcrumbLabel: 'Viewability' },
          },
          {
            path: 'refresh',
            component: RefreshPage,
            metaData: { accessRole: 'user', allowDemoRole: true, feature: 'refresh', breadcrumbLabel: 'Ad refresh' },
          },
          {
            path: 'user-session',
            component: UserSessionPage,
            metaData: { accessRole: 'user', allowDemoRole: true, feature: 'standaloneUserSession', breadcrumbLabel: 'User sessions' },
          },
          {
            path: 'explore',
            component: ExplorePage,
            metaData: { accessRole: 'user', allowDemoRole: true, breadcrumbLabel: 'Explore', allowAnalyticsDemoRequest: true },
          },
        ],
      },
      {
        path: '/reports',
        component: ReportsPage,
        metaData: { accessRole: 'user', allowDemoRole: true, allowAnalyticsDemoRequest: true, breadcrumbLabel: 'Reports' },
      },
      {
        path: '/monitors/summary',
        component: AlertsPage,
        metaData: { accessRole: 'user', allowDemoRole: true, allowAnalyticsDemoRequest: true },
      },
      {
        path: '/admin/scopes',
        component: () => <Outlet />,
        metaData: { accessRole: 'pubstack', allowDemoRole: false, breadcrumbLabel: 'Admin' },
        children: [
          {
            path: '',
            component: AdminScopePage,
            metaData: { accessRole: 'pubstack', allowDemoRole: false },
          },
          {
            path: '/admin/scopes/:scopeId',
            component: AdminScopeDetail,
            metaData: {
              accessRole: 'pubstack',
              allowDemoRole: false,
              breadcrumbLabel: ({ additionalParams }) => additionalParams && additionalParams.name,
            } as PubstackRouteMeta<AdminScope>,
            children: [
              {
                path: '',
                component: () => <Redirect to={'sites'} />,
                metaData: { accessRole: 'pubstack', allowDemoRole: false },
              },
              {
                path: 'sites',
                component: AdminScopeSites,
                metaData: { accessRole: 'pubstack', allowDemoRole: false },
              },
              {
                path: 'features',
                component: AdminScopeFeatures,
                metaData: { accessRole: 'pubstack', allowDemoRole: false },
              },
              {
                path: 'api',
                component: AdminScopeApiKeys,
                metaData: { accessRole: 'pubstack', allowDemoRole: false },
              },
              {
                path: 'adstack-settings',
                component: AdminScopeAdStackConfig,
                metaData: { accessRole: 'pubstack', allowDemoRole: false },
              },
              {
                path: 'poc-overrides',
                component: AdminScopePocOverridesConfig,
                metaData: { accessRole: 'pubstack', allowDemoRole: false },
              },
              {
                path: 'qa',
                component: Outlet,
                metaData: { accessRole: 'pubstack', allowDemoRole: false },
                children: [
                  {
                    path: '',
                    component: AdmQAReportsPage,
                    metaData: { accessRole: 'pubstack', allowDemoRole: false },
                  },
                  {
                    path: 'analysis/:reportId',
                    component: AdmQaReportAnalysisPage,
                    metaData: { accessRole: 'pubstack', allowDemoRole: false },
                  },
                ],
              },
              {
                path: 'gam-manager',
                component: GamManager,
                metaData: { accessRole: 'pubstack', allowDemoRole: false },
              },
            ],
          },
        ],
      },
      {
        path: '/admin/delivery',
        component: AdminDelivery,
        metaData: { accessRole: 'pubstack', allowDemoRole: false },
      },
      {
        path: '/admin/snooze-alarm',
        component: AdminSnoozeAlarm,
        metaData: { accessRole: 'pubstack', allowDemoRole: false },
      },

      {
        path: '/settings',
        component: SettingsPage,
        metaData: { accessRole: 'user', allowDemoRole: false, breadcrumbLabel: 'Settings' },
        children: [
          {
            path: '',
            component: SettingsHomePage,
            metaData: { accessRole: 'user', allowDemoRole: false },
          },
          {
            path: 'members',
            component: SettingsMembersPage,
            metaData: { accessRole: 'user', allowDemoRole: false, breadcrumbLabel: SETTINGS_NAV_CONFIG.members.title },
          },
          {
            path: 'sites',
            component: SettingsSitesPage,
            metaData: { accessRole: 'user', allowDemoRole: false, breadcrumbLabel: SETTINGS_NAV_CONFIG.sites.title },
          },
          {
            path: 'profile',
            component: SettingsProfilePage,
            metaData: { accessRole: 'user', allowDemoRole: false, breadcrumbLabel: SETTINGS_NAV_CONFIG.profile.title },
          },
          {
            path: 'security',
            component: SettingsSecurityPage,
            metaData: { accessRole: 'owner', allowDemoRole: false, breadcrumbLabel: SETTINGS_NAV_CONFIG.security.title },
          },
          {
            path: 'refresh-groups',
            component: () => <Outlet />,
            metaData: { accessRole: 'user', allowDemoRole: false, breadcrumbLabel: SETTINGS_NAV_CONFIG.refreshGroups.title },
            children: [
              {
                path: '',
                component: SettingsRefreshGroupsPage,
                metaData: { accessRole: 'user', allowDemoRole: false },
              },
              {
                path: 'new',
                component: SettingsRefreshGroupAccordionPage,
                metaData: { accessRole: 'user', allowDemoRole: false, breadcrumbLabel: 'New refresh group' },
              },
              {
                path: 'edit/:id',
                component: SettingsRefreshGroupAccordionPage,
                metaData: {
                  accessRole: 'user',
                  allowDemoRole: false,

                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: RefreshGroup }) => additionalParams?.name ?? 'Edit refresh group',
                },
              },
            ],
          },
          {
            path: 'refresh-exceptions',
            component: SettingsRefreshExceptionsPage,
            metaData: {
              accessRole: 'user',
              allowDemoRole: false,
              breadcrumbLabel: 'Refresh exceptions',
              breadcrumbSubtitle: 'Manage exceptions that will be applied on all refresh groups',
            },
          },
          {
            path: 'ab-test',
            component: () => <Outlet />,
            metaData: {
              accessRole: 'user',
              allowDemoRole: false,
              feature: 'abtest',
              breadcrumbLabel: 'A/B test',
              breadcrumbSubtitle: 'Monitor your A/B tests',
            },
            children: [
              {
                path: '',
                component: SettingsAbTestPage,
                metaData: { accessRole: 'user', allowDemoRole: false, feature: 'abtest' },
              },
              {
                path: 'new',
                component: () => <Outlet />,
                metaData: { accessRole: 'user', allowDemoRole: false, feature: 'abtest', breadcrumbLabel: 'Setup test' },
                children: [
                  {
                    path: '',
                    component: AddAbTestPage,
                    metaData: { accessRole: 'user', allowDemoRole: false, feature: 'abtest' },
                  },
                  {
                    path: 'view/:id',
                    component: ViewAbTestPage,
                    metaData: { accessRole: 'user', allowDemoRole: false, feature: 'abtest', breadcrumbLabel: 'How to run the test' },
                  },
                ],
              },
              {
                path: 'view/:id',
                component: ViewAbTestPage,
                metaData: { accessRole: 'user', allowDemoRole: false, feature: 'abtest', breadcrumbLabel: 'How to run the test' },
              },
            ],
          },
        ],
      },
      {
        path: 'adstack',
        component: () => <Outlet />,
        metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
        children: [
          {
            path: 'adunits',
            component: Outlet,
            metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false, breadcrumbLabel: 'Ad units', breadcrumbSubtitle: 'Manage the ad units of your stack.' },
            children: [
              {
                path: '',
                component: AdStackAdUnitsPage,
                metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
              },
              {
                path: 'edit/:adUnitId',
                component: AdStackAdUnitEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: AdUnit }) => (additionalParams?.name ? `Edit ${additionalParams.name}` : 'Edit ad unit'),
                  breadcrumbSubtitle: undefined,
                },
              },
              {
                path: 'new',
                component: AdStackAdUnitEditPage,
                metaData: {
                  accessRole: 'user',
                  allowDemoRole: false,
                  breadcrumbLabel: 'New ad unit',
                  breadcrumbSubtitle: undefined,
                },
              },
            ],
          },
          {
            path: 'context',
            component: Outlet,
            metaData: {
              accessRole: 'user',
              feature: 'adstack',
              allowDemoRole: false,
              breadcrumbLabel: 'Contexts',
              breadcrumbSubtitle: 'Push the right ad environment to the right user on the right page',
            },
            children: [
              {
                path: '',
                component: AdStackContextTabs,
                metaData: { accessRole: 'user', allowDemoRole: false },
                children: [
                  {
                    path: '',
                    component: () => <Redirect to={'contexts'} />,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                  {
                    path: 'contexts',
                    component: AdStackContextsPage,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                  {
                    path: 'context-keys',
                    component: AdStackContextKeysPage,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                ],
              },
              {
                path: 'contexts/edit/:contextId',
                component: AdStackContextEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'Edit context',
                  breadcrumbSubtitle: undefined,
                },
              },
              {
                path: 'contexts/new',
                component: AdStackContextEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'New context',
                  breadcrumbSubtitle: undefined,
                },
              },
              {
                path: 'context-keys/edit/:contextKeyName',
                component: AdStackContextKeyEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'Edit context key',
                  breadcrumbSubtitle: undefined,
                },
              },
              {
                path: 'context-keys/new',
                component: AdStackContextKeyEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'New context key',
                  breadcrumbSubtitle: undefined,
                },
              },
            ],
          },
          {
            path: 'floor-price',
            component: Outlet,
            metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false, breadcrumbLabel: 'Floor price rules', breadcrumbSubtitle: 'Create and manage rules that will be used in stacks' },
            children: [
              {
                path: '',
                component: AdStackFloorPricePage,
                metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
              },
              {
                path: 'new',
                component: AdStackFloorPriceEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'New rule',
                },
              },
              {
                path: 'edit/:floorRuleId',
                component: AdStackFloorPriceEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'Edit rule',
                },
              },
            ],
          },
          {
            path: 'lazy-loading',
            component: Outlet,
            metaData: {
              accessRole: 'user',
              feature: 'adstack',
              allowDemoRole: false,
              breadcrumbLabel: 'Lazy loading rules',
              breadcrumbSubtitle: 'Create and manage rules that will be used in stacks',
            },
            children: [
              {
                path: '',
                component: AdStackLazyLoadingPage,
                metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
              },
              {
                path: 'new',
                component: AdStackLazyLoadingEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'New rule',
                },
              },
              {
                path: 'edit/:lazyLoadingRuleId',
                component: AdStackLazyLoadingEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'Edit rule',
                },
              },
            ],
          },
          {
            path: 'refresh',
            component: Outlet,
            metaData: {
              accessRole: 'user',
              feature: 'adstack',
              allowDemoRole: false,
              breadcrumbLabel: 'Refresh rules',
            },
            children: [
              {
                path: '',
                component: AdStackRefreshPage,
                metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                children: [
                  {
                    path: '',
                    component: () => <Redirect to={'rules'} />,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                  {
                    path: 'rules',
                    component: AdStackRefreshRulesPage,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                  {
                    path: 'global-settings',
                    component: AdStackRefreshGlobalSettingsPage,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                ],
              },
              {
                path: 'new',
                component: AdStackRefreshEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'New rule',
                },
              },
              {
                path: 'edit/:refreshRuleId',
                component: AdStackRefreshEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'Edit rule',
                },
              },
            ],
          },
          {
            path: 'header-bidding',
            component: Outlet,
            metaData: {
              accessRole: 'user',
              feature: 'adstack',
              allowDemoRole: false,
              breadcrumbLabel: 'Bidder rules',
              breadcrumbSubtitle: 'Create and manage rules that will be used in stacks',
            },
            children: [
              {
                path: '',
                component: AdStackHeaderBiddingPage,
                metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
              },
              {
                path: 'new',
                component: AdStackHeaderBiddingEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'New rule',
                },
              },
              {
                path: 'edit/:headerBiddingRuleId',
                component: AdStackHeaderBiddingEditPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: 'Edit rule',
                },
              },
            ],
          },
          {
            path: 'sites',
            component: Outlet,
            metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false, breadcrumbLabel: 'Sites & stacks', breadcrumbSubtitle: 'Manage the ad management of your sites' },
            children: [
              {
                path: '',
                component: SitesPage,
                metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
              },
              {
                path: ':siteId',
                component: Outlet,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: Site }) => `${additionalParams?.name}`,
                },
                children: [
                  {
                    path: '',
                    component: SitePage,
                    metaData: {
                      accessRole: 'user',
                      feature: 'adstack',
                      allowDemoRole: false,
                    },
                    children: [
                      {
                        path: '',
                        component: () => <Redirect to={'activation'} />,
                        metaData: { accessRole: 'user', allowDemoRole: false },
                      },
                      {
                        path: 'activation',
                        component: SiteOverview,
                        metaData: {
                          accessRole: 'user',
                          feature: 'adstack',
                          allowDemoRole: false,
                        },
                      },
                      {
                        path: 'stacks',
                        component: SiteStacks,
                        metaData: {
                          accessRole: 'user',
                          feature: 'adstack',
                          allowDemoRole: false,
                        },
                      },
                      {
                        path: 'mapping',
                        component: SiteMapping,
                        metaData: {
                          accessRole: 'user',
                          feature: 'adstack',
                          allowDemoRole: false,
                        },
                      },
                      {
                        path: 'gamwrappers',
                        component: GamAndWrappers,
                        metaData: {
                          accessRole: 'user',
                          feature: 'adstack',
                          allowDemoRole: false,
                        },
                      },
                      {
                        path: 'modulesBySite',
                        component: ModulesBySite,
                        metaData: {
                          accessRole: 'user',
                          feature: 'adstack',
                          allowDemoRole: false,
                        },
                      },
                      {
                        path: 'connect-to-pubstack',
                        component: SiteConnectToPubstack,
                        metaData: {
                          accessRole: 'user',
                          feature: 'adstack',
                          allowDemoRole: false,
                        },
                      },
                      {
                        path: 'extra-scripts',
                        component: SiteExtraScript,
                        metaData: {
                          accessRole: 'pubstack',
                          feature: 'adstack',
                          allowDemoRole: false,
                        },
                      },
                    ],
                  },
                  {
                    path: 'stacks/new',
                    component: StackEdit,
                    metaData: {
                      accessRole: 'user',
                      feature: 'adstack',
                      allowDemoRole: false,
                      breadcrumbLabel: 'New stack',
                    },
                  },
                  {
                    path: 'stacks/:stackId',
                    component: StackEdit,
                    metaData: {
                      accessRole: 'user',
                      feature: 'adstack',
                      allowDemoRole: false,
                      breadcrumbLabel: 'Edit stack',
                    },
                  },
                  {
                    path: 'adserver/gam',
                    component: AdStackGamIntegrationPage,
                    metaData: {
                      accessRole: 'user',
                      feature: 'adstack',
                      allowDemoRole: false,
                      breadcrumbLabel: 'Google Ad Manager (GAM)',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'integrations',
            component: Outlet,
            metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false, breadcrumbLabel: 'Integrations' },
            children: [
              {
                path: '',
                component: AdStackCatalogTabs,
                metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                children: [
                  {
                    path: '',
                    component: () => <Redirect to={'my-integrations'} />,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                  {
                    path: 'my-integrations',
                    component: AdStackCatalogPage,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                  {
                    path: 'all-integrations',
                    component: AdStackCatalogPage,
                    metaData: { accessRole: 'user', feature: 'adstack', allowDemoRole: false },
                  },
                ],
              },
              {
                path: 'bidder/:integrationId',
                component: AdStackIntegrationPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: { integrationName: string } }) => additionalParams?.integrationName ?? 'Integration',
                },
              },
              {
                path: 'wrapper/tam',
                component: AdStackTamIntegrationPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: { integrationName: string } }) => additionalParams?.integrationName ?? 'TAM',
                },
              },
              {
                path: 'adserver/gam',
                component: AdStackGamIntegrationPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: { integrationName: string } }) => additionalParams?.integrationName ?? 'Google Ad Manager (GAM)',
                },
              },
              {
                path: 'prebid',
                component: AdStackPrebidIntegrationPage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: { integrationName: string } }) => additionalParams?.integrationName ?? 'Prebid Wrapper',
                },
              },
              {
                path: 'identity/:integrationId',
                component: AdStackIdentityModulePage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: { integrationName: string } }) => additionalParams?.integrationName ?? 'Integration',
                },
              },
              {
                path: 'real-time-data/:moduleId',
                component: AdStackRealTimeDataModulePage,
                metaData: {
                  accessRole: 'user',
                  feature: 'adstack',
                  allowDemoRole: false,
                  breadcrumbLabel: ({ additionalParams }: { additionalParams?: { integrationName: string } }) => additionalParams?.integrationName ?? 'Integration',
                },
              },
            ],
          },
          {
            path: 'control-center',
            component: AdStackControlCenterPage,
            metaData: {
              accessRole: 'owner',
              feature: 'adstack',
              allowDemoRole: false,
              breadcrumbLabel: 'Control center',
            },
          },
        ],
      },
      {
        path: 'marketplace',
        component: () => <Outlet />,
        metaData: { accessRole: 'owner', feature: 'marketplace', allowDemoRole: false },
        children: [
          {
            path: 'integration',
            component: IntegrationPage,
            metaData: { allowDemoRole: false, accessRole: 'owner', feature: 'marketplace' },
          },
        ],
      },
      { path: '*', component: () => <Redirect to={'/analytics/overview'} />, metaData: { accessRole: 'user', allowDemoRole: false } },
    ],
  },
]

const build = (routesConfig: RouteConfig[]): RouteObject[] =>
  routesConfig.map(({ path, component, metaData, children, caseSensitive }) => ({
    path,
    caseSensitive,
    element: <ProtectedRoute component={component} metaData={metaData} />,
    children: children ? build(children) : undefined,
    handle: metaData,
  }))

const canAccessRoute = (match: RouteMatch, scopeId: ScopeId, features: string[], userRole: UserRole): boolean => {
  const element = match.route.element as ReactElement
  if (element.props) {
    const metaData = element.props.metaData as PubstackRouteMeta
    if ('allowDemoRole' in metaData && getScopeRole(scopeId, userRole) === 'demo' && !metaData.allowDemoRole) return false
    if ('accessRole' in metaData && !canAccess(scopeId, userRole, metaData.accessRole)) return false
    if ('feature' in metaData && metaData.feature) {
      // RefreshPage case: visible for both refresh features
      if (metaData.feature === 'refresh') return hasFeature(scopeId, features, 'refresh') || hasFeature(scopeId, features, 'admRefresh')
      if (!hasFeature(scopeId, features, metaData.feature)) return false
    }
    return true
  }
  return false
}

export const routes = build(routeConfig)

export const useCanAccessRoute = () => {
  const user = useUser()
  const currentScope = useRecoilValue(currentScopeState)

  return useCallback(
    (path: string): boolean => {
      const matches = matchRoutes(routes, path)
      return matches?.every((match) => canAccessRoute(match, currentScope.id, user?.features || [], user?.userRoles || {})) ?? false
    },
    [user, currentScope]
  )
}

const getRouteFromPathname = (pathname: string) => {
  const matches = matchRoutes(routes, pathname) || []
  return matches.length ? matches[matches.length - 1] : undefined
}

export const useCurrentRoute = () => {
  const location = useLocation()
  return getRouteFromPathname(location.pathname)
}

export const useLogRouteChange = () => {
  const location = useLocation()
  const [previousLocation, setPreviousLocation] = useState(location)
  const currentRoute = useCurrentRoute()

  const logger = useLogger()
  useEffect(() => {
    const loggerMessage: GoToProductAction = {
      action: 'click',
      toRoute: removeTraillingSlash(currentRoute?.pathname || ''),
      fromRoute: removeTraillingSlash(getRouteFromPathname(previousLocation.pathname)?.pathname || ''),
      type: 'navigation',
    }
    logger.info(loggerMessage)
    setPreviousLocation(location)
  }, [location])
}
