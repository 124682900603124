import { ReactElement, ReactNode } from 'react'
import { Colors, PaletteColor } from '~/assets/style/colors'
import { IconName } from '~/components/Icon'

export type ToastOptions = { id?: string; duration?: number; icon?: IconName; iconFillColor?: PaletteColor[keyof PaletteColor]; type?: ToastType }
export type ToastHandlerMessage = ReactElement | string | { title: ReactNode; body: ReactNode }

export type ToastType = 'success' | 'alert' | 'neutral' | 'warning'

export const TOAST_TYPE_COLOR: { [key in ToastType]: { color: PaletteColor[keyof PaletteColor]; background: PaletteColor[keyof PaletteColor] } } = {
  success: {
    color: Colors.Success,
    background: Colors.Mint,
  },
  alert: {
    color: Colors.Alert,
    background: Colors.Mew,
  },
  neutral: {
    color: Colors.SlateGrey,
    background: Colors.White,
  },
  warning: {
    color: Colors.Warning,
    background: Colors.White,
  },
}
