import { cx } from '@emotion/css'
import styled from '@emotion/styled'
import { BidParam, BidderCatalogAlias, BidderParam, BidderParamValue } from '@pubstack/common/src/bidderCatalog'
import { BidderParamValidationType } from '@pubstack/common/src/bidderParamValidation'
import { ComponentProps, FunctionComponent, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { CellComponent, Matrix, Spreadsheet } from 'react-spreadsheet'
import { useDebounceCallback } from 'usehooks-ts'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { StatusState } from '~/components/Status'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

const Container = styled.div`
  Table {
    width: 100%;
    box-sizing: border-box;

    tr {
      th:first-of-type,
      td:first-of-type {
        width: 5ch;
      }
    }
  }
`

const Filters = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;

  > * {
    width: 100%;
  }
  margin-top: 12px;
  margin-bottom: 12px;
`

const SpreadsheetContainer = styled.div`
  max-height: 450px;
  width: 100%;
`
const NoDataSpreadsheet = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  ${Fonts.P0}
  height: 440px;
`

export const RowIndicator = styled.th<{ hasError?: boolean; selected: boolean }>`
  line-height: 20px;
  font-size: 0.875rem;
  background-color: ${({ selected, hasError }) => hasError && (selected ? Colors.Ash : Colors.Mew)};
  color: ${({ selected, hasError }) => hasError && (selected ? Colors.Mew : Colors.Alert)};
`

const getOffsetRect = (el: HTMLElement) => {
  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
    left: el.offsetLeft,
    top: el.offsetTop,
  }
}

const CellContainer = styled.td<{ hasError?: boolean }>`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 0 4px;
    width: 100%;
    height: 100%;
    line-height: 20px;
    font-size: 0.875rem;
    
  }
  ${({ hasError }) => hasError && `background-color: ${Colors.Mew};`};
  --readonly-text-color: ${Colors.Ash};
`

type CellProps = {
  value: string | number | boolean | BidderParamValue | unknown[]
  readOnly: boolean
  rowId: string
}
const Cell: FunctionComponent<ComponentProps<CellComponent<CellProps>> & { validation?: SpreadsheetSiteValidation; bidParams: BidParam[]; fixedHeaders: CellHeader[] }> = ({
  column,
  row,
  selected,
  DataViewer: Dv,
  activate,
  active,
  data,
  dragging,
  evaluatedData,
  mode,
  select,
  setCellData,
  setCellDimensions,
  validation: currentSiteValidation,
  bidParams,
  fixedHeaders,
}) => {
  const rootRef = useRef<HTMLTableCellElement | null>(null)
  const point = useMemo(
    (): { row: number; column: number } => ({
      row,
      column,
    }),
    [row, column]
  )
  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLTableCellElement>) => {
      if (mode === 'view') {
        setCellDimensions(point, getOffsetRect(event.currentTarget))

        if (event.shiftKey) {
          select(point)
        } else {
          activate(point)
        }
      }
    },
    [mode, setCellDimensions, point, select, activate]
  )

  const handleMouseOver = useCallback(
    (event: React.MouseEvent<HTMLTableCellElement>) => {
      if (dragging) {
        setCellDimensions(point, getOffsetRect(event.currentTarget))
        select(point)
      }
    },
    [setCellDimensions, select, dragging, point]
  )

  const errorMessage: string | undefined = useMemo(() => {
    if (currentSiteValidation) {
      // get the validation entries for the current cell if any
      const validationEntries = currentSiteValidation?.entries?.filter((v) => getRowId(v) === (evaluatedData?.rowId ?? ''))
      const cellInError = validationEntries?.some((v) => v.param.name === bidParams[column - fixedHeaders.length]?.name)
      const errorMessage = cellInError ? generateTooltipErrorMessageContent(validationEntries?.[0]?.errorType, bidParams[column - fixedHeaders.length]) : undefined
      return errorMessage
    }
  }, [data, currentSiteValidation])

  useEffect(() => {
    const root = rootRef.current
    if (selected && root) {
      setCellDimensions(point, getOffsetRect(root))
    }
    if (root && active && mode === 'view') {
      root.focus()
    }
  }, [setCellDimensions, selected, active, mode, point, data])

  return (
    <CellContainer
      hasError={!!errorMessage}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      tabIndex={0}
      className={cx('Spreadsheet__cell', data?.readOnly && 'Spreadsheet__cell--readonly')}
    >
      <div>
        <Dv row={row} column={column} cell={data} evaluatedCell={evaluatedData} setCellData={setCellData} />
        {errorMessage && (
          <Tooltip reposition title={errorMessage}>
            <span>{<Icon width={'16px'} fill={Colors.Alert} name={'warning'} />}</span>
          </Tooltip>
        )}
      </div>
    </CellContainer>
  )
}

const ValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 5px;
`

const ValidationMessageContainer = styled.div`
  display: inline-flex;
  align-items: center;
  ${Fonts.colors.Alert}
  ${BorderRadius.style};
`

const SpreadSheetActions = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 24px;
  justify-content: flex-end;
`

export const Header = styled.th<{ fixedWidth?: number }>`
  line-height: 20px;
  font-size: 0.875rem;
  ${({ fixedWidth }) => fixedWidth && `width: ${fixedWidth}px;`}
`

const ValidationMessage: FunctionComponent<WithClassName & { children: ReactNode }> = ({ className, children }) => {
  return (
    <ValidationMessageContainer className={className}>
      <Icon name={'warning'} height={'16px'} fill={Colors.Alert} />
      <span>{children}</span>
    </ValidationMessageContainer>
  )
}

export type SpreadsheetValidationEntry = {
  device: 'desktop' | 'mobile'
  siteName: string
  adUnitName: string
  bidderName: string
  bidderParamIndex: number
  param: BidParam
  errorType: BidderParamValidationType.MISSING_PARAMS | BidderParamValidationType.WRONG_TYPE
}
export type SpreadsheetSiteValidation = { siteName: string; entries: SpreadsheetValidationEntry[] }

export type CellHeader = {
  name: string
  width: number
}

/**
 * Get a unique row comp id for a bidder param consisting of siteName, device and adUnitName concatenated together
 */
const getRowId = (param: Pick<BidderParam, 'adUnitName' | 'siteName' | 'device'>) => `${param.siteName}-${param.device}-${param.adUnitName}`

/**
 * Return the error message content based on the error type for every line
 */
const generateErrorMessageContent = (errorType: BidderParamValidationType, param: BidParam, rowIndexes: number[]) => {
  const adjustedRowIndexes = rowIndexes.map((index) => index + 1)
  switch (errorType) {
    case BidderParamValidationType.MISSING_PARAMS:
      return `Missing required parameter ${param.name} on the following rows : ${adjustedRowIndexes.join(', ')}`
    case BidderParamValidationType.WRONG_TYPE:
      return `${param.name} is expected to be of ${param.type} type on lines : ${adjustedRowIndexes.join(', ')}`
  }
}
/**
 * Return the error message content based on the error type for every line
 */
const generateTooltipErrorMessageContent = (errorType: BidderParamValidationType, param: BidParam) => {
  switch (errorType) {
    case BidderParamValidationType.MISSING_PARAMS:
      return `Missing required parameter ${param.name}`
    case BidderParamValidationType.WRONG_TYPE:
      return `${param.name} is expected to be of ${param.type} type`
  }
}

type SpreadsheetValidationDisplayProps = {
  getRowIndex: (entry: SpreadsheetValidationEntry) => number
  validation: SpreadsheetSiteValidation[]
  siteName: string
  params: BidParam[]
}
const SpreadsheetValidationDisplay: FunctionComponent<SpreadsheetValidationDisplayProps> = ({ getRowIndex, validation, siteName, params }) => {
  return (
    <ValidationContainer>
      {validation
        // only show details of errors for current site
        .filter((v) => v.siteName === siteName)
        .map((v) =>
          params
            // group by param
            .filter((param) => v.entries.some((v) => v.param.name === param.name))
            // and show error on all lines for each param
            .map((param, i) => (
              <ValidationMessage key={i}>
                {generateErrorMessageContent(v.entries.find((v) => v.param.name === param.name)!.errorType, param, v.entries.filter((v) => v.param.name === param.name).map(getRowIndex))}
              </ValidationMessage>
            ))
        )}

      {validation
        .filter((v) => v.siteName !== siteName)
        .map((v, i) => (
          <ValidationMessage key={i}>Errors found on site {v.siteName}</ValidationMessage>
        ))}
    </ValidationContainer>
  )
}

type PureAdStackIntegrationSpreadsheetProps = WithClassName & {
  alias: Pick<BidderCatalogAlias, 'bidderParams' | 'bidParams'>
  validateBidderParams: (bidderParamsValue: BidderParam[]) => SpreadsheetSiteValidation[]
  onBidderParamsUpdate: (bidderParamsValue: BidderParam[]) => void
}
const _PureAdStackIntegrationSpreadsheet: FunctionComponent<PureAdStackIntegrationSpreadsheetProps> = ({ className, alias, validateBidderParams, onBidderParamsUpdate }) => {
  const [validation, setValidation] = useState<SpreadsheetSiteValidation[]>([])
  const [currentBidderParams, setCurrentBidderParams] = useState<BidderParam[]>([...alias.bidderParams])

  useEffect(() => {
    setCurrentBidderParams([...alias.bidderParams])
  }, [alias])

  const getSiteState = (siteName: string): { status: StatusState; adUnitsMappedCount: number } => {
    const adUnitsMappedCount = currentBidderParams.filter((bP) => bP.siteName === siteName && Object.keys(bP.params).length > 0).length
    const hasError = validation.some((v) => v.siteName === siteName)
    const status = adUnitsMappedCount === 0 ? 'inactive' : hasError ? 'error' : 'active'
    return { status, adUnitsMappedCount }
  }

  const siteFilterOptions = currentBidderParams.reduce((acc, param) => {
    if (!acc.find((option) => option.value === param.siteName)) {
      const state = getSiteState(param.siteName)
      acc.push({
        value: param.siteName,
        label: param.siteName,
        status: state.status,
        sublabel: state.status !== 'inactive' ? `${state.adUnitsMappedCount} ad unit${state.adUnitsMappedCount > 1 ? 's' : ''} mapped` : undefined,
      })
    }
    return acc
  }, [] as SelectOptionProp<string>[])

  const defaultSite = siteFilterOptions?.length === 1 ? siteFilterOptions[0].value : ''

  const { control, resetField } = useForm({
    defaultValues: {
      site: defaultSite,
      device: '',
      adUnit: '',
    },
  })

  const deviceFilterOptions = [
    { value: '', label: 'Any' },
    { value: 'desktop', label: 'Desktop' },
    { value: 'mobile', label: 'Mobile' },
  ]

  const currentSite = useWatch({ control, name: 'site' })
  const currentDevice = useWatch({ control, name: 'device' })
  const adUnitSearch = useWatch({ control, name: 'adUnit' })

  const currentSiteValidation = validation.find((v) => v.siteName === currentSite)

  const fixedHeaders: CellHeader[] = [
    {
      name: 'Device',
      width: 100,
    },
    {
      name: 'Ad unit name',
      width: 260,
    },
  ]
  const bidParamsHeaders = alias.bidParams.map((param) => param.name)
  const headers = [...fixedHeaders.map((f) => f.name), ...(bidParamsHeaders.length ? bidParamsHeaders : [])]

  const bidderParamToSpreadsheetRow = (bidderParam: BidderParam) => {
    return [
      { value: bidderParam.device, readOnly: true, rowId: getRowId(bidderParam) },
      { value: bidderParam.adUnitName, readOnly: true, rowId: getRowId(bidderParam) },
      ...alias.bidParams.map((param) => ({ value: bidderParam.params[param.name], readOnly: false, rowId: getRowId(bidderParam) })),
    ]
  }

  const filterBySiteAndDevice = (bidderParam: BidderParam) => {
    return bidderParam.siteName === currentSite && (currentDevice === bidderParam.device || !currentDevice) && bidderParam.adUnitName.toLocaleLowerCase().includes(adUnitSearch.toLocaleLowerCase())
  }

  const spreadsheetData = currentBidderParams.filter(filterBySiteAndDevice).map(bidderParamToSpreadsheetRow)
  /**
   * Use unique rowId to get a specific row in our spreadsheet data
   */
  const getRowIndex = (rowId: string) => {
    return spreadsheetData.findIndex((row) => row[0]?.rowId === rowId)
  }

  const spreadsheetDataToBidderParams = (data: Matrix<BidderParamValue>) => {
    const newBidderParams = [...currentBidderParams]
    // We need to update the bidderParams with the new values
    data.forEach((row, index) => {
      const rowIndex = currentBidderParams.findIndex((bidderParam) => row[0]?.rowId === getRowId(bidderParam))
      const newParams = Object.fromEntries(
        headers.map((header, hIndex) => (fixedHeaders.some((f) => f.name === header) ? [] : [header, data[index][hIndex]?.value ?? ''])).filter((entry) => entry.length && !!entry[1])
      )
      if (rowIndex >= 0) {
        newBidderParams.splice(rowIndex, 1, {
          ...currentBidderParams[rowIndex],
          params: newParams,
        })
      }
    })
    setValidation(validateBidderParams(newBidderParams))
    setCurrentBidderParams(newBidderParams)
  }

  // Debounce to avoid unnecessary rendering bogging down our UI
  const debouncedOnChange = useDebounceCallback(spreadsheetDataToBidderParams, 100, {})

  return (
    <Container className={className}>
      <Filters>
        {siteFilterOptions.length > 1 ? (
          <>
            <Select label={'Site'} control={control} name={'site'} options={siteFilterOptions} searchable />
            <Select label={'Device'} control={control} name={'device'} options={deviceFilterOptions} />
            <Input iconLeft={'search'} label={'Ad unit'} control={control} name={'adUnit'} />
          </>
        ) : (
          <>
            <Input iconLeft={'search'} label={'Ad unit'} control={control} name={'adUnit'} />
            <Select label={'Device'} control={control} name={'device'} options={deviceFilterOptions} />
          </>
        )}
      </Filters>
      <SpreadsheetContainer>
        {spreadsheetData.length ? (
          // TODO tmu sle 2024-03-05 - we need to have a proper spreadsheet component handling all this nonsense in a pure and structured way. Don't have time right now
          <Spreadsheet
            ColumnIndicator={({ column, onSelect, selected, label }) => {
              const isRequired = alias.bidParams[column - fixedHeaders.length]?.required
              return (
                <Header
                  onClick={(event) => onSelect(column, event.shiftKey)}
                  className={cx(['Spreadsheet__header', selected && 'Spreadsheet__header--selected'])}
                  fixedWidth={fixedHeaders[column]?.width}
                >
                  {label} {isRequired && '*'}
                </Header>
              )
            }}
            Cell={(props) => {
              return <Cell bidParams={alias.bidParams} fixedHeaders={fixedHeaders} validation={currentSiteValidation} {...props} />
            }}
            RowIndicator={({ onSelect, row, selected, label }) => {
              const hasError = currentSiteValidation?.entries?.some((v) => getRowIndex(getRowId(v)) === row)
              return (
                <RowIndicator
                  hasError={hasError}
                  selected={selected}
                  onClick={(event) => onSelect(row, event.shiftKey)}
                  className={cx(['Spreadsheet__header', selected && 'Spreadsheet__header--selected'])}
                >
                  {label ?? row}
                </RowIndicator>
              )
            }}
            css={{
              overflowY: 'auto',
              overflowX: 'auto',
              maxHeight: '440px',
              maxWidth: '100%',
            }}
            data={spreadsheetData}
            columnLabels={headers}
            onChange={(data) => {
              debouncedOnChange(data)
            }}
            // Needs to be set to an empty function to avoid performance issues
            // we don't use the formula parser in this context
            createFormulaParser={() => () => {}}
          />
        ) : (
          <NoDataSpreadsheet>
            <Icon name={'night_sky'} width={'144px'} />
            {currentSite ? <div>No data to display, please check your filters above. </div> : <div>No data to display, please select a site in the filters above first.</div>}
          </NoDataSpreadsheet>
        )}
      </SpreadsheetContainer>
      {validation?.length ? <SpreadsheetValidationDisplay getRowIndex={(v) => getRowIndex(getRowId(v))} validation={validation} siteName={currentSite} params={alias.bidParams} /> : undefined}
      <SpreadSheetActions>
        <Button disabled={!!validation?.length} onClick={() => onBidderParamsUpdate(currentBidderParams)}>
          Save mapping
        </Button>
      </SpreadSheetActions>
    </Container>
  )
}

export const PureAdStackIntegrationSpreadsheet = styled(_PureAdStackIntegrationSpreadsheet)``
