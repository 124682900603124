import { Auth0Provider } from '@auth0/auth0-react'
import { FC, PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import { configurationState } from '~/state'

const Auth0ProviderWithHistory: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const config = useRecoilValue(configurationState)

  return (
    <Auth0Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: 'openid email profile offline_access',
        audience: config.AUTH0_AUDIENCE,
      }}
      cacheLocation={'localstorage'}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizeTimeoutInSeconds={5}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
