import styled from '@emotion/styled'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent } from 'react'
import { asShort } from '~/modules/analytics/formulas/operation'
import { WithClassName } from '~/types/utils'
import { displayWithCurrency } from '~/utils/string'

const Uplift = styled.span`
  font-weight: bold;
`

type RefreshUpliftMessageProps = WithClassName & {
  uplift?: string
  value: number
  percentage: number
  currencySymbol: CurrencySymbol
}

const _RefreshUpliftMessage: FunctionComponent<RefreshUpliftMessageProps> = ({ value, currencySymbol, percentage, className }) => (
  <span className={className}>
    Ad refresh gave you{' '}
    <Uplift>
      +{displayWithCurrency(asShort(value), currencySymbol)} in revenue (+{percentage}%)
    </Uplift>
    .
  </span>
)
export const RefreshUpliftMessage = styled(_RefreshUpliftMessage)``
