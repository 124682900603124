import { AdFormat, MOBILE_INTERSTITIAL, Mediatype, OUT_OF_PAGE } from './adFormat'
import { AdUnitDevice } from './adunitDevice'
import { ScopeId } from './scope'

export type AnchorFeature = {
  /** to handle forms an undefined mode means it's disabled  */
  mode: 'useExistingDiv' | 'createNewDiv' | undefined
}

export type AdUnitFeature = {
  dynamicEnabled: boolean
  headerBiddingEnabled: boolean
  parallaxEnabled?: boolean
  skinEnabled?: boolean
  anchor?: AnchorFeature
  canOutstream: boolean
}

export type ContainerMaxSize = {
  width?: number
  height?: number
}

export type AdUnit = {
  id: string
  adUnitPath?: string
  creationTime: number
  scopeId: ScopeId
  enabled: boolean
  name: string
  adServerAdUnitName: string
  divId: string
  sizes: string[]
  customSizes: string[]
  fluid: boolean
  slotKeyValues?: Array<{ key: string; values: string[] }>
  devices: AdUnitDevice[]
  biddersOutstream: string[]
  adFormat?: AdFormat
  containerMaxSize?: ContainerMaxSize
} & AdUnitFeature

export type DisplayedAdUnit = Pick<
  AdUnit,
  | 'id'
  | 'name'
  | 'adServerAdUnitName'
  | 'divId'
  | 'enabled'
  | 'headerBiddingEnabled'
  | 'canOutstream'
  | 'fluid'
  | 'sizes'
  | 'customSizes'
  | 'dynamicEnabled'
  | 'slotKeyValues'
  | 'devices'
  | 'adFormat'
  | 'containerMaxSize'
  | 'skinEnabled'
  | 'parallaxEnabled'
  | 'anchor'
>

export const VALIDATE_ADUNIT_SIZE_REGEX = /^\d+[x]\d+$/

export const isDeviceEnabled = (device: AdUnitDevice, devices?: string[]): boolean => {
  return (devices || []).some((d) => d === device)
}

export const getAdUnitMediatypes = (adunit: AdUnit | DisplayedAdUnit): Mediatype[] => {
  const mediatypes: Mediatype[] = []
  if (adunit.fluid || adunit.sizes.length > 0 || adunit.customSizes.length > 0) {
    mediatypes.push('Banner')
  }
  if (adunit.canOutstream) {
    mediatypes.push('Outstream')
  }
  return mediatypes
}

export const isOutOfPage = (adFormatId: string): boolean => adFormatId === OUT_OF_PAGE.id || adFormatId === MOBILE_INTERSTITIAL.id
