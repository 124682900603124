import { Role } from '@pubstack/common/src/rolesAndFeatures'
import { ScopeUser } from '@pubstack/common/src/user'
import { useEffect, useState } from 'react'
import { useUserData } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { useToast } from '~/components/Toast/useToasts'
import { useLogger } from '~/utils/logger'
import { PureSettingsMembersPage } from './PureSettingsMembersPage'

export const USER_ROLE_OPTIONS: SelectOptionProp<Role>[] = [
  { label: 'User', value: 'user' },
  { label: 'Owner', value: 'owner' },
]

export const SettingsMembersPage = () => {
  const logger = useLogger()
  const user = useUser()
  const canEditOrDeleteMember = !!(user?.userRoles && (user.getScopeRole() === 'owner' || user?.isAdmin))
  const { all, byId } = useUserData(null)
  const [users, setUsers] = useState<ScopeUser[]>([])
  const toast = useToast()

  const getUsers = async () => {
    if (all.loading) {
      all.abort()
    }
    const res = await all.get()
    setUsers(res)
  }

  const addUser = async (user: ScopeUser) => {
    try {
      const createdUser = await byId.post(user)
      setUsers([...users, createdUser])
      toast.success(
        <>
          <strong>{createdUser.nickname}</strong> will receive an invitation to access Pubstack.
        </>
      )
      logger.info({ action: 'click', type: 'user-settings', detail: 'add', userEmail: createdUser.email })
    } catch (e) {
      toast.alert('An error occurred while trying to create this user.')
      logger.info({ message: `impossible to save a new user ${user.email}`, error: new Error((e as Error).message) })
    }
  }

  const deleteUser = async (user: ScopeUser) => {
    try {
      await byId.del(user)
      setUsers([...users.filter((u) => u.email !== user.email)])
      toast.success(
        <>
          <strong>{user.nickname}</strong>&#39;s access has been revoked.
        </>
      )
      logger.info({ action: 'click', type: 'user-settings', detail: 'delete', userEmail: user.email })
    } catch (e) {
      toast.alert("An error occurred while trying to revoke this user's access.")
      logger.info({ message: `impossible to delete user ${user.email}`, error: new Error((e as Error).message) })
    }
  }

  const updateUser = async (user: ScopeUser) => {
    try {
      const updatedUser = await byId.put(user)
      setUsers(users.map((u) => (u.email === user.email ? updatedUser : u)))
      toast.success(
        <>
          <strong>{updatedUser.nickname}</strong>&#39;s information has been updated.
        </>
      )
      logger.info({ action: 'click', type: 'user-settings', detail: 'edit-role', userEmail: updatedUser.email })
    } catch (e) {
      toast.alert('An error occurred while updating this user.')
      logger.info({ message: `impossible to update user ${user.email}`, error: new Error((e as Error).message) })
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <PureSettingsMembersPage
      isError={!!all.error}
      members={users}
      isLoading={all.loading || byId.loading}
      canEditOrDeleteMember={canEditOrDeleteMember}
      onAddMember={addUser}
      onDeleteMember={deleteUser}
      onEditMember={updateUser}
    />
  )
}
