import {
  ANCHOR,
  AdFormat,
  CUSTOM_AD_FORMAT,
  IN_CONTENT,
  IN_CONTENT_PARALLAX,
  LEADERBOARD,
  MOBILE_BANNER,
  MOBILE_INTERSTITIAL,
  OUTSTREAM_SINGLE_AD,
  OUT_OF_PAGE,
  RECTANGLE_LARGE,
  RECTANGLE_MEDIUM,
  SIDEBAR,
  SKIN_SINGLE_AD,
} from '@pubstack/common/src/adFormat'
import { ReactNode, useMemo } from 'react'
import { IconName } from '~/components/Icon'
import {
  ANCHOR_HELP_TEXT,
  CUSTOM_AD_FORMAT_HELP_TEXT,
  IN_CONTENT_HELP_TEXT,
  IN_CONTENT_PARALLAX_HELP_TEXT,
  LEADERBOARD_HELP_TEXT,
  MOBILE_BANNER_HELP_TEXT,
  MOBILE_INTERSTITIAL_HELP_TEXT,
  OUTSTREAM_SINGLE_AD_HELP_TEXT,
  OUT_OF_PAGE_HELP_TEXT,
  RECTANGLE_LARGE_HELP_TEXT,
  RECTANGLE_MEDIUM_HELP_TEXT,
  SIDEBAR_HELP_TEXT,
  SKIN_SINGLE_AD_HELP_TEXT,
} from '~/utils/adFormatConstants'

export type AdFormatDisplay = AdFormat & {
  iconName: IconName
  helpText: ReactNode
}

export const STANDARD_AD_FORMATS: AdFormatDisplay[] = [
  { ...LEADERBOARD, iconName: 'leaderboard', helpText: LEADERBOARD_HELP_TEXT },
  { ...SIDEBAR, iconName: 'sidebar', helpText: SIDEBAR_HELP_TEXT },
  { ...RECTANGLE_LARGE, iconName: 'rectangle_large', helpText: RECTANGLE_LARGE_HELP_TEXT },
  { ...RECTANGLE_MEDIUM, iconName: 'rectangle_medium', helpText: RECTANGLE_MEDIUM_HELP_TEXT },
  { ...OUTSTREAM_SINGLE_AD, iconName: 'outstream_single_ad', helpText: OUTSTREAM_SINGLE_AD_HELP_TEXT },
  { ...MOBILE_BANNER, iconName: 'mobile_leaderboard', helpText: MOBILE_BANNER_HELP_TEXT },
  { ...IN_CONTENT, iconName: 'in_content', helpText: IN_CONTENT_HELP_TEXT },
]
export const HIGH_IMPACT_AD_FORMATS: AdFormatDisplay[] = [
  { ...MOBILE_INTERSTITIAL, iconName: 'mobile_interstitial', helpText: MOBILE_INTERSTITIAL_HELP_TEXT },
  { ...OUT_OF_PAGE, iconName: 'out_of_page', helpText: OUT_OF_PAGE_HELP_TEXT },
  { ...IN_CONTENT_PARALLAX, iconName: 'in_content', helpText: IN_CONTENT_PARALLAX_HELP_TEXT },
  { ...SKIN_SINGLE_AD, iconName: 'skin_single_ad', helpText: SKIN_SINGLE_AD_HELP_TEXT },
  { ...ANCHOR, iconName: 'anchor_ad_format', helpText: ANCHOR_HELP_TEXT },
  { ...CUSTOM_AD_FORMAT, iconName: 'custom', helpText: CUSTOM_AD_FORMAT_HELP_TEXT },
]

export const AD_FORMATS_DISPLAY = [...STANDARD_AD_FORMATS, ...HIGH_IMPACT_AD_FORMATS]

export const useAdFormat = (id: string | undefined) => {
  return useMemo(() => AD_FORMATS_DISPLAY.find((format) => format.id === id), [id])
}
