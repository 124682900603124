import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { useTestEmail, useUserMetadata } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { PureSettingsProfilePage } from './PureSettingsProfilePage'

export const SettingsProfilePage = () => {
  const user = useUser()
  const { byId: testEmailById } = useTestEmail(null) // TODO tmu nra 2022-10-12 manage error
  const { byId: userMetadataById } = useUserMetadata(null)
  const { getAccessTokenSilently } = useAuth0()

  const [hasEmailNotifications, setHasEmailNotifications] = useState<boolean>(user?.alertEmail?.[user?.currentScopeId] ?? false)

  const onTestEmailSend = () => {
    testEmailById.post()
  }

  const onToggleEmailNotifications = async () => {
    if (user) {
      setHasEmailNotifications(!hasEmailNotifications)
      await userMetadataById.put({
        scopeId: user.currentScopeId,
        alertEmail: { ...user.alertEmail, [user.currentScopeId]: !hasEmailNotifications },
      })

      await getAccessTokenSilently({ cacheMode: 'off' }) //TODO tmu nra 2022-10-13 find out why it reloads on firefox
    }
  }

  return (
    <PureSettingsProfilePage
      onTestEmailSend={onTestEmailSend}
      onToggleEmailNotifications={onToggleEmailNotifications}
      isEmailNotificationsOn={hasEmailNotifications}
      userInfo={{ name: user?.profile.nickname ?? '', email: user?.profile.email ?? '', role: user ? user.getScopeRole() ?? 'user' : 'user' }}
    />
  )
}
