import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { Scope } from '@pubstack/common/src/scope'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { atom, selector } from 'recoil'
import { loadConfig } from '~/api/loadConfig'
import { FilterCategory } from '~/types/analytics'
import { Config } from '~/types/config'
import { getFilterSidebarContent } from '~/utils/analytics'

export const loadingState = atom<boolean>({ key: 'loading', default: false })

export const configurationState = selector<Config>({
  key: 'configuration',
  get: loadConfig,
})

export type SidebarMenuOpen = {
  open: boolean
  pinned: boolean
}
export const sidebarOpenState = atom<SidebarMenuOpen>({
  key: 'sidebarOpen',
  default: {
    open: true,
    pinned: true,
  },
})

export const currentScopeState = atom<Scope>({
  key: 'currentScope',
  default: {
    id: '',
    name: '',
    enabled: false,
    pbjs: 'pbjs',
    currencyCode: 'EUR',
    requestAnalyticsSent: false,
    gamKeyNamesForPrebid: ['hb_pb', 'hb_deal', 'hbv_pb'],
    timezone: 'Europe/Paris',
    mfaRequired: false,
  },
})

export const contextState = atom<Context>({
  key: 'context',
  default: {
    timeRange: {
      from: DateTime.local().startOf('day'),
      to: DateTime.local().endOf('day'),
      tz: 'Europe/Paris',
    },
    filters: {},
  },
})

const configSidebar = getFilterSidebarContent()
const categoriesConfig: FilterCategory[] = [...configSidebar]
export const filterSidebarState = atom<FilterCategory[]>({
  key: 'filterSidebar',
  default: categoriesConfig,
})

export type Context = {
  timeRange: TimeRange
  filters: { [key in Dimension]?: Filter }
}

export type Filter = {
  mode: 'include' | 'exclude'
  name: Dimension
  values: {
    label: string
    sublabel?: string
    value: string
  }[]
}

export type TimeRange = {
  from: DateTime
  to: DateTime
  tz: Timezone
}
