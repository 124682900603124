import styled from '@emotion/styled'
import { AdmQaReport } from '@pubstack/common/src/adm-qa-reports'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { useState } from 'react'
import { DataColors } from '~/assets/style/colors'
import { IconName } from '~/components/Icon'
import { AdmQaReportsDimName, AuctionsImpressionsData } from '~/modules/admin/adm-qa/admQaReports'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { auctionCount, eCPM, hbAuctionRpm, hbBidWinCount, hbImpressionCount, hbImpressionRevenue } from '~/modules/analytics/formulas'
import { ChartMetrics, useAdmQAReportChartData } from './admQaChartsData'

const CHART_METRICS: ChartMetrics<AuctionsImpressionsData> = {
  Auctions: {
    label: 'Auctions',
    compute: (data, index, site) => data.auctionCount[index][site],
    display: (data, index, site) => auctionCount.displayable(data.auctionCount[index][site]),
  },
  bidWinCount: {
    label: 'Bids Win',
    compute: (data, index, site) => data.bidWinCount[index][site],
    display: (data, index, site) => hbBidWinCount.displayable(data.bidWinCount[index][site]),
  },
  Impressions: {
    label: 'Impressions',
    compute: (data, index, site) => data.impressionCount[index][site],
    display: (data, index, site) => hbImpressionCount.displayable(data.impressionCount[index][site]),
  },
  revenue: {
    label: 'Revenue',
    compute: (data, index, site) => data.impressionCpmSum[index][site],
    display: (data, index, site, currencySymbol) => hbImpressionRevenue.displayable(data.impressionCpmSum[index][site], currencySymbol),
  },
  eCPM: {
    label: 'eCPM',
    compute: (data, index, site) => eCPM.raw({ impressionCpmSum: data.impressionCpmSum[index][site], impressionCount: data.impressionCount[index][site] }),
    display: (data, index, site, currencySymbol) => {
      const ecpm = eCPM.raw({ impressionCpmSum: data.impressionCpmSum[index][site], impressionCount: data.impressionCount[index][site] })
      return eCPM.displayable(ecpm, currencySymbol)
    },
  },
  RPM: {
    label: 'RPM',
    compute: (data, index, site) => hbAuctionRpm.raw({ auctionCount: data.auctionCount[index][site], impressionCpmSum: data.impressionCpmSum[index][site] }),
    display: (data, index, site, currencySymbol) => {
      const auctionRpm = hbAuctionRpm.raw({ auctionCount: data.auctionCount[index][site], impressionCpmSum: data.impressionCpmSum[index][site] })
      return hbAuctionRpm.displayable(auctionRpm, currencySymbol)
    },
  },
}

type PureAuctionsImpressionsChartProps = {
  rawData: AuctionsImpressionsData
  dimension: AdmQaReportsDimName
  sites: string[]
  isLoading: boolean
  report: AdmQaReport
  currencySymbol: CurrencySymbol
  iconName: IconName
}

const _PureAuctionsImpressionsChart = ({ rawData, dimension, iconName, sites, isLoading, report, currencySymbol }: PureAuctionsImpressionsChartProps) => {
  const [bidderChartMetric, setBidderChartMetric] = useState<keyof ChartMetrics<AuctionsImpressionsData>>('Auctions')

  const { chartData, legends } = useAdmQAReportChartData(rawData, dimension, sites, CHART_METRICS[bidderChartMetric], report, currencySymbol)
  return (
    <AnalyticsChartWidget
      title={`Auctions/impressions - ${dimension}`}
      empty={!isLoading && rawData.adunit.length === 0}
      error={false}
      onRefreshClick={() => {}}
      icon={iconName}
      isLoading={isLoading}
      legends={legends}
      metrics={Object.keys(CHART_METRICS).map((key) => {
        const metric = CHART_METRICS[key]
        return {
          label: metric.label,
          active: bidderChartMetric === key,
          onClick: () => setBidderChartMetric(key),
        }
      })}
      chart={{
        chartType: 'ColumnChart',
        options: {
          ...AnalyticsDefaultChartOptions,
          colors: [DataColors.Kaiminus, DataColors.Pumpkin],
          isStacked: 'false',
        },
        data: chartData,
      }}
    />
  )
}

export const PureAuctionsImpressionsChart = styled(_PureAuctionsImpressionsChart)``
