import styled from '@emotion/styled'
import { FunctionComponent, ReactNode, useState } from 'react'
import Button from '~/components/Button'
import { TabProp, Tabs } from '~/components/Tabs'
import { WithClassName } from '~/types/utils'
import { PureSiteStackHelpFlyout } from './PureSiteStackHelpFlyout'

const PageWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const ContentWrapper = styled.div``

const BreadcrumbsAndHelp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

type PureSitePageProps = WithClassName & {
  breadcrumbs?: ReactNode
  tabs: TabProp[]
  onTabChange: (tab: TabProp) => void
  children?: ReactNode | ReactNode[]
}
const _PureSitePage: FunctionComponent<PureSitePageProps> = ({ className, breadcrumbs, tabs, onTabChange, children }) => {
  const [isOpenHelpFlyout, setIsOpenHelpFlyout] = useState<boolean>(false)
  return (
    <PageWrapper className={className}>
      <BreadcrumbsAndHelp>
        {breadcrumbs}
        <Button variant={'tertiary'} iconName={'help'} onClick={() => setIsOpenHelpFlyout(true)}>
          Help
        </Button>
      </BreadcrumbsAndHelp>
      <Tabs tabs={tabs} onClick={onTabChange} />
      <ContentWrapper>{children}</ContentWrapper>
      <PureSiteStackHelpFlyout isOpen={isOpenHelpFlyout} onClose={() => setIsOpenHelpFlyout(false)} />
    </PageWrapper>
  )
}
export const PureSitePage = styled(_PureSitePage)``
