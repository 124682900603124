import styled from '@emotion/styled'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { GamConfig } from '@pubstack/common/src/gam/gamConfig'
import { integerRegExp } from '@pubstack/common/src/input'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useState } from 'react'
import { FieldErrors, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Breadcrumbs } from '~/components/Breadcrumbs'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { Skeleton } from '~/components/Skeleton'
import { Status } from '~/components/Status'
import Table, { handleTableSearchAndSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import { TableCollapsibleRow, TableCollapsibleRowChevronColumn } from '~/components/table/TableCollapsibleRow'
import { PureCatalogItemHeader } from '~/modules/adstack/inventory/catalog/PureCatalogItemHeader'
import { WithClassName } from '~/types/utils'
import { PureAdStackGamIntegrationHelpFlyout } from './PureAdStackGamIntegrationHelpFlyout'
import { PureAdStackGamSiteConfigForm } from './PureAdStackGamSiteConfigForm'

const Wrapper = styled.div`
  padding: 20px;

  & ${Breadcrumbs} {
    margin-bottom: 20px;
  }
`

const NetworkIdForm = styled.form`
  display: inline-flex;
  align-items: center;
  gap: 16px;
`

const SiteGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`
const SiteTitle = styled(TableCell)`
  font-weight: bold;
`
const NoSites = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 28px;
  text-align: center;
  ${Fonts.P1}
  h1 {
    ${Fonts.H1}
    font-weight: 400;
  }
`
const FilterAction = styled.form`
  margin: 12px 0px;
  display: flex;
`
const BackButton = styled.div`
  padding-top: 28px;
  display: flex;
  justify-content: center;
`

type PureAdStackGamIntegrationPageProps = WithClassName & {
  breadcrumbs: React.ReactNode
  isLoading: boolean
  gamConfig: GamConfig
  contextKeys: ContextKey[]
  createNetworkId: (networkId: string) => Promise<void>
  createSiteConfig: (siteConfig: SiteConfig) => Promise<void>
  onNavigateToContextKeys: () => void
  onNavigateToAdUnits: () => void
  onBackClick: () => void
  baseCDNUrl: string
  fromSiteId?: string
}

const _PureAdStackGamIntegrationPage: FunctionComponent<PureAdStackGamIntegrationPageProps> = ({
  className,
  breadcrumbs,
  isLoading,
  gamConfig,
  createNetworkId,
  createSiteConfig,
  onNavigateToContextKeys,
  onNavigateToAdUnits,
  onBackClick,
  contextKeys,
  baseCDNUrl,
  fromSiteId,
}) => {
  const [isOpenHelpFlyout, setIsOpenHelpFlyout] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [columns, setColumns] = useState<TableColumns<Site>>([
    {
      name: 'Site',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'Status',
      isSortable: false,
    },
    TableCollapsibleRowChevronColumn,
  ])

  const { control: searchControl } = useForm<{ search: string }>({
    defaultValues: {
      search: fromSiteId || '',
    },
  })

  const search = useWatch({ control: searchControl, name: 'search' })

  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({})
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm<GamConfig>({
    defaultValues: gamConfig,
  })

  const onNetworkIdEdit = () => {
    setIsEditing(!isEditing)
  }
  const onNetworkIdSubmit: SubmitHandler<GamConfig> = (formValue) => {
    createNetworkId(formValue.networkId)
    setIsEditing(!isEditing)
  }

  const getErrorMessage = (errors: FieldErrors<GamConfig>) => {
    if (errors.networkId?.type === 'required') {
      return 'NetworkId is required'
    }
    if (errors.networkId?.type === 'pattern') {
      return 'The id should be numeric'
    }
  }
  const sortedSites = handleTableSearchAndSort(columns, gamConfig.sites, search, ['name', 'id'])

  useEffect(() => {
    reset({
      ...gamConfig,
    })
  }, [gamConfig])

  useEffect(() => {
    if (fromSiteId) {
      setIsOpen({ [fromSiteId]: true })
    }
  }, [fromSiteId])

  return (
    <Wrapper className={className}>
      <PureCatalogItemHeader
        breadcrumbs={breadcrumbs}
        catalogItemCode={'gam'}
        catalogItemType={'adServer'}
        isLoading={isLoading}
        isActive={!!gamConfig.networkId}
        baseCDNUrl={baseCDNUrl}
        actions={
          <>
            <Button variant={'tertiary'} onClick={onBackClick}>
              Back
            </Button>
            <Button variant={'tertiary'} onClick={() => setIsOpenHelpFlyout(true)} iconName={'help'}>
              Help
            </Button>
          </>
        }
      />
      <ContentCard className={className} color={Colors.Violet}>
        <NetworkIdForm id={'gamNetworkIdForm'} onSubmit={handleSubmit(onNetworkIdSubmit)}>
          <span>Network Id:</span>
          <Input disabled={!isEditing} type={'text'} name={'networkId'} rules={{ required: true, pattern: integerRegExp }} control={control} error={getErrorMessage(errors)} />
          {!isEditing ? (
            <Button variant={'primary'} onClick={onNetworkIdEdit}>
              Edit
            </Button>
          ) : (
            <Button disabled={!isDirty} variant={'primary'} onClick={handleSubmit(onNetworkIdSubmit)}>
              Save
            </Button>
          )}
        </NetworkIdForm>
      </ContentCard>
      {!fromSiteId && (
        <FilterAction>
          <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={searchControl} />
        </FilterAction>
      )}
      <SiteGroup>
        {!isLoading ? (
          gamConfig.networkId !== '0' && gamConfig.sites.length > 0 ? (
            <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
              {sortedSites.map((site) => {
                const siteConfig = gamConfig.siteConfigs.find((s) => site.id === s.siteId)
                return (
                  <TableCollapsibleRow
                    key={site?.id}
                    isOpen={isOpen[site.id] ?? false}
                    onToggleCollapse={() => {
                      setIsOpen((previousState) => ({ ...previousState, [site.id]: !previousState[site.id] }))
                    }}
                    content={(toggleCollapse) => (
                      <PureAdStackGamSiteConfigForm
                        contextKeys={contextKeys}
                        siteId={site.id}
                        siteConfig={siteConfig}
                        scopeId={gamConfig.scopeId}
                        createSiteConfig={(data) => {
                          createSiteConfig(data)
                          toggleCollapse()
                        }}
                      />
                    )}
                  >
                    <SiteTitle>{site.name}</SiteTitle>
                    <TableCell>
                      <Status state={siteConfig?.adUnitPathConfig ? 'active' : 'inactive'}>{siteConfig?.adUnitPathConfig ? 'Configured' : 'Not configured'}</Status>
                    </TableCell>
                  </TableCollapsibleRow>
                )
              })}
            </Table>
          ) : (
            <NoSites>
              <Icon name={'night_sky'} width={'190px'} />
              <h1>You don’t have any sites yet or your network Id is not set.</h1>
            </NoSites>
          )
        ) : (
          <Skeleton bigger width={'100%'} />
        )}
      </SiteGroup>
      {fromSiteId && (
        <BackButton>
          <Button variant={'primary'} onClick={onBackClick}>
            Back to site
          </Button>
        </BackButton>
      )}
      <PureAdStackGamIntegrationHelpFlyout
        isOpen={isOpenHelpFlyout}
        onClose={() => setIsOpenHelpFlyout(false)}
        navigateToContextKeys={onNavigateToContextKeys}
        navigateToAdUnits={onNavigateToAdUnits}
      />
    </Wrapper>
  )
}

export const PureAdStackGamIntegrationPage = styled(_PureAdStackGamIntegrationPage)``
