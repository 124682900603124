import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'
import { LazyLoadingViewport, LazyLoadingViewportProps, ViewportType } from './LazyLoadingViewport'

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: ${Colors.White};
  width: 388px;
  height: 500px;
`

const PreviewTitle = styled.p`
  ${Fonts.H2};
`

const PreviewViewports = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`

export type LazyLoadingViewportPreviewProps = WithClassName & {
  focusElement?: ViewportType
  auctionStart: number
  gamRequest: number
  renderImpression: number
}

const getElementIndex = (value: number) => {
  const truncatedValue = Math.trunc(value)
  return truncatedValue < 3 ? truncatedValue : 3
}

const _LazyLoadingViewportPreview: FunctionComponent<LazyLoadingViewportPreviewProps> = ({ className, focusElement, auctionStart, gamRequest, renderImpression }) => {
  const lazyLoadingViewportsProps: LazyLoadingViewportProps[] = [
    {
      title: '0 viewport (in view)',
      viewports: [],
    },
    {
      title: '1 viewport',
      viewports: [],
    },
    {
      title: '2 viewports',
      viewports: [],
    },
    {
      title: '3 and above viewports',
      viewports: [],
    },
  ]
  if (auctionStart > -1 && auctionStart.toString() !== '') {
    const auctionStartIndex = getElementIndex(auctionStart)
    lazyLoadingViewportsProps[auctionStartIndex].viewports.push({
      type: 'Auction start',
      value: auctionStart,
      hasFocus: focusElement === 'Auction start',
    })
  }
  if (gamRequest > -1 && gamRequest.toString() !== '') {
    const gamRequestIndex = getElementIndex(gamRequest)
    lazyLoadingViewportsProps[gamRequestIndex].viewports.push({
      type: 'GAM request',
      value: gamRequest,
      hasFocus: focusElement === 'GAM request',
    })
  }
  if (renderImpression > -1 && renderImpression.toString() !== '') {
    const renderImpressionIndex = getElementIndex(renderImpression)
    lazyLoadingViewportsProps[renderImpressionIndex].viewports.push({
      type: 'Render impression',
      value: renderImpression,
      hasFocus: focusElement === 'Render impression',
    })
  }
  return (
    <Preview className={className}>
      <PreviewTitle>Preview based on viewports</PreviewTitle>
      <PreviewViewports>
        {lazyLoadingViewportsProps.map((lazyLoadingViewport) => (
          <LazyLoadingViewport {...lazyLoadingViewport} key={lazyLoadingViewport.title} />
        ))}
      </PreviewViewports>
    </Preview>
  )
}
export const LazyLoadingViewportPreview = styled(_LazyLoadingViewportPreview)``
