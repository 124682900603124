import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { Icon } from '~/components/Icon'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

const IconWrapper = styled.div`
  display: flex;
`
const Text = styled.div`
  margin: 0 4px 0 8px;
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 12px;
  ${Fonts.colors.Hurricane};
  ${Fonts.P1};
`

type FilterSidebarMinorDataToggleProps = WithClassName & {
  value?: boolean
  onToggle: () => void
}
const _FilterSidebarMinorDataToggle: FunctionComponent<FilterSidebarMinorDataToggleProps> = ({ className, value, onToggle }) => (
  <Wrapper className={className}>
    <Toggle value={value} onClick={onToggle} />
    <Text>Minor data</Text>
    <Tooltip
      title={
        <>
          Show or hide data under 1% of the
          <br /> total revenue or bid requests
        </>
      }
      reposition={true}
    >
      <IconWrapper>
        <Icon name={'info'} width={Sizes['18']} />
      </IconWrapper>
    </Tooltip>
  </Wrapper>
)
export const FilterSidebarMinorDataToggle = styled(_FilterSidebarMinorDataToggle)``
