import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Chart, ReactGoogleChartProps } from 'react-google-charts'
import { IconName } from '~/components/Icon'
import { Legend, LegendProps } from '~/components/Legend'
import { TimelineMetricProps } from '~/components/TimelineMetric'
import { TimelineMetrics } from '~/components/TimelineMetrics'
import { Widget, WidgetProps } from '~/components/Widget'
import { WidgetMessage } from '~/components/WidgetMessage'
import { WithClassName } from '~/types/utils'
import { ChartWrapper, ChartWrapperSmall, Legends } from './AnalyticsCharts'

const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
`
const TimelineMetricsWrapper = styled.div`
  margin-left: 50px;
`

const ChartContainer = styled.div`
  flex: 1 1 auto;
`

export type AnalyticsChartWidgetMessage = {
  message: ReactNode
  icon: IconName
}
type AnalyticsChartWidgetProps = WithClassName &
  WidgetProps & {
    metrics?: TimelineMetricProps[]
    metricMessage?: AnalyticsChartWidgetMessage
    chart: ReactGoogleChartProps
    legends: LegendProps[]
    smallChart?: boolean
  }
const _AnalyticsChartWidget: FunctionComponent<AnalyticsChartWidgetProps> = ({ metrics, chart, legends, metricMessage, smallChart, ...props }) => {
  const Wrapper = smallChart ? ChartWrapperSmall : ChartWrapper
  return (
    <Widget {...props} empty={props.empty || (!props.isLoading && !legends.length)}>
      <WidgetContent>
        {(!!metrics || !!metricMessage) && (
          <TimelineMetricsWrapper>
            {!!metrics && <TimelineMetrics metrics={metrics} />}
            {!!metricMessage && <WidgetMessage {...metricMessage} />}
          </TimelineMetricsWrapper>
        )}
        <ChartContainer>
          <Wrapper>{chart.data && <Chart width={'100%'} height={'100%'} {...chart} />}</Wrapper>
          <Legends>{legends.map((legend, index) => legend && <Legend key={index} {...legend} />)}</Legends>
        </ChartContainer>
      </WidgetContent>
    </Widget>
  )
}
export const AnalyticsChartWidget = styled(_AnalyticsChartWidget)``
