import { ScopeId } from './scope'
export const features = [
  'analytics',
  'adx',
  'viewability',
  'openBidding',
  'refresh',
  'userSession',
  'standaloneUserSession',
  'liveChat',
  'gamOverviewWeb',
  'gamOverviewApp',
  'gamOverviewAmp',
  'adstack',
  'abtest',
  'smartAdServer',
  'admRefresh',
  'marketplace',
] as const

export type Feature = (typeof features)[number]

// custom declaration to enable includes(string) on features readonly array
// see https://stackoverflow.com/questions/56565528/typescript-const-assertions-how-to-use-array-prototype-includes
declare global {
  interface ReadonlyArray<T> {
    includes<U>(x: U & (T & U extends never ? never : unknown)): boolean
  }
}

export type AccessRole = 'user' | 'owner' | 'pubstack'
// Demo is not an access role, it's a normal user with some rename stuff
export type Role = AccessRole | 'demo'
export type UserViewMode = 'owner' | 'user' | 'demo'
export interface PubstackUserRole {
  pubstack: true
  view?: UserViewMode
}
export interface NonPubstackUserRole {
  [scopeId: string]: Role // {'d5629df8-a263-4238-a3ab-efc2ececbe81': 'user'}
}
export type UserRole = PubstackUserRole | NonPubstackUserRole

export const isPubstackUserRole = (userRole: UserRole): userRole is PubstackUserRole => userRole.pubstack === true
export const isNonPubstackUserRole = (userRole: UserRole): userRole is NonPubstackUserRole => !isPubstackUserRole(userRole)

export const getScopeRole = (scopeId: ScopeId, userRole: UserRole): Role | null => {
  // TODO 2023-09-07 SLE: Below it is an if - else with default value null ???
  if (isPubstackUserRole(userRole)) return 'pubstack'
  if (isNonPubstackUserRole(userRole) && userRole[scopeId] !== undefined) {
    return userRole[scopeId]
  }
  return null
}

export const scopesAllowed = (userRole: UserRole): ScopeId[] | 'all' => {
  if (isPubstackUserRole(userRole)) return 'all'
  return Object.keys(userRole)
}

export const canAccess = (scopeId: ScopeId, userRole: UserRole, accessRole: AccessRole): boolean => {
  const role = getScopeRole(scopeId, userRole)
  if (role === null) return false
  if (role === 'pubstack') return true
  if (role === 'owner') {
    return accessRole === 'owner' || accessRole === 'user'
  }
  if (role === 'user' || role === 'demo') {
    return accessRole === 'user'
  }
  return false
}

export const canAccessScopeId = (scopeId: ScopeId, userRole: UserRole): boolean => {
  if (isPubstackUserRole(userRole)) {
    return true
  } else {
    return scopeId in userRole
  }
}
export const scopeFeature = (scopeId: ScopeId, feature: Feature): string => `${scopeId}:feature:${feature}`

export const hasFeature = (scopeId: ScopeId, features: string[], feature: Feature): boolean => {
  return features.findIndex((scopedFeature) => scopedFeature === scopeFeature(scopeId, feature)) !== -1
}

export const scopeAllFeatures = (scopeId: ScopeId): string[] => features.map((feature) => scopeFeature(scopeId, feature))
