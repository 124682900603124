import { AdmQaReportsTimeRange } from '@pubstack/common/src/adm-qa-reports'
import { AllMetrics } from '@pubstack/common/src/analytics/query'

export type AdmQAReportsDim = {
  adunit: string[]
  biddercode: string[]
  device: string[]
  website: string[]
  size: string[]
  gampath: string[]
}

export type AdmQaReportsDimName = keyof AdmQAReportsDim

export type AuctionsImpressionsData = Pick<AllMetrics, 'auctionCount' | 'impressionCount' | 'bidWinCpmSum' | 'impressionCpmSum' | 'bidWinCount'> & AdmQAReportsDim

export type BidsData = Pick<AllMetrics, 'bidRequestCount' | 'bidCpmSum' | 'bidResponseCount' | 'bidTimeoutCount' | 'bidWinCount'> & AdmQAReportsDim

export type ViewabilityData = Pick<
  AllMetrics,
  | 'impressionCpmSum'
  | 'impressionCount'
  | 'mrcViewableImpressionCount'
  | 'adxMrcViewableImpressionCount'
  | 'measuredImpressionViewedTimeSum'
  | 'measuredImpressionCount'
  | 'adxMeasuredImpressionCount'
  | 'adxMeasuredImpressionViewedTimeSum'
> &
  AdmQAReportsDim

export type UserSessionData = Pick<AllMetrics, 'impressionCount' | 'sessionCount' | 'pageCount'> & AdmQAReportsDim

export const ADM_QA_REPORTS_DIMS: AdmQaReportsDimName[] = ['adunit', 'biddercode', 'device', 'website', 'size', 'gampath']

export const ADM_QA_REPORTS_TIME_RANGE_LABELS: { [key in AdmQaReportsTimeRange]: string } = {
  '30-minutes': '30 min before/after',
  '1-day': '1 day before/after (live excluded)',
  '1-day-last-week': '1 day before/after (last week)',
  '1-hour': '1 hour before/after',
  '2-hours': '2 hours before/after',
  '1-week': '1 week before/after',
}
