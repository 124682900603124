import styled from '@emotion/styled'
import { RefreshGroup } from '@pubstack/common/src/refresh'
import { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Modal } from '~/components/Modal'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { EmptyTable, handleTableSearchAndSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'
import { SUPPORT_MAIL } from '~/utils/constants'

const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  & > * {
    display: inline-flex;
    flex-direction: column;
  }
`

const DeleteRefreshGroupModal: FunctionComponent<{ refreshGroup: RefreshGroup; onDelete: (refreshGroup: RefreshGroup) => void }> = ({ refreshGroup, onDelete }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Remove refresh group</Modal.Title>

      <Modal.Body>
        <Paragraphs>
          <p>
            <span>
              You are about to delete <strong>{refreshGroup.name}</strong>
            </span>
            <span>Targeted sites and ad units will no longer be refreshed.</span>
          </p>
          <p>Do you confirm your choice ?</p>
        </Paragraphs>
      </Modal.Body>

      <Modal.Actions>
        <Button onClick={close} variant={'tertiary'}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onDelete(refreshGroup)
            close()
          }}
          variant={'negative'}
        >
          Remove refresh group
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const SettingsRefreshGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const SettingsRefreshGroupsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const ActionsTableCell = styled(TableCell)`
  width: 0; /** necessary to get the last cell to shrink down to hug action buttons */

  > div {
    display: flex;
    gap: 16px;
  }
`

type PureSettingsRefreshGroupsPageProps = WithClassName & {
  refreshGroups: RefreshGroup[]
  isLoading: boolean
  isError: boolean
  onDeleteRefreshGroup: (refreshGroup: RefreshGroup) => void
  onEditRefreshGroup: (refreshGroup?: RefreshGroup) => void
  onCloneRefreshGroup: (refreshGroup: RefreshGroup) => void
  onCreateRefreshGroup: () => void
  onToggleRefreshGroupStatus: (refreshGroup: RefreshGroup) => void
}
const _PureSettingsRefreshGroupsPage: FunctionComponent<PureSettingsRefreshGroupsPageProps> = ({
  className,
  refreshGroups,
  isLoading,
  isError,
  onEditRefreshGroup,
  onCloneRefreshGroup,
  onCreateRefreshGroup,
  onDeleteRefreshGroup,
  onToggleRefreshGroupStatus,
}) => {
  const modal = useGlobalModal()
  const refreshGroupsCols: TableColumns<RefreshGroup> = [
    { name: 'Name', attributeSort: 'name', isSortable: true, order: 'ascending' },
    { name: 'Rule', isSortable: false },
    { name: 'Capping', isSortable: false },
    { name: 'Status', isSortable: false },
    { name: 'Action', isSortable: false },
  ]

  const [columns, setColumns] = useState<TableColumns<RefreshGroup>>(refreshGroupsCols)

  const { control, watch } = useForm({
    defaultValues: {
      search: '',
    },
  })

  const refreshGroupSearch = watch('search')

  const sortedRefreshGroups = handleTableSearchAndSort(columns, refreshGroups, refreshGroupSearch, ['name'])

  return (
    <SettingsRefreshGroupsWrapper className={className}>
      <SettingsRefreshGroupsHeader>
        <Input control={control} name={'search'} label={'Search'} labelIsPlaceholder iconLeft={'search'} />
        <Button onClick={onCreateRefreshGroup}>New refresh group</Button>
      </SettingsRefreshGroupsHeader>
      <Table isLoading={isLoading} columns={columns} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {sortedRefreshGroups.length ? (
          sortedRefreshGroups.map((refreshGroup, index) => (
            <TableRow
              key={index}
              onClick={() => {
                onEditRefreshGroup(refreshGroup)
              }}
            >
              <TableCell>{refreshGroup.name}</TableCell>
              <TableCell>{`${refreshGroup.timer} seconds in view`}</TableCell>
              <TableCell>{refreshGroup.capping ? `${refreshGroup.capping} refreshes` : 'No cap'}</TableCell>
              <TableCell>
                <Toggle
                  onClick={(event) => {
                    event.stopPropagation()
                    onToggleRefreshGroupStatus(refreshGroup)
                  }}
                  value={refreshGroup.enabled}
                />
              </TableCell>
              <ActionsTableCell>
                <div>
                  <Tooltip title={'Edit'}>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation()
                        onEditRefreshGroup(refreshGroup)
                      }}
                      variant={'tertiary'}
                      iconName={'edit'}
                    />
                  </Tooltip>
                  <Tooltip title={'Clone'}>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation()
                        onCloneRefreshGroup(refreshGroup)
                      }}
                      variant={'tertiary'}
                      iconName={'copy'}
                    />
                  </Tooltip>
                  <Tooltip title={'Remove'}>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation()
                        modal.open(DeleteRefreshGroupModal, { refreshGroup, onDelete: onDeleteRefreshGroup })
                      }}
                      variant={'tertiary'}
                      iconName={'delete'}
                    />
                  </Tooltip>
                </div>
              </ActionsTableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colspan={columns.length}>
              <EmptyTable>
                {isError ? (
                  <>
                    <h2>An error occurred when trying to display your refresh groups</h2>
                    <p>
                      Please refresh the page or contact <Link label={SUPPORT_MAIL} href={`mailto:${SUPPORT_MAIL}`} /> if the issue persists.
                    </p>
                  </>
                ) : (
                  <>
                    <div>
                      <h2>No refresh groups</h2>
                      <p>You have no refresh groups set-up yet.</p>
                    </div>
                    <Button onClick={() => onCreateRefreshGroup()}>New refresh group</Button>
                  </>
                )}
              </EmptyTable>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </SettingsRefreshGroupsWrapper>
  )
}

export const PureSettingsRefreshGroupsPage = styled(_PureSettingsRefreshGroupsPage)``
