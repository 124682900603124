import { AdUnit } from './adunit'
import { AdUnitDevice } from './adunitDevice'

export const AD_UNIT_MEDIATYPES = ['Banner', 'Outstream'] as const
export type Mediatype = (typeof AD_UNIT_MEDIATYPES)[number]

export type SizesByDevice = {
  size: string
  devices: AdUnitDevice[]
}
export type AdFormat = {
  id: string
  name: string
  sizes: SizesByDevice[]
  mediatypes: Mediatype[]
  devices: AdUnitDevice[]
  isDynamicToggleEnabled?: boolean
  isParallaxToggleEnabled?: boolean
  isAnchorToggleEnabled?: boolean
  defaultValues?: Pick<Partial<AdUnit>, 'fluid' | 'dynamicEnabled' | 'parallaxEnabled' | 'anchor' | 'containerMaxSize' | 'skinEnabled'>
}

export const SKIN_SIZE = '1800x1000'

// STANDARD
export const LEADERBOARD: AdFormat = {
  id: 'LEADERBOARD',
  name: 'Leaderboard',
  sizes: [
    { size: '728x90', devices: ['desktop'] },
    { size: '1000x90', devices: ['desktop'] },
    { size: '468x60', devices: ['desktop'] },
    { size: '970x250', devices: ['desktop'] },
    { size: '970x90', devices: ['desktop'] },
    { size: '1000x300', devices: ['desktop'] },
  ],
  mediatypes: ['Banner'],
  devices: ['desktop'],
  isDynamicToggleEnabled: false,
  defaultValues: {
    dynamicEnabled: false,
    parallaxEnabled: false,
    anchor: undefined,
  },
}
export const SIDEBAR: AdFormat = {
  id: 'SIDEBAR',
  name: 'Sidebar',
  sizes: [
    { size: '300x600', devices: ['desktop'] },
    { size: '300x250', devices: ['desktop'] },
    { size: '160x600', devices: ['desktop'] },
    { size: '120x600', devices: ['desktop'] },
  ],
  mediatypes: ['Banner'],
  devices: ['desktop'],
  isDynamicToggleEnabled: true,
  defaultValues: {
    parallaxEnabled: false,
    anchor: undefined,
  },
}
export const RECTANGLE_MEDIUM: AdFormat = {
  id: 'RECTANGLE_MEDIUM',
  name: 'Rectangle medium',
  sizes: [
    { size: '300x250', devices: ['desktop', 'mobile'] },
    { size: '250x250', devices: ['desktop', 'mobile'] },
  ],
  mediatypes: ['Banner', 'Outstream'],
  devices: ['desktop', 'mobile'],
  isDynamicToggleEnabled: true,
  defaultValues: {
    parallaxEnabled: false,
    anchor: undefined,
  },
}
export const RECTANGLE_LARGE: AdFormat = {
  id: 'RECTANGLE_LARGE',
  name: 'Rectangle large',
  sizes: [
    { size: '300x600', devices: ['desktop'] },
    { size: '300x250', devices: ['desktop'] },
    { size: '250x250', devices: ['desktop'] },
    { size: '120x600', devices: ['desktop'] },
    { size: '160x600', devices: ['desktop'] },
  ],
  mediatypes: ['Banner', 'Outstream'],
  devices: ['desktop'],
  isDynamicToggleEnabled: true,
  defaultValues: {
    parallaxEnabled: false,
    anchor: undefined,
  },
}
export const OUTSTREAM_SINGLE_AD: AdFormat = {
  id: 'OUTSTREAM_SINGLE_AD',
  name: 'Outstream',
  mediatypes: ['Outstream'],
  devices: ['desktop', 'mobile'],
  sizes: [],
  isDynamicToggleEnabled: false,
  defaultValues: {
    dynamicEnabled: false,
    parallaxEnabled: false,
    anchor: undefined,
  },
}
export const MOBILE_BANNER: AdFormat = {
  id: 'MOBILE_BANNER',
  name: 'Mobile banner',
  sizes: [
    { size: '320x100', devices: ['mobile'] },
    { size: '320x50', devices: ['mobile'] },
    { size: '300x100', devices: ['mobile'] },
    { size: '300x50', devices: ['mobile'] },
  ],
  mediatypes: ['Banner'],
  devices: ['mobile'],
  isDynamicToggleEnabled: true,
  defaultValues: {
    parallaxEnabled: false,
    anchor: undefined,
  },
}
export const IN_CONTENT: AdFormat = {
  id: 'IN_CONTENT',
  name: 'In content',
  sizes: [
    { size: '300x600', devices: ['desktop'] },
    { size: '300x250', devices: ['desktop', 'mobile'] },
    { size: '320x100', devices: ['mobile'] },
    { size: '320x50', devices: ['mobile'] },
    { size: '320x480', devices: ['mobile'] },
    { size: '336x280', devices: ['desktop'] },
  ],
  mediatypes: ['Banner', 'Outstream'],
  devices: ['desktop', 'mobile'],
  isDynamicToggleEnabled: true,
  defaultValues: {
    parallaxEnabled: false,
    anchor: undefined,
  },
}

// HIGH IMPACT
export const MOBILE_INTERSTITIAL: AdFormat = {
  id: 'MOBILE_INTERSTITIAL',
  name: 'Mobile interstitial',
  mediatypes: ['Banner', 'Outstream'],
  devices: ['mobile'],
  sizes: [],
  isDynamicToggleEnabled: true,
  defaultValues: {
    parallaxEnabled: false,
    anchor: undefined,
  },
}
export const OUT_OF_PAGE: AdFormat = {
  id: 'OUT_OF_PAGE',
  name: 'Out-of-page',
  mediatypes: ['Banner', 'Outstream'],
  devices: ['desktop', 'mobile'],
  sizes: [],
  isDynamicToggleEnabled: true,
  defaultValues: {
    parallaxEnabled: false,
    anchor: undefined,
  },
}
export const SKIN_SINGLE_AD: AdFormat = {
  id: 'SKIN_SINGLE_AD',
  name: 'Skin single ad ',
  sizes: [{ size: SKIN_SIZE, devices: ['desktop'] }],
  mediatypes: ['Banner'],
  devices: ['desktop'],
  isDynamicToggleEnabled: false,
  // specifying the max size filter to undefined allows Skin to remove the existing max size filter
  defaultValues: { parallaxEnabled: false, anchor: undefined, dynamicEnabled: false, containerMaxSize: { width: undefined, height: undefined }, skinEnabled: true },
}
export const CUSTOM_AD_FORMAT: AdFormat = {
  id: 'CUSTOM',
  name: 'Custom',
  mediatypes: ['Banner', 'Outstream'],
  devices: ['desktop', 'mobile'],
  sizes: [],
  isDynamicToggleEnabled: true,
  isParallaxToggleEnabled: true,
  isAnchorToggleEnabled: true,
}

export const IN_CONTENT_PARALLAX: AdFormat = {
  id: 'IN_CONTENT_PARALLAX',
  name: 'In content parallax',
  sizes: [
    { size: '300x600', devices: ['mobile', 'desktop'] },
    { size: '300x250', devices: ['mobile', 'desktop'] },
    { size: '320x480', devices: ['mobile', 'desktop'] },
    { size: '336x280', devices: ['mobile', 'desktop'] },
    { size: '320x50', devices: ['mobile'] },
    { size: '320x100', devices: ['mobile'] },
  ],
  mediatypes: ['Banner', 'Outstream'],
  devices: ['mobile', 'desktop'],
  defaultValues: {
    parallaxEnabled: true,
    anchor: undefined,
    fluid: true,
    containerMaxSize: {
      height: undefined,
    },
  },
  isDynamicToggleEnabled: true,
}

export const ANCHOR: AdFormat = {
  id: 'ANCHOR',
  name: 'Anchor',
  sizes: [
    { size: '320x100', devices: ['mobile'] },
    { size: '320x50', devices: ['mobile'] },
    { size: '300x100', devices: ['mobile'] },
    { size: '300x50', devices: ['mobile'] },
  ],
  mediatypes: ['Banner'],
  devices: ['mobile'],
  defaultValues: {
    dynamicEnabled: false,
    parallaxEnabled: false,
    anchor: { mode: 'useExistingDiv' },
  },
  isDynamicToggleEnabled: true,
}

export const AD_FORMATS: AdFormat[] = [
  LEADERBOARD,
  SIDEBAR,
  RECTANGLE_MEDIUM,
  RECTANGLE_LARGE,
  OUTSTREAM_SINGLE_AD,
  MOBILE_BANNER,
  IN_CONTENT,
  MOBILE_INTERSTITIAL,
  OUT_OF_PAGE,
  SKIN_SINGLE_AD,
  CUSTOM_AD_FORMAT,
  IN_CONTENT_PARALLAX,
  ANCHOR,
]
