import { StacksBySite } from './stack'

export type ConditionGroupElement = ConditionGroup | ConditionElement
export type ConditionGroup = {
  op: 'AND' | 'OR'
  elements: ConditionGroupElement[]
}

export type ConditionElement = {
  op: 'IN' | 'NOT_IN'
  key: string
  values: string[]
}

// this object can't be named "Context" because it already exists
export type StackContext = {
  id: string
  name: string
  scopeId: string
  creationTime: number
  conditions: ConditionGroup
  stacksUse?: StacksBySite // Stacks using this context
  enabled: boolean
}

export const CONTEXT_ELEMENT_OP_AS_STR: Record<ConditionElement['op'], string> = {
  IN: 'is one of',
  NOT_IN: 'is not one of',
}
