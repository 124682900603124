import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, ReactElement } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'
import { Icon } from './Icon'
import { Tooltip } from './Tooltip'

const TimelineMetricLabel = styled.div`
  display: inline-flex;
  align-items: center;
  ${Fonts.P2}
  ${Fonts.colors.SlateGrey}

  & ${Tooltip} {
    margin-left: 4px;
    display: inline-flex;
    align-items: center;
  }
`
const TimelineMetricValue = styled.div`
  ${Fonts.P0}
  font-weight: bold;
  ${Fonts.colors.Jet}
`

const TimelineMetricNotApplicable = styled.div`
  ${Fonts.P1}
  ${Fonts.colors.SlateGrey}
`
const TimelineMetricWrapper = styled.div<{ active: boolean; notApplicable?: boolean }>`
  padding: 2px 8px;
  cursor: pointer;
  border-radius: 4px;
  flex-shrink: 0;

  ${({ active }) =>
    active &&
    css`
      background-color: ${Colors.King};

      ${TimelineMetricValue} {
        ${Fonts.colors.White}
      }

      ${TimelineMetricLabel} {
        ${Fonts.colors.Topaze}
      }
    `}

  :hover {
    background-color: ${Colors.Topaze};

    ${({ active }) =>
      active &&
      css`
        background-color: ${Colors.Cobalt};
      `}

    ${({ notApplicable }) =>
      notApplicable &&
      css`
        background-color: ${Colors.Platinum};
        cursor: not-allowed;
      `}
  }

  ${({ notApplicable }) =>
    notApplicable &&
    css`
      background-color: ${Colors.Platinum};
    `}
`

export type TimelineMetricProps = WithClassName & {
  active: boolean
  onClick: () => void
  label: string
  value?: string
  notApplicable?: boolean
  tooltipText?: string | ReactElement
}

const _TimelineMetric: FunctionComponent<TimelineMetricProps> = ({ className, onClick, active, label, notApplicable, value, tooltipText }) => (
  <TimelineMetricWrapper notApplicable={notApplicable} className={className} onClick={onClick} active={active}>
    <TimelineMetricLabel>
      {label}
      {tooltipText && (
        <Tooltip title={tooltipText}>
          <Icon width={'16px'} name={'info'} />
        </Tooltip>
      )}
    </TimelineMetricLabel>
    {notApplicable ? (
      <Tooltip title={'Please, try to change the current selection'}>
        <TimelineMetricNotApplicable>(Not applicable)</TimelineMetricNotApplicable>
      </Tooltip>
    ) : (
      <TimelineMetricValue>{value}</TimelineMetricValue>
    )}
  </TimelineMetricWrapper>
)
export const TimelineMetric = styled(_TimelineMetric)``
