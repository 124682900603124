import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { FunctionComponent, useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ChipListInput } from '~/components/ChipListInput'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

const SiteConfigForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const KVMSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 760px;
`

const Title = styled.div`
  padding-top: 20px;
  ${Fonts.H2};
  font-weight: bold;
  color: ${Colors.Jet};
`

const Help = styled.div`
  ${Fonts.P2};
  ${Fonts.colors.SlateGrey};
  margin-left: 50px;
  margin-top: -4px;
`

const FormToggle = styled.div`
  display: inline-flex;
  gap: 8px;
  flex-wrap: wrap;
  ${Tooltip} {
    display: inline-flex;
  };

  & ${Help} {
    flex-basis: 100%;
  }
`
const Actions = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
`
const LabelWithTooltip = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const SchainItem = styled.div`
  display: inline-flex;
  gap: 25px;
  align-items: center;
  ${_Input} {
    width: 478px;
  };

  input:placeholder-shown {
    font-style: italic;
  }

  & ${Button} {
    align-self: center;
  }
`

const SchainBloc = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 39px;
  align-items: flex-start;
`

const DEFAULT_SCHAIN_NODE = {
  asi: '',
  sid: '',
  hp: 1,
}
const DEFAULT_SCHAIN = {
  validation: 'strict',
  config: {
    ver: '1.0',
    complete: 1,
    nodes: [DEFAULT_SCHAIN_NODE],
  },
}

type PureAdStackSiteConfigFormProps = WithClassName & {
  siteId: string
  scopeId: string
  siteConfig?: SiteConfig
  onCreateSiteConfig: (siteConfig: SiteConfig) => void
  bidderAdapters: BidderAdapter[]
}

const preparePrebidSiteConfig = (siteConfig: SiteConfig | undefined, scopeId: string, siteId: string): SiteConfig => {
  // migration data: ensure allowLocalStorage and sendKVTargetings are always present
  return {
    scopeId,
    siteId,
    ...siteConfig,
    pbjsConfig: {
      ...siteConfig?.pbjsConfig,
      // the default behavior of kleanads.js is to allow local storage
      allowLocalStorage: siteConfig?.pbjsConfig?.allowLocalStorage ?? true,
      targetingBehaviors: {
        sendKVTargetings: !!siteConfig?.pbjsConfig?.targetingBehaviors?.sendKVTargetings,
        biddersBlackList: siteConfig?.pbjsConfig?.targetingBehaviors?.biddersBlackList || [],
      },
      schain: siteConfig?.pbjsConfig?.schain ?? DEFAULT_SCHAIN,
    },
  }
}

const _PureAdStackPrebidSiteConfigForm: FunctionComponent<PureAdStackSiteConfigFormProps> = ({ onCreateSiteConfig, siteConfig: originalSiteConfig, siteId, scopeId, bidderAdapters }) => {
  // migration data: ensure allowLocalStorage and sendKVTargetings are always present
  const siteConfig = preparePrebidSiteConfig(originalSiteConfig, scopeId, siteId)

  const defaultValues = { ...siteConfig, siteId, scopeId: scopeId }
  const {
    handleSubmit,
    formState: { isDirty, errors },
    setValue,
    reset,
    control,
  } = useForm<SiteConfig>({
    defaultValues,
    mode: 'onChange',
  })

  const {
    setValue: setSchainValue,
    control: schainControl,
    formState: { isDirty: isSchainDirty },
    reset: resetSchain,
  } = useForm<{ isSchainEnabled: boolean }>({
    defaultValues: {
      isSchainEnabled: !!originalSiteConfig?.pbjsConfig?.schain,
    },
    mode: 'onChange',
  })

  const { pbjsConfig } = useWatch({ control })

  const { isSchainEnabled } = useWatch({ control: schainControl })

  const { fields, append, remove } = useFieldArray({ control, name: 'pbjsConfig.schain.config.nodes' })

  const [isFormSubmit, setFormSubmit] = useState<boolean>(false)

  const onSiteConfigSubmit: SubmitHandler<SiteConfig> = (formValue) => {
    setFormSubmit(true)
    onCreateSiteConfig({ ...formValue, pbjsConfig: { ...formValue.pbjsConfig, schain: isSchainEnabled ? formValue.pbjsConfig.schain : undefined } })
  }

  const restore = () => {
    reset(defaultValues)
    resetSchain({ isSchainEnabled: !!originalSiteConfig?.pbjsConfig?.schain })
  }

  useEffect(() => {
    reset(defaultValues)
    resetSchain({ isSchainEnabled: !!originalSiteConfig?.pbjsConfig?.schain })
  }, [originalSiteConfig])

  return (
    <SiteConfigForm id={'siteConfigForm'} onSubmit={handleSubmit(onSiteConfigSubmit)}>
      <Title>Local storage settings</Title>
      <FormToggle>
        <Toggle
          id={'pbjsConfig.allowLocalStorage'}
          value={pbjsConfig.allowLocalStorage}
          onClick={() => {
            setValue('pbjsConfig.allowLocalStorage', !pbjsConfig.allowLocalStorage, { shouldDirty: true })
          }}
        />
        <span>Allow use of cookies and/or local storage</span>
      </FormToggle>

      <Title>Key value targeting</Title>
      <FormToggle>
        <Toggle
          id={'pbjsConfig.targetingBehaviors.sendKVTargetings'}
          value={pbjsConfig.targetingBehaviors.sendKVTargetings}
          onClick={() => {
            setValue('pbjsConfig.targetingBehaviors.sendKVTargetings', !pbjsConfig.targetingBehaviors.sendKVTargetings, { shouldDirty: true })
            setValue('pbjsConfig.targetingBehaviors.biddersBlackList', [])
          }}
        />
        <span>Send Key Value targeting to all compatible bidders</span>
      </FormToggle>

      <KVMSection>
        <span>Don&apos;t send key value targeting to the following bidders:</span>
        <ChipListInput
          control={control}
          name={'pbjsConfig.targetingBehaviors.biddersBlackList'}
          label={'Excluded bidders'}
          disabled={!pbjsConfig.targetingBehaviors.sendKVTargetings}
          isSpaceAllowed
          selectableOptions={bidderAdapters.map((bidderAdapter) => {
            return { label: bidderAdapter.displayName, value: bidderAdapter.code }
          })}
        />
      </KVMSection>

      <Title>SupplyChain (schain) settings</Title>
      <FormToggle>
        <Toggle
          id={'isSchainEnabled'}
          value={!!isSchainEnabled}
          onClick={() => {
            setSchainValue('isSchainEnabled', !isSchainEnabled, { shouldDirty: true })
          }}
        />
        <LabelWithTooltip>
          <span>Use SupplyChain</span>
          <Tooltip
            title={
              'The SupplyChain Object enables buyers and intermediaries to see all parties who are selling or reselling ad inventory. The object works together with ads.txt / app-ads.txt and sellers.json to provide transparency into the ads ecosystem.'
            }
          >
            <Icon width={'20px'} name={'info'} />
          </Tooltip>
        </LabelWithTooltip>
      </FormToggle>
      {isSchainEnabled && (
        <SchainBloc>
          {fields.map((field, index) => (
            <SchainItem key={field.id}>
              <Input
                label={'asi (advertising system identifier)'}
                helper={'Domain name of the advertiser system'}
                control={control}
                name={`pbjsConfig.schain.config.nodes.${index}.asi`}
                rules={{
                  required: 'Required',
                }}
                error={errors.pbjsConfig && pbjsConfig.schain.config.nodes[index]?.asi === '' ? 'Required' : ''}
              />
              <Input
                label={'sid (seller ID)'}
                helper={'Used to identify the seller of the inventory'}
                control={control}
                name={`pbjsConfig.schain.config.nodes.${index}.sid`}
                rules={{
                  required: 'Required',
                }}
                error={errors.pbjsConfig && pbjsConfig.schain.config.nodes[index]?.sid === '' ? 'Required' : ''}
              />
              {index > 0 && (
                <Button
                  onClick={() => {
                    remove(index)
                  }}
                  variant={'tertiary'}
                  iconName={'delete'}
                />
              )}
            </SchainItem>
          ))}
          <Button variant={'secondary'} iconName={'add'} onClick={() => append(DEFAULT_SCHAIN_NODE, { shouldFocus: false })}>
            Add
          </Button>
        </SchainBloc>
      )}

      <Title>Transaction identifiers</Title>
      <FormToggle>
        <Toggle
          id={'pbjsConfig.enableTIDs'}
          value={pbjsConfig.enableTIDs}
          onClick={() => {
            setValue('pbjsConfig.enableTIDs', !pbjsConfig.enableTIDs, { shouldDirty: true })
          }}
        />
        <span>Use TIDs</span>
        <Help>Prebid generates unique IDs for auctions and ad units to help DSPs correlate requests.</Help>
      </FormToggle>

      <Actions>
        {(isDirty || isSchainDirty) && (
          <Button onClick={restore} iconName={'restore'} variant={'tertiary'}>
            Restore
          </Button>
        )}
        <Button disabled={(!isDirty && !isSchainDirty) || isFormSubmit} variant={'primary'} type={'submit'} iconSpin={isFormSubmit} iconName={isFormSubmit ? 'pubstack' : undefined}>
          Validate
        </Button>
      </Actions>
    </SiteConfigForm>
  )
}

export const PureAdStackPrebidSiteConfigForm = styled(_PureAdStackPrebidSiteConfigForm)``
