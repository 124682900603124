import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Color, Colors } from '~/assets/style/colors'
import { WithClassName } from '~/types/utils'
import Chip from './Chip'
import { IconName } from './Icon'

type FilterChipAppearance = 'include' | 'exclude' | 'disable'

type FilterChipConfig = {
  color: Color
  backgroundColor: Color
  borderColor: Color
  hoverBackgroundColor: Color
  hoverBorderColor: Color
}
const CONFIGURATION: { [key in FilterChipAppearance]: FilterChipConfig } = {
  include: {
    color: Colors.Jet,
    backgroundColor: Colors.Sora,
    borderColor: Colors.Sora,
    hoverBackgroundColor: Colors.Turquoise,
    hoverBorderColor: Colors.Turquoise,
  },
  exclude: {
    color: Colors.Jet,
    backgroundColor: Colors.Platinum,
    borderColor: Colors.Platinum,
    hoverBackgroundColor: Colors.Silver,
    hoverBorderColor: Colors.Silver,
  },
  disable: {
    color: Colors.Ash,
    backgroundColor: Colors.Ghost,
    borderColor: Colors.Platinum,
    hoverBackgroundColor: Colors.Ghost,
    hoverBorderColor: Colors.Platinum,
  },
}

export type FilterChipProps = WithClassName & {
  icon?: IconName
  closable?: boolean
  onClick?: () => void
  text: string
  appearance: FilterChipAppearance
}
const StyledChip = styled(Chip)<{ appearance: FilterChipAppearance }>`
  // TODO 2023-03-27 CFO NRA - we should refactor Chip component to include this behaviour
  color: ${({ appearance }) => CONFIGURATION[appearance].color};
  background-color: ${({ appearance }) => CONFIGURATION[appearance].backgroundColor};
  border-color: ${({ appearance }) => CONFIGURATION[appearance].borderColor};
  cursor: pointer;

  &:hover {
    background-color: ${({ appearance }) => CONFIGURATION[appearance].hoverBackgroundColor};
    border-color: ${({ appearance }) => CONFIGURATION[appearance].hoverBorderColor};
    text-decoration: none;
  }

  &:active {
    transform: scale(0.97);
  }
`

const _FilterChip: FunctionComponent<FilterChipProps> = ({ text, icon, appearance, closable = true, onClick, className }) => (
  <StyledChip className={className} appearance={appearance} iconLeft={icon} onClick={onClick} text={text} iconRight={closable ? 'close' : undefined} />
)

export const FilterChip = styled(_FilterChip)``
