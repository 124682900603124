import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { formatHumanReadable, sameDay, sameMonth } from '@pubstack/common/src/date'
import { DateTime, Interval } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ElevationLevel, Sizes, Transitions } from '~/assets/style/tokens'
import Button from './Button'
import SelectableItem from './SelectableItem'
import { IntervalWithLabel, Preset, days } from './datepicker.utils'

type DatepickerProps = {
  selectableInterval: Interval
  initialSelectedInterval: IntervalWithLabel
  maxNumberOfDayInTimerangeSelected: number
  today: DateTime
  onTimerangeSelected: (entry: IntervalWithLabel) => void
  disclaimer?: string
}

const Header = styled.div`
  padding: ${Sizes[16]};
  border-bottom: 1px solid ${Colors.Platinum};
  ${Fonts.P1};
  font-weight: bold;
  margin: 0;
`

const MonthDisplayed = styled.div<{ monthSelectedIndex: number }>`
  transform: ${(props) => 'translateX(calc(-326px * ' + props.monthSelectedIndex + '))'};
`

const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  background: white;
  border-radius: 6px;
`

const Container = styled.div`
  position: relative;
`

const Calendar = styled.div`
  padding: ${Sizes[8]};
  padding-bottom: 0;
  max-width: 640px;
  overflow: hidden;
  & > div {
    display: flex;
    transition: ${Transitions.default};
  }
  & > div > div {
    padding: ${Sizes[16]};
  }
`

const MonthLabel = styled.div`
  text-align: center;
  padding-bottom: ${Sizes[16]};
  grid-column: 1 / span 7;
  ${Fonts.P1};
  font-weight: bold;
  ${Fonts.colors.Jet};
`

const Month = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  ${Fonts.P2};
  ${Fonts.colors.Hurricane};
`

const Day = styled.div`
  text-align: center;
  width: 32px;
  ${Fonts.P2};
  ${Fonts.colors.Hurricane};
  font-weight: 700;
  padding-bottom: 5px;
`

const DayNumber = styled.button`
  --btn-color: ${Colors.Jet};
  border: none;
  background: transparent;
  outline: none;
  padding: 0 5px;
  & > div {
    --btn-color: ${Colors.Jet};
    --btn-bg: transparent;
    --btn-bg-hover: ${Colors.Topaze};
    --btn-bg-active: ${Colors.Topaze};
    height: 2rem;
    width: 2rem;
    border-radius: 2.5rem;
    background: var(--btn-bg);
    color: var(--btn-color);
    transition: ${Transitions.quick};
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
  }
  &:hover > div {
    background: var(--btn-bg-hover);
  }

  &:active > div {
    background: var(--btn-bg-active);
    transform: scale(0.95);
    --btn-color: ${Colors.White};
  }

  &:focus > div {
    box-shadow: 0 0 0 2px ${Colors.Topaze};
    --btn-color: ${Colors.White};
    --btn-bg: ${Colors.King};
    --btn-bg-hover: ${Colors.Cobalt};
    --btn-bg-active: ${Colors.Cobalt};
  }
  &:disabled > div {
    background: transparent;
    cursor: default;
    --btn-color: ${Colors.Silver};
    --btn-bg: transparent;
    --btn-bg-hover: transparent;
    --btn-bg-active: transparent;
  }
`

const ButtonsNavigate = styled.div`
  position: absolute;
  width: calc(100% - 2 * 1.25rem);
  display: flex;
  justify-content: space-between;
  padding: ${Sizes[16]} 1.25rem;
  ${ElevationLevel.low}
`

const Sidebar = styled.div`
  width: 167px;
  border-left: 1px solid ${Colors.Platinum};
  padding: ${Sizes[16]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`

const ApplyButton = styled(Button)`
  justify-content: center;
`

const AllDays = styled.div<{ type: { start: boolean; bound: boolean; end: boolean; range: boolean; inSelectedRange: boolean } }>`
  margin: 5px 0;
  ${(props) =>
    props.type.start &&
    css`
      background: linear-gradient(to right, ${Colors.Topaze}, ${Colors.Topaze}) no-repeat 21px 0;
    `}
  ${(props) =>
    props.type.end &&
    css`
      background: linear-gradient(to left, ${Colors.Topaze}, ${Colors.Topaze}) no-repeat -21px 0;
    `}


  ${(props) =>
    props.type.inSelectedRange &&
    css`
      & > ${DayNumber}:hover > div {
        --btn-bg-hover: radial-gradient(32px circle, ${Colors.Topaze} 50%, transparent 55%);
        --btn-color: ${Colors.Jet};
      }

      & > ${DayNumber} > div {
        --btn-bg: radial-gradient(22px circle, ${Colors.Topaze} 50%, transparent 55%);
      }
    `}
  
  ${(props) =>
    props.type.range &&
    css`
      background-color: ${Colors.Topaze};
      & > ${DayNumber}:hover > div {
        --btn-bg-hover: ${Colors.Cobalt};
        --btn-color: ${Colors.White};
      }
    `}
  
  ${(props) =>
    props.type.bound &&
    css`
      & > ${DayNumber} > div,
      & > ${DayNumber}:hover > div {
        --btn-color: ${Colors.White};
        --btn-bg: ${Colors.King};
        --btn-bg-hover: ${Colors.Cobalt};
        --btn-bg-active: ${Colors.King};
      }
    `}
`
const FirstDayOfMonth = styled(AllDays)<{ month: DateTime }>`
  grid-column: ${(props) => props.month.startOf('month').weekday || 7};
`

const Footer = styled.div`
  padding: ${Sizes[16]};
  padding-top: 0;
  ${Fonts.P2};
  ${Fonts.colors.Jet};
  font-style: italic;
`

const Presets = styled.div`
  padding: ${Sizes[12]} ${Sizes[16]};

  > * ~ * {
    margin-top: 4px;
  }
`

const Datepicker: FunctionComponent<DatepickerProps> = ({ initialSelectedInterval, maxNumberOfDayInTimerangeSelected, onTimerangeSelected, selectableInterval, today, disclaimer }) => {
  const [label, setLabel] = useState<string>('custom')
  const [restrictedSelection, setRestrictedSelection] = useState<boolean>(false)
  const [selector, setSelector] = useState<{ even: DateTime; odd: DateTime; next: 'even' | 'odd' }>({ even: today, odd: today, next: 'even' })
  const [visibleMonths, setVisibleMonths] = useState<DateTime[]>([selectableInterval.end.minus({ months: 1 }).startOf('month'), selectableInterval.end.startOf('month')])
  const [preset, setPreset] = useState<Preset[]>([
    {
      label: 'Last 7 days',
      selector: { even: today.minus({ days: 7 }), odd: today.minus({ days: 1 }), next: 'even' },
      action: function action() {
        setRestrictedSelection(false)
        setSelector(this.selector)
      },
      displayable: () => selectableInterval.contains(today.minus({ days: 7 })) && (maxNumberOfDayInTimerangeSelected ?? 7) >= 7,
    },
    {
      label: 'Last week',
      selector: { even: today.minus({ weeks: 1 }).startOf('week'), odd: today.minus({ weeks: 1 }).endOf('week'), next: 'even' },
      action: function action() {
        setRestrictedSelection(false)
        setSelector(this.selector)
      },
      displayable: () => selectableInterval.contains(today.minus({ weeks: 1 }).startOf('week')) && (maxNumberOfDayInTimerangeSelected ?? 7) >= 7,
    },
    {
      label: 'Last 14 days',
      selector: { even: today.minus({ weeks: 2 }), odd: today.minus({ days: 1 }), next: 'even' },
      action: function action() {
        setRestrictedSelection(false)
        setSelector(this.selector)
      },
      displayable: () => selectableInterval.contains(today.minus({ days: 14 })) && (maxNumberOfDayInTimerangeSelected ?? 15) >= 15,
    },
    {
      label: 'Last month',
      selector: { even: today.minus({ months: 1 }).startOf('month'), odd: today.minus({ months: 1 }).endOf('month'), next: 'even' },
      action: function action() {
        setRestrictedSelection(false)
        setSelector(this.selector)
      },
      displayable: () => selectableInterval.contains(today.minus({ months: 1 }).startOf('month')) && (maxNumberOfDayInTimerangeSelected ?? 31) >= 31,
    },
  ])

  useEffect(() => {
    updatedSelectableInterval()
    let newPresets = [...preset]
    if (today !== undefined) {
      const yesterday = today.minus({ days: 1 }).startOf('day')
      if (selectableInterval.contains(yesterday)) {
        newPresets = [
          {
            label: 'Yesterday',
            selector: { even: yesterday, odd: yesterday, next: 'even' },
            action: function action() {
              setRestrictedSelection(false)
              // The end interval is exclusive, so yesterday is actually the day before the day before end.
              setSelector(this.selector)
            },
            displayable: () => (maxNumberOfDayInTimerangeSelected ?? 1) >= 1,
          },
          ...newPresets,
        ]
      }
      if (selectableInterval.contains(today)) {
        newPresets = [
          {
            label: 'Today',
            selector: { even: today, odd: today, next: 'even' },
            action: function action() {
              if (today) {
                setRestrictedSelection(false)
                // The end interval is exclusive, so yesterday is actually the day before the day before end.
                setSelector(this.selector)
              }
            },
            displayable: () => (maxNumberOfDayInTimerangeSelected ?? 1) >= 1,
          },
          ...newPresets,
        ]
      }
    }
    if (maxNumberOfDayInTimerangeSelected) {
      newPresets = [
        ...newPresets,
        {
          label: `Last ${maxNumberOfDayInTimerangeSelected} days`,
          selector: { even: today.minus({ days: maxNumberOfDayInTimerangeSelected }), odd: today.minus({ days: 1 }), next: 'even' },
          action: function action() {
            setRestrictedSelection(false)
            setSelector(this.selector)
          },
          displayable: () =>
            maxNumberOfDayInTimerangeSelected !== 1 && // already have "Yesterday"
            maxNumberOfDayInTimerangeSelected !== 7 && // already have "Last 7 days"
            maxNumberOfDayInTimerangeSelected !== 15 && // already have "Last 15 days"
            selectableInterval.contains(selectableInterval.end.minus({ days: maxNumberOfDayInTimerangeSelected })),
        },
      ]
    }

    setPreset(newPresets)
  }, [])

  const updatedSelectableInterval = (): void => {
    if (initialSelectedInterval) {
      setLabel(initialSelectedInterval.label)
      setSelector({ ...selector, even: initialSelectedInterval.interval.start, odd: initialSelectedInterval.interval.end.minus({ days: 1 }) })
    } else {
      setSelector({ ...selector, even: selectableInterval.end.minus({ days: 1 }), odd: selectableInterval.end.minus({ days: 1 }) })
    }

    if (sameMonth(selectableInterval.start, selectableInterval.end)) {
      setVisibleMonths([selectableInterval.start.startOf('month')])
    } else {
      if (initialSelectedInterval) {
        setVisibleMonths([initialSelectedInterval.interval.end.minus({ months: 1 }).startOf('month'), initialSelectedInterval.interval.end.startOf('month')])
      } else {
        setVisibleMonths([selectableInterval.end.minus({ months: 1 }).startOf('month'), selectableInterval.end.startOf('month')])
      }
    }
  }

  const selectorToInterval = (): Interval => {
    const start = selector.even > selector.odd ? selector.odd : selector.even
    const end = selector.even < selector.odd ? selector.odd : selector.even
    return Interval.fromDateTimes(start, end)
  }

  const getMonths = (): DateTime[] => {
    const months: DateTime[] = []
    let currentMonth = selectableInterval.start.startOf('month')
    months.push(currentMonth)
    while (!sameMonth(currentMonth, selectableInterval.end)) {
      currentMonth = currentMonth.plus({ months: 1 })
      months.push(currentMonth)
    }
    return months
  }

  const months = getMonths()
  const monthSelectedIndex = months.findIndex((month) => sameMonth(month, visibleMonths[0]))
  const canISeePreviousMonth = !sameMonth(visibleMonths[0], selectableInterval.start) && monthSelectedIndex !== -1
  const canISeeNextMonth = !sameMonth(visibleMonths[visibleMonths.length - 1], selectableInterval.end) && monthSelectedIndex !== months.length - 1
  const fromAsDate = formatHumanReadable(selectorToInterval().start)
  const toAsDate = formatHumanReadable(selectorToInterval().end)

  const displayNextMonth = (): void => {
    setVisibleMonths(visibleMonths.map((month) => month.plus({ months: 1 })))
  }

  const displayPreviousMonth = (): void => {
    setVisibleMonths(visibleMonths.map((month) => month.minus({ months: 1 })))
  }

  const getType = (date: DateTime): { start: boolean; bound: boolean; end: boolean; range: boolean; inSelectedRange: boolean } => {
    const interval = selectorToInterval()
    const nodeType = { start: false, bound: false, end: false, range: false, inSelectedRange: false }
    if (sameDay(date, interval.start)) {
      nodeType.bound = true
      nodeType.start = !sameDay(selector.even, selector.odd)
    }

    if (sameDay(date, interval.end)) {
      nodeType.bound = true
      nodeType.end = !sameDay(selector.even, selector.odd)
    }
    if (interval.contains(date) && !(sameDay(date, interval.start) || sameDay(date, interval.end))) {
      nodeType.range = true
    }
    if (maxNumberOfDayInTimerangeSelected && restrictedSelection && isInSelectableRange(date, selector.even, maxNumberOfDayInTimerangeSelected) && isSelectable(date)) {
      nodeType.inSelectedRange = true
    }
    return nodeType
  }

  const asMonth = (month: DateTime): string => {
    return month.toFormat('MMMM yy')
  }

  const dayOfMonth = (date: DateTime): string => {
    return date.toFormat('d')
  }

  const daysInMonth = (date: DateTime): DateTime[] => {
    const days: DateTime[] = []
    const origin = date.startOf('month')
    for (let i = 1; i < origin.daysInMonth; i += 1) {
      days.push(origin.plus({ days: i }).startOf('day'))
    }
    return days
  }

  const isSelectable = (date: DateTime): boolean => {
    return selectableInterval.contains(date)
  }

  const isInSelectableRange = (day: DateTime, referenceDay: DateTime, range: number): boolean => {
    const min = referenceDay.minus({ days: range - 1 })
    const max = referenceDay.plus({ days: range })
    const interval = Interval.fromDateTimes(min, max)
    return interval.contains(day)
  }

  const apply = (): void => {
    if (selector.even <= selector.odd) {
      onTimerangeSelected({ label: label, interval: Interval.fromDateTimes(selector.even, selector.odd) })
    } else {
      onTimerangeSelected({ label: label, interval: Interval.fromDateTimes(selector.odd, selector.even) })
    }
  }

  const select = (date: DateTime) => {
    setLabel('custom')
    if (maxNumberOfDayInTimerangeSelected) {
      if (selector.next === 'even') {
        setSelector({ even: date, odd: date, next: 'odd' })
        setRestrictedSelection(true)
      } else {
        if (isInSelectableRange(date, selector.even, maxNumberOfDayInTimerangeSelected)) {
          setSelector({ ...selector, odd: date, next: 'even' })
          setRestrictedSelection(false)
        } else {
          setSelector({ even: date, odd: date, next: 'odd' })
        }
      }
    } else {
      const selectorNext = selector.next === 'odd' ? 'even' : 'odd'
      setSelector({ ...selector, [selector.next]: date, next: selectorNext })
    }
  }
  const selectPreset = (p: Preset): void => {
    setLabel(p.label)
    p.action()
  }

  useEffect(() => {
    if (!visibleMonths.some((m) => sameMonth(m, selector.odd.startOf('month')))) {
      const diff = selectableInterval.end.diff(selector.even, ['months', 'days']).toObject()
      if (diff.months === 0) {
        setVisibleMonths([selector.odd.minus({ months: 1 }), selectableInterval.end.startOf('month')])
      } else {
        setVisibleMonths([selector.even.startOf('month'), selector.odd.plus({ months: 1 }).startOf('month')])
      }
    }
  }, [selector])

  return (
    <CalendarContainer>
      <div>
        <Header>{fromAsDate !== toAsDate ? fromAsDate + ' to ' + toAsDate : toAsDate}</Header>
        <Container>
          <ButtonsNavigate>
            <div>
              {canISeePreviousMonth && (
                <Button
                  variant={'tertiary'}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    displayPreviousMonth()
                  }}
                  iconName={'chevron_left'}
                />
              )}
            </div>
            <div>
              {canISeeNextMonth && (
                <Button
                  variant={'tertiary'}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    displayNextMonth()
                  }}
                  iconName={'chevron_right'}
                />
              )}
            </div>
          </ButtonsNavigate>
          <Calendar>
            <MonthDisplayed monthSelectedIndex={monthSelectedIndex === -1 ? 0 : monthSelectedIndex}>
              {months.map((month) => {
                return (
                  <div key={month.toMillis()}>
                    <MonthLabel>
                      <span>{asMonth(month)}</span>
                    </MonthLabel>
                    <Month>
                      {days.map((day, index) => (
                        <Day key={day + index}>{day}</Day>
                      ))}
                      <FirstDayOfMonth type={getType(month)} month={month}>
                        <DayNumber disabled={!isSelectable(month)} onClick={() => select(month)}>
                          <div>1</div>
                        </DayNumber>
                      </FirstDayOfMonth>
                      {daysInMonth(month).map((day) => (
                        <AllDays key={day.toISO()} type={getType(day)}>
                          <DayNumber disabled={!isSelectable(day)} onClick={() => select(day)}>
                            <div>{dayOfMonth(day)}</div>
                          </DayNumber>
                        </AllDays>
                      ))}
                    </Month>
                  </div>
                )
              })}
            </MonthDisplayed>
          </Calendar>
        </Container>
        <Footer>
          <p>{disclaimer}</p>
        </Footer>
      </div>
      <Sidebar>
        <div>
          <Presets>
            {preset.map(
              (p) =>
                p.displayable() && (
                  <SelectableItem selected={sameDay(selector.even, p.selector.even) && sameDay(selector.odd, p.selector.odd)} key={p.label} text={p.label} onClick={() => selectPreset(p)} />
                )
            )}
          </Presets>
        </div>
        <ApplyButton variant={'primary'} onClick={apply}>
          Apply
        </ApplyButton>
      </Sidebar>
    </CalendarContainer>
  )
}

export default Datepicker
