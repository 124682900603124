import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { CodeSnippet } from '~/components/CodeSnippet'
import { FoldingContent } from '~/components/FoldingContent'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

const Card = styled.div`
  background-color: ${Colors.White};
  border-radius: 4px;
  padding: 20px;
  flex: 1;
`
const Text = styled.div`
  ${Fonts.P1};
  ${Fonts.colors.Jet};
`
const Strong = styled(Text)`
  font-weight: 500;
`

const PreparationCard = styled(Card)`
  flex: 1 1 360px;
  width: 360px;
  min-width: 360px;
`
const ScriptCard = styled(Card)`
  flex: 1000 1 640px;
  min-width: 640px;
`

const ScriptsBlock = styled.div`
  margin-top: 32px;
  ${Text} {
    margin-bottom: 12px;
  }
`

const PreparationTitle = styled.div`
  ${Fonts.H2};
  ${Fonts.colors.Jet};
  margin-bottom: 20px;
`
const PreparationBlock = styled.div`
  ${Fonts.colors.Hurricane};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`
const PreparationBlockTitle = styled(Strong)`
  margin-bottom: 4px;
`

const AnalyticsScripts = styled.div`
  padding: 24px 0 0;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  ${Link} {
    ${Fonts.P2}
  }
`

type PureSiteConnectToPubstackProps = WithClassName & {
  siteId: string | number
  onUseAnalyticsScriptsClick: () => void
}
const _PureSiteConnectToPubstack: FunctionComponent<PureSiteConnectToPubstackProps> = ({ className, siteId, onUseAnalyticsScriptsClick }) => (
  <Wrapper className={className}>
    <ScriptCard>
      <Text>Add the following script to the &lt;head&gt; section of each page.</Text>
      <Strong>For better performances, please load Pubstack&apos;s script before any other.</Strong>

      <ScriptsBlock>
        <Text>Use one of the following option to connect your site to Pubstack:</Text>
        <FoldingContent title={'HTML - script tag'} isFolding>
          <CodeSnippet contentText={`<script async src="https://boot.pbstck.com/v1/adm/${siteId}"></script>`} />
        </FoldingContent>
        <FoldingContent title={'Javascript - inline code'} isFolding>
          <CodeSnippet
            contentText={`var s = document.createElement('script');
s.type = 'text/javascript';
s.src = 'https://boot.pbstck.com/v1/adm/${siteId}';
s.async = true;
(document.head || document.documentElement).appendChild(s);`}
          />
        </FoldingContent>
        <AnalyticsScripts>
          Want to use only the analytic features and keep your monetization settings on a page ?&nbsp;
          <Link onClick={onUseAnalyticsScriptsClick} label={'Use this script'} />
        </AnalyticsScripts>
      </ScriptsBlock>
    </ScriptCard>
    <PreparationCard>
      <PreparationTitle>Site preparation</PreparationTitle>
      <PreparationBlock>
        <PreparationBlockTitle>My site is already monetized.</PreparationBlockTitle>
        <div>Make sure to remove every existing monetization from the pages. This includes GAM, Amazon, Prebid.</div>
      </PreparationBlock>
      <PreparationBlock>
        <PreparationBlockTitle>I already use the Analytic module from Pubstack.</PreparationBlockTitle>
        <div>Remove the Analytic tag from your site and replace it with this one. Don&apos;t worry, this tag includes both Analytic and Ad management features.</div>
      </PreparationBlock>
    </PreparationCard>
  </Wrapper>
)
export const PureSiteConnectToPubstack = styled(_PureSiteConnectToPubstack)``
