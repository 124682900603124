import { Status } from '~/types/utils'

type HexColorString = `#${string}`
export type Color = HexColorString

/**
 * @param red a value between 0 and 255
 * @param green a value between 0 and 255
 * @param blue a value between 0 and 255
 */
type rgbColor = { red: number; green: number; blue: number }

/**
 * @param hue  a value between 0 and 360
 * @param saturation a value between 0 and 100
 * @param lightness a value between 0 and 100
 */
type hslColor = { hue: number; saturation: number; lightness: number }
export const ColorUtil = {
  ACCEPTABLE_CONTRAST_RATIO: 4.5, //as per WCAG2.0 recommandation

  hexToRgb: (hex: string): rgbColor => {
    const intValue = parseInt(hex.substring(1), 16)
    const red = (intValue >> 16) & 255
    const green = (intValue >> 8) & 255
    const blue = intValue & 255

    return { red, green, blue }
  },

  /**
   * conversion from hsl to hex value by converting to rgb first
   */
  hslToHex: (color: hslColor): HexColorString => {
    return ColorUtil.rgbToHex(ColorUtil.hslToRgb(color))
  },

  getHexContrastRatio: (color1: string, color2: string): number => {
    const rgb1 = ColorUtil.hexToRgb(color1)
    const rgb2 = ColorUtil.hexToRgb(color2)
    return ColorUtil.getRgbContrastRatio(rgb1, rgb2)
  },

  hexOpacity: (hex: Color, opacity: number): HexColorString => {
    return `${hex}${Math.round(opacity * 255)
      .toString(16)
      .padStart(2, '0')}`
  },

  getRgbContrastRatio: (color1: rgbColor, color2: rgbColor): number => {
    const L1 = ColorUtil.getLuminance(color1)
    const L2 = ColorUtil.getLuminance(color2)
    const brightest = Math.max(L1, L2)
    const darkest = Math.min(L1, L2)
    return (brightest + 0.05) / (darkest + 0.05)
  },

  rgbToHex: (color: rgbColor): HexColorString => {
    const red = color.red.toString(16).substring(0, 2).split('.').pop()?.padStart(2, '0')
    const green = color.green.toString(16).substring(0, 2).split('.').pop()?.padStart(2, '0')
    const blue = color.blue.toString(16).substring(0, 2).split('.').pop()?.padStart(2, '0')
    return `#${red}${green}${blue}`
  },

  /**
   * conversion from rgb to hsl value
   * https://en.wikipedia.org/wiki/HSL_and_HSV#HSL_to_RGB_alternative
   */
  rgbToHsl: ({ red, green, blue }: rgbColor): hslColor => {
    const [r, g, b] = [red / 255, green / 255, blue / 255]
    const xmax = Math.max(r, g, b)
    const xmin = Math.min(r, g, b)
    const chroma = xmax - xmin

    // calculating lightness
    const lightness = (xmax + xmin) / 2

    //calculating hue
    let hue = 0
    if (chroma === 0) {
      hue = 0
    } else if (xmax === r) {
      hue = (g - b) / chroma
    } else if (xmax === g) {
      hue = (b - r) / chroma + 2
    } else {
      hue = (r - g) / chroma + 4
    }

    hue = Math.round(hue * 60)
    if (hue < 0) {
      hue += 360
    }

    // calculating saturation
    const saturation = lightness === 0 || lightness === 1 ? 0 : chroma / (1 - Math.abs(2 * lightness - 1))

    return { hue, saturation: Math.floor(saturation * 100), lightness: Math.floor(lightness * 100) }
  },

  /**
   * conversion from hsl to rgb value
   * https://en.wikipedia.org/wiki/HSL_and_HSV#HSL_to_RGB_alternative
   */
  hslToRgb: ({ hue, saturation, lightness }: hslColor): rgbColor => {
    const { s, l } = { s: saturation / 100, l: lightness / 100 }
    const a = s * Math.min(l, 1 - l)
    const f = (n: number, k = (n + hue / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return { red: f(0) * 255, green: f(8) * 255, blue: f(4) * 255 }
  },

  /**
   * returns srgb colorspace luminance value as calculated per WCAG's recommandations, to use for contrast detection purposes
   * https://www.w3.org/TR/WCAG20-TECHS/G17.html
   * */
  getLuminance: ({ red, green, blue }: rgbColor): number => {
    const uicolors = [red / 255, green / 255, blue / 255]
    const c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92
      }
      return Math.pow((col + 0.055) / 1.055, 2.4)
    })
    return 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  },

  varyColorLightness: (hex: Color, value: number): HexColorString => {
    const hslValue = ColorUtil.rgbToHsl(ColorUtil.hexToRgb(hex))
    const lightness = Math.min(Math.max(20, (hslValue.lightness + value) % 100), 90)
    return ColorUtil.hslToHex({ ...hslValue, lightness })
  },
}

export const Colors = {
  Ash: '#767676',
  King: '#0030CF',
  Cobalt: '#005be4',
  Navy: '#000eb2',
  Topaze: '#e5effc',
  Hurricane: '#4c5774',
  SlateGrey: '#6a7590',
  Ghost: '#f9f8ff',
  Jet: '#363636',
  Silver: '#c4c4c4',
  Platinum: '#e5e5e5',
  White: '#ffffff',
  Alert: '#cd0606',
  Cherry: '#a70000',
  Warning: '#ff7a00',
  Success: '#1ba290',
  Pool: '#28bfb6',
  Ming: '#4359cd',
  Pumpkin: '#f6a341',
  Milka: '#6f43cd',
  Yellow: '#FADA5E',
  Rose: '#ffacfc',
  NeonPink: '#ff45a9',
  Orchid: '#de73ef',
  Salmon: '#ff827a',
  Forest: '#0b7c74',
  SkyBlue: '#4FA9DF',
  Violet: '#AF4BCE',
  Sun: '#FFC30B',
  Frog: '#5DBB63',
  Turquoise: '#30DCDC',
  Lilac: '#B5BAFF',
  Petrol: '#277EAE',
  Mint: '#D9F2F0',
  Mew: '#FAE6E6',
  Kaiminus: '#28A0B3',
  Sora: '#A8F0F0',
  Lava: '#ED6B5B',
  Cloud: '#F3F3F3',
  Mauve: '#EDD7F4',
  Greu: '#D5EAF6',
} as const

export type PaletteColor = typeof Colors

export const DataColors = {
  Rose: Colors.Rose,
  Orchid: Colors.Orchid,
  NeonPink: Colors.NeonPink,
  Violet: Colors.Violet,
  Milka: Colors.Milka,
  Turquoise: Colors.Turquoise,
  Pool: Colors.Pool,
  Frog: Colors.Frog,
  Forest: Colors.Forest,
  Ming: Colors.Ming,
  Sun: Colors.Sun,
  Pumpkin: Colors.Pumpkin,
  Salmon: Colors.Salmon,
  Lilac: Colors.Lilac,
  Silver: Colors.Silver,
  Alert: Colors.Alert,
  Hurricane: Colors.Hurricane,
  Yellow: Colors.Yellow,
  Petrol: Colors.Petrol,
  SkyBlue: Colors.SkyBlue,
  Kaiminus: Colors.Kaiminus,
  Lava: Colors.Lava,
  SlateGrey: Colors.SlateGrey,
}

export const StatusColor: { [key in Status]: (typeof Colors)[keyof typeof Colors] } = {
  success: Colors.Success,
  alert: Colors.Alert,
  warning: Colors.Warning,
}

export const StorybookColorPresets = Object.keys(Colors).map((colorname) => ({ title: colorname, color: Colors[colorname as keyof typeof Colors] }))
