import styled from '@emotion/styled'
import { AbTestConfiguration } from '@pubstack/common/src/abTest'
import { Scope } from '@pubstack/common/src/scope'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { CodeSnippet } from '~/components/CodeSnippet'
import { ContentCard } from '~/components/ContentCard'
import { Skeleton } from '~/components/Skeleton'
import { WithClassName } from '~/types/utils'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'

const ViewWrapper = styled.form`
  max-width: 1000px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CardTitle = styled.h2`
  ${Fonts.colors.Jet}
  ${Fonts.H1}
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.Platinum};
`

const Card = styled(ContentCard)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CloseWrapper = styled.div`
  text-align: right;
  ${Button} {
    margin-left: 15px;
  }
`

const CardHeaderWrapper = styled.div`
  ${Fonts.colors.Jet}
  ${Fonts.P1}
`

const SnippetHeaderWrapper = styled.div`
  ${Fonts.colors.SlateGrey}
  ${Fonts.P2}
`

const ExampleWrapper = styled(SnippetHeaderWrapper)`
  margin-left: 12px;
  padding-top: 1px;
`

const ZeroPaddingDiv = styled.div`
  div {
    padding: 0;
  }
`

const LongSnippetWrapper = styled(ZeroPaddingDiv)`
  code {
    width: 700px;
    padding: 12px;
  }
`

const ShortSnippetWrapper = styled(ZeroPaddingDiv)`
  code {
    width: 260px;
    padding: 12px;
  }
`

type PureViewAbTestPage = WithClassName & {
  abTest: AbTestConfiguration
  isLoading: boolean
  onClose: () => void
  scope: Scope
  hasAdx: boolean
}

const _PureViewAbTestPage: FunctionComponent<PureViewAbTestPage> = ({ className, abTest, isLoading, onClose, scope, hasAdx }) => {
  return (
    <div className={className}>
      {isLoading ? (
        <Skeleton bigger width={'200px'} />
      ) : (
        <ViewWrapper>
          {scope.pbjs && (
            <Card color={SETTINGS_NAV_CONFIG['abTest'].color}>
              <CardTitle>A/B test in Prebid</CardTitle>
              <CardContent>
                <CardHeaderWrapper>To monitor A/B tests from Prebid, add the following meta to the targeted pages header:</CardHeaderWrapper>
                {abTest.variants.map((variant, index) => (
                  <LongSnippetWrapper key={`Prebid${index}`}>
                    <CodeSnippet className={className} contentText={`<meta name="pbstck_context:pbstck_ab_test" content="${variant.variant}" />`} />
                  </LongSnippetWrapper>
                ))}
              </CardContent>
            </Card>
          )}
          {hasAdx && (
            <Card color={SETTINGS_NAV_CONFIG['abTest'].color}>
              <CardTitle>A/B test in GAM</CardTitle>
              <CardContent>
                <CardHeaderWrapper>To monitor A/B tests from GAM, set the following key-value as custom dim</CardHeaderWrapper>
                <div>
                  <SnippetHeaderWrapper>Key name</SnippetHeaderWrapper>
                  <ShortSnippetWrapper>
                    <CodeSnippet key={'key name'} contentText={`pbstck_ab_test`} />
                  </ShortSnippetWrapper>
                </div>
                {abTest.variants.map((variant, index) => (
                  <div key={`GAM${index}`}>
                    <SnippetHeaderWrapper>Value</SnippetHeaderWrapper>
                    <ShortSnippetWrapper>
                      <CodeSnippet contentText={`${variant.variant}`} />
                    </ShortSnippetWrapper>
                  </div>
                ))}
                <CardHeaderWrapper>Use the setTargeting function:</CardHeaderWrapper>
                <div>
                  <LongSnippetWrapper>
                    <CodeSnippet key={'targeting function'} contentText={`googletag.pubads().setTargeting('pbstck_ab_test','<value>')`} />
                  </LongSnippetWrapper>
                  <ExampleWrapper>Example : googletag.pubads().setTargeting(&apos;pbstck_ab_test&apos;,&apos;variant_a&apos;)</ExampleWrapper>
                </div>
              </CardContent>
            </Card>
          )}
          <CloseWrapper>
            <Button onClick={onClose}>Close</Button>
          </CloseWrapper>
        </ViewWrapper>
      )}
    </div>
  )
}

export const PureViewAbTestPage = styled(_PureViewAbTestPage)``
