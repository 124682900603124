import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { useOutletContext } from 'react-router-dom'
import { WithClassName } from '~/types/utils'
import { PureSiteConnectToPubstack } from './PureSiteConnectToPubstack'
import { SitePageContext } from './SitePage'

type SiteConnectToPubstackProps = WithClassName & {
  //
}
const _SiteConnectToPubstack: FunctionComponent<SiteConnectToPubstackProps> = ({ className }) => {
  const { currentSite, onUseAnalyticsScriptsClick } = useOutletContext<SitePageContext>()
  return <PureSiteConnectToPubstack className={className} siteId={currentSite.id} onUseAnalyticsScriptsClick={onUseAnalyticsScriptsClick} />
}
export const SiteConnectToPubstack = styled(_SiteConnectToPubstack)``
