import styled from '@emotion/styled'
import { pluralize } from '@pubstack/common/src/localization'
import { GroupOverlap } from '@pubstack/common/src/refresh'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'

const OverlapList = styled.ul`
  & li {
    & span {
      font-style: italic;
    }
    &::marker {
      color: ${Colors.King};
    }
  }
`

type PureRefreshGroupOverlapModalProps = { overlap: GroupOverlap; onSaveAndDeactivate: () => unknown }
export const PureRefreshGroupOverlapModal: FunctionComponent<PureRefreshGroupOverlapModalProps> = ({ overlap, onSaveAndDeactivate }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Overlapping issues</Modal.Title>

      <Modal.Body>
        Your inventory selection is overlapping with the following active refresh {pluralize('group', overlap.groups.length)}:
        <OverlapList>
          {overlap?.groups.map((g, i) => (
            <li key={i}>
              <span>{g.name}</span>
            </li>
          ))}
        </OverlapList>
        The following data in your group is <span>overlapping</span>:
        <OverlapList>
          <li>
            Sites: <span>{overlap.tagIds.join('; ')}</span>
          </li>
          <li>
            Adunits: <span>{overlap.adUnits.join('; ')}</span>
          </li>
        </OverlapList>
        On the following {pluralize('device', overlap.devices.length)}:
        <OverlapList>
          <li>
            <span>{overlap.devices.join('; ')}</span>
          </li>
        </OverlapList>
        <p>You can either modify the current selection, or save it and come back later.</p>
        <p>
          If you save right now, refresh will <strong>not be activated</strong> on this group.
        </p>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={onSaveAndDeactivate}>
          Save & deactivate
        </Button>
        <Button onClick={close}>Continue editing</Button>
      </Modal.Actions>
    </Modal.Content>
  )
}
