import styled from '@emotion/styled'
import { getQueryLink } from '@pubstack/common/src/alert-utils'
import { Alert } from '@pubstack/common/src/analytics/check'
import { formatToDateWithoutTime, parseFromDateWithoutTime } from '@pubstack/common/src/date'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useAlerts } from '~/api/api.hook'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureAlertsPage } from './PureAlertsPage'
import { useAcknowledgeAlerts } from './hooks/useAlerts'

type AlertRedirectToAnalyticsRouteParams = {
  from: string
  to: string
  mapping?: string
  ctx?: string
}

const _AlertsPage: FunctionComponent<WithClassName> = ({ className }) => {
  const navigate = useNavigate()
  const currencySymbol = useScopeCurrency()
  const [context, setContext] = useRecoilState(contextState)
  const { all } = useAlerts(null)
  const [alerts, setAlerts] = useState<Alert[]>([])
  const acknowledge = useAcknowledgeAlerts()

  const redirect = (alert: Alert) => {
    // if bidder redirect on the bidder dashboard
    // otherwise redirect on overview dashboard
    let pathname: string
    let params: AlertRedirectToAnalyticsRouteParams
    if (alert.check.split('.')[0] === 'bidder') {
      pathname = `/analytics/bidders/${alert.keys.bidder?.label ?? ''}`
      params = {
        from: formatToDateWithoutTime(DateTime.fromSeconds(alert.time).minus({ days: 4 })),
        to: formatToDateWithoutTime(DateTime.fromSeconds(alert.time).minus({ days: 3 })),
        mapping: btoa(
          JSON.stringify({
            bidder: alert.keys.bidder?.value,
          })
        ),
        ...getQueryLink(alert, btoa),
      }
    } else {
      pathname = '/analytics/overview'
      params = {
        from: formatToDateWithoutTime(DateTime.fromSeconds(alert.time).minus({ days: 3 })),
        to: formatToDateWithoutTime(DateTime.fromSeconds(alert.time).minus({ days: 1 })),
        ...getQueryLink(alert, btoa),
      }
    }

    //TODO cfo 2022-08-03 - find a better solution to sync url to context globally
    setContext({
      timeRange: {
        from: parseFromDateWithoutTime(params.from),
        to: parseFromDateWithoutTime(params.to),
        tz: context.timeRange.tz,
      },
      filters: params['ctx'] ? JSON.parse(decodeURIComponent(atob(params['ctx']))) : {},
    })
    navigate({
      pathname,
      search: `?${createSearchParams(params)}`,
    })
  }

  const loadAlerts = async () => {
    const result = await all.post({})
    setAlerts(result || [])
    acknowledge()
  }

  useEffect(() => {
    loadAlerts()
  }, [])

  return (
    <PureAlertsPage
      className={className}
      onManageClick={() => navigate('/settings/profile')}
      loading={all.loading}
      error={!!all.error}
      data={alerts}
      currencySymbol={currencySymbol}
      onRowClick={redirect}
      onRefreshClick={loadAlerts}
    />
  )
}

export const AlertsPage = styled(_AlertsPage)``
export default AlertsPage
