import { GamIntegration, GamNetworkConfig } from '@pubstack/common/src/gam/adx'
import { AdminScope } from '@pubstack/common/src/scope'
import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { useCreateServiceAccount, useGamLaunchChecks, useGamNetwork } from '~/api/admin-api.hook'
import { PureAdminScopeFeaturesGamIntegration } from './PureAdminScopeFeaturesGamIntegration'

type AdminScopeFeaturesGamIntegrationProps = {
  scope: AdminScope
  currentIntegration: GamIntegration
  loadGamIntegration: () => Promise<void>
  setCurrentIntegration: Dispatch<SetStateAction<GamIntegration>>
  gamIntegrationLoading: boolean
}
export const AdminScopeFeaturesGamIntegration: FunctionComponent<AdminScopeFeaturesGamIntegrationProps> = ({
  scope,
  currentIntegration,
  setCurrentIntegration,
  loadGamIntegration,
  gamIntegrationLoading,
}) => {
  const { byId: serviceAccountById } = useCreateServiceAccount(null, scope.id ?? '')

  const { byId: gamNetworkById } = useGamNetwork(null, scope.id ?? '')

  const { byId: gamLaunchChecksById } = useGamLaunchChecks(null, scope.id ?? '')

  const launchGamChecks = async (networkCode: number) => {
    if (gamLaunchChecksById.loading) {
      gamLaunchChecksById.abort()
    }
    const network = await gamLaunchChecksById.post(networkCode)
    const updatedNetworks = currentIntegration.gamNetworks ?? []

    //TODO tmu nra 2022-09-26 get rid of validations behaving this way, normalize it accross backend/frontend
    updatedNetworks.splice(currentIntegration.gamNetworks?.findIndex((n) => n.networkCode === networkCode.toString()) ?? -1, 1, network)
    setCurrentIntegration({
      ...currentIntegration,
      gamNetworks: updatedNetworks,
    })
  }

  const createServiceAccount = async () => {
    if (serviceAccountById.loading) {
      serviceAccountById.abort()
    }
    await serviceAccountById.post(scope.name)
    loadGamIntegration()
  }

  const createNetwork = async (network: GamNetworkConfig) => {
    if (scope) {
      if (gamNetworkById.loading) {
        gamNetworkById.abort()
      }
      const newNetwork = await gamNetworkById.post(scope.name, network.networkCode)
      setCurrentIntegration({
        ...currentIntegration,
        gamNetworks: [...(currentIntegration.gamNetworks ?? []), newNetwork],
      })
    }
  }

  const updateNetwork = async (network: GamNetworkConfig) => {
    if (scope) {
      if (gamNetworkById.loading) {
        gamNetworkById.abort()
      }

      const updatedNetwork = await gamNetworkById.put(network)
      if (updatedNetwork) {
        const updatedNetworks = currentIntegration.gamNetworks ?? []
        updatedNetworks.splice(currentIntegration.gamNetworks?.findIndex((n) => n.networkCode === network.networkCode) ?? -1, 1, updatedNetwork)
        setCurrentIntegration({
          ...currentIntegration,
          gamNetworks: updatedNetworks,
        })
      }
    }
  }
  const startAdxIngestionTesting = (network: GamNetworkConfig) => {
    if (network.dataIngestionStatus === 'INITIALIZED') {
      const updatedNetwork: GamNetworkConfig = { ...network, dataIngestionStatus: 'TESTING' }
      updateNetwork(updatedNetwork)
    }
  }

  const toggleAdxIngestion = (network: GamNetworkConfig) => {
    const shouldActivate = network.dataIngestionStatus === 'ERROR' || network.dataIngestionStatus === 'INITIALIZED' || network.dataIngestionStatus === 'TESTING'
    const updatedNetwork: GamNetworkConfig = { ...network, dataIngestionStatus: shouldActivate ? 'ACTIVE' : 'STOPPED', openBiddingEnabled: false, gamIntegrationEnabled: false }
    updateNetwork(updatedNetwork)
  }

  const toggleOpenBiddingIngestion = (network: GamNetworkConfig) => {
    const updatedNetwork: GamNetworkConfig = { ...network, openBiddingEnabled: !network.openBiddingEnabled }
    updateNetwork(updatedNetwork)
  }

  const toggleGamIntegrationIngestion = (network: GamNetworkConfig) => {
    const updatedNetwork: GamNetworkConfig = { ...network, gamIntegrationEnabled: !network.gamIntegrationEnabled }
    updateNetwork(updatedNetwork)
  }

  //TODO tmu nra 2022-09-21 -- handle error
  return (
    <PureAdminScopeFeaturesGamIntegration
      loading={{ isIntegrationLoading: gamIntegrationLoading || serviceAccountById.loading, isCheckLoading: gamLaunchChecksById.loading, isNetworkLoading: gamNetworkById.loading }}
      integration={currentIntegration}
      createGamNetwork={createNetwork}
      createServiceAccount={createServiceAccount}
      launchChecks={launchGamChecks}
      toggleOpenBiddingIngestion={toggleOpenBiddingIngestion}
      toggleAdxIngestion={toggleAdxIngestion}
      toggleGamIntegrationIngestion={toggleGamIntegrationIngestion}
      startAdxIngestionTesting={startAdxIngestionTesting}
    />
  )
}
