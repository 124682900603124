import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren, ReactElement, ReactNode, useEffect, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

const FoldingContentSubjectTitle = styled.span<{ isClickable?: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  ${Fonts.H2}
  display: inline-flex;
  align-items: center;
  gap: 4px;
  user-select: none;
`
const FoldingTriggerIcon = styled(Icon)``

const FoldingTrigger = styled.button<{ opened?: boolean }>`
  ${Fonts.P2}
  ${Fonts.colors.King}
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  display: inline-flex;
  align-items: center;
  :hover {
    color: ${Colors.Cobalt};
  }
  & ${FoldingTriggerIcon} {
    transition: transform ${Transitions.quick};
    transform: rotate(${({ opened }) => (opened ? '-180deg' : '0')});
  }
`
const FoldingContentDiv = styled.div`
  padding-bottom: 16px;
`
const FoldingContentSubjectActions = styled.div`
  display: flex;
`
const FoldingContentHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 16px;
`
const FoldingContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.Platinum};
  border-bottom: 1px solid ${Colors.Platinum};
  padding: 16px 0 4px 0;
  background-color: ${Colors.White};

  & + & {
    border-top: none;
  }
`
const Folding_STATE = {
  open: 'open',
  closed: 'closed',
}

type FoldingProps = WithClassName & {
  title: ReactNode
  icon?: IconName
  actions?: ReactElement
  isFolding?: boolean
  forceState?: keyof typeof Folding_STATE
  onToggle?: (isOpen: boolean) => void
  disabled?: boolean
}

const _FoldingContent: FunctionComponent<PropsWithChildren<FoldingProps>> = ({ className, children, isFolding, forceState, onToggle, actions: subject, title, icon, disabled }) => {
  const [isOpen, setIsOpen] = useState(forceState === Folding_STATE.open)

  const toggle = () => {
    onToggle && onToggle(!isOpen)
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setIsOpen(forceState === Folding_STATE.open)
  }, [forceState])

  return (
    <FoldingContentWrapper className={className}>
      <FoldingContentHeader>
        <FoldingContentSubjectTitle
          isClickable={isFolding}
          onClick={(event) => {
            event.preventDefault()
            isFolding && toggle()
          }}
        >
          {icon && <Icon name={icon} height={'20px'} width={'20px'} />} {title}
        </FoldingContentSubjectTitle>
        <FoldingContentSubjectActions>
          {subject}
          {isFolding && (
            <FoldingTrigger
              onClick={() => {
                isFolding && !disabled && toggle()
              }}
              opened={isOpen}
            >
              <FoldingTriggerIcon fill={disabled ? Colors.Silver : Colors.Jet} height={'18px'} width={'18px'} name={'chevron_down'} />
            </FoldingTrigger>
          )}
        </FoldingContentSubjectActions>
      </FoldingContentHeader>
      {((isFolding && isOpen) || (!isFolding && children)) && <FoldingContentDiv>{children}</FoldingContentDiv>}
    </FoldingContentWrapper>
  )
}

export const FoldingContent = styled(_FoldingContent)``
