import styled from '@emotion/styled'
import { ComponentProps, ForwardedRef, forwardRef } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

const LinkLabel = styled.span``
export const LinkIcon = styled(Icon)`
  ${LinkLabel} ~ & {
    margin-left: 2px;
  }
`

const Wrapper = styled.a<{ variant?: LinkProps['variant'] }>`
  cursor: pointer;
  text-decoration: none;
  ${Fonts.colors.King};
  font-weight: bold;
  ${({ variant }) => (variant === 'secondary' ? Fonts.P2 : Fonts.P1)};

  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  :hover {
    text-decoration: none;
    ${Fonts.colors.Cobalt};
  }

  :active {
    text-decoration: none;
    ${Fonts.colors.Navy};
  }

  :visited {
    text-decoration: none;
    ${Fonts.colors.King};
  }
`

const IconSizes: { [key in Required<LinkProps>['variant']]: string } = {
  primary: '20px',
  secondary: '18px',
}

type LinkProps = WithClassName &
  ComponentProps<'a'> & {
    label?: string
    icon?: IconName
    iconSize?: string
    variant?: 'primary' | 'secondary'
  }
// eslint-disable-next-line react/display-name
const _Link = forwardRef(({ className, icon, label, iconSize, variant, ...props }: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => (
  <Wrapper className={className} variant={variant} ref={ref} {...props}>
    {label && <LinkLabel>{label}</LinkLabel>}
    {icon && <LinkIcon name={icon} width={iconSize ?? IconSizes[variant ?? 'primary']} />}
  </Wrapper>
))
export const Link = styled(_Link)``
