import styled from '@emotion/styled'
import { ContextKey, RESERVED_CONTEXT_KEY_NAMES } from '@pubstack/common/src/contextKey'
import { noSpaceQuoteAllowedRegExp } from '@pubstack/common/src/input'
import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { SubmitHandler, Validate, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import Chip from '~/components/Chip'
import { ChipListInput } from '~/components/ChipListInput'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Tooltip } from '~/components/Tooltip'
import { PureAdStackEditPage } from '~/modules/adstack/PureAdStackEditPage'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 0;
  max-width: 1000px;
  ${Fonts.P1}
  h2 {
    ${Fonts.H2}
    font-weight: 500;
  }

  & > ${_Input} {
    width: 292px;
  }

  ${_Input} {
    margin-bottom: 36px;
  }

  ${Tooltip} {
    margin: 6px 0px 0px 4px;
  }
`

const Subtitle = styled.div`
  ${Fonts.P1}
  span {
    display: inline-flex;
    align-items: center;
  }
`

const FormButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px;
  ${Button} {
    margin-left: 15px;
  }
`

const AgreementCheckbox = styled(Checkbox)`
  label {
    align-items: baseline;
    user-select: text;
  }
`

const CheckboxLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const CheckboxMeta = styled.div`
  ${Fonts.Mono};
`

type PureAdStackContextKeyEditPageProps = {
  isEditing: boolean
  contextKey: ContextKey
  onBack: () => void
  breadcrumbs: React.ReactNode
  createOrUpdateContextKey: (contextKey: ContextKey) => Promise<void>
  validateUniqueName: (name: string) => Promise<boolean>
  isAdmin: boolean
}

type NewContextKey = ContextKey

enum ERROR_MESSAGES {
  NAME_REQUIRED = 'Name required.',
  QUOTES_AND_SPACES_NOT_ALLOWED = 'Quotes and spaces are not allowed.',
  NAME_START_WITH_NUMBER = 'A name cannot start with a number.',
  SPECIAL_CHARACTERS_NOT_ALLOWED = 'No special characters allowed except "_"',
  NAME_NOT_AVAILABLE = 'This name is not available.',
  NAME_ALREADY_IN_USE = 'This name is already in use.',
  MINIMUM_ONE_VALUE = 'Minimum one value.',
  SAME_VALUE_TWICE = 'You cannot enter the same value twice.',
}

const PureAdStackContextKeyEditPage: FunctionComponent<PureAdStackContextKeyEditPageProps> = ({
  contextKey,
  isEditing,
  onBack,
  createOrUpdateContextKey,
  validateUniqueName,
  isAdmin,
  breadcrumbs,
}) => {
  const defaultValues: NewContextKey = { ...contextKey, values: contextKey.values.sort((a, b) => (a === contextKey.defaultValue || b !== contextKey.defaultValue ? -1 : 1)) }

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
    reset,
    control,
    clearErrors,
  } = useForm<ContextKey>({ defaultValues, mode: 'onChange' })

  const [agreement, setAgreement] = useState<boolean>(false)

  const validateName: Validate<string | string[] | undefined, ContextKey> = async (value) => {
    const parsedValue = (Array.isArray(value) ? value.join() : value) as string
    if (/^[\d].*$/.test(parsedValue)) {
      return ERROR_MESSAGES.NAME_START_WITH_NUMBER
    }
    if (!/^[a-zA-Z0-9_]*$/.test(parsedValue)) {
      return ERROR_MESSAGES.SPECIAL_CHARACTERS_NOT_ALLOWED
    }
    if (RESERVED_CONTEXT_KEY_NAMES.includes(parsedValue)) {
      return ERROR_MESSAGES.NAME_NOT_AVAILABLE
    }
    const isUnique = await validateUniqueName(parsedValue)
    if (!isUnique) {
      return 'This name is already in use.'
    }
    return true
  }

  const validateValues: Validate<string | string[], ContextKey> = (value) => {
    const parsedValue = (Array.isArray(value) ? value : [value]).map((value) => value.toLocaleLowerCase())
    if (new Set(parsedValue).size !== parsedValue.length) {
      return ERROR_MESSAGES.SAME_VALUE_TWICE
    }
    return true
  }

  const { defaultValue } = useWatch({ control })

  useEffect(() => {
    reset({
      ...defaultValues,
      ...contextKey,
    })
  }, [contextKey])

  const onSubmit: SubmitHandler<ContextKey> = (newContextKey) => {
    clearErrors()
    if (newContextKey.defaultValue && !newContextKey.values.includes(newContextKey.defaultValue)) {
      newContextKey.defaultValue = undefined
    }
    createOrUpdateContextKey(newContextKey)
  }

  const buildCheckbox: ReactNode = (
    <CheckboxLabel>
      <div>I know that I must add the context key on the pages by using the following template:</div>
      <CheckboxMeta>
        &lt;meta name=”pbstck_context:<strong>context_key_name</strong>” content=”<strong>your_value</strong>”/&gt;
      </CheckboxMeta>
    </CheckboxLabel>
  )

  const renderErrorMessage = (message: string) => {
    return (
      <span>
        {message}
        {message === ERROR_MESSAGES.SAME_VALUE_TWICE && (
          <Button
            size={'s'}
            onClick={() => {
              const values = getValues().values.reduce((acc, next) => {
                if (acc.every((value) => value.toLocaleLowerCase() !== next.toLocaleLowerCase())) acc.push(next)
                return acc
              }, [] as string[])

              setValue('values', values, { shouldValidate: true })
            }}
            variant={'tertiary'}
          >
            Click here to remove duplicates.
          </Button>
        )}
      </span>
    )
  }

  return (
    <PureAdStackEditPage title={isEditing ? 'Edit' : 'New context key'} breadcrumbs={breadcrumbs} color={Colors.Violet} onBackClick={onBack}>
      <form id={'newContextKeyForm'} onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <Subtitle>
            <h2>Name</h2>
          </Subtitle>
          <Input
            type={'text'}
            label={'Name'}
            control={control}
            name={'name'}
            error={errors.name?.message}
            rules={{
              required: { value: true, message: ERROR_MESSAGES.NAME_REQUIRED },
              pattern: { value: noSpaceQuoteAllowedRegExp, message: ERROR_MESSAGES.QUOTES_AND_SPACES_NOT_ALLOWED },
              validate: isEditing ? () => true : validateName,
            }}
            disabled={isEditing}
            helper={'Cannot be edited later.'}
            maxLength={100}
          />
          <Subtitle>
            <h2>Values</h2>
            <span>Only following values will be accepted for this context key. Values are not case sensitive </span>
            <span>
              You can set a default value by clicking on <Icon name={'star_empty'} width={Sizes[22]} /> . This value will be used in case of missing or unlisted value.
            </span>
          </Subtitle>
          <ChipListInput
            chipColor={Colors.Milka}
            type={'text'}
            label={'Values'}
            control={control}
            name={'values'}
            helper={'Type a value then press Enter.'}
            error={errors.values?.message && renderErrorMessage(errors.values.message)}
            rules={{ required: ERROR_MESSAGES.MINIMUM_ONE_VALUE, validate: validateValues }}
            chipRenderer={(chipProps, { value }) => (
              <Chip
                {...chipProps}
                onLeftIconClick={() => {
                  setValue('defaultValue', defaultValue === value ? undefined : value)
                }}
                iconLeft={defaultValue === value ? 'star' : 'star_empty'}
                color={defaultValue === value ? Colors.Violet : chipProps.color}
              />
            )}
            canRemoveValues={defaultValues.values.length === 0}
            forceChipsRemovableFromIndex={contextKey.values.length}
            isSpaceAllowed={true}
            maxChipCount={Number.POSITIVE_INFINITY}
          />
          {!isAdmin && !isEditing && <AgreementCheckbox checked={agreement} onChange={() => setAgreement(!agreement)} label={buildCheckbox} />}
        </FormWrapper>
        <FormButtonsWrapper>
          <Button variant={'tertiary'} onClick={onBack}>
            Cancel
          </Button>
          {isEditing ? (
            <Button variant={'primary'} type={'submit'}>
              Update
            </Button>
          ) : (
            <Button variant={'primary'} type={'submit'} disabled={!isAdmin && !agreement}>
              Validate
            </Button>
          )}
        </FormButtonsWrapper>
      </form>
    </PureAdStackEditPage>
  )
}

export default PureAdStackContextKeyEditPage
