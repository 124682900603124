import styled from '@emotion/styled'
import { LEADERBOARD, Mediatype, SKIN_SINGLE_AD } from '@pubstack/common/src/adFormat'
import { DisplayedAdUnit, getAdUnitMediatypes } from '@pubstack/common/src/adunit'
import { Fragment } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon } from '~/components/Icon'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

const TooltipStyled = styled(Tooltip)`
  width: 18px;
  height: 18px;
`

const MediatypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const BannerAttributes = styled.div`
  padding: 8px 0 12px 34px;
  display: flex;
  gap: 14px;
  flex-direction: column;
  h3 {
    ${Fonts.P2}
    color: ${Colors.SlateGrey};
    font-weight: normal;
  }
`
const TextIcon = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`
const AdUnitDetailWrapper = styled.div`
  display: flex;
  gap: 10px;
`
const NoValueText = styled.div`
  color: ${Colors.Ash};
  font-style: italic;
`

const pipeSeparator = `\xa0 | \xa0`
export type AdUnitMediatypesDetailsProps = WithClassName & {
  adUnit: DisplayedAdUnit
}

const _AdUnitMediatypesDetails = ({ className, adUnit }: AdUnitMediatypesDetailsProps) => {
  const adUnitMediatypes: Mediatype[] = getAdUnitMediatypes(adUnit)
  const hasSkin: boolean = adUnit.adFormat?.id === LEADERBOARD.id || adUnit.adFormat?.id === SKIN_SINGLE_AD.id
  return (
    <MediatypesWrapper className={className}>
      {adUnit.adFormat?.mediatypes.map((mediatype, i) => (
        <Fragment key={i}>
          <TextIcon>
            <Icon width={'26px'} name={adUnitMediatypes.includes(mediatype) ? 'check' : 'close'} fill={adUnitMediatypes.includes(mediatype) ? Colors.Success : Colors.Hurricane} />
            <span>{mediatype}</span>
          </TextIcon>
          {mediatype === 'Banner' && adUnitMediatypes.includes(mediatype) && (
            <BannerAttributes>
              {hasSkin && (
                <TextIcon>
                  <Icon width={'26px'} name={adUnit.skinEnabled ? 'check' : 'close'} fill={adUnit.skinEnabled ? Colors.Success : Colors.Hurricane} />
                  <span>Skin</span>
                </TextIcon>
              )}
              <TextIcon>
                <Icon width={'26px'} name={adUnit.fluid ? 'check' : 'close'} fill={adUnit.fluid ? Colors.Success : Colors.Hurricane} />
                <span>Fluid</span>
              </TextIcon>
              <AdUnitDetailWrapper>
                <div>
                  <TextIcon>
                    <h3>Standard sizes</h3>
                    <TooltipStyled title={'Standard sizes (px) - ad server, header bidding'}>
                      <Icon width={'16px'} name={'info'} fill={Colors.Hurricane} />
                    </TooltipStyled>
                  </TextIcon>
                  <div>{adUnit.sizes && adUnit.sizes.length > 0 ? <span>{adUnit.sizes?.join(pipeSeparator)}</span> : <NoValueText>No standard sizes.</NoValueText>}</div>
                </div>
              </AdUnitDetailWrapper>
              <AdUnitDetailWrapper>
                <div>
                  <TextIcon>
                    <h3>Custom sizes</h3>
                    <TooltipStyled title={'Custom sizes (px) - ad server only'}>
                      <Icon width={'16px'} name={'info'} fill={Colors.Hurricane} />
                    </TooltipStyled>
                  </TextIcon>
                  <div>{adUnit.customSizes && adUnit.customSizes.length > 0 ? <span>{adUnit.customSizes?.join(pipeSeparator)}</span> : <NoValueText>No custom sizes.</NoValueText>}</div>
                </div>
              </AdUnitDetailWrapper>
            </BannerAttributes>
          )}
        </Fragment>
      ))}
    </MediatypesWrapper>
  )
}

export const AdUnitMediatypesDetails = styled(_AdUnitMediatypesDetails)``
