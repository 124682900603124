import styled from '@emotion/styled'
import { Site } from '@pubstack/common/src/tag'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { CodeSnippet } from '~/components/CodeSnippet'
import { CopyButton } from '~/components/CopyButton'
import { Flyout } from '~/components/Flyout'
import { FoldingContent } from '~/components/FoldingContent'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'

const EditSiteWrapper = styled.div`
  ${Fonts.P1}
  & h2 {
    ${Fonts.H2}
    ${Fonts.colors.Jet}
    margin: 0;
    padding: 0;
  }
  & p {
    margin-bottom: 12px;
  }

  display: flex;
  flex-direction: column;
  gap: 32px;
`

const TagInfoWrapper = styled.div`
  & h2 {
    margin-bottom: 16px;
  }
`

const EditSiteForm = styled.form`
  padding-right: 64px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  & ${Button} {
    align-self: flex-start;
  }
`

const EditActionsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 24px;
`

const InputIdWrapper = styled.div`
  display: flex;
  gap: 12px;

  & div {
    flex-grow: 1;
  }
`

export const PureSettingsSitesEditSiteFlyout: React.FunctionComponent<{
  site: Site | undefined
  setSite: React.Dispatch<React.SetStateAction<Site | undefined>>
  onSaveChanges: (site: Site) => unknown
  onCopyToClipboard: (label: string) => unknown
}> = ({ site, setSite, onSaveChanges, onCopyToClipboard }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<Site>({
    defaultValues: site,
  })

  const saveChanges = (site: Site) => {
    onSaveChanges(site)
    setSite(undefined)
  }

  useEffect(() => {
    reset(site)
  }, [site])

  return (
    <Flyout
      color={SETTINGS_NAV_CONFIG.sites.color}
      onClose={() => setSite(undefined)}
      title={site?.name}
      isOpen={!!site}
      actions={
        isDirty && (
          <EditActionsWrapper>
            <Button onClick={() => reset(site)} iconName={'restore'} variant={'tertiary'}>
              Restore
            </Button>
            <Button onClick={handleSubmit(saveChanges)}>Save changes</Button>
          </EditActionsWrapper>
        )
      }
    >
      {site && (
        <EditSiteWrapper>
          <EditSiteForm>
            <h2>Global information</h2>
            <Input control={control} name={'name'} label={'Site name'} />
            <InputIdWrapper>
              <Input readOnly disabled control={control} name={'id'} label={'Site ID'} />
              <CopyButton onCopy={() => onCopyToClipboard('Site ID')} contentText={site.id} />
            </InputIdWrapper>
          </EditSiteForm>
          <TagInfoWrapper>
            <h2>Connect to Pubstack</h2>
            <p>Use one of the following option to connect your site to Pubstack:</p>
            <FoldingContent title={'HTML - script tag'} isFolding={true}>
              Edit your site by adding the following script into your <code>&lt;head\&gt;</code> section.
              <CodeSnippet onCodeCopy={() => onCopyToClipboard('HTML - script tag')} contentText={`<script async src="https://boot.pbstck.com/v1/tag/${site.id}"></script>`} />
            </FoldingContent>
            <FoldingContent title={'Javascript - inline code'} isFolding={true}>
              Copy/paste the following code in your javascript file
              <CodeSnippet
                onCodeCopy={() => onCopyToClipboard('HTML - script tag')}
                contentText={
                  "var s = document.createElement('script');\n" +
                  "s.type = 'text/javascript';\n" +
                  `s.src = 'https://boot.pbstck.com/v1/tag/${site.id}';\n` +
                  's.async = true;\n' +
                  '(document.head || document.documentElement).appendChild(s);'
                }
              />
            </FoldingContent>
            <FoldingContent title={'Google tag manager'} isFolding={true}>
              You can add Pubstack to your <Link label={'Google Tag Manager'} href={'https://tagmanager.google.com/'} />. Look at
              <Link label={'the documentation'} href={'https://support.google.com/tagmanager/answer/6107167?hl=en&ref_topic=3002579'} /> to help you installing Pubstack with this tool.
            </FoldingContent>
          </TagInfoWrapper>
        </EditSiteWrapper>
      )}
    </Flyout>
  )
}
