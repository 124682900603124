import styled from '@emotion/styled'
import { SnoozeAlarm } from '@pubstack/common/src/snoozeAlarm'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import Button from '~/components/Button'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import Table, { Column, EmptyTable } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'
import { Ressources } from './AdminSnoozeAlarm'

const Wrapper = styled.div`
  margin: 20px;
`

const NewConfigForm = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

export type ConfigFormValues = { type: string; resourceId: string }

type PureAdminSnoozeAlarmProps = WithClassName & {
  ressources: Ressources
  snoozeAlarms: SnoozeAlarm[]
  createSnoozeAlarm: (type: string, scopeOrNetwork: string) => unknown
  deleteSnoozeAlarm: (id: string) => unknown
  onRefreshClick: () => unknown
}
const _PureAdminSnoozeAlarm: FunctionComponent<PureAdminSnoozeAlarmProps> = ({ className, snoozeAlarms, onRefreshClick, createSnoozeAlarm, deleteSnoozeAlarm, ressources }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ConfigFormValues>({
    mode: 'onChange',
    defaultValues: { type: '', resourceId: '' },
  })
  const type = useWatch({
    control,
    name: 'type',
  })
  const [option, setOption] = useState<SelectOptionProp[]>([])
  const onSubmit = (formValues: ConfigFormValues) => {
    createSnoozeAlarm(formValues.type, formValues.resourceId)
  }

  useEffect(() => {
    if (type === 'gam') {
      setOption(ressources.gamNetworks.map((t) => ({ label: t.id, value: t.id })))
    } else {
      setOption(ressources.scopes.map((t) => ({ label: t.name, value: t.id })))
    }
  }, [type])

  const COLUMNS: Column[] = [
    {
      name: 'Scope Or Network',
      isSortable: false,
    },
    {
      name: 'Type',
      isSortable: false,
    },
    {
      name: 'Expiration Time',
      isSortable: false,
    },
    {
      name: 'Action',
      isSortable: false,
    },
  ]

  return (
    <Wrapper className={className}>
      <NewConfigForm>
        <p>Snooze an alarm for one week</p>
        <Select
          control={control}
          name={'type'}
          label={'Type'}
          options={[
            { label: 'Gam', value: 'gam' },
            { label: 'Darty', value: 'darty' },
          ]}
          rules={{ required: true }}
        />
        <Select searchable={true} disabled={!type} control={control} name={'resourceId'} label={'resourceId'} options={option} rules={{ required: 'Please fill this to continue' }} />
        <Button onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          Add snooze alarm
        </Button>
      </NewConfigForm>
      <Table isLoading={false} columns={COLUMNS}>
        {snoozeAlarms ? (
          snoozeAlarms.map((c) => (
            <TableRow key={c.id}>
              <TableCell>{c.resourceId}</TableCell>
              <TableCell>{c.type}</TableCell>
              <TableCell>{c.expirationTime ? DateTime.fromISO(c.expirationTime).toFormat('yyyy-LL-dd HH:mm:ss') : 'never'}</TableCell>
              <TableCell>
                <Button
                  iconName={'delete'}
                  variant={'tertiary'}
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    deleteSnoozeAlarm(c.id)
                  }}
                />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colspan={5}>
              <EmptyTable>
                <div>
                  <h3>Something happened</h3>
                  <p>An error occurred when getting snoozed alarms.</p>
                </div>
                <Button onClick={onRefreshClick}>Try again</Button>
              </EmptyTable>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </Wrapper>
  )
}

export const PureAdminSnoozeAlarm = styled(_PureAdminSnoozeAlarm)``
