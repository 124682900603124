import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, MappedName, SiteMetricsByTagId } from '@pubstack/common/src/analytics/query'
import { FunctionComponent, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useAnalyticsQuery } from '~/api/api.hook'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { logSortBreakdownAction } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureSitesOverviewTable } from './PureSitesOverviewTable'

const SitesOverviewTable = styled(PureSitesOverviewTable)``

type SitesOverviewPageProps = WithClassName
const _SitesOverviewPage: FunctionComponent<SitesOverviewPageProps> = ({ className }) => {
  const logger = useLogger()
  const dimensions: Dimension[] = ['tagId', 'bidder', 'adUnit', 'device', 'country', 'size', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion']
  const dashboard: AnalyticsQueryDashboard = { name: 'sites', filterType: 'auctions' }

  const context = useRecoilValue(contextState)
  const [sitesData, setSitesData] = useState<SiteMetricsByTagId | undefined>(undefined)
  const { byId: analyticsQuery } = useAnalyticsQuery<SiteMetricsByTagId>(null, dimensions, context)
  const currencySymbol = useScopeCurrency()

  const navigate = useNavigate()

  const loadSitesData = async () => {
    if (analyticsQuery.loading) {
      analyticsQuery.abort()
    }
    const d = await analyticsQuery.post('site.metrics.by.tagid')
    setSitesData(d.values)
  }

  const dateParameterFormat = 'yyyy-MM-dd'
  const onRowClick = (name: MappedName) => {
    navigate({
      pathname: `/analytics/sites/${name.label.replace(/[./]/g, '-')}`,
      search: `${createSearchParams({
        from: context.timeRange.from.toFormat(dateParameterFormat),
        to: context.timeRange.to.toFormat(dateParameterFormat),
        mapping: btoa(JSON.stringify({ site: name.value, siteName: name.label })), //TODO: tmu 2022-07-11 don't give out the tagid/sitename this way ?
      })}`,
    })
  }

  useEffect(() => {
    setTimeout(() => {
      loadSitesData()
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context])

  const breadcrumbs = useBreadcrumbs()
  return (
    <AnalyticsPage className={className} onRefreshClick={loadSitesData} filterDimensions={dimensions} dashboard={dashboard} title={breadcrumbs}>
      <SitesOverviewTable
        onRowClick={onRowClick}
        onRefreshClick={loadSitesData}
        currencySymbol={currencySymbol}
        rawData={sitesData}
        empty={!analyticsQuery.loading && !sitesData?.name.length}
        error={!!analyticsQuery.error}
        isLoading={analyticsQuery.loading}
        onSortChange={logSortBreakdownAction(logger)}
      />
    </AnalyticsPage>
  )
}
export const SitesOverviewPage = styled(_SitesOverviewPage)``
