import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import {
  AdmRefreshGlobalSettings,
  DuplicatedSettings,
  RefreshGlobalSetting,
  displayDuplicateSettings,
  getDuplicatedSettings,
  refreshSettingIsEmpty,
} from '@pubstack/common/src/adstack/refresh-global-settings'
import { VALIDATE_ADUNIT_SIZE_REGEX } from '@pubstack/common/src/adunit'
import { FunctionComponent } from 'react'
import { Control, FieldErrors, UseFormSetValue, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ChipListInput } from '~/components/ChipListInput'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { RadioGroup, RadioOption } from '~/components/RadioGroup'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { WithClassName } from '~/types/utils'

const GlobalSettingWrapper = styled.div`
  border: 1px solid ${Colors.Platinum};
  display: flex;
  flex-direction: column;
  gap: 39px;
  padding: 20px 12px;
  width: 1158px;
`

const TargetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding-left: 5px;
  ${Fonts.colors.SlateGrey};
  ${_Input} {
    width: 502px;
  }
`

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  ${Fonts.colors.SlateGrey};
  ${_Input} {
    width: 172px;
  }
`

const Error = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${Colors.Alert};
`

type GlobalSettingProps = WithClassName & {
  control: Control<AdmRefreshGlobalSettings>
  errors: FieldErrors<AdmRefreshGlobalSettings>
  selectableBidders: BidderAdapter[]
  onDelete: (indexToDelete: number) => void
  index: number
  setValue: UseFormSetValue<AdmRefreshGlobalSettings>
}

const _GlobalSetting: FunctionComponent<GlobalSettingProps> = ({ index, control, errors, selectableBidders, onDelete, setValue }) => {
  const radioOptions: RadioOption[] = [
    {
      label: 'No refresh',
      value: false,
    },
    {
      label: 'Refresh',
      value: true,
    },
  ]

  const selectableBiddersOptions: SelectOptionProp<string>[] = selectableBidders.map((bidder) => ({ value: bidder.displayName, label: bidder.displayName }))
  const { settings } = useWatch({ control })

  const { control: radioControl } = useForm<{ refresh?: boolean }>({ mode: 'onChange', defaultValues: { refresh: !!settings?.[index]?.timer } })
  const { refresh } = useWatch({ control: radioControl })

  const validateChiplistInput = (predicate: (err: DuplicatedSettings) => boolean): string | boolean => {
    const settingToValidate = settings?.[index] as RefreshGlobalSetting
    if (refreshSettingIsEmpty(settingToValidate)) {
      return 'Must target at least one item, bidder or size.'
    }
    const allSettings: RefreshGlobalSetting[] = (settings ?? []).filter((_, i) => index !== i) as RefreshGlobalSetting[]
    const errors = getDuplicatedSettings(settingToValidate, allSettings)
    return errors.some(predicate) ? `Following target is already used: ${displayDuplicateSettings(errors)}` : true
  }

  return (
    <GlobalSettingWrapper>
      <TargetWrapper>
        <div>Target</div>
        <ChipListInput
          name={`settings.${index}.bidders`}
          control={control}
          error={errors.settings?.[index]?.bidders?.message}
          selectableOptions={selectableBiddersOptions}
          label={'Bidders'}
          helper={'Leave Bidders empty to target all of them.'}
          chipColor={Colors.Greu}
          rules={{
            validate: {
              value: () => {
                return validateChiplistInput((err) => err.bidders.length > 0)
              },
            },
          }}
          isSpaceAllowed={true}
        />
        <ChipListInput
          name={`settings.${index}.sizes`}
          control={control}
          error={errors.settings?.[index]?.sizes?.message}
          label={'Sizes'}
          helper={'Leave Sizes empty to target all of them.'}
          chipColor={Colors.Greu}
          rules={{
            validate: {
              value: () => {
                const settingToValidate = settings?.[index] as RefreshGlobalSetting
                if (!settingToValidate.sizes.every((size) => VALIDATE_ADUNIT_SIZE_REGEX.test(size))) {
                  return 'Sizes should follow this format : [Num]x[Num] (ex: 300x250)'
                }
                return validateChiplistInput((err) => err.sizes.length > 0)
              },
            },
          }}
        />
        <Button variant={'tertiary'} iconName={'delete'} onClick={() => onDelete(index)} />
      </TargetWrapper>
      <SettingsWrapper>
        <div>Settings</div>
        <RadioGroup
          name={'refresh'}
          control={radioControl}
          onChange={(value) => {
            if (!value) {
              setValue(`settings.${index}.timer`, undefined)
            }
          }}
          options={radioOptions}
        />
        {refresh && (
          <>
            <Input
              name={`settings.${index}.timer`}
              control={control}
              label={'Viewability timer'}
              iconRight={'second'}
              type={'number'}
              min={1}
              step={1}
              rules={{
                required: { value: true, message: 'Viewability timer is required.' },
                min: { value: 1, message: 'Please enter a value, 1 second at least.' },
                pattern: { value: RegExp('^[0-9]*$'), message: 'Please enter a value, 1 second at least.' },
              }}
            />
            {errors.settings?.[index]?.timer?.message && (
              <Error>
                <Icon width={'16px'} name={'alert'} />
                <span>{errors.settings?.[index]?.timer?.message}</span>
              </Error>
            )}
          </>
        )}
      </SettingsWrapper>
    </GlobalSettingWrapper>
  )
}

export const GlobalSetting = styled(_GlobalSetting)``
