import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'
import { Icon } from './Icon'

export type StatusState = 'active' | 'inactive' | 'error' | 'warning'
type StatusProps = WithClassName & {
  state: StatusState
}

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  ${Fonts.P1}
`

const STATE_COLOR_CONFIG: { [key in StatusState]: string } = {
  active: Colors.Success,
  inactive: Colors.Silver,
  error: Colors.Alert,
  warning: Colors.Warning,
}

const _Status: FunctionComponent<PropsWithChildren<StatusProps>> = ({ className, state, children }) => (
  <StatusWrapper className={className}>
    <Icon fill={STATE_COLOR_CONFIG[state]} name={'circle'} height={'8px'} width={'8px'} />
    {children}
  </StatusWrapper>
)

export const Status = styled(_Status)``
