import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type PortalHandler = (content: ReactNode | ((target: HTMLElement) => ReactNode), fallback?: ReactElement) => ReactElement | null
/**
 * Returns a function to create a portal towards the target indentified by `portalId`
 * 
 * The function accepts a `ReactNode` or a function returning a `ReactElement`, exposing the portal target as a parameter
 * It also accepts a `ReactElement` as a `fallback` in case the portal target is not ready, it defaults to `null`
 * 
 * @param portalId - the portal target's id
 * 
 * @example without access to the portal's target
 * ```tsx
  const portal = usePortal('my-target-id')

  return portal(<div>Going through</div>)
 * ```
 * @example with access to the portal's target
 * ```tsx
  const portal = usePortal('my-target-id')

  return portal((target) => <div>Going through to {target.id}</div>)
 * ```
 */
export const usePortal = (portalId: string): PortalHandler => {
  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const portalDestination = document.getElementById(portalId)
    if (portalDestination) {
      setPortalTarget(portalDestination)
    }
  }, [])

  const portal: PortalHandler = (content, fallback) => (portalTarget ? createPortal(typeof content === 'function' ? content(portalTarget) : content, portalTarget) : fallback ?? null)
  return portal
}
