import styled from '@emotion/styled'
import { StacksBySite } from '@pubstack/common/src/stack'
import { ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Tooltip } from '~/components/Tooltip'

const StacksTooltip = styled.div`
  div:not(:last-child) {
    border-bottom: 1px solid ${Colors.SlateGrey};
    padding-bottom: 6px;
  }
  div:not(:first-child) {
    padding-top: 6px;
  }
`

const ArchivedStackName = styled.span`
  ${Fonts.colors.Silver};
  font-style: italic;
`

export const generateStackNameList = (stacks: StacksBySite[string]): ReactNode[] => {
  return [
    ...stacks
      .filter((s) => !s.enabled)
      .map((stack, i) => (
        <ArchivedStackName key={i}>
          {stack.stackName} - {stack.version}
        </ArchivedStackName>
      )),
    ...stacks
      .filter((s) => s.enabled)
      .map((stack, i) => (
        <span key={i}>
          {stack.stackName} - {stack.version}
        </span>
      )),
  ]
}

type PureUsedStackTooltipProps = { stacksBySite: StacksBySite }
const _PureUsedStackTooltip = ({ stacksBySite }: PureUsedStackTooltipProps) => {
  const pipeSeparator = `\xa0 | \xa0`
  const countStacksUse = stacksBySite
    ? Object.entries(stacksBySite)
        .map(([_, stacks]) => stacks.length)
        .reduce((sum, current) => sum + current, 0)
    : 0

  return (
    <Tooltip
      title={
        <StacksTooltip>
          {Object.entries(stacksBySite).map(([siteName, stacks]) => (
            <div key={siteName}>
              <strong>{siteName}: </strong>
              {generateStackNameList(stacks).reduce((stack, acc) => [acc, pipeSeparator, stack])}
            </div>
          ))}
        </StacksTooltip>
      }
      positions={['left', 'bottom', 'top', 'right']}
    >
      {`${countStacksUse} stack${countStacksUse > 1 ? 's' : ''}`}
    </Tooltip>
  )
}

export const PureUsedStackTooltip = styled(_PureUsedStackTooltip)``
