import styled from '@emotion/styled'
import { FunctionComponent, ReactNode, createContext, useContext, useMemo, useState } from 'react'
import { Modal } from '~/components/Modal'
import { WithClassName } from '~/types/utils'

type AdditionalProps = object
type OnCloseCallbacks = {
  onBeforeDefaultClose?: () => void
  onAfterDefaultClose?: () => void
}
type GlobalModalHandler = {
  isOpen: boolean
  open: <T extends AdditionalProps>(comp: (props: T) => ReactNode, additionalProps: T, onCloseCallbacks?: OnCloseCallbacks) => void
  close: () => Promise<void>
}
const GlobalModalContext = createContext<GlobalModalHandler>({
  isOpen: false,
  open: () => {},
  close: async () => {},
})

export const useGlobalModal = () => useContext(GlobalModalContext)

type Content<T extends AdditionalProps = AdditionalProps> = {
  Component: FunctionComponent<T>
  additionalProps: T
  onCloseCallbacks?: OnCloseCallbacks
}

const defaultContent: Content = {
  Component: () => null,
  additionalProps: {},
}

const useModal = () => {
  const [isOpen, setOpen] = useState(false)
  const [content, setContent] = useState<Content>(defaultContent)
  const handler = useMemo((): GlobalModalHandler => {
    return {
      isOpen,
      open: (comp, additionalProps, onCloseCallbacks) => {
        setContent({
          Component: comp as unknown as FunctionComponent<AdditionalProps>, // couldn't type it better
          additionalProps,
          onCloseCallbacks,
        })
        setOpen(true)
      },
      close: async () => {
        setOpen(false)
        return new Promise(
          (resolve) =>
            setTimeout(() => {
              setContent(defaultContent)
              resolve()
            }, Modal.CLOSE_TIMEOUT_MS + 1) // modal close timeout +1, could cause issue if we close then reopen, need some tests
        )
      },
    }
  }, [isOpen])

  return {
    content,
    handler,
  }
}

type GlobalModalWrapperProps = WithClassName & {
  children: ReactNode
}
const _GlobalModalWrapperModal: FunctionComponent<GlobalModalWrapperProps> = ({ children }) => {
  const {
    content: { Component, additionalProps, onCloseCallbacks },
    handler,
  } = useModal()
  return (
    <GlobalModalContext.Provider value={handler}>
      <Modal.Container
        isOpen={handler.isOpen}
        onRequestClose={async () => {
          const { onBeforeDefaultClose, onAfterDefaultClose } = onCloseCallbacks || {}
          onBeforeDefaultClose?.()
          await handler.close()
          onAfterDefaultClose?.()
        }}
      >
        <Component {...additionalProps} />
      </Modal.Container>
      {children}
    </GlobalModalContext.Provider>
  )
}
export const GlobalModalWrapper = styled(_GlobalModalWrapperModal)``
