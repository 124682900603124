import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, SiteMetricsByDim, SiteMetricsByTime } from '@pubstack/common/src/analytics/query'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useAnalyticsQuery } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { useBuildContextWithFilterEnforced } from '~/modules/analytics/useContext'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { logSortBreakdownAction, onBreakdownRowClick } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useBreakdownTabs } from '~/utils/useBreakdownTabs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureSiteBreakdown } from './PureSiteBreakdown'
import { PureSiteChartsContainer } from './PureSiteChartsContainer'
import { SiteInventoryTabLabel } from './PureSiteInventoryChart'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 20px;
  }
`

type SiteDetailsPageProps = WithClassName
const _SiteDetailsPage: FunctionComponent<SiteDetailsPageProps> = ({ className }) => {
  const filterDimensions: Dimension[] = ['bidder', 'adUnit', 'device', 'country', 'size', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion']
  const queryDimensions: Dimension[] = [...filterDimensions, 'tagId']
  const logger = useLogger()

  const search = useSearchParams()
  //TODO: tmu 2022-07-11 don't get the tagid/sitename this way ?
  const searchParams = JSON.parse(atob(search[0].get('mapping') ?? "{site: '', siteName:''}"))
  const tagId = searchParams.site

  const [context, setContext] = useRecoilState(contextState)
  const buildContext = useBuildContextWithFilterEnforced('tagId', { label: searchParams.siteName, value: tagId })
  const currencySymbol = useScopeCurrency()

  const [dataByTime, setDataByTime] = useState<SiteMetricsByTime | undefined>(undefined)
  const { byId: analyticsQueryByTime } = useAnalyticsQuery<SiteMetricsByTime>(null, queryDimensions, context)

  const [dataByDim, setDataByDim] = useState<SiteMetricsByDim | undefined>(undefined)
  const { byId: analyticsQueryByDim } = useAnalyticsQuery<SiteMetricsByDim>(null, queryDimensions, context)
  const [breakdownDimension, setBreakdownDimension] = useState<Dimension>('bidder')

  const dashboard: AnalyticsQueryDashboard = { name: 'sites', filterType: 'auctions' }

  const breadcrumbs = useBreadcrumbs()
  const user = useUser()
  const tabsConfig = useBreakdownTabs(user, ['bidder', 'adUnit', 'device', 'country', 'size'], { abTestPopulation: 'abtest', stackIdVersion: 'adstack' })

  const loadSiteDataByTime = async () => {
    if (analyticsQueryByTime.loading) {
      analyticsQueryByTime.abort()
    }
    const d = await analyticsQueryByTime.post('site.metrics.by.time', { tagId: tagId })
    setDataByTime(d.values)
  }

  const loadSiteDataByDim = async (dimension: Dimension) => {
    if (analyticsQueryByDim.loading) {
      analyticsQueryByDim.abort()
    }
    const d = await analyticsQueryByDim.post('site.metrics.by.dim', { tagId, dimension })
    setDataByDim(d.values)
  }

  const loadAllData = async () => {
    loadSiteDataByTime()
    loadSiteDataByDim(breakdownDimension)
  }

  const onInventoryTabChange = (tab: SiteInventoryTabLabel) => {
    logger.info({ type: 'graph-action', action: 'click', graphName: 'site-detail-inventory-filling', detail: tab === 'Rate' ? 'rate' : 'events' })
  }

  const onBreakdownTabChange = (dimension: Dimension) => {
    logger.info({ action: 'click', type: 'site-detail-breakdown', detail: dimension })
    loadSiteDataByDim(dimension)
    setBreakdownDimension(dimension)
  }

  useEffect(() => {
    setTimeout(() => {
      loadAllData()
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context])

  return (
    <AnalyticsPage
      className={className}
      onRefreshClick={() => loadAllData()}
      filterDimensions={filterDimensions}
      queryDimensions={queryDimensions}
      buildContext={buildContext}
      dashboard={dashboard}
      title={breadcrumbs}
    >
      <PageContent>
        <PureSiteChartsContainer
          onInventoryTabChange={onInventoryTabChange}
          rawData={dataByTime}
          onRefreshClick={loadSiteDataByTime}
          currencySymbol={currencySymbol}
          empty={!analyticsQueryByTime.loading && !dataByTime?.epoch.length}
          error={!!analyticsQueryByTime.error}
          isLoading={analyticsQueryByTime.loading}
          timezone={context.timeRange.tz}
          currentEpoch={DateTime.now()}
        />
        <PureSiteBreakdown
          rawData={dataByDim}
          onRefreshClick={() => loadSiteDataByDim(breakdownDimension)}
          onTabChange={onBreakdownTabChange}
          onRowClick={onBreakdownRowClick(logger, breakdownDimension, setContext, !dataByDim)}
          currencySymbol={currencySymbol}
          empty={!analyticsQueryByDim.loading && !dataByTime}
          error={!!analyticsQueryByDim.error}
          isLoading={analyticsQueryByDim.loading}
          onSortChange={logSortBreakdownAction(logger)}
          tabsConfig={tabsConfig}
        />
      </PageContent>
    </AnalyticsPage>
  )
}

export const SiteDetailsPage = styled(_SiteDetailsPage)``
