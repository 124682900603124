import User from '~/auth/User'
import {
  auctionCountObj,
  auctionFillRateObj,
  auctionRPMObj,
  averageViewedTimeObj,
  bidCpmObj,
  bidRPMObj,
  bidRateObj,
  bidTimeoutRateObj,
  bidWinCpmObj,
  bidWinRateObj,
  bidWinRpmObj,
  eCPMObj,
  impressionCountObj,
  impressionPerPageObj,
  impressionPerSessionObj,
  impressionRPMObj,
  impressionRevenueObj,
  pageViewObj,
  sessionCountObj,
  sessionRpmObj,
  viewabilityMRCObj,
} from '~/modules/analytics/formulas'
import { FormulaObj } from '~/modules/analytics/formulas/operation-obj'

export type DataDistributionType = 'percentage' | 'number'
export type ExploreFormulasConfig = { [key: string]: FormulaObj<any> }

export const getExploreFormulasConfig = (user: User | null): ExploreFormulasConfig => {
  return {
    auctionCount: auctionCountObj,
    auctionRPM: auctionRPMObj,
    impressionCount: impressionCountObj,
    fillRate: auctionFillRateObj,
    impressionCpmSum: impressionRevenueObj,
    impressionRPM: impressionRPMObj,
    eCPM: eCPMObj,
    bidRate: bidRateObj,
    bidWinRate: bidWinRateObj,
    bidTimeoutRate: bidTimeoutRateObj,
    bidCpm: bidCpmObj,
    bidWinCpm: bidWinCpmObj,
    bidRPM: bidRPMObj,
    bidWinRpm: bidWinRpmObj,
    ...(user?.hasFeature('userSession') || user?.hasFeature('standaloneUserSession')
      ? { sessionCount: sessionCountObj, sessionRpm: sessionRpmObj, impressionPerSession: impressionPerSessionObj, impressionPerPage: impressionPerPageObj, pageView: pageViewObj }
      : {}),
    ...(user?.hasFeature('viewability') ? { viewabilityMRC: viewabilityMRCObj, averageViewedTime: averageViewedTimeObj } : {}),
  }
}
