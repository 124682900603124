import { FloorRule } from '@pubstack/common/src/stack'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFloorRules } from '~/api/adm-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureAdStackFloorPriceEditPage } from './PureAdStackFloorPriceEditPage'

export const AdStackFloorPriceEditPage: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const breadcrumbs = useBreadcrumbs()
  const [floorRules, setFloorRules] = useState<FloorRule[]>([])
  const currencySymbol = useScopeCurrency()

  const [floorRule, setFloorRule] = useState<FloorRule>()
  const { floorRuleId } = useParams()

  const { all, byId } = useFloorRules(null)

  async function loadFloorRule(floorRuleId?: string) {
    if (floorRuleId) {
      try {
        const floorRuleToUpdate = await byId.get(floorRuleId)
        setFloorRule(floorRuleToUpdate)
      } catch (exception) {
        toast.alert('An error occurred while fetching this floor rule.')
        throw exception
      }
    }
  }

  const loadFloorRules = async () => {
    if (all.loading) {
      all.abort()
    }
    const result = await all.get()
    setFloorRules(result)
  }

  const createFloorRule = async (floorRule: FloorRule) => {
    try {
      const newRule = await byId.post(floorRule)
      toast.success(`${newRule.name} successfully created.`)
      navigate('/adstack/floor-price')
    } catch (exception) {
      toast.alert('An error occurred while saving this rule.')
      throw exception
    }
  }

  const updateFloorRule = async (floorRule: FloorRule) => {
    try {
      const updatedFloorRule = await byId.put(floorRule)
      toast.admSuccess(floorRule.name ?? '', floorRule.stacksUse && floorRule.id !== updatedFloorRule.id, navigate)
      navigate('/adstack/floor-price')
    } catch (exception) {
      toast.alert('An error occurred while saving this rule.')
      throw exception
    }
  }

  useEffect(() => {
    loadFloorRules()
    loadFloorRule(floorRuleId)
  }, [floorRuleId])

  return (
    <PureAdStackFloorPriceEditPage
      isEditing={!!floorRuleId}
      isLoading={byId.loading}
      floorRule={floorRule}
      existingFloorRules={floorRules}
      currencySymbol={currencySymbol}
      breadcrumbs={breadcrumbs}
      onCreateFloorRule={createFloorRule}
      onUpdateFloorRule={updateFloorRule}
      onBack={() => navigate(-1)}
    />
  )
}
