import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, FC, FunctionComponent, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import { Icon } from '~/components/Icon'
import { Spinner } from '~/components/Spinner'
import { FilterCategory, FilterCategoryDataItem } from '~/types/analytics'
import { WithClassName } from '~/types/utils'
import { FilterSidebarCategoryExpandAction } from './FilterSidebarCategoryExpandAction'
import { FilterSidebarCategoryItem } from './FilterSidebarCategoryItem'
import { FilterSidebarSearch } from './FilterSidebarSearch'

const FilterCategoryWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  overflow: hidden;
  padding: 12px 0;
  margin: 0 12px;
  border-bottom: 1px solid ${Colors.Silver};

  ${({ expanded }) =>
    expanded &&
    css`
      border-bottom: none;
    `}
`
const FilterCategoryTitleWrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-items: center;
  ${Fonts.colors.Hurricane};
  ${Fonts.P1}
  cursor: pointer;
  transition: all 0.25s;
  clip-path: inset(0px 0px -2px 0px);

  ${({ open }) =>
    open &&
    css`
      margin-bottom: 14px;
    `}
`
const FilterCategoryTitle = styled.div`
  flex: 1 0 auto;
`
const FilterCategoryIcon = styled(Icon)`
  margin-right: 8px;
`
const CategorySpinner = styled(Spinner)`
  width: 32px;
`

const FilterSidebarCategoryItemsWrapper = styled.div`
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
  ${ScrollbarStyle}
`
const FilterCategoryContentWrapper = styled.div<{ isOpen: boolean; isLoading: boolean; isExpanded: boolean }>`
  transition: 0.25s ease-in-out;
  opacity: ${({ isLoading }) => (isLoading ? 0.33 : 1)};
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      ${FilterSidebarCategoryItemsWrapper} {
        overflow: auto;
        flex: 1 1 1px;
      }
    `}
  ${({ isOpen }) =>
    isOpen
      ? css`
          height: auto;
        `
      : css`
          height: 0;
          overflow: hidden;
        `}
`

const FilterCategoryOpenButtonWrapper = styled.button<{ open: boolean }>`
  ${Fonts.P1}
  cursor: pointer;
  border: none;
  background: none;
  ${Fonts.colors.SlateGrey}
  :hover {
    ${Fonts.colors.Jet}
  }

  max-height: 24px;
  max-width: 24px;

  ${Icon} {
    min-width: 20px;
    min-height: 20px;
    transition: 0.25s;
    transform: rotate(${(props) => (props.open ? '-180deg' : '0')});
  }
`

type FilterCategoryOpenButtonProps = WithClassName & ComponentProps<'button'> & { open: boolean }
const FilterCategoryOpenButton: FC<FilterCategoryOpenButtonProps> = ({ className, open, ...props }) => {
  return (
    <FilterCategoryOpenButtonWrapper {...props} open={open} className={className}>
      <Icon width={Sizes[24]} name={'chevron_down'} />
    </FilterCategoryOpenButtonWrapper>
  )
}

export type FilterSidebarCategoryProps = WithClassName & {
  category: FilterCategory
  loading: boolean
  onTitleClick: (category: FilterCategory) => void
  onExpandToggle: (category: FilterCategory) => void
  onSearch?: (search: string, category: FilterCategory) => void
  onSelectAll?: (category: FilterCategory) => void
  onLabelClick: (item: FilterCategoryDataItem, category: FilterCategory) => void
  onCheckboxClick: (item: FilterCategoryDataItem, category: FilterCategory) => void
}

const EmptyFilterSidebarCategoryItemLabel = styled.div`
  flex: 1 1 auto;
  margin: 0 5px 0 4px;
  font-style: italic;
  ${Fonts.colors.SlateGrey}
`

const MAX_ITEM = 8
const _FilterSidebarCategory: FunctionComponent<FilterSidebarCategoryProps> = ({
  category,
  onTitleClick,
  className,
  onExpandToggle,
  onSearch,
  onSelectAll,
  onLabelClick,
  onCheckboxClick,
  loading,
}) => {
  const [search, setSearch] = useState('')
  const maxItems = category.expanded ? Number.MAX_SAFE_INTEGER : MAX_ITEM
  const showMore = !category.expanded && (category.data || []).length > MAX_ITEM
  const showLess = category.expanded
  return (
    <FilterCategoryWrapper className={className} expanded={category.expanded}>
      <FilterCategoryTitleWrapper open={category.open} onClick={() => !loading && onTitleClick(category)}>
        <FilterCategoryIcon name={category.icon} width={Sizes['20']} />
        <FilterCategoryTitle>{category.title}</FilterCategoryTitle>
        {loading ? <CategorySpinner /> : <FilterCategoryOpenButton open={category.open} />}
      </FilterCategoryTitleWrapper>
      {category.expanded && onSearch && (
        <FilterSidebarSearch
          nbResults={category.searchResults?.length || 0}
          searchValue={search}
          setSearchValue={setSearch}
          onSearch={() => onSearch && onSearch(search, category)}
          onSelectAllClick={() => onSelectAll && onSelectAll(category)}
        />
      )}
      <FilterCategoryContentWrapper isOpen={category.open} isLoading={loading} isExpanded={category.expanded}>
        {category.searchResults?.length !== 0 && category.data?.length !== 0 ? (
          <FilterSidebarCategoryItemsWrapper>
            {(category.searchResults || category.data || []).slice(0, maxItems).map((item, index) => (
              <FilterSidebarCategoryItem
                {...item}
                key={index}
                onLabelClick={() => onLabelClick && onLabelClick(item, category)}
                onCheckboxClick={() => onCheckboxClick && onCheckboxClick(item, category)}
              />
            ))}
          </FilterSidebarCategoryItemsWrapper>
        ) : (
          <EmptyFilterSidebarCategoryItemLabel>No {category.title} available for current set of filters or configuration</EmptyFilterSidebarCategoryItemLabel>
        )}

        {(showMore || showLess) && <FilterSidebarCategoryExpandAction onClick={() => onExpandToggle(category)} expanded={category.expanded} />}
      </FilterCategoryContentWrapper>
    </FilterCategoryWrapper>
  )
}

export const FilterSidebarCategory = styled(_FilterSidebarCategory)``
