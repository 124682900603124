import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { AdUnitForm } from './PureAdStackAdUnitEditPage'

const AD_UNIT_PROPERTY_LABEL: { [key in keyof Partial<AdUnitForm>]: string } = {
  adServerAdUnitName: 'Ad server ad unit name',
  divId: 'Technical identification (div ID)',
  devices: 'Devices',
  adFormat: 'Ad format',
  dynamicEnabled: 'Dynamic',
  headerBiddingEnabled: 'Header bidding',
  bannerEnabled: 'Banner',
  skinEnabled: 'Skin',
  fluid: 'Fluid size',
  sizes: 'Standard sizes',
  customSizes: 'Custom sizes',
  canOutstream: 'Outstream',
  slotKeyValues: 'Key-values',
  parallaxEnabled: 'Parallax',
  anchor: 'Anchor',
} as const

const AD_UNIT_PROPERTY_LABEL_KEYS = Object.keys(AD_UNIT_PROPERTY_LABEL)

type PureAdStackAdUnitEditConfirmationModalProps = { onValidate: () => void; updateDiff: Partial<AdUnitForm> }
const _PureAdStackAdUnitEditConfirmationModal: FunctionComponent<PureAdStackAdUnitEditConfirmationModalProps> = ({ onValidate, updateDiff }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Save changes</Modal.Title>

      <Modal.Body>
        The following properties will be updated:
        <ul>
          {Object.keys(updateDiff)
            .filter((k) => AD_UNIT_PROPERTY_LABEL_KEYS.includes(k))
            .sort((a, b) => AD_UNIT_PROPERTY_LABEL_KEYS.indexOf(a) - AD_UNIT_PROPERTY_LABEL_KEYS.indexOf(b))
            .map((k, i) => (
              <li key={i}>{AD_UNIT_PROPERTY_LABEL[k as keyof Partial<AdUnitForm>]}</li>
            ))}
        </ul>
        Do you confirm ?
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onValidate}>
          Validate
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

export const PureAdStackAdUnitEditConfirmationModal = styled(_PureAdStackAdUnitEditConfirmationModal)``
