import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { getNumberOfWeeks } from '@pubstack/common/src/date'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import { Colors } from '~/assets/style/colors'
import { ElevationLevel } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import { FilterChip, FilterChipProps } from '~/components/FilterChip'
import { IconName } from '~/components/Icon'
import TimeSelector, { TimeSelectorProps } from '~/components/TimeSelector'
import { Tooltip } from '~/components/Tooltip'
import { Filter } from '~/state'
import { WithClassName } from '~/types/utils'
import { isFilterAvailable } from '~/utils/analytics'

type PureAnalyticsPageProps = WithClassName &
  Omit<TimeSelectorProps, 'presets'> & {
    timePresets: TimeSelectorProps['presets']
    comparedTo: boolean
    filters: {
      [key in Dimension]?: Filter
    }
    filterDimensions: Dimension[]
    onFilterClick: (dimension: Dimension) => void
    filterSidebar: ReactElement
    title?: ReactElement
    timezone: Timezone
    maxNumberOfDayInTimerangeSelected: number
  }

const PureAnalyticsPageWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-areas: 'content filter';
  grid-template-columns: minmax(750px, auto) 283px;
  grid-template-rows: 1fr;
  overflow: auto;
  min-width: 100%;
`
const PureAnalyticsPageContentWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'content';
  grid-template-rows: min-content minmax(0, auto);
  grid-area: content;
  overflow: auto;
  padding: 0 20px 0;
  ${ScrollbarStyle};
`

const PaddingBottom = styled.div`
  height: 0;
  min-height: 0;
  max-height: 0;
  padding-bottom: 16px;
`

const PureAnalyticsPageTimeSelector = styled(TimeSelector)`
  margin-bottom: 16px;
  flex: 1 0 auto;
`
const PureAnalyticsPageFilterSidebar = styled.div`
  grid-area: filter;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${ScrollbarStyle};
  background: ${Colors.White};
`
const PureAnalyticsPageContentChildrenWrapperWrapper = styled.div`
  // This div ensures the children height size will be okay
  grid-area: content;
  display: flex;
  flex-direction: row;
`

const PureAnalyticsPageContentChildrenWrapper = styled.div`
  // This div is here to have the PaddingBottom component after the children
  display: flex;
  flex-direction: column;
  flex: 1;
`
const PureAnalyticsPageHeaderWrapper = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background: ${Colors.Ghost};
  ${ElevationLevel.low}
  flex: 0 0 auto;
`

const PureAnalyticsTitle = styled.div`
  flex: 0 1 0;
  margin: 20px 0;
`

const AnalyticsFilterChip = styled(FilterChip)`
  max-width: 300px;
`

// TODO 2022-06-01 cfo - check how to not duplicate this config as it's already in filter sidebar state
const CONFIG: { [key in Dimension]: { icon: IconName; name: string } } = {
  tagId: { icon: 'site', name: 'Sites' },
  bidder: { icon: 'bidder', name: 'Bidders' },
  adUnit: { icon: 'picture_in_picture', name: 'Ad units' },
  device: { icon: 'device', name: 'Devices' },
  country: { icon: 'position', name: 'Countries' },
  size: { icon: 'size', name: 'Sizes' },
  stackIdVersion: { icon: 'ad_stack', name: 'Stacks' },
  pubstackRefresh: { icon: 'refresh', name: 'Pubstack Refresh' },
  pubstackInventoryType: { icon: 'empty', name: 'Pubstack Inventory Type' }, // 2022-09-29 les bvion - We are forced to add this into this list because of silent inventory type filter in AMP & APP dashboards
  pubstackDemandChannel: { icon: 'bidder', name: 'Demand Channels' },
  abTestPopulation: { icon: 'population_split', name: 'A/B test' },
  adUnitLevel1: { icon: 'path', name: 'Ad unit path: Level 1' },
  adUnitLevel2: { icon: 'path', name: 'Ad unit path: Level 2' },
  adUnitLevel3: { icon: 'path', name: 'Ad unit path: Level 3' },
  adUnitLevel4: { icon: 'path', name: 'Ad unit path: Level 4' },
  adUnitLevel5: { icon: 'path', name: 'Ad unit path: Level 5' },
  mediaType: { icon: 'video', name: 'Media type' },
}

const _PureAnalyticsPageFilter: FunctionComponent<{ filter: Filter; filterDimensions: Dimension[]; onClick: () => void } & WithClassName> = ({ filter, onClick, filterDimensions, className }) => {
  const appearance: FilterChipProps['appearance'] = isFilterAvailable(filter, filterDimensions) ? filter.mode : 'disable'
  let chipTooltip: string
  if (appearance === 'disable') {
    chipTooltip = `${CONFIG[filter.name].name} disabled on this page`
  } else {
    chipTooltip = `${filter?.mode === 'exclude' ? 'Excluded' : 'Selected'} ${CONFIG[filter.name].name.toLowerCase()}: ${filter?.values.map((f) => `${f.label} ${f.sublabel || ''}`).join(', ')}`
  }
  return (
    <Tooltip title={chipTooltip} className={className} offset={2}>
      <AnalyticsFilterChip icon={CONFIG[filter.name].icon} text={filter?.values.map((f) => `${f.label} ${f.sublabel || ''}`).join(', ') || ''} appearance={appearance} onClick={onClick} />
    </Tooltip>
  )
}
const PureAnalyticsPageFilter = styled(_PureAnalyticsPageFilter)``

const PureAnalyticsPageChipsWrapper = styled.div`
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
  align-items: center;
  overflow: auto;
  margin-bottom: 10px;
`

export const displayTimeshift = (from: DateTime, to: DateTime, today: DateTime = DateTime.local()) => {
  const hasDailyData = from.startOf('day') < today.startOf('day').minus({ days: 14 })
  const lessThan1Week = getNumberOfWeeks(from, to) <= 1

  if (hasDailyData) {
    return lessThan1Week && from >= today.startOf('day').minus({ days: 93 }).plus({ weeks: 1 })
  } else {
    return lessThan1Week && from >= today.startOf('day').minus({ days: 14 }).plus({ weeks: 1 })
  }
}

const _PureAnalyticsPage: FunctionComponent<PropsWithChildren<PureAnalyticsPageProps>> = ({
  className,
  children,
  onRefreshClick,
  onUpdate,
  timePresets,
  today,
  selectedRange,
  filters,
  filterDimensions,
  onFilterClick,
  filterSidebar,
  selectableInterval,
  title,
  timezone,
  maxNumberOfDayInTimerangeSelected,
}) => {
  const filtersToShow = (Object.keys(filters) as (keyof typeof filters)[]).filter((key) => filters[key]?.values.length)
  return (
    <PureAnalyticsPageWrapper className={className}>
      <PureAnalyticsPageContentWrapper>
        <PureAnalyticsPageHeaderWrapper>
          <PureAnalyticsTitle>{title}</PureAnalyticsTitle>
          <PureAnalyticsPageTimeSelector
            presets={timePresets}
            onRefreshClick={onRefreshClick}
            onUpdate={onUpdate}
            selectedRange={selectedRange}
            today={today}
            selectableInterval={selectableInterval}
            timezone={timezone}
            maxNumberOfDayInTimerangeSelected={maxNumberOfDayInTimerangeSelected}
          />
          {filtersToShow.length > 0 && (
            <PureAnalyticsPageChipsWrapper>
              {filtersToShow.map((key) => (
                <PureAnalyticsPageFilter filter={filters[key] as Filter} filterDimensions={filterDimensions} key={key} onClick={() => onFilterClick(key)} />
              ))}
            </PureAnalyticsPageChipsWrapper>
          )}
        </PureAnalyticsPageHeaderWrapper>
        <PureAnalyticsPageContentChildrenWrapperWrapper>
          <PureAnalyticsPageContentChildrenWrapper>
            {children}
            <PaddingBottom />
          </PureAnalyticsPageContentChildrenWrapper>
        </PureAnalyticsPageContentChildrenWrapperWrapper>
      </PureAnalyticsPageContentWrapper>
      <PureAnalyticsPageFilterSidebar>{filterSidebar}</PureAnalyticsPageFilterSidebar>
    </PureAnalyticsPageWrapper>
  )
}

export const PureAnalyticsPage = styled(_PureAnalyticsPage)``
