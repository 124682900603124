import { RefreshGroup } from '@pubstack/common/src/refresh'
import { useMemo } from 'react'

export const useValidateRefreshGroup = (refreshGroup?: RefreshGroup) => {
  const isNameValid = useMemo(() => {
    if (!refreshGroup) return false
    return !!refreshGroup.name
  }, [refreshGroup?.name])

  const isAdunitsValid = useMemo(() => {
    if (!refreshGroup) return false
    return !!refreshGroup.adUnits.length
  }, [refreshGroup?.adUnits])

  const isSitesValid = useMemo(() => {
    if (!refreshGroup) return false
    return !!refreshGroup.tagIds.length
  }, [refreshGroup?.tagIds])

  const isDevicesValid = useMemo(() => {
    if (!refreshGroup) return false
    return !!refreshGroup.devices.length
  }, [refreshGroup?.devices])

  const isKeyValuesValid = useMemo(() => {
    if (!refreshGroup) return false
    if (refreshGroup.keyValueTargetings) {
      return refreshGroup.keyValueTargetings.every((kvt) => (kvt.key && kvt.value.length) || (!kvt.key && !kvt.value.length))
    }
    return true
  }, [refreshGroup?.keyValueTargetings])

  const isRulesValid = useMemo(() => {
    if (!refreshGroup) return false
    if (refreshGroup.specificRules) {
      return refreshGroup.specificRules.every((rule) => (rule.bidderName && rule.timer > 0) || (!rule.bidderName && !rule.timer)) && refreshGroup.timer > 0
    }
    return refreshGroup.timer > 0
  }, [refreshGroup?.specificRules, refreshGroup?.timer])

  const isCappingValid = useMemo(() => {
    if (!refreshGroup) return false
    if (refreshGroup.capping) {
      return refreshGroup.capping > 0
    }
    return refreshGroup.capping?.toString() !== ''
  }, [refreshGroup?.capping])

  const isCustomSettingsValid = useMemo(() => {
    if (!refreshGroup) return false
    if (refreshGroup.populationSplit) {
      return refreshGroup.populationSplit > 0
    }
    return !refreshGroup.blockerEnabled && refreshGroup.populationSplit?.toString() !== ''
  }, [refreshGroup?.blockerEnabled, refreshGroup?.populationSplit])

  return {
    name: isNameValid,
    sites: isSitesValid,
    adUnits: isAdunitsValid,
    devices: isDevicesValid,
    keyValueTargetings: isKeyValuesValid,
    rules: isRulesValid,
    capping: isCappingValid,
    customSettings: isCustomSettingsValid,
  }
}
