import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  > * {
    margin-bottom: 2rem;
  }
`

const Title = styled.div`
  ${Fonts.H1};
  ${Fonts.colors.Jet};
  font-weight: 700;
`

const Description = styled.div`
  ${Fonts.P1};
`

type PureAnalyticsDemoRequestPageProps = WithClassName & {
  isEmailSent: boolean
  isRequestPending: boolean
  isSending: boolean
  onSendRequestClick: () => void
}
const _PureAnalyticsDemoRequestPage: FunctionComponent<PureAnalyticsDemoRequestPageProps> = ({ className, isEmailSent, isRequestPending, isSending, onSendRequestClick }) => (
  <Wrapper className={className}>
    {isEmailSent ? (
      <>
        <Icon name={'paperplane_round'} width={'160px'} />
        <Title>Thank you, your request has been successfully sent!</Title>
        <Description>We will come back to you as soon as possible.</Description>
      </>
    ) : (
      <>
        <Icon name={'lock_round'} width={'160px'} />
        <Title>It looks like you don&apos;t have access to this page.</Title>
        <Description>
          This powerful module allows you to monitor your programmatic revenue in real time, analyze SSPs&apos; performances
          <br />
          and quickly troubleshoot your ad stack.
        </Description>
        {isRequestPending ? (
          <Description>Your request has been sent. We will come back to you as soon as possible.</Description>
        ) : (
          <>
            <Description>Send us a request to get more information and unlock your data!</Description>
            <Button disabled={isSending} variant={'primary'} onClick={onSendRequestClick}>
              {isSending ? 'Sending request...' : 'Send request'}
            </Button>
          </>
        )}
      </>
    )}
  </Wrapper>
)
export const PureAnalyticsDemoRequestPage = styled(_PureAnalyticsDemoRequestPage)``
