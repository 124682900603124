import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { AdUnit } from '@pubstack/common/src/adunit'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { AdunitDeviceRuleConfig, FloorRule, HeaderBiddingRule, LazyLoadingRule, RefreshRule, Stack } from '@pubstack/common/src/stack'
import { FunctionComponent, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Skeleton } from '~/components/Skeleton'
import { StepperIndicator } from '~/components/StepperIndicator'
import { useToast } from '~/components/Toast/useToasts'
import { PureAdStackEditPage as EditPage } from '~/modules/adstack/PureAdStackEditPage'
import { WithClassName } from '~/types/utils'
import { useCustomStep } from '~/utils/useCustomStep'
import { PureStackAdUnitSelectionStep } from './PureStackAdUnitSelectionStep'
import { PureStackHeaderBiddingRulesStep } from './PureStackHeaderBiddingRulesStep'
import { PureStackRulesStep } from './PureStackRulesStep'

const CardContent = styled.div`
  padding: 20px 0;
`

const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: end;
`

const PreviousButton = styled(Button)`
  margin-right: auto;
`

const CardTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const CardTitle = styled.h2`
  ${Fonts.colors.Jet}
  ${Fonts.H1}
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  margin: 0;
  flex: 1;
`

export type StackFormData = Pick<
  Stack,
  'name' | 'contextId' | 'desktopAdUnits' | 'mobileAdUnits' | 'headerBiddingEnabled' | 'adCallTimeout' | 'lazyLoadingRules' | 'floorsRules' | 'commitMessage' | 'refreshRules' | 'headerBiddingRuleId'
>

type PureStackEditProps = WithClassName & {
  breadcrumbs: React.ReactNode
  stack: StackFormData
  selectableAdUnits: AdUnit[]
  contextOptions: SelectOptionProp[]
  selectableFloorRules: FloorRule[]
  selectableLazyLoadingRules: LazyLoadingRule[]
  selectableRefreshRules: RefreshRule[]
  selectableHeaderBiddingRules: HeaderBiddingRule[]
  currencySymbol: CurrencySymbol
  siteName: string
  validateStackUniqueName: () => Promise<boolean>
  onCancel: () => unknown
  onSave: (stack: StackFormData, saveAsDraft: boolean) => Promise<void>
  isSaving: boolean
  isEditing: boolean
  isLoading: boolean
  hasAdmRefresh: boolean
  bidderAdapters: BidderAdapter[]
  onHeaderBiddingRuleEdit: (id: string) => void
}
const _PureStackEdit: FunctionComponent<PureStackEditProps> = ({
  className,
  breadcrumbs,
  stack,
  selectableAdUnits,
  contextOptions,
  selectableFloorRules,
  selectableLazyLoadingRules,
  selectableRefreshRules,
  selectableHeaderBiddingRules,
  currencySymbol,
  siteName,
  onCancel,
  onSave,
  validateStackUniqueName,
  isSaving,
  isEditing,
  isLoading,
  hasAdmRefresh,
  bidderAdapters,
  onHeaderBiddingRuleEdit,
}) => {
  const formSteps = 3
  const [currentStep, stepHelpers] = useCustomStep(formSteps)
  const toast = useToast()
  const {
    control,
    trigger,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<StackFormData>({
    defaultValues: {
      ...stack,
      headerBiddingEnabled: stack.headerBiddingEnabled ?? true,
      adCallTimeout: stack.adCallTimeout ?? 3000,
    },
    mode: 'onChange',
  })

  const { name } = useWatch({ control })

  const onContinue = async () => {
    const valid = await trigger(['desktopAdUnits', 'mobileAdUnits', 'name', 'contextId'])
    if (valid) {
      if (currentStep === 1) {
        updateRules()
      }
      stepHelpers.goToNextStep()
    } else {
      errors.desktopAdUnits?.message && errors.desktopAdUnits?.type === 'validate' && toast.alert(errors.desktopAdUnits.message)
      errors.mobileAdUnits?.message && errors.mobileAdUnits?.type === 'validate' && toast.alert(errors.mobileAdUnits.message)
    }
  }

  const onSubmit = (stack: StackFormData, saveAsDraft: boolean) => {
    onSave(stack, saveAsDraft)
  }

  const updateRules = () => {
    const desktopAdUnitsIds = (getValues('desktopAdUnits') ?? []).map((a) => a.adUnitId)
    const mobileAdUnitsIds = (getValues('mobileAdUnits') ?? []).map((a) => a.adUnitId)

    const map = (rules: AdunitDeviceRuleConfig[]): AdunitDeviceRuleConfig[] => {
      const adUnitsById = selectableAdUnits.reduce<{ [key: string]: AdUnit }>((acc, adunit) => ({ ...acc, [adunit.id]: adunit }), {})
      return rules.map((r) => {
        const idsToKeep = r.device === 'desktop' ? desktopAdUnitsIds : mobileAdUnitsIds
        const adUnitsToKeep = idsToKeep.map((i) => adUnitsById[i].name)
        // WARNING: it's not ids... don't get fooled by the name...
        return { ...r, adUnitIds: r.adUnitIds.filter((adUnitId) => adUnitsToKeep.includes(adUnitId)) }
      })
    }
    const floorsRules = getValues('floorsRules')
    if (floorsRules) {
      setValue('floorsRules', map(floorsRules))
    }
    const refreshRules = getValues('refreshRules')
    if (refreshRules) {
      setValue('refreshRules', map(refreshRules))
    }
  }

  useEffect(() => {
    reset({ ...stack, adCallTimeout: stack.adCallTimeout ?? 3000 })
  }, [stack])

  return (
    <EditPage
      title={
        <CardTitleWrapper>
          <CardTitle>{isLoading ? <Skeleton bigger width={'120px'} /> : isEditing ? `Edit stack: ${stack.name}` : `New stack${name ? `: ${name}` : ''}`}</CardTitle>
          <StepperIndicator steps={formSteps} color={Colors.Lilac} currentStep={currentStep} />
        </CardTitleWrapper>
      }
      breadcrumbs={breadcrumbs}
      color={Colors.Lilac}
      onBackClick={onCancel}
    >
      <form className={className} id={'stackForm'}>
        <CardContent>
          {currentStep === 1 && (
            <PureStackAdUnitSelectionStep
              control={control}
              errors={errors}
              selectableAdUnits={selectableAdUnits}
              contextOptions={contextOptions}
              stackName={stack.name}
              validateUniqueName={validateStackUniqueName}
              isEditing={isEditing}
              isLoading={isLoading}
            />
          )}
          {currentStep === 2 && (
            <PureStackHeaderBiddingRulesStep
              control={control}
              errors={errors}
              selectableHeaderBiddingRules={selectableHeaderBiddingRules}
              bidderAdapters={bidderAdapters}
              onEdit={onHeaderBiddingRuleEdit}
            />
          )}
          {currentStep === 3 && (
            <PureStackRulesStep
              setValue={setValue}
              trigger={trigger}
              control={control}
              errors={errors}
              selectableAdUnits={selectableAdUnits}
              selectableLazyLoadingRules={selectableLazyLoadingRules}
              selectableFloorRules={selectableFloorRules}
              selectableRefreshRules={selectableRefreshRules}
              currencySymbol={currencySymbol}
              siteName={siteName}
              hasAdmRefresh={hasAdmRefresh}
            />
          )}
        </CardContent>
        <CardActions>
          {stepHelpers.canGoToPrevStep && (
            <PreviousButton variant={'tertiary'} onClick={stepHelpers.goToPrevStep}>
              Previous
            </PreviousButton>
          )}
          <Button variant={'tertiary'} onClick={onCancel}>
            Cancel
          </Button>
          {stepHelpers.canGoToNextStep ? (
            <Button variant={'primary'} onClick={onContinue} disabled={isLoading}>
              Continue
            </Button>
          ) : (
            <>
              <Button variant={'secondary'} disabled={isSaving || isLoading} onClick={handleSubmit((stack) => onSubmit(stack, true))}>
                Save as draft
              </Button>
              <Button variant={'primary'} disabled={isSaving || isLoading} onClick={handleSubmit((stack) => onSubmit(stack, false))}>
                Validate
              </Button>
            </>
          )}
        </CardActions>
      </form>
    </EditPage>
  )
}
export const PureStackEdit = styled(_PureStackEdit)``
