import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Color, ColorUtil, Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes, Transitions } from '~/assets/style/tokens'
import { WithClassName } from '~/types/utils'
import { Icon, IconName } from './Icon'

/**
 * returns Jet color or White based on which contrast is better
 * @param bgColor background color
 * @returns Colors.Jet or Colors.White
 */
const getTextColor = (bgColor?: Color) => {
  if (!bgColor) return Colors.Jet
  const bgToJetratio = ColorUtil.getHexContrastRatio(bgColor, Colors.Jet)
  const bgToWhiteRatio = ColorUtil.getHexContrastRatio(bgColor, Colors.White)
  return bgToJetratio > bgToWhiteRatio ? Colors.Jet : Colors.White
}

const IconWrapper = styled.span<{ side: 'left' | 'right'; variant?: 'regular' | 'small' }>`
  ${({ side, variant }) => {
    const size = variant === 'small' ? Sizes[2] : Sizes[4]
    return side === 'left'
      ? css`
          margin-right: ${size};
        `
      : css`
          margin-left: ${size};
        `
  }}
  display: inherit;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        transform: scale(1.15);
      }

      &:active {
        transform: scale(0.97);
      }
    `}
`
const ChipTextWrapper = styled.span<{ variant?: 'small' | 'regular' }>`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${Fonts.P2}

  ${({ variant }) =>
    variant === 'small' &&
    css`
      ${Fonts.P3}
    `}
`
const ChipWrapper = styled.button<{ backgroundColor: Color; variant?: 'regular' | 'small' }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ backgroundColor }) => getTextColor(backgroundColor)};
  border: 1px solid ${({ backgroundColor }) => (ColorUtil.getHexContrastRatio(backgroundColor, Colors.White) < 1.1 ? Colors.Platinum : backgroundColor)};
  border-radius: 32px;

  box-sizing: border-box;

  height: 26px;
  padding: 0 8px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  transition: ${Transitions.quick};
  flex: 0 1 auto;

  ${({ variant }) =>
    variant === 'small' &&
    css`
      padding: 0 4px;
      height: 20px;
      line-height: 20px;
    `}

  ${({ onClick, backgroundColor }) => {
    if (onClick) {
      return css`
        cursor: pointer;
        &:hover {
          background-color: ${ColorUtil.varyColorLightness(backgroundColor, -3)};
          border-color: ${ColorUtil.varyColorLightness(backgroundColor, -3)};
        }
        &:active {
          transform: scale(0.97);
        }
      `
    }
  }}

  & ${Icon} {
    color: ${({ backgroundColor }) => getTextColor(backgroundColor)};
  }
`

export type ChipProps = WithClassName & {
  iconLeft?: IconName
  iconRight?: IconName
  onClick?: () => void
  onRightIconClick?: () => void
  onLeftIconClick?: () => void
  text: string
  color?: Color
  variant?: 'regular' | 'small'
}

const _Chip: FunctionComponent<ChipProps> = ({ text, iconLeft, color, iconRight, onClick, onLeftIconClick, onRightIconClick, variant, className }) => {
  const iconSize = variant === 'small' ? '14px' : '15px'
  return (
    <ChipWrapper type={'button'} backgroundColor={color ?? Colors.Platinum} onClick={onClick} variant={variant} className={className}>
      {iconLeft && (
        <IconWrapper
          side={'left'}
          onClick={
            onLeftIconClick &&
            ((event) => {
              event.stopPropagation()
              onLeftIconClick()
            })
          }
        >
          <Icon name={iconLeft} width={iconSize} />
        </IconWrapper>
      )}
      <ChipTextWrapper variant={variant}>{text}</ChipTextWrapper>
      {iconRight && (
        <IconWrapper
          side={'right'}
          onClick={
            onRightIconClick &&
            ((event) => {
              event.stopPropagation()
              onRightIconClick()
            })
          }
          variant={variant}
        >
          <Icon name={iconRight} width={iconSize} />
        </IconWrapper>
      )}
    </ChipWrapper>
  )
}

const Chip = styled(_Chip)``
export default Chip
