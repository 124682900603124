import { useRecoilState } from 'recoil'
import { useScopes } from '~/api/api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { currentScopeState } from '~/state'
import { PureSettingsSecurityPage } from './PureSettingsSecurityPage'

export const SettingsSecurityPage = () => {
  const [currentScope, setCurrentScope] = useRecoilState(currentScopeState)
  const { byId } = useScopes(null)
  const toast = useToast()
  const onActivateMfa = async () => {
    try {
      await byId.put({ ...currentScope, mfaRequired: true })
      setCurrentScope((scope) => ({ ...scope, mfaRequired: true }))
      toast.success('MFA has been activated for all users in this account.')
    } catch (e) {
      toast.alert(`Something went wrong when trying to activate MFA`)
    }
  }

  return <PureSettingsSecurityPage scope={currentScope} onActivateMfa={onActivateMfa} />
}
