import styled from '@emotion/styled'
import { AdmQaReport } from '@pubstack/common/src/adm-qa-reports'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { AdminTag } from '@pubstack/common/src/tag'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Color, Colors, DataColors } from '~/assets/style/colors'
import { BorderRadius } from '~/assets/style/tokens'
import Button from '~/components/Button'
import Chip from '~/components/Chip'
import { IconName } from '~/components/Icon'
import { Message } from '~/components/Message'
import { Skeleton } from '~/components/Skeleton'
import { AnalyticsChartsTooltipHidden } from '~/modules/analytics/AnalyticsChartsTooltip'
import { ADM_QA_REPORTS_TIME_RANGE_LABELS, AdmQaReportsDimName, AuctionsImpressionsData, BidsData, UserSessionData, ViewabilityData } from './admQaReports'
import { PureAuctionsImpressionsChart } from './charts/PureAuctionsImpressionsChart'
import { PureBidsChart } from './charts/PureBidsChart'
import { PureUserSessionChart } from './charts/PureUserSessionChart'
import { PureViewabilyChart } from './charts/PureViewabilityChart'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
`

const AnalysisContainer = styled.div`
  display: flex;
  gap: 20px;
`

const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const Dimensions = styled.div`
  ${BorderRadius.style}
  border: 1px solid ${Colors.Platinum};
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  min-height: 0;
  gap: 10px;

  align-items: start;
`

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
`

const Versus = styled.div`
  display: flex;
  gap: 20px;
  margin-left: 20px;
`

const SiteTitle = styled.div<{ color: Color }>`
  display: inline-flex;

  border-left: 5px solid ${({ color }) => color};

  padding: 5px;

  span {
    display: inline-flex;
    gap: 4px;
    align-items: center;
  }
  flex-direction: column;
`
const ReportQuerySummary = styled.div`
  ${BorderRadius.style}
  border: 1px solid ${Colors.Platinum};
  padding: 5px;
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: ${Colors.White};
`

const isoTo24Time = (iso: string): string => {
  return DateTime.fromISO(iso).toLocaleString(DateTime.TIME_24_SIMPLE)
}

const isoToWeekDayDate = (iso: string): string => {
  return DateTime.fromISO(iso).setLocale('en-GB').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
}

const ReportTitle = ({ sites, report, isLoading }: { sites: AdminTag[]; report: AdmQaReport; isLoading: boolean }) => {
  const siteA = sites.find((site) => site.id === report.siteIdA)?.name ?? 'N/A'
  const siteB = sites.find((site) => site.id === report.siteIdB)?.name ?? 'N/A'
  const daySiteA = isoToWeekDayDate(report.startDateSiteA)
  const daySiteB = isoToWeekDayDate(report.endDateSiteB)
  const timeSiteA = `${isoTo24Time(report.startDateSiteA)} to ${isoTo24Time(report.endDateSiteA)}`
  const timeSiteB = `${isoTo24Time(report.startDateSiteB)} to ${isoTo24Time(report.endDateSiteB)}`

  return (
    <Versus>
      <SiteTitle color={DataColors.Kaiminus}>
        {isLoading ? (
          <Skeleton width={'300px'} />
        ) : (
          <>
            <h2>{siteA}</h2>
            <span>
              {timeSiteA}, {daySiteA}
            </span>
          </>
        )}
      </SiteTitle>
      <SiteTitle color={DataColors.Pumpkin}>
        {isLoading ? (
          <Skeleton width={'300px'} />
        ) : (
          <>
            <h2>{siteB}</h2>
            <span>
              {timeSiteB}, {daySiteB}
            </span>
          </>
        )}
      </SiteTitle>
    </Versus>
  )
}

const DimToIcon: { [key in AdmQaReportsDimName]: IconName } = {
  adunit: 'picture_in_picture',
  biddercode: 'bidder',
  device: 'device',
  size: 'size',
  website: 'site',
  gampath: 'picture_in_picture',
}

type PureAdmQaReportAnalysisPageProps = {
  report: AdmQaReport
  sites: AdminTag[]
  isLoading: boolean
  bidsData: BidsData
  auctionsImpressionsData: AuctionsImpressionsData
  viewabilityData: ViewabilityData
  userSessionData: UserSessionData
  currencySymbol: CurrencySymbol
  onClickBack: () => void
  applicableDimensions: AdmQaReportsDimName[]
}
const _PureAdmQaReportAnalysisPage = ({
  report,
  sites,
  isLoading,
  bidsData,
  auctionsImpressionsData,
  viewabilityData,
  userSessionData,
  currencySymbol,
  onClickBack,
  applicableDimensions,
}: PureAdmQaReportAnalysisPageProps) => {
  const [currentDimension, setCurrentDimension] = useState<AdmQaReportsDimName>('adunit')

  const siteNames = sites.map((site) => site.name)

  return (
    <Container>
      {(auctionsImpressionsData.adunit.length === 0 || bidsData.adunit.length === 0) && (
        <Message title={'Reports appear to be empty'} description={'Make sure you have the correct sites and dates and try generating another one.'} />
      )}
      <AnalyticsChartsTooltipHidden />
      <ReportQuerySummary>
        {isLoading ? (
          <Skeleton width={'300px'} />
        ) : (
          <>
            <span>Live on {DateTime.fromISO(report.admLiveDate).setLocale('en-GB').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}</span>
            <span>{ADM_QA_REPORTS_TIME_RANGE_LABELS[report.range]}</span>
          </>
        )}
      </ReportQuerySummary>
      <TitleBar>
        <ReportTitle isLoading={isLoading} sites={sites} report={report} />
        <Button variant={'tertiary'} onClick={() => onClickBack()}>
          Back
        </Button>
      </TitleBar>
      <AnalysisContainer>
        <ChartsContainer>
          <PureAuctionsImpressionsChart
            currencySymbol={currencySymbol}
            dimension={currentDimension}
            iconName={DimToIcon[currentDimension]}
            isLoading={isLoading}
            rawData={auctionsImpressionsData}
            report={report}
            sites={siteNames}
          />
          <PureBidsChart
            currencySymbol={currencySymbol}
            dimension={currentDimension}
            iconName={DimToIcon[currentDimension]}
            isLoading={isLoading}
            rawData={bidsData}
            report={report}
            sites={siteNames}
          />
          <PureViewabilyChart
            currencySymbol={currencySymbol}
            dimension={currentDimension}
            iconName={DimToIcon[currentDimension]}
            isLoading={isLoading}
            rawData={viewabilityData}
            report={report}
            sites={siteNames}
          />
          <PureUserSessionChart
            currencySymbol={currencySymbol}
            dimension={currentDimension}
            iconName={DimToIcon[currentDimension]}
            isLoading={isLoading}
            rawData={userSessionData}
            report={report}
            sites={siteNames}
          />
        </ChartsContainer>
        <Dimensions>
          <h2>Dimension</h2>
          {applicableDimensions.map((dim) => (
            <Chip iconLeft={DimToIcon[dim]} key={dim} onClick={() => setCurrentDimension(dim)} color={currentDimension === dim ? Colors.King : Colors.White} text={dim} />
          ))}
        </Dimensions>
      </AnalysisContainer>
    </Container>
  )
}

export const PureAdmQaReportAnalysisPage = styled(_PureAdmQaReportAnalysisPage)``
