import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Flyout } from '~/components/Flyout'
import { WithClassName } from '~/types/utils'

type PureAdStackIntegrationHelpFlyoutProps = WithClassName & {
  isOpen: boolean
  onClose: () => unknown
}

const HelpText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    margin-bottom: 12px;
  }

  ul,
  ol {
    margin: 0;
    margin-left: 20px;
    padding: 0;
  }

  & div > p {
    display: inline-flex;
    flex-direction: column;
  }
`

const _PureAdStackIntegrationHelpFlyout: FunctionComponent<PureAdStackIntegrationHelpFlyoutProps> = ({ className, isOpen, onClose }) => {
  return (
    <Flyout className={className} isOpen={isOpen} onClose={onClose} title={'Help'} color={Colors.Ming}>
      <HelpText>
        <div>
          <h2>How can I integrate a Prebid bidder to my site?</h2>
          <ol>
            <li>Validate the adapter name. It can be the bidder name or a customized name as an alias.</li>
            <li>Download the mapping template related to this bidder.</li>
            <li>Fill the template with corresponding bid params.</li>
            <li>Upload the filled mapping csv file.</li>
            <li>Pubstack will check file integrity.</li>
            <li>Apply the changes to use the bidder in production!</li>
          </ol>
        </div>

        <div>
          <h2>Where can I find the bidder informations?</h2>
          <ul>
            <li>Check bid params on the bidder’s site.</li>
            <li>Contact directly your account manager’s bidder partner.</li>
          </ul>
        </div>

        <div>
          <h2>What if I made a mistake?</h2>
          <p>
            <span>If bid params are wrong, the bidder will not be called for the corresponding ad unit, which will not be monetized by the bidder.</span>
            <span>Please ensure that:</span>
          </p>
          <ul>
            <li>Uploaded mapping file corresponds to the right bidder partner.</li>
            <li>Bid params are well filled: be careful of type and values defined on bidder’s site.</li>
          </ul>
        </div>

        <div>
          <h2>Note about array values</h2>
          <p>
            <span>Array values are a specific case since they use commas to separate values, which is also the case for CSV files.</span>
            <span>To avoid any errors, please follow these rules when filling in array values:</span>
          </p>
          <ul>
            <li>
              Values are contained between brackets (<code>[]</code>).
            </li>
            <li>
              The whole array is surrounded with quotes (<code>&quot;&quot;</code>). This is mandatory but usually done automatically by your spreadsheet software.
            </li>
          </ul>
          <p>
            A proper array value should look like this in the CSV file: <code>&quot;[value1,value2]&quot;</code>
          </p>
        </div>

        <div>
          <h2>What if I do not want to map everything?</h2>
          <p>If you do not want to map a specific line, leave it in the file with empty params.</p>
        </div>
      </HelpText>
    </Flyout>
  )
}

export const PureAdStackIntegrationHelpFlyout = styled(_PureAdStackIntegrationHelpFlyout)``
