import { Stack, StackGroup } from '@pubstack/common/src/stack'
import { useMemo } from 'react'

export const groupStacks = (stacks: Stack[]) => {
  const result: StackGroup[] = []
  stacks.forEach((stack) => {
    const currentStackGroup = result.find((s) => stack.name === s.groupName)
    if (!currentStackGroup) {
      result.push({
        priority: stack.priority || [...new Set(stacks.filter((s) => s.enabled).map((s) => s.stackId)).values()].length,
        groupName: stack.name,
        stackId: stack.stackId,
        stacks: [{ ...stack }],
      })
    } else {
      currentStackGroup.stacks.push({ ...stack })
    }
  })
  const groups = result.sort((a, b) => a.priority - b.priority)
  const activeGroups = groups.filter((g) => g.stacks.every((s) => s.enabled))
  const archivedGroups = groups.filter((g) => g.stacks.some((s) => !s.enabled))
  return { activeGroups, archivedGroups }
}

export const useStacksGroups = (stacks: Stack[]): { activeGroups: StackGroup[]; archivedGroups: StackGroup[] } =>
  useMemo(() => {
    return groupStacks(stacks)
  }, [stacks])
