import styled from '@emotion/styled'
import { AdUnitLevelDimensions, Dimension, MobileInventoryType } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, AnalyticsQueryDashboardName, GamOverviewMetricsByTime, MappedName } from '@pubstack/common/src/analytics/query'
import { sameDay } from '@pubstack/common/src/date'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useAnalyticsQuery } from '~/api/api.hook'
import { Link, LinkIcon } from '~/components/Link'
import { SelectOptionProp, SelectableOptionsPopover } from '~/components/SelectableOptionsPopover'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { videoCompletionRate, videoViewershipTotalErrorCount } from '~/modules/analytics/formulas'
import { useBuildContextWithFilterEnforced } from '~/modules/analytics/useContext'
import { contextState } from '~/state'
import { WithClassName } from '~/types/utils'
import { getFilterSidebarContent, logSortBreakdownAction, onBreakdownRowClick } from '~/utils/analytics'
import { GamOverviewDimensions } from '~/utils/gamOverview'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureGamOverviewBreakdown } from './PureGamOverviewBreakdown'
import { PureGamOverviewTimeline } from './PureGamOverviewTimeline'
import { PureGamOverwiewWidgetFunnel } from './PureGamOverviewWidgetFunnel'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const SortSelect = styled(SelectableOptionsPopover)`
  margin-left: 4px;
`
const SortLink = styled(Link)<{ open: boolean }>`
  ${LinkIcon} {
    transition: 0.25s;
    transform: rotate(${(props) => (props.open ? '-180deg' : '0')});
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const DimensionSelector: FunctionComponent<{ options: SelectOptionProp<Dimension>[]; currentDimension: string; onChange: (option: SelectOptionProp) => unknown }> = ({
  options,
  currentDimension,
  onChange,
}) => {
  const [sortOpen, setSortOpen] = useState<boolean>(false)

  const adUnitPathOption: SelectOptionProp<SelectOptionProp<string>[]> = { label: 'Ad unit path', value: [] }
  const SELECTOR_NAME_CONFIG: { [key in (typeof AdUnitLevelDimensions)[number]]: string } = {
    adUnitLevel1: 'Level 1',
    adUnitLevel2: 'Level 2',
    adUnitLevel3: 'Level 3',
    adUnitLevel4: 'Level 4',
    adUnitLevel5: 'Level 5',
  }

  const refinedOptions = options.reduce<SelectOptionProp[]>((acc, option) => {
    if (Object.keys(SELECTOR_NAME_CONFIG).includes(option.value)) {
      if (!acc.find((option) => option.label === adUnitPathOption.label)) {
        acc.push(adUnitPathOption)
      }
      adUnitPathOption.value.push({ ...option, label: SELECTOR_NAME_CONFIG[option.value as (typeof AdUnitLevelDimensions)[number]] })
    } else {
      acc.push(option)
    }
    return acc
  }, [])

  return (
    <Wrapper>
      By :
      <SortSelect options={refinedOptions} onChange={onChange} trigger={<SortLink open={sortOpen} label={currentDimension} icon={'chevron_down'} />} open={sortOpen} setOpen={setSortOpen} />
    </Wrapper>
  )
}

type GamOverviewPageProps = WithClassName & {
  inventoryType: MobileInventoryType
}

const _GamOverviewPage: FunctionComponent<GamOverviewPageProps> = ({ className, inventoryType }) => {
  const today = DateTime.now().startOf('day')
  const logger = useLogger()
  const webOnlyDimensions: Dimension[] = ['adUnit', 'tagId']
  const selectorDimensions: Dimension[] = [
    'device',
    'pubstackDemandChannel',
    'abTestPopulation',
    'adUnitLevel1',
    'adUnitLevel2',
    'adUnitLevel3',
    'adUnitLevel4',
    'adUnitLevel5',
    ...(inventoryType === 'Web' ? webOnlyDimensions : []),
  ]
  const dimensions: Dimension[] = [...selectorDimensions, 'mediaType', 'country']
  const [context, setContext] = useRecoilState(contextState)
  const [timeData, setTimeData] = useState<GamOverviewMetricsByTime | undefined>(undefined)
  const [currentDimension, setCurrentDimension] = useState<GamOverviewDimensions>('pubstackDemandChannel')
  const dashboard: AnalyticsQueryDashboard = { name: `gam-overview-${inventoryType.toLowerCase()}` as AnalyticsQueryDashboardName, filterType: 'revenues' }
  const currencySymbol = useScopeCurrency()
  const queryDim: Dimension[] = [...dimensions, 'pubstackInventoryType']
  const buildContext = useBuildContextWithFilterEnforced('pubstackInventoryType', { label: 'pubstackInventoryType', value: inventoryType })
  const { byId: analyticsQuery } = useAnalyticsQuery<GamOverviewMetricsByTime>(null, queryDim, buildContext(context))
  const timelineEmptyData: GamOverviewMetricsByTime = {
    epoch: [],
    impressionCpmSum: [],
    impressionCount: [],
    measurableImpressionCount: [],
    mrcViewableImpressionCount: [],
    videoViewershipComplete: [],
    videoErrorCount: [],
    videoStart: [],
    videoFirstQuartile: [],
    videoMidpoint: [],
    videoThirdQuartile: [],
    mappings: {},
  }
  const isTodaySelected = sameDay(context.timeRange.from, today) && sameDay(context.timeRange.to, today)
  const loadTimeLineData = async (dimension: Dimension, inventoryType: MobileInventoryType) => {
    if (analyticsQuery.loading) {
      analyticsQuery.abort()
    }
    // 2022-10-17 bvion lestrade : no call to backend if "Today" is selected
    if (!isTodaySelected) {
      const d = await analyticsQuery.post('gam-overview.metrics.by.time', { dimension, inventoryType })
      setTimeData(d.values)
    }
  }
  const loadAllData = async () => {
    loadTimeLineData(currentDimension, inventoryType)
  }
  useEffect(() => {
    setTimeout(() => {
      loadAllData()
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context, currentDimension])

  const dimensionSelectorOptions = getFilterSidebarContent() // TODO 2022-09-01 NRA VMA retrieve options from Dimensions/GamOverviewDimensions => need key/value object for displayable dimension label
    .filter((fs) => selectorDimensions.includes(fs.dimension))
    .map((d) => ({ label: d.title, value: d.dimension }))
    .sort((a, b) => {
      if (a.value === 'pubstackDemandChannel') {
        return -1
      }
      if (b.value === 'pubstackDemandChannel') {
        return 1
      }
      return 0
    })

  const onSwitchTo7DaysClick = () => {
    setContext({
      ...context,
      timeRange: {
        from: today.minus({ days: 7 }),
        to: today.minus({ days: 1 }),
        tz: context.timeRange.tz,
      },
    })
  }

  const onRowClick = (name: MappedName) => {
    // 2022-10-27 LES BVI : We don't want the "Others" to be clickable in the breakdown (we accept the Others in devices because it is a real aggregation from backend)
    if (currentDimension === 'device' || name.value !== 'Others') {
      onBreakdownRowClick(logger, currentDimension, setContext)(name)
    }
  }

  const hasVideoInstream = (data: GamOverviewMetricsByTime): boolean => videoCompletionRate.sum(data) !== 0 || videoViewershipTotalErrorCount.sum(data) !== 0

  const currentDimensionLabel = dimensionSelectorOptions.find((d) => d.value === currentDimension)?.label || ''

  const breadcrumbs = useBreadcrumbs()
  return (
    <AnalyticsPage
      className={className}
      filterDimensions={dimensions}
      dashboard={dashboard}
      queryDimensions={queryDim}
      buildContext={buildContext}
      title={
        <>
          {breadcrumbs}
          <DimensionSelector currentDimension={currentDimensionLabel} options={dimensionSelectorOptions} onChange={(dimension) => setCurrentDimension(dimension.value as GamOverviewDimensions)} />
        </>
      }
    >
      <PageContent>
        <PureGamOverviewTimeline
          dimension={currentDimension}
          currencySymbol={currencySymbol}
          rawData={timeData ?? timelineEmptyData}
          isLoading={analyticsQuery.loading}
          error={!!analyticsQuery.error}
          empty={!analyticsQuery.loading && !timeData?.epoch.length}
          isTodayDisabled={isTodaySelected}
          onRefreshClick={loadAllData}
          onSwitchTo7DaysClick={onSwitchTo7DaysClick}
          onMetricChange={function (metric: string): void {
            logger.info({ type: 'graph-action', action: 'click', graphName: 'gam-overview-timeline', detail: metric })
          }}
          displayVideoData={hasVideoInstream(timeData ?? timelineEmptyData)}
        />
        <PureGamOverviewBreakdown
          currencySymbol={currencySymbol}
          rawData={timeData ?? timelineEmptyData}
          isLoading={analyticsQuery.loading}
          error={!!analyticsQuery.error}
          empty={!analyticsQuery.loading && !timeData?.epoch.length}
          isTodayDisabled={isTodaySelected}
          onRefreshClick={loadAllData}
          onRowClick={onRowClick}
          onSortChange={logSortBreakdownAction(logger)}
          dimension={currentDimension}
          onSwitchTo7DaysClick={onSwitchTo7DaysClick}
          displayVideoData={hasVideoInstream(timeData ?? timelineEmptyData)}
        />
        <PureGamOverwiewWidgetFunnel
          data={timeData ?? timelineEmptyData}
          isLoading={analyticsQuery.loading}
          error={!!analyticsQuery.error}
          empty={!analyticsQuery.loading && (!timeData?.epoch.length || !hasVideoInstream(timeData ?? timelineEmptyData))}
          isTodayDisabled={isTodaySelected}
          onRefreshClick={loadAllData}
          onSwitchTo7DaysClick={onSwitchTo7DaysClick}
        />
      </PageContent>
    </AnalyticsPage>
  )
}

export const GamOverviewPage = styled(_GamOverviewPage)``
