import styled from '@emotion/styled'
import { formatHumanReadable, sameDay } from '@pubstack/common/src/date'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime, FixedOffsetZone, Interval } from 'luxon'
import { FunctionComponent, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { Range, TimeRangeConfigItem } from '~/types/analytics'
import { WithClassName } from '~/types/utils'
import { TIME_RANGE_CONFIG } from '~/utils/analytics'
import Button from './Button'
import Datepicker from './Datepicker'
import { Link } from './Link'
import { Popover } from './Popover'
import SelectableItem from './SelectableItem'
import { Tooltip } from './Tooltip'

const TimeSelectorWrapper = styled.div`
  display: flex;
  border: 1px solid ${Colors.Platinum};
  border-radius: 4px;
  padding: 12px;
  background: ${Colors.White};
  align-items: center;
`

const TimeSelectorSelectableItemTooltip = styled(Tooltip)`
  & ~ & {
    margin-left: 4px;
  }
`
const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  ${Fonts.P1};
`

const TimeZone = styled.div`
  ${Fonts.P3};
  ${Fonts.colors.Hurricane};
`
const DateWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: column;
`

const DateLink = styled(Link)`
  margin-left: 4px;
`

const RefreshButton = styled(Button)`
  margin-left: 2px;
`

export type TimeSelectorProps = WithClassName & {
  presets: (TimeRangeConfigItem & Range)[]
  onRefreshClick: () => void
  onUpdate: (label: string, range: Range) => void
  selectedRange: Range
  today: DateTime
  selectableInterval: Interval
  timezone: Timezone
  maxNumberOfDayInTimerangeSelected: number
}

const _TimeSelector: FunctionComponent<TimeSelectorProps> = ({
  className,
  presets,
  onRefreshClick,
  onUpdate,
  selectedRange,
  today,
  selectableInterval,
  timezone,
  maxNumberOfDayInTimerangeSelected,
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const intervalLabel = sameDay(selectedRange.from, selectedRange.to)
    ? formatHumanReadable(selectedRange.from)
    : `${formatHumanReadable(selectedRange.from)} to ${formatHumanReadable(selectedRange.to)}`
  const initialSelectedInterval = { label: TIME_RANGE_CONFIG.CUSTOM.label, interval: Interval.fromDateTimes(selectedRange.from, selectedRange.to.plus({ days: 1 }).startOf('day')) }
  const timeZoneUTC = FixedOffsetZone.instance(DateTime.fromISO(today.toISO(), { zone: timezone }).offset).name

  // TODO cfo 2023-12-13 - need to be reworked because selectable interval + max number of days are defined elsewhere and have no impact on this
  const disclaimer =
    maxNumberOfDayInTimerangeSelected === 31
      ? `You can select up to 31 days among the last 3 months. Hourly data is available for the last 15 days.`
      : `You can select up to 3 months of data at daily level. Hourly data is available for the last 15 days.`
  return (
    <TimeSelectorWrapper className={className}>
      <DateWrapper>
        <DatePickerWrapper>
          Date:
          <Popover trigger={<DateLink variant={'primary'} icon={'calendar'} label={intervalLabel} />} open={datePickerOpen} setOpen={setDatePickerOpen} align={'start'}>
            <Datepicker
              selectableInterval={selectableInterval}
              initialSelectedInterval={initialSelectedInterval}
              maxNumberOfDayInTimerangeSelected={maxNumberOfDayInTimerangeSelected}
              today={today}
              disclaimer={disclaimer}
              onTimerangeSelected={(selection) => {
                onUpdate(selection.label, { from: selection.interval.start.startOf('day'), to: selection.interval.end.endOf('day') })
                setDatePickerOpen(false)
              }}
            />
          </Popover>
        </DatePickerWrapper>
        <TimeZone>{timeZoneUTC}</TimeZone>
      </DateWrapper>

      {presets.map(({ shortLabel, label, from, to }, index) => (
        <TimeSelectorSelectableItemTooltip title={label} key={index}>
          <SelectableItem
            selected={sameDay(from, selectedRange.from) && sameDay(to, selectedRange.to)}
            text={shortLabel}
            onClick={() => {
              onUpdate(label, { from, to })
            }}
          />
        </TimeSelectorSelectableItemTooltip>
      ))}
      <RefreshButton variant={'tertiary'} iconName={'refresh'} iconSize={Sizes['24']} onClick={onRefreshClick} />
    </TimeSelectorWrapper>
  )
}

const TimeSelector = styled(_TimeSelector)``
export default TimeSelector
