import styled from '@emotion/styled'
import { BidderCatalogAlias, BidderParam } from '@pubstack/common/src/bidderCatalog'
import { FunctionComponent, ReactElement, useEffect } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { Input, _Input } from '~/components/Input'
import { Selector } from '~/components/Selector'
import { Skeleton } from '~/components/Skeleton'
import { Status } from '~/components/Status'
import { Toggle } from '~/components/Toggle'
import { WithClassName } from '~/types/utils'
import { MappingTransfer } from './mappingTransfer/MappingTransfer'

const AliasIntro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-bottom: 16px;
`

const MediatypesWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 2;
`

const MappingLabel = styled.span`
  ${Fonts.H2};
  display: inline-flex;
  align-items: center;
  gap: 4px;
`

const MediaTypeChoices = styled.div`
  display: flex;

  & ${Selector} {
    width: 282px;
  }
`

const AliasSummaryTitle = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;

  & > div {
    display: flex;
    gap: 70px;
    margin-right: 70px;
  }
`

const Title = styled.h2`
  ${Fonts.H2};
  font-weight: 500;
`

const Subtitle = styled.h2`
  ${Fonts.P1};
  font-weight: 500;
`

const PossiblyDisabled = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? Colors.Silver : Colors.Jet)};
`

const FormActions = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
`

const SpreadsheetDescription = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 12px;
`

const BidderSettings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

const Divider = styled.div`
  width: 1px;
  max-width: 1px;
  min-width: 1px;
  flex: 1;
  align-self: stretch;
  background-color: ${Colors.Platinum};
`

const BidAdjustments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 2;
  ${_Input} {
    max-width: 212px;
  }
`

const FormToggle = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

const AdjustmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

const Preview = styled.div`
  ${Fonts.P2};
  color: ${Colors.Hurricane};
  font-style: italic;
`

const getAliasUsage = (bidderParams: BidderParam[]) => {
  const maxAdUnits = new Set(bidderParams.map((bp) => bp.adUnitName)).size
  const usedAdUnits = new Set(bidderParams.filter((bp) => Object.keys(bp.params).length).map((bp) => bp.adUnitName)).size
  const maxSites = new Set(bidderParams.map((bp) => bp.siteName)).size
  const usedSites = new Set(bidderParams.filter((bp) => Object.keys(bp.params).length).map((bp) => bp.siteName)).size

  return {
    maxAdUnits,
    usedAdUnits,
    maxSites,
    usedSites,
  }
}

type PureAdStackIntegrationMappingProps = WithClassName & {
  alias: BidderCatalogAlias
  SpreadsheetElement: ReactElement
  onAliasSettingsUpdate: (alias: BidderCatalogAlias) => Promise<void>
  isLoading: boolean
  isBidderSettingsLoading: boolean
  isUploading: boolean
  isTemplateDownloading: boolean
  isMappingDownloading: boolean
  onMappingDownload: (options?: { fullMapping?: boolean }) => void
  onMappingUpload: (file: File) => void
  canEditBidAdjustment: boolean
}
const _PureAdStackIntegrationMapping: FunctionComponent<PureAdStackIntegrationMappingProps> = ({
  className,
  alias,
  SpreadsheetElement,
  onAliasSettingsUpdate,
  isLoading,
  isBidderSettingsLoading,
  isUploading,
  isMappingDownloading,
  isTemplateDownloading,
  onMappingDownload,
  onMappingUpload,
  canEditBidAdjustment,
}) => {
  const isOutstreamSelected = alias.isOutstream

  const { maxAdUnits, usedAdUnits, maxSites, usedSites } = getAliasUsage(alias.bidderParams)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
    trigger,
  } = useForm<{
    isOutstream: boolean
    bidAdjustment: {
      enable: boolean
      adjustment?: number
    }
  }>({
    mode: 'onSubmit',
    defaultValues: {
      isOutstream: isOutstreamSelected,
      bidAdjustment: {
        enable: !!alias.bidAdjustment,
        adjustment: alias.bidAdjustment ? Math.round(alias.bidAdjustment * 100) : undefined,
      },
    },
  })

  const { bidAdjustment } = useWatch({ control })
  const convertedBidAdjustment = (bidAdjustment?.adjustment ?? 0) / 100

  useEffect(() => {
    if (bidAdjustment?.adjustment !== undefined) {
      trigger('bidAdjustment.adjustment')
    }
  }, [bidAdjustment])

  const onSubmit = handleSubmit((data) => {
    const { isOutstream, bidAdjustment: bidAdjustmentData } = data
    onAliasSettingsUpdate({
      ...alias,
      isOutstream,
      bidAdjustment: bidAdjustmentData.enable && bidAdjustmentData.adjustment ? convertedBidAdjustment : undefined,
    })
    reset(data)
  })

  return (
    <CollapsiblePanel
      isCollapsible={!isLoading}
      forceState={isLoading ? 'closed' : undefined}
      hasBorderWhenOpened
      className={className}
      title={
        <AliasSummaryTitle>
          <MappingLabel>{isLoading ? <Skeleton width={`10ch`} /> : alias.bidderLabel}</MappingLabel>

          <div>
            <Status state={usedSites ? 'active' : 'inactive'}>
              Used on {isLoading ? <Skeleton width={'2ch'} /> : usedSites}/{isLoading ? <Skeleton width={'2ch'} /> : maxSites} sites
            </Status>
            <Status state={usedAdUnits ? 'active' : 'inactive'}>
              Used on {isLoading ? <Skeleton width={'2ch'} /> : usedAdUnits}/{isLoading ? <Skeleton width={'2ch'} /> : maxAdUnits} ad units
            </Status>
            <div>
              Display <PossiblyDisabled disabled={!alias.isOutstream}>| Outstream</PossiblyDisabled>
            </div>
          </div>
        </AliasSummaryTitle>
      }
    >
      <AliasIntro>
        <Title>Bidder mapping</Title>
      </AliasIntro>
      {isLoading ? (
        <Skeleton bigger width={'150px'} />
      ) : (
        alias && (
          <>
            <MappingTransfer
              isMapped={alias.isUsed}
              isUploading={isUploading}
              isTemplateDownloading={isTemplateDownloading}
              isMappingDownloading={isMappingDownloading}
              onDownload={(options) => {
                onMappingDownload(options)
              }}
              onUpload={(file) => onMappingUpload(file)}
            />

            <SpreadsheetDescription>
              <span>You can also select a site below to edit its mapping.</span>
            </SpreadsheetDescription>

            {SpreadsheetElement}
            <form onSubmit={onSubmit}>
              <Title>Bidder settings for all sites</Title>
              <BidderSettings>
                <MediatypesWrapper>
                  <span>Allow or disable outstream</span>
                  <MediaTypeChoices>
                    <Controller
                      control={control}
                      name={'isOutstream'}
                      render={({ field }) => (
                        <Selector
                          title={'Video - outstream'}
                          icon={'video'}
                          selectedColor={Colors.Cobalt}
                          detail={'Only for compatible ad units.'}
                          disabled={!alias.isUsed || isBidderSettingsLoading}
                          selected={field.value}
                          onClick={() => field.onChange(!field.value)}
                        />
                      )}
                    />
                  </MediaTypeChoices>
                </MediatypesWrapper>
                <Divider />
                <BidAdjustments>
                  <div>
                    <Subtitle>Bid adjustment</Subtitle>
                    <div>Adjust the bidder's revshare when returning gross CPMs.</div>
                  </div>
                  <Controller
                    control={control}
                    name={'bidAdjustment.enable'}
                    render={({ field: { onChange, value } }) => (
                      <FormToggle>
                        <Toggle id={'bidAdjustment.enable'} onClick={() => onChange(!value)} value={value} disabled={!canEditBidAdjustment} />
                        <span>Bid adjustment</span>
                      </FormToggle>
                    )}
                  />
                  <AdjustmentWrapper>
                    <Input
                      label="% of adjustment"
                      control={control}
                      name="bidAdjustment.adjustment"
                      disabled={!canEditBidAdjustment || !bidAdjustment?.enable}
                      error={errors.bidAdjustment?.adjustment?.message}
                      type="number"
                      min={1}
                      step={1}
                      rules={{
                        validate: {
                          min: (value) => !bidAdjustment?.enable || (Number(value) > 0 ? true : 'Should be greater than 0'),
                        },
                        required: bidAdjustment?.enable,
                      }}
                      iconRight={'percent'}
                    />
                    {bidAdjustment?.enable && bidAdjustment.adjustment && <Preview>Preview: a 1€ bid will turn into {convertedBidAdjustment}€</Preview>}
                  </AdjustmentWrapper>
                </BidAdjustments>
              </BidderSettings>
              <FormActions>
                <Button
                  disabled={isLoading || isBidderSettingsLoading || !isValid || !isDirty}
                  iconSpin={isLoading || isBidderSettingsLoading}
                  iconName={isLoading || isBidderSettingsLoading ? 'pubstack' : undefined}
                  type="submit"
                >
                  Save settings
                </Button>
              </FormActions>
            </form>
          </>
        )
      )}
    </CollapsiblePanel>
  )
}

export const PureAdStackIntegrationMapping = styled(_PureAdStackIntegrationMapping)``
